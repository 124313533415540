import type { OperationalEvent } from '../new-analytics-types';
import { OperationalAction, OperationalSubject } from '../new-analytics-types';

/**
 * Type to ensure the uniqueness of each event source.
 * Please extend the type when onboarding new components to use reCAPTCHA
 */
export type LoadRecaptchaEnterpriseEventSources =
  | 'unknown'
  | 'orgUserInviteDrawer'
  | 'orgQuickInvite'
  | 'siteUserInviteDrawer'
  | 'siteQuickInvite'
  | 'orgCreateGroupModal'
  | 'addUserToGroupDetailsModal';

export const recaptchaEnterpriseEvents = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/I/pages/744718557/Vortex+Analytic+Events',
  screen: {},
  ui: {},
  track: {},
  operational: {
    loadRecaptchaEnterpriseFailed: (
      source: LoadRecaptchaEnterpriseEventSources,
      attributes: { errorType: 'windowError' | 'scriptError' },
    ): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.LoadRecaptchaEnterprise,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45885',
    }),
    loadRecaptchaEnterpriseSucceeded: (source: LoadRecaptchaEnterpriseEventSources): OperationalEvent => ({
      data: {
        action: OperationalAction.Succeeded,
        actionSubject: OperationalSubject.LoadRecaptchaEnterprise,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46033',
    }),
  },
};
