import type { AdminNotifications } from '@adminhub/graphql-types';

import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';

const userInviteAccessTabScreen = 'userInviteAccessTabScreen';
const approvedDomainsTabScreen = 'approvedDomainsTabScreen';
const approvedDomainsModalScreen = 'approvedDomainsModalScreen';
const removeDomainModalScreen = 'approvedDomainsRemoveDomainModalScreen';
const removeAccessSettingsModalScreen = 'removeAccessSettingsModalScreen';
const inviteLinkTabScreen = 'inviteLinkTabScreen';
const inviteLinkTabRegenerateModalScreen = 'inviteLinkTabRegenerateModalScreen';
const siteAccessChangeScreen = 'siteAccessChangeScreen';
export const userAccessSettingsHeaderScreen = 'userAccessSettingsHeaderScreen';

type ScreenKeys =
  | 'approvedDomainsTabScreen'
  | 'approvedDomainsModalScreen'
  | 'approvedDomainsRemoveDomainModalScreen'
  | 'userInviteAccessTabScreen'
  | 'removeUserAccessModalScreen'
  | 'inviteLinkTabScreen'
  | 'inviteLinkTabRegenerateModalScreen'
  | 'siteAccessChangeScreen';
type UiKeys =
  | 'addDomainButtonClicked'
  | 'domainNameButtonClicked'
  | 'removeRemoveDomainModalButtonClicked'
  | 'cancelRemoveDomainModalButtonClicked'
  | 'requireAdminApprovaLinkClicked'
  | 'inviteAnyoneDropdownLinkClicked'
  | 'inviteApprovedDomainsDropdownLinkClicked'
  | 'inviteNotAllowedDropdownLinkClicked'
  | 'notAllowedCustomNoteButtonClicked'
  | 'editDomainButtonClicked'
  | 'saveDomainButtonClicked'
  | 'cancelModalButtonClicked'
  | 'cancelRemoveOrgAccessModalButtonClicked'
  | 'saveRemoveOrgAccessModalButtonClicked'
  | 'inviteLinkToggleClicked'
  | 'regenerateInviteLinkButtonClicked'
  | 'copyInviteLinkButtonClicked'
  | 'cancelInviteLinkRegenerateModalButton'
  | 'regenerateInviteLinkRegenerateModalButtonClicked'
  | 'redirectToOrgAccessSettingsButton'
  | 'orgAccessSettingLearnMoreButton'
  | 'vogueSpotlightTourClickNext'
  | 'vogueSpotlightTourClickSkip'
  | 'approvedDomainInlineMessageLinkClick'
  | 'learnMoreUserAccessSettingsLinkClick'
  | 'userAccessSettingsDropdownButtonClicked';
type TrackKeys =
  | 'requireAdminApprovalSaved'
  | 'inviteAnyoneSaved'
  | 'approvedDomainsSaved'
  | 'notAllowedSaved'
  | 'notAllowedNoteSaved'
  | 'approvedDomainsRemoved'
  | 'removeAccessSettingsSaved'
  | 'inviteLinkEnabled'
  | 'inviteLinkDisabled'
  | 'inviteLinkRegenerated';

type ApprovedDomainsModalScreenType = 'anyone' | 'edit' | 'add';

interface ApprovedDomainsTabUiAttributes {
  existingDomainsCount: number;
  enabledDomainsCount: number;
  disabledDomainsCount: number;
  pageNumber: number;
  domain: string;
}

interface ApprovedDomainsModalUiAttributes {
  existingProductsCount: number;
  previousNotifyOrgAdmin: AdminNotifications;
  newNotifyOrgAdmin: AdminNotifications;
  previousUserProductRoles: string[];
  newUserProductRoles: string[];
}

export const userAccessSettingsEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/I/pages/980248374/Milestone+3+Analytics+Spec',
  screen: {
    approvedDomainsTabScreen: (attributes: {
      existingDomainsCount: number;
      enabledDomainsCount: number;
      disabledDomainsCount: number;
    }): ScreenEvent => ({
      data: {
        name: approvedDomainsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33560',
    }),
    approvedDomainsModalScreen: (attributes: { type: ApprovedDomainsModalScreenType }): ScreenEvent => ({
      data: {
        name: approvedDomainsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33559',
    }),
    approvedDomainsRemoveDomainModalScreen: (): ScreenEvent => ({
      data: {
        name: removeDomainModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33558',
    }),
    removeUserAccessModalScreen: (attributes: { source: string }): ScreenEvent => ({
      data: {
        name: removeAccessSettingsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34449',
    }),
    userInviteAccessTabScreen: (attributes: {
      orgProductCount: number;
      requireAnyoneCount: number;
      inviteAnyoneCount: number;
      notAllowedCount: number;
      notAllowedFeatureEnabled: boolean;
    }): ScreenEvent => ({
      data: {
        name: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33225',
    }),
    inviteLinkTabScreen: (): ScreenEvent => ({
      data: {
        name: inviteLinkTabScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34941',
    }),
    inviteLinkTabRegenerateModalScreen: (): ScreenEvent => ({
      data: {
        name: inviteLinkTabRegenerateModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39953',
    }),
    siteAccessChangeScreen: (attributes: { orgId: string; isOrgAdmin: boolean }): ScreenEvent => ({
      data: {
        name: siteAccessChangeScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40021',
    }),
  },
  ui: {
    userAccessSettingsDropdownButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'userAccessSettingsMenu',
        source: userAccessSettingsHeaderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71958',
    }),
    saveDomainButtonClicked: (attributes: ApprovedDomainsModalUiAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveDomainButton',
        source: approvedDomainsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34087',
    }),
    cancelModalButtonClicked: (attributes: ApprovedDomainsModalUiAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSaveDomainModalButton',
        source: approvedDomainsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34088',
    }),
    removeRemoveDomainModalButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeRemoveDomainButton',
        source: removeDomainModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39986',
    }),
    cancelRemoveDomainModalButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRemoveDomainModalButton',
        source: removeDomainModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39985',
    }),
    saveRemoveOrgAccessModalButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeAccessSettingsButton',
        source: removeAccessSettingsModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34447',
    }),
    cancelRemoveOrgAccessModalButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRemoveAccessSettingsButton',
        source: removeAccessSettingsModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34448',
    }),
    addDomainButtonClicked: (attributes: ApprovedDomainsTabUiAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addDomainButton',
        source: approvedDomainsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34031',
    }),
    domainNameButtonClicked: (attributes: ApprovedDomainsTabUiAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainNameButton',
        source: approvedDomainsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34032',
    }),
    editDomainButtonClicked: (attributes: ApprovedDomainsTabUiAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editDomainButton',
        source: approvedDomainsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34033',
    }),
    requireAdminApprovaLinkClicked: (attributes: { productARI: string; notAllowedFeatureEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'requireAdminApprovalLink',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33226',
    }),
    inviteAnyoneDropdownLinkClicked: (attributes: { existingProductsCount: number; notAllowedFeatureEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'inviteAnyoneDropdownLink',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33227',
    }),
    inviteApprovedDomainsDropdownLinkClicked: (attributes: {
      existingProductsCount: number;
      notAllowedFeatureEnabled: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'inviteApprovedDomainsDropdownLink',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40743',
    }),
    inviteNotAllowedDropdownLinkClicked: (attributes: { existingProductsCount: number; notAllowedFeatureEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'inviteNotAllowedDropdownLink',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46661',
    }),
    notAllowedCustomNoteButtonClicked: (attributes: { orgARI: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'notAllowedCustomNoteButton',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48190',
    }),
    inviteLinkToggleClicked: (attributes: { productARI: string; productId: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'inviteLinkToggle',
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34946',
    }),
    regenerateInviteLinkButtonClicked: (attributes: { productARI: string; productId: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateInviteLink',
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34944',
    }),
    copyInviteLinkButtonClicked: (attributes: { productARI: string; productId: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyInviteLink',
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34947',
    }),
    regenerateInviteLinkRegenerateModalButtonClicked: (attributes: {
      productARI: string;
      productId: string;
      cloudId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateInviteLinkModalCreateButton',
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34945',
    }),
    cancelInviteLinkRegenerateModalButton: (attributes: { productARI: string; productId: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateInviteLinkModalCancelButton',
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34943',
    }),
    redirectToOrgAccessSettingsButton: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'redirectToOrgAccessSettingsButton',
        source: siteAccessChangeScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40022',
    }),
    orgAccessSettingLearnMoreButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'orgAccessSettingLearnMoreButton',
        source: siteAccessChangeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40595',
    }),
    vogueSpotlightTourClickNext: (attributes: { orgId: string; target: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'vogueSpotlightTourClickNext',
        source: siteAccessChangeScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42346',
    }),
    vogueSpotlightTourClickSkip: (attributes: { orgId: string; target: string; isLastStep: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'vogueSpotlightTourClickSkip',
        source: siteAccessChangeScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44597',
    }),
    approvedDomainInlineMessageLinkClick: (attributes: { productId: string; role: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'approvedDomainModalInlineMessageLink',
        source: approvedDomainsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47870',
    }),
    learnMoreUserAccessSettingsLinkClick: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreUserAccessSettings',
        source: userAccessSettingsHeaderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57886',
    }),
  },
  track: {
    approvedDomainsSaved: (attributes: {
      existingProductsCount: number;
      domain: string;
      previousUserProductRoles: string[];
      newUserProductRoles: string[];
    }): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.ApprovedDomain,
        actionSubjectId: attributes.domain,
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34089',
    }),
    approvedDomainsRemoved: (attributes: { domain: string; products: string[] }): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.ApprovedDomain,
        actionSubjectId: attributes.domain,
        source: removeDomainModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40119',
    }),
    removeAccessSettingsSaved: (atrributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.AccessSetting,
        actionSubjectId: atrributes.orgId,
        source: removeAccessSettingsModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34446',
    }),
    requireAdminApprovalSaved: (attributes: { productARI: string }): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.UserInviteSetting,
        actionSubjectId: 'requireAdminApproval',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33228',
    }),
    inviteAnyoneSaved: (attributes: { productARI: string }): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.UserInviteSetting,
        actionSubjectId: 'inviteAnyone',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33228',
    }),
    notAllowedSaved: (attributes: { productARI: string }): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.UserInviteSetting,
        actionSubjectId: 'notAllowed',
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33228',
    }),
    notAllowedNoteSaved: (attributes: { orgARI: string }): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.NotAllowedNoteSetting,
        actionSubjectId: attributes.orgARI,
        source: userInviteAccessTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48184',
    }),
    inviteLinkEnabled: (attributes: { productARI: string; productId: string; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Enabled,
        actionSubject: TrackActionSubject.InviteLinks,
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34949',
    }),
    inviteLinkDisabled: (attributes: { productARI: string; productId: string; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Disabled,
        actionSubject: TrackActionSubject.InviteLinks,
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34948',
    }),
    inviteLinkRegenerated: (attributes: { productARI: string; productId: string; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Regenerated,
        actionSubject: TrackActionSubject.InviteLinks,
        source: inviteLinkTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34950',
    }),
  },
  operational: {},
};
