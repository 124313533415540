import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import type { LoadingComponentProps } from 'react-loadable';

import AkButton from '@atlaskit/button/loading-button';

import { UFOLoadHold } from '@adminhub/ufo';

import { createErrorIcon, errorFlagMessages, GenericErrorMessage } from '@adminhub/error';
import type { FlagProps } from '@adminhub/flag';
import { withFlag } from '@adminhub/flag';

import { FullPageSkeleton } from './full-page-skeleton';

const messages = defineMessages({
  retry: {
    id: 'common.loading.page-loading.retry',
    defaultMessage: 'Retry',
    description: 'Retry message for a generic error',
  },
});

interface PageLoadingComponentProps {
  onError?: React.ReactNode;
  onLoading?: React.ReactNode;
}

type PageLoadingProps = LoadingComponentProps & FlagProps & PageLoadingComponentProps;

const InternalPageLoadingImpl: React.FC<PageLoadingProps> = (props) => {
  if (props.error || props.timedOut) {
    props.showFlag({
      autoDismiss: true,
      icon: createErrorIcon(),
      id: 'react-loadable-error',
      title: <FormattedMessage {...errorFlagMessages.title} />,
      description: <FormattedMessage {...errorFlagMessages.description} />,
    });

    return (props.onError as any) || null;
  } else if (props.pastDelay) {
    // Display spinner or nothing
    return (props.onLoading as any) || null;
  }

  return null;
};

export const PageLoadingImpl: React.FC<PageLoadingProps> = (props) => (
  <UFOLoadHold name="page-loading">
    <InternalPageLoadingImpl {...props} />
  </UFOLoadHold>
);

export const FullPageLoadingImpl: React.FC<PageLoadingProps> = (props) => (
  <UFOLoadHold name="page-loading-full">
    <InternalPageLoadingImpl
      {...props}
      onError={
        <React.Fragment>
          <h1>
            <GenericErrorMessage />
          </h1>
          <p>
            <AkButton onClick={props.retry}>
              <FormattedMessage {...messages.retry} />
            </AkButton>
          </p>
        </React.Fragment>
      }
      onLoading={<FullPageSkeleton id="page-loading" collapsedNav={true} />}
    />
  </UFOLoadHold>
);

export const PageLoading = withFlag(PageLoadingImpl);
export const FullPageLoading = withFlag(FullPageLoadingImpl);
