import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'rovoSignupLandingScreen'
  | 'rovoSignupJoinScreen'
  | 'rovoSignupOrgScreen'
  | 'rovoSignupOrgErrorScreen'
  | 'rovoSignupEnabledOrgScreen';
type UiKeys =
  | 'selectOrgClickEvent'
  | 'selectOrgLinkClickEvent'
  | 'rovoCustomerAgreementClickEvent'
  | 'getStartedWithRovoClickEvent'
  | 'rovoPrivacyPolicyClickEvent';

type TrackEventKeys = 'startedRovoBeta';

export const rovoSignupLandingScreen = 'rovoSignupLandingScreen';
export const rovoSignupJoinScreen = 'rovoSignupJoinScreen';
export const rovoSignupOrgScreen = 'rovoSignupOrgScreen';
export const rovoSignupOrgErrorScreen = 'rovoSignupOrgErrorScreen';
export const rovoSignupEnabledOrgScreen = 'rovoSignupEnabledOrgScreen';

export const rovoSignupEvents: FeatureEvents<ScreenKeys, UiKeys, TrackEventKeys, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/~7120205bb008ffa36e44e99a045aa24f47a9cd/pages/3733890290/Rovo+Signup+Analytics+Spec',

  screen: {
    rovoSignupLandingScreen: (): ScreenEvent => ({
      data: {
        name: rovoSignupLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67406',
    }),

    rovoSignupJoinScreen: (): ScreenEvent => ({
      data: {
        name: rovoSignupJoinScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67407',
    }),

    rovoSignupOrgScreen: (): ScreenEvent => ({
      data: {
        name: rovoSignupOrgScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71815',
    }),

    rovoSignupOrgErrorScreen: (): ScreenEvent => ({
      data: {
        name: rovoSignupOrgErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72677',
    }),

    rovoSignupEnabledOrgScreen: (): ScreenEvent => ({
      data: {
        name: rovoSignupEnabledOrgScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71857',
    }),
  },

  ui: {
    selectOrgClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectOrg',
        source: rovoSignupLandingScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19439',
    }),
    selectOrgLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectOrg',
        source: rovoSignupLandingScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62094',
    }),
    rovoCustomerAgreementClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'customerAgreementRovoLink',
        source: rovoSignupJoinScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67409',
    }),

    getStartedWithRovoClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rovoGetStarted',
        source: rovoSignupJoinScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67411',
    }),

    rovoPrivacyPolicyClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'privacyPolicyRovoLink',
        source: rovoSignupJoinScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67410',
    }),
  },

  track: {
    startedRovoBeta: (attributes: { isSuccess: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.RovoBeta,
        source: rovoSignupJoinScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67412',
    }),
  },

  operational: {},
};
