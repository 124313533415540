import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

import { ErrorMessage as AkErrorMessage } from '@atlaskit/form';
import AkHeading from '@atlaskit/heading';
import AkWarningIcon from '@atlaskit/icon/glyph/warning';
import AkInlineDialog from '@atlaskit/inline-dialog';
import AkInlineEdit from '@atlaskit/inline-edit';
import { Box as AkBox } from '@atlaskit/primitives';
import AkTextfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';

import type { ExtendedFieldProps as AkExtendedFieldProps } from '@adminhub/types';

import { Title } from './main-layout.styled';

const TextfieldWrapper = styled.div`
  input {
    font-size: ${24 / 14}em;
    line-height: ${28 / 24};
    color: ${token('color.text', '#172B4D')};
    font-weight: ${token('font.weight.medium', '500')};
    letter-spacing: -0.01em;
    margin: 0;
  }
`;

const ErrorWrapper = styled.div`
  margin: 0;
  font-size: initial;
`;

const WarningIconWrapper = styled.div`
  padding-right: ${token('space.100', '8px')};
  line-height: 100%;
`;

const InlineEditWrapper = styled.div`
  margin-top: ${token('space.negative.100', '-8px')};
  width: 100%;

  #error-message {
    font-size: 14px;
  }
`;

const ReadViewWrapper = styled.div`
  margin: 0 0 0 ${token('space.negative.025', '-2px')};
`;

interface OwnProps {
  defaultValue: string;
  errorMessage?: string | null;
  onConfirm?(value: string): void;
}

export const InlineEditTitle: React.FC<OwnProps> = ({ defaultValue, errorMessage, onConfirm }) => {
  const [inlineTitleTextValue, setInlineTitleTextValue] = React.useState<string>(defaultValue);

  React.useEffect(() => {
    setInlineTitleTextValue(defaultValue);
  }, [defaultValue]);

  const handleConfirm = (value: string) => {
    setInlineTitleTextValue(value);
    if (onConfirm) {
      onConfirm(value);
    }
  };

  const validate = (value: string) => {
    if (errorMessage && value === inlineTitleTextValue) {
      return errorMessage;
    }

    return undefined;
  };

  const renderInlineEditView = ({ errorMessage: _errorMessage, ...fieldProps }: AkExtendedFieldProps<string>) => (
    <AkInlineDialog isOpen={fieldProps.isInvalid} content={<AkBox id="error-message">{errorMessage}</AkBox>} placement="right">
      <TextfieldWrapper>
        <AkTextfield
          {...fieldProps}
          autoFocus
          testId="inline-edit-edit-view"
          elemAfterInput={
            errorMessage &&
            fieldProps.value === inlineTitleTextValue && (
              <WarningIconWrapper>
                <AkWarningIcon label="information" primaryColor={token('color.icon.warning', '#FFAB00')} size="large" />
              </WarningIconWrapper>
            )
          }
        />
      </TextfieldWrapper>
    </AkInlineDialog>
  );

  const renderInlineEditReadView = () => (
    <AkHeading size="large">
      <ReadViewWrapper data-testid="inline-edit-title-view">
        <Title isDisabled={false}>{inlineTitleTextValue}</Title>
        {errorMessage && (
          <ErrorWrapper>
            <AkErrorMessage>{errorMessage}</AkErrorMessage>
          </ErrorWrapper>
        )}
      </ReadViewWrapper>
    </AkHeading>
  );

  return (
    <InlineEditWrapper>
      <AkInlineEdit
        defaultValue={inlineTitleTextValue}
        editView={renderInlineEditView}
        readView={renderInlineEditReadView}
        onConfirm={handleConfirm}
        validate={validate}
        isRequired={true}
      />
    </InlineEditWrapper>
  );
};
