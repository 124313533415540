/* eslint-disable import/no-unassigned-import */
// (we are relying on side-effects here, don't report them as errors)
// IE11
import 'core-js/fn/array/fill';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/from';
import 'core-js/fn/array/includes';
import 'core-js/fn/map';
import 'core-js/fn/object/assign';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/values';
import 'core-js/fn/string/starts-with';

import 'intersection-observer';

// BUX
import 'babel-polyfill';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behaviour.
  require('promise/lib/rejection-tracking').enable(); // eslint-disable-line  @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  (window as any).Promise = require('promise/lib/es6-extensions.js'); // eslint-disable-line  @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
}

// fetch() polyfill for making API calls
// eslint-disable-line  @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
require('whatwg-fetch');
