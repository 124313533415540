import type { OrgPolicyResourceMigrationType, RealmTypes, UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

const enum DataResidencyScreenVariations {
  hasNoEntitlements = 'hasNoEntitlements',
}

type ScreenKeys =
  | 'dataResidencyScreen'
  | 'requestMigrationWhyScreen'
  | 'requestMigrationChooseRealmScreen'
  | 'requestMigrationSelectWindowScreen'
  | 'requestMigrationSelectWindowNewScreen'
  | 'requestMigrationReviewAppsScreen'
  | 'requestMigrationReviewScreen'
  | 'dataResidencyScreenWithApps'
  | 'realmConfirmationModalScreen';

type UiKeys =
  | 'learnMore'
  | 'whyDisabled'
  | 'moreRegions'
  | 'requestMove'
  | 'requestMigrationWhyScreenNext'
  | 'requestMigrationWhyScreenCancel'
  | 'requestMigrationChooseRealmChoseRealm'
  | 'requestMigrationChooseRealmScreenReview'
  | 'requestMigrationChooseRealmScreenBack'
  | 'requestMigrationChooseRealmScreenWhatIsRealm'
  | 'requestMigrationReviewScreenSelectPreferredDate'
  | 'requestMigrationChooseRealmSelect'
  | 'realmConfirmationModalConfirm'
  | 'realmConfirmationModalCancel'
  | 'requestMigrationSelectWindowScreenBack'
  | 'requestMigrationSelectWindowScreenNext'
  | 'requestMigrationReviewAppsScreenNext'
  | 'requestMigrationReviewAppsScreenBack'
  | 'requestMigrationReviewScreenBack'
  | 'requestMigrationReviewScreenRequestMove'
  | 'requestMigrationReviewScreenSubmitMove'
  | 'requestMigrationSelectWindowScreenViewApps'
  | 'viewTicket'
  | 'ogDaReLearnMore'
  | 'applyFiltersMainTable'
  | 'rovoPinInPlaceRequestSubmitted'
  | 'pinInPlaceRequestSubmitted';

type TrackKeys = 'dataResidencyChanged' | 'dataResidencyCanceled';

type OperationalKeys = 'featureExposed';

interface MigrationAttributes {
  cloudId: string;
  product: UiProductType;
}

interface DataResidencyChangedAttributes {
  product: string;
  oldRegion: string;
  newRegion: string;
  dateStart: string;
  siteId: string;
}

export enum DataResidencyScreens {
  dataResidencyScreen = 'dataResidencyScreen',
  dataResidencyScreenWithApps = 'dataResidencyScreenWithApps',
  requestMigrationWhyScreen = 'requestMigrationWhyScreen',
  requestMigrationChooseRealmScreen = 'requestMigrationChooseRealmScreen',
  requestMigrationSelectWindowScreen = 'requestMigrationSelectWindowScreen',
  requestMigrationSelectWindowNewScreen = 'requestMigrationSelectWindowNewScreen',
  requestMigrationReviewAppsScreen = 'requestMigrationReviewAppsScreen',
  requestMigrationReviewScreen = 'requestMigrationReviewScreen',
  realmConfirmationModalScreen = 'realmConfirmationModalScreen',
}

export const dataResidencyEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/752371988/Data+Residency+Analytics+Spec',

  screen: {
    dataResidencyScreenWithApps: (attributes: { numberOfEntitlements: number; isExperimentEnabled: boolean }): ScreenEvent => {
      const screenVariations = attributes.numberOfEntitlements === 0 ? DataResidencyScreenVariations.hasNoEntitlements : undefined;

      return {
        data: {
          name: DataResidencyScreens.dataResidencyScreenWithApps,
          attributes: {
            ...attributes,
            numberOfEntitlements: attributes.numberOfEntitlements,
            screenVariations,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50841',
      };
    },
    dataResidencyScreen: (attributes: { numberOfEntitlements: number; isExperimentEnabled: boolean }): ScreenEvent => {
      const screenVariations = attributes.numberOfEntitlements === 0 ? DataResidencyScreenVariations.hasNoEntitlements : undefined;

      return {
        data: {
          name: DataResidencyScreens.dataResidencyScreen,
          attributes: {
            numberOfEntitlements: attributes.numberOfEntitlements,
            screenVariations,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25677',
      };
    },

    requestMigrationWhyScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28014',
    }),

    requestMigrationChooseRealmScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28015',
    }),

    requestMigrationSelectWindowScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationSelectWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42169',
    }),

    requestMigrationSelectWindowNewScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationSelectWindowNewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59422',
    }),

    requestMigrationReviewAppsScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61963',
    }),

    requestMigrationReviewScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.requestMigrationReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28016',
    }),

    realmConfirmationModalScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyScreens.realmConfirmationModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58921',
    }),
  },

  ui: {
    learnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        source: DataResidencyScreens.dataResidencyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),

    whyDisabled: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Icon,
        actionSubjectId: 'whyDisabled',
        source: DataResidencyScreens.dataResidencyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26520',
    }),

    moreRegions: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreRegions',
        source: DataResidencyScreens.dataResidencyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26519',
    }),

    requestMove: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'requestMove',
        source: DataResidencyScreens.dataResidencyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28019',
    }),

    requestMigrationWhyScreenNext: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'next',
        source: DataResidencyScreens.requestMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25541',
    }),

    requestMigrationWhyScreenCancel: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: DataResidencyScreens.requestMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    requestMigrationChooseRealmChoseRealm: (attributes: MigrationAttributes & { region: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'choseRealm',
        source: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28101',
    }),
    requestMigrationChooseRealmSelect: (attributes: MigrationAttributes & { region: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.SelectItem,
        actionSubjectId: 'chooseRealmSelect',
        source: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55539',
    }),

    realmConfirmationModalConfirm: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: DataResidencyScreens.realmConfirmationModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58999',
    }),

    realmConfirmationModalCancel: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: DataResidencyScreens.realmConfirmationModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59000',
    }),

    requestMigrationChooseRealmScreenReview: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'review',
        source: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24840',
    }),

    requestMigrationChooseRealmScreenBack: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),

    requestMigrationChooseRealmScreenWhatIsRealm: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'whatIsRealm',
        source: DataResidencyScreens.requestMigrationChooseRealmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28102',
    }),

    requestMigrationReviewScreenSelectPreferredDate: (attributes: MigrationAttributes & { date: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Datepicker,
        actionSubjectId: 'preferredDate',
        source: DataResidencyScreens.requestMigrationReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28056',
    }),

    requestMigrationReviewScreenBack: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source: DataResidencyScreens.requestMigrationReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),

    requestMigrationSelectWindowScreenBack: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source: DataResidencyScreens.requestMigrationSelectWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),

    requestMigrationReviewAppsScreenNext: (attributes: MigrationAttributes & { windowType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextReviewApps',
        source: DataResidencyScreens.requestMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61962',
    }),

    requestMigrationReviewAppsScreenBack: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source: DataResidencyScreens.requestMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),

    requestMigrationSelectWindowScreenNext: (attributes: MigrationAttributes & { windowType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextSelectWindowNew',
        source: DataResidencyScreens.requestMigrationSelectWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59101',
    }),

    requestMigrationReviewScreenRequestMove: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'requestMove',
        source: DataResidencyScreens.requestMigrationReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28053',
    }),

    requestMigrationReviewScreenSubmitMove: (attributes: MigrationAttributes & { isSafeMigration: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submit',
        source: DataResidencyScreens.requestMigrationReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36669',
    }),

    requestMigrationSelectWindowScreenViewApps: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewApps',
        source: DataResidencyScreens.requestMigrationSelectWindowNewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62027',
    }),

    viewTicket: (attributes: MigrationAttributes & { ticketLink: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewTicket',
        source: DataResidencyScreens.dataResidencyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28262',
    }),

    ogDaReLearnMore: (attributes: MigrationAttributes & { ticketLink: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'ogDaReLearnMore',
        source: DataResidencyScreens.requestMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48724',
    }),

    applyFiltersMainTable: (attributes: {
      locationFilter: boolean;
      searchFilter: boolean;
      productFilter: boolean;
      statusFilter: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dareMainTableApplyFilter',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72531',
    }),

    rovoPinInPlaceRequestSubmitted: (attributes: {
      newRealm: RealmTypes;
      refProductType: UiProductType;
      cloudId: string;
      product: UiProductType;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rovoPinInPlaceRequestSubmit',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75746',
    }),

    pinInPlaceRequestSubmitted: (attributes: { newRealm: RealmTypes; refProductType: UiProductType; ari: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitPinInPlaceRequest',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80012',
    }),
  },

  track: {
    dataResidencyChanged: (attributes: DataResidencyChangedAttributes & { isSandbox?: boolean; isSafeMigration: boolean }): TrackEvent => {
      const { product, oldRegion, newRegion, dateStart, isSandbox, isSafeMigration } = attributes;

      return {
        data: {
          action: TrackAction.Changed,
          actionSubject: TrackActionSubject.DataResidency,
          actionSubjectId: attributes.siteId,
          source: DataResidencyScreens.requestMigrationReviewScreen,
          attributes: {
            oldRegion,
            newRegion,
            dateStart,
            product,
            isSandbox,
            isSafeMigration,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28103',
      };
    },

    dataResidencyCanceled: (
      attributes: MigrationAttributes & { isSandbox?: boolean; siteId: string; migrationType: OrgPolicyResourceMigrationType },
    ): TrackEvent => {
      const { product, isSandbox, siteId, migrationType } = attributes;

      return {
        data: {
          action: TrackAction.Canceled,
          actionSubject: TrackActionSubject.DataResidency,
          actionSubjectId: siteId,
          source: DataResidencyScreens.dataResidencyScreen,
          attributes: {
            product,
            isSandbox,
            migrationType,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36670',
      };
    },
  },

  operational: {
    featureExposed: (attributes: {
      cloudId: string;
      product: string;
      orgId: string;
      edition: string;
      isSelfServeOn: boolean;
      maxEstimatedDowntime: number;
      totalApps: number;
      movableApps: number;
      inEligibleApps: number;
      pinnedApps: number;
      appMigrationEnabled: boolean;
    }): OperationalEvent => {
      const {
        cloudId,
        product,
        orgId,
        edition,
        isSelfServeOn,
        maxEstimatedDowntime,
        totalApps,
        movableApps,
        inEligibleApps,
        pinnedApps,
        appMigrationEnabled,
      } = attributes;

      return {
        data: {
          action: OperationalAction.Exposed,
          actionSubject: OperationalSubject.Feature,
          source: DataResidencyScreens.dataResidencyScreen,
          attributes: {
            cloudId,
            product,
            edition,
            isSelfServeOn,
            maxEstimatedDowntime,
            flagKey: 'data-residency-self-serve-eligibility-analysis',
            experimentName: 'Self serve',
            growthInitiative: 'virality',
            movableApps,
            pinnedApps,
            inEligibleApps,
            totalApps,
            appMigrationEnabled,
          },
        },
        orgId,
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/21022',
      };
    },
  },
};
