/* eslint-disable @typescript-eslint/no-namespace */
import type { ReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';

import type { UiProductType, UserRequestTypes } from '@adminhub/graphql-types';

export namespace Navigation {
  export enum DrawerType {
    NONE = 'NONE',
    IDM = 'IDM',
    IDM_NO_REDIRECT = 'IDM_NO_REDIRECT',
    SAML = 'SAML',
    USER_PROVISIONING_CREATE_DIRECTORY = 'USER_PROVISIONING_CREATE_DIRECTORY',
    USER_PROVISIONING_REGEN_API_KEY = 'USER_PROVISIONING_REGEN_API_KEY',
    CREATE_ADMIN_API_KEY_DRAWER = 'CREATE_ADMIN_API_KEY_DRAWER',
    CREATE_ADMIN_API_KEY_DRAWER_NEW = 'CREATE_ADMIN_API_KEY_DRAWER_NEW',
    CREATE_SERVICE_ACCOUNT_DRAWER = 'CREATE_SERVICE_ACCOUNT_DRAWER',
    CREATE_API_TOKEN_DRAWER_NEW = 'CREATE_API_TOKEN_DRAWER_NEW',
    ADD_PRODUCT_DRAWER = 'ADD_PRODUCT_DRAWER',
    SETUP_PRODUCT_DRAWER = 'SETUP_PRODUCT_DRAWER',
    SELECTIVE_DOMAIN_CLAIM_DRAWER = 'SELECTIVE_DOMAIN_CLAIM_DRAWER',
    ADD_DOMAIN_DRAWER = 'ADD_DOMAIN_DRAWER',
    SITE_CREATE = 'SITE_CREATE',
    INVITE_USER = 'INVITE_USER',
    INVITE_EXCO_USER = 'INVITE_EXCO_USER',
    EXPORT_USERS = 'EXPORT_USERS',
    G_SUITE_WIZARD = 'G_SUITE_WIZARD',
    ORG_G_SUITE_IN_ACCESS_WIZARD = 'ORG_G_SUITE_IN_ACCESS_WIZARD',
    ORG_INVITE_USER = 'ORG_INVITE_USER',
    ORG_SITE_IMPORT = 'ORG_SITE_IMPORT',
    PRODUCTS_TRANSFER = 'PRODUCTS_TRANSFER',
    OKTA_CLOUD_CONNECT = 'OKTA_CLOUD_CONNECT',
    ATLASSIAN_SWITCHER = 'ATLASSIAN_SWITCHER',
    IDP_ADD_SAML = 'IDP_ADD_SAML',
    IDP_ADD_SCIM = 'IDP_ADD_SCIM',
    JSM_IDP_ADD_SAML = 'JSM_IDP_ADD_SAML',
    JSM_IDP_ADD_SCIM = 'JSM_IDP_ADD_SCIM',
    ORG_O_SYNC_DRAWER = 'ORG_O_SYNC_DRAWER',
    LINK_PRODUCTS_DRAWER = 'LINK_PRODUCTS_DRAWER',
    SITELESS_ADD_PRODUCT_DRAWER = 'SITELESS_ADD_PRODUCT_DRAWER',
    COLLECTIONS_ADD_PRODUCT_DRAWER = 'COLLECTIONS_ADD_PRODUCT_DRAWER',
    ADD_ATLASSIAN_INTELLIGENCE = 'ADD_ATLASSIAN_INTELLIGENCE',
    ADD_TOWNSQUARE = 'ADD_TOWNSQUARE',
    EXTERNAL_USER_SECURITY_ADD_TEST_USERS = 'EXTERNAL_USER_SECURITY_ADD_TEST_USERS',
    DATA_CLASSIFICATION_EDIT_DRAWER = 'DATA_CLASSIFICATION_EDIT_DRAWER',
    DATA_CLASSIFICATION_DRAWER = 'DATA_CLASSIFICATION_DRAWER',
    BYOK_ADD_PRODUCT_DRAWER = 'BYOK_ADD_PRODUCT_DRAWER',
    ADD_SINGLE_PRODUCT_DRAWER = 'ADD_SINGLE_PRODUCT_DRAWER',
    AGP_ONBOARDING_SITE_SELECTION = 'AGP_ONBOARDING_SITE_SELECTION',
  }

  export enum ModalType {
    NONE = 'NONE',
    AGP_ONBOARDING_PREMIUM_EDITIONS = 'AGP_ONBOARDING_PREMIUM_EDITIONS',
    AGP_ONBOARDING_PREMIUM_TRIAL = 'AGP_ONBOARDING_PREMIUM_TRIAL',
    AGP_ONBOARDING_CONTACT_REP = 'AGP_ONBOARDING_CONTACT_REP',
    AGP_ONBOARDING_NO_TRIAL = 'AGP_ONBOARDING_NO_TRIAL',
  }

  export type LeftSidebarViewId =
    | 'overview'
    | 'directory'
    | 'security'
    | 'privacy'
    | 'billing'
    | 'settings'
    | 'siteBilling'
    | 'site'
    | 'products';

  export type ShowContainer = ReactiveVar<boolean>;
  export type ActiveDrawer = ReactiveVar<DrawerType>;
  export type ActiveModal = ReactiveVar<ModalType>;
  export type SelectedOrgId = ReactiveVar<string | undefined>;
  export type SelectedUserId = ReactiveVar<string>;
  export type SelectedDirectoryId = ReactiveVar<string>;
  export type RedirectOrgRoute = ReactiveVar<string | null>;
  export type SelectedClassificationLevelId = ReactiveVar<string | undefined>;
  export type isDataClassificationOnboarding = ReactiveVar<boolean>;
  export type ActiveLeftSidebarView = ReactiveVar<LeftSidebarViewId | null>;
  export type ActiveRovoTab = ReactiveVar<number>;
  export type AddProductFromTaskVar = ReactiveVar<AddProductFromTask | undefined>;
  export type UserRequestsInterestSidebar = ReactiveVar<UserRequestInterestData | null>;
  export type AtlassianIntelligenceContextRV = ReactiveVar<unknown | null>;

  export interface NavigationReactiveVariables {
    activeDrawer: ActiveDrawer;
    activeModal: ActiveModal;
    selectedOrgId: SelectedOrgId;
    selectedUserId: SelectedUserId;
    redirectOrgRoute: RedirectOrgRoute;
    selectedDirectoryId: SelectedDirectoryId;
    selectedClassificationLevelId: SelectedClassificationLevelId;
    isDataClassificationOnboarding: isDataClassificationOnboarding;
    showLeftSidebarContainer: ShowContainer;
    activeLeftSidebarView: ActiveLeftSidebarView;
    showRightSidebarContainer: ShowContainer;
    addProductFromTask: AddProductFromTaskVar;
    userRequestsInterestSidebar: UserRequestsInterestSidebar;
    atlassianIntelligenceContext: AtlassianIntelligenceContextRV;
  }

  interface ShowTrialAccessDrawerArgs {
    orgId: string;
    redirectOrgRoute: string;
  }

  interface ShowEditClassificationLevelDrawerArgs {
    orgId: string;
    levelId: string;
  }

  interface AddProductFromTask {
    productType: UiProductType;
    productRequestId: string;
    changedAt: number;
    requestorName: string;
    requestorEmail: string;
    avatar: string;
  }

  interface UserRequestInterestData {
    id: string;
    type: UserRequestTypes;
    createdAt: number | null;
    changedAt: number | null;
    requestedEntity: string;
    targetEntity: string;
    requestCount: string;
    metadata: {
      product: {
        key: string;
        name: string | null;
        url: string | null;
      };
      marketplaceAppInfo: {
        marketplaceApp: {
          id: string | null;
          key: string;
          name: string;
          description: string;
          url: string | null;
          logoUrl: string | null;
        } | null;
        vendor: {
          id: string;
          name: string;
          makerId: string;
          link: string;
        } | null;
        ratings: {
          avgStars: number;
          numRatings: number;
        } | null;
        distribution: {
          installs: number;
        } | null;
        programs: string[] | null;
      } | null;
    };
  }

  export const reactiveVariables: Readonly<NavigationReactiveVariables> = {
    activeDrawer: makeVar<DrawerType>(DrawerType.NONE),
    activeModal: makeVar<ModalType>(ModalType.NONE),
    selectedOrgId: makeVar<string | undefined>(undefined),
    selectedUserId: makeVar<string>(''),
    redirectOrgRoute: makeVar<string | null>(null),
    selectedDirectoryId: makeVar<string>(''),
    selectedClassificationLevelId: makeVar<string | undefined>(undefined),
    isDataClassificationOnboarding: makeVar<boolean>(false),
    showLeftSidebarContainer: makeVar<boolean>(false),
    showRightSidebarContainer: makeVar<boolean>(false),
    activeLeftSidebarView: makeVar<LeftSidebarViewId | null>(null),
    addProductFromTask: makeVar<AddProductFromTask | undefined>(undefined),
    userRequestsInterestSidebar: makeVar<UserRequestInterestData | null>(null),
    atlassianIntelligenceContext: makeVar<unknown | null>(null),
  };

  export const closeTrialAccessDrawer = () => {
    reactiveVariables.activeDrawer(DrawerType.NONE);
    reactiveVariables.selectedOrgId(undefined);
    reactiveVariables.redirectOrgRoute(null);
  };

  export const showTrialAccessDrawer = ({ orgId, redirectOrgRoute }: ShowTrialAccessDrawerArgs) => {
    reactiveVariables.activeDrawer(DrawerType.IDM);
    reactiveVariables.selectedOrgId(orgId);
    reactiveVariables.redirectOrgRoute(redirectOrgRoute);
  };

  export const showResetApiTokenDrawer = ({ externalDirectoryId }: { externalDirectoryId: string }) => {
    reactiveVariables.activeDrawer(DrawerType.USER_PROVISIONING_REGEN_API_KEY);
    reactiveVariables.selectedDirectoryId(externalDirectoryId);
  };

  export const showEditClassificationDrawer = ({ orgId, levelId }: ShowEditClassificationLevelDrawerArgs) => {
    reactiveVariables.activeDrawer(DrawerType.DATA_CLASSIFICATION_EDIT_DRAWER);
    reactiveVariables.selectedOrgId(orgId);
    reactiveVariables.selectedClassificationLevelId(levelId);
  };

  export const showDataClassificationDrawer = ({ showOnboarding }: { showOnboarding: boolean }) => {
    reactiveVariables.isDataClassificationOnboarding(showOnboarding);
    reactiveVariables.activeDrawer(DrawerType.DATA_CLASSIFICATION_DRAWER);
  };

  export const showAddSingleProductDrawer = (addProductFromTask: AddProductFromTask) => {
    reactiveVariables.activeDrawer(DrawerType.ADD_SINGLE_PRODUCT_DRAWER);
    reactiveVariables.addProductFromTask(addProductFromTask);
  };

  export const showUserRequestsInterestSidebar = (interestData: UserRequestInterestData) => {
    reactiveVariables.showRightSidebarContainer(true);
    reactiveVariables.userRequestsInterestSidebar(interestData);
  };
}
