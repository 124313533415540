import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export const organizationOverviewScreen = 'organizationOverviewScreen';
export const organizationOverviewAddPaymentAction = 'organizationOverviewAddPaymentAction';
export const overviewPageForReviewSection = 'overviewPageForReviewSection';

type ScreenKeys = 'organizationOverviewScreenEvent' | 'addPaymentDetailsScreen' | 'forReviewSection' | 'userLimitUpgradeScreen';

type UiKeys =
  | 'giveFeedbackButton'
  | 'quickActionButton'
  | 'giveFeedbackLink'
  | 'viewDomainsSuccessFlag'
  | 'refreshChartButton'
  | 'discoverCard'
  | 'addPaymentDetails'
  | 'toggleMoreActions'
  | 'upgradePlan';

export const orgOverviewEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4063866580/Org+Overview+M1+Analytics',
  screen: {
    organizationOverviewScreenEvent: (attributes: {
      uasDiscoveryCardExperimentCohort: string;
      ctaExperimentCohort: string;
      shuffleCtasBoxesExperimentCohort: string;
    }): ScreenEvent => ({
      data: {
        name: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71220',
    }),
    addPaymentDetailsScreen: (attributes: Record<string, unknown>): ScreenEvent => ({
      data: {
        name: organizationOverviewAddPaymentAction,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74567',
    }),
    userLimitUpgradeScreen: (attributes: Record<string, unknown>): ScreenEvent => ({
      data: {
        name: 'organizationOverviewUserLimitUpgradeAction',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75150',
    }),
    forReviewSection: (attributes: Record<string, unknown>): ScreenEvent => ({
      data: {
        name: overviewPageForReviewSection,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74566',
    }),
  },
  ui: {
    giveFeedbackButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'giveFeedback',
        source: organizationOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71223',
    }),
    giveFeedbackLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'giveFeedback',
        source: organizationOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71325',
    }),
    quickActionButton: (attributes: { actionType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'quickAction',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72480',
    }),
    viewDomainsSuccessFlag: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewDomainsSuccessFlag',
        source: organizationOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71225',
    }),
    refreshChartButton: (attributes: { reportId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'refreshChart',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71226',
    }),
    discoverCard: (attributes: { cardType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'discoverCard',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71227',
    }),
    addPaymentDetails: (attributes: Record<string, unknown>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'overviewPageAddPayment',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74568',
    }),
    upgradePlan: (attributes: Record<string, unknown>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'overviewPageUpgradePlan',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75149',
    }),
    toggleMoreActions: (attributes: Record<string, unknown>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'overviewPageToggleMoreActions',
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74569',
    }),
  },
  track: {
    featureExposed: (attributes: Record<string, unknown>): TrackEvent => ({
      data: {
        action: TrackAction.Exposed,
        actionSubject: TrackActionSubject.Feature,
        source: organizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14797',
    }),
  },
  operational: {},
};
