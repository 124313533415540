import type { ReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';

export namespace Analytics {
  export enum ReferrerType {
    DIRECT = 'direct',
    BREADCRUMB = 'breadcrumb',
    CREATE_GROUP_DIALOG = 'createGroupDialog',
    GROUPS_TABLE_LINK = 'groupsTableLink',
    APP_DETAILS_LINK = 'appDetailsLink',
    NAVIGATION = 'navigation',
    USER_PROVISIONING_SCREEN = 'userProvisioningScreen',
    LAUNCHPAD = 'launchpad',
    PASSWORD = 'password',
    SAML = 'saml',
    TWO_STEP = 'twoStep',
    ORG_OVERVIEW = 'orgOverview',
  }

  export type Referrer = ReactiveVar<ReferrerType>;

  export interface AnalyticsReactiveVariables {
    referrer: Referrer;
  }

  export const reactiveVariables: Readonly<AnalyticsReactiveVariables> = {
    referrer: makeVar<ReferrerType>(ReferrerType.DIRECT),
  };
}
