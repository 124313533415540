import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'clickedCancelOptOut'
  | 'clickedSubmitOptOut'
  | 'clickLearnMoreAboutEapLink'
  | 'clickEapEnrollFirstSite'
  | 'clickEapEnrollAnotherSite'
  | 'expandWhySomeOfMySitesNotListed'
  | 'closeEapDrawer'
  | 'submitEapEnrollment'
  | 'eapOptOutButtonClicked'
  | 'whySomeOfMySitesNotListedLearnMoreClicked'
  | 'exploreGoalsSuccessFlagLinkClicked'
  | 'eapDrawerNextButtonClicked'
  | 'eapDrawerBackButtonClicked'
  | 'sitesNotListedEligibilitySupportTicketLinkClicked'
  | 'noEligibleSitesEligibilitySupportTicketLinkClicked';

type ScreenKeys = string;
type TrackEventKeys = string;
type OperationalEventKeys = string;

const townsquareScreen = 'townsquareScreen';

export const townsquareEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalEventKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/STATUS/pages/3614311869/EAP+Analytics',
  ui: {
    clickedCancelOptOut: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapOptOutModalCancel',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66062',
    }),
    clickedSubmitOptOut: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapOptOutModalSubmit',
        source: townsquareScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66060',
    }),
    clickLearnMoreAboutEapLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'townsquareEapLearnMore',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66058',
    }),
    clickEapEnrollFirstSite: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapEnrollFirstSite',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66063',
    }),
    clickEapEnrollAnotherSite: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapEnrollAnotherSite',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66065',
    }),
    expandWhySomeOfMySitesNotListed: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapWhySomeSitesNotListed',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66066',
    }),
    whySomeOfMySitesNotListedLearnMoreClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'townsquareEapWhySomeSitesNotListedLearnMore',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66073',
    }),
    closeEapDrawer: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapDrawerClose',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66068',
    }),
    eapDrawerNextButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapDrawerNext',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66082',
    }),
    eapDrawerBackButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapDrawerBack',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66081',
    }),
    submitEapEnrollment: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareSubmitEapEnrollment',
        source: townsquareScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66071',
    }),
    exploreGoalsSuccessFlagLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'exploreGoalsSuccess',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66072',
    }),
    eapOptOutButtonClicked: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'townsquareEapOptOut',
        source: townsquareScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66057',
    }),
    sitesNotListedEligibilitySupportTicketLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'sitesNotListedEligibilitySupportTicketLink',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69751',
    }),
    noEligibleSitesEligibilitySupportTicketLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'noEligibleSitesEligibilitySupportTicketLink',
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69752',
    }),
  },
  screen: {
    townsquareScreen: (attributes: { eligibleSites: number; enrolledSites: number }): ScreenEvent => ({
      data: {
        name: 'townsquareScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66017',
    }),
  },
  track: {
    townsquareEapEnrollment: (attributes: { status: string; success: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Added,
        actionSubject: TrackActionSubject.Townsquare,
        source: townsquareScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/65950',
    }),
    townsquareEapOptOut: (): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.Townsquare,
        source: townsquareScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/65951',
    }),
  },
  operational: {},
};
