import * as React from 'react';

import type { ScreenEvent } from './new-analytics-types';
import { ScreenEventSender } from './screen-event-sender';

export interface ConditionalScreenEventSenderProps {
  event?: ScreenEvent;
  children: React.ReactElement;
}

export const ConditionalScreenEventSender: React.FC<ConditionalScreenEventSenderProps> = ({ event, children }) =>
  event ? <ScreenEventSender event={event}>{children}</ScreenEventSender> : children;
