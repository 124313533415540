import type { ScreenEvent, TrackEvent } from '../new-analytics-types';
import { TrackAction, TrackActionSubject } from '../new-analytics-types';
import type { accessRequestsTabScreen } from './site-access-event-data';
import type { UMSource } from './user-management-event-data';
import type { userRequestsScreenNameSource } from './user-requests-event-data';

const smartSettingsRecommendations = 'SmartSettingsRecommendations';
const recommendationTypeDirectAccess = 'DirectAccess';

export const smartSettingsEvents = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/PGT/pages/1301055450/Smart+Settings+Recommendations+4+DA+-+Analytics+spec',
  screen: {
    smartSettingsModal: (
      attributes: {
        domain: string;
        invitedUsersCount: number;
        suggestedProducts: { productId: string; productAri: string }[];
        triggerSource?: UMSource | typeof accessRequestsTabScreen | typeof userRequestsScreenNameSource.accessRequestsTab;
        daNudgeExpandCohort?: string;
        daExpandAttributionEligible?: boolean;
        iterationOnDaPromptCohort?: string;
      },
      orgId?: string,
    ): ScreenEvent => ({
      data: {
        name: smartSettingsRecommendations,
        attributes: {
          ...attributes,
          recommendationType: recommendationTypeDirectAccess,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39969',
      orgId,
    }),
  },
  track: {
    approvedDomainSaved: (
      attributes: {
        domain: string;
        invitedUsersCount: number;
        suggestedProducts: { productId: string; productAri: string }[];
        enabledProducts: { productId: string; productAri: string }[];
        triggerSource?: UMSource | typeof accessRequestsTabScreen | typeof userRequestsScreenNameSource.accessRequestsTab;
        iterationOnDaPromptCohort?: string;
      },
      orgId?: string,
    ): TrackEvent => ({
      data: {
        action: TrackAction.Saved,
        actionSubject: TrackActionSubject.ApprovedDomain,
        actionSubjectId: attributes.domain,
        source: smartSettingsRecommendations,
        attributes: {
          ...attributes,
          recommendationType: recommendationTypeDirectAccess,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34089',
      orgId,
    }),
  },
};
