// import AkFeatureGates from '@atlaskit/feature-gate-js-client';

import { UiProductType } from '@adminhub/graphql-types';

import type { ProductConfigMapJson } from '../types/product-config-schema';

export const config: ProductConfigMapJson = {
  products: {
    // TODO: Fix feature gated product names
    // TODO: Need to allow Atlassian Guard being renamed to AGS
    // ACCESS: {
    //   type: UiProductType.ACCESS,
    //   productName: 'Atlassian Guard Standard', // AkFeatureGates.checkGate('add_agp_to_workspaces_table') ? 'Atlassian Guard Standard' : 'Atlassian Guard',
    // },
    ANALYTICS: {
      type: UiProductType.ANALYTICS,
      productName: 'Atlassian Analytics',
      userManagementProductId: 'avp',
      idOrgManagerProductKey: 'avp',
      emauProductKey: 'atlassian_analytics',
      ariResourceOwner: 'avp',
      userManagementCanonicalProductKey: 'avp',
      releaseTrackProductKey: undefined,
      sandboxProductKey: undefined,
      hamsProductKey: undefined,
    },
    ATLASSIAN_TOGETHER: {
      type: UiProductType.ATLASSIAN_TOGETHER,
      productName: 'Atlassian Together',
      emauProductKey: 'atlassian_together',
      hamsProductKey: undefined,
    },
    AVOCADO: {
      type: UiProductType.AVOCADO,
      productName: 'Avocado',
      releaseTrackProductKey: 'Avocado',
      emauProductKey: undefined,
      hamsProductKey: undefined,
    },
    BEACON: {
      type: UiProductType.BEACON,
      productName: 'Guard Detect',
      hamsProductKey: undefined,
    },
    BITBUCKET: {
      type: UiProductType.BITBUCKET,
      productName: 'Bitbucket',
      hamsProductKey: undefined,
    },
    CLOUD_ADMIN: {
      type: UiProductType.CLOUD_ADMIN,
      productName: 'Atlassian Administration',
      ariResourceOwner: undefined,
      emauProductKey: undefined,
      sandboxProductKey: undefined,
      hamsProductKey: undefined,
    },
    COMPASS: {
      type: UiProductType.COMPASS,
      productName: 'Compass',
      hamsProductKey: undefined,
    },
    CONFLUENCE: {
      type: UiProductType.CONFLUENCE,
      productName: 'Confluence',
      userManagementProductId: 'conf',
      sandboxProductKey: 'confluence.ondemand',
      releaseTrackProductKey: 'Confluence',
      hamsProductKey: 'confluence.ondemand',
    },
    DEVAI: {
      type: UiProductType.DEVAI,
      productName: 'Rovo Dev Agents',
      hamsProductKey: undefined,
    },
    FABRIC: {
      type: UiProductType.FABRIC,
      productName: 'Fabric',
      userManagementProductId: 'canvas',
      idOrgManagerProductKey: 'canvas',
      ariResourceOwner: 'canvas',
      userManagementCanonicalProductKey: 'canvas',
      releaseTrackProductKey: 'canvas',
      sandboxProductKey: 'canvas',
      hamsProductKey: undefined,
    },
    GOAL: {
      type: UiProductType.GOAL,
      productName: 'Goals',
      hamsProductKey: undefined,
    },
    JIRA_ADMIN: {
      type: UiProductType.JIRA_ADMIN,
      productName: 'Jira Administration',
      ariResourceOwner: 'jira',
      userManagementCanonicalProductKey: undefined,
      idOrgManagerProductKey: undefined,
      sandboxProductKey: undefined,
      releaseTrackProductKey: undefined,
      emauProductKey: undefined,
      hamsProductKey: undefined,
    },
    JIRA_ALIGN: {
      type: UiProductType.JIRA_ALIGN,
      productName: 'Jira Align',
      emauProductKey: 'jira_align',
    },
    JIRA_CORE: {
      type: UiProductType.JIRA_CORE,
      productName: 'Jira Work Management',
      emauProductKey: 'jira_workManagement',
      releaseTrackProductKey: 'Jira',
      sandboxProductKey: 'jira-core.ondemand',
      hamsProductKey: undefined,
    },
    JIRA_CUSTOMER_SERVICE: {
      type: UiProductType.JIRA_CUSTOMER_SERVICE,
      productName: 'Jira Customer Service',
      emauProductKey: 'jira_customerService',
      releaseTrackProductKey: 'Jira',
      hamsProductKey: undefined,
    },
    // TODO: Fix feature gated product names
    // JIRA_FAMILY: {
    //   type: UiProductType.JIRA_FAMILY,
    //   productName: 'Jira Apps', // AkFeatureGates.checkGate('admin_hub_team_25_appification') ? 'Jira Apps' : 'Jira Products',
    //   userManagementProductId: 'jira-family',
    //   ariResourceOwner: undefined,
    //   userManagementCanonicalProductKey: undefined,
    //   emauProductKey: undefined,
    //   idOrgManagerProductKey: undefined,
    //   releaseTrackProductKey: undefined,
    //   sandboxProductKey: undefined,
    //   hamsProductKey: undefined,
    // },
    JIRA_INCIDENT_MANAGER: {
      type: UiProductType.JIRA_INCIDENT_MANAGER,
      productName: 'Jira Ops',
      idOrgManagerProductKey: 'jira-ops',
      emauProductKey: 'jira_ops',
      hamsProductKey: undefined,
    },
    JIRA_PRODUCT_DISCOVERY: {
      type: UiProductType.JIRA_PRODUCT_DISCOVERY,
      productName: 'Jira Product Discovery',
      emauProductKey: 'jira_productDiscovery',
      releaseTrackProductKey: 'Jira',
      hamsProductKey: undefined,
    },
    JIRA_SERVICE_DESK: {
      type: UiProductType.JIRA_SERVICE_DESK,
      productName: 'Jira Service Management',
      userManagementProductId: 'jira-servicedesk',
      emauProductKey: 'jira_serviceManagement',
      ariResourceOwner: 'jira-servicedesk',
      userManagementCanonicalProductKey: 'jira-servicedesk',
      releaseTrackProductKey: 'Jira',
      sandboxProductKey: 'jira-servicedesk.ondemand',
      hamsProductKey: undefined,
    },
    JIRA_SERVICE_MANAGEMENT: {
      type: UiProductType.JIRA_SERVICE_MANAGEMENT,
      productName: 'Jira Service Management',
      userManagementProductId: 'jira-servicedesk',
      idOrgManagerProductKey: 'jira-service-desk',
      emauProductKey: 'jira_serviceManagement',
      ariResourceOwner: 'jira-servicedesk',
      releaseTrackProductKey: undefined,
      sandboxProductKey: undefined,
      hamsProductKey: undefined,
    },
    JIRA_SOFTWARE: {
      type: UiProductType.JIRA_SOFTWARE,
      productName: 'Jira',
      emauProductKey: 'jira_software',
      releaseTrackProductKey: 'Jira',
      sandboxProductKey: 'jira-software.ondemand',
      hamsProductKey: undefined,
    },
    JIRA_WORK_MANAGEMENT: {
      type: UiProductType.JIRA_WORK_MANAGEMENT,
      productName: 'Jira Work Management',
      idOrgManagerProductKey: 'jira-core',
      emauProductKey: 'jira_workManagement',
      ariResourceOwner: 'jira-core',
      releaseTrackProductKey: undefined,
      sandboxProductKey: undefined,
      hamsProductKey: undefined,
    },
    LOOM: {
      type: UiProductType.LOOM,
      productName: 'Loom',
      hamsProductKey: undefined,
    },
    LOOM_ATTRIBUTION: {
      type: UiProductType.LOOM_ATTRIBUTION,
      productName: 'Loom Attribution',
      userManagementProductId: 'loom-attribution',
      idOrgManagerProductKey: undefined,
      emauProductKey: undefined,
      ariResourceOwner: undefined,
      userManagementCanonicalProductKey: undefined,
      releaseTrackProductKey: undefined,
      sandboxProductKey: undefined,
      hamsProductKey: undefined,
    },
    MERCURY: {
      type: UiProductType.MERCURY,
      productName: 'Focus',
      emauProductKey: undefined,
      hamsProductKey: undefined,
    },
    OPSGENIE: {
      type: UiProductType.OPSGENIE,
      productName: 'Opsgenie',
      hamsProductKey: undefined,
    },
    PASSIONFRUIT: {
      type: UiProductType.PASSIONFRUIT,
      productName: 'Passionfruit',
      hamsProductKey: undefined,
    },
    PROJECT: {
      type: UiProductType.PROJECT,
      productName: 'Projects',
      hamsProductKey: undefined,
    },
    ROVO: {
      type: UiProductType.ROVO,
      productName: 'Atlassian Rovo',
      sandboxProductKey: 'rovo.ondemand',
      hamsProductKey: undefined,
    },
    RADAR: {
      type: UiProductType.RADAR,
      productName: 'Talent',
      hamsProductKey: undefined,
    },
    STATUSPAGE: {
      type: UiProductType.STATUSPAGE,
      productName: 'Statuspage',
      emauProductKey: 'statuspage_manage',
      hamsProductKey: undefined,
    },
    STRIDE: {
      type: UiProductType.STRIDE,
      productName: 'Stride',
      userManagementProductId: 'hipchat.cloud',
      ariResourceOwner: 'hipchat.cloud',
      userManagementCanonicalProductKey: 'chat',
      releaseTrackProductKey: 'chat',
      sandboxProductKey: 'chat',
      hamsProductKey: undefined,
    },
    TEAMCENTRAL: {
      type: UiProductType.TEAMCENTRAL,
      productName: 'Atlas',
      userManagementProductId: 'townsquare',
      idOrgManagerProductKey: 'townsquare',
      emauProductKey: 'atlas',
      ariResourceOwner: 'townsquare',
      userManagementCanonicalProductKey: 'townsquare',
      releaseTrackProductKey: 'townsquare',
      sandboxProductKey: 'townsquare',
      hamsProductKey: undefined,
    },
    TRELLO: {
      type: UiProductType.TRELLO,
      productName: 'Trello',
      hamsProductKey: undefined,
    },
    UNIFIED_HELP: {
      type: UiProductType.UNIFIED_HELP,
      productName: 'Unified Help',
      emauProductKey: undefined,
      hamsProductKey: undefined,
    },

    // TODO: Fix feature gated product names
    // TODO: Fix Unknown Atlassian config
    // UNKNOWN_ATLASSIAN: {
    //   type: UiProductType.UNKNOWN_ATLASSIAN,
    //   productName: 'Unknown Atlassian app', // AkFeatureGates.checkGate('admin_hub_team_25_appification') ? 'Unknown Atlassian app' : 'Unknown Atlassian product',
    //   userManagementProductId: 'unknown-atlassian',
    //   idOrgManagerProductKey: undefined,
    //   emauProductKey: undefined,
    //   ariResourceOwner: undefined,
    //   userManagementCanonicalProductKey: undefined,
    //   releaseTrackProductKey: undefined,
    //   sandboxProductKey: undefined,
    //   hamsProductKey: undefined,
    // },
  },
};
