import type { FeatureEvents, ScreenEvent } from '../new-analytics-types';

type ScreenKeys = 'manageAppsInstallationControlsComponentScreenEvent';
// These are just 'string' for now because FeatureEvents requires 4 typed arguments.
// For the events which names aren't decided yet, or the event types that we're not using.
type UIEventKeys = string;
type TrackEventKeys = string;
type OperationalKeys = string;

export const manageAppsScreens = {
  manageAppsInstallationControlsComponent: 'manageAppsInstallationControlsComponent',
};

export const manageAppsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1660327532/Manage+Apps+-+Analytics+Spec',
  screen: {
    manageAppsInstallationControlsComponentScreenEvent: (attributes: { cloudId: string }): ScreenEvent => ({
      cloudId: attributes.cloudId,
      data: {
        name: manageAppsScreens.manageAppsInstallationControlsComponent,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43640',
    }),
  },
  ui: {},
  track: {},
  operational: {},
};
