import { Action, ActionSubject } from '../new-analytics-types';

export type Cohort = boolean;

const CONTACTS_PAGE_SOURCE = 'contactsPage';

const ScreenEventNames = {
  contactsPage: CONTACTS_PAGE_SOURCE,
};

const UIEventSources = {
  contactsPage: CONTACTS_PAGE_SOURCE,
};

export const contactsEvents = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/PGT/pages/1724458860/Control+settings+for+3rd+party+invites+-+Analytics+specification',
  screen: {
    contactsPageScreenViewed: () => ({
      data: {
        name: ScreenEventNames.contactsPage,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45705',
    }),
  },
  ui: {
    thirdPartyInvitesToggleChanged: (attributes?: { newState: boolean }) => ({
      data: {
        source: UIEventSources.contactsPage,
        action: Action.Toggled,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'thirdPartyIntegrations',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46214',
    }),
    learnMoreInviteContactsLinkClick: () => ({
      data: {
        source: UIEventSources.contactsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreInviteContacts',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57887',
    }),
  },
};
