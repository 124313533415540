export enum RouteNames {
  CLAIM_APP = 'claim-app-page',
  MASTER_ORG_LANDING = 'master-org-landing-page',
  ORG_DETAILS_PAGE = 'org-details-page',
  ADMIN_PAGE = 'admin-page',
  AUTH_POLICY_PAGE = 'authentication-policies-page',
  API_TOKENS_PAGE = 'api-tokens-page',
  DOMAIN_CLAIM_DETAILS_PAGE = 'domain-claim-details-page',
  DOMAIN_CLAIM_DETAILS_VORTEX = 'domain-claim-details-page-vortex',
  DOMAIN_CLAIM_VORTEX = 'domain-claim-page-vortex',
  DOMAIN_CLAIM = 'domain-claim-page',
  USER_PROVISIONING_VORTEX = 'user-provisioning-page-vortex',
  CLAIM_PAGE = 'claim-page',
  MEMBER_PAGE = 'member-page',
  MEMBER_DETAIL_PAGE = 'member-detail-page',
  AUDIT_LOG_EXPORT_DOWNLOAD = 'audit-log-export-download-page',
  AUDIT_LOG_ACTIVITIES_PAGE = 'audit-logs-activity-settings-page',
  EXPORT_DOWNLOAD = 'export-download-page',
  GROUP_DETAILS_PAGE = 'group-details-page',
  SERVICE_ACCOUNTS_PAGE = 'service-accounts-page',
  USERS_PAGE = 'unified-users-page',
  USER_DETAILS_PAGE = 'user-details-page',
  UNIFIED_USER_PROFILE_PAGE = 'unified-user-profile-page',
  USER_EXPORT_DOWNLOAD_PAGE = 'user-export-download-page',
  COMPLIANCE_PAGE = 'compliance-page',
  ROVO_PAGE = 'rovo-page',
  MIGRATION_DATA_MANAGEMENT_PAGE = 'migration-data-management-page',
  COMPLIANCE_BAA_FEATURE_GATE_PAGE = 'compliance-baa-feature-gate-page',
  SAML_PAGE = 'saml-page',
  GROUPS_PAGE = 'groups-page',
  DATA_CLASSIFICATION_DETAILS_PAGE = 'data-classification-details-page',
  DATA_CLASSIFICATION_SETTINGS_PAGE = 'data-classification-settings-page',
  DATA_CLASSIFICATION_PAGE = 'data-classification-page',
  DATA_SECURITY_POLICIES_DETAILS = 'data-security-policies-details-page',
  DATA_SECURITY_POLICIES = 'data-security-policies-page',
  USER_PROVISIONING_PAGE = 'user-provisioning-page',
  GSUITE_PAGE_VORTEX = 'g-suite-page-vortex',
  OSYNC_PAGE_VORTEX = 'osync-page-vortex',
  OSYNC_PAGE = 'osync-page',
  GSUITE_PAGE = 'g-suite-page',
  PRODUCTS_LIST = 'products-list-page',
  PRODUCTS_OVERVIEW = 'products-overview-page',
  USER_REQUESTS_PAGE = 'user-requests-page',
  LINK_ON_PREM_PRODUCTS_DASHBOARD = 'link-on-prem-products-dashboard-page',
  DATA_TRANSFER_PAGE = 'data-transfer-page',
  CONTACTS_PAGE = 'contacts-page',
  USER_COUNTS_DETAILS = 'user-counts-details-page',
  USER_TIER_DETAILS = 'user-tier-details-page',
  MOBILE_SECURITY_SETTINGS = 'mobile-security-settings-page',
  USER_ACCESS_SETTINGS = 'user-access-settings-page',
  DISCOVERED_PRODUCTS = 'discovered-products-page',
  SECURE_TUNNELS_PAGE = 'secure-tunnels-page',
  EMAILS_PAGE = 'emails-page',
  BILLING_PREVIEW_PAGE = 'billing-preview-page',
  MOBILE_SECURITY_PAGE = 'mobile-security-page',
  USER_TIER_PAGE = 'user-tier-page',
  BRIE_DASHBOARD_PAGE = 'brie-dashboard-page',
  BRIE_POLICY_DETAILS_PAGE = 'brie-policy-details-page',
  BRIE_CREATE_POLICY_PAGE = 'brie-create-policy-page',
  PRODUCT_REQUESTS_SETTING_PAGE = 'product-requests-setting-page',
  ATLASSIAN_INTELLIGENCE_PAGE = 'atlassian-intelligence-page',
  TOWNSQUARE_PAGE = 'townsquare-page',
  COMPLIANCE_MANAGE_PRODUCT_PAGE = 'compliance-manage-product-page',
  PRODUCT_REQUESTS_PAGE = 'product-requests-page',
  AUDIT_LOG_PAGE = 'audit-log-page',
  ADMIN_API_PAGE = 'admin-api-page',
  API_KEYS_PAGE = 'api-keys-page',
  CUSTOM_DOMAINS_DETAILS_PAGE = 'custom-domains-details-page',
  SECURITY_CHECKLIST_PAGE = 'security-checklist-page',
  UGC_LANDING_PAGE = 'ugc-landing-page',
  RELEASE_TRACKS_PAGE = 'release-tracks-page',
  EXPERIMENT_CONTROLS_PAGE = 'experiment-controls-page',
  IDENTITY_PROVIDERS_PAGE = 'identity-providers-page',
  DATA_RESIDENCY_PAGE = 'data-residency-page',
  DATA_RESIDENCY_SETTINGS_PAGE = 'data-residency-settings-page',
  ENCRYPTION_PAGE = 'encryption-page',
  LINKS_MIGRATION_PAGE = 'links-migration-page',
  LINKS_MIGRATION_AUDIT_LOG_PAGE = 'links-migration-audit-log-page',
  C2C_MIGRATION_DASHBOARD_PAGE = 'cloud-to-cloud-migration-dashboard-page',
  MIGRATION_DASHBOARD_PAGE = 'migration-dashboard-page',
  MIGRATION_PLAN_SCOPE_DETAILS = 'migration-plan-scope-details',
  AUDIT_LOG_PAGE_SEARCH = 'audit-log-page-search',
  PRODUCT_URLS_PAGE = 'product-urls-page',
  INSIGHTS_PAGE = 'insights-page',
  IP_ALLOWLIST_PAGE = 'ip-allowlist-page',
  IDENTITY_PROVIDERS_DETAILS_PAGE = 'identity-providers-details-page',
  SANDBOX_PAGE = 'sandbox-page',
  AUTH_POLICIES_DETAILS_PAGE = 'authentication-policies-details-page',
  AUTH_POLICIES_PAGE = 'auth-policies-page',
  EXTERNAL_USER_SECURITY_PAGE_POLICY = 'external-user-security-page-policy',
  LOGIN_CUSTOMIZATION_PAGE = 'login-customisation-page',
  ADMINHUB_AUTOMATION_PAGE = 'adminhub-automation-page',
  EXTERNAL_USER_SECURITY_PAGE = 'external-user-security-page',
  PRODUCT_DETAILS_PAGE = 'product-details-page',
  DATA_RESIDENCY_APP_DETAILS_PAGE = 'data-residency-app-details-page',
  ENCRYPTION_POLICY_DETAILS = 'encryption-policy-details-page',
  C2C_MIGRATION_DETAILS_PAGE = 'cloud-to-cloud-migration-details-page',
  C2C_MIGRATION_CREATE_PAGE = 'cloud-to-cloud-migration-create-page',
  MIGRATION_CONTROL_CENTER_PAGE = 'migration-control-center-page',
  AVIATOR_ROUTER = 'aviator-router',
  PRODUCT_UPDATES = 'product-updates-routes',
  FREEZE_WINDOWS = 'freeze-windows-page',
  GSUITE_SETTINGS = 'g-suite-settings',
  GSUITE_SETUP = 'g-suite-setup',
  EXTERNAL_USERS_POLICY_USERS = 'external-users-policy-users',
  EXTERNAL_USERS_POLICY_ID = 'external-users-policy-id',
  EXTERNAL_USER_DEFAULT_POLICY = 'external-users-default-policy',
  EXTERNAL_USER_POLICY = 'external-users-policy',
  EXTERNAL_USER_DEFAULT = 'external-users-default',
  PRODUCT_UPDATES_CHANGELOG_DETAILS_PAGE = 'product-updates-changelog-details-page',
  PRODUCT_UPDATES_LIST_VIEW_PAGE = 'product-updates-list-view-page',
  IDENTITY_PROVIDERS_SAML_PAGE = 'identity-providers-saml-page',
  IDENTITY_PROVIDERS_SCIM_PAGE = 'identity-providers-scim-page',
  IDENTITY_PROVIDERS_GSUITE_PAGE = 'identity-providers-g-suite-page',
  IDENTITY_PROVIDERS_OSYNC_PAGE = 'identity-providers-osync-page',
  IDENTITY_PROVIDERS_GROUPS_PAGE = 'identity-providers-groups-page',
  IDENTITY_PROVIDERS_DOMAINS_PAGE = 'identity-providers-linked-domains-page',
  IDENTITY_PROVIDERS_AUTH_DETAILS_PAGE = 'identity-providers-authentication-policies-details-page',
  IDENTITY_PROVIDERS_AUTH_POLICIES_PAGE = 'identity-providers-authentication-policies-page',
  IDENTITY_PROVIDERS_AUDIT_LOG_PAGE = 'identity-providers-audit-log-page',
  JSM_IDENTITY_PROVIDERS_SAML_PAGE = 'jsm-identity-providers-saml-page',
  JSM_IDENTITY_PROVIDERS_SAML_DETAILS_PAGE = 'jsm-identity-providers-details-page',
  JSM_SCIM_PAGE = 'jsm-identity-providers-scim-page',
  JSM_PAGE = 'jsm-page',
  ACCESS_REQUESTS = 'access-requests-page',
  USER_CONNECTED_APPS = 'user-connected-apps-page',
  USER_CONNECTED_APPS_TAB = 'user-connected-apps-page-tab',
  USER_CONNECTED_APPS_DETAILS = 'user-connected-apps-details-page',
  USER_CONNECTED_APPS_PRODUCT_DETAILS = 'user-connected-apps-product-details',
  USER_CONNECTED_APPS_PRODUCT_DETAILS_TAB = 'user-connected-apps-product-details-tab',
  USER_CONNECTED_APPS_OAUTH = 'user-connected-apps-oauth',
  USER_CONNECTED_OAUTH_TAB = 'user-connected-apps-oauth-tab',
  SETUP_PAGE = 'setup-page',
  BILLING_PAGES = 'billing-pages',
  AVIATOR_ASSESSMENT = 'aviator-assessment-report-page',
  FOOTPRINT_INSIGHTS = 'footprint-insights-page',
  AVIATOR_SERVICE_HEALTH_DASHBOARD = 'aviator-service-health-dashboard',
  DISCOVERY_PAGE = 'discovery-page',
  UPDATE_PAYMENT_METHOD = 'update-payment-method-page',
  ADD_PAYMENT_DETAILS = 'add-payment-details-page',
  MONTHLY_TO_ANNUAL = 'monthly-to-annual-page',
  MIGRATION_DATA_SCOPE = 'migration-data-scope',
  MIGRATION_SXP_CREATE_MIGRATION = 'migration-sxp-create-migration',
  MIGRATION_PERMISSION_PAGE = 'migration-permission-error-page',
  CHANGE_EDITION_ENTITLEMENT = 'change-edition-entitlement',
  CHOOSE_ANNUAL_BILLING_CYCLE = 'choose-annual-billing-cycle',
  APP_DETAILS = 'app-details',

  // site routes
  SITE_GROUP_PAGE = 'site-groups-page',
  SITE_GROUP_DETAIL_PAGE = 'group-detail-page',
  SITE_USER_EXPORT_DOWNLOAD_PAGE = 'site-user-export-download-page',
  SITE_USERS = 'site-users-page',
  SITE_USER_DETAILS_PAGE = 'site-user-details-page',
  SITE_ACCESS_REQUESTS_PAGE = 'site-access-requests-page',
  SITE_ACCESS_CONFIGURATION_PAGE = 'access-configuration-page',
  SITE_JSM_PAGE = 'site-jsm-page',
  SITE_JSM_IDP_PAGE = 'jsm-idp-page',
  SITE_USER_CONNECTED_APPS_PAGE = 'site-user-connected-apps-page',
  SITE_USER_CONNECTED_APPS_SUBPAGE = 'site-user-connected-apps-subpage',
  SITE_USER_CONNECTED_APPS_DETAILS_PAGE = 'site-user-connected-apps-details-page',
  SITE_ACCESS_PAGE = 'access-page',
  // for ufo
  ACCESS_SITE = 'site-access-page',
  SITE_GSUITE_PAGE = 'gsuite-page',
  SITE_EMOJI_PAGE = 'emoji-page',
  SITE_STORAGE_PAGE = 'storage-page',
  SITE_SETUP_PAGE = 'site-setup-page',
  SITE_BILLING_PAGE = 'site-billing-pages',
  SITE_BILLING_PREVIEW_PAGE = 'site-billing-preview-page',
  SITE_GOALS_AND_PROJECTS_OPTOUT_PAGE = 'site-goals-and-projects-opt-out-page',
  // top routes
  TOP_ROUTE_DASHBOARD = 'dashboard',
  TOP_ROUTE_SHADOW_IT_REQUEST_GATE = 'shadow-it-request-gate',
  TOP_ROUTE_ORG_PICKER = 'org-picker',
  TOP_ROUTE_ROVO = 'rovo',
}
