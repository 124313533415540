import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export type ErrorVariation = 'cannotSyncError' | 'loadUserError' | 'productAccessError' | 'otherErrors';
export type ExpandView = 'unchanged' | 'removed' | 'added';

type ScreenKeys =
  | 'scimReviewGroupsScreenEvent'
  | 'scimReviewGroupsModalScreenEvent'
  | 'scimSyncAllModalScreenEvent'
  | 'scimExportUsersModalEvent';
type UiKeys =
  | 'reviewGroupsLinkClicked'
  | 'closeConflictingGroupsDrawerClicked'
  | 'tryAgainButtonClicked'
  | 'reviewButtonClicked'
  | 'learnAboutAccessGroupLinkClicked'
  | 'learnMoreConflictingGroupsLinkClicked'
  | 'expandButtonClicked'
  | 'syncButtonClicked'
  | 'cancelSyncButtonClicked'
  | 'loadMoreUsersButtonClicked'
  | 'userListTryAgainButtonClicked'
  | 'syncAllGroupsButtonClicked'
  | 'syncAllButtonClicked'
  | 'cancelSyncAllButtonClicked'
  | 'exportUsersButtonClicked'
  | 'cancelExportUsersButtonClicked';
type TrackKeys = 'syncedGroup' | 'syncedAllGroup' | 'exportedUsers';

const userProvisioningScreen = 'userProvisioningScreen';
const scimReviewGroupsScreen = 'scimReviewGroupsScreen';
const scimReviewGroupsModal = 'scimReviewGroupsModal';
const scimSyncAllModal = 'scimSyncAllModal';
const scimExportUsersModal = 'scimExportUsersModal';

export const scimEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/720564317/SCIM+-+Existing+Groups+Analytics+Spec',

  screen: {
    scimReviewGroupsScreenEvent: (attributes: { screenVariations: { hasError: boolean } }): ScreenEvent => ({
      data: {
        name: scimReviewGroupsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24945',
    }),
    scimReviewGroupsModalScreenEvent: (attributes: {
      screenVariations: { hasMultipleSites: boolean; errorType?: ErrorVariation };
    }): ScreenEvent => ({
      data: {
        name: scimReviewGroupsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24946',
    }),
    scimSyncAllModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: scimSyncAllModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24947',
    }),
    scimExportUsersModalEvent: (): ScreenEvent => ({
      data: {
        name: scimExportUsersModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27537',
    }),
  },

  ui: {
    closeConflictingGroupsDrawerClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeDrawer',
        source: scimReviewGroupsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),
    reviewGroupsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'reviewGroups',
        source: userProvisioningScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24839',
    }),
    tryAgainButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryAgain',
        source: scimReviewGroupsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24944',
    }),
    reviewButtonClicked: (attributes: { hasMultipleSites: boolean; scimGroupIds: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'review',
        source: scimReviewGroupsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24840',
    }),
    learnAboutAccessGroupLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreConflictingGroups',
        source: scimReviewGroupsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26800',
    }),
    learnMoreConflictingGroupsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreConflictingGroups',
        source: userProvisioningScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26800',
    }),
    expandButtonClicked: (attributes: { expand: boolean; numberOfUsers: number; view: ExpandView; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'expand',
        source: scimReviewGroupsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24842',
    }),
    syncButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sync',
        source: scimReviewGroupsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24843',
    }),
    cancelSyncButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: scimReviewGroupsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    loadMoreUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'loadMore',
        source: scimReviewGroupsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26408',
    }),
    userListTryAgainButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryAgain',
        source: scimReviewGroupsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24944',
    }),
    syncAllGroupsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'syncAllGroups',
        source: scimReviewGroupsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24844',
    }),
    syncAllButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'syncAll',
        source: scimSyncAllModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24845',
    }),
    cancelSyncAllButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: scimSyncAllModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    exportUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportUsers',
        source: scimExportUsersModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14634',
    }),
    cancelExportUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: scimExportUsersModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
  },

  track: {
    syncedGroup: (): TrackEvent => ({
      data: {
        action: TrackAction.Synced,
        actionSubject: TrackActionSubject.Group,
        actionSubjectId: 'user',
        source: scimReviewGroupsModal,
        attributes: {
          syncAll: false,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24846',
    }),
    syncedAllGroup: (attributes: { scimGroupIds: string[] }): TrackEvent => ({
      data: {
        action: TrackAction.Synced,
        actionSubject: TrackActionSubject.Group,
        actionSubjectId: 'user',
        source: scimSyncAllModal,
        attributes: {
          syncAll: true,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24846',
    }),
    exportedUsers: (): TrackEvent => ({
      data: {
        action: TrackAction.Exported,
        actionSubject: TrackActionSubject.Groups,
        actionSubjectId: 'users',
        source: scimExportUsersModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27539',
    }),
  },

  operational: {},
};
