import type { OrgPolicyResourceMigrationType, UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import { DataResidencyScreens } from './data-residency-event-data';

type ScreenKeys =
  | 'requestAppMigrationWhyScreen'
  | 'requestAppMigrationReviewAppsScreen'
  | 'requestAppMigrationSelectMoveWindowScreen'
  | 'requestAppMigrationSubmitRequestScreen'
  | 'dataResidencyViewDetailsScreen';

type UiKeys =
  | 'moveAppsAction'
  | 'cancelAction'
  | 'productStatus'
  | 'appStatus'
  | 'learnMoreAboutDataResidencyMovesForApps'
  | 'learnMoreDataResidencyForAppsClickEvent'
  | 'learnMoreAboutDataResidencyMovesPage'
  | 'nextRequestAppMigrationWhyScreen'
  | 'cancelActionRequestAppMigrationWhyScreen'
  | 'nextRequestAppMigrationCheckAppsScreen'
  | 'backRequestAppMigrationCheckAppsScreen'
  | 'expandAccordion'
  | 'collapseAccordion'
  | 'previousRequestAppMigrationCheckAppsScreen'
  | 'pageSize'
  | 'preferredDate'
  | 'nextRequestAppMigrationSelectWindowScreen'
  | 'previousRequestAppMigrationSelectWindowScreen'
  | 'submitRequest'
  | 'backRequestAppMigrationReviewScreen'
  | 'learnMoreAboutEligibleApps'
  | 'moveProductActionViewDetails'
  | 'moveAppsActionViewDetails'
  | 'manageAppsAction'
  | 'cancelActionViewDetailsScreen'
  | 'searchingApp'
  | 'nextDataResidencyViewDetailsScreen'
  | 'learnMoreAboutDataResidencyForAppsInsideTabs'
  | 'learnMoreAboutNoActionAvailableApps'
  | 'learnMoreAboutNoActionAvailableAppsOnReviewScreen'
  | 'learnMoreAboutDaReApps'
  | 'opsGenieDomainConflictInfoModal'
  | 'learnMoreAboutEligibleAppsDetails'
  | 'previousDataResidencyViewDetailsScreen'
  | 'tabType'
  | 'learnMoreAboutEligibleAppsRequestMigrationReviewScreen'
  | 'appDataStorageManagement'
  | 'upgradeToMoveClicked'
  | 'upgradeToMoveViewed';

type TrackKeys = 'dataResidencyChanged';

type AppsType = 'ON' | 'CAN_MOVE' | 'CANNOT_MOVE' | 'NO_ACTION_AVAILABLE';
type TabType = 'eligible' | 'pinned' | 'not_eligible' | 'no_action_available';

interface MigrationAttributes {
  cloudId: string;
  product: UiProductType;
}

interface DataResidencyChangedAttributes {
  product: string;
  dateStart: string;
  cloudId: string;
}

interface UpgradeToMoveAttributes {
  orgId: string;
  cloudId: string;
  product: UiProductType;
}

export enum DataResidencyAppScreens {
  requestAppMigrationWhyScreen = 'requestAppMigrationWhyScreen',
  requestAppMigrationReviewAppsScreen = 'requestAppMigrationReviewAppsScreen',
  requestAppMigrationSelectMoveWindowScreen = 'requestAppMigrationSelectMoveWindowScreen',
  requestAppMigrationSubmitRequestScreen = 'requestAppMigrationSubmitRequestScreen',
  dataResidencyViewDetailsScreen = 'dataResidencyViewDetailsScreen',
}

export const dataResidencyAppEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/~270518040/pages/2411172615/Apps+Data+Residency+Analytics+Spec',

  screen: {
    dataResidencyViewDetailsScreen: (attributes: MigrationAttributes & { link_source: string | null }): ScreenEvent => ({
      data: {
        name: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49969',
    }),

    requestAppMigrationSubmitRequestScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49951',
    }),

    requestAppMigrationSelectMoveWindowScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyAppScreens.requestAppMigrationSelectMoveWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49948',
    }),

    requestAppMigrationReviewAppsScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49947',
    }),
    requestAppMigrationWhyScreen: (attributes: MigrationAttributes): ScreenEvent => ({
      data: {
        name: DataResidencyAppScreens.requestAppMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49944',
    }),
  },

  ui: {
    moveAppsAction: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveAppsActionLandingPage',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50790',
    }),

    cancelAction: (attributes: MigrationAttributes & { migrationType: OrgPolicyResourceMigrationType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelAction',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50097',
    }),

    productStatus: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Icon,
        actionSubjectId: 'productStatus',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50131',
    }),

    appStatus: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Icon,
        actionSubjectId: 'appStatus',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50132',
    }),
    learnMoreAboutDataResidencyMovesForApps: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataResidencyMoves',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50098',
    }),
    learnMoreDataResidencyForAppsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreDataResidencyForApps',
        source: DataResidencyScreens.dataResidencyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57997',
    }),

    learnMoreAboutDataResidencyMovesPage: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataResidencyMovesPage',
        source: DataResidencyAppScreens.requestAppMigrationWhyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50099',
    }),

    nextRequestAppMigrationWhyScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextRequestAppMigrationWhyScreen',
        source: DataResidencyAppScreens.requestAppMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50102',
    }),

    cancelActionRequestAppMigrationWhyScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelActionRequestAppMigrationWhyScreen',
        source: DataResidencyAppScreens.requestAppMigrationWhyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50103',
    }),

    nextRequestAppMigrationCheckAppsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextRequestAppMigrationCheckAppsScreen',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50104',
    }),

    backRequestAppMigrationCheckAppsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backRequestAppMigrationCheckAppsScreen',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50105',
    }),

    expandAccordion: (attributes: MigrationAttributes & { appsType: AppsType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Accordion,
        actionSubjectId: 'expand',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50107',
    }),

    collapseAccordion: (attributes: MigrationAttributes & { appsType: AppsType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Accordion,
        actionSubjectId: 'collapse',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50108',
    }),

    previousRequestAppMigrationCheckAppsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'previousRequestAppMigrationCheckAppsScreen',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50110',
    }),

    pageSize: (attributes: MigrationAttributes & { appsType: AppsType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'pageSize',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50111',
    }),

    preferredDate: (attributes: MigrationAttributes & { date: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Datepicker,
        actionSubjectId: 'preferredDate',
        source: DataResidencyAppScreens.requestAppMigrationSelectMoveWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50112',
    }),

    nextRequestAppMigrationSelectWindowScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextRequestAppMigrationSelectWindowScreen',
        source: DataResidencyAppScreens.requestAppMigrationSelectMoveWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50113',
    }),

    previousRequestAppMigrationSelectWindowScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'previousRequestAppMigrationSelectWindowScreen',
        source: DataResidencyAppScreens.requestAppMigrationSelectMoveWindowScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50114',
    }),

    submitRequest: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitRequest',
        source: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50115',
    }),

    backRequestAppMigrationReviewScreen: (attributes: MigrationAttributes & { isSafeMigration: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backRequestAppMigrationReviewScreen',
        source: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50116',
    }),

    learnMoreAboutEligibleApps: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutEligibleApps',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50117',
    }),

    moveProductActionViewDetails: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveProductAction',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50118',
    }),

    moveAppsActionViewDetails: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveAppsAction',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50119',
    }),

    manageAppsAction: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageAppsAction',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50120',
    }),

    cancelActionViewDetailsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelActionViewDetailsScreen',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50121',
    }),

    learnMoreAboutEligibleAppsDetails: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataResidency',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50122',
    }),

    searchingApp: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.TextField,
        actionSubjectId: 'searchingApp',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50123',
    }),

    nextDataResidencyViewDetailsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextDataResidencyViewDetailsScreen',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50124',
    }),

    previousDataResidencyViewDetailsScreen: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'previousDataResidencyViewDetailsScreen',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50125',
    }),

    tabType: (attributes: MigrationAttributes & { tabType: TabType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Tab,
        actionSubjectId: 'tabType',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50126',
    }),

    learnMoreAboutDataResidencyForAppsInsideTabs: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataResidencyForAppsInsideTabs',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50127',
    }),

    learnMoreAboutEligibleAppsRequestMigrationReviewScreen: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutEligibleAppsRequestMigrationReviewScreen',
        source: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50128',
    }),

    appDataStorageManagement: (attributes: MigrationAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'appDataStorageManagement',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52955',
    }),

    upgradeToMoveClicked: (attributes: UpgradeToMoveAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'upgradeToMove',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55748',
    }),

    upgradeToMoveViewed: (attributes: UpgradeToMoveAttributes): UIEvent => ({
      data: {
        action: Action.Viewed,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'upgradeToMove',
        source: DataResidencyScreens.dataResidencyScreenWithApps,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55852',
    }),

    learnMoreAboutDaReApps: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataResidencyForApps',
        source: DataResidencyAppScreens.requestAppMigrationReviewAppsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58849',
    }),
    opsGenieDomainConflictInfoModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'opsGenieDomainConflictInfoModal',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71633',
    }),
    learnMoreAboutNoActionAvailableApps: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutNoActionAvailableApps',
        source: DataResidencyAppScreens.dataResidencyViewDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58850',
    }),

    learnMoreAboutNoActionAvailableAppsOnReviewScreen: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutNoActionAvailableAppsOnReviewScreen',
        source: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58851',
    }),
  },

  track: {
    dataResidencyChanged: (attributes: DataResidencyChangedAttributes): TrackEvent => {
      const { product, cloudId, dateStart } = attributes;

      return {
        data: {
          action: TrackAction.Changed,
          actionSubject: TrackActionSubject.DataResidencyApps,
          actionSubjectId: attributes.cloudId,
          source: DataResidencyAppScreens.requestAppMigrationSubmitRequestScreen,
          attributes: {
            product,
            cloudId,
            dateStart,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50047',
      };
    },
  },
  operational: {},
};
