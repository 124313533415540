export enum DomainClaimStatus {
  Verified = 'VERIFIED',
  Deleted = 'DELETED',
  Unverified = 'UNVERIFIED',
  Superseded = 'SUPERSEDED',
  VerifiedUnclaimed = 'VERIFIED_UNCLAIMED',
  MissingToken = 'MISSING_TOKEN',
}

export interface Domain {
  status: string;
  verified: boolean;
}

export interface DomainStatusAttributes {
  /**
   * Domains that have been added
   */
  domainAdded: boolean;
  /**
   * Domains that are verified or verified unclaimed
   */
  domainVerified: boolean;
  /**
   * Domains that have claimed accounts
   */
  accountsClaimed: boolean;
}

export const getDomainStatusAttributes = (domains: Domain[]): DomainStatusAttributes => ({
  domainAdded: domains.length > 0,
  domainVerified:
    domains.filter((d) => d.status === DomainClaimStatus.Verified || d.status === DomainClaimStatus.VerifiedUnclaimed).length > 0,
  accountsClaimed: domains.filter((d) => d.status === DomainClaimStatus.Verified).length > 0,
});

export const hasDomainsVerifiedAndAccountsClaimed = (domains: Domain[]): boolean =>
  domains.some((d) => d.verified && d.status === DomainClaimStatus.Verified);
