import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIEventKeys = 'trelloUsersExportNotificationShown' | 'trelloUsersExportClicked' | 'trelloUsersExportLearnMoreClicked';

interface TrelloUsersExportAttributes {
  orgId: string;
  trelloUsersCount: number;
}

export const managedAccountsScreen = 'managedAccountsScreen';
export const trelloUserExportNotification = 'trelloUserExportNotification';

export const managedAccountsTrelloUsersExportEvents: FeatureEvents<string, UIEventKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1058181024/Managed+Accounts+Trello+Users+Export+Notification+Analytics+Spec',
  screen: {},
  ui: {
    trelloUsersExportNotificationShown: (attributes: TrelloUsersExportAttributes): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Notification,
        actionSubjectId: 'trelloUsersExportNotification',
        source: managedAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34186',
    }),
    trelloUsersExportClicked: (attributes: TrelloUsersExportAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'trelloUsersExport',
        source: trelloUserExportNotification,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34187',
    }),
    trelloUsersExportLearnMoreClicked: (attributes: TrelloUsersExportAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        source: trelloUserExportNotification,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
  },
  track: {},
  operational: {},
};
