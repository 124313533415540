import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UIKeys =
  | 'insightsDomainClaimCoverageShown'
  | 'insightsDomainClaimCoverageChart'
  | 'insightsDomainClaimCoverageGraph'
  | 'insightsDomainClaimCoveragePagination'
  | 'insightsDomainClaimCoverageTable'
  | 'insightsDomainClaimCoverageTryAgain';

export const adminInsightsDomainClaimCoverageEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/824440617/Organization+Insights+Domain+Claim+Coverage+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    insightsDomainClaimCoverageShown: (attributes: { selectedProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'domainClaimCoverage',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28116',
    }),
    insightsDomainClaimCoverageChart: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'domainClaimCoverageChart',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28261',
    }),
    insightsDomainClaimCoverageGraph: (attributes: { show: 'table' | 'chart' }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'domainClaimCoverageGraph',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28260',
    }),
    insightsDomainClaimCoveragePagination: (attributes: { previousPage: number; currentPage: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainClaimCoveragePagination',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28120',
    }),
    insightsDomainClaimCoverageTable: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'domainClaimCoverageTable',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28121',
    }),
    insightsDomainClaimCoverageTryAgain: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainClaimCoverageTryAgain',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28122',
    }),
  },
  track: {},
  operational: {},
};
