import * as React from 'react';

import type { TrackEvent } from './new-analytics-types';
import { useAnalyticsClient } from './use-analytics-client';

export interface TrackEventSenderProps {
  eventData: TrackEvent;
  isLoading: boolean;
}

export const TrackEventSender: React.FC<TrackEventSenderProps> = ({ eventData, isLoading }) => {
  const analyticsClient = useAnalyticsClient();
  const [sentTrackEvent, setSentTrackEvent] = React.useState(false);

  React.useEffect((): void => {
    if (isLoading || sentTrackEvent) {
      return;
    }

    analyticsClient.sendTrackEvent(eventData);

    setSentTrackEvent(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return null;
};
