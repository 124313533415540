import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export const orgAdminScreen = 'orgAdminScreen';

export type ScreenKeys = 'orgAdminScreenEvent';
type UiKeys = 'addAdministratorsButtonClicked' | 'grantAdminAccessButtonClicked';
type TrackKeys = 'adminAdded';

export const orgAdminEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/943645881/Security+checklist+-+Organization+set+up+-+Analytics+spec',
  screen: {
    orgAdminScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgAdminScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34524',
    }),
  },
  ui: {
    addAdministratorsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addAdministrators',
        source: orgAdminScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/35411',
    }),
    grantAdminAccessButtonClicked: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'grantAccess',
        source: orgAdminScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/35413',
    }),
  },
  track: {
    adminAdded: (): TrackEvent => ({
      data: {
        action: TrackAction.Added,
        actionSubject: TrackActionSubject.OrgAdmin,
        actionSubjectId: '',
        source: orgAdminScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/35432',
    }),
  },
  operational: {},
};
