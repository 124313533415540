import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'rovoNoActivatedSitesHomeScreen'
  | 'rovoActivatedHomeScreen'
  | 'rovoSearchPinsTab'
  | 'rovoSearchPinsModal'
  | 'rovoSearchBookmarkImportModal'
  | 'rovoSelectSitesFocusedTaskStep1'
  | 'rovoSelectSitesFocusedTaskStep2'
  | 'rovoSiteActivationComplete'
  | 'rovoSiteDeactivateModal'
  | 'rovoSuggestedBookmarks';
type UiKeys =
  | 'moreAboutRovoNoActivatedSitesScreen'
  | 'moreAboutRovoRovoActivatedScreen'
  | 'selectSitesToActivate'
  | 'activateSiteRovoHome'
  | 'rovoHomeProductAccessTab'
  | 'moreAboutRovoSearchConnectedAppsScreen'
  | 'nextRovoSelectSitesTaskStep1'
  | 'activateRovoSelectSitesTaskStep2'
  | 'connectAppsRovoSiteActivationComplete'
  | 'doThisLaterRovoSiteActivationComplete'
  | 'deactivateSiteRovoHome'
  | 'deactivateSiteRovoModal'
  | 'tryRovo'
  | 'manageSubscription'
  | 'openTrialPillDropdown'
  | 'addBillingDetailsLinkClicked'
  | 'manageProduct'
  | 'disconnectProduct'
  | 'connectThirdPartyProduct';

export const rovoNoActivatedSitesHomeScreen = 'rovoNoActivatedSitesHomeScreen';
export const rovoActivatedHomeScreen = 'rovoActivatedHomeScreen';
export const rovoConnectedAppsHomeScreen = 'rovoConnectedAppsHomeScreen';
export const rovoSearchPinsTab = 'rovoSearchPinsTab';
export const rovoSearchPinsModal = 'rovoSearchPinsModal';
export const rovoSearchBookmarkImportModal = 'rovoSearchBookmarkImportModal';
export const rovoSelectSitesFocusedTaskStep1 = 'rovoSelectSitesFocusedTaskStep1';
export const rovoSelectSitesFocusedTaskStep2 = 'rovoSelectSitesFocusedTaskStep2';
export const rovoSiteActivationComplete = 'rovoSiteActivationComplete';
export const rovoSiteDeactivateModal = 'rovoSiteDeactivateModal';
export const rovoSuggestedBookmarks = 'rovoSuggestedBookmarks';

export const rovoEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/ETF/pages/3696480870/Rovo+Admin+Hub+Beta+-+Product+Analytics+Spec',

  screen: {
    rovoNoActivatedSitesHomeScreen: (): ScreenEvent => ({
      data: {
        name: rovoNoActivatedSitesHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67044',
    }),
    rovoActivatedHomeScreen: (): ScreenEvent => ({
      data: {
        name: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67027',
    }),
    rovoSearchPinsTab: (): ScreenEvent => ({
      data: {
        name: rovoSearchPinsTab,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71274',
    }),
    rovoSearchPinsModal: (attributes: { isEdit: boolean }): ScreenEvent => ({
      data: {
        name: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71275',
    }),
    rovoSearchBookmarkImportModal: (): ScreenEvent => ({
      data: {
        name: rovoSearchBookmarkImportModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74752',
    }),
    rovoSelectSitesFocusedTaskStep1: (): ScreenEvent => ({
      data: {
        name: rovoSelectSitesFocusedTaskStep1,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67920',
    }),
    rovoSelectSitesFocusedTaskStep2: (): ScreenEvent => ({
      data: {
        name: rovoSelectSitesFocusedTaskStep2,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67922',
    }),
    rovoSiteActivationComplete: (): ScreenEvent => ({
      data: {
        name: rovoSiteActivationComplete,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67925',
    }),
    rovoSiteDeactivateModal: (): ScreenEvent => ({
      data: {
        name: rovoSiteDeactivateModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67969',
    }),
    rovoSuggestedBookmarks: (): ScreenEvent => ({
      data: {
        name: rovoSuggestedBookmarks,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80092',
    }),
  },

  ui: {
    moreAboutRovoNoActivatedSitesScreen: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreAboutAtlassianRovo',
        source: rovoNoActivatedSitesHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67030',
    }),

    tryRovo: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryRovo',
        source: 'rovoNoActivatedSitesHomeScreen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71376',
    }),

    selectSitesToActivate: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectSitesToActivate',
        source: rovoNoActivatedSitesHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67045',
    }),

    moreAboutRovoRovoActivatedScreen: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreAboutAtlassianRovo',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67030',
    }),

    rovoHomeProductAccessTab: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Tab,
        actionSubjectId: 'rovoHomeProductAccessTab',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67041',
    }),

    activateSiteRovoHome: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activateSiteRovoHome',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67043',
    }),

    moreAboutRovoSearchConnectedAppsScreen: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreAboutRovoSearch',
        source: rovoConnectedAppsHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67293',
    }),

    nextRovoSelectSitesTaskStep1: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextRovoSelectSitesTaskStep1',
        source: rovoSelectSitesFocusedTaskStep1,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67971',
    }),

    activateRovoSelectSitesTaskStep2: (attributes: { selectedSitesCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activateRovoSelectSitesTaskStep2',
        source: rovoSelectSitesFocusedTaskStep2,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67972',
    }),

    connectAppsRovoSiteActivationComplete: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectAppsRovoSiteActivationComplete',
        source: rovoSiteActivationComplete,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67973',
    }),

    doThisLaterRovoSiteActivationComplete: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterRovoSiteActivationComplete',
        source: rovoSiteActivationComplete,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67975',
    }),

    deactivateSiteRovoHome: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'deactivateSiteRovoHome',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67974',
    }),

    deactivateSiteRovoModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deactivateSiteRovoModal',
        source: rovoSiteDeactivateModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67976',
    }),
    manageSubscription: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'manageSubscription',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72215',
    }),
    openTrialPillDropdown: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openTrialPillDropdown',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72216',
    }),
    addBillingDetailsLinkClicked: (attributes: { surface: 'rovo-page' }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'addBillingDetails',
        source: rovoActivatedHomeScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62127',
    }),
    connectThirdPartyProduct: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectThirdPartyProduct',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72356',
    }),

    manageProduct: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageConnectedProduct',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72456',
    }),

    disconnectProduct: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectProduct',
        source: rovoActivatedHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72358',
    }),
  },

  track: {
    rovoSearchPinCreated: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.RovoSearchPin,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71276',
    }),
    rovoSearchPinUpdated: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.RovoSearchPin,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71277',
    }),
    rovoSearchPinDeleted: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.RovoSearchPin,
        source: rovoSearchPinsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71278',
    }),
    rovoSearchPinKeyPhraseDuplicated: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Duplicated,
        actionSubject: TrackActionSubject.RovoSearchPinKeyPhrase,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71279',
    }),
    rovoSearchBookmarkImportSubmissionFailed: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Errored,
        actionSubject: TrackActionSubject.RovoSearchBookmarkImportSubmission,
        source: rovoSearchBookmarkImportModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75621',
    }),
    rovoSearchBookmarkImportSubmissionSuccess: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Submitted,
        actionSubject: TrackActionSubject.RovoSearchBookmarkImportSubmission,
        source: rovoSearchBookmarkImportModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75622',
    }),
    rovoBookmarkSuggestionApproved: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Approved,
        actionSubject: TrackActionSubject.RovoBookmarkSuggestion,
        source: rovoSuggestedBookmarks,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80093',
    }),
    rovoBookmarkSuggestionUpdated: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.RovoBookmarkSuggestion,
        source: rovoSuggestedBookmarks,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80094',
    }),
    rovoBookmarkSuggestionDismissed: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Dismissed,
        actionSubject: TrackActionSubject.RovoBookmarkSuggestion,
        source: rovoSuggestedBookmarks,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80095',
    }),
    rovoBookmarkSuggestionsErrored: (attributes): TrackEvent => ({
      data: {
        action: TrackAction.Errored,
        actionSubject: TrackActionSubject.RovoBookmarkSuggestion,
        source: rovoSuggestedBookmarks,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80096',
    }),
  },

  operational: {
    rovoSearchPinFetchFailed: (attributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.RovoSearchPinFetch,
        source: rovoSearchPinsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71280',
    }),
    rovoSearchPinAddFailed: (attributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.RovoSearchPinAdd,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71281',
    }),
    rovoSearchPinEditFailed: (attributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.RovoSearchPinEdit,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71282',
    }),
    rovoSearchPinDeleteFailed: (attributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.RovoSearchPinDelete,
        source: rovoSearchPinsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71283',
    }),
  },
};
