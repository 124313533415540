import * as Sentry from '@sentry/browser';

import type { MonitoringClient } from './types';
import { hasGraphQlProperties, isApolloError } from './util';

export class ProductionMonitoringClient implements MonitoringClient {
  onError(error: unknown) {
    if (hasGraphQlProperties(error)) {
      Sentry.withScope((scope) => {
        scope.setTag('isGraphQLError', true);
        scope.setTag('graphQLErrorOperation', error.operation);

        const newMessage = `GraphQL Error - operation:${error.operation} - message:${error.message} - path:[${error.path.join(',')}]`;
        const newError = new Error(newMessage);

        Sentry.captureException(newError);
      });

      return;
    }

    if (!(error instanceof Error)) {
      let nonErrorWrapper: Error | undefined;

      try {
        nonErrorWrapper = new Error(`Non-Error rejection: ${JSON.stringify(error)}`);
      } catch (e) {
        // rare case of Big Int or Circular object
        nonErrorWrapper = new Error(`Non-Error rejection: ${error}. Unserializable object.`);
      }

      Sentry.captureException(nonErrorWrapper);

      return;
    }

    Sentry.captureException(error);
  }

  onUIError(error: unknown) {
    if (isApolloError(error)) {
      return;
    }

    this.onError(error);
  }
}
