import type { UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

export const inventoryPageTemplateEvents = {
  ui: {
    filterLimitWarningFlagShown: (table: string, attributes: { userId: string; filterId: string; shownOnLoad?: boolean }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Flag,
        actionSubjectId: 'filterLimitWarningFlag',
        source: `${table}-inventory-page-table`,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76067',
    }),
  },
};
