/* eslint-disable @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires */
import { svgAltDescriptions } from './ui/svgs/alt-descriptions';

export { AccessGuardLogo } from './ui/access-guard-logo';

// SVGs
export const hipaaSecurityImage = require('./ui/svgs/hipaa-security-shield.svg');
export const hipaaCertificateImage = require('./ui/svgs/hipaa-certificate.svg');
export const hipaaTagImage = require('./ui/svgs/hipaa-tag.svg');
export const hipaaInsightImage = require('./ui/svgs/hipaa-insight.svg');
export const chartImage: string = require('./ui/svgs/charts.svg');
export const gearsImage: string = require('./ui/svgs/gears.svg');
export const cartAddOnsImage: string = require('./ui/svgs/cart-add-ons.svg');
export const shoppingCartSuccessImage: string = require('./ui/svgs/shopping-cart-success.svg');
export const dashedLineHorizontalImage: string = require('./ui/svgs/dashed-line-horizontal.svg');
export const emptyChartImage: string = require('./ui/svgs/funnel.svg');
export const errorImage: string = require('./ui/svgs/error.svg');
export const errorV2Image: string = require('./ui/svgs/error-v2.svg');
export const errorGenericGreyImage: string = require('./ui/svgs/error-generic-grey.svg');
export const gmailLogoImage: string = require('./ui/svgs/gmail-logo.svg');
export const googleLogoImage: string = require('./ui/svgs/google-logo.svg');
export const googleCalendarLogoImage: string = require('./ui/svgs/google-calendar-logo.svg');
export const googleDriveLogoImage: string = require('./ui/svgs/google-drive-logo.svg');
export const githubLogoImage: string = require('./ui/svgs/github-logo.svg');
export const microsoftSharepointLogo: string = require('./ui/svgs/sharepoint-logo.svg');
export const confluenceDCLogo: string = require('./ui/svgs/confluence-dc-logo.svg');
export const jiraDCLogo: string = require('./ui/svgs/jira-dc-logo.svg');
export const microsoftTeamsLogo: string = require('./ui/svgs/microsoft-teams-logo.svg');
export const microsoftOneDriveLogo: string = require('./ui/svgs/onedrive-logo.svg');
export const smartsheetLogo: string = require('./ui/svgs/smartsheet-logo.svg');
export const airtableLogo: string = require('./ui/svgs/airtable-logo.svg');
export const gitlabLogo: string = require('./ui/svgs/gitlab-logo.svg');
export const asanaLogo: string = require('./ui/svgs/asana-logo.svg');
export const lucidLogo: string = require('./ui/svgs/lucid-logo.svg');
export const miroLogo: string = require('./ui/svgs/miro-logo.svg');
export const notionLogo: string = require('./ui/svgs/notion-logo.svg');
export const zendeskLogo: string = require('./ui/svgs/zendesk-logo.svg');
export const workdayLogo: string = require('./ui/svgs/workday-logo.svg');
export const azureDevopsLogo: string = require('./ui/svgs/azure-devops-logo.svg');
export const adobeSignLogo: string = require('./ui/svgs/adobe-sign-logo.svg');
export const canvaLogo: string = require('./ui/svgs/canva-logo.svg');
export const mondayLogo: string = require('./ui/svgs/monday-logo.svg');
export const ahaLogo: string = require('./ui/svgs/aha-logo.svg');
export const docusignLogo: string = require('./ui/svgs/docusign-logo.svg');
export const serviceNowLogo: string = require('./ui/svgs/servicenow-logo.svg');
export const dropboxLogo: string = require('./ui/svgs/dropbox-logo.svg');
export const salesforceLogo: string = require('./ui/svgs/salesforce-logo.svg');
export const microsoftOutlookMailLogo: string = require('./ui/svgs/outlook-mail-logo.svg');
export const microsoftOutlookCalendarLogo: string = require('./ui/svgs/outlook-calendar-logo.svg');
export const slackLogoImage: string = require('./ui/svgs/slack-logo.svg');
export const jerseyImage: string = require('./ui/svgs/jersey.svg');
export const jiraIncidentManagerLogoImagePath: string = require('./ui/svgs/jira-incident-manager.svg');
export const lockImage: string = require('./ui/svgs/lock.svg');
export const lockFolder: string = require('./ui/svgs/lock-folder.svg');
export const noResultsImage: string = require('./ui/svgs/no-results.svg');
export const oktaLogoImage: string = require('./ui/svgs/okta-logo.svg');
export const oneloginLogoImage: string = require('./ui/svgs/onelogin-logo.svg');
export const opsGenieLogoImagePath: string = require('./ui/svgs/opsgenie.svg');
export const overComingObstaclesImage: string = require('./ui/svgs/overcoming-obstacles.svg');
export const plugImage: string = require('./ui/svgs/plug.svg');
export const teamOnboardingImage: string = require('./ui/svgs/team-onboarding.svg');
export const searchErrorImage: string = require('./ui/svgs/search-error.svg');
export const siteAdminMigrationImage: string = require('./ui/svgs/site-admin-migration.svg');
export const downloadImage: string = require('./ui/svgs/download.svg');
export const dashedLineImage: string = require('./ui/svgs/dashed-line.svg');
export const catchInNetImage: string = require('./ui/svgs/catching-in-net.svg');
export const accessLogoImage: string = require('./ui/svgs/access-logo.svg');
export const guardLogoImage: string = require('./ui/svgs/guard-logo.svg');
export const trustedUserHeroImage: string = require('./ui/svgs/trusted-user-hero.svg');
export const azureLogoImage: string = require('./ui/svgs/azure-logo.svg');
export const bitiumLogoImage: string = require('./ui/svgs/bitium-logo.svg');
export const centrifyLogoImage: string = require('./ui/svgs/centrify-logo.svg');
export const opsGenie32LogoImagePath: string = require('./ui/svgs/opsgenie32.svg');
export const accessActivationImagePath: string = require('./ui/svgs/access-activation.svg');
export const accessHeroImagePath: string = require('./ui/svgs/access-hero.svg');
export const adminToAtlassian: string = require('./ui/svgs/admin-to-atlassian.svg');
export const siteAdministrationSelectedImage: string = require('./ui/svgs/shield-cog-selected.svg');
export const siteAdministrationNormalImage: string = require('./ui/svgs/shield-cog-normal.svg');
export const trustedUserSelectedImage: string = require('./ui/svgs/shield-check-selected.svg');
export const trustedUserNormalImage: string = require('./ui/svgs/shield-check-normal.svg');
export const userAvatarSelectedImage: string = require('./ui/svgs/user-avatar-circle-selected.svg');
export const userAvatarNormalImage: string = require('./ui/svgs/user-avatar-circle-normal.svg');
export const onboardingModalImage: string = require('./ui/svgs/spotlight-tour-start-modal.svg');
export const noSitesImage: string = require('./ui/svgs/financial-planning.svg');
export const cloudSecurityImage: string = require('./ui/svgs/cloud-security.svg');
export const lockSafeImage: string = require('./ui/svgs/safe.svg');
export const sandboxImage: string = require('./ui/svgs/sandbox.svg');
export const sandboxProjectOrSpacesNoIcon: string = require('./ui/svgs/sandbox-project-or-spaces-no-icon.svg');
export const reviewData: string = require('./ui/svgs/review-data.svg');
export const appsImage: string = require('./ui/svgs/apps.svg');
export const moonImage: string = require('./ui/svgs/moon.svg');
export const addUser: string = require('./ui/svgs/add-user.svg');
export const idBadgeImage: string = require('./ui/svgs/id-badge.svg');
export const idBadgeImageLegacy: string = require('./ui/svgs/id-badge-legacy.svg');
export const explorersImage: string = require('./ui/svgs/explorers.svg');
export const growthPlant: string = require('./ui/svgs/growth-plant.svg');
export const iceCreamImage: string = require('./ui/svgs/ice-cream.svg');
export const avocadoImagePath: string = require('./ui/svgs/avocado.svg');
export const atlasImagePath: string = require('./ui/svgs/atlas.svg');
export const avocadoSmallImagePath: string = require('./ui/svgs/avocado_small.svg');
export const compassImagePath: string = require('./ui/svgs/compass.svg'); // TODO: Add small image path for compass
export const fileCabinetOpenImage: string = require('./ui/svgs/file-cabinet-open.svg');
export const firewallKeyImage: string = require('./ui/svgs/firewall-key.svg');
export const lightbulbOrbitImage: string = require('./ui/svgs/lightbulb-orbit.svg');
export const lockClosedImage: string = require('./ui/svgs/lock-closed.svg');
export const trashImage: string = require('./ui/svgs/trash.svg');
export const microscopeImage: string = require('./ui/svgs/microscope.svg');
export const satelliteImage: string = require('./ui/svgs/satellite.svg');
export const serviceBellWindow: string = require('./ui/svgs/service-bell-window.svg');
export const toolsImage: string = require('./ui/svgs/tools.svg');
export const twoFaImage: string = require('./ui/svgs/two-fa.svg');
export const checklistImage: string = require('./ui/svgs/checklist.svg');
export const sandTimerImage: string = require('./ui/svgs/sand-timer.svg');
export const samlImage: string = require('./ui/svgs/saml.svg');
export const movingImage: string = require('./ui/svgs/moving.svg');
export const adminAvatarsImage: string = require('./ui/svgs/admin-avatars.svg');
export const collaborationImage: string = require('./ui/svgs/collaboration-flight.svg');
export const globeNetworkCheck: string = require('./ui/svgs/globe-network-check.svg');
export const secureEmailEmptyState: string = require('./ui/svgs/secure-email-empty-state.svg');
export const secureTunnelsEmptyState: string = require('./ui/svgs/secure-tunnels-empty-state.svg');
export const securityShield: string = require('./ui/svgs/security-shield.svg');
export const userProvisioning: string = require('./ui/svgs/user-provisioning.svg');
export const microsoftLogoImage: string = require('./ui/svgs/microsoft-logo.svg');
export const slackLogoGrey: string = require('./ui/svgs/slack-logo-grey.svg');
export const slackAndAtlassianConnect: string = require('./ui/svgs/slack-and-atlassian-connect.svg');
export const slackConnectWithAtlassian: string = require('./ui/svgs/slack-connect-with-atlassian.svg');
export const reviewImage: string = require('./ui/svgs/review.svg');
export const rocketImage: string = require('./ui/svgs/rocket.svg');
export const scheduleImage: string = require('./ui/svgs/schedule.svg');
export const reviewAppsImage: string = require('./ui/svgs/review-apps.svg');
export const line2Image: string = require('./ui/svgs/line-2.svg');
export const confluenceChartWindow: string = require('./ui/svgs/confluence-chart-window.svg');
export const idpCardMicrosoftAzureAD: string = require('./ui/svgs/idp-card-logo-microsoft-azure-AD.svg');
export const idpCardAuth0: string = require('./ui/svgs/idp-card-logo-auth-0.svg');
export const idpCardGoogleCloudIdentity: string = require('./ui/svgs/idp-card-logo-google-cloud-identity.svg');
export const idpCardOneLogin: string = require('./ui/svgs/idp-card-logo-one-login.svg');
export const idpCardPingIdentity: string = require('./ui/svgs/idp-card-logo-ping-identity.svg');
export const idpCardPreferredProvider: string = require('./ui/svgs/idp-card-logo-preferred-provider.svg');
export const idpCardGoogleWorkSpace: string = require('./ui/svgs/google-logo.svg');
export const idpCardADFS: string = require('./ui/svgs/microsoft-logo.svg');
export const idpCardCyberarkIdaptive: string = require('./ui/svgs/idp-card-logo-cyberark-idaptive.svg');
export const idpCardOkta: string = require('./ui/svgs/okta-logo.svg');
export const idpCardJumpCloud: string = require('./ui/svgs/idp-card-logo-jumpCloud.svg');
export const clipboardImage: string = require('./ui/svgs/clipboard.svg');
export const cloudRoadmapImage = require('./ui/svgs/cloud-roadmap-image.svg');
export const appLogo = require('./ui/svgs/app-logo.svg');
export const serviceSuccess = require('./ui/svgs/service-success.svg');
export const applyRules = require('./ui/svgs/apply-rules.svg');
export const arrowNextStep = require('./ui/svgs/arrow-next-step.svg');
export const chooseStepIcon = require('./ui/svgs/choose-step-icon.svg');
export const exportRuleStep = require('./ui/svgs/export-rule-step.svg');
export const openFolderWithCodeImage = require('./ui/svgs/open-folder-with-code.svg');
export const certificateImage: string = require('./ui/svgs/certificate.svg');
export const ipAddressImage: string = require('./ui/svgs/ip-address.svg');
export const appDetailsNoSearchResults = require('./ui/svgs/no_search_result.svg');
export const locationImage: string = require('./ui/svgs/location.svg');
export const celebrationImage: string = require('./ui/svgs/celebration.svg');
export const policyNotFound: string = require('./ui/svgs/policy-not-found.svg');
export const searchFileImage: string = require('./ui/svgs/search-files.svg');
export const collectUsersImage: string = require('./ui/svgs/catching-in-net.svg');
export const errorModalImage: string = require('./ui/svgs/error-modal.svg');
export const uploadImage: string = require('./ui/svgs/upload.svg');
export const collaborationModelWorkspace = require('./ui/svgs/collaboration-model-workspace.svg');
export const collaborationModelContainer = require('./ui/svgs/collaboration-model-container.svg');
export const collaborationModelClassification = require('./ui/svgs/collaboration-model-classification.svg');
export const collaborationModelWorkspaceV2 = require('./ui/svgs/collaboration-model-workspace-v2.svg');
export const collaborationModelContainerV2 = require('./ui/svgs/collaboration-model-container-v2.svg');
export const collaborationModelClassificationV2 = require('./ui/svgs/collaboration-model-classification-v2.svg');
export const errorGenericNoResultsImage: string = require('./ui/svgs/error-generic-no-results.svg');
export const folderImage: string = require('./ui/svgs/folder.svg');
export const binoculars: string = require('./ui/svgs/binoculars.svg');
export const searchNoResults: string = require('./ui/svgs/search-no-results.svg');
export const productRequestLeftBottomImage: string = require('./ui/svgs/product-request-left-bottom.svg');
export const productRequestRightBottomImage: string = require('./ui/svgs/product-request-right-bottom.svg');
export const migrationFullImage: string = require('./ui/svgs/migration-full.svg');
export const migrationSelectiveImage: string = require('./ui/svgs/migration-selective.svg');
export const migrationWithMediaImage: string = require('./ui/svgs/migration-with-media.svg');
export const migrationWithoutMediaImage: string = require('./ui/svgs/migration-without-media.svg');
export const prequeryImage: string = require('./ui/svgs/prequery-image.svg');
export const controlTowerImage: string = require('./ui/svgs/control-tower.svg');
export const addMediaPointer: string = require('./ui/svgs/add-media-pointer.svg');
export const addMedia: string = require('./ui/svgs/add-media.svg');
export const classificationPointer: string = require('./ui/svgs/classification-pointer.svg');
export const aiLogo: string = require('./ui/svgs/ai-logo.svg');
export const securityBadgeImage: string = require('./ui/svgs/security-badge.svg');
export const celebrationWithSparklesImage: string = require('./ui/svgs/celebration-with-sparkles.svg');
export const dataClassificationLevelsTable = require('./ui/svgs/data-classification-levels-table.svg');
export const dataClassificationOnboardingEmptyState = require('./ui/svgs/data-classification-onboarding-empty-state.svg');
export const authPoliciesReviewImage: string = require('./ui/svgs/auth-policy-review.svg');
export const authPoliciesImage: string = require('./ui/svgs/auth-policies.svg');
export const dataClassificationShieldV2 = require('./ui/svgs/data-classification-shield-v2.svg');
export const largeFileStorage = require('./ui/svgs/large-file-storage.svg');
export const multipleFilesOpen = require('./ui/svgs/multiple-files-open.svg');
export const cloud = require('./ui/svgs/cloud.svg');
export const apiKeysWelcome = require('./ui/svgs/api-keys-welcome.svg');
export const allowedIcon = require('./ui/svgs/dsp-allowed-icon.svg');
export const blockedIcon = require('./ui/svgs/dsp-blocked-icon.svg');
export const dspAddIcon = require('./ui/svgs/dsp-add-icon.svg');
export const dspCoverageSelectionDataClassification = require('./ui/svgs/dsp-coverage-selection-data-classification.svg');
export const dspCoverageSelectionSpacesAndProjects = require('./ui/svgs/dsp-coverage-selection-spaces-and-projects.svg');
export const dspCoverageSelectionProducts = require('./ui/svgs/dsp-coverage-selection-products.svg');
export const dspCoverageSelectionDataCenterProducts = require('./ui/svgs/dsp-coverage-selection-dc-products.svg');
export const dspAnonymousAccessRule = require('./ui/svgs/dsp-anonymous-access-rule.svg');
export const dspAppAccessRule = require('./ui/svgs/dsp-app-access-rule.svg');
export const dspDataExportRule = require('./ui/svgs/dsp-data-export-rule.svg');
export const dspPublicLinksRule = require('./ui/svgs/dsp-public-links-rule.svg');
export const dspAIReadWriteAccessRule = require('./ui/svgs/dsp-ai-read-write-access-rule.svg');
export const townsquareGoalsEmptyState = require('./ui/svgs/townsquare-goals-empty-state-image.svg');
export const accountAutoClaim = require('./ui/svgs/account-auto-claim.svg');
export const accountManualClaim = require('./ui/svgs/account-manual-claim.svg');
export const letterOpen = require('./ui/svgs/letter-open.svg');
export const adoptionImage = require('./ui/svgs/adoption-modal.svg');
export const crossedAttachment = require('./ui/svgs/crossed-attachment.svg');
export const clipboardBlue: string = require('./ui/svgs/clipboard-blue.svg');
export const claimAllAccounts = require('./ui/svgs/claim-all-accounts.svg');
export const claimSomeAccounts = require('./ui/svgs/claim-some-accounts.svg');
export const dspLock = require('./ui/svgs/dsp-lock.svg');
export const atlassianIntelligenceDataFlowDiagram = require('./ui/svgs/ai-data-flow-diagram.svg');
export const rovoLogo = require('./ui/svgs/rovo-logo.svg');
export const rovoWelcomeDiamond = require('./ui/svgs/rovo-welcome-diamond.svg');
export const rovoSuccessImage = require('./ui/svgs/rovo-success.svg');
export const rovoConnectProductSuccessImage = require('./ui/svgs/rovo-connect-product-success.svg');
export const rovoAppIcon = require('./ui/svgs/app-icon.svg');
export const rovoOrgIcon = require('./ui/svgs/org-icon.svg');
export const rovoFlaskIcon = require('./ui/svgs/flask-icon.svg');
export const rovoTransitionIcon = require('./ui/svgs/transition-icon.svg');
export const rovoPopupBanner = require('./ui/svgs/rovo-popup-banner.svg');
export const guardIcon = require('./ui/svgs/guard-icon.svg');
export const guardSafeImage = require('./ui/svgs/guard-safe.svg');
export const guardPremiumSafeImage = require('./ui/svgs/guard-premium-safe.svg');
export const rocketLaunchImage = require('./ui/svgs/rocket-launch.svg');
export const arrowIcon = require('./ui/svgs/arrow.svg');
export const loomOnboardingImage = require('./ui/svgs/loom-onboarding.svg');
export const connectProductImage = require('./ui/svgs/connect-product.svg');
export const globeIcon = require('./ui/svgs/globe-icon.svg');
export const globeIconBrand = require('./ui/svgs/globe-icon-brand.svg');
export const shieldFlameBrand = require('./ui/svgs/shield-flame-brand.svg');
export const safeLightImage = require('./ui/svgs/safe-light.svg');
export const twofaLightImage = require('./ui/svgs/2fa-light.svg');
export const safeCheckImage = require('./ui/svgs/safe-check.svg');
export const premiumTrialImage = require('./ui/svgs/onboarding-agp-premium-trial.svg');
export const premiumStarsImage = require('./ui/svgs/onboarding-agp-premium-stars.svg');
export const checkmarkImage = require('./ui/svgs/checkmark.svg');
export const lockCheckImage = require('./ui/svgs/lock-check.svg');
export const rovoActivateWelcomeImage = require('./ui/svgs/rovo-activate-welcome.svg');
export const headerStars1Image = require('./ui/svgs/onboarding-agp-header-stars1.svg');
export const headerStars2Image = require('./ui/svgs/onboarding-agp-header-stars2.svg');
export const telescopeIcon = require('./ui/svgs/telescope.svg');
export const gearImage = require('./ui/svgs/spot-basics-gear.svg');
export const spotBasicsAppsImage = require('./ui/svgs/spot-basics-apps.svg');
export const spotBasicsSpeedImage = require('./ui/svgs/spot-basics-speed.svg');
export const spotBasicsSimplicityImage = require('./ui/svgs/spot-basics-simplicity.svg');
export const searchImage = require('./ui/svgs/search.svg');
export const scalesShapesImage = require('./ui/svgs/scales-shapes.svg');
export const serviceAccountsWelcomeImage = require('./ui/svgs/service-accounts-welcome.svg');
export const hierarchyPointerImage = require('./ui/svgs/hierarchy-pointer.svg');
export const ugcOneBar = require('./ui/svgs/ugc-one-bar.svg');
export const ugcTwoBars = require('./ui/svgs/ugc-two-bars.svg');
export const ugcThreeBars = require('./ui/svgs/ugc-three-bars.svg');
export const boxLogoImage = require('./ui/svgs/box-logo.svg');
export const linkImage = require('./ui/svgs/link.svg');
export const groupAvatarIcon = require('./ui/svgs/group-avatar-icon.svg');
export const dataCenter = require('./ui/svgs/data-center.svg');
export const twcBannerImageSvg = require('./ui/svgs/twc-graphic.svg');
export const freezeWindowsCalendarImage = require('./ui/svgs/freeze-windows-calendar.svg');
export const checkCircleIcon = require('./ui/svgs/check-circle-icon.svg');
export const crossCircleIcon = require('./ui/svgs/cross-circle-icon.svg');
export const runsOnAtlassianIcon = require('./ui/svgs/runs-on-atlassian.svg');
export const brieV2EmptyPlaceholder = require('./ui/svgs/brie-v2-empty-placeholder.svg');
export const cloudSyncError = require('./ui/svgs/cloud-sync-error.svg');
export const upgradeJira = require('./ui/svgs/upgrade-jira.svg');

// PNGs
export const idpLogoAdfs = require('./ui/pngs/idp-logo-adfs.png');
export const idpLogoGsync = require('./ui/pngs/idp-logo-google-cloud-identity.png');
export const idpLogoAzureAd = require('./ui/pngs/idp-logo-azure-ad.png');
export const idpLogoOkta = require('./ui/pngs/idp-logo-okta.png');
export const idpLogoIdaptive = require('./ui/pngs/idp-logo-idaptive.png');
export const idpLogoOnelogin = require('./ui/pngs/idp-logo-onelogin.png');
export const orgDeleteSuccess: string = require('./ui/pngs/org-delete-success.png');
export const mobileAppPoliciesImage: string = require('./ui/pngs/mobile-app-policies.png');
export const productUpdatesFirstTimeVisitorsImage = require('./ui/pngs/product-updates-benefits-modal.png');
export const aiEmptyStateImage: string = require('./ui/pngs/platform-automation.png');
export const browserSkeleton = require('./ui/pngs/browser-skeleton.png');
export const aiSecurityImage: string = require('./ui/pngs/ai-security.png');
export const aiLockImage: string = require('./ui/pngs/ai-object-lock.png');
export const aiIcon = require('./ui/pngs/ai-icon-gray.png');
export const aiFlagIcon = require('./ui/pngs/ai-icon-blue.png');
export const allowList = require('./ui/pngs/dsp-option-allowlist.png');
export const blockList = require('./ui/pngs/dsp-option-blocklist.png');
export const awsLogo = require('./ui/pngs/aws-logo.png');
export const brieV2AtlassianLogo = require('./ui/svgs/brie-atlassian.svg');
export const brieV2AwsLogo = require('./ui/svgs/awsLogo.svg');
export const spinnakerLogo = require('./ui/pngs/spinnaker-logo.png');

// Favicons
export const favicon16: string = require('./ui/favicon/favicon-16x16.png');
export const favicon32: string = require('./ui/favicon/favicon-32x32.png');
export const favicon96: string = require('./ui/favicon/favicon-96x96.png');
export const faviconIco: string = require('./ui/favicon/favicon.ico');

// Base64 PNGs
export const downArrowBase64Svg =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyM1MDVmNzknIHdpZHRoPScyNCcgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyBmb2N1c2FibGU9J2ZhbHNlJyByb2xlPSdwcmVzZW50YXRpb24nPjxwYXRoIGQ9J004LjI5MiAxMC4yOTNhMS4wMDkgMS4wMDkgMCAwIDAgMCAxLjQxOWwyLjkzOSAyLjk2NWMuMjE4LjIxNS41LjMyMi43NzkuMzIycy41NTYtLjEwNy43NjktLjMyMmwyLjkzLTIuOTU1YTEuMDEgMS4wMSAwIDAgMCAwLTEuNDE5Ljk4Ny45ODcgMCAwIDAtMS40MDYgMGwtMi4yOTggMi4zMTctMi4zMDctMi4zMjdhLjk5Ljk5IDAgMCAwLTEuNDA2IDB6JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnPjwvcGF0aD48L3N2Zz4=';

// GIFs
export const buildingFunctionalityImage = require('./ui/gifs/construction.gif');

// Alt descriptions
export const imageAltDescriptions = {
  ...svgAltDescriptions,
};
