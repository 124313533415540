import * as React from 'react';
import type { Subtract } from 'utility-types';

import type { RouteIdContextProps } from './route-id-context';
import { RouteIdContext } from './route-id-context';

export interface OrgNameProps {
  orgName?: string;
}

export function withOrgName<TOwnProps extends OrgNameProps>(
  Component: React.ComponentType<TOwnProps>,
): React.ComponentClass<Subtract<TOwnProps, OrgNameProps>> {
  return class extends React.Component<TOwnProps> {
    public render() {
      return (
        <RouteIdContext.Consumer>
          {(value: RouteIdContextProps) => <Component {...(this.props as any)} orgName={value.orgName} />}
        </RouteIdContext.Consumer>
      );
    }
  };
}

export const useOrgName = (): string | undefined => {
  const routeInfo = React.useContext(RouteIdContext);

  return routeInfo.orgName;
};
