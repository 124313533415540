import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'productUrls' | 'inputSiteUrlModal' | 'confirmSiteUrlUpdateModal';

type UiKeys =
  | 'updateSiteUrl'
  | 'inputSiteUrlSubmit'
  | 'inputSiteUrlCancel'
  | 'confirmSiteUrlUpdateModalSubmit'
  | 'confirmSiteUrlUpdateModalCancel'
  | 'learnMoreAboutSiteUrlRename'
  | 'learnMoreAboutRecommendation';

type TrackKeys = 'siteUrlRenamed';

type OperationalKeys = 'siteUrlConflict' | 'siteUrlMaxRenames';

const productUrlsScreen = 'productUrlsScreen';
const confirmSiteUrlUpdateModal = 'confirmSiteUrlUpdateModal';
const inputSiteUrlModal = 'inputSiteUrlModal';

export const siteUrlRenameEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/552849676/Site+URL+Rename+Analytics+Spec',
  screen: {
    productUrls: (attributes: { addCustomDomainVisible: boolean; hasFreeWorkspaces: boolean }): ScreenEvent => ({
      data: {
        name: productUrlsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18499',
    }),
    inputSiteUrlModal: (attributes: { siteUrlRenameAttempts: number }): ScreenEvent => ({
      data: {
        name: inputSiteUrlModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18501',
    }),
    confirmSiteUrlUpdateModal: (): ScreenEvent => ({
      data: {
        name: confirmSiteUrlUpdateModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18500',
    }),
  },
  ui: {
    learnMoreAboutSiteUrlRename: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        source: productUrlsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/1887',
    }),
    updateSiteUrl: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateSiteUrlButton',
        source: productUrlsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18502',
    }),
    inputSiteUrlSubmit: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitButton',
        source: inputSiteUrlModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14767',
    }),
    inputSiteUrlCancel: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelButton',
        source: inputSiteUrlModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    learnMoreAboutRecommendation: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        source: inputSiteUrlModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/1887',
    }),
    confirmSiteUrlUpdateModalSubmit: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitButton',
        source: confirmSiteUrlUpdateModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14767',
    }),
    confirmSiteUrlUpdateModalCancel: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backButton',
        source: confirmSiteUrlUpdateModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15325',
    }),
  },
  track: {
    siteUrlRenamed: (attributes: { siteId: string; oldSiteUrl: string; newSiteUrl: string }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.SiteUrl,
        source: productUrlsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18503',
    }),
  },
  operational: {
    siteUrlConflict: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Triggered,
        actionSubject: OperationalSubject.SiteUrlError,
        actionSubjectId: 'conflictSiteUrl',
        source: productUrlsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18504',
    }),
    siteUrlMaxRenames: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Triggered,
        actionSubject: OperationalSubject.SiteUrlError,
        actionSubjectId: 'maxRenames',
        source: productUrlsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18504',
    }),
  },
};
