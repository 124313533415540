import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UiKeys =
  | 'insightsActiveTrelloUsersChart'
  | 'insightsActiveTrelloUsersCardShown'
  | 'insightsActiveTrelloUsersManagedDomains'
  | 'insightsActiveTrelloUsersLearnMore';

export const adminInsightsActiveTrelloUsersEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1359197433/Organization+Insights+-+Active+Trello+users+chart+Active+Trello+users+by+pricing+plan+table+-+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    insightsActiveTrelloUsersManagedDomains: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'activeTrelloUsersViewManagedDomains',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40018',
    }),
    insightsActiveTrelloUsersLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'activeTrelloUsersLearnMoreEnterprise',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40017',
    }),
    insightsActiveTrelloUsersChart: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'activeTrelloUsers',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40016',
    }),
    insightsActiveTrelloUsersCardShown: (attributes: { selectedProducts: string[]; hasTrelloEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'activeTrelloUsers',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40004',
    }),
  },
  track: {},
  operational: {},
};
