import AkFeatureGates from '@atlaskit/feature-gate-js-client';

import { UiProductType, WorkspaceBundle } from '@adminhub/graphql-types';
import type { ProductName } from '@adminhub/icons';

// Key map reference: https://bitbucket.org/atlassian/growth-analytics-pipeline-airflow/src/master/configurations/business/dim_model/subdag_configurations/dimensional_model/task_scripts/sql_scripts/07_dim_product.hql
export type EmauProductKey =
  | 'confluence'
  | 'bamboo'
  | 'bitbucket'
  | 'hipchat'
  | 'stride'
  | 'jira_portfolio'
  | 'jira_portfolio'
  | 'jira_workManagement'
  | 'jira_software'
  | 'jira_software'
  | 'jira_serviceManagement'
  | 'jira_customerService'
  | 'statuspage_manage'
  | 'trello'
  | 'jira_ops'
  | 'opsgenie'
  | 'atlassian_access'
  | 'confluence_questions'
  | 'confluence_team_calendars'
  | 'jira_training'
  | 'marketplace_addon'
  | 'insight'
  | 'jira_align'
  | 'jira_align_addon'
  | 'atlas'
  | 'compass'
  | 'atlassian_analytics'
  | 'fabric'
  | 'halp'
  | 'jira_productDiscovery'
  | 'beacon'
  | 'access'
  | 'atlassian_together'
  | 'loom'
  | 'guard'
  | 'rovo'
  | 'goal'
  | 'project'
  | 'devai'
  | 'radar'
  | 'passionfruit';

/**
 * See https://stash.atlassian.com/projects/IPS/repos/id-org-manager/browse/src/main/java/com/atlassian/orgmanager/services/products/ProductsData.java
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the `productKey` that is used by `id-org-manager` service.
 */
function getIdOrgManagerProductKey(productType: UiProductType): string {
  switch (productType) {
    case 'CONFLUENCE':
      return 'confluence';
    case 'JIRA_CORE':
      return 'jira-core';
    case 'JIRA_SERVICE_DESK':
      return 'jira-service-desk';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira-product-discovery';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-ops';
    case 'JIRA_SOFTWARE':
      return 'jira-software';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'BITBUCKET':
      return 'bitbucket';
    case 'TRELLO':
      return 'trello';
    case 'STRIDE':
      return 'stride';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'COMPASS':
      return 'compass';
    case 'AVOCADO':
      return 'avocado';
    case 'BEACON':
      return 'beacon';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'ANALYTICS':
      return 'avp';
    case 'FABRIC':
      return 'canvas';
    case 'CLOUD_ADMIN':
      return 'cloud-admin';
    case 'MERCURY':
      return 'mercury';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'JIRA_WORK_MANAGEMENT':
      return 'jira-core';
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira-service-desk';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira-customer-service';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getIdOrgManagerProductKey" function was called with productType="${productType}", which does not yet have a IdOrgManagerProductKey defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }

      throw new Error(`Cannot determine IdOrgManagerProductKey of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * See https://stash.atlassian.com/projects/UN/repos/user-management/browse/hipster-user-management/src/main/java/com/atlassian/usermanagement/nextgen/model/product/ProductId.java
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the `productId` that is used by `user-management` service.
 */
function getUserManagementProductId(productType: UiProductType): string {
  switch (productType) {
    case 'JIRA_FAMILY':
      return 'jira-family';
    case 'CONFLUENCE':
      return 'conf';
    case 'JIRA_CORE':
      return 'jira-core';
    case 'JIRA_SERVICE_DESK':
      return 'jira-servicedesk';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira-product-discovery';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-incident-manager';
    case 'JIRA_SOFTWARE':
      return 'jira-software';
    case 'JIRA_ADMIN':
      return 'jira-admin';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira-customer-service';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'STRIDE':
      return 'hipchat.cloud';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'COMPASS':
      return 'compass';
    case 'AVOCADO':
      return 'avocado';
    case 'BEACON':
      return 'beacon';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'ANALYTICS':
      return 'avp';
    case 'TRELLO':
      return 'trello';
    case 'BITBUCKET':
      return 'bitbucket';
    case 'FABRIC':
      return 'canvas';
    case 'CLOUD_ADMIN':
      return 'cloud-admin';
    case 'MERCURY':
      return 'mercury';
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira-servicedesk';
    case 'JIRA_WORK_MANAGEMENT':
      return 'jira-work-management';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'LOOM':
      return 'loom';
    case 'LOOM_ATTRIBUTION':
      return 'loom-attribution';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    case 'UNKNOWN_ATLASSIAN':
      return 'unknown-atlassian';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getUserManagementProductId" function was called with productType="${productType}", which does not yet have a productId defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }

      throw new Error(`Cannot determine productId of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * See https://bitbucket.org/atlassian/user-management/src/master/user-management-util/src/main/java/com/atlassian/usermanagement/productconfig/products/CanonicalProduct.java
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the `canonicalProductKey` that is used by `user-management` service.
 */
function getUserManagementCanonicalProductKey(productType: UiProductType): string {
  switch (productType) {
    case 'BITBUCKET':
      return 'bitbucket';
    case 'CONFLUENCE':
      return 'confluence';
    case 'JIRA_CORE':
      return 'jira-core';
    case 'JIRA_SOFTWARE':
      return 'jira-software';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-incident-manager';
    case 'STRIDE':
      return 'chat';
    case 'JIRA_SERVICE_DESK':
      return 'jira-servicedesk';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira-product-discovery';
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira-service-management';
    case 'JIRA_WORK_MANAGEMENT':
      return 'jira-work-management';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira-customer-service';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'COMPASS':
      return 'compass';
    case 'AVOCADO':
      return 'avocado';
    case 'BEACON':
      return 'beacon';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'ANALYTICS':
      return 'avp';
    case 'TRELLO':
      return 'trello';
    case 'FABRIC':
      return 'canvas';
    case 'CLOUD_ADMIN':
      return 'cloud-admin';
    case 'MERCURY':
      return 'mercury';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getUserManagementCanonicalProductKey" function was called with productType="${productType}", which does not yet have a canonicalProductKey defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }
      throw new Error(`Cannot determine canonicalProductKey of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * See https://bitbucket.org/atlassian/sandbox-service/src/master/common/src/main/kotlin/com/atlassian/sandbox/model/SandboxableProductKey.kt
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the `sandboxProductKey` that is used by `sandbox-service`.
 */
function getSandboxProductKey(productType: UiProductType): string {
  switch (productType) {
    case 'BITBUCKET':
      return 'bitbucket';
    case 'CONFLUENCE':
      return 'confluence.ondemand';
    case 'JIRA_CORE':
      return 'jira-core.ondemand';
    case 'JIRA_SOFTWARE':
      return 'jira-software.ondemand';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-incident-manager';
    case 'STRIDE':
      return 'chat';
    case 'JIRA_SERVICE_DESK':
      return 'jira-servicedesk.ondemand';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira-product-discovery';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira-customer-service';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'COMPASS':
      return 'compass';
    case 'AVOCADO':
      return 'avocado';
    case 'BEACON':
      return 'beacon';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'TRELLO':
      return 'trello';
    case 'FABRIC':
      return 'canvas';
    case 'MERCURY':
      return 'mercury';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo.ondemand';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getSandboxProductKey" function was called with productType="${productType}", which does not yet have a sandboxProductKey defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }
      throw new Error(`Cannot determine sandboxProductKey of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * See https://bitbucket.org/atlassian/release-track/src/master/src/main/java/io/atlassian/micros/releasetrack/model/Product.java
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the `releaseTrackProductKey` that is used by `release-track`.
 */
function getReleaseTrackProductKey(productType: UiProductType): string {
  switch (productType) {
    case 'BITBUCKET':
      return 'bitbucket';
    case 'CONFLUENCE':
      return 'Confluence';
    case 'JIRA_CORE':
    case 'JIRA_SOFTWARE':
    case 'JIRA_SERVICE_DESK':
    case 'JIRA_PRODUCT_DISCOVERY':
    case 'JIRA_CUSTOMER_SERVICE':
      return 'Jira';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-incident-manager';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'COMPASS':
      return 'compass';
    case 'STRIDE':
      return 'chat';
    case 'AVOCADO':
      return 'Avocado';
    case 'BEACON':
      return 'beacon';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'FABRIC':
      return 'canvas';
    case 'TRELLO':
      return 'trello';
    case 'CLOUD_ADMIN':
      return 'cloud-admin';
    case 'MERCURY':
      return 'mercury';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getReleaseTrackProductKey" function was called with productType="${productType}", which does not yet have a releaseTrackProductKey defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }
      throw new Error(`Cannot determine releaseTrackProductKey of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * See https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/
 *
 * @param {UiProductType} productType the type of product
 * @returns {string} Returns the productKey portion of the ARI (i.e. ari:cloud:productKey::site/<id>)
 */
function getAriKey(productType: UiProductType): string {
  switch (productType) {
    case 'BITBUCKET':
      return 'bitbucket';
    case 'CONFLUENCE':
      return 'confluence';
    case 'JIRA_WORK_MANAGEMENT':
    case 'JIRA_CORE':
      return 'jira-core';
    case 'JIRA_SERVICE_DESK':
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira-servicedesk';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira-product-discovery';
    case 'JIRA_SOFTWARE':
      return 'jira-software';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira-incident-manager';
    case 'JIRA_ADMIN':
      return 'jira';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira-customer-service';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'STATUSPAGE':
      return 'statuspage';
    case 'STRIDE':
      return 'hipchat.cloud';
    case 'TRELLO':
      return 'trello';
    case 'AVOCADO':
      return 'avocado';
    case 'BEACON':
      return 'beacon';
    case 'COMPASS':
      return 'compass';
    case 'TEAMCENTRAL':
      return 'townsquare';
    case 'ANALYTICS':
      return 'avp';
    case 'FABRIC':
      return 'canvas';
    case 'MERCURY':
      return 'mercury';
    case 'UNIFIED_HELP':
      return 'unified-help';
    case 'ACCESS':
      return 'access';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian-together';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira-align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getAriKey" function was called with productType="${productType}", which does not yet have a ariKey defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }
      throw new Error(`Cannot determine ariKey of ${JSON.stringify(productType)}`);
    }
  }
}

/**
 * These keys are usually used in commerce filters, for example when fetching `entitlementInfo`
 * When adding new keys refer to https://bitbucket.org/atlassian/ccp-graphql-gateway/src/master/src/main/kotlin/io/atlassian/commerce/ccp/graphql/gateway/dto/hams/HamsProductKeys.kt
 *
 * @param {UiProductType} productType product type
 * @returns {HamsProductKey|undefined} Returns the hams product key, one of the standard product key sets used by commerce in CCP graphql through AGG
 */
const getHamsProductKey = (productType: UiProductType) => {
  switch (productType) {
    case UiProductType.CONFLUENCE:
      return 'confluence.ondemand';
    case UiProductType.JIRA_PRODUCT_DISCOVERY:
      return 'jira-product-discovery';
    case UiProductType.JIRA_SERVICE_DESK:
      return 'jira-servicedesk.ondemand';
    case UiProductType.JIRA_SOFTWARE:
      return 'jira-software.ondemand';
    default:
      return undefined;
  }
};

/**
 * These product keys are primarily used for xflow/product activation analytics
 *
 ***** When adding new keys, refer to https://bitbucket.org/atlassian/growth-analytics-pipeline-airflow/src/master/configurations/business/dim_model/subdag_configurations/dimensional_model/task_scripts/sql_scripts/07_dim_product.hql
 *
 * @param {UiProductType} productType product type
 * @returns {EmauProductKey} Returns the emau product key, one of the standard product key sets used by xflow
 */

function getEmauProductKey(productType: UiProductType): EmauProductKey {
  switch (productType) {
    case 'BITBUCKET':
      return 'bitbucket';
    case 'CONFLUENCE':
      return 'confluence';
    case 'STRIDE':
      return 'stride';
    case 'JIRA_CORE':
    case 'JIRA_WORK_MANAGEMENT':
      return 'jira_workManagement';
    case 'JIRA_SOFTWARE':
      return 'jira_software';
    case 'JIRA_SERVICE_DESK':
      return 'jira_serviceManagement';
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira_serviceManagement';
    case 'JIRA_CUSTOMER_SERVICE':
      return 'jira_customerService';
    case 'STATUSPAGE':
      return 'statuspage_manage';
    case 'TRELLO':
      return 'trello';
    case 'OPSGENIE':
      return 'opsgenie';
    case 'COMPASS':
      return 'compass';
    case 'JIRA_PRODUCT_DISCOVERY':
      return 'jira_productDiscovery';
    case 'BEACON':
      return 'beacon';
    case 'ATLASSIAN_TOGETHER':
      return 'atlassian_together';
    case 'FABRIC':
      return 'fabric';
    case 'JIRA_INCIDENT_MANAGER':
      return 'jira_ops';
    case 'ANALYTICS':
      return 'atlassian_analytics';
    case 'TEAMCENTRAL':
      return 'atlas';
    case 'ACCESS':
      return 'access';
    case 'LOOM':
      return 'loom';
    case 'ROVO':
      return 'rovo';
    case 'GOAL':
      return 'goal';
    case 'PROJECT':
      return 'project';
    case 'DEVAI':
      return 'devai';
    case 'RADAR':
      return 'radar';
    case 'JIRA_ALIGN':
      return 'jira_align';
    case 'PASSIONFRUIT':
      return 'passionfruit';
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
        // eslint-disable-next-line no-console
        console.log(
          `%c "getEmauProductKey" function was called with productType="${productType}", which does not yet have an emau product key defined! Please add a switch case for that`,
          'color: red; font-size: 24px;',
        );
      }
      throw new Error(`Cannot determine emauProductKey of ${JSON.stringify(productType)}`);
    }
  }
}

function getProductName(productId: string): ProductName {
  const isAgpWorkspacesEnabled = AkFeatureGates.checkGate('add_agp_to_workspaces_table');

  const productIdToProductNameMap = {
    conf: 'Confluence',
    jira: 'Jira Legacy',
    refapp: 'Refapp',
    'hipchat.cloud': 'Stride',
    'jira-align': 'Jira Align',
    'jira-core': 'Jira Work Management',
    'jira-work-management': 'Jira Work Management',
    'jira-software': 'Jira',
    'jira-admin': 'Jira Administration',
    'jira-incident-manager': 'Jira Ops',
    'jira-product-discovery': 'Jira Product Discovery',
    'jira-servicedesk': 'Jira Service Management',
    'jira-servicedesk.ondemand': 'Jira Service Desk Legacy',
    'jira-customer-service': 'Jira Customer Service',
    opsgenie: 'Opsgenie',
    statuspage: 'Statuspage',
    avocado: 'Avocado',
    beacon: 'Guard Detect',
    compass: 'Compass',
    avp: 'Atlassian Analytics',
    townsquare: 'Atlas',
    trello: 'Trello',
    bitbucket: 'Bitbucket',
    'atlassian-whiteboard': 'Fabric',
    canvas: 'Fabric',
    'cloud-admin': 'Atlassian Administration',
    mercury: 'Focus',
    'atlassian-together': 'Atlassian Together',
    access: isAgpWorkspacesEnabled ? 'Atlassian Guard Standard' : 'Atlassian Guard',
    'unified-help': 'Unified Help',
    'jira-family': AkFeatureGates.checkGate('admin_hub_team_25_appification') ? 'Jira Apps' : 'Jira Products',
    loom: 'Loom',
    'loom-attribution': 'Loom Attribution',
    guard: isAgpWorkspacesEnabled ? 'Atlassian Guard Standard' : 'Atlassian Guard',
    rovo: 'Atlassian Rovo',
    goal: 'Goals',
    project: 'Projects',
    devai: 'Rovo Dev Agents',
    radar: 'Talent',
    passionfruit: 'Passionfruit',
    'unknown-atlassian': AkFeatureGates.checkGate('admin_hub_team_25_appification') ? 'Unknown Atlassian app' : 'Unknown Atlassian product',
  };

  return productIdToProductNameMap[productId];
}

// Product ids from: https://workbench.staging.atl-paas.net/products?categoriesIn=CLOUD&billingSystem=CCP&productOwner=Atlassian
function getBundleType(productKeyOrId: string): WorkspaceBundle | undefined {
  switch (productKeyOrId) {
    case '3e6f105a-4f6d-44d1-8716-7e9468544d93':
      return WorkspaceBundle.StrategyCollection;
    case '3503c251-c247-4b55-a6ed-d4c8c3257b8b':
      return WorkspaceBundle.TeamworkCollection;
    default:
      return undefined;
  }
}

// Product ids from: https://workbench.staging.atl-paas.net/products?categoriesIn=CLOUD&billingSystem=CCP&productOwner=Atlassian
function getProductType(productKeyOrId: string): UiProductType | undefined {
  switch (productKeyOrId) {
    case 'Confluence':
    case 'confluence':
    case 'conf':
    case 'confluence.ondemand':
    case '6aab3f4e-cc71-474d-8d28-abef8b057808':
    case 'com.atlassian.enterprise-confluence':
      return UiProductType.CONFLUENCE;
    case 'Jira Align':
    case 'jira-align':
    case '4d114aa3-0046-4d74-9033-34bd4ed0f1db':
      return UiProductType.JIRA_ALIGN;
    case 'jira-family':
      return UiProductType.JIRA_FAMILY;
    case 'Jira Core':
    case 'jira-core':
    case 'Jira Work Management':
    case 'jira-core.ondemand':
      return UiProductType.JIRA_CORE;
    case 'JPD':
    case 'Jira Product Discovery':
    case 'jira-product-discovery':
    case 'a6f908c8-709c-45f4-9e2d-eca25b93b94a':
      return UiProductType.JIRA_PRODUCT_DISCOVERY;
    case 'JSD':
    case 'Jira Service Desk':
    case 'Jira Service Management':
    case 'jira-servicedesk':
    case 'jira-service-desk':
    case 'jira-servicedesk.ondemand':
    case 'com.atlassian.servicedesk.ondemand':
    case '08d4e010-47e9-4552-98bc-1d9e5f0fed45':
      return UiProductType.JIRA_SERVICE_DESK;
    case 'de63a248-1f93-46ed-b6ea-ab8e38af1c88':
    case 'com.atlassian.enterprise-jira-servicedesk':
      return UiProductType.JIRA_SERVICE_MANAGEMENT;
    case '46ccaa9e-cdb0-4995-8886-5827a5ff162b':
      return UiProductType.JIRA_WORK_MANAGEMENT;
    case 'Jira Ops':
    case 'jira-incident-manager':
    case 'jira-ops':
      return UiProductType.JIRA_INCIDENT_MANAGER;
    case 'JSW':
    case 'Jira Software':
    case 'jira-software':
    case 'jira-software.ondemand':
    case 'd8a847a4-cde4-4c50-8ea1-dc3d4193214f':
    case 'com.atlassian.enterprise-jira-software':
      return UiProductType.JIRA_SOFTWARE;
    case 'Jira':
    case 'jira':
      return UiProductType.JIRA_SOFTWARE;
    case 'jira-admin':
    case 'Jira Administration':
      return UiProductType.JIRA_ADMIN;
    case 'JCS':
    case 'Jira Customer Service':
    case 'jira-customer-service':
    case 'fbc7e14b-2f9c-45a9-9bcb-3993a4eac0ce':
      return UiProductType.JIRA_CUSTOMER_SERVICE;
    case 'Opsgenie':
    case 'opsgenie':
    case 'fdb4649d-ca4d-49f8-a8bf-ffa558a65551':
      return UiProductType.OPSGENIE;
    case 'Bitbucket':
    case 'bitbucket':
    case 'com.atlassian.bitbucket':
    case '3ce9ed1f-0561-484f-9e7a-e47efa3c0156':
    case '7b4bb2aa-e04f-44cb-b9ae-e752d28239e3':
      return UiProductType.BITBUCKET;
    case 'Trello':
    case 'trello':
    case 'trello.free':
    case '196be9d6-d01b-4eef-ac43-6a806ef56aa6':
      return UiProductType.TRELLO;
    case 'Stride':
    case 'stride':
    case 'chat':
    case 'hipchat.cloud':
      return UiProductType.STRIDE;
    case 'Statuspage':
    case 'statuspage':
    case 'fb892fd7-2de3-4a89-8583-49de8e7b7f55':
      return UiProductType.STATUSPAGE;
    case 'Avocado':
    case 'avocado':
      return UiProductType.AVOCADO;
    case 'Beacon':
    case 'beacon':
    case 'Guard Detect':
      return UiProductType.BEACON;
    case 'avp': // ARI owner. Also returned by UM/IOM.
    case 'atlassian-analytics-free': // Commerce key
    case 'Analytics':
    case 'f44da23e-5907-4d81-8a78-33bedee10969':
      return UiProductType.ANALYTICS;
    case 'Atlas':
    case 'townsquare':
      return UiProductType.TEAMCENTRAL;
    case 'Compass':
    case 'compass':
    case '6bce2ccb-4f57-46f5-812a-9dbf02dc28f2':
      return UiProductType.COMPASS;
    case 'Fabric':
    case 'canvas':
    case 'atlassian-whiteboard':
      return UiProductType.FABRIC;
    case 'Atlassian Administration':
    case 'cloud-admin':
    case 'unknown-atlassian':
    case 'Unknown Atlassian':
      return UiProductType.CLOUD_ADMIN;
    case 'Mercury':
    case 'mercury':
    case 'Focus':
      return UiProductType.MERCURY;
    case 'Unified Help':
    case 'unified-help':
    case '330b7c44-74df-42e2-98a6-30bf0fbd098b':
      return UiProductType.UNIFIED_HELP;
    case 'Atlassian Together':
    case 'atlassian-together':
      return UiProductType.ATLASSIAN_TOGETHER;
    case 'com.atlassian.identity-manager':
    case 'access':
    case 'Access':
    case 'Atlassian Access':
    case 'Atlassian Guard':
    case 'Guard':
    case 'ad8473d4-3a4d-4e36-827c-0a893439e9fa':
      return UiProductType.ACCESS;
    case 'Loom':
    case 'loom':
    case '7683a73e-423d-41a1-b43f-d497656f8b5d':
      return UiProductType.LOOM;
    case 'loom-attribution':
      return UiProductType.LOOM_ATTRIBUTION;
    case 'rovo':
    case 'Rovo':
    case 'rovo.ondemand':
    case 'Atlassian Rovo':
    case '91ae3e3f-138e-43be-98cc-48df03d13c32':
    case 'com.atlassian.rovo':
      return UiProductType.ROVO;
    case 'goal':
    case 'Goal':
      return UiProductType.GOAL;
    case 'project':
    case 'Project':
      return UiProductType.PROJECT;
    case 'Dev AI':
    case 'devai':
    case 'devai.ondemand':
    case 'Rovo Dev Agents':
    case 'rovo dev agents':
      return UiProductType.DEVAI;
    case 'Radar':
    case 'radar':
    case 'radar.ondemand':
    case 'Talent':
    case 'talent':
      return UiProductType.RADAR;
    case 'Passionfruit':
    case 'passionfruit':
    case '2b8ea083-125a-49b1-bfdb-d6ae767fe08a':
      return UiProductType.PASSIONFRUIT;
    default: {
      return undefined;
    }
  }
}

export interface ProductKeyConverterValues {
  productId: string | undefined;
  key: string | undefined;
  canonicalProductKey: string | undefined;
  type: UiProductType | undefined;
  name?: string;
}

/**
 * @deprecated Do not update this class. Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
 * ```ts
 * // Deprecated Usage
 * import { ProductKeyConverter } from '@adminhub/schema';
 * const product = new ProductKeyConverter({ type: UiProductType.CONFLUENCE });
 *
 * // New Usage
 * import { fromProductConfig } from '@adminhub/product-config';
 * const product = fromProductConfig({ type: UiProductType.CONFLUENCE });
 * ```
 */
export class ProductKeyConverter {
  private values: ProductKeyConverterValues;

  constructor({ canonicalProductKey, key, name, productId, type }: ProductKeyConverterValues) {
    if (![canonicalProductKey, key, productId, name, type].some((v) => v !== undefined)) {
      throw new Error('cannot determine product type - no values provided');
    }

    this.values = {
      canonicalProductKey,
      key,
      name,
      productId,
      type,
    };
  }

  public get productName(): string {
    return getProductName(this.productId);
  }

  public get productId(): string {
    return this.values.productId || getUserManagementProductId(this.type);
  }

  public get key(): string {
    return this.values.key || getIdOrgManagerProductKey(this.type);
  }

  public get canonicalProductKey(): string {
    return this.values.canonicalProductKey || getUserManagementCanonicalProductKey(this.type);
  }

  public get ariKey(): string {
    return getAriKey(this.type);
  }

  public get emauKey(): string {
    return getEmauProductKey(this.type);
  }

  public get hamsProductKey(): string | undefined {
    return getHamsProductKey(this.type);
  }

  public get sandboxProductKey(): string {
    return getSandboxProductKey(this.type);
  }

  public get releaseTrackProductKey(): string {
    return getReleaseTrackProductKey(this.type);
  }

  public get getBundleOrProductType(): UiProductType | WorkspaceBundle | undefined {
    if (!this.values.productId) {
      return undefined;
    }

    return getBundleType(this.values.productId) || getProductType(this.values.productId);
  }

  public get type(): UiProductType {
    if (this.values.type) {
      return this.values.type;
    }

    const productKeyOrId = this.values.productId || this.values.key || this.values.canonicalProductKey || this.values.name || '';
    const productType = getProductType(productKeyOrId);

    if (productType) {
      return productType;
    }
    if (process.env.NODE_ENV === 'development') {
      console.log('%cStop for a second!', 'color: red; font-size: 30px; font-weight: bold;'); // eslint-disable-line no-console
      // eslint-disable-next-line no-console
      console.log(
        `%c "ProductType" could not be determined for ${JSON.stringify(productKeyOrId)}! Please add a switch case for that`,
        'color: red; font-size: 24px;',
      );
    }

    throw new Error(`Cannot determine product type of ${JSON.stringify(productKeyOrId)}`);
  }

  public get isUiProductType(): boolean {
    return !!(
      this.values.type ||
      getProductType(this.values.productId || this.values.key || this.values.canonicalProductKey || this.values.name || '')
    );
  }

  public get uiProductType(): UiProductType | undefined {
    return (
      this.values.type ||
      getProductType(this.values.productId || this.values.key || this.values.canonicalProductKey || this.values.name || '')
    );
  }

  /**
   * @deprecated Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
   * ```ts
   * // Deprecated Usage
   * import { ProductKeyConverter } from '@adminhub/schema';
   * const product = ProductKeyConverter.fromType(UiProductType.CONFLUENCE);
   *
   * // New Usage
   * import { fromProductConfig } from '@adminhub/product-config';
   * const product = fromProductConfig({ type: UiProductType.CONFLUENCE });
   * ```
   */
  public static fromType(type: UiProductType): ProductKeyConverter {
    return new ProductKeyConverter({
      canonicalProductKey: undefined,
      key: undefined,
      productId: undefined,
      type,
    });
  }

  /**
   * @deprecated Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
   * ```ts
   * // Deprecated Usage
   * import { ProductKeyConverter } from '@adminhub/schema';
   * const product = ProductKeyConverter.fromCanonicalProductKey("some-product-key");
   *
   * // New Usage
   * import { fromProductConfig } from '@adminhub/product-config';
   * const product = fromProductConfig({ canonicalProductKey: "some-product-key" });
   * ```
   */
  public static fromCanonicalProductKey(canonicalProductKey: string): ProductKeyConverter {
    return new ProductKeyConverter({
      canonicalProductKey,
      key: undefined,
      productId: undefined,
      type: undefined,
    });
  }

  /**
   * @deprecated Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
   * ```ts
   * // Deprecated Usage
   * import { ProductKeyConverter } from '@adminhub/schema';
   * const product = ProductKeyConverter.fromKey("some-key");
   *
   * // New Usage
   * import { fromProductConfig } from '@adminhub/product-config';
   * const product = fromProductConfig({ key: "some-key" });
   * ```
   */
  public static fromKey(key: string): ProductKeyConverter {
    return new ProductKeyConverter({
      canonicalProductKey: undefined,
      key,
      productId: undefined,
      type: undefined,
    });
  }

  /**
   * @deprecated Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
   * ```ts
   * // Deprecated Usage
   * import { ProductKeyConverter } from '@adminhub/schema';
   * const product = ProductKeyConverter.fromId("some-product-id");
   *
   * // New Usage
   * import { fromProductConfig } from '@adminhub/product-config';
   * const product = fromProductConfig({ productId: "some-product-id" });
   * ```
   */
  public static fromId(productId: string): ProductKeyConverter {
    return new ProductKeyConverter({
      canonicalProductKey: undefined,
      key: undefined,
      productId,
      type: undefined,
    });
  }

  /**
   * @deprecated Use {@link fromProductConfig} in `@adminhub/product-config` instead. Reach out to !portkey in #help-admin-experience for more information.
   * ```ts
   * // Deprecated Usage
   * import { ProductKeyConverter } from '@adminhub/schema';
   * const product = ProductKeyConverter.fromName("Some Product Name");
   *
   * // New Usage
   * import { fromProductConfig } from '@adminhub/product-config';
   * const product = fromProductConfig({ name: "Some Product Name" });
   * ```
   */
  public static fromName(productName: ProductName): ProductKeyConverter {
    return new ProductKeyConverter({
      canonicalProductKey: undefined,
      key: undefined,
      productId: undefined,
      type: undefined,
      name: productName,
    });
  }
}
