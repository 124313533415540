import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

const enum ReleaseTrackScreenVariations {
  hasNoEntitlements = 'hasNoEntitlements',
}

type ScreenKeys = 'releaseTrackScreen' | 'changeTrackModal' | 'pendingChangesModal' | 'confirmTrackModal';
type UiKeys =
  | 'changeTrack'
  | 'releaseTracksLearnMore'
  | 'releaseTracks'
  | 'trackActions'
  | 'nextBundledRelease'
  | 'pastBundledReleases'
  | 'pastContinuousChanges'
  | 'pendingChanges'
  | 'selectTrackItem'
  | 'cancelChangeTrackModal'
  | 'nextChangeTrackModal'
  | 'cancelConfirmTrackModal'
  | 'confirmConfirmTrackModal'
  | 'releasesPendingChangesModal'
  | 'allChangesPendingChangesModal'
  | 'productsPendingChangesModal'
  | 'learnMoreReleaseTracksClickEvent';

type TrackKeys = 'releaseTracksChanged';

export const releaseTrackScreen = 'releaseTrackScreen';
const changeTrackModal = 'changeTrackModal';
const pendingChangesModal = 'pendingChangesModal';
const confirmTrackModal = 'confirmTrackModal';

export const releaseTrackEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/752522474/Release+Tracks+Analytics+Spec',

  screen: {
    releaseTrackScreen: (attributes: { numberOfEntitlements: number }): ScreenEvent => {
      const screenVariations = attributes.numberOfEntitlements === 0 ? ReleaseTrackScreenVariations.hasNoEntitlements : undefined;

      return {
        data: {
          name: releaseTrackScreen,
          attributes: {
            numberOfEntitlements: attributes.numberOfEntitlements,
            screenVariations,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25483',
      };
    },

    changeTrackModal: (): ScreenEvent => ({
      data: {
        name: changeTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25509',
    }),

    pendingChangesModal: (): ScreenEvent => ({
      data: {
        name: pendingChangesModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25504',
    }),

    confirmTrackModal: (): ScreenEvent => ({
      data: {
        name: confirmTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25525',
    }),
  },

  ui: {
    changeTrack: (attributes: { product: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'changeTrack',
        source: releaseTrackScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25527',
    }),

    releaseTracksLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'releaseTracksLearnMore',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25533',
    }),

    releaseTracks: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'releaseTracks',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25534',
    }),

    trackActions: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'trackActions',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25535',
    }),

    nextBundledRelease: (attributes: { product: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'nextBundledRelease',
        source: releaseTrackScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25536',
    }),

    pastBundledReleases: (attributes: { product: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'pastBundledReleases',
        source: releaseTrackScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25537',
    }),

    pastContinuousChanges: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'pastContinuousChanges',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25538',
    }),

    pendingChanges: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'pendingChanges',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25539',
    }),

    selectTrackItem: (attributes: { trackChanged: 'continuous' | 'bundled' | 'preview' | null }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.SelectItem,
        actionSubjectId: 'selectTrackItem',
        source: changeTrackModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25540',
    }),

    cancelChangeTrackModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: changeTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),

    nextChangeTrackModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'next',
        source: changeTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25541',
    }),

    cancelConfirmTrackModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: confirmTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),

    confirmConfirmTrackModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: confirmTrackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    releasesPendingChangesModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'releases',
        source: pendingChangesModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25545',
    }),

    allChangesPendingChangesModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'allChanges',
        source: pendingChangesModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25550',
    }),

    productsPendingChangesModal: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'products',
        source: pendingChangesModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25552',
    }),

    learnMoreReleaseTracksClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreReleaseTracks',
        source: releaseTrackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58001',
    }),
  },

  track: {
    releaseTracksChanged: (attributes: { trackChanged: 'continuous' | 'bundled'; siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.ReleaseTracks,
        actionSubjectId: attributes.siteId,
        source: changeTrackModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25555',
    }),
  },

  operational: {},
};
