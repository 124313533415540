import type { UiProductType, WorkspacePlan } from '@adminhub/graphql-types';

import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UiKeys = 'sortedWorkspaceTable' | 'clickedNext' | 'clickedPrevious' | 'updatedPageSize' | 'clickedRefresh' | 'seeBillingInfo';
export type workspacesSources = 'orgOverviewScreen' | 'productsOverviewScreen' | 'releaseTracksScreen' | 'orgProductListScreen';

export const workspacesEvents: FeatureEvents<string, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1287229913/Workspaces+Table+Analytics',

  screen: {},
  ui: {
    sortedWorkspaceTable: ({
      source,
      column,
      sortDirection,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      column: string;
      sortDirection: 'asc' | 'desc';
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'sortedTable',
        source,
        attributes: {
          column,
          sortDirection,
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32399',
    }),
    clickedRefresh: ({
      source,
      sortDirection,
      pageSize,
      totalRecords,
    }: {
      source: workspacesSources;
      pageSize: number;
      sortDirection: 'asc' | 'desc';
      totalRecords: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesPaginationRefresh',
        source,
        attributes: {
          sortDirection,
          pageSize,
          totalRecords,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42642',
    }),
    clickedNext: ({
      source,
      sortDirection,
      pageSize,
      totalRecords,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      pageSize: number;
      sortDirection: 'asc' | 'desc';
      totalRecords: number;
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesPaginationNext',
        source,
        attributes: {
          sortDirection,
          pageSize,
          totalRecords,
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38635',
    }),
    clickedPrevious: ({
      source,
      sortDirection,
      pageSize,
      totalRecords,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      pageSize?: number;
      sortDirection: 'asc' | 'desc';
      totalRecords: number;
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesPaginationPrevious',
        source,
        attributes: {
          sortDirection,
          pageSize,
          totalRecords,
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38636',
    }),
    updatedPageSize: ({ source, pageSize }: { source: workspacesSources; pageSize?: number }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'workSpacesPaginationSize',
        source,
        attributes: {
          pageSize,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38637',
    }),
    seeBillingInfo: ({ source }: { source: workspacesSources }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessBillingInfo',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55170',
    }),
  },
  track: {},
  operational: {},
};
