import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export enum JsmPortalCustomersScreens {
  addCustomerIdentityProviderScreen = 'addCustomerIdentityProviderScreen',
  customerIdentityProviderSamlScreen = 'customerIdentityProviderSamlScreen',
  customerIdentityProviderPickerScreen = 'customerIdentityProviderPickerScreen',
  customerConnectIdentityProviderScreen = 'customerConnectIdentityProviderScreen',
  customerIdentityProvidersListScreen = 'customerIdentityProvidersListScreen',
  setupCustomerSamlFocusedTask = 'setupCustomerSamlFocusedTask',
  customerIdentityProviderDetailsScreen = 'customerIdentityProviderDetailsScreen',
  jsdPortalCustomersScreen = 'jsdPortalCustomersScreen',
}

type ScreenKeys =
  | 'addCustomerIdentityProviderScreenEvent'
  | 'customerIdentityProviderSamlScreenEvent'
  | 'customerIdentityProviderPickerScreenEvent'
  | 'customerConnectIdentityProviderScreenEvent'
  | 'customerIdentityProvidersListScreenEvent'
  | 'setupCustomerSamlFocusedTaskEvent'
  | 'customerIdentityProviderDetailsScreenEvent';

type UIEventKeys =
  | 'jsdPortalCustomersScreenClickEvent'
  | 'viewCustomerIdentityProvidersClickEvent'
  | 'learnMoreLinkClickEvent'
  | 'learnMoreLinkConnectClickEvent'
  | 'managePolicyInJiraClickEvent'
  | 'managePolicyInJiraFlagClickEvent'
  | 'saveSamlConfigurationFocusedTaskClickEvent'
  | 'managePolicyInJiraFocusedTaskClickEvent'
  | 'cancelSetupSamlClickEvent';

type TrackEventKeys =
  | 'createdIdentityProviderTrackEvent'
  | 'disconnectedIdentityProviderTrackEvent'
  | 'createdSamlConfigurationTrackEvent'
  | 'deletedSamlConfigurationTrackEvent'
  | 'updatedSamlConfigurationTrackEvent';

// TODO: Replace "any"s with string literal keys
export const jsmIdentityProvidersEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, any> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2545447819/JSM+SAML+SSO+ET+Analytics+Spec',
  screen: {
    addCustomerIdentityProviderScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.addCustomerIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51994',
    }),
    customerIdentityProviderSamlScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.customerIdentityProviderSamlScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51995',
    }),
    customerIdentityProviderPickerScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.customerIdentityProviderPickerScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51996',
    }),
    customerConnectIdentityProviderScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.customerConnectIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51997',
    }),
    customerIdentityProvidersListScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.customerIdentityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51998',
    }),
    setupCustomerSamlFocusedTaskEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.setupCustomerSamlFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51999',
    }),
    customerIdentityProviderDetailsScreenEvent: (): ScreenEvent => ({
      data: {
        name: JsmPortalCustomersScreens.customerIdentityProviderDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52000',
    }),
  },
  ui: {
    jsdPortalCustomersScreenClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMoreAccessButton',
        source: JsmPortalCustomersScreens.jsdPortalCustomersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/eventcatalog/view/13492',
    }),
    viewCustomerIdentityProvidersClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewCustomerIdentityProviders',
        source: JsmPortalCustomersScreens.jsdPortalCustomersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52770',
    }),
    learnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source: JsmPortalCustomersScreens.customerIdentityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    learnMoreLinkConnectClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source: JsmPortalCustomersScreens.customerConnectIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    managePolicyInJiraClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'managePolicyInJira',
        source: JsmPortalCustomersScreens.customerIdentityProviderDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52771',
    }),
    managePolicyInJiraFlagClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'managePolicyInJiraFlag',
        source: JsmPortalCustomersScreens.customerIdentityProviderDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52772',
    }),
    saveSamlConfigurationFocusedTaskClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveSamlConfiguration',
        source: JsmPortalCustomersScreens.setupCustomerSamlFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43909',
    }),
    managePolicyInJiraFocusedTaskClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'managePolicyInJira',
        source: JsmPortalCustomersScreens.setupCustomerSamlFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52773',
    }),
    cancelSetupSamlClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSetupSaml',
        source: JsmPortalCustomersScreens.setupCustomerSamlFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43908',
    }),
  },
  track: {
    createdIdentityProviderTrackEvent: (attributes: { idpId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubjectId: 'identityProviderCreated',
        actionSubject: TrackActionSubject.IdentityProvider,
        source: JsmPortalCustomersScreens.addCustomerIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42774',
    }),
    disconnectedIdentityProviderTrackEvent: (attributes: { idpId: string; routeId?: string }): TrackEvent => ({
      data: {
        action: TrackAction.Disconnected,
        actionSubjectId: 'identityProviderDisconnected',
        actionSubject: TrackActionSubject.IdentityProvider,
        source: JsmPortalCustomersScreens.customerIdentityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43904',
    }),
    createdSamlConfigurationTrackEvent: (attributes: { idpId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubjectId: 'samlConfigurationCreated',
        actionSubject: TrackActionSubject.SamlConfiguration,
        source: JsmPortalCustomersScreens.setupCustomerSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52726',
    }),
    deletedSamlConfigurationTrackEvent: (attributes: { idpId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubjectId: 'samlConfigurationDeleted',
        actionSubject: TrackActionSubject.SamlConfiguration,
        source: JsmPortalCustomersScreens.customerIdentityProviderSamlScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52727',
    }),
    updatedSamlConfigurationTrackEvent: (attributes: { idpId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubjectId: 'identityProviderUpdated',
        actionSubject: TrackActionSubject.SamlConfiguration,
        source: JsmPortalCustomersScreens.customerIdentityProviderSamlScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52728',
    }),
  },
  operational: {},
};
