import * as React from 'react';

import type { AnalyticsClient } from './analytics-client';
import { NewAnalyticsContext } from './new-analytics-context';

export interface AnalyticsClientProps {
  analyticsClient: AnalyticsClient;
}

export declare type WithAnalyticsClient<P> = P & {
  analyticsClient: AnalyticsClient;
};

export function withAnalyticsClient<TOwnProps>(
  WrappedComponent: React.ComponentType<WithAnalyticsClient<TOwnProps>>,
): React.ComponentClass<TOwnProps> {
  return class extends React.Component<TOwnProps> {
    public static WrappedComponent = WrappedComponent;

    public render() {
      return <NewAnalyticsContext.Consumer>{this.wrappedComponentRenderer}</NewAnalyticsContext.Consumer>;
    }

    private wrappedComponentRenderer = (analyticsClient: AnalyticsClient): React.ReactNode => (
      <WrappedComponent {...this.props} analyticsClient={analyticsClient} />
    );
  };
}
