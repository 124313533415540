import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';

import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'accessAdModalScreenEvent';

type UiKeys =
  | 'accessAdFindOutMoreButtonUIEvent'
  | 'accessAdOpenMenuButtonUIEvent'
  | 'accessAdNotInterestedButtonUIEvent'
  | 'accessAdWhySeeingThisButtonUIEvent'
  | 'accessAdPrivacyLinkUIEvent';

export const accessAdEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2677604602/Access+SMB+Experiment+Analytic+Spec',
  screen: {
    accessAdModalScreenEvent: (): ScreenEvent => {
      const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/54669';

      return {
        data: {
          name: 'accessAdWhyAmISeeingThisModal',
        },
        dataPortalLink,
      };
    },
  },
  ui: {
    accessAdFindOutMoreButtonUIEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessAdFindOutMore',
        source: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54664',
    }),

    accessAdOpenMenuButtonUIEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAccessAdMenu',
        source: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54665',
    }),

    accessAdNotInterestedButtonUIEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessAdMenuNotInterested',
        source: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54666',
    }),

    accessAdWhySeeingThisButtonUIEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessAdMenuWhyAmISeeingThis',
        source: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54667',
    }),

    accessAdPrivacyLinkUIEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'accessAdPrivacyLink',
        source: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54668',
    }),
  },
  track: {},
  operational: {},
};
