/* eslint-disable import/first */
// Since we have side effects, the order of these imports matter.
// These must come before other imports.
/* eslint-disable import/no-unassigned-import */
import '@atlaskit/css-reset';
// eslint-disable-next-line @typescript-eslint/tslint/config
import './polyfills';

// preload first chunks
import '@adminhub/application/src/ui/preloader';

import { initSentry } from '@adminhub/sentry';

// the idea of the following construction is to preload "next" js chunk
// and let it load while browser would execute "the rest" JS

/* eslint-disable */
(async () => {
  // load the rest of application after "two ticks" to let
  // browser start network requests.
  await 1;
  await 2;

  initSentry();

  // we are using import in "eager" mode - it does not cause code splitting
  // we are just deffering the code executing, nothing more

  await import(/* webpackMode: "eager" */ '@adminhub/application/src/ui/react-root');
})();
/* eslint-enable */
