import type { PasswordPolicyStrength, TwoFactorOption } from '@adminhub/auth-policies-types';
import type { ApiTokenAccessMode, AuthPolicySocialLoginStatus, AuthSsoConfigurationType } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export enum AuthPolicyScreens {
  authPolicyListScreen = 'authPolicyListScreen',
  authPolicyListScreenFull = 'authPolicyListScreenFull',
  authPolicySettingsScreen = 'authPolicySettingsScreen',
  authPolicyMembersScreen = 'authPolicyMembersScreen',
  authPolicyAddMembersModal = 'authPolicyAddMembersModal',
  authPolicyAddPolicyModal = 'authPolicyAddPolicyModal',
  authPolicyResetPasswordModal = 'authPolicyResetPasswordModal',
  authPolicyResetSessionModal = 'authPolicyResetSessionModal',
  authPolicyRevokeApiTokensModal = 'authPolicyRevokeApiTokensModal',
  deleteAuthPolicyModal = 'deleteAuthPolicyModal',
  changeDefaultAuthPolicyModal = 'changeDefaultAuthPolicyModal',
  changeUserPolicyModal = 'changeUserPolicyModal',
  restoreStandardPolicyFocusedTask = 'restoreStandardPolicyFocusedTask',
  convertToBasicPolicyFocusedTask = 'convertToBasicPolicyFocusedTask',
}

type ScreenKeys =
  | 'authPolicyListScreenEvent'
  | 'authPolicySettingsScreenEvent'
  | 'authPolicyMembersScreenEvent'
  | 'authPolicyAddPolicyModalScreenEvent'
  | 'authPolicyAddMembersModalScreenEvent'
  | 'authPolicyResetPasswordModalScreenEvent'
  | 'authPolicyResetSessionModalScreenEvent'
  | 'authPolicyRevokeApiTokensModalScreenEvent'
  | 'deleteAuthPolicyModalScreenEvent'
  | 'changeDefaultAuthPolicyModalScreenEvent'
  | 'restoreStandardPolicyFocusedTaskScreenEvent'
  | 'convertToBasicPolicyFocusedTaskScreenEvent';

type UIEventKeys =
  | 'addPolicyClickEvent'
  | 'freeTrialClickEvent'
  | 'exploreAtlassianGuardButtonClicked'
  | 'accessAdPrivacyLinkClickEvent'
  | 'createPolicyClickEvent'
  | 'updateAuthPolicyClickEvent'
  | 'resetUsersPasswordClickEvent'
  | 'revokeApiTokensClickEvent'
  | 'deletePolicyClickEvent'
  | 'editPolicyClickEvent'
  | 'changeDefaultPolicyClickEvent'
  | 'setDefaultPolicyClickEvent'
  | 'cancelButtonClickEvent'
  | 'addMemberClickEvent'
  | 'addMembersClickEvent'
  | 'uploadMembersCSVClickEvent'
  | 'useGSuiteClickEvent'
  | 'useSamlClickEvent'
  | 'setTwoFactorEnforcementClickEvent'
  | 'setSocialLoginAllowanceClickEvent'
  | 'setPasswordStrengthClickEvent'
  | 'setPasswordExpirationClickEvent'
  | 'changeApiTokenAuthSettingClickEvent'
  | 'convertToBasicPolicyClickEvent'
  | 'confirmConvertToBasicPolicyClickEvent'
  | 'restoreToStandardPolicyClickEvent'
  | 'confirmRestoreToStandardPolicyClickEvent'
  | 'flexAuthPostMigrationShownEvent'
  | 'learnMoreFlexAuthPostMigrationClickEvent'
  | 'flexAuthPreMigrationShownEvent'
  | 'learnMoreClickEvent'
  | 'dismissFlexAuthPreMigrationBannerClickEvent'
  | 'dismissFlexAuthPostMigrationBannerClickEvent'
  | 'leanMoreAuthPoliciesClickEvent'
  | 'learnMoreSSOClickEvent'
  | 'learnMoreTwoFactorClickEvent'
  | 'learnMoreThirdPartyLoginClickEvent'
  | 'learnMorePasswordRequirementsClickEvent'
  | 'learnMoreIdleSessionDurationClickEvent'
  | 'resetUsersSessionClickEvent';

type TrackEventKeys =
  | 'createPolicyTrackEvent'
  | 'updatePolicyTrackEvent'
  | 'deletePolicyTrackEvent'
  | 'defaultAuthPolicyTrackEvent'
  | 'updateMembersAuthPolicyTrackEvent'
  | 'convertAuthPolicyTypeTrackEvent';

interface PolicyIdAttributes {
  policyId: string;
}

interface AddMembersToPolicyAttributes extends PolicyIdAttributes {
  usersAdded: string[];
  userCount: number;
  method: string;
}

interface TwoFactorEnforcementAttributes extends PolicyIdAttributes {
  setting: TwoFactorOption;
}

interface SocialLoginAllowanceAttributes extends PolicyIdAttributes {
  setting: AuthPolicySocialLoginStatus;
}

interface ApiTokenAuthAttributes extends PolicyIdAttributes {
  setting: ApiTokenAccessMode;
}

interface PasswordStrengthAttributes {
  strength: PasswordPolicyStrength;
}

interface ExpiryDaysAttributes {
  expiryDays: number;
}

interface UpdatePolicyTrackEventAttributes {
  singleSignOn?: AuthSsoConfigurationType;
  mfa: TwoFactorOption;
  passwordStrength?: PasswordPolicyStrength;
  expiryDays?: number;
  mobileAppSessionExpiry?: number;
}

interface ConvertAuthPolicyTypeAttributes {
  newPolicyType: 'STANDARD' | 'BASIC';
}

interface PolicyIdAttributes {
  policyId: string;
}

export const authPolicyEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/765732576/Flexible+Authentication+Policies+Analytics+Spec+M1',
  screen: {
    authPolicyListScreenEvent: (attributes: { claimedDomain: boolean; hasAccess: boolean }): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27012',
    }),
    authPolicySettingsScreenEvent: (attributes: PolicyIdAttributes): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27350',
    }),
    authPolicyMembersScreenEvent: (attributes: PolicyIdAttributes): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyMembersScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27351',
    }),
    authPolicyAddPolicyModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyAddPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27352',
    }),
    authPolicyAddMembersModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyAddMembersModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27576',
    }),
    authPolicyResetPasswordModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyResetPasswordModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27375',
    }),
    authPolicyResetSessionModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyResetSessionModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40001',
    }),
    authPolicyRevokeApiTokensModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.authPolicyRevokeApiTokensModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79894',
    }),
    deleteAuthPolicyModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.deleteAuthPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27374',
    }),
    changeDefaultAuthPolicyModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.changeDefaultAuthPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27426',
    }),
    restoreStandardPolicyFocusedTaskScreenEvent: (): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.restoreStandardPolicyFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32706',
    }),
    convertToBasicPolicyFocusedTaskScreenEvent: (attributes: {
      hasIdpUsers: boolean;
      isDefaultPolicy: boolean;
      hasBasicPolicy: boolean;
    }): ScreenEvent => ({
      data: {
        name: AuthPolicyScreens.convertToBasicPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32707',
    }),
  },
  ui: {
    addPolicyClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addPolicy',
        source: AuthPolicyScreens.authPolicyListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26982',
    }),
    freeTrialClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'freeTrialButton',
        source: AuthPolicyScreens.authPolicyListScreenFull,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15443',
    }),
    exploreAtlassianGuardButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exploreAtlassianGuard',
        source: AuthPolicyScreens.authPolicyListScreenFull,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67806',
    }),
    accessAdPrivacyLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'accessAdPrivacyLink',
        source: 'accessAdWhyAmISeeingThisModal',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54668',
    }),
    createPolicyClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createPolicy',
        source: AuthPolicyScreens.authPolicyAddPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26983',
    }),
    resetUsersPasswordClickEvent: (attributes: PolicyIdAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resetUsersPasswords',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27058',
    }),
    resetUsersSessionClickEvent: (attributes: PolicyIdAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resetSession',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40000',
    }),
    revokeApiTokensClickEvent: (attributes: PolicyIdAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'revokeApiTokens',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79896',
    }),
    deletePolicyClickEvent: (attributes: PolicyIdAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deletePolicy',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27195',
    }),
    editPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editPolicy',
        source: AuthPolicyScreens.authPolicyListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27575',
    }),
    changeDefaultPolicyClickEvent: (attributes: { newPolicyId: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'changeDefaultPolicy',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27189',
    }),
    setDefaultPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setDefaultPolicy',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27188',
    }),
    updateAuthPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateAuthPolicy',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27066',
    }),
    cancelButtonClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    addMemberClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addMember',
        source: AuthPolicyScreens.authPolicyMembersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27067',
    }),
    addMembersClickEvent: (attributes: AddMembersToPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addMembers',
        source: AuthPolicyScreens.authPolicyAddMembersModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27068',
    }),
    uploadMembersCSVClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'uploadMembersCSV',
        source: AuthPolicyScreens.authPolicyAddMembersModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27069',
    }),
    useGSuiteClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'useGSuite',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27104',
    }),
    useSamlClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'useSaml',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27105',
    }),
    setTwoFactorEnforcementClickEvent: (attributes: TwoFactorEnforcementAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'twoFactorEnforcement',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27196',
    }),
    setSocialLoginAllowanceClickEvent: (attributes: SocialLoginAllowanceAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'socialLoginAllowance',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47286',
    }),
    setPasswordStrengthClickEvent: (attributes: PasswordStrengthAttributes): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'passwordStrength',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27197',
    }),
    setPasswordExpirationClickEvent: (attributes: ExpiryDaysAttributes): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'passwordExpired',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27198',
    }),
    changeApiTokenAuthSettingClickEvent: (attributes: ApiTokenAuthAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'changeApiTokenAuthSetting',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52517',
    }),
    convertToBasicPolicyClickEvent: ({ attributes, source }: { attributes: PolicyIdAttributes; source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'convertToBasicPolicyButton',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32718',
    }),
    confirmConvertToBasicPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmConvertToBasicPolicyButton',
        source: AuthPolicyScreens.convertToBasicPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32719',
    }),
    learnMoreClickEvent: (attributes: { orgId: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreFlexAuthPreMigration',
        actionSubject: ActionSubject.Link,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33138',
    }),
    flexAuthPreMigrationShownEvent: (attributes: { orgId: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'flexAuthPreMigration',
        actionSubject: ActionSubject.Notification,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33139',
    }),
    restoreToStandardPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'restoreToStandardPolicyButton',
        source: AuthPolicyScreens.authPolicySettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32720',
    }),
    confirmRestoreToStandardPolicyClickEvent: (attributes: PolicyIdAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRestoreToStandardPolicyButton',
        source: AuthPolicyScreens.restoreStandardPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32721',
    }),
    flexAuthPostMigrationShownEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Notification,
        actionSubjectId: 'flexAuthPostMigration',
        source: AuthPolicyScreens.authPolicyListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33149',
    }),
    learnMoreFlexAuthPostMigrationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreFlexAuthPostMigration',
        source: AuthPolicyScreens.authPolicyListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33150',
    }),
    dismissFlexAuthPreMigrationBannerClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismissFlexAuthPreMigrationBanner',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33726',
    }),
    dismissFlexAuthPostMigrationBannerClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismissFlexAuthPostMigrationBanner',
        source: AuthPolicyScreens.authPolicyListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33727',
    }),
    leanMoreAuthPoliciesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAuthPolicies',
        source: AuthPolicyScreens.authPolicyListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57991',
    }),
    learnMoreSSOClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreSSO',
        source: AuthPolicyScreens.authPolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57992',
    }),
    learnMoreTwoFactorClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreTwoFactor',
        source: AuthPolicyScreens.authPolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57993',
    }),
    learnMoreThirdPartyLoginClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreThirdPartyLogin',
        source: AuthPolicyScreens.authPolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57994',
    }),
    learnMorePasswordRequirementsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMorePasswordRequirements',
        source: AuthPolicyScreens.authPolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57995',
    }),
    learnMoreIdleSessionDurationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreIdleSessionDuration',
        source: AuthPolicyScreens.authPolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57996',
    }),
  },
  operational: {},
  track: {
    createPolicyTrackEvent: (attributes: Partial<PolicyIdAttributes>): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.AuthPolicy,
        source: AuthPolicyScreens.authPolicyAddPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27013',
    }),
    updatePolicyTrackEvent: (attributes: UpdatePolicyTrackEventAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.AuthPolicy,
        source: AuthPolicyScreens.authPolicyAddPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27427',
    }),
    deletePolicyTrackEvent: (attributes: PolicyIdAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.AuthPolicy,
        source: AuthPolicyScreens.deleteAuthPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27194',
    }),
    defaultAuthPolicyTrackEvent: (attributes: PolicyIdAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.AuthPolicy,
        source: AuthPolicyScreens.changeDefaultAuthPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27187',
    }),
    updateMembersAuthPolicyTrackEvent: (attributes: { numberOfUsers: number }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.MembersAuthPolicy,
        source: AuthPolicyScreens.authPolicyAddMembersModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27429',
    }),
    convertAuthPolicyTypeTrackEvent: ({
      attributes,
      source,
      policyId,
    }: {
      attributes: ConvertAuthPolicyTypeAttributes;
      policyId: string;
      source: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Converted,
        actionSubject: TrackActionSubject.AuthPolicyType,
        actionSubjectId: policyId,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32722',
    }),
  },
};
