import { z } from 'zod';

import { UiProductType } from '@adminhub/graphql-types';

const UiProductTypeEnum = z.nativeEnum(UiProductType).describe('The UI product type.');

const ProductConfigJsonSchema = z
  .object({
    type: UiProductTypeEnum,
    productName: z.string().describe('The name of the product.'),
    idOrgManagerProductKey: z
      .string()
      .optional()
      .describe(
        'The `productKey` used by `id-org-manager` service. See https://stash.atlassian.com/projects/IPS/repos/id-org-manager/browse/src/main/java/com/atlassian/orgmanager/services/products/ProductsData.java. Defaults to the product type.',
      ),
    userManagementProductId: z
      .string()
      .optional()
      .describe(
        'The `productId` that is used by `user-management` service. See https://stash.atlassian.com/projects/UN/repos/user-management/browse/hipster-user-management/src/main/java/com/atlassian/usermanagement/nextgen/model/product/ProductId.java. Defaults to the product type.',
      ),
    userManagementCanonicalProductKey: z
      .string()
      .optional()
      .describe(
        'The `canonicalProductKey` that is used by `user-management` service. See https://bitbucket.org/atlassian/user-management/src/master/user-management-util/src/main/java/com/atlassian/usermanagement/productconfig/products/CanonicalProduct.java. Defaults to the product type.',
      ),
    sandboxProductKey: z
      .string()
      .optional()
      .describe(
        'The `sandboxProductKey` that is used by `sandbox-service`. See https://bitbucket.org/atlassian/sandbox-service/src/master/common/src/main/kotlin/com/atlassian/sandbox/model/SandboxableProductKey.kt. Defaults to the product type.',
      ),
    releaseTrackProductKey: z
      .string()
      .optional()
      .describe(
        'The `releaseTrackProductKey` that is used by `release-track`. See https://bitbucket.org/atlassian/release-track/src/master/src/main/java/io/atlassian/micros/releasetrack/model/Product.java. Defaults to the product type.',
      ),
    ariResourceOwner: z
      .string()
      .optional()
      .describe(
        'The productKey portion of the ARI (i.e. ari:cloud:productKey::site/<id>). See https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/. Defaults to the product type.',
      ),
    emauProductKey: z
      .string()
      .optional()
      .describe(
        'The product key used for xflow/product activation analytics. The emau product key, one of the standard product key sets used by xflow. See https://bitbucket.org/atlassian/growth-analytics-pipeline-airflow/src/master/configurations/business/dim_model/subdag_configurations/dimensional_model/task_scripts/sql_scripts/07_dim_product.hql. Defaults to the product type.',
      ),
    hamsProductKey: z
      .string()
      .optional()
      .describe(
        'The HAMS product key, one of the standard product key sets used by commerce in CCP graphql through AGG. See https://bitbucket.org/atlassian/ccp-graphql-gateway/src/master/src/main/kotlin/io/atlassian/commerce/ccp/graphql/gateway/dto/hams/HamsProductKeys.kt. Will be undefiend if not specified.',
      ),
    // TODO: Add more fields
  })
  .strict();

export type ProductConfigJson = z.infer<typeof ProductConfigJsonSchema>;

export const ProductConfigMapJsonSchema = z
  .object({
    $schema: z.string().optional(),
    products: z.record(UiProductTypeEnum, ProductConfigJsonSchema).superRefine((data, ctx) => {
      Object.entries(data).forEach(([productType, productConfig]) => {
        if (productType === productConfig.type) return;

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The product type "${productType}" does not match the type "${productConfig.type}" in the product configuration.`,
        });
      });
    }),
  })
  .strict();

export type ProductConfigMapJson = z.infer<typeof ProductConfigMapJsonSchema>;
