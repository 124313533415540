import * as React from 'react';

import { Analytics } from '@adminhub/reactive-vars';

interface OwnProps {
  value: Analytics.ReferrerType;
}

export const Referrer: React.FC<OwnProps> = ({ children, value }) => {
  const onClick = () => Analytics.reactiveVariables.referrer(value);

  return (
    // eslint-disable-next-line
    <div onClick={onClick}>{children}</div>
  );
};
