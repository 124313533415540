import * as React from 'react';

import type { Milestone } from './types';
import type { PageTimingProps } from './with-page-timing';
import { withPageTiming } from './with-page-timing';

interface OwnProps {
  isLoading?: boolean;
  isError?: boolean;
  pageId: string;
  milestone: Milestone;
}

export class PageTimingLoadReporterImpl extends React.Component<OwnProps & PageTimingProps> {
  public componentDidMount() {
    if (!this.props.isLoading && !this.props.isError) {
      this.props.pageTiming.reportPageLoaded(this.getId());
    }
  }

  public componentDidUpdate() {
    if (!this.props.isLoading && !this.props.isError) {
      this.props.pageTiming.reportPageLoaded(this.getId());
    }
  }
  public render() {
    return null;
  }

  private getId = () => `${this.props.pageId}:${this.props.milestone}`;
}

export const PageTimingLoadReporter = withPageTiming(PageTimingLoadReporterImpl);
