import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'confirmToggleActionModalScreenEvent' | 'adminSecurityControlsComponentScreenEvent';
type UIEventKeys =
  | 'openConfirmToggleActionModalButtonClickEvent'
  | 'cancelActionModalButtonClickEvent'
  | 'confirmActionModalButtonClickEvent';

// These are just 'string' for now because FeatureEvents requires 4 typed arguments.
// For the events which names aren't decided yet, or the event types that we're not using.
type TrackEventKeys = string;
type OperationalKeys = string;

export const adminSecurityControlsScreens = {
  confirmToggleActionModal: 'confirmToggleActionModal',
  adminSecurityControlsComponent: 'adminSecurityControlsComponent',
};

export const adminSecurityControlsButtons = {
  openConfirmToggleActionModalButton: 'openConfirmToggleActionModalButton',
  cancelActionModalButton: 'cancelActionModalButton',
  confirmActionModalButton: 'confirmActionModalButton',
};

export const adminSecurityControlsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1521970879/Admin+security+controls+-+Analytics+spec',
  screen: {
    confirmToggleActionModalScreenEvent: (attributes: { isUserInstalledAppsAllowed: boolean }): ScreenEvent => ({
      data: {
        name: adminSecurityControlsScreens.confirmToggleActionModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42340',
    }),

    adminSecurityControlsComponentScreenEvent: (attributes: { cloudId: string }): ScreenEvent => ({
      cloudId: attributes.cloudId,
      data: {
        name: adminSecurityControlsScreens.adminSecurityControlsComponent,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42427',
    }),
  },
  track: {},
  ui: {
    openConfirmToggleActionModalButtonClickEvent: (attributes: { isUserInstalledAppsAllowed: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: adminSecurityControlsButtons.openConfirmToggleActionModalButton,
        actionSubject: ActionSubject.Button,
        source: adminSecurityControlsScreens.adminSecurityControlsComponent,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42425',
    }),

    cancelActionModalButtonClickEvent: (attributes: { isUserInstalledAppsAllowed: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: adminSecurityControlsButtons.cancelActionModalButton,
        actionSubject: ActionSubject.Button,
        source: adminSecurityControlsScreens.confirmToggleActionModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42417',
    }),

    confirmActionModalButtonClickEvent: (attributes: { isUserInstalledAppsAllowed: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: adminSecurityControlsButtons.confirmActionModalButton,
        actionSubject: ActionSubject.Button,
        source: adminSecurityControlsScreens.confirmToggleActionModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42426',
    }),
  },
  operational: {},
};
