import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'experimentControlsHomeScreen' | 'experimentControlsConfirmModal' | 'experimentControlsFeedbackModal';
type UiKeys =
  | 'experimentControlsOptOutToggle'
  | 'experimentControlsOptOutModalConfirmButton'
  | 'experimentControlsOptOutModalCancelButton'
  | 'experimentControlsOptOutModalExit'
  | 'experimentControlsFeedbackSubmitButton'
  | 'experimentControlsFeedbackSkipButton';

type TrackKeys = 'experimentControlsOptedOut' | 'experimentControlsOptedIn';

export enum ExperimentControlScreens {
  experimentControlsHomeScreen = 'experimentControlsHomeScreen',
  experimentControlsConfirmModal = 'experimentControlsConfirmModal',
  experimentControlsFeedbackModal = 'experimentControlsFeedbackModal',
}
export const experimentControlsHomeScreen = 'experimentControlsHomeScreen';
export const experimentControlsConfirmModal = 'experimentControlsConfirmModal';
export const experimentControlsFeedbackModal = 'experimentControlsFeedbackModal';
export type ExperimentControlsEventSource =
  | typeof experimentControlsHomeScreen
  | typeof experimentControlsConfirmModal
  | typeof experimentControlsFeedbackModal;

const ExperimentOptOutReasons = [
  'unsureWhatExperimentsAre',
  'unknownWorkAndImpact',
  'doesNotSeeValue',
  'needMoreInfo',
  'needMoreControl',
  'regulatedIndustry',
  'other',
  'tellUsWhy',
];

type ExperimentOptOutReason = (typeof ExperimentOptOutReasons)[number];

export const experimentControlEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CHG/pages/3801318668/Experimentation+Controls+Analytics+Spec',

  // org id attribute
  screen: {
    experimentControlsHomeScreen: (): ScreenEvent => ({
      data: {
        name: ExperimentControlScreens.experimentControlsHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69377',
    }),

    experimentControlsConfirmModal: (): ScreenEvent => ({
      data: {
        name: ExperimentControlScreens.experimentControlsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69388',
    }),
    experimentControlsFeedbackModal: (): ScreenEvent => ({
      data: {
        name: ExperimentControlScreens.experimentControlsFeedbackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69389',
    }),
  },

  ui: {
    experimentControlsOptOutToggle: ({
      currentlyOptedOut,
      requestingOptOut,
    }: {
      currentlyOptedOut: boolean;
      requestingOptOut: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'experimentControlsOptOutToggle',
        source: ExperimentControlScreens.experimentControlsHomeScreen,
        attributes: {
          currentlyOptedOut,
          requestingOptOut,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70160',
    }),
    experimentControlsOptOutModalConfirmButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'experimentControlsOptOutModalConfirmButton',
        source: ExperimentControlScreens.experimentControlsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70192',
    }),
    experimentControlsOptOutModalCancelButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'experimentControlsOptOutModalCancelButton',
        source: ExperimentControlScreens.experimentControlsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70195',
    }),
    experimentControlsOptOutModalExit: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dialog,
        actionSubjectId: 'experimentControlsOptOutModalExit',
        source: ExperimentControlScreens.experimentControlsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70197',
    }),
    // / look into tracking the reasons for feedback
    experimentControlsFeedbackSubmitButton: (optOutReasons: { [REASON in ExperimentOptOutReason]: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'experimentControlsFeedbackSubmitButton',
        source: ExperimentControlScreens.experimentControlsFeedbackModal,
        attributes: optOutReasons,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70166',
    }),
    experimentControlsFeedbackSkipButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'experimentControlsFeedbackSkipButton',
        source: ExperimentControlScreens.experimentControlsFeedbackModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70198',
    }),
  },

  track: {
    experimentControlsOptedOut: ({ orgId }: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.OptedOutOfExperiments,
        actionSubject: TrackActionSubject.OrganizationSetting,
        actionSubjectId: orgId,
        source: ExperimentControlScreens.experimentControlsHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69386',
    }),
    experimentControlsOptedIn: ({ orgId }: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.OptedIntoExperiments,
        actionSubject: TrackActionSubject.OrganizationSetting,
        actionSubjectId: orgId,
        source: ExperimentControlScreens.experimentControlsHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69387',
    }),
  },

  operational: {
    // https://hello.jira.atlassian.cloud/browse/CC-1688
  },
};

export const getValidOptOutReasons = (feedback: Record<string, any>): { [REASON in ExperimentOptOutReason]: boolean } => {
  const optOutReasonValidKeys = Object.keys(feedback).filter((key) => feedback[key] === true && ExperimentOptOutReasons.includes(key));
  const optOutReasons: { [REASON in ExperimentOptOutReason]: boolean } = optOutReasonValidKeys.reduce(
    (acc, key) => ({ ...acc, [key]: true }),
    {},
  );
  if (feedback.description) {
    optOutReasons.tellUsWhy = true;
  }

  return optOutReasons;
};
