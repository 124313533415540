import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

const guardLogoImage: string = require('./svgs/guard-logo.svg');

const LogoWrapper = styled.img<{ width: number }>`
  width: ${({ width }) => `${width}`}px;
`;

export const AccessGuardLogo = ({ width }: { width: number }) => {
  const logo = guardLogoImage;
  const altText = 'Atlassian Guard';

  return <LogoWrapper alt={altText} src={logo} width={width} />;
};
