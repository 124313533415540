import * as React from 'react';

import { AnalyticsContext as AkAnalyticsContext } from '@atlaskit/analytics-next';

import { Analytics, withReactiveVars } from '@adminhub/reactive-vars';

import type { ScreenEvent } from './new-analytics-types';
import type { AnalyticsClientProps } from './with-analytics-client';
import { withAnalyticsClient } from './with-analytics-client';

export interface ScreenEventSenderProps {
  event: ScreenEvent;
  // If the page data hasn't loaded yet, the user hasn't really seen the screen.
  // Use this prop for more accurate reporting.
  isDataLoading?: boolean;
}

interface ReactiveProps {
  referrer: Analytics.Referrer;
}

type Props = ReactiveProps & ScreenEventSenderProps & AnalyticsClientProps;

export class ScreenEventSenderImpl extends React.Component<Props> {
  private eventSent = false;

  public componentDidMount() {
    this.sendAnalyticsIfLoaded();
  }

  public componentDidUpdate() {
    this.sendAnalyticsIfLoaded();
  }

  public render() {
    return (
      <AkAnalyticsContext data={{ screen: this.props.event.data.name }}>
        <React.Fragment>{this.props.children}</React.Fragment>
      </AkAnalyticsContext>
    );
  }

  private sendAnalyticsIfLoaded() {
    if (this.eventSent || this.props.isDataLoading) {
      return;
    }

    const event = {
      ...this.props.event,
      data: {
        ...this.props.event.data,
        attributes: {
          ...(this.props.event.data.attributes || {}),
          referrer: this.props.referrer().toString(),
        },
      },
    };

    this.props.analyticsClient.sendScreenEvent(event);

    this.eventSent = true;

    this.props.referrer(Analytics.ReferrerType.DIRECT);
  }
}

const mapVarToProps = {
  referrer: Analytics.reactiveVariables.referrer,
};

export const ScreenEventSender = withAnalyticsClient(
  withReactiveVars<Props, ScreenEventSenderProps & AnalyticsClientProps>(mapVarToProps, ScreenEventSenderImpl),
);
