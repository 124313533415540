import type { ConsolidatedDatesValues } from '@adminhub/datetime-filter';
import type { UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'auditLogScreenEvent'
  | 'auditLogActivitySettingScreenEvent'
  | 'auditLogWebhookScreenEvent'
  | 'AuditLogWebhookFlagViewedEvent'
  | 'AuditLogsWebhookFlagErrorEvent';

type UIEventKeys =
  | 'learnMoreLinkClickEvent'
  | 'activitySettingsAuditLogClickEvent'
  | 'auditLogDataResidencyNotificationShownEvent'
  | 'activitySettingsFeedbackButton'
  | 'auditLogsWebhookSaveButton'
  | 'auditLogsWebhookRemoveButton'
  | 'auditLogsWebhookTestRegistrationButton'
  | 'activitySettingsFeedbackModalLink'
  | 'filterFeedbackModalLink'
  | 'filterFeedbackButton'
  | 'auditLogsResetFiltersEvent'
  | 'auditLogsApplyButtonEvent'
  | 'auditLogsSearchFeedbackSubmitEvent'
  | 'auditLogsRefreshEventsEvent'
  | 'auditLogsSelectPageSizeEvent'
  | 'auditLogsDateFilterEvent'
  | 'auditLogsDateFilterUpdateEvent'
  | 'auditLogsActivityFilterEvent'
  | 'auditLogsActorFilterClickEvent'
  | 'auditLogsIpFilterClickEvent'
  | 'auditLogsLocationFilterClickEvent'
  | 'auditLogsProductFilterClickEvent'
  | 'auditLogsActivityFilterClearEvent'
  | 'auditLogsSidePanelOpenedClickEvent'
  | 'auditLogsSidePanelClosedClickEvent'
  | 'auditLogsSortTableClick'
  | 'auditLogsEventsQueried'
  | 'auditLogSidePanelFeedbackButtonClicked'
  | 'learnMoreAuditLogsClickEvent'
  | 'auditLogsWebhookFlagClose';

type TrackEventKeys = 'changedTrackEvent' | 'searchedFormActorFilterEvent' | 'searchedFormIpFilterEvent' | 'searchedFormProductFilterEvent';

type OperationalEventKeys = 'invalidPolicyDetectedEvent' | 'invalidPolicyFixedEvent';

export const auditLogActivitySettingScreen = 'auditLogActivitySettingScreen';
export const auditLogWebhookScreen = 'auditLogWebhookScreen';
export const auditLogScreen = 'auditLogScreen';
export const AuditLogsWebhookFlagViewed = 'AuditLogsWebhookFlagViewed';
export const AuditLogWebhookFlagError = 'AuditLogWebhookFlagError';

// <Audit Logs> https://product-fabric.atlassian.net/wiki/spaces/SA/pages/876251626/WIP+Analytic+Events+For+Audit+Logs
interface LocationFilter {
  city: string;
  countryName: string;
  regionName?: string;
}
interface AuditLogProductFilter {
  type: UiProductType;
  eventsParam: string;
  productName: string;
}
interface AuditLogFeaturesAnalytics {
  hasVortexMode: boolean;
}
interface AuditLogFilterAnalyticsInput {
  q: string;
  dateValues: ConsolidatedDatesValues;
  action: string;
  limit?: number;
  actor?: string[];
  ip?: string[];
  location?: LocationFilter[];
  product?: AuditLogProductFilter[];
}
interface AuditLogFeedbackAnalytics {
  feedback: boolean;
}
type AuditLogFilterAndFeaturesAnalytics = AuditLogFilterAnalyticsInput & AuditLogFeaturesAnalytics;
type AuditLogFeedbackAnalyticsInput = AuditLogFilterAndFeaturesAnalytics & AuditLogFeedbackAnalytics;
interface AuditLogFilterAnalytics {
  dateFrom: string;
  dateTo: string;
  activitySelected: string;
  resultSize?: number;
  hasSearchQuery: boolean;
  lengthOfSearchQuery?: number;
  listOfActivities?: string[];
  hasThirdPartyApplicationSelected: boolean;
  numActivitiesSelected: number;
  numActorsSelected: number;
  numIPAddressesSelected: number;
  numLocationsSelected: number;
  numProductSelected: number;
  totalFilterOptionsSelected: number;
}

const convertFilterAnalytics = ({
  dateValues,
  q: searchQuery = '',
  action: activitySelected = '',
  limit: resultSize,
  actor = [],
  ip = [],
  location = [],
  product = [],
}: AuditLogFilterAnalyticsInput): AuditLogFilterAnalytics => {
  const dateFrom = dateValues?.betweenDates?.startDate ?? '';
  const dateTo = dateValues?.betweenDates?.endDate ?? '';
  const hasSearchQuery = Boolean(searchQuery && searchQuery.length > 0);
  const lengthOfSearchQuery = hasSearchQuery ? searchQuery.length : undefined;
  const hasResultSize = !!resultSize;
  const hasThirdPartyApplicationSelected = actor.includes('THIRD_PARTY_APPLICATION');
  const listOfActivities = activitySelected.length === 0 ? undefined : activitySelected.split(',');
  const numActivitiesSelected = !listOfActivities ? 0 : listOfActivities.length;
  const numActorsSelected = actor.length;
  const numIPAddressesSelected = ip.length;
  const numLocationsSelected = location.length;
  const numProductSelected = product.length;
  const hasDateOption = !!dateFrom || !!dateTo;
  const totalFilterOptionsSelected =
    (hasDateOption ? 1 : 0) +
    (hasSearchQuery ? 1 : 0) +
    (hasResultSize ? 1 : 0) +
    (numActivitiesSelected > 0 ? 1 : 0) +
    (numActorsSelected > 0 ? 1 : 0) +
    (numIPAddressesSelected > 0 ? 1 : 0) +
    (numLocationsSelected > 0 ? 1 : 0) +
    (numProductSelected > 0 ? 1 : 0) +
    0;

  return {
    dateFrom,
    dateTo,
    activitySelected,
    resultSize,
    hasSearchQuery,
    lengthOfSearchQuery,
    listOfActivities,
    hasThirdPartyApplicationSelected,
    numActivitiesSelected,
    numActorsSelected,
    numIPAddressesSelected,
    numLocationsSelected,
    numProductSelected,
    totalFilterOptionsSelected,
  };
};

const auditLogSidePanelEvents = {
  ui: {
    auditLogSidePanelFeedbackButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSidePanelFeedbackButton',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79538',
    }),
  },
};

// Confluence page for all audit log frontend analytics
// https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/5054880911
export const auditLogEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalEventKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1362707950/UAL+Frontend+analytics+technical+spec',
  screen: {
    auditLogScreenEvent: (attributes: {
      hasIdentityManager: boolean;
      hasAuditLogAccess: boolean;
      hasActivitySettings: boolean;
      hasVortexMode: boolean;
    }): ScreenEvent => ({
      data: {
        name: auditLogScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16323',
    }),
    auditLogActivitySettingScreenEvent: (attributes: {
      numberOfWorkspaces: number;
      numberOfUGCEnabledWorkspaces: number;
      numberOfUGCDisabledWorkspaces: number;
    }): ScreenEvent => ({
      data: {
        name: auditLogActivitySettingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39857',
    }),
    auditLogWebhookScreenEvent: (attributes: { hasWebhookRegistration: boolean }): ScreenEvent => ({
      data: {
        name: auditLogWebhookScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55001',
    }),
    AuditLogWebhookFlagViewedEvent: (): ScreenEvent => ({
      data: {
        name: AuditLogsWebhookFlagViewed,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79942',
    }),
    AuditLogsWebhookFlagErrorEvent: (): ScreenEvent => ({
      data: {
        name: AuditLogWebhookFlagError,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79957',
    }),
  },
  ui: {
    auditLogsWebhookFlagClose: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'auditLogsWebhookFlagClose',
        source: auditLogScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79490',
    }),
    learnMoreAuditLogsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAuditLogs',
        source: auditLogScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58356',
    }),
    learnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreLink',
        source: auditLogActivitySettingScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    activitySettingsAuditLogClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'activitySettingsAuditLog',
        source: auditLogScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39850',
    }),
    auditLogDataResidencyNotificationShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'auditLogDataResidencyNotification',
        source: auditLogScreen,
        actionSubject: ActionSubject.Notification,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40043',
    }),
    activitySettingsFeedbackButton: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedback',
        source: auditLogScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40231',
    }),
    filterFeedbackButton: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedback',
        source: auditLogScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    auditLogsWebhookSaveButton: (attributes: { isCreating: boolean; isUpdating: boolean; hasHeaders: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsWebhookSave',
        source: auditLogWebhookScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55002',
    }),
    auditLogsWebhookRemoveButton: (attributes: { hasHeaders: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsWebhookRemove',
        source: auditLogWebhookScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55003',
    }),
    auditLogsWebhookTestRegistrationButton: (attributes: { hasHeaders: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsWebhookTestRegistration',
        source: auditLogWebhookScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56904',
    }),
    activitySettingsFeedbackModalLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'auditLogDataResidencyNotification',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40232',
    }),
    filterFeedbackModalLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'leaveFeedbackDialog',
        actionSubject: ActionSubject.Link,
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    auditLogsResetFiltersEvent: ({
      q,
      dateValues,
      action,
      actor,
      ip,
      location,
      product,
      limit,
      hasVortexMode,
    }: AuditLogFilterAndFeaturesAnalytics): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsResetFilters',
        source: auditLogScreen,
        attributes: { ...convertFilterAnalytics({ q, dateValues, action, actor, ip, location, product, limit }), hasVortexMode },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/eventcatalog/view/50391',
    }),
    auditLogsRefreshEventsEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsRefreshEvents',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50393',
    }),
    auditLogsSelectPageSizeEvent: ({ limit: resultSize }: { limit?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSelectPageSize',
        source: auditLogScreen,
        attributes: {
          resultSize,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50394',
    }),
    auditLogsDateFilterEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsDateFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50395',
    }),
    auditLogsDateFilterUpdateEvent: ({ dateFilterType }: { dateFilterType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsDateFilterUpdate',
        source: auditLogScreen,
        attributes: {
          dateFilterType,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50396',
    }),
    auditLogsActivityFilterEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsActivityFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50397',
    }),
    auditLogsActivityFilterClearEvent: ({ action: activitySelected = '' }: { action: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsActivityFilterClear',
        source: auditLogScreen,
        attributes: {
          activitySelected,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50398',
    }),
    auditLogsApplyButtonEvent: ({
      q,
      dateValues,
      action,
      actor,
      ip,
      location,
      product,
      limit,
      hasVortexMode,
    }: AuditLogFilterAndFeaturesAnalytics): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'applyButton',
        source: auditLogScreen,
        attributes: { ...convertFilterAnalytics({ q, dateValues, action, actor, ip, location, product, limit }), hasVortexMode },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15911',
    }),

    auditLogsSearchFeedbackSubmitEvent: ({
      q,
      dateValues,
      action,
      actor,
      ip,
      location,
      product,
      limit,
      feedback,
      hasVortexMode,
    }: AuditLogFeedbackAnalyticsInput): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSearchFeedbackSubmitEvent',
        source: auditLogScreen,
        attributes: { ...convertFilterAnalytics({ q, dateValues, action, actor, ip, location, product, limit }), feedback, hasVortexMode },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71146',
    }),
    auditLogsActorFilterClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsActorFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55196',
    }),
    auditLogsIpFilterClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsIpFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55197',
    }),
    auditLogsLocationFilterClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsLocationFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68827',
    }),
    auditLogsProductFilterClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsProductFilter',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67912',
    }),
    auditLogsSidePanelOpenedClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSidePanelOpened',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80090?tabIndex=Destinations',
    }),
    auditLogsSidePanelClosedClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSidePanelClosed',
        source: auditLogScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/80090?tabIndex=Destinations',
    }),
    auditLogsSortTableClick: ({ column, order }: { column: string; order: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'auditLogsSortTable',
        source: auditLogScreen,
        attributes: {
          column,
          order,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55200',
    }),
    auditLogsEventsQueried: ({
      eventsAnalytics,
      input,
    }: {
      eventsAnalytics: Record<string, any>;
      input: AuditLogFilterAndFeaturesAnalytics;
    }): UIEvent => ({
      data: {
        action: Action.Fetch,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'auditLogsEventsTable',
        source: auditLogScreen,
        attributes: { ...eventsAnalytics, ...convertFilterAnalytics(input) },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76593',
    }),
    ...auditLogSidePanelEvents.ui,
  },
  track: {
    changedTrackEvent: (attributes: { enabled: boolean; numberOfWorkspaces: number; selectedProducts: string[] }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.AuditLogActivitySettings,
        source: auditLogActivitySettingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40044',
    }),
    searchedFormActorFilterEvent: ({ searchQuery, resultsCount }: { searchQuery: string; resultsCount: number }) => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.Form,
        actionSubjectId: 'auditLogsActorFilter',
        source: auditLogScreen,
        attributes: {
          hasSearchQuery: Boolean(searchQuery && searchQuery.length),
          resultsCount,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55198',
    }),
    searchedFormIpFilterEvent: ({ searchQuery, resultsCount }: { searchQuery: string; resultsCount: number }) => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.Form,
        actionSubjectId: 'auditLogsIpFilter',
        source: auditLogScreen,
        attributes: {
          hasSearchQuery: Boolean(searchQuery && searchQuery.length),
          resultsCount,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55199',
    }),
    searchedFormProductFilterEvent: ({ searchQuery, resultsCount }: { searchQuery: string; resultsCount: number }) => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.Form,
        actionSubjectId: 'auditLogsProductFilter',
        source: auditLogScreen,
        attributes: {
          hasSearchQuery: Boolean(searchQuery && searchQuery.length),
          resultsCount,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67996',
    }),
  },
  operational: {
    invalidPolicyDetectedEvent: ({ policyId }: { policyId: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Viewed,
        actionSubject: OperationalSubject.InvalidAuditLogsUGCPolicy,
        actionSubjectId: policyId,
        source: auditLogActivitySettingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56442',
    }),
    invalidPolicyFixedEvent: ({ policyId }: { policyId: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Changed,
        actionSubject: OperationalSubject.InvalidAuditLogsUGCPolicy,
        actionSubjectId: policyId,
        source: auditLogActivitySettingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56443',
    }),
  },
};
