import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIEventKeys = 'learnMoreLinkClickEvent';

export const productsScreen = 'productsScreen';

// TODO: Will keep this file as it is required for other analytics event. We can remove leaarnmore click event after rollout.
export const trelloAdminhubIntegrationEvents: FeatureEvents<string, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1926236735/Trello+-+AdminHub+Integration+Analytics+Spec',
  screen: {},
  ui: {
    learnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreLink',
        source: productsScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
  },
  track: {},
  operational: {},
};
