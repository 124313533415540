import type { Domain } from '@adminhub/domain-claim';
import { getDomainStatusAttributes } from '@adminhub/domain-claim';
import type { VerificationType } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'claimAccountsDomainClaimSuccessModalClickEvent'
  | 'claimAccountsSelectiveDomainsLinkClickEvent'
  | 'claimAccountsSelectiveDomainLinkClickEvent'
  | 'verifyDomainSelectiveDomainsClickEvent'
  | 'reviewMissingTokenDomainsClickEvent'
  | 'reviewMissingTokenDomainClickEvent'
  | 'managedAccountsCountSelectiveDomainsClickEvent'
  | 'claimAccountsSelectiveDomainsDropdownClickEvent'
  | 'changeClaimSettingSelectiveDomainsClickEvent'
  | 'unclaimAccountsSelectiveDomainsClickEvent'
  | 'removeDomainSelectiveDomainsClickEvent'
  | 'removeDomainSelectiveDomainClickEvent'
  | 'removeVerificationMethodDomainClickEvent'
  | 'removeVerificationMethodClickEvent'
  | 'manageDomainSelectiveDomainsClickEvent'
  | 'domainNameClickEvent'
  | 'exportAccountsClickEvent'
  | 'nextSelectiveAddDomainClickEvent'
  | 'nextSelectiveUnderstandDomainVerificationClickEvent'
  | 'doThisLaterSelectiveUnderstandDomainVerificationClickEvent'
  | 'doThisLaterSelectiveAddDomainClickEvent'
  | 'verifyDomainSelectiveVerifyDomainClickEvent'
  | 'backSelectiveAddDomainClickEvent'
  | 'backSelectiveVerifyDomainClickEvent'
  | 'copyNameHostOrAliasSelectiveVerifyDomainClickEvent'
  | 'copyTimeToLiveSelectiveVerifyDomainClickEvent'
  | 'copyRecordTypeSelectiveVerifyDomainClickEvent'
  | 'copyRecordOrDataSelectiveVerifyDomainClickEvent'
  | 'downloadHtmlFileSelectiveVerifyDomainClickEvent'
  | 'goToGoogleWorkspaceSelectiveVerifyDomainClickEvent'
  | 'goToAzureAdSyncSelectiveVerifyDomainClickEvent'
  | 'goToIdentityProvidersDomainClickEvent'
  | 'doThisLaterClaimAccountsPreInfoClickEvent'
  | 'nextClaimAccountsPreInfoClickEvent'
  | 'doThisLaterClaimAccountsChooseAccountsClickEvent'
  | 'backClaimAccountsChooseAccountsClickEvent'
  | 'nextClaimAccountsChooseAccountsClickEvent'
  | 'nextSelectiveReviewAccountsClickEvent'
  | 'doThisLaterSelectiveReviewAccountsClickEvent'
  | 'exportCsvFileSelectiveReviewAccountsClickEvent'
  | 'exportCsvFileClaimAccountsReviewAccountsClickEvent'
  | 'backClaimAccountsReviewAccountsClickEvent'
  | 'nextClaimAccountsReviewAccountsClickEvent'
  | 'claimAccountsSelectiveClaimAccountsClickEvent'
  | 'backSelectiveClaimAccountsClickEvent'
  | 'backClaimAccountsClaimSettingsClickEvent'
  | 'nextClaimAccountsClaimSettingsClickEvent'
  | 'selectCsvFileSelectiveClaimAccountsClickEvent'
  | 'chooseCsvFileClaimAccountsChooseAccountsClickEvent'
  | 'backClaimAccountsChooseAccountsCsvFileClickEvent'
  | 'nextClaimAccountsChooseAccountsCsvFileClickEvent'
  | 'backClaimAccountsReviewClickEvent'
  | 'nextClaimAccountsReviewClickEvent'
  | 'closeSelectiveClaimAccountsSuccessClickEvent'
  | 'unclaimAccountsSelectiveUnclaimAccountsClickEvent'
  | 'doThisLaterSelectiveUnclaimAccountsClickEvent'
  | 'selectCsvFileSelectiveUnclaimAccountsClickEvent'
  | 'changeClaimSettingSelectiveClaimSettingClickEvent'
  | 'doThisLaterSelectiveClaimSettingClickEvent'
  | 'removeSelectiveRemoveDomainClickEvent'
  | 'exportDomainAccountsClickEvent'
  | 'cancelSelectiveRemoveDomainClickEvent'
  | 'cancelExportDomainAccountsClickEvent'
  | 'viewGoogleWorkspaceSelectiveRemoveGoogleDomainClickEvent'
  | 'cancelSelectiveRemoveGoogleDomainClickEvent'
  | 'viewAzureAdSyncSelectiveRemoveAzureDomainClickEvent'
  | 'cancelSelectiveRemoveAzureDomainClickEvent'
  | 'selectiveAddDomainClickEvent'
  | 'domainTablePaginationNextClickEvent'
  | 'domainTablePaginationPreviousClickEvent'
  | 'domainTablePageSizeSelectedEvent'
  | 'domainTableFilterRefreshClickEvent'
  | 'domainTableFilterResetClickEvent'
  | 'domainTableSearchQueryEvent'
  | 'addVerificationMethodClickEvent'
  | 'updateVerificationMethodClickEvent';

type ScreenEventKeys =
  | 'selectiveDomainsScreenEvent'
  | 'selectiveUnderstandDomainVerificationScreenEvent'
  | 'selectiveAddDomainScreenEvent'
  | 'selectiveVerifyDomainScreenEvent'
  | 'selectiveClaimSettingScreenEvent'
  | 'selectiveReviewAccountsScreenEvent'
  | 'selectiveClaimAccountsScreenEvent'
  | 'selectiveClaimAccountsSuccessScreenEvent'
  | 'selectiveUnclaimAccountsScreenEvent'
  | 'selectiveRemoveDomainModalScreenEvent'
  | 'exportDomainAccountsModal'
  | 'selectiveRemoveGoogleDomainModalScreenEvent'
  | 'selectiveRemoveAzureDomainModalScreenEvent'
  | 'claimAccountsPreInfoScreenEvent'
  | 'claimAccountsChooseAccountsScreenEvent'
  | 'claimAccountsReviewAccountsScreenEvent'
  | 'claimAccountsClaimSettingsScreenEvent'
  | 'claimAccountsChooseAccountsCsvFileScreenEvent'
  | 'claimAccountsReviewScreenEvent'
  | 'manageDomainScreenEvent'
  | 'updateIdpDomainModalScreenEvent'
  | 'removeVerificationMethodModalScreenEvent'
  | 'removeErrorManageDomainFlagScreenEvent';

type TrackEventKeys =
  | 'domainVerified'
  | 'domainRemoved'
  | 'domainRemovedManageDomain'
  | 'claimSettingChanged'
  | 'accountsClaimed'
  | 'accountsUnclaimed';

interface SelectiveUserClaimAttributes {
  domainName: string;
  verificationType: VerificationType;
  verificationStatus: 'VERIFIED_UNCLAIMED' | 'VERIFIED' | 'MISSING_TOKEN';
  verificationCount: number;
  claimType: 'all' | 'custom';
  claimSetting: 'automatically' | 'manually';
  totalAccountsCount: number;
  availableAccountsCount: number;
  managedAccountsCount: number;
  multipleOwners: boolean;
  selfAutoClaim: boolean;
  autoClaim: boolean;
  shouldClaimAccountsByDefault: boolean;
  verificationFlow: 'addDomain' | 'addVerificationMethod' | 'reviewVerificationMethod' | 'updateVerificationMethod';
}

interface ClaimAccountsFlowAttributes {
  hasMultipleOwnersBanner: boolean;
}

interface DomainPaginatedTableAttributes {
  pageSize?: number;
  totalRecords?: number;
  hasQuery?: boolean;
  currentPage?: number;
  totalPages?: number;
}

export const selectiveDomainsScreen = 'selectiveDomainsScreen';
export const manageDomainScreen = 'manageDomainScreen';
export const selectiveUnderstandDomainVerificationScreen = 'selectiveUnderstandDomainVerificationScreen';
export const selectiveAddDomainScreen = 'selectiveAddDomainScreen';
export const selectiveVerifyDomainScreen = 'selectiveVerifyDomainScreen';
export const selectiveReviewAccountsScreen = 'selectiveReviewAccountsScreen';
export const claimAccountsPreInfoScreen = 'claimAccountsPreInfoScreen';
export const claimAccountsChooseAccountsScreen = 'claimAccountsChooseAccountsScreen';
export const claimAccountsReviewAccountsScreen = 'claimAccountsReviewAccountsScreen';
export const claimAccountsClaimSettingsScreen = 'claimAccountsClaimSettingsScreen';
export const claimAccountsChooseAccountsCsvFileScreen = 'claimAccountsChooseAccountsCsvFileScreen';
export const claimAccountsReviewScreen = 'claimAccountsReviewScreen';
export const selectiveClaimAccountsScreen = 'selectiveClaimAccountsScreen';
export const selectiveClaimAccountsSuccessScreen = 'selectiveClaimAccountsSuccessScreen';
export const selectiveUnclaimAccountsScreen = 'selectiveUnclaimAccountsScreen';
export const selectiveClaimSettingScreen = 'selectiveClaimSettingScreen';
export const selectiveRemoveDomainModal = 'selectiveRemoveDomainModal';
export const exportDomainAccountsModal = 'exportDomainAccountsModal';
export const selectiveRemoveGoogleDomainModal = 'selectiveRemoveGoogleDomainModal';
export const selectiveRemoveAzureDomainModal = 'selectiveRemoveAzureDomainModal';
export const updateIdpDomainModalScreen = 'updateIdpDomainModalScreen';
export const removeVerificationMethodModal = 'removeVerificationMethodModal';
export const removeErrorManageDomainFlag = 'removeErrorManageDomainFlag';

export const selectiveUserClaimEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/2295375451/SUC+Frontend+analytics+technical+spec',
  screen: {
    selectiveDomainsScreenEvent: (attributes: { domains: Domain[] }): ScreenEvent => {
      const { domains } = attributes;
      const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/48965';

      return {
        data: {
          name: selectiveDomainsScreen,
          attributes: {
            ...getDomainStatusAttributes(domains),
          },
        },
        dataPortalLink,
      };
    },
    selectiveUnderstandDomainVerificationScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveUnderstandDomainVerificationScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57563',
    }),
    selectiveAddDomainScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveAddDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48966',
    }),
    selectiveVerifyDomainScreenEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationFlow'>): ScreenEvent => ({
      data: {
        name: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48967',
    }),
    selectiveClaimSettingScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveClaimSettingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48968',
    }),
    claimAccountsPreInfoScreenEvent: (): ScreenEvent => ({
      data: {
        name: claimAccountsPreInfoScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66582',
    }),
    claimAccountsChooseAccountsScreenEvent: (): ScreenEvent => ({
      data: {
        name: claimAccountsChooseAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66588',
    }),
    claimAccountsReviewAccountsScreenEvent: (): ScreenEvent => ({
      data: {
        name: claimAccountsReviewAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66592',
    }),
    claimAccountsClaimSettingsScreenEvent: (attributes: Pick<ClaimAccountsFlowAttributes, 'hasMultipleOwnersBanner'>): ScreenEvent => ({
      data: {
        name: claimAccountsClaimSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66618',
    }),
    claimAccountsChooseAccountsCsvFileScreenEvent: (): ScreenEvent => ({
      data: {
        name: claimAccountsChooseAccountsCsvFileScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66623',
    }),
    claimAccountsReviewScreenEvent: (): ScreenEvent => ({
      data: {
        name: claimAccountsReviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66627',
    }),
    selectiveReviewAccountsScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveReviewAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48969',
    }),
    selectiveClaimAccountsScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveClaimAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48970',
    }),
    selectiveClaimAccountsSuccessScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveClaimAccountsSuccessScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48971',
    }),
    selectiveUnclaimAccountsScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveUnclaimAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48972',
    }),
    selectiveRemoveDomainModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveRemoveDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48974',
    }),
    exportDomainAccountsModal: (): ScreenEvent => ({
      data: {
        name: exportDomainAccountsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60890',
    }),
    selectiveRemoveGoogleDomainModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveRemoveGoogleDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48975',
    }),
    selectiveRemoveAzureDomainModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: selectiveRemoveAzureDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50144',
    }),
    manageDomainScreenEvent: (): ScreenEvent => ({
      data: {
        name: manageDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70324',
    }),
    updateIdpDomainModalScreenEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType'>): ScreenEvent => ({
      data: {
        name: updateIdpDomainModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70354',
    }),
    removeVerificationMethodModalScreenEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType'>): ScreenEvent => ({
      data: {
        name: removeVerificationMethodModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70359',
    }),
    removeErrorManageDomainFlagScreenEvent: (): ScreenEvent => ({
      data: {
        name: removeErrorManageDomainFlag,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70360',
    }),
  },
  ui: {
    claimAccountsDomainClaimSuccessModalClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'claimAccountsDomainClaimSuccessModal',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55817',
    }),
    claimAccountsSelectiveDomainsLinkClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'claimAccountsSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48976',
    }),
    claimAccountsSelectiveDomainLinkClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'claimAccountsSelectiveDomains',
        source: manageDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48976',
    }),
    verifyDomainSelectiveDomainsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'verifyDomainSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48977',
    }),
    reviewMissingTokenDomainsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reviewMissingTokenDomains',
        source: selectiveDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70356',
    }),
    reviewMissingTokenDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reviewMissingTokenDomains',
        source: manageDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70356',
    }),
    managedAccountsCountSelectiveDomainsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'managedAccountsCountSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48978',
    }),
    claimAccountsSelectiveDomainsDropdownClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'claimAccountsSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48979',
    }),
    changeClaimSettingSelectiveDomainsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'changeClaimSettingSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48980',
    }),
    unclaimAccountsSelectiveDomainsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'unclaimAccountsSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48982',
    }),
    removeDomainSelectiveDomainsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'removeDomainSelectiveDomains',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48983',
    }),
    removeDomainSelectiveDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'removeDomainSelectiveDomains',
        source: manageDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48983',
    }),
    removeVerificationMethodDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'removeVerificationMethodDomain',
        source: manageDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70357',
    }),
    removeVerificationMethodClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeVerificationMethod',
        source: removeVerificationMethodModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70358',
    }),
    manageDomainSelectiveDomainsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'manageDomainSelectiveDomains',
        source: selectiveDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70322',
    }),
    domainNameClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'domainName',
        source: selectiveDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70323',
    }),
    exportAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'exportAccounts',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60815',
    }),
    nextSelectiveUnderstandDomainVerificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextSelectiveUnderstandDomainVerification',
        source: selectiveAddDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57567',
    }),
    nextSelectiveAddDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextSelectiveAddDomain',
        source: selectiveAddDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48984',
    }),
    doThisLaterSelectiveUnderstandDomainVerificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterSelectiveUnderstandDomainVerification',
        source: selectiveUnderstandDomainVerificationScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57568',
    }),
    doThisLaterSelectiveAddDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterSelectiveAddDomain',
        source: selectiveAddDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48985',
    }),
    verifyDomainSelectiveVerifyDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'verificationType' | 'verificationFlow' | 'verificationCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'verifyDomainSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48986',
    }),
    backSelectiveAddDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backSelectiveAddDomain',
        source: selectiveAddDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57569',
    }),
    backSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48987',
    }),
    copyNameHostOrAliasSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyNameHostOrAliasSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48988',
    }),
    copyTimeToLiveSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyTimeToLiveSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48989',
    }),
    copyRecordTypeSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyRecordTypeSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48990',
    }),
    copyRecordOrDataSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyRecordOrDataSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48991',
    }),
    downloadHtmlFileSelectiveVerifyDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadHtmlFileSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48992',
    }),
    goToGoogleWorkspaceSelectiveVerifyDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'verificationCount' | 'verificationFlow'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goToGoogleWorkspaceSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48993',
    }),
    goToAzureAdSyncSelectiveVerifyDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'verificationCount' | 'verificationFlow'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goToAzureAdSyncSelectiveVerifyDomain',
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49575',
    }),
    goToIdentityProvidersDomainClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goToIdentityProvidersDomain',
        source: updateIdpDomainModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70355',
    }),
    doThisLaterClaimAccountsPreInfoClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterClaimAccountsPreInfo',
        source: claimAccountsPreInfoScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66586',
    }),
    nextClaimAccountsPreInfoClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsPreInfo',
        source: claimAccountsPreInfoScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66587',
    }),
    doThisLaterClaimAccountsChooseAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterClaimAccountsChooseAccounts',
        source: claimAccountsChooseAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66589',
    }),
    backClaimAccountsChooseAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backClaimAccountsChooseAccounts',
        source: claimAccountsChooseAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66590',
    }),
    nextClaimAccountsChooseAccountsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsChooseAccounts',
        source: claimAccountsChooseAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66591',
    }),
    nextSelectiveReviewAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextSelectiveReviewAccounts',
        source: selectiveReviewAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/489924',
    }),
    doThisLaterSelectiveReviewAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterSelectiveReviewAccounts',
        source: selectiveReviewAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48995',
    }),
    exportCsvFileSelectiveReviewAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportCsvFileSelectiveReviewAccounts',
        source: selectiveReviewAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48996',
    }),
    exportCsvFileClaimAccountsReviewAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportCsvFileClaimAccountsReviewAccounts',
        source: claimAccountsReviewAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66593',
    }),
    backClaimAccountsReviewAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backClaimAccountsReviewAccounts',
        source: claimAccountsReviewAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66595',
    }),
    nextClaimAccountsReviewAccountsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsReviewAccounts',
        source: claimAccountsReviewAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66596',
    }),
    claimAccountsSelectiveClaimAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'claimType'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'claimAccountsSelectiveClaimAccounts',
        source: selectiveClaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48997',
    }),
    backSelectiveClaimAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backSelectiveClaimAccounts',
        source: selectiveClaimAccountsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48998',
    }),
    backClaimAccountsClaimSettingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backClaimAccountsClaimSettings',
        source: claimAccountsClaimSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66621',
    }),
    nextClaimAccountsClaimSettingsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsClaimSettings',
        source: claimAccountsClaimSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66622',
    }),
    selectCsvFileSelectiveClaimAccountsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectCsvFileSelectiveClaimAccounts',
        source: selectiveClaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48999',
    }),
    chooseCsvFileClaimAccountsChooseAccountsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'claimAccountsChooseAccountsCsvFile',
        source: claimAccountsChooseAccountsCsvFileScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66624',
    }),
    backClaimAccountsChooseAccountsCsvFileClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backClaimAccountsChooseAccountsCsvFile',
        source: claimAccountsChooseAccountsCsvFileScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66625',
    }),
    nextClaimAccountsChooseAccountsCsvFileClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsChooseAccountsCsvFile',
        source: claimAccountsChooseAccountsCsvFileScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66626',
    }),
    backClaimAccountsReviewClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backClaimAccountsReview',
        source: claimAccountsReviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66628',
    }),
    nextClaimAccountsReviewClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextClaimAccountsReview',
        source: claimAccountsReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66629',
    }),
    closeSelectiveClaimAccountsSuccessClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeSelectiveClaimAccountsSuccess',
        source: selectiveClaimAccountsSuccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49000',
    }),
    unclaimAccountsSelectiveUnclaimAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'unclaimAccountsSelectiveUnclaimAccounts',
        source: selectiveUnclaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49001',
    }),
    doThisLaterSelectiveUnclaimAccountsClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterSelectiveUnclaimAccounts',
        source: selectiveUnclaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49002',
    }),
    selectCsvFileSelectiveUnclaimAccountsClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectCsvFileSelectiveUnclaimAccounts',
        source: selectiveUnclaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49003',
    }),
    changeClaimSettingSelectiveClaimSettingClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'claimSetting'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'changeClaimSettingSelectiveClaimSetting',
        source: selectiveClaimSettingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49005',
    }),
    doThisLaterSelectiveClaimSettingClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'doThisLaterSelectiveClaimSetting',
        source: selectiveClaimSettingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49007',
    }),
    removeSelectiveRemoveDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeSelectiveRemoveDomain',
        source: selectiveRemoveDomainModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49008',
    }),
    exportDomainAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'export',
        source: exportDomainAccountsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60816',
    }),
    cancelSelectiveRemoveDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSelectiveRemoveDomain',
        source: selectiveRemoveDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49009',
    }),
    cancelExportDomainAccountsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: exportDomainAccountsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    viewGoogleWorkspaceSelectiveRemoveGoogleDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewGoogleWorkspaceSelectiveRemoveGoogleDomain',
        source: selectiveRemoveGoogleDomainModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49010',
    }),
    cancelSelectiveRemoveGoogleDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSelectiveRemoveGoogleDomain',
        source: selectiveRemoveGoogleDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49011',
    }),
    viewAzureAdSyncSelectiveRemoveAzureDomainClickEvent: (
      attributes: Pick<SelectiveUserClaimAttributes, 'domainName' | 'managedAccountsCount' | 'totalAccountsCount'>,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAzureAdSyncSelectiveRemoveAzureDomain',
        source: selectiveRemoveAzureDomainModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50145',
    }),
    cancelSelectiveRemoveAzureDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSelectiveRemoveAzureDomain',
        source: selectiveRemoveAzureDomainModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50146',
    }),
    selectiveAddDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addDomain',
        source: selectiveDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38912',
    }),
    domainTablePaginationNextClickEvent: (attributes: DomainPaginatedTableAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'paginationNext',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60819',
    }),
    domainTablePaginationPreviousClickEvent: (attributes: DomainPaginatedTableAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'paginationPrevious',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60820',
    }),
    domainTablePageSizeSelectedEvent: (attributes: DomainPaginatedTableAttributes): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'paginationSize',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60826',
    }),
    domainTableFilterRefreshClickEvent: (attributes: DomainPaginatedTableAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainsFilterRefresh',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60822',
    }),
    domainTableFilterResetClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainsFilterReset',
        source: selectiveDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60824',
    }),
    domainTableSearchQueryEvent: (attributes: DomainPaginatedTableAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'domainsSearchQuery',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60823',
    }),
    addVerificationMethodClickEvent: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationCount'>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addVerificationMethod',
        source: selectiveDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70330',
    }),
    updateVerificationMethodClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'updateVerificationMethod',
        source: manageDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70353',
    }),
  },
  track: {
    domainVerified: (
      attributes: Pick<SelectiveUserClaimAttributes, 'verificationType' | 'domainName' | 'verificationCount' | 'verificationFlow'>,
    ): TrackEvent => ({
      data: {
        action: TrackAction.Verified,
        actionSubject: TrackActionSubject.SelectiveDomain,
        source: selectiveVerifyDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48905',
    }),
    domainRemoved: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType' | 'domainName'>): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.SelectiveDomain,
        source: selectiveRemoveDomainModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48906',
    }),
    domainRemovedManageDomain: (attributes: Pick<SelectiveUserClaimAttributes, 'verificationType' | 'domainName'>): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.SelectiveDomain,
        source: manageDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48906',
    }),
    claimSettingChanged: (
      attributes: Pick<
        SelectiveUserClaimAttributes,
        'managedAccountsCount' | 'totalAccountsCount' | 'domainName' | 'shouldClaimAccountsByDefault'
      >,
    ): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.SelectiveClaimSetting,
        source: selectiveClaimSettingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48907',
    }),
    accountsClaimed: (
      attributes: Pick<
        SelectiveUserClaimAttributes,
        | 'domainName'
        | 'verificationStatus'
        | 'verificationType'
        | 'claimType'
        | 'claimSetting'
        | 'totalAccountsCount'
        | 'availableAccountsCount'
        | 'multipleOwners'
        | 'selfAutoClaim'
        | 'autoClaim'
      >,
    ): TrackEvent => ({
      data: {
        action: TrackAction.Claimed,
        actionSubject: TrackActionSubject.SelectiveAccounts,
        source: selectiveClaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48908',
    }),
    accountsUnclaimed: (attributes: Pick<SelectiveUserClaimAttributes, 'domainName'>): TrackEvent => ({
      data: {
        action: TrackAction.Unclaimed,
        actionSubject: TrackActionSubject.SelectiveAccounts,
        source: selectiveUnclaimAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48909',
    }),
  },
  operational: {},
};
