import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export enum GoalsAndProjectsScreens {
  goalsAndProjectsOptOutScreen = 'goalsAndProjectsOptOutScreen',
}

type ScreenEventKeys = 'goalsAndProjectsOptOutScreenEvent';
type UIEventKeys = 'goalsAndProjectsToggleClickedEvent' | 'goalsAndProjectsModalButtonClickedEvent';
type TrackEventKeys = 'goalsAndProjectsSettingChangedEvent';

export const goalsAndProjectsIntegrationEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, TrackEventKeys, any> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4463017191/G+P+Analytics+Spec',
  screen: {
    goalsAndProjectsOptOutScreenEvent: (): ScreenEvent => ({
      data: {
        name: GoalsAndProjectsScreens.goalsAndProjectsOptOutScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73942',
    }),
  },
  ui: {
    goalsAndProjectsToggleClickedEvent: (attributes: { action: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goalsAndProjectsToggleClicked',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75559',
    }),
    goalsAndProjectsModalButtonClickedEvent: (attributes: { action: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goalsAndProjectsModalButton',
        source: GoalsAndProjectsScreens.goalsAndProjectsOptOutScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75560',
    }),
  },
  track: {
    goalsAndProjectsSettingChangedEvent: (attributes: { action: string }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.GoalsAndProjectsSetting,
        source: GoalsAndProjectsScreens.goalsAndProjectsOptOutScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47934',
    }),
  },
  operational: {},
};
