import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'ipAllowlistScreenEvent'
  | 'ipAllowlistCreateModalScreenEvent'
  | 'ipAllowlistEditModalScreenEvent'
  | 'ipAllowlistDeleteModalScreenEvent';
type UIEventKeys =
  | 'createClickEvent'
  | 'learnMoreClickEvent'
  | 'cancelCreateClickEvent'
  | 'createConfirmClickEvent'
  | 'switchStatusToggleEvent'
  | 'editClickEvent'
  | 'saveClickEvent'
  | 'cancelEditClickEvent'
  | 'deleteClickEvent'
  | 'deleteConfirmClickEvent'
  | 'cancelDeleteClickEvent'
  | 'viewMobileAppPoliciesEvent';
type TrackEventKeys = 'createdIpAllowlist' | 'deletedIpAllowlist' | 'changedIpAllowlist' | 'enabledAllowList' | 'disabledAllowList';

export const ipAllowlistScreen = 'ipAllowlistScreen';
export const ipAllowlistCreateModalScreen = 'ipAllowlistCreateModalScreen';
export const ipAllowlistEditModalScreen = 'ipAllowlistEditModalScreen';
export const ipAllowlistDeleteModalScreen = 'ipAllowlistDeleteModalScreen';

export const ipAllowlistEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/629947870/IP+Allowlisting+Front+end+Analytics+Spec',
  screen: {
    ipAllowlistScreenEvent: (attributes: {
      premiumProductsCount: number;
      allowListCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
    }): ScreenEvent => ({
      data: {
        name: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26474',
    }),
    ipAllowlistCreateModalScreenEvent: (attributes: {
      premiumProductsCount: number;
      allowListCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
    }): ScreenEvent => ({
      data: {
        name: ipAllowlistCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26475',
    }),
    ipAllowlistEditModalScreenEvent: (attributes: {
      premiumProductsCount: number;
      allowListCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      policyId?: string;
      ipAddressesCount: number;
      enabled: string;
    }): ScreenEvent => ({
      data: {
        name: ipAllowlistEditModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26476',
    }),
    ipAllowlistDeleteModalScreenEvent: (attributes: {
      premiumProductsCount: number;
      allowListCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      policyId?: string;
      ipAddressesCount: number;
      enabled: string;
    }): ScreenEvent => ({
      data: {
        name: ipAllowlistDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26477',
    }),
  },
  ui: {
    createClickEvent: (attributes: { allowListCount: number; allowMobileBypass: boolean; mobileAppPolicyCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createAllowList',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26489',
    }),
    learnMoreClickEvent: (attributes: { hasAccessSubscription: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMore',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22168',
    }),
    cancelCreateClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancel',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistCreateModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    createConfirmClickEvent: (attributes: {
      productsAppliedCount: number;
      ipAddressesCount: number;
      enabled: boolean;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createAllowlist',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26488',
    }),
    switchStatusToggleEvent: (attributes: { statusChanged: 'enabled' | 'disabled' }): UIEvent => ({
      data: {
        action: Action.Switched,
        actionSubjectId: 'enabledDisableAllowList',
        actionSubject: ActionSubject.Toggle,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26487',
    }),
    editClickEvent: (attributes: { enabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'editAllowList',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26486',
    }),
    saveClickEvent: (attributes: {
      productsAppliedCount: number;
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      hasNameBeenChanged: boolean;
      policyId: string;
      enabled: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'save',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistEditModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26485',
    }),
    cancelEditClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancel',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistEditModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    deleteClickEvent: (attributes: { enabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'delete',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26484',
    }),
    deleteConfirmClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirm',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistDeleteModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),
    cancelDeleteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancel',
        actionSubject: ActionSubject.Button,
        source: ipAllowlistDeleteModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    viewMobileAppPoliciesEvent: (attributes: { ipAllowlistCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewMobileAppPolicies',
        actionSubject: ActionSubject.Link,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74464',
    }),
  },
  track: {
    createdIpAllowlist: (attributes: {
      enabled: boolean;
      selectedProducts: string[];
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      policyId: string;
      mobileAppPolicyCount: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.IpAllowlist,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26478',
    }),
    deletedIpAllowlist: (attributes: {
      enabled: boolean;
      selectedProducts: string[];
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      policyId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.IpAllowlist,
        source: ipAllowlistDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26479',
    }),
    changedIpAllowlist: (attributes: {
      enabled: boolean;
      selectedProducts: string[];
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      policyId: string;
      mobileAppPolicyCount: number;
      hasNameBeenChanged: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.IpAllowlist,
        source: ipAllowlistEditModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26480',
    }),
    enabledAllowList: (attributes: {
      selectedProducts: string[];
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      policyId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Enabled,
        actionSubject: TrackActionSubject.IpAllowlist,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26481',
    }),
    disabledAllowList: (attributes: {
      selectedProducts: string[];
      ipAddressesCount: number;
      allowMobileBypass: boolean;
      mobileAppPolicyCount: number;
      policyId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Disabled,
        actionSubject: TrackActionSubject.IpAllowlist,
        source: ipAllowlistScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26482',
    }),
  },
  operational: {},
};
