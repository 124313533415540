import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

export enum SecurityChecklistScreen {
  securityChecklistScreen = 'securityChecklistScreen',
}

type ScreenKeys = 'securityChecklistScreenEvent';

type UiKeys =
  | 'hideCompletedTasksButtonClicked'
  | 'showCompletedTasksButtonClicked'
  | 'openManagedInsightMenuButtonClicked'
  | 'viewManagedAuthPolicyButtonClicked'
  | 'viewManagedInsightsButtonClicked'
  | 'viewManagedAccountsButtonClicked'
  | 'openExternalInsightsMenuButtonClicked'
  | 'viewExternalInsightsButtonClicked'
  | 'viewExternalUsersButtonClicked'
  | 'tryAtlassianGuardButtonClicked'
  | 'tryAtlassianGuardPremiumButtonClicked'
  | 'guardTrialEndingButtonClicked'
  | 'guardPremiumTrialEndingButtonClicked'
  | 'guardUnsubscribeInfoLinkClicked'
  | 'viewBillingButtonClicked'
  | 'guardFeaturesLinkClicked'
  | 'openUsersInsightMenuButtonClicked'
  | 'viewUsersButtonClicked'
  | 'viewClaimAccountsButtonClicked'
  | 'viewManagedUsersButtonClicked'
  | 'viewInsightsButtonClicked'
  | 'viewGuardDetectButtonClicked'
  | 'viewAuditLogsButtonClicked'
  | 'viewUserApiTokensButtonClicked'
  | 'viewCommunityButtonClicked'
  | 'guardReactivateLinkClicked'
  | 'claimAccountsButtonClicked'
  | 'connectIdpButtonClicked'
  | 'learnMoreIdpButtonClicked'
  | 'recommendedAtlassianGuardButtonClicked'
  | 'exploreAtlassianGuardButtonClicked'
  | 'viewAuthPolicyButtonClicked'
  | 'verifyDomainButtonClicked'
  | 'viewAdminsButtonClicked'
  | 'viewDomainsButtonClicked'
  | 'viewAccountsButtonClicked'
  | 'viewPoliciesButtonClicked'
  | 'exploreAtlassianGuardLinkClicked'
  | 'requiresAtlassianGuardLinkClicked'
  | 'exploreAtlassianGuardPremiumLinkClicked'
  | 'viewIdentityProvidersButtonClicked'
  | 'addAuthPoliciesButtonClicked'
  | 'viewExternalUserPoliciesButtonClicked'
  | 'viewDataPoliciesButtonClicked'
  | 'viewMobilePoliciesButtonClicked'
  | 'setupDataClassificationButtonClicked'
  | 'viewSecurityAlertsButtonClicked'
  | 'sendSecurityAlertsButtonClicked';

export const securityGuideEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/3366366633/Access+SMB+Security+Guide+Overhaul+Analytics+Spec',
  screen: {
    securityChecklistScreenEvent: ({
      isAccessEnabled,
      isDomainVerified,
    }: {
      isAccessEnabled: boolean;
      isDomainVerified: boolean;
    }): ScreenEvent => ({
      data: {
        name: SecurityChecklistScreen.securityChecklistScreen,
        attributes: {
          isAccessEnabled,
          isDomainVerified,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30536',
    }),
  },
  ui: {
    hideCompletedTasksButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'hideCompletedTasks',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67525',
    }),
    showCompletedTasksButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'showCompletedTasks',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67526',
    }),
    guardReactivateLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'guardReactivate',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67687',
    }),
    openExternalInsightsMenuButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openExternalInsightsMenu',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67626',
    }),
    viewExternalInsightsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewExternalInsights',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67627',
    }),
    viewExternalUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewExternalUsers',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58454',
    }),
    openManagedInsightMenuButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openManagedInsightMenu',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67641',
    }),
    viewManagedAuthPolicyButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewManagedAuthPolicy',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67644',
    }),
    viewManagedInsightsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewManagedInsights',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67643',
    }),
    viewManagedAccountsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewManagedAccounts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18247',
    }),
    tryAtlassianGuardButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryAtlassianGuard',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67668',
    }),
    tryAtlassianGuardPremiumButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryAtlassianGuardPremium',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71253',
    }),
    guardTrialEndingButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'guardTrialEnding',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67667',
    }),
    guardPremiumTrialEndingButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'guardPremiumTrialEnding',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72606',
    }),
    guardUnsubscribeInfoLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'guardUnsubscribeInfo',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67666',
    }),
    viewBillingButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewBilling',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34516',
    }),
    guardFeaturesLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'guardFeatures',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67665',
    }),
    openUsersInsightMenuButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openUsersInsightMenu',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67663',
    }),
    viewUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewUsers',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67664',
    }),
    viewClaimAccountsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewClaimAccounts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58452',
    }),
    viewManagedUsersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewManagedUsers',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58457',
    }),
    viewInsightsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewInsights',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30559',
    }),
    viewGuardDetectButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewGuardDetect',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71134',
    }),
    viewAuditLogsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAuditLogs',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34521',
    }),
    viewUserApiTokensButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewUserApiTokens',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67589',
    }),
    viewCommunityButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewCommunity',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67590',
    }),
    claimAccountsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'claimAccounts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18246',
    }),
    connectIdpButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectIdp',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67802',
    }),
    learnMoreIdpButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMoreIdp',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67803',
    }),
    recommendedAtlassianGuardButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'recommendedAtlassianGuard',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67805',
    }),
    exploreAtlassianGuardButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exploreAtlassianGuard',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67806',
    }),
    viewAuthPolicyButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAuthPolicy',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56639',
    }),
    verifyDomainButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'verifyDomainButton',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15870',
    }),
    viewAdminsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAdmins',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34514',
    }),
    viewDomainsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewDomains',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34515',
    }),
    viewAccountsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAccounts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34523',
    }),
    viewPoliciesButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewPoliciesButton',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34526',
    }),
    exploreAtlassianGuardLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'exploreAtlassianGuard',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67669',
    }),
    requiresAtlassianGuardLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'requiresAtlassianGuard',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71396',
    }),
    exploreAtlassianGuardPremiumLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'exploreAtlassianGuardPremium',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70532',
    }),
    viewIdentityProvidersButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewIdentityProviders',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57837',
    }),
    addAuthPoliciesButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addAuthPolicies',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67670',
    }),
    viewExternalUserPoliciesButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewExternalUserPolicies',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67671',
    }),
    viewDataPoliciesButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewDataPolicies',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67672',
    }),
    viewMobilePoliciesButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewMobilePolicies',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67673',
    }),
    setupDataClassificationButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewDataClassification',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70567',
    }),
    viewSecurityAlertsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewSecurityAlerts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70566',
    }),
    sendSecurityAlertsButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sendSecurityAlerts',
        source: SecurityChecklistScreen.securityChecklistScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70565',
    }),
  },
  track: {},
  operational: {},
};
