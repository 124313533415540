function windowMockName(name: string) {
  return `__E2E_MOCK_${name}__`;
}

/**
 * A way of mocking out the original value of something in E2E tests.
 * Call attachMock in your e2e tests to specify what the mock value should be
 *
 * @param {T} originalValue The value used outside of test/development and when no mock exists
 * @param {string} name The name used to attach a mock to the window
 * @returns {T} The mocked value (if in test/dev env & the mock is attached to the window)
 */
export function withMockOverride<T>(originalValue: T, name: string): T {
  const mockName = windowMockName(name);
  if (window[mockName as any] !== undefined) {
    return (window as any)[mockName];
  }

  return originalValue;
}

/* TODO: Unfortunately, at the moment, the Cypress tests can't comprehend TS so can't use this for now
export function attachMock(mockedValue, name: string) {
  window[windowMockName(name)] = mockedValue;
} */
