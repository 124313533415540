/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessBundle {
  guest = "guest",
  user = "user",
}

export enum AccessEntitlementStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  OFFLINE = "OFFLINE",
}

export enum AccessLevel {
  ADMIN = "ADMIN",
  NONE = "NONE",
  SYSADMIN = "SYSADMIN",
  USE = "USE",
}

export enum AccessRequestStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum AccessRequestType {
  INVITE = "INVITE",
  REQUEST = "REQUEST",
}

export enum AccessRequestUserType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export enum AccountStatus {
  active = "active",
  closed = "closed",
  inactive = "inactive",
  partial = "partial",
}

export enum AccountType {
  APP = "APP",
  ATLASSIAN = "ATLASSIAN",
  CUSTOMER = "CUSTOMER",
}

export enum ActiveStatus {
  BLOCKED = "BLOCKED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum ActorAccountType {
  app = "app",
  atlassian = "atlassian",
  customer = "customer",
}

export enum AdminNotificationSettingsOptions {
  PRODUCT_CREATION_REQUEST_EMAIL = "PRODUCT_CREATION_REQUEST_EMAIL",
  UNMANAGED_ACCOUNT_DIGEST_EMAIL = "UNMANAGED_ACCOUNT_DIGEST_EMAIL",
}

export enum AdminNotifications {
  ON_DIRECT_ACCESS_AND_REQUEST = "ON_DIRECT_ACCESS_AND_REQUEST",
  ON_REQUEST = "ON_REQUEST",
}

export enum AdvancedRoadmapPlans {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
}

export enum ApiTokenAccessMode {
  ALLOW = "ALLOW",
  BLOCK = "BLOCK",
}

export enum AppEnvironmentType {
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
}

export enum AppLinkErrorStatusCategory {
  ACCESS_ERROR = "ACCESS_ERROR",
  CONFIG_ERROR = "CONFIG_ERROR",
  DEPRECATED = "DEPRECATED",
  DISABLED = "DISABLED",
  INCOMPATIBLE = "INCOMPATIBLE",
  NETWORK_ERROR = "NETWORK_ERROR",
  NON_ATLASSIAN = "NON_ATLASSIAN",
  SYSTEM = "SYSTEM",
  UNKNOWN = "UNKNOWN",
}

export enum AppNetworkEgressCategory {
  ANALYTICS = "ANALYTICS",
}

export enum AppNetworkPermissionType {
  FETCH_BACKEND_SIDE = "FETCH_BACKEND_SIDE",
  FETCH_CLIENT_SIDE = "FETCH_CLIENT_SIDE",
  FONTS = "FONTS",
  FRAMES = "FRAMES",
  IMAGES = "IMAGES",
  MEDIA = "MEDIA",
  NAVIGATION = "NAVIGATION",
  SCRIPTS = "SCRIPTS",
  STYLES = "STYLES",
}

export enum AppRequestEdition {
  ADVANCED = "ADVANCED",
  STANDARD = "STANDARD",
}

export enum AppRequestProductName {
  bitbucket = "bitbucket",
  compass = "compass",
  confluence = "confluence",
  jira = "jira",
}

export enum AppRequestStatus {
  INSTALLED = "INSTALLED",
  OPEN = "OPEN",
  REJECTED = "REJECTED",
}

export enum AppTaskState {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
}

export enum Assets {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
}

export enum AtlassianConnectAccessTokenLicenseState {
  ACTIVE_SUBSCRIPTION = "ACTIVE_SUBSCRIPTION",
  ACTIVE_SUBSCRIPTION_CANCELLED = "ACTIVE_SUBSCRIPTION_CANCELLED",
  ACTIVE_TRIAL = "ACTIVE_TRIAL",
  INACTIVE_SUBSCRIPTION = "INACTIVE_SUBSCRIPTION",
  INACTIVE_TRIAL = "INACTIVE_TRIAL",
  NONE = "NONE",
}

export enum AtlassianConnectSupportedProduct {
  CONFLUENCE = "CONFLUENCE",
  JIRA = "JIRA",
}

export enum AtlassianProductHostingType {
  CLOUD = "CLOUD",
}

export enum AuditLogEventTypes {
  ADMIN_ACTIVITY = "ADMIN_ACTIVITY",
  USER_ACTIVITY = "USER_ACTIVITY",
}

export enum AuditLogMessageFormat {
  adf = "adf",
  simple = "simple",
}

export enum AuditLogType {
  jobs = "jobs",
  links = "links",
}

export enum AuthPolicyBillingType {
  BASIC = "BASIC",
  STANDARD = "STANDARD",
}

export enum AuthPolicySocialLoginStatus {
  ALLOW_ALL = "ALLOW_ALL",
  DENY_ALL = "DENY_ALL",
}

export enum AuthPolicyType {
  azure = "azure",
  classic = "classic",
  google = "google",
  saml = "saml",
}

export enum AuthSsoConfigurationType {
  azure = "azure",
  google = "google",
  saml = "saml",
}

export enum AvDeploymentType {
  CLOUD = "CLOUD",
  DATA_CENTER = "DATA_CENTER",
}

export enum AvDiscoveryJobStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum AvDiscoverySources {
  ASSESSMENT_UPLOAD = "ASSESSMENT_UPLOAD",
  CLOUD_APPLICATION_LINKS = "CLOUD_APPLICATION_LINKS",
  CLOUD_LICENSE_DATA = "CLOUD_LICENSE_DATA",
  CONNECTIVITY = "CONNECTIVITY",
  DC_APPLICATION_LINKS = "DC_APPLICATION_LINKS",
  DC_LICENSE_DATA = "DC_LICENSE_DATA",
  SITES_IN_YOUR_ORG = "SITES_IN_YOUR_ORG",
}

export enum AvInsightImpactType {
  EXTENDS_TIME = "EXTENDS_TIME",
  LIMITS_ACCESS = "LIMITS_ACCESS",
  LIMITS_ENTITIES = "LIMITS_ENTITIES",
  LIMITS_SYNCING = "LIMITS_SYNCING",
  NEEDS_OPTIMIZATION = "NEEDS_OPTIMIZATION",
  SKIPPED = "SKIPPED",
  WITHIN_GUARDRAILS = "WITHIN_GUARDRAILS",
}

export enum AvInsightStatus {
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  SKIPPED = "SKIPPED",
}

export enum AvInstancePlan {
  EVALUATION = "EVALUATION",
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  SEATS_BASED = "SEATS_BASED",
  STANDARD = "STANDARD",
  UNLIMITED = "UNLIMITED",
}

export enum AvProductAssessmentStatus {
  ASSESSING = "ASSESSING",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  NOT_STARTED = "NOT_STARTED",
  NO_ASSESSMENT_DATA = "NO_ASSESSMENT_DATA",
  OUTDATED = "OUTDATED",
  REASSESSING = "REASSESSING",
}

export enum AvProductInsightsStatus {
  HAS_ERRORS = "HAS_ERRORS",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  NO_DATA = "NO_DATA",
  OUTDATED = "OUTDATED",
  UPDATING = "UPDATING",
  UP_TO_DATE = "UP_TO_DATE",
}

export enum AvProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum AvProductType {
  CONFLUENCE = "CONFLUENCE",
  JIRA = "JIRA",
  UNKNOWN_ATLASSIAN = "UNKNOWN_ATLASSIAN",
}

export enum AvToggleDiscardAction {
  HIDE = "HIDE",
  UNHIDE = "UNHIDE",
}

export enum AvTrustLevel {
  CONFIRMED = "CONFIRMED",
  NOT_CONFIRMED = "NOT_CONFIRMED",
}

export enum AvUploadStatusEnum {
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum Availability {
  AVAILABLE = "AVAILABLE",
  INVALID = "INVALID",
  TAKEN = "TAKEN",
}

export enum BaaStatus {
  REQUESTED = "REQUESTED",
  SIGNED = "SIGNED",
  UNSIGNED = "UNSIGNED",
}

export enum BlockedReason {
  API_COMMUNICATION = "API_COMMUNICATION",
  BLOCKED_EXPERIENCES = "BLOCKED_EXPERIENCES",
  NONE = "NONE",
  SPECIAL_GROUP = "SPECIAL_GROUP",
  UNMODIFIABLE = "UNMODIFIABLE",
}

export enum BrieBackupSelections {
  APPS = "APPS",
  ASSETS = "ASSETS",
  ATTACHMENTS = "ATTACHMENTS",
  DATA = "DATA",
  SCOPED_USERS = "SCOPED_USERS",
  USERS = "USERS",
}

export enum BrieBackupStatus {
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  SUCCESS = "SUCCESS",
  TIMED_OUT = "TIMED_OUT",
}

export enum BrieDownloadStatus {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum BrieEncryptionType {
  CMK = "CMK",
  DEFAULT = "DEFAULT",
}

export enum BrieExportDownloadStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum BrieJobType {
  BACKUP = "BACKUP",
  DOWNLOAD = "DOWNLOAD",
  EXPORT = "EXPORT",
  IMPORT = "IMPORT",
  RESTORE = "RESTORE",
  UPLOAD = "UPLOAD",
}

export enum BriePolicyStatus {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export enum BrieProducts {
  CONFLUENCE = "CONFLUENCE",
  JIRA = "JIRA",
}

export enum BrieScheduleInterval {
  DAILY = "DAILY",
  ONCE = "ONCE",
  WEEKLY = "WEEKLY",
}

export enum BrieUploadStatus {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  INVALID = "INVALID",
  IN_PROGRESS = "IN_PROGRESS",
  VALID = "VALID",
  VALIDATING = "VALIDATING",
}

export enum BulkJsmAccountMigrationStatus {
  FAILURE = "FAILURE",
  MIGRATING = "MIGRATING",
  SUCCESS = "SUCCESS",
}

/**
 * BYOK Policies
 */
export enum ByokPolicyKeyManagementModel {
  ATLASSIAN_BYOK = "ATLASSIAN_BYOK",
  CUSTOMER_BYOK = "CUSTOMER_BYOK",
}

export enum ByokPolicyRootOfTrust {
  AWS_KMS = "AWS_KMS",
}

export enum C2CMigrationCheckStatus {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  SCHEDULED = "SCHEDULED",
  SKIPPED = "SKIPPED",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum C2CMigrationDataProgress {
  EXPORTED = "EXPORTED",
  EXPORTING = "EXPORTING",
  FAILED = "FAILED",
  IMPORTED = "IMPORTED",
  IMPORTING = "IMPORTING",
  INCOMPLETE = "INCOMPLETE",
  READY = "READY",
}

export enum C2CMigrationProgressStatus {
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  CREATED = "CREATED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  SUCCESS = "SUCCESS",
  TIMED_OUT = "TIMED_OUT",
}

export enum C2CMigrationStatus {
  CANCELLED = "CANCELLED",
  COMPLETING = "COMPLETING",
  CREATED = "CREATED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  INITIALIZED = "INITIALIZED",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  SKIPPED = "SKIPPED",
  SUCCESS = "SUCCESS",
  TIMED_OUT = "TIMED_OUT",
}

export enum C2CProducts {
  confluence = "confluence",
  jira = "jira",
}

export enum CcpActivationReason {
  ADVANTAGE_PRICING = "ADVANTAGE_PRICING",
  DEFAULT_PRICING = "DEFAULT_PRICING",
  EXPERIMENTAL_PRICING = "EXPERIMENTAL_PRICING",
}

export enum CcpBillingInterval {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum CcpOfferingUncollectibleActionType {
  CANCEL = "CANCEL",
  DOWNGRADE = "DOWNGRADE",
  NO_ACTION = "NO_ACTION",
}

export enum CcpPricingType {
  EXTERNAL = "EXTERNAL",
  FREE = "FREE",
  LIMITED_FREE = "LIMITED_FREE",
  PAID = "PAID",
}

export enum CcpRelationshipType {
  ADDON_DEPENDENCE = "ADDON_DEPENDENCE",
  APP_COMPATIBILITY = "APP_COMPATIBILITY",
  COLLECTION = "COLLECTION",
  COLLECTION_TRIAL = "COLLECTION_TRIAL",
  ENTERPRISE = "ENTERPRISE",
  ENTERPRISE_SANDBOX_GRANT = "ENTERPRISE_SANDBOX_GRANT",
  FAMILY_CONTAINER = "FAMILY_CONTAINER",
  MULTI_INSTANCE = "MULTI_INSTANCE",
  SANDBOX_DEPENDENCE = "SANDBOX_DEPENDENCE",
  SANDBOX_GRANT = "SANDBOX_GRANT",
  UNKNOWN = "UNKNOWN",
}

export enum CcpSupportedBillingSystems {
  BACK_OFFICE = "BACK_OFFICE",
  CCP = "CCP",
  HAMS = "HAMS",
  OPSGENIE = "OPSGENIE",
}

export enum ChargeType {
  agent = "agent",
  product_user = "product_user",
  user = "user",
}

export enum CheckWorkflowStatusResult {
  EXECUTING = "EXECUTING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export enum ClaimDomainStatusType {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
}

export enum ClaimStatus {
  managed = "managed",
  unmanaged = "unmanaged",
}

export enum CohortType {
  C2C_JIRA_ACTIVE_USERS = "C2C_JIRA_ACTIVE_USERS",
  NEVER_USED_C2C_JIRA = "NEVER_USED_C2C_JIRA",
  NOT_USED_C2C_JIRA_IN_3_MONTHS = "NOT_USED_C2C_JIRA_IN_3_MONTHS",
}

export enum ColumnId {
  PLAN_COLUMN = "PLAN_COLUMN",
  PRODUCT_COLUMN = "PRODUCT_COLUMN",
  RADIO_COLUMN = "RADIO_COLUMN",
  SANDBOXES_CREATED_COLUMN = "SANDBOXES_CREATED_COLUMN",
}

export enum ColumnType {
  integer = "integer",
  product = "product",
  radio = "radio",
  status = "status",
  text = "text",
}

export enum ConsumerType {
  C2C = "C2C",
  S2C = "S2C",
  Sandbox = "Sandbox",
  X2C = "X2C",
}

export enum CrossProjectBoardsAndFilters {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
}

export enum CustomDomainAppliesTo {
  HELP_CENTER = "HELP_CENTER",
  JIRA = "JIRA",
  PRODUCT = "PRODUCT",
}

export enum CustomDomainStatus {
  ACTIVE = "ACTIVE",
  DELETING = "DELETING",
  ERROR = "ERROR",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  PROVISIONED = "PROVISIONED",
  SUSPENDED = "SUSPENDED",
  UPDATING = "UPDATING",
}

/**
 * https: // hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2491857607/UI+statuses+and+descriptions+for+custom+domains
 */
export enum CustomDomainStatusDescription {
  ADDITIONAL_VERIFICATION_REQUIRED = "ADDITIONAL_VERIFICATION_REQUIRED",
  CAA_ERROR = "CAA_ERROR",
  CA_CHANGED = "CA_CHANGED",
  CA_SN_CHANGED = "CA_SN_CHANGED",
  CERTIFICATE_DETECTED_PADLOCK = "CERTIFICATE_DETECTED_PADLOCK",
  CERTIFICATE_EXPIRED = "CERTIFICATE_EXPIRED",
  CLOUDFLARE_ERROR = "CLOUDFLARE_ERROR",
  CNAME_CHANGED = "CNAME_CHANGED",
  DOMAIN_NOT_ALLOWED = "DOMAIN_NOT_ALLOWED",
  DOMAIN_VALIDATION_TIMEOUT = "DOMAIN_VALIDATION_TIMEOUT",
  FQDN_DNS_DETECTED = "FQDN_DNS_DETECTED",
  GENERATING_RECORDS = "GENERATING_RECORDS",
  GENERIC_ERROR = "GENERIC_ERROR",
  NEW_CERTIFICATE_DETECTED = "NEW_CERTIFICATE_DETECTED",
  NO_CNAME_FOUND = "NO_CNAME_FOUND",
  PRODUCT_NOT_ASSOCIATED = "PRODUCT_NOT_ASSOCIATED",
  READY_FOR_ACTIVATION = "READY_FOR_ACTIVATION",
  READY_TO_ADD_RECORDS = "READY_TO_ADD_RECORDS",
  RECOVERABLE_ALERT = "RECOVERABLE_ALERT",
  REDIRECT_DNS_DETECTED = "REDIRECT_DNS_DETECTED",
  REDIRECT_VALIDATION_TIMEDOUT = "REDIRECT_VALIDATION_TIMEDOUT",
  SERIAL_NUMBER_CHANGED = "SERIAL_NUMBER_CHANGED",
}

/**
 * Data Security Policies
 */
export enum DSPPolicyCoverageLevel {
  CLASSIFICATION = "CLASSIFICATION",
  CONTAINER = "CONTAINER",
  DC_WORKSPACE = "DC_WORKSPACE",
  UNASSIGNED = "UNASSIGNED",
  WORKSPACE = "WORKSPACE",
}

export enum DashboardSortKey {
  expiredAt = "expiredAt",
  name = "name",
  status = "status",
}

export enum DashboardSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum DataClassificationLevelColor {
  BLUE = "BLUE",
  GREEN = "GREEN",
  GREY = "GREY",
  LIME = "LIME",
  ORANGE = "ORANGE",
  PURPLE = "PURPLE",
  RED = "RED",
  RED_BOLD = "RED_BOLD",
  TEAL = "TEAL",
  YELLOW = "YELLOW",
}

export enum DataClassificationLevelStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum DataClassificationOverrideMode {
  ANY = "ANY",
  DEFAULT_OR_ABOVE = "DEFAULT_OR_ABOVE",
  DISABLED = "DISABLED",
}

export enum DataCloneType {
  full = "full",
  selective = "selective",
}

export enum DataResidencyComponent {
  connect_apps = "connect_apps",
  product = "product",
}

export enum DataResidencyProduct {
  Confluence = "Confluence",
  Jira = "Jira",
  Mercury = "Mercury",
  Radar = "Radar",
  Rovo = "Rovo",
  Townsquare = "Townsquare",
}

export enum DelayOptInStatus {
  ELIGIBLE = "ELIGIBLE",
  NOT_ELIGIBLE = "NOT_ELIGIBLE",
  NOT_OPTED_IN = "NOT_OPTED_IN",
  OPTED_IN = "OPTED_IN",
}

export enum DirectoryDeletionValidationCode {
  CANNOT_DELETE_DEFAULT_DIRECTORY = "CANNOT_DELETE_DEFAULT_DIRECTORY",
  CANNOT_DELETE_DIRECTORY_WITH_PINNED_DOMAINS = "CANNOT_DELETE_DIRECTORY_WITH_PINNED_DOMAINS",
  CANNOT_DELETE_DIRECTORY_WITH_SAML = "CANNOT_DELETE_DIRECTORY_WITH_SAML",
  CANNOT_DELETE_DIRECTORY_WITH_USER_PROVISIONING = "CANNOT_DELETE_DIRECTORY_WITH_USER_PROVISIONING",
}

export enum DirectoryEntityType {
  groups = "groups",
  users = "users",
}

export enum DirectoryType {
  org = "org",
  site = "site",
}

export enum DomainClaimStatus {
  CLAIMED_BY_DIFF_ORG = "CLAIMED_BY_DIFF_ORG",
  CLAIMED_BY_OTHER_ORGS = "CLAIMED_BY_OTHER_ORGS",
  CLAIMED_BY_SAME_ORG = "CLAIMED_BY_SAME_ORG",
  NOT_CLAIMED = "NOT_CLAIMED",
}

export enum DomainOwnerType {
  CloudId = "CloudId",
  Google = "Google",
  Org = "Org",
}

export enum DomainVerificationType {
  AZURE = "AZURE",
  DNS = "DNS",
  GOOGLE = "GOOGLE",
  HTTP = "HTTP",
}

export enum EcosystemAppsInstalledInContextsFilterType {
  NAME = "NAME",
  ONLY_APP_IDS = "ONLY_APP_IDS",
}

export enum EcosystemAppsInstalledInContextsOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum EcosystemAppsInstalledInContextsOrderKey {
  NAME = "NAME",
  RELATED_APPS = "RELATED_APPS",
}

export enum EcosystemGlobalInstallationOverrideKeys {
  ALLOW_EGRESS_ANALYTICS = "ALLOW_EGRESS_ANALYTICS",
  ALLOW_LOGS_ACCESS = "ALLOW_LOGS_ACCESS",
}

export enum EcosystemInstallationOverrideKeys {
  ALLOW_EGRESS_ANALYTICS = "ALLOW_EGRESS_ANALYTICS",
}

export enum EcosystemMarketplaceListingStatus {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  READY_TO_LAUNCH = "READY_TO_LAUNCH",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum EditionType {
  business_ai = "business_ai",
  enterprise = "enterprise",
  free = "free",
  standard = "standard",
}

export enum EmailAndNotificationsAccessMode {
  ALLOW = "ALLOW",
  BLOCK = "BLOCK",
}

export enum EntityType {
  ARJ_PLANS = "ARJ_PLANS",
  ASSETS = "ASSETS",
  BOARDS_AND_FILTERS = "BOARDS_AND_FILTERS",
}

export enum ExclusionType {
  CONFLICTING = "CONFLICTING",
  CONFLICTING_SCHEMA_KEYS = "CONFLICTING_SCHEMA_KEYS",
  CONFLICTING_SCHEMA_NAMES = "CONFLICTING_SCHEMA_NAMES",
}

export enum ExternalDirectoryType {
  GSYNC = "GSYNC",
  IDP_SOURCED = "IDP_SOURCED",
  OSYNC = "OSYNC",
}

export enum ExternalUserSecurityBillingType {
  billable = "billable",
  nonBillable = "nonBillable",
}

export enum FeatureType {
  ALLOWLISTING = "ALLOWLISTING",
  ATLASSIAN_INTELLIGENCE = "ATLASSIAN_INTELLIGENCE",
}

export enum FlexAuthMigrationStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  NONE = "NONE",
  NOT_STARTED = "NOT_STARTED",
}

/**
 * Maps to https: // stash.atlassian.com/projects/IPS/repos/gsyncv2/browse/src/main/kotlin/io/atlassian/micros/gsyncv2/clients/ErrorOptions.kt#3
 */
export enum GSuiteFailureCaseType {
  emailalreadyexists = "emailalreadyexists",
  exceedProvisionLimit = "exceedProvisionLimit",
  hipchatservercommunicationerror = "hipchatservercommunicationerror",
  iedaDirectoryAlreadyExists = "iedaDirectoryAlreadyExists",
  iedaDirectoryDoesNotExist = "iedaDirectoryDoesNotExist",
  iedaDirectoryGroupAlreadyExists = "iedaDirectoryGroupAlreadyExists",
  iedaDirectoryGroupDoesNotExist = "iedaDirectoryGroupDoesNotExist",
  iedaUserDoesNotExist = "iedaUserDoesNotExist",
  iedaUserOrDirectoryDoesNotExist = "iedaUserOrDirectoryDoesNotExist",
  insufficientpermissions = "insufficientpermissions",
  invalidRequest = "invalidRequest",
  invalidusername = "invalidusername",
  morelicensesneeded = "morelicensesneeded",
  orgIdNotFound = "orgIdNotFound",
  productconfigurationfailure = "productconfigurationfailure",
  serverError = "serverError",
  userAlreadyDeleted = "userAlreadyDeleted",
}

export enum GSuiteLastSyncStatus {
  failure = "failure",
  successful = "successful",
}

export enum GSuiteOrgConnectionState {
  conflictingOrgConnected = "conflictingOrgConnected",
  eligibleOrgs = "eligibleOrgs",
  externalOrgConnected = "externalOrgConnected",
  instanceAvailable = "instanceAvailable",
  nonOrgTenantsExist = "nonOrgTenantsExist",
  orgAlreadyConnected = "orgAlreadyConnected",
  thisOrgConnected = "thisOrgConnected",
}

/**
 * Maps to https: // stash.atlassian.com/projects/IPS/repos/gsyncv2/browse/src/main/kotlin/io/atlassian/micros/gsyncv2/state/TenantState.kt#53
 */
export enum GSuiteSyncFailureReasonSuggestion {
  adddomain = "adddomain",
  disconnect = "disconnect",
  reconnect = "reconnect",
  reselect = "reselect",
  retry = "retry",
}

export enum GoalsAndProjectsSettingsState {
  DEFAULT_GROUP_ENABLED = "DEFAULT_GROUP_ENABLED",
  DISABLED = "DISABLED",
}

export enum GroupConflictResolutionStrategy {
  OVERRIDE_LOCAL_GROUPS = "OVERRIDE_LOCAL_GROUPS",
  REJECT_IDP_GROUPS = "REJECT_IDP_GROUPS",
}

export enum GroupMembership {
  manual = "manual",
  preserve = "preserve",
}

export enum GroupSearchView {
  WITHOUT_ENRICHMENT = "WITHOUT_ENRICHMENT",
}

export enum GroupType {
  DEFAULT_ALL_USERS = "DEFAULT_ALL_USERS",
  IDP = "IDP",
}

/**
 * Only group is supported right now
 */
export enum GroupTypes {
  GROUP = "GROUP",
  TEAM = "TEAM",
}

export enum GuardPremiumEntitlementStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  OFFLINE = "OFFLINE",
}

export enum GuidelineRepresentation {
  EDITOR = "EDITOR",
  PLAIN = "PLAIN",
}

export enum IdPContainerType {
  customer = "customer",
  org = "org",
}

export enum IdpCreationStatusType {
  failure = "failure",
  in_progress = "in_progress",
  success = "success",
}

export enum IdpType {
  ACTIVE_DIRECTORY_FEDERATION_SERVICES = "ACTIVE_DIRECTORY_FEDERATION_SERVICES",
  AUTH0 = "AUTH0",
  DEFAULT = "DEFAULT",
  GOOGLE_CLOUD_IDENTITY = "GOOGLE_CLOUD_IDENTITY",
  GOOGLE_WORKSPACE = "GOOGLE_WORKSPACE",
  IDAPTIVE = "IDAPTIVE",
  JUMPCLOUD = "JUMPCLOUD",
  LEGACY = "LEGACY",
  MICROSOFT_AZURE_ACTIVE_DIRECTORY = "MICROSOFT_AZURE_ACTIVE_DIRECTORY",
  MICROSOFT_AZURE_ACTIVE_DIRECTORY_PRECONFIGURED = "MICROSOFT_AZURE_ACTIVE_DIRECTORY_PRECONFIGURED",
  OKTA = "OKTA",
  ONELOGIN = "ONELOGIN",
  OTHER = "OTHER",
  PING_IDENTITY = "PING_IDENTITY",
}

export enum IhsGuardrailStatus {
  APPROACHING_LIMIT = "APPROACHING_LIMIT",
  CRITICAL = "CRITICAL",
  EXCEEDING_LIMIT = "EXCEEDING_LIMIT",
  GOOD = "GOOD",
  MODERATE = "MODERATE",
  POOR = "POOR",
}

export enum IhsInstanceStatus {
  AT_RISK = "AT_RISK",
  CRITICAL = "CRITICAL",
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  MODERATE = "MODERATE",
  POOR = "POOR",
}

export enum IhsMetricStatus {
  GOOD = "GOOD",
  MODERATE = "MODERATE",
  POOR = "POOR",
}

export enum IhsNodeStatus {
  GOOD = "GOOD",
  MODERATE = "MODERATE",
  POOR = "POOR",
}

export enum ImpactSeverity {
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  SKIPPED = "SKIPPED",
}

export enum InsightsReportChartColorPalette {
  categorical = "categorical",
  severity = "severity",
  single = "single",
}

export enum InsightsReportChartType {
  area = "area",
  bar = "bar",
  donut = "donut",
  line = "line",
  pie = "pie",
  single_value = "single_value",
}

export enum InsightsReportMetricNames {
  domain = "domain",
  mfaEnabled = "mfaEnabled",
  mfaNotEnabled = "mfaNotEnabled",
  mobile_app_users = "mobile_app_users",
  rovoAccessBillableUsers = "rovoAccessBillableUsers",
  rovoAccessNonBillableUsers = "rovoAccessNonBillableUsers",
  ssoEnforced = "ssoEnforced",
  totalExternalUsers = "totalExternalUsers",
  totalManagedAccounts = "totalManagedAccounts",
  twoStepEnforced = "twoStepEnforced",
  twoStepVerificationDisabled = "twoStepVerificationDisabled",
  twoStepVerificationEnabled = "twoStepVerificationEnabled",
}

export enum InsightsReportMetricType {
  area = "area",
  line = "line",
}

export enum InvitationStatus {
  COMPLETE = "COMPLETE",
  EMAIL_SUPPRESSED = "EMAIL_SUPPRESSED",
  INVALID = "INVALID",
  JOINED = "JOINED",
  PENDING = "PENDING",
}

export enum JiraDashboardSelection {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
}

export enum JiraProjectStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum Key {
  privacyPolicy = "privacyPolicy",
  termsOfService = "termsOfService",
}

export enum KnowledgeDiscoveryBookmarkState {
  ACTIVE = "ACTIVE",
  SUGGESTED = "SUGGESTED",
}

export enum LandingHostProduct {
  confluence = "confluence",
  jira = "jira",
}

export enum LicenseBillingPeriod {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum LicenseOverrideState {
  ACTIVE = "ACTIVE",
  ADVANCED = "ADVANCED",
  INACTIVE = "INACTIVE",
  STANDARD = "STANDARD",
  TRIAL = "TRIAL",
}

export enum MCCContainerProjectType {
  BUSINESS = "BUSINESS",
  SERVICE_DESK = "SERVICE_DESK",
  SOFTWARE = "SOFTWARE",
}

export enum MCCMigrationContainerType {
  App = "App",
  ConfluenceSpace = "ConfluenceSpace",
  JiraProject = "JiraProject",
  Site = "Site",
}

export enum MCCMigrationStage {
  PRODUCTION = "PRODUCTION",
  TEST = "TEST",
}

export enum MCCMigrationStatus {
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  DRAFT = "DRAFT",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
  PENDING = "PENDING",
  READY = "READY",
  SAVED = "SAVED",
  SKIPPED = "SKIPPED",
  SUCCESS = "SUCCESS",
  TIMED_OUT = "TIMED_OUT",
  UNKNOWN = "UNKNOWN",
}

export enum MCCReportState {
  AVAILABLE = "AVAILABLE",
  EXPIRED = "EXPIRED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum MCCReportSupport {
  NOT_SUPPORTED = "NOT_SUPPORTED",
  SUPPORTED = "SUPPORTED",
}

export enum MCCReportType {
  ERROR = "ERROR",
  POST_MIGRATION_REPORT = "POST_MIGRATION_REPORT",
}

export enum ManagementAccess {
  ALL = "ALL",
  CANNOT_DELETE = "CANNOT_DELETE",
  NONE = "NONE",
  READ_ONLY = "READ_ONLY",
}

export enum MarketplaceAppPaymentModel {
  FREE = "FREE",
  PAID_VIA_ATLASSIAN = "PAID_VIA_ATLASSIAN",
  PAID_VIA_PARTNER = "PAID_VIA_PARTNER",
}

export enum MarketplaceAppVersionVisibility {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum MarketplaceListingStatus {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  READY_TO_LAUNCH = "READY_TO_LAUNCH",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum MediaUsageEdition {
  free = "free",
  premium = "premium",
  standard = "standard",
}

export enum MemberProvisioningSource {
  GSYNC = "GSYNC",
  SCIM = "SCIM",
}

export enum MemberStateFilter {
  ALL = "ALL",
  BILLABLE = "BILLABLE",
  DEACTIVATED = "DEACTIVATED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  NON_BILLABLE = "NON_BILLABLE",
  PENDING_DELETE = "PENDING_DELETE",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}

export enum MemberStatus {
  BLOCKED = "BLOCKED",
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum MembershipStatus {
  active = "active",
  no_membership = "no_membership",
  suspended = "suspended",
}

export enum MigrationCheckStatus {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  SCHEDULED = "SCHEDULED",
  SKIPPED = "SKIPPED",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum MigrationProductFamily {
  CONFLUENCE = "CONFLUENCE",
  JIRA = "JIRA",
}

export enum MigrationScheduleTypes {
  immediately = "immediately",
  maintenance_window = "maintenance_window",
  specified = "specified",
}

export enum MigrationStatus {
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  CREATED = "CREATED",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
  SUCCESS = "SUCCESS",
  TIMED_OUT = "TIMED_OUT",
}

export enum MigrationsProductTypes {
  confluence_server = "confluence_server",
  jira_server = "jira_server",
}

export enum MobilePlatform {
  Android = "Android",
  iOS = "iOS",
  macOS = "macOS",
}

export enum MpsARModeOption {
  ALL = "ALL",
  NONE = "NONE",
  SCOPED = "SCOPED",
}

export enum MpsBoardsAndFiltersModeOption {
  ALL = "ALL",
  NONE = "NONE",
  SCOPED = "SCOPED",
}

export enum MpsDashboardModeOption {
  ALL = "ALL",
  NONE = "NONE",
  SCOPED = "SCOPED",
}

export enum MpsDataScopeExpand {
  OPTIONS = "OPTIONS",
  PREFLIGHT = "PREFLIGHT",
  PROGRESS = "PROGRESS",
}

export enum MpsDataScopeStatus {
  CHECK_COMPLETED = "CHECK_COMPLETED",
  CHECK_FAILED = "CHECK_FAILED",
  CHECK_RUNNING = "CHECK_RUNNING",
  CHECK_STARTING = "CHECK_STARTING",
  CHECK_WARNING = "CHECK_WARNING",
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  RUNNING = "RUNNING",
  STARTING = "STARTING",
  STOPPED = "STOPPED",
}

export enum MpsEntityType {
  ADVANCED_ROADMAPS = "ADVANCED_ROADMAPS",
  APP = "APP",
  BOARDS = "BOARDS",
  BOARDS_AND_FILTERS = "BOARDS_AND_FILTERS",
  CROSS_PROJECT_BOARDS = "CROSS_PROJECT_BOARDS",
  DASHBOARDS = "DASHBOARDS",
  FILTERS = "FILTERS",
  GLOBAL_ENTITIES = "GLOBAL_ENTITIES",
  GROUPS = "GROUPS",
  JIRA_CUSTOMER = "JIRA_CUSTOMER",
  PROJECT = "PROJECT",
  SPACE = "SPACE",
  TOTAL_ATTACHMENT_BYTES = "TOTAL_ATTACHMENT_BYTES",
  USERS = "USERS",
}

export enum MpsGlobalEntitiesType {
  CUSTOM_SYSTEM_TEMPLATES = "CUSTOM_SYSTEM_TEMPLATES",
  GLOBAL_PAGE_TEMPLATES = "GLOBAL_PAGE_TEMPLATES",
}

export enum MpsJSMCustomersModeOption {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
}

export enum MpsPhase {
  PROD = "PROD",
  TEST = "TEST",
}

export enum MpsProjectIncludedDataOption {
  ALL = "ALL",
  APP_DATA_PRELOAD = "APP_DATA_PRELOAD",
  ATTACHMENTS = "ATTACHMENTS",
}

export enum MpsProjectType {
  business = "business",
  jira = "jira",
  service_desk = "service_desk",
  software = "software",
}

export enum MpsSourceType {
  SERVER = "SERVER",
}

export enum MpsSpaceIncludedDataOption {
  ALL = "ALL",
  ATTACHMENTS = "ATTACHMENTS",
}

export enum MpsUserAndGroupModeOption {
  ALL = "ALL",
  NONE = "NONE",
  REFERENCED = "REFERENCED",
  SCOPED = "SCOPED",
}

export enum OSyncConnectStatusType {
  connect = "connect",
  reconnect = "reconnect",
}

export enum OSyncDirectoryObtainMethod {
  CREATE = "CREATE",
  TAKEOVER = "TAKEOVER",
}

export enum OSyncFailSuggestion {
  disconnect = "disconnect",
  reconnect = "reconnect",
  reselect = "reselect",
  retry = "retry",
}

export enum OSyncLastSyncStatus {
  failure = "failure",
  successful = "successful",
}

export enum OSyncUserIdentifier {
  EMAIL = "EMAIL",
  UPN = "UPN",
}

export enum OperationType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum OptInStatus {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  NEVER = "NEVER",
  SUCCESS = "SUCCESS",
}

export enum OrgLifecycleStatusCheckName {
  ORG_DELETE_INITIATION = "ORG_DELETE_INITIATION",
  ORG_DELETE_VALIDATION = "ORG_DELETE_VALIDATION",
}

export enum OrgLifecycleStatusCheckResult {
  EXECUTING = "EXECUTING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export enum OrgPolicyApplicationStatus {
  applied = "applied",
  applying = "applying",
  failed = "failed",
  removed = "removed",
  removing = "removing",
  requested = "requested",
  scheduled = "scheduled",
}

export enum OrgPolicyResourceMigrationType {
  CONNECT_APPS = "CONNECT_APPS",
  PRODUCT = "PRODUCT",
}

export enum OrgPolicyStatus {
  deleted = "deleted",
  disabled = "disabled",
  enabled = "enabled",
}

export enum OrgSettingKeys {
  is3rdPartyInvitesEnabled = "is3rdPartyInvitesEnabled",
}

export enum OrgUsersRole {
  IT_ADMIN = "IT_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  PRODUCT_ADMIN = "PRODUCT_ADMIN",
  PRODUCT_BASIC = "PRODUCT_BASIC",
  PRODUCT_CONTRIBUTOR = "PRODUCT_CONTRIBUTOR",
  PRODUCT_CUSTOMER = "PRODUCT_CUSTOMER",
  PRODUCT_EXTERNAL_COLLABORATOR = "PRODUCT_EXTERNAL_COLLABORATOR",
  PRODUCT_HELPSEEKER = "PRODUCT_HELPSEEKER",
  PRODUCT_STAKEHOLDER = "PRODUCT_STAKEHOLDER",
  PRODUCT_VIEWER = "PRODUCT_VIEWER",
  SECURITY_ADMIN = "SECURITY_ADMIN",
  SITE_ADMIN = "SITE_ADMIN",
  USER_ACCESS_ADMIN = "USER_ACCESS_ADMIN",
}

export enum OrgUsersStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  NONE = "NONE",
  SUSPENDED = "SUSPENDED",
}

export enum OwnerType {
  EXT_GSYNC = "EXT_GSYNC",
  EXT_SCIM = "EXT_SCIM",
  MANAGED = "MANAGED",
  ORG = "ORG",
}

export enum PaginationSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Permission {
  EXTERNAL_COLLAB_WRITE = "EXTERNAL_COLLAB_WRITE",
  MANAGE = "MANAGE",
  WRITE = "WRITE",
}

export enum Plan {
  EVALUATION = "EVALUATION",
  FREE = "FREE",
  FREE_SANDBOX = "FREE_SANDBOX",
  PREMIUM = "PREMIUM",
  PREMIUM_SANDBOX = "PREMIUM_SANDBOX",
  SEATS_BASED = "SEATS_BASED",
  STANDARD = "STANDARD",
  STANDARD_SANDBOX = "STANDARD_SANDBOX",
  UNLIMITED = "UNLIMITED",
}

export enum PlatformPermission {
  ADD_PRODUCT = "ADD_PRODUCT",
  ADD_USER_TO_USERBASE = "ADD_USER_TO_USERBASE",
  CHANGE_GROUP_MEMBERSHIP = "CHANGE_GROUP_MEMBERSHIP",
  CHANGE_GROUP_PRODUCT_ROLES = "CHANGE_GROUP_PRODUCT_ROLES",
  CHANGE_ORG_ROLES = "CHANGE_ORG_ROLES",
  CHANGE_USER_PRODUCT_ROLES = "CHANGE_USER_PRODUCT_ROLES",
  CREATE_DELETE_GROUP = "CREATE_DELETE_GROUP",
  EDIT_GROUP_DETAILS = "EDIT_GROUP_DETAILS",
  EXPORT_USERBASE_DETAILS = "EXPORT_USERBASE_DETAILS",
  GRANDFATHERED_SITE_ADMIN = "GRANDFATHERED_SITE_ADMIN",
  IMPERSONATE_USER = "IMPERSONATE_USER",
  MANAGE = "MANAGE",
  MANAGE_GROUP_TEAM_LINK = "MANAGE_GROUP_TEAM_LINK",
  MANAGE_ORG = "MANAGE_ORG",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_SLASH_ADMIN = "ORG_SLASH_ADMIN",
  REMOVE_USER = "REMOVE_USER",
  SECURITY_ADMIN = "SECURITY_ADMIN",
  SUGGEST_USER_PROFILE_CHANGES = "SUGGEST_USER_PROFILE_CHANGES",
  UPDATE_USER_ACTIVE_STATUS = "UPDATE_USER_ACTIVE_STATUS",
  USER_ADMIN_ORG = "USER_ADMIN_ORG",
  VIEW_AUDIT_LOGS = "VIEW_AUDIT_LOGS",
  VIEW_CLASSIFICATION_LIST = "VIEW_CLASSIFICATION_LIST",
  VIEW_DSP_LIST = "VIEW_DSP_LIST",
  VIEW_GROUP_LIST = "VIEW_GROUP_LIST",
  VIEW_PRODUCT_LIST = "VIEW_PRODUCT_LIST",
  VIEW_SERVICE_ACCOUNT_LIST = "VIEW_SERVICE_ACCOUNT_LIST",
  VIEW_USER_API_TOKEN_LOGS = "VIEW_USER_API_TOKEN_LOGS",
  VIEW_USER_LIST = "VIEW_USER_LIST",
}

export enum PlatformRole {
  GRANDFATHERED_ADMIN = "GRANDFATHERED_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_GRANDFATHERED_SITE_ADMIN = "ORG_GRANDFATHERED_SITE_ADMIN",
  ORG_USER_ACCESS_ADMIN = "ORG_USER_ACCESS_ADMIN",
  SECURITY_ADMIN = "SECURITY_ADMIN",
  SITE_ADMIN = "SITE_ADMIN",
}

export enum PlatformType {
  ORG = "ORG",
  SITE = "SITE",
}

export enum PolicyType {
  BYOK = "BYOK",
  CUSTOM_DOMAINS = "CUSTOM_DOMAINS",
}

export enum PricingType {
  FREE = "FREE",
  PAID = "PAID",
}

export enum ProductBillingType {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum ProductCreationRequestStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  RESOLVED = "RESOLVED",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export enum ProductOfflineReason {
  atlas_retired = "atlas_retired",
  maintenance = "maintenance",
  migration = "migration",
  other = "other",
  sandbox_data_clone = "sandbox_data_clone",
  sandbox_delete = "sandbox_delete",
  sandbox_reset = "sandbox_reset",
  sandbox_soft_delete = "sandbox_soft_delete",
  support = "support",
  suspended_byok_revocation = "suspended_byok_revocation",
  suspended_for_deactivation = "suspended_for_deactivation",
  suspended_for_destruction = "suspended_for_destruction",
  suspended_payment = "suspended_payment",
  suspended_security = "suspended_security",
  user_cancellation = "user_cancellation",
}

export enum ProductPermission {
  CHANGE_PRODUCT_ROLES = "CHANGE_PRODUCT_ROLES",
  EXTERNAL_COLLAB_WRITE = "EXTERNAL_COLLAB_WRITE",
  MANAGE = "MANAGE",
  USER_ADMIN_PRODUCT = "USER_ADMIN_PRODUCT",
  WRITE = "WRITE",
  WRITE_PORTAL = "WRITE_PORTAL",
}

export enum ProductType {
  ADMIN = "ADMIN",
  EXTERNAL_COLLABORATOR = "EXTERNAL_COLLABORATOR",
  HELP_SEEKER = "HELP_SEEKER",
  USE = "USE",
}

export enum ProductUpdatesDelayType {
  DELAYABLE = "DELAYABLE",
  DELAYED = "DELAYED",
}

export enum ProductUpdatesStatus {
  COMING_SOON = "COMING_SOON",
  GENERALLY_AVAILABLE = "GENERALLY_AVAILABLE",
  NONE = "NONE",
  PLANNED = "PLANNED",
  ROLLED_BACK = "ROLLED_BACK",
  ROLLING_OUT = "ROLLING_OUT",
}

export enum ProductUpdatesType {
  ANNOUNCEMENT = "ANNOUNCEMENT",
  EXPERIMENT = "EXPERIMENT",
  FIX = "FIX",
  IMPROVEMENT = "IMPROVEMENT",
  REMOVED = "REMOVED",
}

export enum RealmTypes {
  au = "au",
  br = "br",
  ca = "ca",
  ch = "ch",
  de = "de",
  eu = "eu",
  gb = "gb",
  global = "global",
  in = "in",
  jp = "jp",
  kr = "kr",
  sg = "sg",
  us = "us",
}

export enum ReconProductType {
  Confluence = "Confluence",
  JIRA = "JIRA",
  JSM = "JSM",
}

export enum ReconcilerSubtype {
  INACTIVE_SANDBOX = "INACTIVE_SANDBOX",
}

export enum ReleaseTrackProduct {
  Avocado = "Avocado",
  Confluence = "Confluence",
  Jira = "Jira",
}

export enum ReleaseTrackSubscriptionStatus {
  Active = "Active",
  Pending = "Pending",
}

export enum ReleaseTrackSubscriptionType {
  ControlTrack = "ControlTrack",
  FastTrack = "FastTrack",
  PreviewTrack = "PreviewTrack",
  ScheduledTrack = "ScheduledTrack",
}

export enum ReleaseTrackTracks {
  fastTrack = "fastTrack",
  previewTrack = "previewTrack",
  scheduledTrack = "scheduledTrack",
}

export enum RemediationReportStatus {
  INVALID = "INVALID",
  VALID = "VALID",
}

export enum Residency {
  CANNOT_MOVE = "CANNOT_MOVE",
  CAN_MOVE = "CAN_MOVE",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  NO_ACTION_AVAILABLE = "NO_ACTION_AVAILABLE",
  OFF = "OFF",
  ON = "ON",
  READY_TO_COMMIT = "READY_TO_COMMIT",
  SCHEDULED = "SCHEDULED",
}

export enum ReviewDomainRule {
  BLOCKED = "BLOCKED",
  NOT_TRUSTED = "NOT_TRUSTED",
  NO_DECISION_MADE = "NO_DECISION_MADE",
  TRUSTED = "TRUSTED",
}

export enum SRSLinkStatus {
  Connected = "Connected",
  Disconnected = "Disconnected",
  Expired = "Expired",
  Initialized = "Initialized",
  Interrupted = "Interrupted",
  Regenerating = "Regenerating",
}

export enum STATUS {
  AVAILABLE = "AVAILABLE",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  LIMIT_REACHED = "LIMIT_REACHED",
  READY = "READY",
  SCHEDULED = "SCHEDULED",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum SamlExpiryStatus {
  EXPIRED = "EXPIRED",
  EXPIRINGSOON = "EXPIRINGSOON",
  VALID = "VALID",
}

export enum SandboxEventResult {
  failed = "failed",
  incomplete = "incomplete",
  successful = "successful",
  timeout = "timeout",
  unknown = "unknown",
}

export enum SandboxEventStatus {
  awaiting_replay = "awaiting_replay",
  cancelled = "cancelled",
  completed = "completed",
  started = "started",
}

export enum SandboxEventType {
  create = "create",
  data_clone = "data_clone",
  data_clone_selective = "data_clone_selective",
  delete = "delete",
  expedite_hard_delete = "expedite_hard_delete",
  hard_delete = "hard_delete",
  provision = "provision",
  reset = "reset",
  reshard = "reshard",
  rollback = "rollback",
  soft_delete = "soft_delete",
}

export enum SandboxTypes {
  CHILD = "CHILD",
  NONE = "NONE",
}

export enum ScheduledJobResultError {
  APPLICATION_LINK_NOT_FOUND = "APPLICATION_LINK_NOT_FOUND",
  APPLICATION_NOT_FOUND = "APPLICATION_NOT_FOUND",
  FORBIDDEN_ERROR = "FORBIDDEN_ERROR",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  NO_MIGRATIONS_FOUND = "NO_MIGRATIONS_FOUND",
  UNAUTHENTICATED_ERROR = "UNAUTHENTICATED_ERROR",
}

export enum ScheduledLinksUpdateJobResultStatus {
  BACKUP = "BACKUP",
  COMPLETE = "COMPLETE",
  COMPLETED_WITH_ERRORS = "COMPLETED_WITH_ERRORS",
  ERROR = "ERROR",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  ISSUES_FOUND = "ISSUES_FOUND",
  NEEDS_UPDATE = "NEEDS_UPDATE",
  NEVER_UPDATED = "NEVER_UPDATED",
  NOT_UPDATED = "NOT_UPDATED",
  PENDING = "PENDING",
  RETRY = "RETRY",
}

export enum ScimGroupChangeStatus {
  added = "added",
  removed = "removed",
  unchanged = "unchanged",
}

export enum SelectiveClaimOperationType {
  FIRST_CLAIM = "FIRST_CLAIM",
  INCREMENTAL_CLAIM = "INCREMENTAL_CLAIM",
  INCREMENTAL_UNCLAIM = "INCREMENTAL_UNCLAIM",
  TRANSITION_TO_SUC_BY_CLAIM = "TRANSITION_TO_SUC_BY_CLAIM",
  TRANSITION_TO_SUC_BY_UNCLAIM = "TRANSITION_TO_SUC_BY_UNCLAIM",
}

export enum ServiceType {
  ccp = "ccp",
  cofs = "cofs",
}

export enum ServiceTypeRequest {
  ccp = "ccp",
  hams = "hams",
}

export enum ShadowItOrgPolicyPermission {
  request = "request",
}

export enum SitePrivilege {
  NONE = "NONE",
  SITE_ADMIN = "SITE_ADMIN",
  SYS_ADMIN = "SYS_ADMIN",
}

export enum SiteReleaseStatus {
  ALL = "ALL",
  NONE = "NONE",
  SOME = "SOME",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum SourceBillingType {
  CCP = "CCP",
  HAMS = "HAMS",
}

export enum SsoType {
  google = "google",
  saml = "saml",
}

export enum StatusType {
  DEGRADED_PERFORMANCE = "DEGRADED_PERFORMANCE",
  MAJOR_OUTAGE = "MAJOR_OUTAGE",
  OPERATIONAL = "OPERATIONAL",
  PARTIAL_OUTAGE = "PARTIAL_OUTAGE",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum StepUpMethod {
  none = "none",
  stepUpEmailAll = "stepUpEmailAll",
  stepUpSso = "stepUpSso",
}

export enum SubscriptionIntent {
  SUBSCRIBE = "SUBSCRIBE",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export enum TableSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaskStatus {
  DISCARDED = "DISCARDED",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
}

export enum TokenStatus {
  ALLOWED = "ALLOWED",
  BLOCKED = "BLOCKED",
}

export enum Track {
  ControlTrack = "ControlTrack",
  FastTrack = "FastTrack",
  PreviewTrack = "PreviewTrack",
  ScheduledTrack = "ScheduledTrack",
}

export enum TunnelHealthCheckResult {
  ALREADY_STARTED = "ALREADY_STARTED",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  MISCONFIGURED = "MISCONFIGURED",
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum TunnelListSortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TunnelStatus {
  CONNECTED = "CONNECTED",
  PENDING = "PENDING",
  UNAVAILABLE = "UNAVAILABLE",
}

/**
 * UGC Policy
 */
export enum UGCWorkspaceEdition {
  enterprise = "enterprise",
  free = "free",
  premium = "premium",
  standard = "standard",
}

export enum UiBundleType {
  COLLECTION = "COLLECTION",
  ENTERPRISE = "ENTERPRISE",
  GUARD = "GUARD",
  TWOPAPP = "TWOPAPP",
  UNKNOWN = "UNKNOWN",
}

export enum UiProductType {
  ACCESS = "ACCESS",
  ANALYTICS = "ANALYTICS",
  ATLASSIAN_TOGETHER = "ATLASSIAN_TOGETHER",
  AVOCADO = "AVOCADO",
  BEACON = "BEACON",
  BITBUCKET = "BITBUCKET",
  CLOUD_ADMIN = "CLOUD_ADMIN",
  COMPASS = "COMPASS",
  CONFLUENCE = "CONFLUENCE",
  DEVAI = "DEVAI",
  FABRIC = "FABRIC",
  GOAL = "GOAL",
  JIRA_ADMIN = "JIRA_ADMIN",
  JIRA_ALIGN = "JIRA_ALIGN",
  JIRA_CORE = "JIRA_CORE",
  JIRA_CUSTOMER_SERVICE = "JIRA_CUSTOMER_SERVICE",
  JIRA_FAMILY = "JIRA_FAMILY",
  JIRA_INCIDENT_MANAGER = "JIRA_INCIDENT_MANAGER",
  JIRA_PRODUCT_DISCOVERY = "JIRA_PRODUCT_DISCOVERY",
  JIRA_SERVICE_DESK = "JIRA_SERVICE_DESK",
  JIRA_SERVICE_MANAGEMENT = "JIRA_SERVICE_MANAGEMENT",
  JIRA_SOFTWARE = "JIRA_SOFTWARE",
  JIRA_WORK_MANAGEMENT = "JIRA_WORK_MANAGEMENT",
  LOOM = "LOOM",
  LOOM_ATTRIBUTION = "LOOM_ATTRIBUTION",
  MERCURY = "MERCURY",
  OPSGENIE = "OPSGENIE",
  PASSIONFRUIT = "PASSIONFRUIT",
  PROJECT = "PROJECT",
  RADAR = "RADAR",
  ROVO = "ROVO",
  STATUSPAGE = "STATUSPAGE",
  STRIDE = "STRIDE",
  TEAMCENTRAL = "TEAMCENTRAL",
  TRELLO = "TRELLO",
  UNIFIED_HELP = "UNIFIED_HELP",
  UNKNOWN_ATLASSIAN = "UNKNOWN_ATLASSIAN",
}

export enum UninstallStatus {
  complete = "complete",
  failed = "failed",
  pending = "pending",
  running = "running",
}

export enum UnmanagedProductStatus {
  Active = "Active",
  Deactivated = "Deactivated",
  Suspended = "Suspended",
}

export enum UploadMethod {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum UpmTaskStatus {
  DONE = "DONE",
  PENDING = "PENDING",
}

export enum UsageStatus {
  USAGE_EXCEEDING_TIER_LIMIT = "USAGE_EXCEEDING_TIER_LIMIT",
  USAGE_NEARING_TIER_LIMIT = "USAGE_NEARING_TIER_LIMIT",
  USAGE_OK = "USAGE_OK",
}

export enum UserCountsDetailsExportType {
  COLLECTION = "COLLECTION",
  ENTERPRISE = "ENTERPRISE",
  GUARD = "GUARD",
  ROVO = "ROVO",
  UNKNOWN = "UNKNOWN",
}

export enum UserInstallationRuleValue {
  allow = "allow",
  deny = "deny",
}

export enum UserProductRole {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  CONTRIBUTOR = "CONTRIBUTOR",
  CUSTOMER = "CUSTOMER",
  EXTERNAL_COLLABORATOR = "EXTERNAL_COLLABORATOR",
  HELP_SEEKER = "HELP_SEEKER",
  MEMBER = "MEMBER",
  STAKEHOLDER = "STAKEHOLDER",
  USER_ADMIN = "USER_ADMIN",
  VIEWER = "VIEWER",
}

export enum UserRequestTypes {
  ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST",
  MARKETPLACE_APP_INTEREST = "MARKETPLACE_APP_INTEREST",
  MARKETPLACE_APP_REQUEST = "MARKETPLACE_APP_REQUEST",
  PRODUCT_ACCESS_REQUEST = "PRODUCT_ACCESS_REQUEST",
  PRODUCT_INTEREST = "PRODUCT_INTEREST",
}

export enum UserRequestsAccessRequestsType {
  ACCESS_REQUEST = "ACCESS_REQUEST",
  INVITE = "INVITE",
}

export enum UserStatus {
  active = "active",
  deactivated = "deactivated",
  not_invited = "not_invited",
  suspended = "suspended",
}

export enum VerificationType {
  azure = "azure",
  dns = "dns",
  google = "google",
  http = "http",
}

export enum WorkspaceBundle {
  SoftwareCollection = "SoftwareCollection",
  StrategyCollection = "StrategyCollection",
  TeamworkCollection = "TeamworkCollection",
  Unknown = "Unknown",
}

export enum WorkspacePlan {
  AtlassianTeams = "AtlassianTeams",
  Beta = "Beta",
  Bitbucket100UsersPremium = "Bitbucket100UsersPremium",
  Business = "Business",
  BusinessAI = "BusinessAI",
  Collection = "Collection",
  Enterprise = "Enterprise",
  Essentials = "Essentials",
  Free = "Free",
  None = "None",
  Paid = "Paid",
  Premium = "Premium",
  Standard = "Standard",
  Starter = "Starter",
  Unknown = "Unknown",
}

/**
 * This is the same "status" as the one in the unified Workspace model.
 * Read more: https: // hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/1354642378/RFC+Workspace+Model#Attributes
 */
export enum WorkspaceStatusAttribute {
  deprecated = "deprecated",
  offline = "offline",
  online = "online",
}

export interface AccessRequestListInput {
  status: AccessRequestStatus[];
  count?: number | null;
  lastEvaluatedKey?: LastEvaluatedKeyInput | null;
}

export interface AddGroupsAccessToProductInput {
  productId: string;
  groups: string[];
  productType: ProductType;
}

export interface AddUsersToGroupsInput {
  groups: (string | null)[];
  users: (string | null)[];
}

export interface AdditionalPayload {
  metadata?: MetadataInput | null;
}

export interface Address {
  lines: string[];
  locality: string;
  region: string;
  postcode: string;
  country: string;
}

export interface AdvancedRoadmapsInput {
  mode: string;
}

/**
 * # AHA Platform Insights GraphQL Schema
 */
export interface AhaInsightsReportFilterInput {
  name: string;
  type: string;
  selected: string[];
}

export interface ApiKeyCreateParams {
  label: string;
  expiresAt: string;
  resource: string;
  scopes: string[];
}

export interface ApiTokenCreateParams {
  label: string;
  expiry: string;
  scopes: string[];
  targetAudience?: string[] | null;
  products?: string[] | null;
}

export interface AppDetailsFilters {
  search?: string | null;
  residencies?: Residency[] | null;
  appsMoveWithProduct?: boolean | null;
}

export interface AppInstallationInput {
  appId: string;
  environmentKey: string;
  installationContext: string;
  versionId?: string | null;
  async?: boolean | null;
  provisionRequestId?: string | null;
}

export interface AppInstallationUpgradeInput {
  appId: string;
  environmentKey: string;
  installationContext: string;
  versionId?: string | null;
  async?: boolean | null;
  sourceBillingType?: SourceBillingType | null;
}

export interface AppInstallationsByContextFilter {
  appInstallations: InstallationsListFilterByAppInstallationsWithCompulsoryContexts;
  apps?: InstallationsListFilterByApps | null;
}

export interface AppLinkInput {
  id: string;
  rpcUrl: string;
}

export interface AppSubscribeInput {
  installationContext: string;
  appId: string;
  envKey: string;
}

export interface AppUninstallationInput {
  installationContext?: string | null;
  installationId?: string | null;
  appId: string;
  environmentKey: string;
  async?: boolean | null;
  licenseOverride?: LicenseOverrideState | null;
  sourceBillingType?: SourceBillingType | null;
}

export interface AppUnsubscribeInput {
  installationContext: string;
  appId: string;
  envKey: string;
}

export interface ApproveAccessInput {
  userId: string;
  productId: string;
  atlOrigin: string;
}

export interface AppsInput {
  includedKeys: string[];
}

export interface AssetsDataSelectionInput {
  assetsSelection: Assets;
}

export interface AuditLogALQLEventsInput {
  cursor?: string | null;
  alql?: string | null;
  limit?: string | null;
}

export interface AuditLogEventsInput {
  cursor?: string | null;
  from?: string | null;
  to?: string | null;
  q?: string | null;
  action?: string | null;
  actor?: string | null;
  ip?: string | null;
  location?: string | null;
  product?: string | null;
  limit?: string | null;
  correlationId?: string | null;
}

export interface AuditLogSettingsOrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: AuditLogSettingsOrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
}

export interface AuditLogSettingsOrgPolicyRulesInput {
  ugcUalEnabled?: string[] | null;
  ugcUalDisabled?: string[] | null;
}

export interface AuditLogWebhookInput {
  webhookUrl?: string | null;
  headers?: WebhookHeadersInput[] | null;
}

export interface AuthApiTokenInput {
  disableAllApiToken?: ApiTokenAccessMode | null;
  apiTokenExpiryDays: number;
}

export interface AuthPasswordPolicyInput {
  minimumStrength: string;
  expiryDays?: number | null;
  socialLoginBan?: AuthPolicySocialLoginStatus[] | null;
}

export interface AuthPolicyInput {
  sso?: AuthSsoConfigurationInput | null;
  twoStepVerification?: AuthTwoStepVerificationInput | null;
  passwordPolicy?: AuthPasswordPolicyInput | null;
  sessionPolicy?: SessionPolicyInput | null;
  apiToken?: AuthApiTokenInput | null;
}

export interface AuthSsoConfigurationInput {
  type: AuthSsoConfigurationType;
  samlConfigurationId?: string | null;
}

export interface AuthTwoStepVerificationInput {
  dateEnforced: number;
}

export interface BaaInput {
  legalEmail: string;
  legalName: string;
  signingAuthorityEmail: string;
  signingAuthorityName: string;
  signingAuthorityTitle?: string | null;
  address: Address;
}

/**
 * # TODO: Refactor existing schemas to be type strict by implementing from these policies
 * # TODO: Refactor schema to be type strict by coupling from BaseOrgPolicyInput via baseInput, consideration remapping client side see - baseInput for ShadowItOrgPolicyInput
 * # NOTE: In order to fully utilize interfaces, we should only return these base policy types, but this comes with its own problems (https: // hello.atlassian.net/wiki/spaces/~975539076/pages/2442483434/org+policies+interfaces)
 * Interfaces
 */
export interface BaseOrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  resources: OrgPolicyResourcesInput[];
}

export interface BlocklistAllowlistItemInput {
  name: string;
  value: string;
}

export interface BrieCreateBackupPolicyInput {
  cloudId: string;
  product: BrieProducts;
  storageId?: string | null;
  includeAttachments: boolean;
  includePersonalSpaces?: boolean | null;
}

export interface BrieCreateBackupPolicyV2Input {
  policies: string[];
}

export interface BrieCreateStorageInput {
  storageName: string;
  storageRegion: string;
  storagePrefix?: string | null;
  roleIdentifier: string;
  principalTag: string;
}

export interface BrieFetchDownloadInput {
  cloudId: string;
  downloadId: string;
}

export interface BriePrepareDownloadInput {
  cloudId: string;
  backupId: string;
  product: BrieProducts;
}

export interface BrieSetBackupScheduleInput {
  interval: BrieScheduleInterval;
  startDate: any;
}

export interface BrieUpdateBackupPolicyInput {
  storageId?: string | null;
  includeAttachments?: boolean | null;
  includePersonalSpaces?: boolean | null;
}

export interface BrieUploadFileInput {
  cloudId: string;
  uploadId: string;
  partNumber: number;
  isLastPart: boolean;
  startByte: number;
  endByte: number;
  chunkString: any;
}

export interface BrieUploadInitiateInput {
  cloudId: string;
  downloadId: string;
  backupId: string;
  product: BrieProducts;
  fileSize: number;
}

export interface BrieV2CreatePolicyInput {
  policyName: string;
  cloudId: string;
  product: BrieProducts;
  includeAttachments?: boolean | null;
  includePersonalSpaces?: boolean | null;
  includeUsersAndGroups?: boolean | null;
  storageId?: string | null;
  scheduleDetails?: BrieV2ScheduleDetailsInput | null;
}

export interface BrieV2EditPolicyInput {
  includeAttachments?: boolean | null;
  includePersonalSpaces?: boolean | null;
  includeUsersAndGroups?: boolean | null;
  storageId?: string | null;
  scheduleDetails?: BrieV2ScheduleDetailsInput | null;
  policyName?: string | null;
  cloudId?: string | null;
  product?: BrieProducts | null;
}

export interface BrieV2ScheduleDetailsInput {
  interval: BrieScheduleInterval;
  startDate: any;
  scheduleSlot: string;
  requiredSlotUnit: number;
  rdsId: string;
}

export interface BxpActivationAuthorizationInput {
  productKeys: string[];
  productEdition: string;
  cloudId?: string | null;
  siteName?: string | null;
}

export interface BxpCreateSiteInput {
  email: string;
  displayName: string;
  ccpProducts: CcpProductInput[];
  siteName?: string | null;
  timezone: string;
  consent: ConsentsInput;
  isNewUser: boolean;
  orgId?: string | null;
  platform?: ServiceTypeRequest | null;
  gRecaptchaResponse?: string | null;
  siteId?: string | null;
}

export interface ByokPolicyContextIdentifierInput {
  tag?: string | null;
}

export interface CcpProductInput {
  offeringId: string;
  productKey: string;
  pricingType?: PricingType | null;
  edition?: EditionType | null;
  chargeElement: ChargeType;
  addOnParentEntitlementId?: string | null;
}

export interface ChangelogReleaseDatesInput {
  product?: UiProductType[] | null;
  cursor?: string | null;
  limit?: number | null;
  query?: string | null;
  orderBy?: TableSortOrder | null;
}

export interface ChangelogsInput {
  product?: UiProductType[] | null;
  status?: ProductUpdatesStatus[] | null;
  type?: ProductUpdatesType[] | null;
  delayType?: ProductUpdatesDelayType[] | null;
  search?: string | null;
  cursor?: string | null;
  limit?: number | null;
  postDate?: (string | null)[] | null;
}

export interface CloneFullSandboxDataInput {
  sandboxSiteId: string;
  productFamily: MigrationProductFamily;
  isMediaIncluded: boolean;
}

export interface CloneSandboxDataSource {
  id: string;
  key: string;
  includeAttachments: boolean;
  projectStyle?: string | null;
}

export interface CloneSelectiveSandboxDataInput {
  parentSiteId: string;
  sandboxSiteId: string;
  productKey: string;
  sourcesSelected: CloneSandboxDataSource[];
}

export interface CloudDetail {
  cloudId: string;
  url: string;
}

export interface ConflictConsents {
  PREFLIGHT_CONFLICTING_SPACE_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_CONFLICTING_PROJECT_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_DUPLICATE_GROUP_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_SITE_LICENSE_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_TDP_SHARD_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_CONFLICTING_CUSTOMER_ORGANIZATION_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_PRODUCT_LICENSE_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_CUSTOM_GROUP_CHECK_CONSENT?: boolean | null;
}

export interface ConfluenceResourceInput {
  operation: string;
  resourceId: string;
}

export interface ConfluenceScopeInput {
  spaces?: SpacesInput | null;
  usersAndGroups?: UsersAndGroupsInput | null;
  apps?: AppsInput | null;
  globalEntities?: GlobalEntitiesInput | null;
}

export interface ConfluenceSpaceForMigration {
  spaceId: number;
  numberOfPages: number;
  attachmentSize: number;
}

export interface Consent {
  key: Key;
  displayedText: string;
  granted: boolean;
}

export interface Consents {
  PREFLIGHT_SPACE_PUBLIC_ACCESS_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_DUPLICATE_GROUP_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_CONFLICTING_CUSTOMER_ORGANIZATION_CHECK_CONSENT?: boolean | null;
  PREFLIGHT_PROJECT_ANONYMOUS_ACCESS_CHECK_CONSENT?: boolean | null;
}

export interface ConsentsInput {
  site: string;
  locale: string;
  formUrl: string;
  consents: (Consent | null)[];
}

export interface ContainerIds {
  containerIds: CreateMigrationContainer[];
}

export interface ContentMetadata {
  projectStyle?: string | null;
}

export interface ContentRestrictedNotificationPolicyOrgPolicyInput {
  status: OrgPolicyStatus;
  rule: ContentRestrictedNotificationPolicyOrgPolicyRulesInput;
}

export interface ContentRestrictedNotificationPolicyOrgPolicyRulesInput {
  blockEmail?: boolean | null;
}

export interface ContextInput {
  INCLUDE_PERSONAL_SPACES?: string | null;
}

export interface CoverageAssociationInput {
  resources: ConfluenceResourceInput[];
}

export interface CreateMigrationContainer {
  sourceId: string;
  key: string;
  productType?: UiProductType | null;
  metadata?: ContentMetadata | null;
  includeAttachments: boolean;
}

export interface CreateMigrationInput {
  migrationName: string;
  source: CreateMigrationSite;
  destination: CreateMigrationSite;
  containers: ContainerIds;
  userGroupSelection: UserGroupSelectionInput;
  crossProjectSelection?: CrossProjectSelectionInput | null;
  advancedRoadmapPlans?: AdvancedRoadmapPlans | null;
  jiraDashboardSelection?: JiraDashboardSelection | null;
  assetsDataSelection?: AssetsDataSelectionInput | null;
}

export interface CreateMigrationSite {
  cloudId: string;
  url: string;
}

export interface CreateSandboxInput {
  parentSiteId: string;
  productKeys: string[];
  offeringKeys: string[];
  targetCloudId?: string | null;
  customHostname?: string | null;
}

export interface CreateSiteInput {
  siteName: string;
  product: string;
  formUrl: string;
  privacyDisplayText: string;
  tosDisplayText: string;
  edition?: string | null;
  byokPolicy?: string | null;
}

export interface CreateWorkspaceInputAttributes {
  type: string;
  productKey: string;
  slug: string;
  name: string;
}

export interface CrossProjectSelectionInput {
  crossProjectBoardsAndFilters: CrossProjectBoardsAndFilters;
}

export interface CursorPaginatedFilterInput {
  cursor?: string | null;
  limit?: number | null;
  productKeys?: string[] | null;
  plans?: WorkspacePlan[] | null;
  bundleIds?: string[] | null;
  sortMulti?: SortItem[] | null;
  sortOrder?: PaginationSortOrder | null;
  sortKey?: string | null;
  textFilter?: string | null;
  statusKeys?: string[] | null;
  appliesTo?: string[] | null;
  query?: string | null;
  sourceKeys?: string[] | null;
  destinationKeys?: string[] | null;
  dateRangeFrom?: string | null;
  dateRangeTo?: string | null;
}

export interface DashboardsInput {
  mode: string;
}

export interface DataClassificationLevelInput {
  name: string;
  definition?: string | null;
  guideline?: string | null;
  guidelineRepresentation: GuidelineRepresentation;
  color: DataClassificationLevelColor;
}

export interface DataClassificationLevelOrderInput {
  levelId: string;
  sensitivity: number;
}

export interface DataClassificationSettingsInput {
  defaultClassification: string;
  reclassificationControl?: DataClassificationOverrideMode | null;
  workspaceSetting?: ReclassificationSettingsInput | null;
  containerSetting?: ReclassificationSettingsInput | null;
  objectSetting?: ReclassificationSettingsInput | null;
}

export interface DataClassificationTemplateLevelInput {
  name: string;
  definition?: string | null;
  guideline?: string | null;
  guidelineRepresentation: GuidelineRepresentation;
  color?: DataClassificationLevelColor | null;
  sensitivity: number;
}

export interface DataClassificationUpdateLevelInput {
  id: string;
  name: string;
  definition?: string | null;
  guideline?: string | null;
  guidelineRepresentation: GuidelineRepresentation;
  color: DataClassificationLevelColor;
}

export interface DataResidencyAppDetailsInput {
  product: ProductRequest;
  limit?: number | null;
  cursor?: string | null;
  filters?: AppDetailsFilters | null;
}

export interface DataResidencyAppsInput {
  products: DataResidencyAppsSiteProductsInput[];
}

export interface DataResidencyAppsSiteProductsInput {
  cloudId: string;
  product: DataResidencyProduct;
  location: RealmTypes;
}

export interface DataSecurityOrgPolicyAiAccessInput {
  blockAll?: boolean | null;
}

export interface DataSecurityOrgPolicyAnonymousAccessInput {
  block?: boolean | null;
}

export interface DataSecurityOrgPolicyAppAccessInput {
  blockAll?: boolean | null;
  blockedApps?: (string | null)[] | null;
  allowedApps?: (string | null)[] | null;
}

export interface DataSecurityOrgPolicyExportInput {
  blockPageExport?: boolean | null;
}

export interface DataSecurityOrgPolicyPublicLinksInput {
  block?: boolean | null;
}

export interface DataSecurityPolicyOrgPolicyAiAccessRuleInput {
  blockAll?: boolean | null;
}

export interface DataSecurityPolicyOrgPolicyAnonymousAccessRuleInput {
  block?: boolean | null;
}

export interface DataSecurityPolicyOrgPolicyAppAccessRuleInput {
  blockAll?: boolean | null;
  blockedApps?: (string | null)[] | null;
  allowedApps?: (string | null)[] | null;
}

export interface DataSecurityPolicyOrgPolicyExportRuleInput {
  blockPageExport?: boolean | null;
}

export interface DataSecurityPolicyOrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule?: DataSecurityPolicyOrgPolicyRulesInput | null;
  resources: OrgPolicyResourcesInput[];
  metadata?: DataSecurityPolicyOrgPolicyMetadataInput | null;
}

export interface DataSecurityPolicyOrgPolicyMetadataInput {
  coverage?: any | null;
  description?: string | null;
  createdBy?: string | null;
  lastUpdatedBy?: string | null;
  policyCoverageLevel?: DSPPolicyCoverageLevel | null;
  systemTag?: string | null;
}

export interface DataSecurityPolicyOrgPolicyPublicLinksRuleInput {
  block?: boolean | null;
}

export interface DataSecurityPolicyOrgPolicyRulesInput {
  export?: DataSecurityPolicyOrgPolicyExportRuleInput | null;
  appAccess?: DataSecurityPolicyOrgPolicyAppAccessRuleInput | null;
  publicLinks?: DataSecurityPolicyOrgPolicyPublicLinksRuleInput | null;
  anonymousAccess?: DataSecurityPolicyOrgPolicyAnonymousAccessRuleInput | null;
  aiAccess?: DataSecurityPolicyOrgPolicyAiAccessRuleInput | null;
}

export interface DateSearchInput {
  earliestStart?: string | null;
  latestStart?: string | null;
}

export interface DeactivateSandboxInput {
  siteId: string;
  productKeys: string[];
}

export interface DefaultGroupsInput {
  productId: string;
  groupId: string;
  isDefault: boolean;
  productType: ProductType;
}

export interface DefaultProductsInput {
  productId: string;
  productDefault: boolean;
}

export interface DeleteSandboxInput {
  siteId: string;
  productKeys: string[];
}

export interface DenyAccessInput {
  userId: string;
  productId: string;
  denialReason?: string | null;
}

export interface DestinationInput {
  url: string;
}

export interface DomainAccessPromotionEligibilityInput {
  productARI: string;
  domain: string;
  setting?: string | null;
}

export interface DomainPaginatedInput {
  getLinkedDirectories?: boolean | null;
  limit?: number | null;
  cursor?: string | null;
  search?: string | null;
  includeAccountQuantities?: boolean | null;
}

export interface DownloadAuditLogRequest {
  orgId: string;
  productUrl: string;
  productFamily: MigrationProductFamily;
  auditLogType: AuditLogType;
}

export interface EcosystemAppsInstalledInContextsFilter {
  type: EcosystemAppsInstalledInContextsFilterType;
  values: string[];
}

export interface EcosystemAppsInstalledInContextsOptions {
  groupByBaseApp?: boolean | null;
  shouldIncludePrivateApps?: boolean | null;
  shouldExcludeFirstPartyApps?: boolean | null;
}

export interface EcosystemAppsInstalledInContextsOrderBy {
  sortKey?: EcosystemAppsInstalledInContextsOrderKey | null;
  direction?: EcosystemAppsInstalledInContextsOrder | null;
}

export interface EcosystemGlobalInstallationConfigInput {
  cloudId: string;
  config: EcosystemGlobalInstallationOverrideInput[];
}

export interface EcosystemGlobalInstallationOverrideInput {
  key: EcosystemGlobalInstallationOverrideKeys;
  value: boolean;
}

export interface EcosystemInstallationConfigInput {
  overrides: EcosystemInstallationOverrides[];
}

export interface EcosystemInstallationOverrides {
  key: EcosystemInstallationOverrideKeys;
  value: boolean;
}

export interface EcosystemUpdateInstallationDetailsInput {
  id: string;
  config: EcosystemInstallationConfigInput;
}

export interface EditGroupProductAccessArgs {
  groupIds: string[];
  roleAssociations: ProductAccessInput[];
}

export interface EmojiSettingsInput {
  uploadEnabled: boolean;
}

export interface EntityCountInput {
  dataSourceId: string;
  connectorType: string;
}

export interface ExpediteSandboxHardDeleteInput {
  siteId: string;
  productKeys: string[];
}

export interface ExperimentsSettingsInput {
  optOut: boolean;
}

export interface ExportFilterInput {
  projects?: ReconProjectsInput[] | null;
  entities?: string[] | null;
}

export interface ExternalUserPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: ExternalUserSecurityOrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
  metadata?: ExternalUserSecurityMetadataInput | null;
}

export interface ExternalUserSecurityMetadataInput {
  directoryId?: string | null;
  billingType?: ExternalUserSecurityBillingType | null;
}

export interface ExternalUserSecurityOrgPolicyRulesInput {
  apiTokenAccessMode?: ApiTokenAccessMode | null;
  emailAndNotificationsAccessMode?: EmailAndNotificationsAccessMode | null;
  stepUpSessionDurationMinutes?: number | null;
  type: StepUpMethod;
  users?: ExternalUserUpdatePolicyInput[] | null;
  scope?: string | null;
  ssoType?: SsoType | null;
  samlConfigurationId?: string | null;
}

export interface ExternalUserUpdatePolicyInput {
  accountId: string;
}

export interface GenerateUserExportInput {
  includeGroups: boolean;
  includeProductAccess: boolean;
  includeInactiveUsers: boolean;
  selectedGroupIds: string[];
}

export interface GenerateUserExportPerGroupInput {
  includeProductAccess: boolean;
  includeInactiveUsers: boolean;
  selectedGroupIds: string[];
}

export interface GenerativeAIOrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: OrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
  metadata?: GenerativeAIOrgPolicyMetadataInput | null;
}

export interface GenerativeAIOrgPolicyMetadataInput {
  consentDisplayText?: string | null;
}

/**
 * Generative AI Disable For Org
 */
export interface GenerativeAiOrgLevelPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: OrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
}

export interface GetAppRequestDetailsInput {
  cloudId: string;
  appKey: string;
  product: string;
}

export interface GetTaskStatusInput {
  cloudId: string;
  taskId: string;
  product: AtlassianConnectSupportedProduct;
}

export interface GetUnifiedDirectoryGroupInput {
  userIds?: string[] | null;
  cursor?: string | null;
  limit?: number | null;
  types?: GroupTypes[] | null;
  directoryIds?: string[] | null;
  groupIds?: string[] | null;
  resourceOwners?: string[] | null;
  resourceIds?: string[] | null;
  searchTerm?: string | null;
  sortBy?: UnifiedDirectoryUsersSortBy[] | null;
}

export interface GlobalEntitiesInput {
  includedTypes: string[];
}

export interface GlobalInstallationConfigFilter {
  keys: EcosystemGlobalInstallationOverrideKeys[];
}

export interface GroupImportInput {
  productId: string;
  groupId: string;
}

export interface GroupToOverride {
  scimGroupId: string;
  siteIds: string[];
}

export interface InsightsProducts {
  siteId: string;
  type: UiProductType;
}

export interface InstallConnectAppInput {
  cloudId: string;
  descriptorUrl: string;
  product: AtlassianConnectSupportedProduct;
}

export interface InstallationsListFilterByAppInstallationsWithCompulsoryContexts {
  contexts: string[];
  ids?: string[] | null;
}

export interface InstallationsListFilterByApps {
  ids?: string[] | null;
}

export interface JiraScopeInput {
  projects?: ProjectsInput | null;
  usersAndGroups?: UsersAndGroupsInput | null;
  jsm?: JsmInput | null;
  ar?: AdvancedRoadmapsInput | null;
  dashboards?: DashboardsInput | null;
  apps?: AppsInput | null;
}

export interface JiraWorkspacesInput {
  id: string;
  type: UiProductType;
  url?: string | null;
}

export interface JsmCustomerInput {
  mode: string;
}

export interface JsmInput {
  customers: JsmCustomerInput;
}

export interface KnowledgeDiscoveryCreateAdminhubBookmarkInput {
  cloudId: string;
  keyPhrases?: string[] | null;
  url: string;
  title: string;
  description?: string | null;
  orgId: string;
}

export interface KnowledgeDiscoveryDeleteBookmarkInput {
  bookmarkAdminhubId: string;
  url: string;
  keyPhrases?: string[] | null;
}

export interface LandingProductsInput {
  workspaceId: string;
  landingHostProduct: LandingHostProduct;
}

export interface LastEvaluatedKeyInput {
  partitionKey: string;
  indexKey: string;
  rangeKey: string;
}

export interface LinkedProductInput {
  productId: string;
  productType: string;
  productName: string;
  productUrl: string;
  appLinkId: string;
  appLinkRpcUrl: string;
}

export interface LogQueryInput {
  installationContexts?: string[] | null;
  functionKey?: string | null;
  appVersion?: string | null;
  dates?: DateSearchInput | null;
}

export interface LoginCustomisationCreatePolicyInput {
  data: LoginCustomisationPolicyDataInput;
}

export interface LoginCustomisationPolicyAttributesInput {
  name: string;
  status: string;
  type: string;
  rule: LoginCustomisationPolicyRuleInput;
  resources: LoginCustomisationPolicyResourcesInput[];
}

export interface LoginCustomisationPolicyDataInput {
  type: string;
  attributes: LoginCustomisationPolicyAttributesInput;
}

export interface LoginCustomisationPolicyPreLoginInput {
  social_login: LoginCustomisationPolicySocialLoginInput;
}

export interface LoginCustomisationPolicyResourcesInput {
  id: string;
}

export interface LoginCustomisationPolicyRuleInput {
  pre_login: LoginCustomisationPolicyPreLoginInput;
}

export interface LoginCustomisationPolicySocialLoginInput {
  visible: boolean;
}

export interface LoginCustomisationUpdatePolicyInput {
  data: LoginCustomisationPolicyDataInput;
}

export interface MapiConfluencePayloadInput {
  flow: string;
  source: SourceInput;
  destination: DestinationInput;
  scope: ConfluenceScopeInput;
  name?: string | null;
}

export interface MapiJiraPayloadInput {
  flow: string;
  source: SourceInput;
  destination: DestinationInput;
  scope: JiraScopeInput;
  name?: string | null;
}

export interface MarketplaceAppVersionFilter {
  cloudAppVersionId?: string | null;
  visibility?: MarketplaceAppVersionVisibility | null;
  productHostingOptions?: AtlassianProductHostingType[] | null;
}

export interface MetadataInput {
  updateMigrationProperties?: UpdateProperties | null;
}

export interface MigrationPropertyInput {
  add: ReconciliationProperty;
}

export interface MigrationPropertyPayload {
  properties?: MigrationPropertyInput | null;
}

export interface MobileSecurityBooleanInput {
  value: boolean;
  platforms: MobilePlatform[];
}

export interface MobileSecurityInput {
  isScreenshotRestricted: MobileSecurityBooleanInput;
  clipboardRestrictions: MobileSecurityNumericInput;
  isDataExportRestricted: MobileSecurityBooleanInput;
  isLocalAuthRequired: MobileSecurityBooleanInput;
  isCompromisedDeviceRestricted: MobileSecurityBooleanInput;
  localAuthTimeout?: MobileSecurityNumericInput | null;
  isThirdPartyKeyboardRestricted: MobileSecurityBooleanInput;
  isDeviceEncryptionRequired: MobileSecurityBooleanInput;
  isAppTrustEnabled: MobileSecurityBooleanInput;
  useIntuneMAM?: MobileSecurityBooleanInput | null;
  iOSPlatformVersion?: MobileSecurityPlatformVersionInput | null;
  androidPlatformVersion?: MobileSecurityPlatformVersionInput | null;
  macOSPlatformVersion?: MobileSecurityPlatformVersionInput | null;
}

export interface MobileSecurityNumericInput {
  value: number;
  platforms: MobilePlatform[];
}

export interface MobileSecurityOrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: MobileSecurityOrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
}

export interface MobileSecurityOrgPolicyRulesInput {
  mobileSecurity: MobileSecurityInput;
}

export interface MobileSecurityPlatformVersionInput {
  min?: string | null;
  max?: string | null;
}

export interface MobileSessionInput {
  timeoutMinutes?: number | null;
}

export interface MpsARInput {
  mode?: MpsARModeOption | null;
}

export interface MpsAppsInput {
  includedKeys?: (string | null)[] | null;
}

export interface MpsBoardsAndFiltersInput {
  mode?: MpsBoardsAndFiltersModeOption | null;
}

export interface MpsDashboardInput {
  mode?: MpsDashboardModeOption | null;
}

export interface MpsDataScopeEntityInput {
  type: MpsEntityType;
  key: string;
  name: string;
  entityId: string;
  projectType?: MpsProjectType | null;
  initialPageCount?: number | null;
  initialIssueCount?: number | null;
}

export interface MpsDataScopeInput {
  expand: MpsDataScopeExpand[];
}

export interface MpsDataScopePayloadInput {
  name?: string | null;
  phase?: MpsPhase | null;
  source?: MpsSourceInput | null;
  destination?: MpsDestinationInput | null;
  options?: MpsOptionsInput | null;
  entities?: (MpsDataScopeEntityInput | null)[] | null;
  entityConflictReconciliationId?: string | null;
}

export interface MpsDestinationInput {
  id?: string | null;
  product?: MigrationProductFamily | null;
  productVersion?: string | null;
  baseUrl?: string | null;
}

export interface MpsGlobalEntitiesInput {
  includedTypes?: (MpsGlobalEntitiesType | null)[] | null;
}

export interface MpsJSMCustomersTypeInput {
  mode?: MpsJSMCustomersModeOption | null;
}

export interface MpsJSMInput {
  customers?: MpsJSMCustomersTypeInput | null;
}

export interface MpsOptionsInput {
  projects?: MpsProjectsInput | null;
  spaces?: MpsSpacesInput | null;
  usersAndGroups?: MpsUsersAndGroupsInput | null;
  jsm?: MpsJSMInput | null;
  ar?: MpsARInput | null;
  dashboards?: MpsDashboardInput | null;
  boardsAndFilters?: MpsBoardsAndFiltersInput | null;
  globalEntities?: MpsGlobalEntitiesInput | null;
  apps?: MpsAppsInput | null;
}

export interface MpsProjectsInput {
  includedData?: MpsProjectIncludedDataOption | null;
  includeArchivedIssues?: boolean | null;
}

export interface MpsSourceInput {
  id?: string | null;
  type?: MpsSourceType | null;
  sen?: string | null;
  product?: MigrationProductFamily | null;
  productVersion?: string | null;
  baseUrl?: string | null;
}

export interface MpsSpacesInput {
  includedData?: MpsSpaceIncludedDataOption | null;
}

export interface MpsUsersAndGroupsInput {
  mode?: MpsUserAndGroupModeOption | null;
  preserveMemberships?: boolean | null;
  includeUsersInGroups?: boolean | null;
  includeRoleActors?: boolean | null;
}

export interface OSyncConfigInput {
  groups: string[];
  domains: string[];
  enableInviteEmail: boolean;
  emailInviteTemplate?: string | null;
  enableSync: boolean;
  uniqueUserIdentifier: OSyncUserIdentifier;
}

export interface OrgGSuiteConfigInput {
  groups: (string | null)[];
  sendEmailInvite: boolean;
  emailInviteTemplate?: string | null;
  enforceGoogleSso: boolean;
  enableSync: boolean;
}

export interface OrgGroupsInput {
  start: number;
  count: number;
  searchText?: string | null;
  product?: string[] | null;
  includeReadOnlyGroups?: boolean | null;
  groupSearchView?: GroupSearchView | null;
}

export interface OrgPolicyDnsRecordInput {
  id: string;
  domain: string;
  value: string;
  status: string;
  statusExtended: string;
}

export interface OrgPolicyDspMetadataInput {
  status: string;
  isSubdomain: boolean;
  mx?: OrgPolicyDnsRecordInput[] | null;
  spf: OrgPolicyDnsRecordInput;
  cname: OrgPolicyDnsRecordInput[];
  bounceCname: OrgPolicyDnsRecordInput;
  txt: OrgPolicyDnsRecordInput;
  lastCheckedTimeStamp: string;
  description?: string | null;
  createdBy?: string | null;
  lastUpdatedBy?: string | null;
  systemTag?: string | null;
}

export interface OrgPolicyInput {
  type: string;
  name: string;
  status: OrgPolicyStatus;
  rule: OrgPolicyRulesInput;
  resources: OrgPolicyResourcesInput[];
  metadata?: OrgPolicyDspMetadataInput | null;
  acknowledged?: boolean | null;
}

export interface OrgPolicyResourcesInput {
  id: string;
  applicationStatus?: OrgPolicyApplicationStatus | null;
}

export interface OrgPolicyResourcesMetadataInput {
  metaAllow?: boolean | null;
  aggAllow?: boolean | null;
  rawAllow?: boolean | null;
}

export interface OrgPolicyRulesInput {
  in?: string[] | null;
  allowMobileBypass?: boolean | null;
  mobileSecurity?: MobileSecurityInput | null;
  ugcUalEnabled?: string[] | null;
  ugcUalDisabled?: string[] | null;
  domain?: string[] | null;
  email?: string[] | null;
  parentPolicyId?: string | null;
  awsAccount?: string | null;
  realm?: RealmTypes | null;
  keyManagementModel?: ByokPolicyKeyManagementModel | null;
  rootOfTrust?: ByokPolicyRootOfTrust | null;
  contextIdentifier?: ByokPolicyContextIdentifierInput | null;
  type?: string | null;
  users?: ExternalUserUpdatePolicyInput[] | null;
  scope?: string | null;
  export?: DataSecurityOrgPolicyExportInput | null;
  appAccess?: DataSecurityOrgPolicyAppAccessInput | null;
  publicLinks?: DataSecurityOrgPolicyPublicLinksInput | null;
  anonymousAccess?: DataSecurityOrgPolicyAnonymousAccessInput | null;
  aiAccess?: DataSecurityOrgPolicyAiAccessInput | null;
  mode?: string | null;
  apiTokenAccessMode?: ApiTokenAccessMode | null;
  isAIEnabledByDefaultForNewEnterpriseProducts?: boolean | null;
  consented?: boolean | null;
  invalidateStepUpsBefore?: string | null;
  ssoType?: SsoType | null;
  samlConfigurationId?: string | null;
}

export interface OrgProductWithAppLinksInput {
  id: string;
  type: string;
  name: string;
  url: string;
  appLinks?: AppLinkInput[] | null;
}

export interface OrgUnmanagedProductsInput {
  query?: string | null;
  products?: string[] | null;
  sortBy?: string | null;
  sortOrder?: SortOrder | null;
  cursor?: string | null;
}

export interface OrgUsersInput {
  start: number;
  count: number;
  status?: OrgUsersStatus[] | null;
  product?: string[] | null;
  role?: OrgUsersRole[] | null;
  customerProducts?: UiProductType[] | null;
  searchText?: string | null;
  includeTotal?: boolean | null;
  appType?: string | null;
}

export interface PagePaginatedFilterInput {
  start?: number | null;
  count?: number | null;
  productKeys?: string[] | null;
  sortOrder?: PaginationSortOrder | null;
  sortKey?: string | null;
  textFilter?: string | null;
}

export interface PayloadDestination {
  cloudId?: string | null;
  url?: string | null;
}

export interface PayloadSource {
  cloudId?: string | null;
  url?: string | null;
}

export interface ProductAccessInput {
  role: UserProductRole;
  productId: UiProductType;
  siteId: string;
}

export interface ProductCreateRequestInput {
  status: ProductCreationRequestStatus;
  title?: string | null;
  description?: string | null;
  metadata: ProductCreateRequestInputMetaData;
}

export interface ProductCreateRequestInputMetaData {
  message: string;
  products: ProductCreateRequestInputMetaDataProduct[];
}

export interface ProductCreateRequestInputMetaDataProduct {
  key: string;
  plan: string;
  cloudUrl?: string | null;
  containerAri?: string | null;
}

export interface ProductRequest {
  product: DataResidencyProduct;
  cloudId: string;
  location: RealmTypes;
}

export interface ProjectsInput {
  includedKeys: string[];
  includedData: string;
}

export interface Properties {
  add?: Consents | null;
  remove?: Consents | null;
}

export interface PublicSiteEmojiInput {
  publicCustomEmojiEnabled: boolean;
}

export interface ReclassificationSettingsInput {
  preventUpClassifying: boolean;
  preventDownClassifying: boolean;
}

export interface ReconProjectsInput {
  projectId: string;
  projectStyle: string;
}

export interface ReconciliationProperty {
  entityReconciliationId: string;
  entityReconciliationInputFileKey?: string | null;
}

export interface RemoveGroupAccessToProductInput {
  productId: string;
  groupId: string;
  productType: ProductType;
}

export interface ResourceOperationInput {
  operation: OperationType;
  resourceAri: string;
}

export interface RestoreSandboxInput {
  siteId: string;
  productKeys: string[];
}

export interface ReviewDomainsInput {
  cloudId?: string | null;
  serverId?: string | null;
  product?: string | null;
  limit?: number | null;
  cursor?: string | null;
}

export interface RevokeApiTokensInput {
  userId: string;
  tokenId: string;
}

export interface RoleAssignmentsQueryInput {
  entityId: string;
  cursor?: string | null;
  limit?: number | null;
  directoryIds?: string[] | null;
  resourceOwners?: string[] | null;
  resourceIds?: string[] | null;
}

export interface RovoSearchConnectorPropertyInput {
  key: string;
  value: any;
}

export interface RovoSiteProvisionInput {
  cloudId: string;
  orgId: string;
  offerings: RovoSiteProvisionInputOfferings[];
}

export interface RovoSiteProvisionInputOfferings {
  parentProductKey: string;
  parentOfferingId: string;
  offeringId: string;
  chargeElement: string;
}

export interface RunJobPermissionCheckRequest {
  orgId: string;
  product: MigrationProductFamily;
  siteId: string;
}

export interface SandboxCreateMigrationInput {
  dataCloneType: DataCloneType;
  parent: CloudDetail;
  sandbox: CloudDetail;
  productKey: string;
  containers: SandboxMigrationContainerInput[];
  isMembershipCopySelected?: boolean | null;
}

export interface SandboxMigrationContainerInput {
  sourceId: string;
  key: string;
  includeAttachments: boolean;
  projectStyle?: string | null;
}

export interface SandboxMigrationContainersInput {
  migrationId: string;
  sourcesSelected: CloneSandboxDataSource[];
  isMembershipCopySelected?: boolean | null;
}

export interface SandboxMigrationPrepareInput {
  dataCloneType: string;
  parentSiteId: string;
  sandboxSiteId: string;
  productKey: string;
  includeAttachments?: boolean | null;
  hasTeamManagedProject: boolean;
}

export interface SandboxMigrationStartInput {
  migrationId: string;
}

export interface ScheduleCmaJobRequest {
  migratedFrom: string;
  productURL: string;
  destinationURL: string;
  product: MigrationProductFamily;
  migratedFromOverride?: string | null;
}

export interface ScheduleNonCmaJobRequest {
  originalApplink?: string | null;
  newApplink?: string | null;
  product: MigrationProductFamily;
  productURL: string;
  migratedFrom?: string | null;
  destinationURL?: string | null;
}

export interface SessionPolicyInput {
  idleTimeoutMinutes: number;
  mobileSession?: MobileSessionInput | null;
}

export interface ShadowItOrgPolicyInput {
  baseInput: BaseOrgPolicyInput;
  rule: ShadowItOrgPolicyRulesInput;
}

/**
 * Shadow IT
 */
export interface ShadowItOrgPolicyRulesInput {
  permission: ShadowItOrgPolicyPermission;
}

export interface SiteInviteExCoProductRolesInput {
  resource: string;
  role: string;
}

export interface SiteInviteExCoUsersInput {
  emails: string[];
  permissionsRules: SiteInviteExCoProductRolesInput[];
  additionalGroups: string[];
  platformRole?: string | null;
  sendNotification: boolean;
  notificationText?: string | null;
}

export interface SiteInviteUsersInput {
  emails?: (string | null)[] | null;
  productIds?: (string | null)[] | null;
  additionalGroups?: (string | null)[] | null;
  sendNotification?: boolean | null;
  notificationText?: string | null;
  role?: string | null;
  atlOrigin?: string | null;
  gRecaptchaToken?: string | null;
}

export interface SitePermissionsInviteInput {
  emails: string[];
  permissionsRules: SitePermissionsInviteProductRolesInput[];
  additionalGroups: string[];
  sendNotification: boolean;
  notificationText: string;
  role?: string | null;
  atlOrigin?: string | null;
  gRecaptchaToken?: string | null;
}

export interface SitePermissionsInviteProductRolesInput {
  resource: string;
  role: string;
}

export interface SortItem {
  field: string;
  order: SortOrder;
}

export interface SourceInput {
  serverId: string;
}

export interface SpacesInput {
  includedKeys: string[];
  includedData: string;
}

export interface StartBackupInput {
  product: BrieProducts;
  type: string;
  selections: BrieBackupSelections[];
  cloudId: string;
  context?: ContextInput | null;
}

export interface StartReconPayload {
  consumerType: ConsumerType;
  productType: ReconProductType;
  source: PayloadSource;
  destination: PayloadDestination;
  exportFilters?: ExportFilterInput | null;
  ruleSpec?: string | null;
}

export interface StartRestoreInput {
  product: BrieProducts;
  type: string;
  selections: BrieBackupSelections[];
  cloudId: string;
  backupId: string;
  uploadId?: string | null;
}

export interface SubmitDomainReview {
  domainName: string;
  rule: ReviewDomainRule;
}

export interface SubscribeConnectAppInput {
  cloudId: string;
  appKey: string;
  product: AtlassianConnectSupportedProduct;
}

export interface ToggleDevelopmentModeInput {
  cloudId: string;
  products: string[];
  enabled: boolean;
}

export interface UnifiedDirectoryUsersInput {
  cursor?: string | null;
  limit?: number | null;
  accountIds?: (string | null)[] | null;
  directoryIds?: (string | null)[] | null;
  resourceOwners?: (string | null)[] | null;
  resourceIds?: (string | null)[] | null;
  groupIds?: (string | null)[] | null;
  claimStatus?: (ClaimStatus | null)[] | null;
  emailDomains?: (string | null)[] | null;
  status?: (UserStatus | null)[] | null;
  accountStatus?: (AccountStatus | null)[] | null;
  membershipStatus?: (MembershipStatus | null)[] | null;
  userRoles?: (UserProductRole | null)[] | null;
  platformRoles?: (PlatformRole | null)[] | null;
  searchTerm?: string | null;
  sortBy?: (UnifiedDirectoryUsersSortBy | null)[] | null;
}

export interface UnifiedDirectoryUsersLastActiveTimesInput {
  accountIds: (string | null)[];
}

export interface UnifiedDirectoryUsersSortBy {
  field: string;
  direction: SortOrder;
}

export interface UnsubscribeConnectAppInput {
  cloudId: string;
  appKey: string;
  product: AtlassianConnectSupportedProduct;
}

export interface UpdateAppRequestInput {
  appKey: string;
  appName: string;
  cloudId: string;
  comment: string;
  productName: AppRequestProductName;
  status: AppRequestStatus;
  actionedByUserId: string;
}

export interface UpdateAuthPolicyInput {
  policyId: string;
  authPolicy: AuthPolicyInput;
}

export interface UpdateConnectAccessTokenInput {
  cloudId: string;
  appKey: string;
  product: AtlassianConnectSupportedProduct;
  token: string;
  state: AtlassianConnectAccessTokenLicenseState;
  capabilitySet: string;
  isPaidApp: boolean;
}

export interface UpdateConnectAppInput {
  cloudId: string;
  descriptorUrl: string;
  product: AtlassianConnectSupportedProduct;
  pluginName: string;
  pluginKey: string;
}

export interface UpdateDomainAccessPromotionInput {
  domains: string[];
  location: string;
  productARI: string;
  setting?: string | null;
}

export interface UpdateGroupInput {
  description?: string | null;
  name: string;
}

export interface UpdateMfaEnrollmentInput {
  id: string;
  member: string;
}

export interface UpdateMfaExemptionInput {
  id: string;
  member: string;
  exempt: boolean;
}

export interface UpdateMigrationInput {
  containersToAdd: CreateMigrationContainer[];
  containersToRemove: CreateMigrationContainer[];
  userGroupSelection?: UserGroupSelectionInput | null;
  crossProjectSelection?: CrossProjectSelectionInput | null;
  advancedRoadmapPlans?: AdvancedRoadmapPlans | null;
  assetsDataSelection?: AssetsDataSelectionInput | null;
  jiraDashboardSelection?: JiraDashboardSelection | null;
}

export interface UpdatePasswordPolicyInput {
  id: string;
  minimumStrength: string;
  expiryDays?: number | null;
  resetPasswords: boolean;
}

export interface UpdateProperties {
  add?: ConflictConsents | null;
  remove?: ConflictConsents | null;
}

export interface UpdateSamlInput {
  containerId: string;
  containerType: IdPContainerType;
  issuer: string;
  ssoUrl: string;
  publicCertificate: string;
}

export interface UpdateUserInstallationRulesInput {
  cloudId: string;
  rule: UserInstallationRuleValue;
}

export interface UserAccessSettingInput {
  domain: string;
  products: UserAccessSettingProductInput[];
  adminNotifications: string;
}

export interface UserAccessSettingProductInput {
  productARI: string;
  adminApprovalRequired: boolean;
  role: string;
}

export interface UserDirectory {
  directoryId: string;
  directoryType: DirectoryType;
}

export interface UserGroupSelectionInput {
  method: GroupMembership;
  scopedUserFlag?: boolean | null;
  scopedCustomerFlag?: boolean | null;
  migrateAllUsersInScopedGroup?: boolean | null;
  crossProjectBoardsAndFilters?: CrossProjectBoardsAndFilters | null;
}

export interface UserGroupsInput {
  start: number;
  count: number;
}

export interface UserRequestsInput {
  sortBy: string;
  sortOrder: PaginationSortOrder;
  limit: number;
  cursor?: string | null;
  requestTypes?: string[] | null;
  targetEntity: string[];
}

export interface UserRoleInput {
  resource: string;
  role: string;
}

export interface UsersAndGroupsInput {
  mode: string;
  preserveMemberships?: boolean | null;
  includeUsersInGroups?: boolean | null;
  includeRoleActors?: boolean | null;
}

export interface UsersInput {
  start: number;
  count: number;
  displayName?: string | null;
  contains?: string | null;
  activeStatus?: (string | null)[] | null;
  productUse?: (string | null)[] | null;
  productAdmin?: (string | null)[] | null;
  productHelpseeker?: (string | null)[] | null;
  role?: (string | null)[] | null;
  includeTotal?: boolean | null;
}

export interface ValidatePlanNameInput {
  name: string;
}

export interface ValidationPropertiesInput {
  properties?: Properties | null;
}

export interface WebhookHeadersInput {
  name: string;
  value: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
