import type { CustomDomainStatus as CustomDomainStatusEnum } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'customDomainsScreenEvent'
  | 'customDomainsDetailsScreenEvent'
  | 'addCustomDomainScreenEvent'
  | 'removeCustomDomainScreenEvent';

type UIEventKeys =
  | 'openFeedbackDialogClickEvent'
  | 'feedbackClickEvent'
  | 'activateCustomDomainButtonClickEvent'
  | 'deactivateCustomDomainButtonClickEvent'
  | 'activateCustomDomainActionClickEvent'
  | 'deactivateCustomDomainActionClickEvent'
  | 'removeCustomDomainActionClickEvent'
  | 'addCustomDomainButtonClickEvent'
  | 'closeAddCustomDomainModalClickEvent'
  | 'cancelAddCustomDomainModalClickEvent'
  | 'nextAddCustomDomainModalClickEvent'
  | 'backAddCustomDomainModalClickEvent'
  | 'selectKeywordAddCustomDomainModalClickEvent'
  | 'selectProductAddCustomDomainModalClickEvent'
  | 'copyCnameButtonClickEvent'
  | 'removeCustomDomainButtonClickEvent'
  | 'cancelRemoveCustomDomainButtonClickEvent'
  | 'closeRemoveCustomDomainDialogCloseEvent'
  | 'fallbackUrlLinkClickEvent'
  | 'redirectUrlLinkClickEvent'
  | 'learnAboutLimitationsLinkClickEvent'
  | 'showDetailsActionClickEvent';
/*
  | 'searchFieldClickEvent'
  | 'productFilterDropdownClickEvent'
  | 'appliesToFilterDropdownClickEvent'
  | 'statusFilterDropdownClickEvent'
  | 'leaveFeedbackDialogClickEvent';
  */

type TrackEventKeys =
  | 'customDomainActivatedTrackEvent'
  | 'customDomainDeactivatedTrackEvent'
  | 'customDomainActionActivatedTrackEvent'
  | 'customDomainActionDeactivatedTrackEvent'
  | 'customDomainDeletedTrackEvent'
  | 'customDomainCreatedTrackEvent'
  | 'domainQueryFilteredTrackEvent';

export enum CustomDomainsScreens {
  customDomainsScreen = 'customDomainsScreen',
  customDomainsDetailsScreen = 'customDomainsDetailsScreen',
  addCustomDomainScreen = 'addCustomDomainScreen',
  removeCustomDomainScreen = 'removeCustomDomainScreen',
}

export const customDomainsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2357920766/Custom+Domains+-+Analytics+Spec',
  screen: {
    customDomainsScreenEvent: (attributes: {
      numCustomDomains: number;
      featureGateVisible: boolean;
      addCustomDomainVisible: boolean;
      hasFreeWorkspaces: boolean;
    }): ScreenEvent => ({
      data: {
        name: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52523',
    }),
    customDomainsDetailsScreenEvent: (attributes: { customDomainId: string; status: string; hasRedirect: boolean }): ScreenEvent => ({
      data: {
        name: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52524',
    }),
    addCustomDomainScreenEvent: (attributes: { step: string }): ScreenEvent => ({
      data: {
        name: CustomDomainsScreens.addCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52525',
    }),
    removeCustomDomainScreenEvent: (attributes: { customDomainId: string }): ScreenEvent => ({
      data: {
        name: CustomDomainsScreens.removeCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52526',
    }),
  },
  ui: {
    activateCustomDomainActionClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'activateCustomDomainAction',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53197',
    }),
    deactivateCustomDomainActionClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'deactivateCustomDomainAction',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53198',
    }),
    removeCustomDomainActionClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'removeCustomDomainAction',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53199',
    }),
    showDetailsActionClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'showDetailsAction',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53200',
    }),
    feedbackClickEvent: (attributes: { feedback: boolean; source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'feedback',
        actionSubject: ActionSubject.Button,
        source: attributes.source,
        attributes: {
          feedback: attributes.feedback,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    openFeedbackDialogClickEvent: ({ source }: { source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'leaveFeedbackDialog',
        actionSubject: ActionSubject.Link,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    addCustomDomainButtonClickEvent: (attributes: { selectedTab: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addCustomDomainButton',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53114',
    }),
    closeAddCustomDomainModalClickEvent: (attributes: { step: string; stepIndex: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'close',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),
    cancelAddCustomDomainModalClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancel',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.addCustomDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    nextAddCustomDomainModalClickEvent: (attributes: { step: string; stepIndex: number; value?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'next',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25541',
    }),
    backAddCustomDomainModalClickEvent: (attributes: { step: string; stepIndex: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'back',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),
    selectKeywordAddCustomDomainModalClickEvent: (attributes: { value: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'keywordDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes: {
          step: 'CUSTOM_DOMAINS_CREATION_SPECIFY_DOMAINS',
          stepIndex: 2,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53115',
    }),
    selectProductAddCustomDomainModalClickEvent: (attributes: { value: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'productDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes: {
          step: 'CUSTOM_DOMAINS_CREATION_ASSOCIATE_PRODUCTS',
          stepIndex: 1,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19775',
    }),
    activateCustomDomainButtonClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'activateCustomDomainButton',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53209',
    }),
    deactivateCustomDomainButtonClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'deactivateCustomDomainButton',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53210',
    }),
    copyCnameButtonClickEvent: (attributes: { customDomainId: string; value: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'copyCnameButton',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53211',
    }),
    removeCustomDomainButtonClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'removeCustomDomainButton',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.removeCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53212',
    }),
    cancelRemoveCustomDomainButtonClickEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancel',
        actionSubject: ActionSubject.Button,
        source: CustomDomainsScreens.removeCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    closeRemoveCustomDomainDialogCloseEvent: (attributes: { customDomainId: string }): UIEvent => ({
      data: {
        action: Action.Closed,
        actionSubjectId: 'removeCustomDomainModal',
        actionSubject: ActionSubject.Dialog,
        source: CustomDomainsScreens.removeCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53213',
    }),
    fallbackUrlLinkClickEvent: (attributes: { customDomainId: string; fallbackUrl: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'fallbackUrlLink',
        actionSubject: ActionSubject.Link,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53214',
    }),
    redirectUrlLinkClickEvent: (attributes: { customDomainId: string; redirectUrl: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'redirectUrlLink',
        actionSubject: ActionSubject.Link,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53215',
    }),
    learnAboutLimitationsLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnAboutLimitationsLink',
        actionSubject: ActionSubject.Link,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54346',
    }),
  },
  track: {
    customDomainCreatedTrackEvent: (attributes: {
      fqdn: string;
      targetAri: string;
      workspaceAri: string;
      keyword: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubjectId: 'customDomainCreated',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.addCustomDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52971',
    }),
    customDomainDeletedTrackEvent: (attributes: {
      customDomainId: string;
      fqdn: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubjectId: 'customDomainDeleted',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52972',
    }),
    customDomainActivatedTrackEvent: (attributes: {
      customDomainId: string;
      fqdn: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
      status: CustomDomainStatusEnum;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Activated,
        actionSubjectId: 'customDomainActivated',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52975',
    }),
    customDomainDeactivatedTrackEvent: (attributes: {
      customDomainId: string;
      fqdn: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Deactivated,
        actionSubjectId: 'customDomainDeactivated',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.customDomainsDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52976',
    }),
    customDomainActionActivatedTrackEvent: (attributes: {
      customDomainId: string;
      fqdn: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
      status: CustomDomainStatusEnum;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Activated,
        actionSubjectId: 'customDomainActivated',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52975',
    }),
    customDomainActionDeactivatedTrackEvent: (attributes: {
      customDomainId: string;
      fqdn: string;
      redirectFqdn: string;
      hasRedirect: boolean;
      appliesTo: string;
      productKey: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Deactivated,
        actionSubjectId: 'customDomainDeactivated',
        actionSubject: TrackActionSubject.CustomDomain,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52976',
    }),
    domainQueryFilteredTrackEvent: (attributes: { products: string[]; appliesTo: string[]; statusFilter: string[] }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubjectId: 'domainQueryFiltered',
        actionSubject: TrackActionSubject.Form,
        source: CustomDomainsScreens.customDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53306',
    }),
  },
  operational: {},
};
