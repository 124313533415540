import type { AvailableProducts } from '@adminhub/types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export const enum ProductRequestsScreen {
  productRequestsScreen = 'productRequestsScreen',
  approveProductRequestAddingProductScreen = 'approveProductRequestAddingProductScreen',
  approveProductRequestBeforeApprovedScreen = 'approveProductRequestBeforeApprovedScreen',
  approveProductRequestAddDetailsScreen = 'approveProductRequestAddDetailsScreen',
}

type ScreenEventKeys =
  | 'approveProductRequestBeforeApprovedScreenEvent'
  | 'approveProductRequestAddDetailsScreen'
  | 'approveProductRequestAddingProductScreen';

type TrackEventKeys = 'productCreatedEvent' | 'productStatusUpdated';

type UiKeys =
  | 'approveProductRequestButtonClicked'
  | 'drawerCloseButtonClicked'
  | 'learnAboutProductRequestsLinkClicked'
  | 'closeButtonClicked'
  | 'nextButtonClicked'
  | 'confirmButtonClicked'
  | 'inviteUsersButtonClicked'
  | 'inviteUsersLinkClicked'
  | 'backButtonClicked';

export const approveProductEvents: FeatureEvents<ScreenEventKeys, UiKeys, TrackEventKeys, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/3998848562/Shadow+IT+M3+-+Approve+Add+Product+Request+Analytics+Spec',
  screen: {
    approveProductRequestBeforeApprovedScreenEvent: (attributes: { product: AvailableProducts }): ScreenEvent => ({
      data: {
        name: ProductRequestsScreen.approveProductRequestBeforeApprovedScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69934',
    }),
    approveProductRequestAddDetailsScreen: (attributes: { product: AvailableProducts }): ScreenEvent => ({
      data: {
        name: ProductRequestsScreen.approveProductRequestAddDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69935',
    }),
    approveProductRequestAddingProductScreen: (attributes: { product: AvailableProducts }): ScreenEvent => ({
      data: {
        name: ProductRequestsScreen.approveProductRequestAddingProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69936',
    }),
  },
  ui: {
    approveProductRequestButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'approveProductRequest',
        source: ProductRequestsScreen.productRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69937',
    }),
    inviteUsersButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'inviteUsers',
        source: ProductRequestsScreen.approveProductRequestAddingProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14416',
    }),
    confirmButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: ProductRequestsScreen.approveProductRequestAddDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),
    nextButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'next',
        source: ProductRequestsScreen.approveProductRequestBeforeApprovedScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25541',
    }),
    backButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source: ProductRequestsScreen.approveProductRequestBeforeApprovedScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),
    drawerCloseButtonClicked: (
      attributes: { product: AvailableProducts },
      {
        source,
      }: {
        source:
          | ProductRequestsScreen.approveProductRequestBeforeApprovedScreen
          | ProductRequestsScreen.approveProductRequestAddDetailsScreen
          | ProductRequestsScreen.approveProductRequestAddingProductScreen;
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'drawerClose',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47441',
    }),
    closeButtonClicked: (attributes: { product: AvailableProducts }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: ProductRequestsScreen.approveProductRequestAddingProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),
    learnAboutProductRequestsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnAboutProductRequests',
        source: ProductRequestsScreen.approveProductRequestBeforeApprovedScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69938',
    }),
    inviteUsersLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'inviteUsers',
        source: ProductRequestsScreen.approveProductRequestAddingProductScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69939',
    }),
  },
  track: {
    productCreatedEvent: (attributes: { product: AvailableProducts }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.Instance,
        source: ProductRequestsScreen.productRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34968',
    }),
    productStatusUpdated: (attributes: { product: AvailableProducts; success: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ProductStatus,
        source: ProductRequestsScreen.productRequestsScreen,
        attributes: {
          ...attributes,
          newStatus: 'APPROVED',
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52984',
    }),
  },
  operational: {},
};
