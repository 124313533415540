import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';

import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'orgOverviewScreen';

type UiKeys =
  | 'accessChecklistSecurityGuideUIEvent'
  | 'accessChecklistSecurityGuideButtonUIEvent'
  | 'accessChecklistOpenChecklistMenuUIEvent'
  | 'accessChecklistMenuNotInterestedUIEvent'
  | 'accessChecklistWhyAmISeeingThisUIEvent'
  | 'accessChecklistLearnMoreAccessUIEvent'
  | 'accessChecklistViewAuthPolicyEvent'
  | 'accessChecklistPrivacyLinkUIEvent';

const source = 'orgOverviewScreen';

export const accessChecklistEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2846798310/Analytics+Spec+Access+SMB+Checklist+Experiment',
  screen: {
    orgOverviewScreen: (): ScreenEvent => {
      const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/13999';

      return {
        data: {
          name: 'orgOverviewScreen',
        },
        dataPortalLink,
      };
    },
  },
  ui: {
    accessChecklistSecurityGuideUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'securityGuide',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56123',
    }),
    accessChecklistSecurityGuideButtonUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'securityGuide',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56731',
    }),
    accessChecklistOpenChecklistMenuUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openChecklistMenu',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56124',
    }),
    accessChecklistMenuNotInterestedUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'checklistMenuNotInterested',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56125',
    }),
    accessChecklistWhyAmISeeingThisUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'checklistMenuWhyAmISeeingThis',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56127',
    }),
    accessChecklistPrivacyLinkUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'checklistPrivacyLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56128',
    }),
    accessChecklistLearnMoreAccessUIEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMoreAccessButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13492',
    }),
    accessChecklistViewAuthPolicyEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAuthPolicy',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56639',
    }),
  },
  track: {},
  operational: {},
};
