import * as React from 'react';

import { Flags } from '@adminhub/reactive-vars';

export interface FlagProps {
  showFlag(flag: Flags.FlagDescriptor): void;
  hideFlag(flagId: string, skipOnClose?: boolean): void;
  hideFlagsStartingWith?(prefix: string, skipOnClose?: boolean): void;
}

/**
 * @deprecated Superseded by direct access to `Flags` from `@adminhub/reactive-vars`. Example: `Flags.showFlag()`
 */
export function withFlag<TOwnProps>(WrappedComponent: React.ComponentType<TOwnProps & FlagProps>): React.FC<TOwnProps> {
  return ({ ...props }) => {
    const show = Flags.showFlag;
    const hide = Flags.hideFlag;
    const hideStartingWith = Flags.hideFlagsStartingWith;

    return <WrappedComponent {...props} showFlag={show} hideFlag={hide} hideFlagsStartingWith={hideStartingWith} />;
  };
}
