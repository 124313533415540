import type { MonitoringClient } from './types';

import { LocalMonitoringClient } from './local-monitoring-client';
import { ProductionMonitoringClient } from './production-monitoring-client';
import { TestMonitoringClient } from './testing-monitoring-client';

const getMonitoringClient = (): MonitoringClient => {
  if (process.env.NODE_ENV === 'production') {
    return new ProductionMonitoringClient();
  }

  if (process.env.NODE_ENV === 'test') {
    return new TestMonitoringClient();
  }

  return new LocalMonitoringClient();
};

export const monitoringClient = getMonitoringClient();
