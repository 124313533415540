/** @jsx jsx */
import React, { useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { B100, N30 } from '@atlaskit/theme/colors';
import { borderRadius } from './constants';
var readViewContainerStyles = css({
  lineHeight: 1
});
var editButtonStyles = css({
  display: 'block',
  margin: "var(--ds-space-0, 0px)",
  padding: "var(--ds-space-0, 0px)",
  appearance: 'none',
  background: 'transparent',
  border: 0,
  lineHeight: 1,
  outline: '0',
  '&:focus + div': {
    border: "2px solid ".concat("var(--ds-border-focused, ".concat(B100, ")"))
  }
});
var readViewWrapperStyles = css({
  display: 'inline-block',
  boxSizing: 'border-box',
  width: 'auto',
  maxWidth: '100%',
  border: '2px solid transparent',
  borderRadius: borderRadius,
  transition: 'background 0.2s',
  '&:hover': {
    background: "var(--ds-background-neutral-subtle-hovered, ".concat(N30, ")")
  }
});
var readViewFitContainerWidthStyles = css({
  width: '100%'
});
var DRAG_THRESHOLD = 5;
var ReadView = function ReadView(_ref) {
  var editButtonLabel = _ref.editButtonLabel,
    onEditRequested = _ref.onEditRequested,
    postReadViewClick = _ref.postReadViewClick,
    editButtonRef = _ref.editButtonRef,
    readViewFitContainerWidth = _ref.readViewFitContainerWidth,
    readView = _ref.readView;
  var startX = useRef(0);
  var startY = useRef(0);
  var mouseHasMovedAfterMouseDown = function mouseHasMovedAfterMouseDown(event) {
    return Math.abs(startX.current - event.clientX) >= DRAG_THRESHOLD || Math.abs(startY.current - event.clientY) >= DRAG_THRESHOLD;
  };
  var onReadViewClick = function onReadViewClick(event) {
    var element = event.target;
    /** If a link is clicked in the read view, default action should be taken */
    if (element.tagName.toLowerCase() !== 'a' && !mouseHasMovedAfterMouseDown(event)) {
      event.preventDefault();
      onEditRequested();
      postReadViewClick();
    }
  };
  return jsx("div", {
    css: readViewContainerStyles
  }, jsx("button", {
    css: editButtonStyles,
    "aria-label": editButtonLabel,
    type: "button",
    onClick: onEditRequested,
    ref: editButtonRef
  }), jsx("div", {
    css: [readViewWrapperStyles, readViewFitContainerWidth && readViewFitContainerWidthStyles]
    /**
     * It is not normally acceptable to add click handlers to non-interactive elements
     * as this is an accessibility anti-pattern. However, because this instance is
     * account for clicking on links that may be embedded within inline-edit and not
     * creating an inaccessible custom element, we can add role="presentation" so that
     * there is no negative impacts to assistive technologies.
     * (Why links are embeeded in inline-edit is for another day...)
     */,
    role: "presentation",
    onClick: onReadViewClick,
    onMouseDown: function onMouseDown(e) {
      startX.current = e.clientX;
      startY.current = e.clientY;
    },
    "data-read-view-fit-container-width": readViewFitContainerWidth
  }, readView()));
};
export default ReadView;