import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export enum IdentityProviderScreens {
  identityProvidersListScreen = 'identityProvidersListScreen',
  identityProvidersPickerScreen = 'identityProvidersPickerScreen',
  addIdentityProviderScreen = 'addIdentityProviderScreen',
  connectIdentityProviderScreen = 'connectIdentityProviderScreen',
  identityProviderDetailsScreen = 'identityProviderDetailsScreen',
  linkedDomainsScreen = 'linkedDomainsScreen',
  linkDomainFocusedTask = 'linkDomainFocusedTask',
  transferLinkDomainScreen = 'transferLinkDomainScreen',
  setupUserProvisioningFocusedTask = 'setupUserProvisioningFocusedTask',
  setupSamlFocusedTask = 'setupSamlFocusedTask',
  identityProviderSamlScreen = 'identityProviderSamlScreen',
  expiredSamlConfigIdpBanner = 'expiredSamlConfigIdpBanner',
}

export enum RouteIds {
  IDP_DETAILS = 'idpDetails',
}

type ScreenKeys =
  | 'identityProvidersListScreenEvent'
  | 'addIdentityProviderScreenEvent'
  | 'connectIdentityProviderScreenEvent'
  | 'identityProviderDetailsScreenEvent'
  | 'linkedDomainsScreenEvent'
  | 'linkDomainFocusedTaskEvent'
  | 'transferLinkDomainScreenEvent'
  | 'setupUserProvisioningFocusedTaskEvent'
  | 'setupSamlFocusedTaskEvent'
  | 'identityProviderSamlScreenEvent'
  | 'expiredSamlConfigIdpBannerEvent';

type UIEventKeys =
  | 'addIdentityProviderClickEvent'
  | 'directoryNameClickEvent'
  | 'learnMoreLinkClickEvent'
  | 'confirmAddIdentityProviderClickEvent'
  | 'cancelAddIdentityProviderClickEvent'
  | 'setupIdpSamlClickEvent'
  | 'setupIdpScimClickEvent'
  | 'setupLaterClickEvent'
  | 'renameIdpConfirmationClickEvent'
  | 'cancelRenameIdpClickEvent'
  | 'disconnectIdentityProviderClickEvent'
  | 'cancelDisconnectIdpClickEvent'
  | 'closeDisconnectIdpClickEvent'
  | 'continueLinkDomainsClickEvent'
  | 'cancelLinkDomainClickEvent'
  | 'confirmLinkDomainsClickEvent'
  | 'continueTransferLinkDomainClickEvent'
  | 'cancelTransferLinkDomainClickEvent'
  | 'linkDomainClickEvent'
  | 'transferLinkDomainClickEvent'
  | 'renameIdentityProviderClickEvent'
  | 'regenerateAPITokenClickEvent'
  | 'deleteUserProvisioningClickEvent'
  | 'viewSamlConfigurationClickEvent'
  | 'viewTroubleshootingLogClickEvent'
  | 'viewGroupsClickEvent'
  | 'viewLinkedDomainsClickEvent'
  | 'viewPoliciesClickEvent'
  | 'setupUserProvisioningConfirmationClickEvent'
  | 'cancelSetupUserProvisioningClickEvent'
  | 'setupSamlClickEvent'
  | 'saveScimConfigurationClickEvent'
  | 'continueSetupSamlClickEvent'
  | 'cancelSetupSamlClickEvent'
  | 'continueToLinkDomainsClickEvent'
  | 'linkDomainsClickEvent'
  | 'setupUserProvisioningClickEvent'
  | 'saveSamlProvisioningClickEvent'
  | 'saveSamlConfigurationClickEvent'
  | 'deleteSamlConfigurationClickEvent'
  | 'expiredSamlConfigIdpLearnMoreLinkClickEvent';

type TrackEventKeys =
  | 'createIdentityProviderTrackEvent'
  | 'linkedDomainTrackEvent'
  | 'disconnectedIdentityProviderTrackEvent'
  | 'deleteUserProvisioningTrackEvent';

interface IdpTypeAttributes {
  idpType: string;
}

interface IdpIdAttributes extends IdpTypeAttributes {
  idpId: string;
}

interface FocusedTaskAttributes {
  isFocusedTask: boolean;
}

export const identityProvidersEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1529496743/Multiple+identity+providers+analytics+spec',
  screen: {
    identityProvidersListScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.identityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42735',
    }),
    addIdentityProviderScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.addIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42772',
    }),
    connectIdentityProviderScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.connectIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42773',
    }),
    identityProviderDetailsScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.identityProviderDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43641',
    }),
    linkedDomainsScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.linkedDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43642',
    }),
    linkDomainFocusedTaskEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.linkDomainFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43643',
    }),
    transferLinkDomainScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.transferLinkDomainScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43644',
    }),
    setupUserProvisioningFocusedTaskEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.setupUserProvisioningFocusedTask,
      },
    }),
    setupSamlFocusedTaskEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.setupSamlFocusedTask,
      },
      dataPortalLink: '', // TODO
    }),
    identityProviderSamlScreenEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.identityProviderSamlScreen,
      },
      dataPortalLink: '', // TODO
    }),
    expiredSamlConfigIdpBannerEvent: (): ScreenEvent => ({
      data: {
        name: IdentityProviderScreens.expiredSamlConfigIdpBanner,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46127',
    }),
  },
  ui: {
    addIdentityProviderClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addIdentityProvider',
        source: IdentityProviderScreens.identityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42737',
    }),
    directoryNameClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'directoryName',
        source: IdentityProviderScreens.identityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42736',
    }),
    learnMoreLinkClickEvent: (attributes: Partial<FocusedTaskAttributes>, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    confirmAddIdentityProviderClickEvent: (attributes: IdpTypeAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmAddIdentityProvider',
        source: IdentityProviderScreens.addIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42775',
    }),
    cancelAddIdentityProviderClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelAddIdentityProvider',
        source: IdentityProviderScreens.addIdentityProviderScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42776',
    }),
    setupIdpSamlClickEvent: (attributes: FocusedTaskAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupIdpSaml',
        source: IdentityProviderScreens.connectIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42777',
    }),
    setupIdpScimClickEvent: (attributes: FocusedTaskAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupIdpScim',
        source: IdentityProviderScreens.connectIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42778',
    }),
    setupLaterClickEvent: (attributes: FocusedTaskAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'setupLater',
        source: IdentityProviderScreens.connectIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42779',
    }),
    renameIdpConfirmationClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'renameIdpConfirmation',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43653',
    }),
    disconnectIdentityProviderClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectIdentityProvider',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43743',
    }),
    cancelRenameIdpClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRenameIdp',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43654',
    }),
    cancelDisconnectIdpClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDisconnectIdp',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43865',
    }),
    closeDisconnectIdpClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeDisconnectIdp',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43866',
    }),
    continueLinkDomainsClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueLinkDomains',
        source: IdentityProviderScreens.linkDomainFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44136',
    }),
    cancelLinkDomainClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelLinkDomain',
        source: IdentityProviderScreens.linkDomainFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44135',
    }),
    confirmLinkDomainsClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmLinkDomains',
        source: IdentityProviderScreens.linkDomainFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44137',
    }),
    continueTransferLinkDomainClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueTransferLinkDomain',
        source: IdentityProviderScreens.transferLinkDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44118',
    }),
    cancelTransferLinkDomainClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelTransferLinkDomain',
        source: IdentityProviderScreens.transferLinkDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44119',
    }),
    linkDomainClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'linkDomain',
        source: IdentityProviderScreens.linkedDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44134',
    }),
    transferLinkDomainClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'transferLinkDomain',
        source: IdentityProviderScreens.linkedDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44359',
    }),
    renameIdentityProviderClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'renameIdentityProvider',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43656',
    }),
    regenerateAPITokenClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateAPIToken',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43744',
    }),
    deleteUserProvisioningClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteUserProvisioning',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43745',
    }),
    viewSamlConfigurationClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewSamlConfiguration',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43746',
    }),
    viewTroubleshootingLogClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewTroubleshootingLog',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43747',
    }),
    viewGroupsClickEvent: (attributes: { idpId: string; routeId: string; groupCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewGroups',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43749',
    }),
    viewLinkedDomainsClickEvent: (attributes: { idpId: string; routeId: string; linkedDomainsCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewLinkedDomains',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43750',
    }),
    viewPoliciesClickEvent: (attributes: { idpId: string; routeId: string; authPolicyCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewPolicies',
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43751',
    }),
    setupUserProvisioningConfirmationClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupUserProvisioningConfirmation',
        source: IdentityProviderScreens.setupUserProvisioningFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44114',
    }),
    cancelSetupUserProvisioningClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSetupUserProvisioning',
        source: IdentityProviderScreens.setupUserProvisioningFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44115',
    }),
    setupSamlClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupSaml',
        source: IdentityProviderScreens.setupUserProvisioningFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44116',
    }),
    saveScimConfigurationClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveScimConfiguration',
        source: IdentityProviderScreens.setupUserProvisioningFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44117',
    }),
    continueSetupSamlClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueSetupSaml',
        source: IdentityProviderScreens.setupSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43907',
    }),
    cancelSetupSamlClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSetupSaml',
        source: IdentityProviderScreens.setupSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43908',
    }),
    saveSamlConfigurationClickEvent: (attributes: { idpId: string; routeId: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveSamlConfiguration',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43909',
    }),
    continueToLinkDomainsClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueToLinkDomains',
        source: IdentityProviderScreens.setupSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43910',
    }),
    linkDomainsClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'linkDomains',
        source: IdentityProviderScreens.setupSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43911',
    }),
    setupUserProvisioningClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupUserProvisioning',
        source: IdentityProviderScreens.setupSamlFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43912',
    }),
    saveSamlProvisioningClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveSamlProvisioning',
        source: IdentityProviderScreens.identityProviderSamlScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43913',
    }),
    deleteSamlConfigurationClickEvent: (attributes: { idpId: string; routeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteSamlConfiguration',
        source: IdentityProviderScreens.identityProviderSamlScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43914',
    }),
    expiredSamlConfigIdpLearnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'expiredSamlConfigIdpLearnMoreLink',
        source: IdentityProviderScreens.identityProvidersListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46128',
    }),
  },
  operational: {
    deleteIdentityProviderOperationalEvent: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Deleted,
        actionSubject: OperationalSubject.IdentityProvider,
        source: IdentityProviderScreens.identityProviderDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44360',
    }),
  },
  track: {
    createIdentityProviderTrackEvent: (attributes: IdpIdAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.IdentityProvider,
        source: IdentityProviderScreens.addIdentityProviderScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42774',
    }),
    linkedDomainTrackEvent: (attributes: { destinationIdpId: string; sourceIdpId: string; routeId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Linked,
        actionSubject: TrackActionSubject.Domain,
        source: IdentityProviderScreens.linkedDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44138',
    }),
    disconnectedIdentityProviderTrackEvent: (attributes: { idpId: string; routeId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Disconnected,
        actionSubject: TrackActionSubject.IdentityProvider,
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43904',
    }),
    deleteUserProvisioningTrackEvent: (attributes: { idpId: string; routeId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.UserProvisioning,
        source: IdentityProviderScreens.identityProviderDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43847',
    }),
  },
};
