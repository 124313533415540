import {
  Action,
  ActionSubject,
  TrackAction,
  TrackActionSubject,
  type FeatureEvents,
  type FeatureEventUi,
  type OperationalEvent,
  type ScreenEvent,
  type TrackEvent,
  type UIEvent,
} from '../new-analytics-types';

type ScreenKeys =
  | 'migrationSharedExperiencesOptInScreenEvent'
  | 'migrationSharedExperiencesSelectProductScreenEvent'
  | 'migrationSharedExperiencesJiraChooseSourceAndDestinationScreenEvent'
  | 'migrationSharedExperiencesJiraSelectMigrationMethodScreenEvent'
  | 'migrationSharedExperiencesJiraMethodSelectionScreenEvent'
  | 'migrationSharedExperiencesJiraInclusionSelectionScreenEvent'
  | 'migrationSharedExperiencesJiraUserScopeSelectionScreenEvent'
  | 'migrationSharedExperiencesJiraProjectSelectionScreenEvent'
  | 'migrationSharedExperiencesJiraAttachmentsOnlySelectionScreenEvent'
  | 'migrationSharedExperiencesJiraPreloadableAppDataSelectionScreenEvent'
  | 'migrationSharedExperiencesJiraPreMigrationChecksScreenEvent'
  | 'migrationSharedExperiencesJiraReviewMigrationScreenEvent'
  | 'migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreenEvent'
  | 'migrationSharedExperiencesConfluenceSelectMigrationMethodScreenEvent'
  | 'migrationSharedExperiencesConfluenceSpaceSelectionScreenEvent'
  | 'migrationSharedExperiencesConfluenceMethodSelectionScreenEvent'
  | 'migrationSharedExperiencesConfluenceInclusionSelectionScreenEvent'
  | 'migrationSharedExperiencesConfluenceAttachmentsOnlySelectionScreenEvent'
  | 'migrationSharedExperiencesConfluencePreMigrationChecksScreenEvent'
  | 'migrationSharedExperiencesConfluenceReviewMigrationScreenEvent'
  | 'migrationSharedExperiencesPlanDetailsScreenEvent';

type TrackEventKeys =
  | 'migrationSharedExperiencesJiraCreateJob'
  | 'migrationSharedExperiencesConfluenceCreateJob'
  | 'migrationSharedExperiencesJiraCheckTask'
  | 'migrationSharedExperiencesConfluenceCheckTask'
  | 'migrationSharedExperiencesJiraRunMigrateServerToCloudTask'
  | 'migrationSharedExperiencesConfluenceRunMigrateServerToCloudTask'
  | 'migrationSharedExperiencesCsvUploadEvent'
  | 'migrationSharedExperiencesPlanSavedEvent'
  | 'migrationSharedExperiencesPremigrationChecksTriggeredEvent'
  | 'migrationSharedExperiencesPremigrationChecksFinishedEvent'
  | 'migrationSharedExperiencesPremigrationChecksErroredEvent';

type UiEventKeys =
  | 'migrationSharedExperiencesOptInButtonClick'
  | 'migrationSharedExperiencesCsvModalToggle'
  | 'migrationSharedExperiencesRunChecksButtonClick'
  | 'migrationSharedExperiencesSavePlanButtonClick'
  | 'openConnectToSourceModalClickEvent'
  | 'continueConnectToSourceClickEvent'
  | 'migrationSharedExperiencesRerunChecksButtonClick'
  | 'migrationSharedExperiencesRunPlanButtonClick'
  | 'migrationSharedExperiencesSavePlanButtonClickReviewPage';

// Screens
export const migrationSharedExperiencesOptInScreen = 'migrationSharedExperiencesOptInScreen';
export const migrationSharedExperiencesSelectProductScreen = 'migrationSharedExperiencesSelectProductScreen';
export const migrationSharedExperiencesJiraChooseSourceAndDestinationScreen =
  'migrationSharedExperiencesJiraChooseSourceAndDestinationScreen';
export const migrationSharedExperiencesJiraSelectMigrationMethodScreen = 'migrationSharedExperiencesJiraSelectMigrationMethodScreen';
export const migrationSharedExperiencesJiraMethodSelectionScreen = 'migrationSharedExperiencesJiraMethodSelectionScreen';
export const migrationSharedExperiencesJiraInclusionSelectionScreen = 'migrationSharedExperiencesJiraInclusionSelectionScreen';
export const migrationSharedExperiencesJiraUserScopeSelectionScreen = 'migrationSharedExperiencesJiraUserScopeSelectionScreen';
export const migrationSharedExperiencesJiraProjectSelectionScreen = 'migrationSharedExperiencesJiraProjectSelectionScreen';
export const migrationSharedExperiencesJiraAttachmentsOnlySelectionScreen = 'migrationSharedExperiencesJiraAttachmentsOnlySelectionScreen';
export const migrationSharedExperiencesJiraPreloadableAppDataSelectionScreen =
  'migrationSharedExperiencesJiraPreloadableAppDataSelectionScreen';
export const migrationSharedExperiencesJiraPreMigrationChecksScreen = 'migrationSharedExperiencesJiraPreMigrationChecksScreen';
export const migrationSharedExperiencesJiraReviewMigrationScreen = 'migrationSharedExperiencesJiraReviewMigrationScreen';
export const migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreen =
  'migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreen';
export const migrationSharedExperiencesConfluenceSelectMigrationMethodScreen =
  'migrationSharedExperiencesConfluenceSelectMigrationMethodScreen';
export const migrationSharedExperiencesConfluenceSpaceSelectionScreen = 'migrationSharedExperiencesConfluenceSpaceSelectionScreen';
export const migrationSharedExperiencesConfluenceMethodSelectionScreen = 'migrationSharedExperiencesConfluenceMethodSelectionScreen';
export const migrationSharedExperiencesConfluenceInclusionSelectionScreen = 'migrationSharedExperiencesConfluenceInclusionSelectionScreen';
export const migrationSharedExperiencesConfluenceAttachmentsOnlySelectionScreen =
  'migrationSharedExperiencesConfluenceAttachmentsOnlySelectionScreen';
export const migrationSharedExperiencesConfluencePreMigrationChecksScreen = 'migrationSharedExperiencesConfluencePreMigrationChecksScreen';
export const migrationSharedExperiencesConfluenceReviewMigrationScreen = 'migrationSharedExperiencesConfluenceReviewMigrationScreen';
export const migrationSharedExperiencesPlanDetailsScreen = 'migrationSharedExperiencesPlanDetailsScreen';
export const migrationSharedExperiencesCsvUploadModal = 'migrationSharedExperiencesCsvUploadModal';
export const migrationSharedExperiencesFocusedTask = 'migrationSharedExperiencesFocusedTask';

export const withMigrationAttribute = (fn) => (attributes) => {
  const newAttributes = { ...attributes, migration: true };

  return fn(newAttributes);
};

export type FeatureEventsScreenType = Record<ScreenKeys, (attributes?: Record<string, any>) => ScreenEvent>;
export type FeatureEventsUIType = Record<UiEventKeys, FeatureEventUi>;
export type FeatureEventsTrackType = Record<TrackEventKeys, (attributes?: Record<string, any>) => TrackEvent>;
export type FeatureEventsOperationalType = Record<string, (attributes?: Record<string, any>) => OperationalEvent>;

export const mapEventsWithMigrationAttribute = <T extends Record<string, (attributes: Record<string, any>) => any>>(events: T): T =>
  Object.fromEntries(Object.entries(events).map(([key, eventFn]) => [key, withMigrationAttribute(eventFn)])) as T;

export const migrationSharedExperiencesEvents: FeatureEvents<ScreenKeys, UiEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/MigPT/pages/3393376446/SXP+-+Analytics',
  screen: mapEventsWithMigrationAttribute<FeatureEventsScreenType>({
    migrationSharedExperiencesOptInScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesOptInScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75507',
    }),
    migrationSharedExperiencesSelectProductScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesSelectProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66642',
    }),
    migrationSharedExperiencesJiraChooseSourceAndDestinationScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraChooseSourceAndDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66643',
    }),
    migrationSharedExperiencesJiraSelectMigrationMethodScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraSelectMigrationMethodScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66644',
    }),
    migrationSharedExperiencesJiraMethodSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraMethodSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66645',
    }),
    migrationSharedExperiencesJiraInclusionSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraInclusionSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66647',
    }),
    migrationSharedExperiencesJiraUserScopeSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraUserScopeSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66646',
    }),
    migrationSharedExperiencesJiraProjectSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraProjectSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66648',
    }),
    migrationSharedExperiencesJiraAttachmentsOnlySelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraAttachmentsOnlySelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75821',
    }),
    migrationSharedExperiencesJiraPreloadableAppDataSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraPreloadableAppDataSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74666',
    }),
    migrationSharedExperiencesJiraPreMigrationChecksScreenEvent: (attributes: {
      migrationScope: string;
      migrationMethod: string;
    }): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraPreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66650',
    }),
    migrationSharedExperiencesJiraReviewMigrationScreenEvent: (attributes: {
      migrationScope: string;
      migrationMethod: string;
    }): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesJiraReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66651',
    }),
    migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66652',
    }),
    migrationSharedExperiencesConfluenceSelectMigrationMethodScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceSelectMigrationMethodScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66653',
    }),
    migrationSharedExperiencesConfluenceSpaceSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceSpaceSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66655',
    }),
    migrationSharedExperiencesConfluenceMethodSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceMethodSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66656',
    }),
    migrationSharedExperiencesConfluenceInclusionSelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceInclusionSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66657',
    }),
    migrationSharedExperiencesConfluenceAttachmentsOnlySelectionScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceAttachmentsOnlySelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75822',
    }),
    migrationSharedExperiencesConfluencePreMigrationChecksScreenEvent: (attributes: {
      migrationScope: string;
      migrationMethod: string;
    }): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluencePreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66658',
    }),
    migrationSharedExperiencesConfluenceReviewMigrationScreenEvent: (attributes: {
      migrationScope: string;
      migrationMethod: string;
    }): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesConfluenceReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66659',
    }),
    migrationSharedExperiencesPlanDetailsScreenEvent: (attributes: {
      migrationId: string;
      cloudId: string;
      phase: string;
      status: string;
      product: string;
    }): ScreenEvent => ({
      data: {
        name: migrationSharedExperiencesPlanDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75388',
    }),
  }),
  ui: mapEventsWithMigrationAttribute<FeatureEventsUIType>({
    migrationSharedExperiencesOptInButtonClick: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sxpOptIn',
        source: migrationSharedExperiencesOptInScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75508',
    }),
    migrationSharedExperiencesCsvModalToggle: (attributes: { isOpen: boolean }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Modal,
        actionSubjectId: 'migrationSharedExperiencesCsvUploadModal',
        source: migrationSharedExperiencesCsvUploadModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74999',
    }),
    openConnectToSourceModalClickEvent: (attributes: {
      cloudId?: string;
      supportEntitlementNumber?: string;
      migrationProduct?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectToSource',
        source:
          attributes?.migrationProduct === 'jira'
            ? migrationSharedExperiencesJiraChooseSourceAndDestinationScreen
            : migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74404',
    }),
    continueConnectToSourceClickEvent: (attributes: {
      cloudId?: string;
      supportEntitlementNumber?: string;
      migrationProduct?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectToSourceContinue',
        source:
          attributes?.migrationProduct === 'jira'
            ? migrationSharedExperiencesJiraChooseSourceAndDestinationScreen
            : migrationSharedExperiencesConfluenceChooseSourceAndDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74434',
    }),
    migrationSharedExperiencesRunChecksButtonClick: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      product: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'runChecks',
        source:
          attributes.product === 'jira'
            ? migrationSharedExperiencesJiraInclusionSelectionScreen
            : migrationSharedExperiencesConfluenceInclusionSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74403',
    }),
    migrationSharedExperiencesSavePlanButtonClick: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      product: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'savePlan',
        source:
          attributes.product === 'jira'
            ? migrationSharedExperiencesJiraInclusionSelectionScreen
            : migrationSharedExperiencesConfluenceInclusionSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74435',
    }),
    migrationSharedExperiencesRerunChecksButtonClick: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      product: string;
      migrationId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rerunChecks',
        source:
          attributes.product === 'jira'
            ? migrationSharedExperiencesJiraPreMigrationChecksScreen
            : migrationSharedExperiencesConfluencePreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74835',
    }),
    migrationSharedExperiencesRunPlanButtonClick: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      product: string;
      migrationId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'runPlan',
        source:
          attributes.product === 'jira'
            ? migrationSharedExperiencesJiraReviewMigrationScreen
            : migrationSharedExperiencesConfluenceReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74836',
    }),
    migrationSharedExperiencesSavePlanButtonClickReviewPage: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      product: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'savePlan',
        source:
          attributes.product === 'jira'
            ? migrationSharedExperiencesJiraReviewMigrationScreen
            : migrationSharedExperiencesConfluenceReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74435',
    }),
  }),
  track: mapEventsWithMigrationAttribute<FeatureEventsTrackType>({
    migrationSharedExperiencesJiraCreateJob: (attributes: {
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.MigrationJob,
        source: migrationSharedExperiencesJiraReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67870',
    }),
    migrationSharedExperiencesConfluenceCreateJob: (attributes: {
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.MigrationJob,
        source: migrationSharedExperiencesConfluenceReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67872',
    }),
    migrationSharedExperiencesJiraCheckTask: (attributes: {
      taskId: string;
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.MigrationTask,
        source: migrationSharedExperiencesJiraPreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67871',
    }),
    migrationSharedExperiencesConfluenceCheckTask: (attributes: {
      taskId: string;
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.MigrationTask,
        source: migrationSharedExperiencesConfluencePreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67873',
    }),
    migrationSharedExperiencesConfluenceRunMigrateServerToCloudTask: (attributes: {
      taskId: string;
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.ServerToCloudTask,
        source: migrationSharedExperiencesConfluenceReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67942',
    }),
    migrationSharedExperiencesJiraRunMigrateServerToCloudTask: (attributes: {
      taskId: string;
      jobId: string;
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.ServerToCloudTask,
        source: migrationSharedExperiencesJiraReviewMigrationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67943',
    }),
    migrationSharedExperiencesCsvUploadEvent: (attributes: {
      isSuccess: boolean;
      errorMessage?: string;
      migrationProduct: string;
      entityCount: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Upload,
        actionSubject: TrackActionSubject.Form,
        source: migrationSharedExperiencesCsvUploadModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69121',
    }),
    migrationSharedExperiencesPlanSavedEvent: (attributes: {
      entityCount: string;
      migrationMethod: string;
      migrationScope: string;
      supportEntitlementNumber: string;
      dataScopeId: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.PlanDraft,
        attributes,
        source: migrationSharedExperiencesFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74436',
    }),
    migrationSharedExperiencesPremigrationChecksTriggeredEvent: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Triggered,
        actionSubject: TrackActionSubject.Checks,
        source: migrationSharedExperiencesJiraPreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74781',
    }),
    migrationSharedExperiencesPremigrationChecksFinishedEvent: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationId: string;
      state: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Finished,
        actionSubject: TrackActionSubject.Checks,
        source: migrationSharedExperiencesJiraPreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74782',
    }),
    migrationSharedExperiencesPremigrationChecksErroredEvent: (attributes: {
      serverId: string;
      cloudId: string;
      supportEntitlementNumber: string;
      migrationId: string;
      migrationProduct: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Errored,
        actionSubject: TrackActionSubject.Checks,
        source: migrationSharedExperiencesJiraPreMigrationChecksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75006',
    }),
  }),
  operational: mapEventsWithMigrationAttribute<FeatureEventsOperationalType>({}),
};
