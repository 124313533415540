import type { ReactiveVar } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import * as React from 'react';

type Impossible<K extends keyof any> = {
  [P in K]: never;
};
type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;

function withReactiveVar<TValue, TPropName extends keyof TProps, TProps extends JSX.IntrinsicAttributes = {}>(
  reactiveVariable: ReactiveVar<TValue>,
  propName: TPropName,
  Component: React.ComponentType<TProps>,
): React.FC<Omit<TProps, TPropName>> {
  return (ownProps) => {
    const props = { [propName]: reactiveVariable };
    const mergedProps = { ...ownProps, ...props } as TProps;

    useReactiveVar(reactiveVariable);

    return <Component {...mergedProps} />;
  };
}

export function withReactiveVars<TProps extends TOwnProps, TOwnProps = {}, TMapVarToProps = Omit<TProps, keyof TOwnProps>>(
  mapVarToProps: NoExtraProperties<TMapVarToProps>,
  Component: React.ComponentType<TProps>,
) {
  const entries = Object.entries<ReactiveVar<unknown>>(mapVarToProps);

  // Reduce here goes through every entry in mapVarToProps and wraps Component with a wrapper that
  // subscribes and injects the reactive variable into the component. TypeScript typing doesn't work
  // well with recursion, so the result is manually coerced to the type React.ComponentType<TOwnProps>
  return entries.reduce<React.ComponentType<any>>((component, couple) => {
    const [prop, reactiveVar] = couple;

    return withReactiveVar(reactiveVar, prop, component);
  }, Component) as React.ComponentType<TOwnProps>;
}
