import type { ConnectionStates } from '../../../../pages/osync/osync-page-connect/src/ui/osync-connect-drawer/osync-connect/osync-connect-authentication';

import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';

type ScreenKeys =
  | 'oSyncConnectDrawerEvent'
  | 'oSyncConnectScreenEvent'
  | 'oSyncSettingsScreenEvent'
  | 'oSyncSettingsConfirmModalEvent'
  | 'oSyncDisableSSOModalEvent'
  | 'oSyncDisabledDisconnectModalEvent'
  | 'oSyncSettingsDisconnectModalEvent'
  | 'oSyncStatusScreenEvent';

type UIEventKeys =
  | 'authenticationPoliciesClickEvent'
  | 'cancelDisconnectOSyncAccountConfirmClickEvent'
  | 'cancelOSyncClickEvent'
  | 'closeOSyncConnectDrawerClickEvent'
  | 'connectAzureADClickEvent'
  | 'disconnectAzureADClickEvent'
  | 'disconnectOSyncAccountConfirmClickEvent'
  | 'editOSyncSettingsClickEvent'
  | 'learnMoreLinkClickEvent'
  | 'openOSyncConnectDrawerClickEvent'
  | 'oSyncActionRequiredBeforeNextSyncStatusError'
  | 'oSyncDisconnectedFlagShownEvent'
  | 'oSyncSavedSettingsNotificationShownEvent'
  | 'reconnectAzureADClickEvent'
  | 'saveOSyncSettingsClickEvent'
  | 'setupOSyncClickEvent'
  | 'startOSyncClickEvent'
  | 'startOSyncLinkClickEvent'
  | 'syncOSyncClickEvent'
  | 'updateOSyncGroupClickEvent'
  | 'viewAuditLogsClickEvent'
  | 'viewAuthenticationPoliciesClickEvent'
  | 'viewDomainsClickEvent'
  | 'viewMembersClickEvent'
  | 'viewUserProvisioningClickEvent';

type TrackEventKeys =
  | 'connectedTrackEvent'
  | 'disconnectedTrackEvent'
  | 'settingsUpdatedTrackEvent'
  | 'syncFinishedTrackEvent'
  | 'syncStartedTrackEvent';

export type OSyncDomainTypeOfSync = 'syncAllDomains' | 'syncSpecificDomains';
export type OSyncGroupTypeOfSync = 'syncAllUsers' | 'syncSpecificGroups';
export type OSyncSourceOfSync = 'oSyncSettingsScreen' | 'oSyncStatusScreen';

export const oSyncConnectDrawer = 'oSyncConnectDrawer';
export const oSyncConnectScreen = 'oSyncConnectScreen';
export const oSyncSettingsScreen = 'oSyncSettingsScreen';
export const oSyncSettingsConfirmModal = 'oSyncSettingsConfirmModal';
export const oSyncDisableSSOModal = 'oSyncDisableSSOModal';
export const oSyncDisabledDisconnectModal = 'oSyncDisabledDisconnectModal';
export const oSyncSettingsDisconnectModal = 'oSyncSettingsDisconnectModal';
export const oSyncStatusScreen = 'oSyncStatusScreen';

export const oSyncEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/ACM/pages/1623298028/Azure+AD+OSync+Analytic+events+for+Frontend',
  screen: {
    oSyncConnectDrawerEvent: (attributes: { connectionState: ConnectionStates | undefined }): ScreenEvent => ({
      data: {
        name: oSyncConnectDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47892',
    }),
    oSyncConnectScreenEvent: (attributes: { hasAccessError: boolean }): ScreenEvent => ({
      data: {
        name: oSyncConnectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47834',
    }),
    oSyncSettingsScreenEvent: (attributes: { isFirstSync: boolean }): ScreenEvent => ({
      data: {
        name: oSyncSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43403',
    }),
    oSyncSettingsConfirmModalEvent: (): ScreenEvent => ({
      data: {
        name: oSyncSettingsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48171',
    }),
    oSyncDisableSSOModalEvent: (): ScreenEvent => ({
      data: {
        name: oSyncDisableSSOModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48172',
    }),
    oSyncDisabledDisconnectModalEvent: (): ScreenEvent => ({
      data: {
        name: oSyncDisabledDisconnectModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48173',
    }),
    oSyncSettingsDisconnectModalEvent: (): ScreenEvent => ({
      data: {
        name: oSyncSettingsDisconnectModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48174',
    }),
    oSyncStatusScreenEvent: (): ScreenEvent => ({
      data: {
        name: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48175',
    }),
  },
  track: {
    connectedTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Connected,
        actionSubject: TrackActionSubject.OSyncAccount,
        source: oSyncConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48475',
    }),
    disconnectedTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Disconnected,
        actionSubject: TrackActionSubject.OSyncAccount,
        source: oSyncSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48514',
    }),
    settingsUpdatedTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.SettingsUpdated,
        actionSubject: TrackActionSubject.OSyncAccount,
        source: oSyncSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48515',
    }),
    syncFinishedTrackEvent: (attributes: {
      oSyncDomainTypeOfSync: OSyncDomainTypeOfSync;
      oSyncGroupTypeOfSync: OSyncGroupTypeOfSync;
    }): TrackEvent => ({
      data: {
        action: TrackAction.SyncFinished,
        actionSubject: TrackActionSubject.OSyncAccount,
        source: oSyncStatusScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48453',
    }),
    syncStartedTrackEvent: ({
      source,
      ...attributes
    }: {
      oSyncDomainTypeOfSync: OSyncDomainTypeOfSync;
      oSyncGroupTypeOfSync: OSyncGroupTypeOfSync;
      source: OSyncSourceOfSync;
    }): TrackEvent => ({
      data: {
        action: TrackAction.SyncStarted,
        actionSubject: TrackActionSubject.OSyncAccount,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43352',
    }),
  },
  ui: {
    authenticationPoliciesClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'authenticationPolicies',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48692',
    }),
    cancelDisconnectOSyncAccountConfirmClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDisconnectOSyncAccountConfirm',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48691',
    }),
    cancelOSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelOSync',
        source: oSyncSettingsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48683',
    }),
    closeOSyncConnectDrawerClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeOSyncConnectDrawer',
        source: oSyncConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48587',
    }),
    connectAzureADClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectAzureAD',
        source: oSyncConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47905',
    }),
    disconnectAzureADClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectAzureAD',
        source: oSyncSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48591',
    }),
    disconnectOSyncAccountConfirmClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectOSyncAccountConfirm',
        source: oSyncSettingsDisconnectModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43477',
    }),
    editOSyncSettingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editOSyncSettings',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48725',
    }),
    learnMoreLinkClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    openOSyncConnectDrawerClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openOSyncConnectDrawer',
        source: oSyncConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48565',
    }),
    oSyncActionRequiredBeforeNextSyncStatusError: (attributes: { errorType?: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'oSyncActionRequiredBeforeNextSync',
        source: 'osyncStatusScreen',
        actionSubject: ActionSubject.Error,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43478',
    }),
    oSyncDisconnectedFlagShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'oSyncDisconnected',
        source: oSyncStatusScreen,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43475',
    }),
    oSyncSavedSettingsNotificationShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'oSyncSavedSettingsNotification',
        source: 'oSyncSettingsScreen',
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43480',
    }),
    reconnectAzureADClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reconnectAzureAD',
        source: oSyncConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48588',
    }),
    saveOSyncSettingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveOSyncSettings',
        source: oSyncSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48590',
    }),
    setupOSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'setupOSync',
        source: oSyncStatusScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43372',
    }),
    startOSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'startOSync',
        source: oSyncSettingsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48686',
    }),
    startOSyncLinkClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'startOSync',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48723',
    }),
    syncOSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'syncOSync',
        source: oSyncSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48589',
    }),
    updateOSyncGroupClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'updateOSyncGroup',
        source: oSyncStatusScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43479',
    }),
    viewAuditLogsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewAuditLogs',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48722',
    }),
    viewAuthenticationPoliciesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewAuthenticationPolicies',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48726',
    }),
    viewDomainsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewDomains',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48721',
    }),
    viewMembersClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewMembers',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48719',
    }),
    viewUserProvisioningClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewUserProvisioning',
        source: oSyncStatusScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48720',
    }),
  },
  operational: {},
};
