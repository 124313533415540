import * as React from 'react';

import type { UIEvent } from './new-analytics-types';
import { useAnalyticsClient } from './use-analytics-client';

export interface DwellEventSenderProps {
  analyticsUIEvent: UIEvent; // Should be a dwell event
  durationSeconds?: number;
}

export const DWELL_DURATION_SECONDS = 10;

export const DwellEventSender: React.FC<DwellEventSenderProps> = ({
  analyticsUIEvent,
  durationSeconds = DWELL_DURATION_SECONDS,
  children,
}) => {
  const { sendUIEvent } = useAnalyticsClient();
  const dwellTimer = React.useRef<number | null>(null);

  const sendDwellEvent = React.useCallback(() => {
    if (!sendUIEvent || !analyticsUIEvent || !dwellTimer.current) {
      return;
    }
    sendUIEvent(analyticsUIEvent);
  }, [dwellTimer, sendUIEvent, analyticsUIEvent]);

  const stopDwellTimer = React.useCallback(() => {
    if (dwellTimer.current) {
      clearTimeout(dwellTimer.current);
      dwellTimer.current = null;
    }
  }, [dwellTimer]);

  const startDwellTimer = React.useCallback(() => {
    if (!sendUIEvent || !analyticsUIEvent) {
      return;
    }
    stopDwellTimer();
    dwellTimer.current = window.setTimeout(sendDwellEvent, durationSeconds * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationSeconds, analyticsUIEvent, dwellTimer, sendDwellEvent, stopDwellTimer]);

  React.useEffect(() => {
    startDwellTimer();

    return stopDwellTimer;
  }, [startDwellTimer, stopDwellTimer]);

  return <>{children}</>;
};
