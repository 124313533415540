import type { SortOrderType } from '@atlaskit/dynamic-table/types';

import type { DirectoryType, PlatformRole, UserProductRole, UserStatus } from '@adminhub/graphql-types';

import type { OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

const unifiedUserListScreen = 'unifiedUserListScreen';

export type UULSource = typeof unifiedUserListScreen;

export interface VortexSiteAdminAttributes {
  vortexStatus: boolean;
  tenantCount?: number;
  enhancedSiteAdmin: boolean;
  userRole?: string;
}

export const unifiedUserListEvents = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4322133136/UUL+Analytic+Specs+M1',
  screen: {
    unifiedUserListScreen: (attributes: {
      totalUsers: number;
      managedUsers: number;
      orgAdmins: number;
      orgType?: DirectoryType;
    }): ScreenEvent => ({
      data: {
        name: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75042',
    }),
  },
  ui: {
    openInviteUserDrawerButtonClicked: (attributes: { totalUserCount?: number | null; userCountOnScreen?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openInviteUserDrawer',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14633',
    }),
    exportButtonClicked: (attributes: { totalUserCount?: number | null; userCountOnScreen?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportUsers',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14634',
    }),
    sortButtonClicked: (attributes: { sortKey: string; sortDirection: SortOrderType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sortByColumn',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74647',
    }),
    lastActiveToolTipHovered: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'lastActiveToolTip',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74750',
    }),
    statusToolTipHovered: (attributes: { status: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'statusToolTip',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74754',
    }),
    resultsPerPageButtonClicked: (attributes: { resultsPerPage: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resultsPerPage',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74515',
    }),
    changePageButtonClicked: (attributes: { direction: string; previousResultsRange: string; previousPageNumber: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'changePage',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74755',
    }),
    actionColumnButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'actionColumn',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74758',
    }),
    resendInviteButtonClicked: (attributes: { userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resendInvite',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14640',
    }),
    addToGroupButtonClicked: (attributes: { userId: string; userStatus: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addToGroup',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14644',
    }),
    suspendAccessButtonClicked: (attributes: { lastSeenOnSite?: string; userId: string; userStatus: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'suspendAccess',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33596',
    }),
    promptPasswordResetButtonClicked: (attributes: { userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'promptPasswordReset',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33732',
    }),
    removeUserButtonClicked: (attributes: { userId: string; userStatus: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeUser',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33960',
    }),
    restoreAccessButtonClicked: (attributes: { lastSeenOnSite?: string; userId: string; userStatus: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resumeAccess',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33597',
    }),
    resetFiltersButtonClicked: (attributes: { source?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resetFilters',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75011',
    }),
    userTablefilteredEmptyViewShown: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.EmptyTable,
        actionSubjectId: 'noUsersScreen',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74825',
    }),
    userListFilterRoleChanged: (attributes: { roleFilterOption: (PlatformRole | UserProductRole)[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Option,
        actionSubjectId: 'roleFilter',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74928',
    }),
    userListFilterStatusChanged: (attributes: { statusFilterOption: UserStatus[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Option,
        actionSubjectId: 'statusFilter',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74986',
    }),
    userListFilterProductAccessChanged: (attributes: { productAccessFilterOption: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Option,
        actionSubjectId: 'productAccessFilter',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74988',
    }),
    userListFilterAccountTypeChanged: (attributes: { accountTypeOptions: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Option,
        actionSubjectId: 'accountTypeFilter',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/78851',
    }),
    userListFilterDomainChanged: (attributes: { domainOptions: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Option,
        actionSubjectId: 'domainFilter',
        source: unifiedUserListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/78852',
    }),
    addUserToGroupsModalConfirmClicked: (
      source: UULSource,
      attributes: {
        groupIds: string[];
        userId: string;
        userStatus?: string;
        workspaceIds?: string[];
        productIds?: string[];
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGroupsToUserConfirm',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40221',
    }),
    assignOrganizationRoleConfirm: (attributes: { userId: string; orgId: string }, source: string): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'assignOrganizationRoleConfirm',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40606',
    }),
    openAddProductsModal: (attributes: { userId: string; orgId: string }, source: string): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModal',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40604',
    }),
    menuDropdownOpenSiteAdminModal: (attributes: VortexSiteAdminAttributes, source: string): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteAdminMenu',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66692',
    }),
  },
  track: {
    userListExportDownloaded: (): TrackEvent => ({
      data: {
        action: TrackAction.UserlistDownloaded,
        actionSubject: TrackActionSubject.Export,
        actionSubjectId: 'exportUsersActions',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75163',
    }),
    grantedOrgAdminAccess: (attributes: { userId: string; orgId: string }, source: string): TrackEvent => ({
      data: {
        action: TrackAction.Granted,
        actionSubject: TrackActionSubject.OrgAdminAccess,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34886',
    }),
    revokedOrgAdminAccess: (attributes: { userId: string; orgId: string }, source: string): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.OrgAdminAccess,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34887',
    }),
  },
  operational: {
    usersLastActiveDataFetchError: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Error,
        actionSubject: OperationalSubject.UsersLastActiveDataFetch,
        actionSubjectId: 'lastActiveColumn',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75347',
    }),
    userListLastActiveSuccess: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Succeeded,
        actionSubject: OperationalSubject.UsersLastActiveDataFetch,
        actionSubjectId: 'lastActiveSuccess',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75401',
    }),
    usersStatsDataFetchError: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Error,
        actionSubject: OperationalSubject.UsersStatsDataFetch,
        actionSubjectId: 'usersSummaryBlocks',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75345',
    }),
    usersStatsDataFetchTaskSuccess: (): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskSuccess,
        actionSubject: OperationalSubject.UsersStatsDataFetch,
        actionSubjectId: 'usersSummaryBlocks',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75346',
    }),
    userListTableSearchTaskStart: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Started,
        actionSubject: OperationalSubject.GraphQL,
        actionSubjectId: 'search userListTable taskStart',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75405',
    }),
    userListTableSearchTaskSuccess: (): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskSuccess,
        actionSubject: OperationalSubject.GraphQL,
        actionSubjectId: 'search userListTable taskSuccess',
        source: unifiedUserListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75406',
    }),
  },
};
