import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'confirmAddIdentityProviderClickEvent'
  | 'cancelAddIdentityProviderClickEvent'
  | 'saveSamlConfigurationClickEvent'
  | 'deleteSamlConfigurationClickEvent'
  | 'viewSamlConfigurationClickEvent'
  | 'directoryNameClickEvent'
  | 'setupIdpSamlClickEvent'
  | 'disconnectIdentityProviderClickEvent'
  | 'cancelDisconnectIdpClickEvent';

// TODO: Replace anys with string literal keys after moving common screen, track, and operational events here
export const sharedIdentityProvidersEvents: FeatureEvents<any, UIEventKeys, any, any> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2545447819/JSM+SAML+SSO+ET+Analytics+Spec',
  screen: {},
  ui: {
    confirmAddIdentityProviderClickEvent: (attributes: { idpType: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmAddIdentityProvider',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42775',
    }),
    cancelAddIdentityProviderClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelAddIdentityProvider',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42776',
    }),
    saveSamlConfigurationClickEvent: (attributes: { idpId: string; routeId?: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveSamlConfiguration',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43909',
    }),
    deleteSamlConfigurationClickEvent: (attributes: { idpId: string; routeId?: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteSamlConfiguration',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43914',
    }),
    viewSamlConfigurationClickEvent: (attributes: { idpId: string; routeId?: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewSamlConfiguration',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43746',
    }),
    directoryNameClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'directoryName',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42736',
    }),
    setupIdpSamlClickEvent: (attributes: { isFocusedTask: boolean }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'setupIdpSaml',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42777',
    }),
    disconnectIdentityProviderClickEvent: (attributes: { idpId: string; routeId?: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectIdentityProvider',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43743',
    }),
    cancelDisconnectIdpClickEvent: (attributes: { idpId: string; routeId?: string }, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDisconnectIdp',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43865',
    }),
  },
  track: {},
  operational: {},
};
