import type { ReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client';

import type { ActionType as AkActionType, AppearanceTypes as AkAppearanceTypes } from '@atlaskit/flag/types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Flags {
  export interface FlagDescriptor {
    id: string;
    title: React.ReactNode;
    description?: React.ReactNode;
    icon: React.ReactNode;
    appearance?: AkAppearanceTypes;
    actions?: AkActionType[] | undefined;
    /**
     * Automatically dismiss the flag after 8 seconds
     */
    autoDismiss?: boolean;
    /**
     * Automatically dismiss the flag when the user navigates away from where the flag was shown
     */
    autoDismissOnRedirect?: boolean;
    onClose?(): void;

    // Do not use. This function is never called. Use onClose instead.
    onDismissed?(): void;
  }
  export type FlagReactiveVariableType = ReactiveVar<FlagDescriptor[]>;

  export interface FlagReactiveVariables {
    flags: FlagReactiveVariableType;
  }
  export const reactiveVariables: Readonly<FlagReactiveVariables> = {
    flags: makeVar<FlagDescriptor[]>([]),
  };

  export const showFlag = (newFlag: FlagDescriptor) => {
    const flagVar = reactiveVariables.flags;
    const hasFlag = flagVar().find((flag) => flag.id === newFlag.id);

    if (hasFlag) {
      return;
    }

    flagVar([...flagVar(), newFlag]);
  };

  export const hideFlag = (flagId: string, skipOnClose?: boolean) => {
    const flagVar = reactiveVariables.flags;
    const newMap = flagVar().filter((flag) => flag.id !== flagId);

    // Return if there are no flags found of that flag id.
    if (newMap.length === flagVar().length) {
      return;
    }

    // Calls "onClose" for flags that are being hidden, only if skipOnClose is not true.
    if (!skipOnClose) {
      flagVar().forEach((flag) => {
        if (flag.id === flagId && typeof flag.onClose === 'function') {
          flag.onClose();
        }
      });
    }

    flagVar(newMap);
  };

  // Hide Flags start with prefix
  export const hideFlagsStartingWith = (prefix: string, skipOnClose?: boolean) => {
    const flagVar = reactiveVariables.flags;
    const newMap = flagVar().filter((flag) => !flag.id.startsWith(prefix));

    // Return if there are no flags found of that flag id.
    if (newMap.length === flagVar().length) {
      return;
    }

    // Calls "onClose" for flags that are being hidden, only if skipOnClose is not true.
    if (!skipOnClose) {
      flagVar().forEach((flag) => {
        if (flag.id.startsWith(prefix) && typeof flag.onClose === 'function') {
          flag.onClose();
        }
      });
    }

    flagVar(newMap);
  };
}
