import AkFeatureGates from '@atlaskit/feature-gate-js-client';

import { UiProductType } from '@adminhub/graphql-types';
import type { ProductKeyConverterValues } from '@adminhub/schema/src/api/model/product-key-converter';
import { ProductKeyConverter } from '@adminhub/schema/src/api/model/product-key-converter';

import { loadProductConfiguration } from '../types';
import type { ProductConfig, ProductConfigInputOptions, ProductConfigJson } from '../types';
import { logProductKeyConverterUsed } from './utils';

export class ProductConfigImpl implements ProductConfig {
  /**
   * Derived properties that are optional in the JSON
   */
  private readonly derived: {
    userManagementProductId: string;
    idOrgManagerProductKey: string;
    emauProductKey: string;
    ariResourceOwner: string;
    userManagementCanonicalProductKey: string;
    sandboxProductKey: string;
    releaseTrackProductKey: string;
  };

  // CLEANUP TODO: Used for fallback. Remove this after finishing migration
  private readonly _productKeyConverter: ProductKeyConverter;

  constructor(private readonly json: ProductConfigJson) {
    // The default value is a lower case version of the type with underscores replaced by dashes.
    const defaultValue = this.json.type.toLowerCase().replaceAll('_', '-');

    // TODO: Migrate other product properties
    this.derived = {
      userManagementProductId: this.json.userManagementProductId || defaultValue,
      idOrgManagerProductKey: this.json.idOrgManagerProductKey || defaultValue,
      emauProductKey: this.json.emauProductKey || defaultValue,
      ariResourceOwner: this.json.ariResourceOwner || defaultValue,
      userManagementCanonicalProductKey: this.json.userManagementCanonicalProductKey || defaultValue,
      sandboxProductKey: this.json.sandboxProductKey || defaultValue,
      releaseTrackProductKey: this.json.releaseTrackProductKey || defaultValue,
    };

    this._productKeyConverter = new ProductKeyConverter({
      canonicalProductKey: undefined,
      key: undefined,
      productId: undefined,
      type: this.json.type,
    });
  }

  get type() {
    if (!this.json.type) {
      throw new Error('type is not defined');
    }

    return this.json.type;
  }

  get productName() {
    if (!this.json.productName) {
      throw new Error('productName is not defined');
    }

    return this.json.productName;
  }

  get productId() {
    if ('userManagementProductId' in this.json && this.json.userManagementProductId === undefined) {
      throw new Error('userManagementProductId is not defined');
    }

    return this.json.userManagementProductId || this.derived.userManagementProductId;
  }

  get key() {
    if ('idOrgManagerProductKey' in this.json && this.json.idOrgManagerProductKey === undefined) {
      throw new Error('idOrgManagerProductKey is not defined');
    }

    return this.json.idOrgManagerProductKey || this.derived.idOrgManagerProductKey;
  }

  get canonicalProductKey() {
    if ('userManagementCanonicalProductKey' in this.json && this.json.userManagementCanonicalProductKey === undefined) {
      throw new Error('userManagementCanonicalProductKey is not defined');
    }

    return this.json.userManagementCanonicalProductKey || this.derived.userManagementCanonicalProductKey;
  }

  get ariKey() {
    if ('ariResourceOwner' in this.json && this.json.ariResourceOwner === undefined) {
      throw new Error('userManagementCanonicalProductKey is not defined');
    }

    return this.json.ariResourceOwner || this.derived.ariResourceOwner;
  }

  get emauKey() {
    if ('emauProductKey' in this.json && this.json.emauProductKey === undefined) {
      throw new Error('emauProductKey is not defined');
    }

    return this.json.emauProductKey || this.derived.emauProductKey;
  }

  get hamsProductKey() {
    return this._productKeyConverter.hamsProductKey;
  }

  get sandboxProductKey() {
    if ('sandboxProductKey' in this.json && this.json.sandboxProductKey === undefined) {
      throw new Error('sandboxProductKey is not defined');
    }

    return this.json.sandboxProductKey || this.derived.sandboxProductKey;
  }

  get releaseTrackProductKey() {
    if ('releaseTrackProductKey' in this.json && this.json.releaseTrackProductKey === undefined) {
      throw new Error('releaseTrackProductKey is not defined');
    }

    return this.json.releaseTrackProductKey || this.derived.releaseTrackProductKey;
  }

  get isUiProductType() {
    return this._productKeyConverter.isUiProductType;
  }

  get uiProductType() {
    return this._productKeyConverter.uiProductType;
  }
}

interface ProductMappings {
  productTypeToConfig: Record<UiProductType, ProductConfig> | {};
  productCanonicalKeyToConfig: Record<string, ProductConfig>;
  productIdToConfig: Record<string, ProductConfig>;
  productNameToConfig: Record<string, ProductConfig>;
  productKeyToConfig: Record<string, ProductConfig>;
}

/*
 * This function builds reverse mappings from the product config.
 * It is used to quickly lookup a product config by its type, canonicalProductKey, productId, productName, or key.
 * There are a couple of cases where mappings should not be built, or should be overridden.
 * These are handled by the if noMapping checks and the switch cases
 * The try catches are to handle the case where the productId is not defined when fetching them after the mapping
 * is built. In the original PKC they would error out when fetching, and we want to do the same here. But allow it to continue when
 * building the mappings.
 */
export function buildReverseMappings(): ProductMappings {
  const productConfigs = loadProductConfiguration().products;
  const mappings: ProductMappings = {
    productTypeToConfig: {},
    productCanonicalKeyToConfig: {},
    productIdToConfig: {},
    productNameToConfig: {},
    productKeyToConfig: {},
  };
  for (const productConfigJson of Object.values(productConfigs)) {
    const config = new ProductConfigImpl(productConfigJson);

    try {
      mappings.productTypeToConfig[config.type] = config;
    } catch (e) {
      continue;
    }

    // These are names from the PKC that don't map to any product through the fromName function
    // Jira Service Management maps to UiProductType.JIRA_SERVICE_DESK, but UiProductType.JIRA_SERVICE_DESK is not seen in types
    // Jira Align returns empty mapping in PKC
    // Despite the name change, there's no mapping in PKC from name to Atlassian Guard product type
    const productNamesWithNoMapping = [
      'Unknown Atlassian product',
      'Projects',
      'Goals',
      'Loom Attribution',
      'Jira Products',
      'Atlassian Analytics',
      'Jira Align',
      'Atlassian Guard Standard',
    ];

    if (!productNamesWithNoMapping.includes(config.productName)) {
      switch (config.productName) {
        case 'Jira Work Management':
          // Jira Work Management maps to Jira Core in the fromName function
          mappings.productNameToConfig[config.productName] = mappings.productTypeToConfig[UiProductType.JIRA_CORE];
          break;
        case 'Jira Service Management':
          // Jira Service Management maps to Jira Service Desk in the fromName function
          mappings.productNameToConfig[config.productName] = mappings.productTypeToConfig[UiProductType.JIRA_SERVICE_DESK];
          break;
        default:
          mappings.productNameToConfig[config.productName] = config;
          break;
      }
    }

    // These canonicalProductKeys from the PKC don't map to any product through the getProductType function
    const canonicalProductKeysWithNoMapping = ['jira-work-management', 'jira-service-management'];

    try {
      if (!canonicalProductKeysWithNoMapping.includes(config.canonicalProductKey)) {
        mappings.productCanonicalKeyToConfig[config.canonicalProductKey] = config;
      }
    } catch (e) {
      continue;
    }

    // These productIds from the PKC don't map to any product through the fromProductType function
    const productIdsWithNoMapping = ['jira-work-management'];

    try {
      if (!productIdsWithNoMapping.includes(config.productId)) {
        switch (config.productId) {
          case 'jira-servicedesk':
            // jira-servicedesk maps to JIRA_SERVICE_DESK in the fromKey function despite JIRA_SERVICE_MANAGEMENT having the same key
            mappings.productIdToConfig[config.productId] = mappings.productTypeToConfig[UiProductType.JIRA_SERVICE_DESK];
            break;
          default:
            mappings.productIdToConfig[config.productId] = config;
            break;
        }
      }
    } catch (e) {
      continue;
    }

    try {
      switch (config.key) {
        case 'jira-service-desk':
          // jira-service-desk maps to JIRA_SERVICE_DESK in the fromKey function despite JIRA_SERVICE_MANAGEMENT having the same key
          mappings.productKeyToConfig[config.key] = mappings.productTypeToConfig[UiProductType.JIRA_SERVICE_DESK];
          break;
        case 'jira-core':
          // jira-core maps to JIRA_CORE in the fromKey function despite JIRA_WORK_MANAGEMENT having the same key
          mappings.productKeyToConfig[config.key] = mappings.productTypeToConfig[UiProductType.JIRA_CORE];
          break;
        default:
          mappings.productKeyToConfig[config.key] = config;
          break;
      }
    } catch (e) {
      continue;
    }
  }

  return mappings;
}

const { productTypeToConfig, productCanonicalKeyToConfig, productIdToConfig, productNameToConfig, productKeyToConfig } =
  buildReverseMappings();

export function fromProductConfig(options: Partial<ProductConfigInputOptions>): ProductConfig {
  const isAdminHubConfigurationEnabled = AkFeatureGates.checkGate('adminhub_integration_simplified_m3');

  if (isAdminHubConfigurationEnabled) {
    if (options.type && options.type in productTypeToConfig) {
      return productTypeToConfig[options.type];
    }
    if (options.canonicalProductKey && options.canonicalProductKey in productCanonicalKeyToConfig) {
      return productCanonicalKeyToConfig[options.canonicalProductKey];
    }
    if (options.productId && options.productId in productIdToConfig) {
      return productIdToConfig[options.productId];
    }
    if (options.name && options.name in productNameToConfig) {
      return productNameToConfig[options.name];
    }
    if (options.key && options.key in productKeyToConfig) {
      return productKeyToConfig[options.key];
    }

    logProductKeyConverterUsed();

    // TODO: Implement other ways to retrieve product config (i.e. by key, productId, etc)
  }

  // CLEANUP TODO: For now fallback to the original ProductKeyConverter. Throw error if no product config is found
  return new ProductKeyConverter(options as ProductKeyConverterValues);
}
