import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

import { orgProductListScreen } from './user-management-event-data';

type UiKeys = 'enterpriseQuotingBannerCard' | 'whatsTeamworkCollectionTwcBanner';

export const twcBannerEvents: FeatureEvents<string, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4832211151/Analytics+Spec+TWC+View+Collection',
  screen: {},
  ui: {
    enterpriseQuotingBannerCard: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'enterpriseQuotingBannerCard',
        source: orgProductListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59169',
    }),
    whatsTeamworkCollectionTwcBanner: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'whatsTeamworkCollectionTwcBanner',
        source: orgProductListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79802',
    }),
  },
  track: {},
  operational: {},
};
