import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIEventKeys = 'clickedSubmitBetaOptIn' | 'toggledBetaOptOut' | 'clickedSubmitAiOptIn' | 'toggledAiOptOut';

type ScreenKeys = string;
type TrackEventKeys = string;
type OperationalEventKeys = string;

const avpScreen = 'avpScreen';

export const avpEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalEventKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AVP/pages/4945402783/Adminhub+AVP+Analytics',
  ui: {
    clickedSubmitBetaOptIn: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'avpBetaOptInModalSubmit',
        source: avpScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77512',
    }),
    toggledBetaOptOut: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'avpBetaOptOutToggle',
        source: avpScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77514',
    }),
    clickedSubmitAiOptIn: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'avpAiOptInModalSubmit',
        source: avpScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79229',
    }),
    toggledAiOptOut: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'avpAiOptOutToggle',
        source: avpScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79230',
    }),
  },
  operational: {},
  screen: {},
  track: {},
};
