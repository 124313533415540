import type { ScreenEvent, TrackData, TrackEvent, UIData } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

// https://data-portal.internal.atlassian.com/analytics/registry/71772
export const jsmPortalCustomersBulkDeleteModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'bulkDeleteModalSubmitButton',
  source: 'jsmPortalCustomersBulkDeleteAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const jsmPortalCustomersBulkDeleteModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelButton',
  source: 'jsmPortalCustomersBulkDeleteAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/71771
export const jsmPortalCustomersBulkDeleteModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsmPortalCustomersBulkDeleteAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/71854
export const jsmPortalCustomersBulkDeleteModalButtonEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'bulkDeleteAccountButton',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/71850
export const jsmPortalCustomersBulkMigrateAccountModalSubmitEvent = ({ customerCount }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'bulkMigrateAccountModalSubmitButton',
  source: 'jsmPortalCustomersBulkMigrateAccountModal',
  attributes: {
    customerCount,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/71851
export const jsmPortalCustomersBulkMigrateAccountModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'bulkMigrateAccountModalCancelButton',
  source: 'jsmPortalCustomersBulkMigrateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/71852
export const jsmPortalCustomersBulkMigrateAccountModalTrackEvent = ({ customerCount }): TrackData => ({
  action: TrackAction.BulkMigrated,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
  attributes: {
    customerCount,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/73690
export const jsmPortalCustomersBulkDeleteModalTrackEvent = ({ customerCount }): TrackData => ({
  action: TrackAction.BulkDeleted,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
  attributes: {
    customerCount,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/71849
export const jsmPortalCustomersBulkMigrateAccountModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsmPortalCustomersBulkMigrateAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/71853
export const jsmPortalCustomersBulkMigrateAccountButtonEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'bulkMigrateAccountButton',
  source: 'jsdPortalCustomersScreen',
});

export const jsmPortalCustomersSelectionLimitReached = (): TrackEvent => ({
  data: {
    action: TrackAction.Reached,
    actionSubject: TrackActionSubject.BulkSelectionLimit,
    source: 'jsdPortalCustomersScreen',
  },
  dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72738',
});

export const jsmPortalCustomersDeletedCustomerSelected = (): TrackEvent => ({
  data: {
    action: TrackAction.Selected,
    actionSubject: TrackActionSubject.DeletedCustomer,
    source: 'jsdPortalCustomersScreen',
  },
  dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72744',
});
