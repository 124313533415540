// past tense verbs describing the action
export const enum Action {
  Cancelled = 'cancelled',
  Clicked = 'clicked',
  Submitted = 'submitted',
  Closed = 'closed',
  Typed = 'typed',
  Searched = 'searched',
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
  Invited = 'invited',
  Selected = 'selected',
  Focused = 'focused',
  Blurred = 'blurred',
  Viewed = 'viewed',
  Shown = 'shown',
  Displayed = 'displayed',
  Hovered = 'hovered',
  Entered = 'entered',
  Switched = 'switched',
  Dwelled = 'dwelled',
  Toggled = 'toggled',
  Pressed = 'pressed',
  Pasted = 'pasted',
  Copy = 'copy',
  Changed = 'changed',
  Fetch = 'fetch',
  Cleared = 'cleared',
}

export const enum TrackAction {
  Canceled = 'canceled',
  ConnectionFailed = 'connectionFailed',
  Created = 'created',
  CreationFailed = 'creationFailed',
  Updated = 'updated',
  Activated = 'activated',
  Linked = 'linked',
  DefaultGroupUpdated = 'defaultGroupUpdated',
  Added = 'added',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
  Dismissed = 'dismissed',
  Duplicated = 'duplicated',
  CanceledDeletion = 'canceledDeletion',
  Changed = 'changed',
  ChangeFailed = 'changeFailed',
  Submitted = 'submitted',
  Approved = 'approved',
  Denied = 'denied',
  Invited = 'invited',
  InvitedGuest = 'invitedGuest',
  Reset = 'reset',
  Resent = 'resent',
  Impersonated = 'impersonated',
  Imported = 'imported',
  KeyRegenerated = 'keyRegenerated',
  Regenerated = 'regenerated',
  ChangedProductAccess = 'changedProductAccess',
  Toggled = 'toggled',
  Granted = 'granted',
  GrantedProductAccess = 'grantedProductAccess',
  Revoked = 'revoked',
  RevokedProductAccess = 'revokedProductAccess',
  Saved = 'saved',
  Sent = 'sent',
  AddedToGroup = 'addedToGroup',
  AddedUsers = 'addedUsers',
  Exposed = 'exposed',
  Downloaded = 'downloaded',
  Migrated = 'migrated',
  BulkMigrated = 'bulkMigrated',
  BulkSelectionLimit = 'bulkSelectionLimit',
  BulkDeleted = 'bulkDeleted',
  Verified = 'verified',
  Claimed = 'claimed',
  Unclaimed = 'unclaimed',
  Mismatched = 'mismatched',
  Connected = 'connected',
  Selected = 'selected',
  SyncStarted = 'syncStarted',
  SyncFinished = 'syncFinished',
  Synced = 'synced',
  GroupsUpdated = 'groupsUpdated',
  Disconnected = 'disconnected',
  Enabled = 'enabled',
  Disabled = 'disabled',
  SettingsUpdated = 'settingsUpdated',
  Exported = 'exported',
  ExportFailed = 'exportFailed',
  ClonedData = 'clonedData',
  ClonedSelectiveData = 'clonedSelectiveData',
  Completed = 'completed',
  Converted = 'converted',
  Reached = 'reached',
  RemoveMember = 'removeMember',
  RemoveFromOrg = 'removedFromOrg',
  PromptPassword = 'promptedResetPassword',
  DescriptionUpdated = 'descriptionUpdated',
  RemoveProduct = 'removeProduct',
  AddProduct = 'addProduct',
  AddProductFailed = 'addProductFailed',
  Restored = 'restored',
  RemovedDomain = 'removedDomain',
  Removed = 'removed',
  Reactivated = 'reactivated',
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  UsersUpdated = 'usersUpdated',
  Started = 'started',
  Searched = 'searched',
  SessionReset = 'sessionReset',
  RuleUpdated = 'ruleUpdated',
  Upload = 'Upload',
  OptedIntoExperiments = 'optedIntoExperiments',
  OptedOutOfExperiments = 'optedOutOfExperiments',
  UploadFailure = 'uploadFailure',
  Checked = 'checked',
  Triggered = 'triggered',
  Finished = 'finished',
  RemovedUser = 'removedUser',
  Errored = 'errored',
  UserlistDownloaded = 'userList downloaded',
}

export const enum ActionSubject {
  Table = 'table',
  Button = 'button',
  Form = 'form',
  Link = 'link',
  AppSwitcherDropdown = 'appSwitcherDropdown',
  AppSwitcherItem = 'appSwitcherItem',
  Organization = 'organization',
  OrganizationSelectorItem = 'organizationSelectorItem',
  BillingSelectorItem = 'billingSelectorItem',
  HelpSelectorItem = 'helpSelectorItem',
  Dialog = 'dialog',
  Datepicker = 'datepicker',
  Field = 'field',
  NavigationItem = 'navigationItem',
  Toggle = 'toggle',
  Checkbox = 'checkbox',
  DropListItem = 'dropListItem',
  DropListTrigger = 'dropListTrigger',
  RadioButton = 'radioButton',
  Radio = 'radio',
  TextField = 'textField',
  Text = 'text',
  Badge = 'badge',
  Dropdown = 'dropdown',
  DropdownItem = 'dropdownItem',
  SelectItem = 'selectItem',
  Error = 'error',
  Chart = 'chart',
  Page = 'page',
  Card = 'card',
  Tooltip = 'tooltip',
  Icon = 'icon',
  NotificationModal = 'notificationModal',
  Notification = 'notification',
  MenuItem = 'menuItem',
  UserPicker = 'userPicker',
  Accordion = 'accordion',
  Tab = 'tab',
  Modal = 'modal',
  Flag = 'flag',
  IphSideBar = 'iphSideBar',
  Input = 'input',
  SearchResult = 'searchResult',
  CallToAction = 'callToAction',
  ResendInvite = 'resendInvite',
  DisimssButton = 'dismissButton',
  EmptyTable = 'emptyTable',
  Row = 'row',
  Option = 'option',
  FilterController = 'filterController',
}

export const enum TrackActionSubject {
  Button = 'button',
  BulkSelectionLimit = 'bulkSelectionLimit',
  Customer = 'customer',
  DeletedCustomer = 'deletedCustomer',
  Dropdown = 'dropdown',
  Group = 'group',
  GroupName = 'groupname',
  Groups = 'groups',
  Member = 'member',
  Form = 'form',
  Token = 'token',
  User = 'user',
  LandingProduct = 'landingProduct',
  UserList = 'userList',
  UserPassword = 'userPassword',
  UserProvisioning = 'userProvisioning',
  Invitation = 'invitation',
  ProductAccess = 'productAccess',
  Organization = 'organization',
  CustomDomain = 'customDomain',
  AdminApiKey = 'adminApiKey',
  ApiKey = 'userApiKeys',
  Checkbox = 'checkbox',
  UserPermission = 'userPermission',
  SiteAccess = 'siteAccess',
  OrgAccess = 'orgAccess',
  OrgAdmin = 'orgAdmin',
  UserSiteAdminRole = 'userSiteAdminRole',
  SuggestChanges = 'suggestChanges',
  OrgAdminAccess = 'orgAdminAccess',
  Feature = 'feature',
  NewUserAccess = 'newUserAccess',
  InviteUrlLinkToggled = 'inviteUrlLinkToggled',
  InviteUrlLinkRegenerated = 'inviteUrlLinkRegenerated',
  Domain = 'domain',
  MemberApiToken = 'memberApiToken',
  OktaAccount = 'oktaAccount',
  Accounts = 'accounts',
  SessionDuration = 'sessionDuration',
  SiteUrl = 'siteUrl',
  FeatureFlag = 'featureFlag',
  Origin = 'origin', // https://hello.atlassian.net/wiki/spaces/PGT/pages/267613380/Origin+ID+Tracing
  ApprovedDomains = 'approvedDomainsSettings',
  UserInvite = 'userInvitesSettings',
  InviteLinks = 'inviteLinksSettings',
  AccessRequests = 'accessRequestsSettings',
  OrgGSuiteAccount = 'orgGSuiteAccount',
  SiteGSuiteAccount = 'siteGSuiteAccount',
  Sandbox = 'sandbox',
  TwoStepVerification = 'twoStepVerification',
  ThirdPartyAccounts = 'thirdPartyAccounts',
  ReleaseTracks = 'releaseTracks',
  DataSecurityPolicies = 'dataSecurityPolicy',
  IpAllowlist = 'allowlist',
  MobileDevicePolicy = 'mobileDevicePolicy',
  AuthPolicy = 'authPolicy',
  AuthPolicyType = 'authPolicyType',
  MembersAuthPolicy = 'membersAuthPolicy',
  Instance = 'instance',
  DataResidency = 'dataResidency',
  DataResidencyApps = 'dataResidencyApps',
  UserInviteSetting = 'userInviteSetting',
  NotAllowedNoteSetting = 'notAllowedNoteSetting',
  ForgeLogsPreview = 'forgeLogsPreview',
  DisableForgeLogsAccess = 'disableForgeLogsAccess',
  EnableForgeLogsAccess = 'enableForgeLogsAccess',
  ApprovedDomain = 'approvedDomain',
  AccessSetting = 'accessSetting',
  plan = 'plan',
  EmailAddress = 'emailAddress',
  ApplicationTunnel = 'applicationTunnel',
  AuditLogActivitySettings = 'auditLogActivitySettings',
  IdentityProvider = 'identityProvider',
  SamlConfiguration = 'samlConfiguration',
  SlackConnect = 'slackConnect',
  SlackWorkspace = 'slackWorkspace',
  OSyncAccount = 'oSyncAccount',
  ExternalUsers = 'externalUsers',
  ExternalUserPolicy = 'externalUserPolicy',
  ClaimManagedAccounts = 'claimManagedAccounts',
  UnclaimManagedAccounts = 'unclaimManagedAccounts',
  ProductUpdatesFilter = 'productUpdatesFilter',
  ProductUpdatesEmailSubsciption = 'productUpdatesEmailSubsciption',
  MemberSessionToken = 'memberSessionToken',
  MemberOauthToken = 'memberOauthToken',
  SelectiveClaimSetting = 'selectiveClaimSetting',
  SelectiveAccounts = 'selectiveAccounts',
  SelectiveDomain = 'selectiveDomain',
  ActiveUsersByProduct = 'activeUsersByProduct',
  ApiTokenPolicies = 'apiTokenPolicies',
  ProductActivation = 'productActivation',
  ProductRequest = 'productRequest',
  EmailNotification = 'emailNotification',
  ProductSetting = 'productSetting',
  ProductStatus = 'productStatus',
  SearchNoResults = 'searchNoResults',
  SearchDropdownEntry = 'searchDropdownEntry',
  SearchDropdownNoAction = 'searchDropdownNoAction',
  SearchSomeResults = 'searchSomeResults',
  SearchErrorEncountered = 'searchErrorEncountered',
  SiteFilter = 'siteFilter',
  HipaaCompliance = 'hipaaCompliance',
  BaaSignIn = 'baaSignIn',
  AccessTrial = 'accessTrial',
  DataClassificationLevel = 'dataClassificationLevel',
  UserApiTokens = 'userApiTokens',
  DiscoveredProducts = 'discoveredProducts',
  Townsquare = 'townsquare',
  RovoBeta = 'rovoBeta',
  MigrationTask = 'migrationTask',
  MigrationJob = 'migrationJob',
  ServerToCloudTask = 'serverToCloudTask',
  ReportFilter = 'reportFilter',
  OrganizationSetting = 'organizationSetting',
  ZipFile = 'zipFile',
  RovoSearchConnection = 'rovoSearchConnection',
  AgpStandardSignup = 'guardStandardTrial',
  AgpPremiumSignup = 'guardPremiumTrial',
  AdminSearchNavigation = 'adminSearchNavigation',
  Eligibility = 'eligibility',
  RovoSearchPin = 'rovoSearchPin',
  RovoSearchPinKeyPhrase = 'rovoSearchPinKeyPhrase',
  RovoGA = 'rovoGA',
  RovoSite = 'rovoSite',
  AIRemovedSurvey = 'atlassianIntelligenceRemovedSurvey',
  AIRemovedSurveyVariant = 'submittedAIRemovedSurveyTrackEventVariant',
  AIRemovedSurveyControl = 'submittedAIRemovedSurveyTrackEventControl',
  Team = 'team',
  RovoConnector = 'rovoConnector',
  RelatedArticles = 'relatedArticles',
  ContactSupport = 'contactSupport',
  SearchResults = 'searchResults',
  PlanDraft = 'planDraft',
  Checks = 'checks',
  Export = 'export',
  RemovalUser = 'removalUser',
  GoalsAndProjectsSetting = 'goalsAndProjectsSetting',
  RovoSearchBookmarkImportSubmission = 'rovoSearchBookmarkImportSubmission',
  TWGConnector = 'twgConnector',
  RovoBookmarkSuggestion = 'rovoBookmarkSuggestion',
}

export const enum Source {
  BillEstimate = 'billEstimate',
  BillHistory = 'billHistory',
  BillOverview = 'billOverview',
  DiscoverNewProducts = 'discoverNewProducts',
  ManageSubscriptions = 'manageSubscriptions',
  PaymentDetails = 'paymentDetails',
  AdminSearchDialog = 'adminSearchDialog',
}

export const enum OperationalAction {
  Fetched = 'fetched',
  Exposed = 'exposed',
  Triggered = 'triggered',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Aborted = 'aborted',
  Started = 'started',
  Changed = 'changed',
  Done = 'done',
  Exceeded = 'exceeded',
  Deleted = 'deleted',
  Connected = 'connected',
  Viewed = 'viewed',
  Evaluated = 'evaluated',
  Error = 'error',
  Collected = 'collected',
  TaskSuccess = 'taskSuccess',
  TaskFail = 'taskFail',
}

export const enum OperationalSubject {
  GraphqlSever = 'graphqlServer',
  Experiment = 'experiment',
  SiteUrlError = 'siteUrlError',
  DeeplinkedAccessRequest = 'deeplinkedAccessRequest',
  ProductAccess = 'productAccess',
  IdentityProvider = 'identityProvider',
  Circle = 'circle',
  PickLoader = 'pick-loader',
  Feature = 'feature',
  Context = 'context',
  Navigation = 'navigation',
  UserLicense = 'userLicense',
  SlackImportSucceeded = 'slackImportSucceeded',
  InviteUsers = 'inviteUsers',
  ThirdPartyIntegration = 'thirdPartyIntegration',
  ThirdPartyUsers = 'thirdPartyUsers',
  LoadRecaptchaEnterprise = 'loadRecaptchaEnterprise',
  SiteSelectionDropdownError = 'siteSelectionDropdownError',
  FetchNotification = 'fetchNotification',
  MarkNotificationGroupAsRead = 'markNotificationGroupAsRead',
  InvalidAuditLogsUGCPolicy = 'invalidAuditLogsUGCPolicy',
  PendingAccessRequests = 'pendingAccessRequests',
  OrgPolicies = 'orgPolicies',
  RovoSearchPinFetch = 'rovoSearchPinFetch',
  RovoSearchPinAdd = 'rovoSearchPinAdd',
  RovoSearchPinEdit = 'rovoSearchPinEdit',
  RovoSearchPinDelete = 'rovoSearchPinDelete',
  GraphQL = 'graphql',
  SearchError = 'searchError',
  RelatedArticlesError = 'relatedArticlesError',
  ArticleFetchError = 'articleFetchError',
  SiteUsersNumber = 'siteUsersNumber',
  ManualUpgradeDataFetch = 'manualUpgradeDataFetch',
  ManualUpgradeBannerRender = 'manualUpgradeBannerRender',
  ReleaseControlsChangeDelay = 'releaseControlsChangeDelay',
  DeferRolloutOptIn = 'deferRolloutOptIn',
  UsersStatsDataFetch = 'usersStatsDataFetch',
  UsersLastActiveDataFetch = 'usersLastActiveDataFetch',
}

// Remember to update capability-event-data.ts with the data portal link for these capabilities
export const enum CapabilitySubject {
  DomainsViewed = 'domainsViewed',
  OrgInsightsViewed = 'orgInsightsViewed',
  ManagedAccountDetailsViewed = 'managedAccountDetailsViewed',
  ManagedAccountsViewed = 'managedAccountsViewed',
  AuditLogViewed = 'auditLogViewed',
  ManagedAccountPasswordReset = 'managedAccountPasswordReset',
  SiteAccountsViewed = 'siteAccountsViewed',
  TwoStepVerificationEnabled = 'twoStepVerificationEnabled',
  TwoStepVerificationDisabled = 'twoStepVerificationDisabled',
  SamlConfigured = 'samlConfigured',
  ManagedAccountApiTokenRevoked = 'managedAccountApiTokenRevoked',
  ManagedAccountDeactivated = 'managedAccountDeactivated',
  ManagedAccountEdited = 'managedAccountEdited',
  DomainVerified = 'domainVerified',
  IpAllowlistCreated = 'ipAllowlistCreated',
  IpAllowlistUpdated = 'ipAllowlistUpdated',
  IpAllowlistDeleted = 'ipAllowlistDeleted',
  MobileSecurityCreated = 'mobileSecurityPolicyCreated',
  MobileSecurityUpdated = 'mobileSecurityPolicyUpdated',
  MobileSecurityDeleted = 'mobileSecurityPolicyDeleted',
  DataResidencyViewed = 'dataResidencyViewed',
  DataResidencyChanged = 'dataResidencyChanged',
  DataResidencyCanceled = 'dataResidencyCanceled',
  ReleaseTracksViewed = 'releaseTracksViewed',
  ReleaseTrackChanged = 'releaseTrackChanged',
  ReleaseTracksChangelogsViewed = 'releaseTracksChangelogsViewed',
  SandboxesViewed = 'sandboxesViewed',
  AuthPolicyDeleted = 'authPolicyDeleted',
  AuthPolicyDefaultPolicyChanged = 'authPolicyDefaultPolicyChanged',
  AuthPolicyCreated = 'authPolicyCreated',
  AuthPolicyMembersTabViewed = 'authPolicyMembersTabViewed',
  AuthPoliciesListPageViewed = 'authPoliciesListPageViewed',
  AuthPolicySettingsTabViewed = 'authPolicySettingsTabViewed',
  AuthPolicyAddMembers = 'authPolicyAddMembers',
  AuthPolicyAddMembersWithCSV = 'authPolicyAddMembersWithCSV',
  AuthPolicyUpdated = 'authPolicyUpdated',
  AddProductCreated = 'addProductCreated',
  AuthPoliciesEnabledChecked = 'authPoliciesEnabledChecked',
  AuthPolicyTaskPollingCompleted = 'authPolicyTaskPollingCompleted',
  DiscoveredProductsViewed = 'discoveredProductsViewed',
  AuthPolicyMembersExportInitiated = 'authPolicyMembersExportInitiated',
  ExportDownloaded = 'exportDownloaded',
  AuthPolicyResetSessions = 'authPolicyResetSessions',
  OrgInviteUser = 'orgInviteUser',
  OrgGrantProductAccessUserDetails = 'orgGrantProductAccessUserDetails',
  AuditLogActivitySettingsViewed = 'auditLogActivitySettingsViewed',
  AuditLogActivitySettingsChanged = 'auditLogActivitySettingsChanged',
  IdentityProviderCreated = 'identityProviderCreated',
  OrgGroupDetailsViewed = 'orgGroupDetailsViewed',
  OrgRevokeProductAccessGroupDetails = 'orgRevokeProductAccessGroupDetails',
  OrgRemoveUserFromGroup = 'orgRemoveUserFromGroup',
  OrgAssignPlatformRole = 'orgAssignPlatformRole',
  OrgAddUserToGroupModal = 'orgAddUserToGroupModal',
  GroupListViewed = 'groupListViewed',
  OrgGrantProductAccessGroupDetails = 'orgGrantProductAccessGroupDetails',
  DirectoryGrantProductAccessGroupDetails = 'directoryGrantProductAccessGroupDetails',
  IdentityProviderDeleted = 'identityProviderDeleted',
  OrgProductDetailsViewed = 'orgProductDetailsViewed',
  OrgUserListViewed = 'orgUserListViewed',
  OrgRevokeProductAccessUserDetails = 'orgRevokeProductAccessUserDetails',
  OrgRevokePlatformRole = 'orgRevokePlatformRole',
  OrgCreateGroup = 'orgCreateGroup',
  UserDetailsViewed = 'userDetailsViewed',
  OrgDeleteGroup = 'orgDeleteGroup',
  ExternalUserExportInitiated = 'externalUsersExportInitiated',
  ExternalUserPolicyCreated = 'externalUserPolicyCreated',
  ExternalUserPolicyUpdated = 'externalUserPolicyUpdated',
  ExternalUserApiTokenPolicyUpdated = 'externalUserApiTokenPolicyUpdated',
  OrgImpersonateUser = 'orgImpersonateUser',
  OrgRemoveUser = 'orgRemoveUser',
  AddUserToGroupDetails = 'addUserToGroupDetails',
  OrgActivateUser = 'orgActivateUser',
  OrgDeactivateUser = 'orgDeactivateUser',
  OrgUpdateGroupAsDefault = 'orgUpdateGroupAsDefault',
  LicenseUtilizationViewed = 'licenseUtilizationViewed',
  OrgPromptResetPassword = 'orgPromptResetPassword',
  OrgAddGroupToProductDetails = 'orgAddGroupToProductDetails',
  OrgSuggestChangesToUserDetails = 'orgSuggestChangesToUserDetails',
  ContactsViewed = 'contactsViewed',
  ProductUpdatesViewed = 'productUpdatesViewed',
  ProductUpdateDetailsViewed = 'productUpdateDetailsViewed',
  ProductUpdatesEmailSubscribe = 'productUpdatesEmailSubscribe',
  ProductUpdatesReleaseDatesViewed = 'productUpdatesReleaseDatesViewed',
  UserConnectedAppsViewed = 'singleAppListingUserConnectedAppsViewed',
  UserConnectedAppsDetailsPageViewed = 'userConnectedAppsDetailsPageViewed',
  ManagedAccountSessionTokenRevoked = 'managedAccountSessionTokenRevoked',
  WorkspacesViewed = 'workspacesViewed',
  OpsgenieJSMMerged = 'opsgenieJSMMerged',
  OrgBillingPreviewViewed = 'orgBillingPreviewViewed',
  SiteBillingPreviewViewed = 'siteBillingPreviewViewed',
  apiTokenPolicyUpdated = 'apiTokenPolicyUpdated',
  FeatureGatesClientInitialized = 'featureGatesClientInitialized',
  ExternalUserPolicySessionReset = 'externalUserPolicySessionReset',
  ExternalUserPolicySessionDurationUpdated = 'externalUserPolicySessionDurationUpdated',
  DataSecurityPoliciesPublicLinksRuleUpdated = 'dataSecurityPoliciesPublicLinksRuleUpdated',
  rovoActivatedSitesViewed = 'rovoActivatedSitesViewed',
  thirdPartyConnectorViewed = 'thirdPartyConnectorViewed',
  ExperimentControlsToggleOptIn = 'ExperimentControlsToggleOptIn',
  ExperimentControlsPageViewed = 'ExperimentControlsPageViewed',
}

export interface DataType {
  action: Action;
  actionSubject: ActionSubject;
  actionSubjectId: string;
  source: string;
}

// Extracted types from FeatureEvents below to typecheck analytics function in rendered component as needed
export type FeatureEventUi = (attributes?: Record<string, any>, data?: Partial<DataType>) => UIEvent;

export interface FeatureEvents<
  TScreenKeys extends string,
  TUiKeys extends string,
  TTrackKeys extends string,
  TOperationalKeys extends string,
> {
  confluenceSpec: string | string[];
  screen: Record<TScreenKeys, (attributes?: Record<string, any>) => ScreenEvent>;
  ui: Record<TUiKeys, FeatureEventUi>;
  track: Record<TTrackKeys, (attributes?: Record<string, any>) => TrackEvent>;
  operational: Record<TOperationalKeys, (attributes?: Record<string, any>) => OperationalEvent>;
}

export interface UIEvent {
  cloudId?: string;
  orgId?: string;
  tenantId?: string;
  tenantIdType?: 'orgId' | 'cloudId';
  data: UIData;
  dataPortalLink?: string;
}

export interface ScreenEvent {
  cloudId?: string;
  orgId?: string;
  data: ScreenData;
  dataPortalLink?: string;
}

export interface TrackEvent {
  cloudId?: string;
  orgId?: string;
  data: TrackData;
  dataPortalLink?: string;
}

export interface OperationalEvent {
  cloudId?: string;
  orgId?: string;
  data: OperationalData;
  dataPortalLink?: string | string[];
}

export type UIData = Readonly<{
  action: Action;
  actionSubject: ActionSubject;
  actionSubjectId?: string;
  source: string;
  objectType?: string;
  objectId?: string;
  object?: string;
  container?: string;
  attributes?: Record<string, any>;
  tags?: object;
  product?: string;
  eventType?: string;
}>;

export type ScreenData = Readonly<{
  name: string;
  attributes?: Record<string, any>;
  tags?: object;
  product?: string;
  platform?: string;
}>;

export type TrackData = Readonly<{
  source: string;
  action: TrackAction | string;
  actionSubject: TrackActionSubject;
  actionSubjectId?: string;
  attributes?: Record<string, any>;
  tags?: object;
}>;

export type OperationalData = Readonly<{
  action: OperationalAction;
  actionSubject: OperationalSubject | CapabilitySubject;
  actionSubjectId?: string;
  source: string;
  attributes?: Record<string, any>;
  tags?: object;
}>;

export type EngagekitEvent = Readonly<{
  payload: TrackData;
}>;
