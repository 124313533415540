import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export const ahaReportScreen = 'ahaReportScreen';
export const jiraUsageReportScreen = 'jiraUsageReportScreen';
export const jiraIssueReportScreen = 'jiraIssueReportScreen';
export const mobileInsightsReportScreen = 'mobileInsightsReportScreen';
export const organizationOverviewScreen = 'organizationOverviewScreen';
const userCountsReportsScreen = 'userCountsReportsScreen';

// Define reportId mapping to screen here if desired
export const reportIdToScreenMap = {
  activeUsers: organizationOverviewScreen,
  pendingAccessRequests: organizationOverviewScreen,
  userBaseCompositionReport: organizationOverviewScreen,
  jiraAdoption: jiraUsageReportScreen,
  jiraIssueActivity: jiraIssueReportScreen,
  mobileInsights: mobileInsightsReportScreen,
  userCounts: userCountsReportsScreen,
  userCountsGuard: userCountsReportsScreen,
};

interface ahaReportScreenEventAttributes {
  hasData?: boolean;
  isVortex?: boolean;
  reportId: string;
}

interface JiraReportTrackEventAttributes {
  hasData: boolean;
  selected: string;
  reportId: string;
  filterType: string;
  filterName: string;
}

type ScreenKeys = 'ahaReportScreenEvent';
type UiKeys =
  | 'jiraUsageResendJiraInvites'
  | 'jiraIssueAgileWorks'
  | 'mobileInsightsInfo'
  | 'ahaReportActionButton'
  | 'ahaReportFilter'
  | 'ahaReportDatapointHover'
  | 'ahaReportTitleTooltip'
  | 'jiraQuickView';
type TrackEventKeys = 'updateReportFilterTrackEvent';

export const ahaEvents: FeatureEvents<ScreenKeys, UiKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/3808073480/AdminHub+Analytics+AHA+Platform+Analytics+event',
  screen: {
    ahaReportScreenEvent: (attributes: ahaReportScreenEventAttributes): ScreenEvent => ({
      data: {
        name: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67852', // JiraUsage: /67820, JiraIssue: /67819, MobileInsights: /69716
    }),
  },
  ui: {
    jiraUsageResendJiraInvites: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'resendJiraInvites',
        source: jiraUsageReportScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67823',
    }),
    jiraIssueAgileWorks: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'agileWorks',
        source: jiraIssueReportScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67827',
    }),
    mobileInsightsInfo: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'mobileInsightsInfo',
        source: mobileInsightsReportScreen,
        attributes: { reportId: 'mobileInsights' },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69717',
    }),
    ahaReportActionButton: (attributes: { reportId: string; showCtaAtBottom: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reportAction',
        source: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67850',
    }),
    ahaReportFilter: (attributes: { selected: string; reportId: string; filterType: string; filterName: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'reportFilter',
        source: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67851',
    }),
    ahaReportDatapointHover: (attributes: { reportId: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'datapoint',
        source: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67825',
    }),
    ahaReportTitleTooltip: (attributes: { reportId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'titleTooltip',
        source: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68931',
    }),
    jiraQuickView: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'jiraQuickView',
        source: jiraIssueReportScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69308',
    }),
  },
  track: {
    updateReportFilterTrackEvent: (attributes: JiraReportTrackEventAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ReportFilter,
        source: reportIdToScreenMap[attributes.reportId] || ahaReportScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67862',
    }),
  },
  operational: {},
};
