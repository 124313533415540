import type { UiProductType, WorkspacePlan } from '@adminhub/graphql-types';

import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { workspacesSources } from './workspaces-event-data';

type UiKeys =
  | 'searchedWorkspaceTable'
  | 'selectedProductsFilterOption'
  | 'selectedPlansFilterOption'
  | 'clickedResetFilters'
  | 'clickedProductFiltersClearSelection'
  | 'searchedProductFilters'
  | 'clickedPlansFiltersClearSelection'
  | 'searchedPlansFilters';

export const workspacesFilterEvents: FeatureEvents<string, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1554188465/Workspace+Filter+Analytics',

  screen: {},
  ui: {
    searchedWorkspaceTable: ({
      source,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'workSpacesSearchQuery',
        source,
        attributes: {
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42858',
    }),
    selectedProductsFilterOption: ({
      source,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'workSpacesProductsFilter',
        source,
        attributes: {
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42859',
    }),
    selectedPlansFilterOption: ({
      source,
      hasQuery,
      productsFilter,
      plansFilter,
    }: {
      source: workspacesSources;
      hasQuery: boolean;
      productsFilter?: UiProductType[];
      plansFilter?: WorkspacePlan[];
    }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'workSpacesPlanFilter',
        source,
        attributes: {
          hasQuery,
          productsFilter,
          plansFilter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42860',
    }),
    clickedResetFilters: ({ source }: { source: workspacesSources }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesFilterReset',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42861',
    }),
    clickedProductFiltersClearSelection: ({ source, totalProducts }: { source: workspacesSources; totalProducts: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesProductsFilterClearSelection',
        source,
        attributes: {
          totalProducts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42862',
    }),
    searchedProductFilters: ({ source, totalProducts }: { source: workspacesSources; totalProducts: number }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'workSpacesProductsFilterSearch',
        source,
        attributes: {
          totalProducts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42864',
    }),
    clickedPlansFiltersClearSelection: ({ source, totalPlans }: { source: workspacesSources; totalPlans: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'workSpacesPlanFilterClearSelection',
        source,
        attributes: {
          totalPlans,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42863',
    }),
    searchedPlansFilters: ({ source, totalPlans }: { source: workspacesSources; totalPlans: number }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'workSpacesPlanFilterSearch',
        source,
        attributes: {
          totalPlans,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42865',
    }),
  },
  track: {},
  operational: {},
};
