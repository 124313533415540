import type { LoadableComponent } from 'react-loadable';

import { PickPageLoader } from '@adminhub/loading';
import { RouteNames } from '@adminhub/ufo';

// hint: Do not webpackPreload or  webpackPrefetch any page
// why: we have preloader

// hint: add /* webpackMode: "eager" */ to those roots, which shall not be split

// roots
const RequestGate = PickPageLoader(
  async () => import(/* webpackChunkName: "request-gate" */ '@adminhub-page/shadow-it'),
  ({ ShadowItRequestGate: Page }) => Page,
);

const ClaimApp = PickPageLoader(
  async () => import(/* webpackChunkName: "claim-app" */ '@adminhub-page/claim-app'),
  ({ ClaimApp: Page }) => Page,
);

const OrgPickerSwitcher = PickPageLoader(
  async () => import(/* webpackChunkName: "org-picker-switcher-app" */ '@adminhub-page/onboarding-agp'),
  ({ OrgPickerSwitcher: Page }) => Page,
);

const CreateOrganizationPage = PickPageLoader(
  async () => import(/* webpackChunkName: "create-org-app" */ '@adminhub-page/create-page'),
  ({ CreateOrganizationPage: Page }) => Page,
);

const Error404Page = PickPageLoader(
  async () => import(/* webpackMode: "eager" */ /* webpackChunkName: "error-404-page" */ '@adminhub/error-pages'),
  ({ Error404Page: Page }) => Page,
);

const OrgRoutes = PickPageLoader(
  async () => import(/* webpackMode: "eager" */ /* webpackChunkName: "org-app" */ '@adminhub/routes'),
  ({ OrgRoutes: Page }) => Page,
);

const SiteRoutes = PickPageLoader(
  async () => import(/* webpackMode: "eager" */ /* webpackChunkName: "site-app" */ '@adminhub/routes'),
  ({ SiteRoutes: Page }) => Page,
);

const Dashboard = PickPageLoader(
  async () => import(/* webpackMode: "eager" */ /* webpackChunkName: "dashboard-app" */ '@adminhub/dashboard'),
  ({ Dashboard: Page }) => Page,
);

const RovoSignupLandingPage = PickPageLoader(
  async () => import('@adminhub/rovo-signup'),
  ({ RovoSignupLandingPage: Page }) => Page,
);

const RovoSignupOrgPage = PickPageLoader(
  async () => import('@adminhub/rovo-signup'),
  ({ RovoSignupOrgPage: Page }) => Page,
);

const OrgOptIn = PickPageLoader(
  async () => import('@adminhub/migration-shared-experiences-org-opt-in'),
  ({ OrgOptIn: Page }) => Page,
);

type RootRoutes = Record<
  string,
  {
    exact?: boolean;
    navigation: boolean;
    importer: React.ComponentType<any> & LoadableComponent;
    name?: string;
  }
>;

export const ROOT_ROUTES: RootRoutes = {
  '/product-request-workflow': {
    importer: RequestGate,
    navigation: false,
    name: RouteNames.TOP_ROUTE_SHADOW_IT_REQUEST_GATE,
  },
  '/atlassian-access': {
    importer: OrgPickerSwitcher,
    navigation: true,
    name: RouteNames.TOP_ROUTE_ORG_PICKER,
  },
  '/atlassian-guard': {
    importer: OrgPickerSwitcher,
    navigation: true,
    name: RouteNames.TOP_ROUTE_ORG_PICKER,
  },
  '/atlassian-guard-premium': {
    importer: OrgPickerSwitcher,
    navigation: true,
    name: RouteNames.TOP_ROUTE_ORG_PICKER,
  },
  '/claim': {
    importer: ClaimApp,
    navigation: false,
  },
  '/rovo/o/:orgId': {
    importer: RovoSignupOrgPage,
    navigation: true,
  },
  '/rovo': {
    importer: RovoSignupLandingPage,
    navigation: true,
  },
  '/o/create': {
    importer: CreateOrganizationPage,
    navigation: false,
  },
  '/o/:orgId/idp/:idpId': {
    importer: OrgRoutes,
    navigation: true,
  },
  '/o/:orgId': {
    importer: OrgRoutes,
    navigation: true,
  },
  // React router matches on the first valid route, so we need the more specific one higher to get the idpId param to use in JsmIdentityProvidersAddSamlDrawer
  '/s/:cloudId/jira-service-management/portal-only-customers/idp/:idpId': {
    importer: SiteRoutes,
    navigation: true,
  },
  '/s/:cloudId': {
    importer: SiteRoutes,
    navigation: true,
  },
  '/': {
    importer: Dashboard,
    navigation: true,
    exact: true,
    name: RouteNames.TOP_ROUTE_DASHBOARD,
  },
  '/cloud-first-create-a-migration': {
    importer: OrgOptIn,
    navigation: true,
  },
  '': {
    importer: Error404Page,
    navigation: true,
  },
};
