import type { C2CMigrationCheckStatus } from '@adminhub/graphql-types';

import type { C2CProducts } from '@adminhub/schema-types';

import type { FeatureEvents, OperationalEvent, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'copyProductDashboardScreen'
  | 'copyProductInstructionScreen'
  | 'copyProductSourceDestinationScreen'
  | 'copyProductSelectSpacesScreen'
  | 'copyProductUserGroupMembershipScreen'
  | 'copyProductCheckScreen'
  | 'copyProductValidationScreen'
  | 'copyProductDetailsScreen';

type UiKeys =
  | 'excludeConflictingSpacesClicked'
  | 'excludeConflictingProjectsClicked'
  | 'copyWithoutAssetsButtonClicked'
  | 'excludeConflictingAssetsSchemaKeysClicked'
  | 'excludeConflictingAssetsSchemaNamesClicked'
  | 'downloadCsvClicked'
  | 'continueMergeGroupsClicked'
  | 'undoConsentClicked'
  | 'continuePublicAccessClicked'
  | 'copyClicked'
  | 'createCopyClicked'
  | 'closeCreationProcessClicked'
  | 'nextNavigationCreationProcessClicked'
  | 'continueMergeCustomerOrganizationsClicked'
  | 'cpbfOptionSelected'
  | 'arjOptionSelected'
  | 'cpbfPreflightExcludeSelected'
  | 'assetsOptionSelected'
  | 'arjRemoveConflictingPlansClicked';

type OperationalKeys = 'errorViewed';

type Mode = 'create' | 'edit';

export const copyProductDashboardScreen = 'copyProductDashboardScreen';
const copyProductInstructionScreen = 'copyProductInstructionScreen';
const copyProductSourceDestinationScreen = 'copyProductSourceDestinationScreen';
const copyProductSelectSpacesScreen = 'copyProductSelectSpacesScreen';
const copyProductUserGroupMembershipScreen = 'copyProductUserGroupMembershipScreen';
const copyProductCheckScreen = 'copyProductCheckScreen';
const copyProductValidationScreen = 'copyProductValidationScreen';
const copyProductDetailsScreen = 'copyProductDetailsScreen';
const copyProductFocusedTask = 'copyProductFocusedTask';

export const c2cCopyEvents: FeatureEvents<ScreenKeys, UiKeys, string, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CCCM/pages/2413005882/Copy+product+data+f.k.a+C2C+Confluence+page+analytics',
  screen: {
    copyProductDashboardScreen: (attributes: { product: C2CProducts; migrationType: string }): ScreenEvent => ({
      data: {
        name: copyProductDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52446',
    }),

    copyProductInstructionScreen: (attributes: { product: C2CProducts; migrationType: string }): ScreenEvent => ({
      data: {
        name: copyProductInstructionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52447',
    }),

    copyProductSourceDestinationScreen: (attributes: { product: C2CProducts; migrationType: string }): ScreenEvent => ({
      data: {
        name: copyProductSourceDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52448',
    }),

    copyProductSelectSpacesScreen: (attributes: { product: C2CProducts; migrationType: string }): ScreenEvent => ({
      data: {
        name: copyProductSelectSpacesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52449',
    }),

    copyProductUserGroupMembershipScreen: (attributes: { product: C2CProducts; migrationType: string }): ScreenEvent => ({
      data: {
        name: copyProductUserGroupMembershipScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52450',
    }),

    copyProductCheckScreen: (attributes: { product: C2CProducts; migrationType: string; migrationId: string }): ScreenEvent => ({
      data: {
        name: copyProductCheckScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72036',
    }),

    copyProductValidationScreen: (attributes: { product: C2CProducts; migrationType: string; migrationId: string }): ScreenEvent => ({
      data: {
        name: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52451',
    }),

    copyProductDetailsScreen: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      status: C2CMigrationCheckStatus;
    }): ScreenEvent => ({
      data: {
        name: copyProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52452',
    }),
  },
  ui: {
    excludeConflictingSpacesClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'excludeConflictingSpaces',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52453',
    }),
    excludeConflictingProjectsClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'excludeConflictingProjects',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55453',
    }),
    excludeConflictingAssetsSchemaKeysClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'excludeConflictingAssetsSchemaKeys',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: '', // TODO: https://hello.jira.atlassian.cloud/browse/JMB-2517 - Add data portal link for this UI Event
    }),
    excludeConflictingAssetsSchemaNamesClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'excludeConflictingAssetsSchemaNames',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: '', // TODO: https://hello.jira.atlassian.cloud/browse/JMB-2519 - Add data portal link for this UI Event
    }),
    copyWithoutAssetsButtonClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyWithoutAssets',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: '', // TODO: https://hello.jira.atlassian.cloud/browse/JMB-2530 - Add data portal link for this UI Event
    }),
    downloadCsvClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadCsv',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52454',
    }),
    continueMergeGroupsClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueMergeGroups',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52455',
    }),
    continueMergeCustomerOrganizationsClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueMergeCustomerOrganizations',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58137',
    }),
    undoConsentClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'undoConsent',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52456',
    }),
    continuePublicAccessClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      validationScopeId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continuePublicAccess',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52457',
    }),
    copyClicked: (attributes: { product: C2CProducts; migrationType: string; migrationId: string; source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copy',
        source: copyProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52458',
    }),
    createCopyClicked: (attributes: { product: C2CProducts; migrationType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createCopy',
        source: copyProductDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52459',
    }),
    closeCreationProcessClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      stepId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeCreationProcess',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52460',
    }),
    nextNavigationCreationProcessClicked: (attributes: {
      product: C2CProducts;
      migrationType: string;
      migrationId: string;
      stepId: string;
      membership: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextNavigationCreationProcess',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52461',
    }),
    assetsOptionSelected: (attributes: {
      migrationType: string;
      migrationId?: string;
      mode: Mode;
      isVortex: string;
      sourceOrgId: string;
      destinationOrgId: string;
      name: string;
      option: string;
    }): UIEvent => ({
      data: {
        action: Action.Changed,
        actionSubject: ActionSubject.Radio,
        actionSubjectId: 'assetsDataSelection',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: '', // TODO: add data portal link - https://hello.jira.atlassian.cloud/browse/JMB-2434
    }),
    cpbfOptionSelected: (attributes: {
      migrationType: string;
      migrationId?: string;
      mode: Mode;
      isVortex: string;
      sourceOrgId: string;
      destinationOrgId: string;
      name: string;
      option: string;
    }): UIEvent => ({
      data: {
        action: Action.Changed,
        actionSubject: ActionSubject.Radio,
        actionSubjectId: 'cpbfOption',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72190',
    }),
    arjOptionSelected: (attributes: {
      migrationType: string;
      migrationId?: string;
      mode: Mode;
      isVortex: string;
      sourceOrgId: string;
      destinationOrgId: string;
      name: string;
      option: string;
    }): UIEvent => ({
      data: {
        action: Action.Changed,
        actionSubject: ActionSubject.Radio,
        actionSubjectId: 'arjOption',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72189',
    }),
    cpbfPreflightExcludeSelected: (attributes: {
      migrationType: string;
      migrationId: string;
      sourceOrgId: string;
      destinationOrgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cpbfPreflightExclude',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71922',
    }),
    arjRemoveConflictingPlansClicked: (attributes: {
      migrationType: string;
      migrationId: string;
      sourceOrgId: string;
      destinationOrgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'arjRemoveConflictingPlans',
        source: copyProductFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72191',
    }),
  },

  operational: {
    errorViewed: (attributes: {
      orgId: string;
      sourceAdminError: boolean;
      destinationAdminError: boolean;
      migrationType: string;
      product: C2CProducts;
    }): OperationalEvent => ({
      data: {
        action: OperationalAction.Viewed,
        actionSubject: OperationalSubject.SiteSelectionDropdownError,
        source: copyProductSourceDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52486',
    }),
  },
  track: {},
};
