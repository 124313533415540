import type { Domain } from '@adminhub/domain-claim';
import { getDomainStatusAttributes } from '@adminhub/domain-claim';

import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'accessValuePropScreenEvent' | 'orgPickerScreenEvent' | 'emptyOrgPickerScreenEvent';
type UIEventKeys =
  | 'gotToAtlassianAdministrationClickEvent'
  | 'createNewOrgClickEvent'
  | 'selectOrgClickEvent'
  | 'selectOrgLinkClickEvent'
  | 'createOrganizationClickEvent'
  | 'comeBackLaterClickEvent'
  | 'learnMoreClickEvent';

export const accessValuePropScreen = 'accessValuePropScreen';
export const orgPickerScreen = 'orgPickerScreen';
export const orgPickerScreenEmpty = 'orgPickerScreenEmpty';

export const onboardingAnalyticsEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/581081357/Analytics+Spec+for+New+Onboarding+Experience',
  screen: {
    accessValuePropScreenEvent: (domains: Domain[]): ScreenEvent => ({
      data: {
        name: accessValuePropScreen,
        attributes: {
          ...getDomainStatusAttributes(domains),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19200',
    }),
    orgPickerScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgPickerScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19429',
    }),
    emptyOrgPickerScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgPickerScreenEmpty,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62031',
    }),
  },
  ui: {
    gotToAtlassianAdministrationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'goToAdminHub',
        source: orgPickerScreenEmpty,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62032',
    }),
    createNewOrgClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createOrganization',
        source: orgPickerScreenEmpty,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62092',
    }),
    selectOrgClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectOrg',
        source: orgPickerScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19439',
    }),
    selectOrgLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectOrg',
        source: orgPickerScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62094',
    }),
    learnMoreClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAccessLink',
        source: orgPickerScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15135',
    }),
    createOrganizationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createOrganization',
        source: orgPickerScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19440',
    }),
    comeBackLaterClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'comeBackLater',
        source: orgPickerScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19441',
    }),
  },
  operational: {},
  track: {},
};
