import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'apiKeysScreen' | 'createAPIKeyScreen';
type UIEventKeys =
  | 'confirmRevokeApiKeys'
  | 'cancelRevokeApiKeys'
  | 'resetKeysSearchFilters'
  | 'exportApiKeysList'
  | 'confirmCreateApiKeys'
  | 'cancelCreateAPIKey';

type TrackEventKeys = 'exportApiKeysSuccess' | 'exportApiKeysFailure' | 'createAPIKeySuccess' | 'createAPIKeyFailure';

export enum ApiKeysScreens {
  apiKeysScreen = 'apiKeysScreen',
  createApiKeysScreen = 'createAPIKeyScreen',
}

const screen = {
  apiKeysScreen: (): ScreenEvent => ({
    data: {
      name: ApiKeysScreens.apiKeysScreen,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66930',
  }),
  createAPIKeyScreen: (): ScreenEvent => ({
    data: {
      name: ApiKeysScreens.createApiKeysScreen,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66931',
  }),
};

const ui = {
  confirmRevokeApiKeys: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'confirmRevokeApiKeys',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66932',
  }),
  cancelRevokeApiKeys: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'cancelRevokeApiKeys',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66933',
  }),
  resetKeysSearchFilters: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'resetKeysSearchFilters',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68009',
  }),
  exportApiKeysList: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'exportApiKeysList',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68010',
  }),
  cancelCreateAPIKey: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'cancelCreateAPIKey',
      source: ApiKeysScreens.createApiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66934',
  }),
  confirmCreateApiKeys: (attributes: { orgId: string }): UIEvent => ({
    data: {
      action: Action.Clicked,
      actionSubject: ActionSubject.Button,
      actionSubjectId: 'confirmCreateApiKeys',
      source: ApiKeysScreens.createApiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68089',
  }),
};

const track = {
  exportApiKeysSuccess: (attributes: { orgId: string }): TrackEvent => ({
    data: {
      action: TrackAction.Exported,
      actionSubject: TrackActionSubject.ApiKey,
      actionSubjectId: 'exportApiKeysSuccess',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68014',
  }),
  exportApiKeysFailure: (attributes: { orgId: string }): TrackEvent => ({
    data: {
      action: TrackAction.ExportFailed,
      actionSubject: TrackActionSubject.ApiKey,
      actionSubjectId: 'exportApiKeysFailure',
      source: ApiKeysScreens.apiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68013',
  }),
  createAPIKeySuccess: (attributes: { orgId: string }): TrackEvent => ({
    data: {
      action: TrackAction.Created,
      actionSubject: TrackActionSubject.ApiKey,
      actionSubjectId: 'createAPIKeySuccess',
      source: ApiKeysScreens.createApiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68011',
  }),
  createAPIKeyFailure: (attributes: { orgId: string }): TrackEvent => ({
    data: {
      action: TrackAction.CreationFailed,
      actionSubject: TrackActionSubject.ApiKey,
      actionSubjectId: 'createAPIKeyFailure',
      source: ApiKeysScreens.createApiKeysScreen,
      attributes,
    },
    dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68012',
  }),
};

export const apiKeysEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/3647825667/ATC+Milestone+2.1+and+2.2+Analytics',
  screen,
  ui,
  track,
  operational: {},
};
