import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';

import AkSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

import type { MainLayoutProps } from '@adminhub/main-layout';
import { MainLayout } from '@adminhub/main-layout';
import { monitoringClient } from '@adminhub/monitoring-client';
import { UFOLoadHold } from '@adminhub/ufo';

interface SkeletonBlockProps {
  widthPx: number;
  heightPx: number;
}

export const SkeletonBlock = styled.span`
  display: inline-block;
  border-radius: ${token('border.radius.100', '3px')};
  background-color: ${token('color.background.accent.gray.subtlest', '#F4F5F7')};
  ${({ widthPx, heightPx }: SkeletonBlockProps) => css`
    width: ${widthPx}px;
    max-width: 100%;
    height: ${heightPx}px;
  `} &:not(:last-child) {
    margin-right: ${token('space.200', '16px')};
  }
`;

const SkeletonWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

// this height isn't particularly tied to anything, but the whole thing looks nice with it. Design-approved
const headingSkeletonHeightPx = 22;
const TIME_TO_ERROR = 50000;

export const FullPageSkeleton = (
  props: Pick<MainLayoutProps, 'id' | 'collapsedNav' | 'breadcrumbs' | 'title' | 'description' | 'testId'>,
) => {
  const isLazyLoadedPage = props.id === 'page-loading';
  const [shouldSendError, setShouldSendError] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (isLazyLoadedPage) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      const loadedTooLongError = new Error(`Could not load page content after ${TIME_TO_ERROR} milliseconds. page-id=${props.id}.`);
      if (shouldSendError) {
        monitoringClient.onError(loadedTooLongError);
        setShouldSendError(false);
      }
    }, TIME_TO_ERROR);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UFOLoadHold name="full-page-skeleton">
      <SkeletonWrapper>
        <MainLayout
          id={props.id}
          pageType="skeleton"
          collapsedNav={props.collapsedNav}
          isFullWidth={true}
          breadcrumbs={
            props.breadcrumbs || (
              <div>
                <SkeletonBlock widthPx={40} heightPx={8} />
                <SkeletonBlock widthPx={120} heightPx={8} />
              </div>
            )
          }
          title={props.title || <SkeletonBlock widthPx={216} heightPx={headingSkeletonHeightPx} />}
          description={
            props.description || (
              <React.Fragment>
                <div>
                  <SkeletonBlock widthPx={504} heightPx={11} />
                </div>
                <div>
                  <SkeletonBlock widthPx={336} heightPx={11} />
                </div>
              </React.Fragment>
            )
          }
          testId={props.testId}
        />
        <SpinnerWrapper data-testid="full-page-skeleton-spinner">
          <AkSpinner size="large" interactionName="full-page-skeleton-spinner" />
        </SpinnerWrapper>
      </SkeletonWrapper>
    </UFOLoadHold>
  );
};
