import * as React from 'react';

export interface RouteIdContextProps {
  orgId?: string;
  siteId?: string;
  isLoading?: boolean;
  orgName?: string;
  siteName?: string | null;
}

const defaultRouteId: RouteIdContextProps = {
  orgId: undefined,
  siteId: undefined,
  isLoading: false,
  orgName: undefined,
  siteName: undefined,
};

export const RouteIdContext = React.createContext(defaultRouteId);
