import * as React from 'react';

import type { Interaction, PageTimingRootContext, PerformanceProp } from './types';

interface OwnProps {
  performance?: PerformanceProp;
}

interface LoadCounts {
  [key: string]: number;
}

const { Provider, Consumer } = React.createContext<PageTimingRootContext>({
  startInteraction: (_interaction: Interaction) => null,
  reportPageLoaded: (_pageName: string) => null,
});

export const RootConsumer = Consumer;

export class PageTimingRoot extends React.Component<OwnProps> {
  public static defaultProps = {
    performance: window.performance,
  };

  private seenAtInteraction: { [key: string]: boolean } = {};
  private loadCounts: LoadCounts = {};

  public render() {
    return (
      <Provider
        value={{
          startInteraction: this.startInteraction,
          reportPageLoaded: this.reportPageLoaded,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }

  private startInteraction = () => {
    this.seenAtInteraction = {};
  };

  private reportPageLoaded = (pageId: string) => {
    if (this.seenAtInteraction[pageId]) {
      return;
    }

    this.incrementLoadCount(pageId);
    this.seenAtInteraction[pageId] = true;
  };

  private incrementLoadCount = (pageId: string) => {
    if (this.loadCounts[pageId] === undefined) {
      this.loadCounts[pageId] = 1;

      return;
    }

    this.loadCounts[pageId] = this.loadCounts[pageId] + 1;
  };
}
