import type { UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'manualUpgradeBanner';

type UiKeys = 'tryItFreeButtonClicked';

type TrackKeys = 'eligibilityChecked';

type OperationalKeys = 'manualUpgradeDataFetchFailed' | 'manualUpgradeBannerRenderFailed' | 'siteUsersNumberCollected';

export interface ManualUpgradeBannerScreenEventAttribute {
  cloudId: string;
  product: UiProductType;
  variant: 'approaching_limits' | 'at_user_limit';
  seatsTaken: number;
  seatsTotal: number;
}

export interface EligibilityCheckedAttributes {
  flagCohort: string;
  flagKey: string;
  ineligibilityReasons: string[];
}

export interface ManualUpgradeDataFetchFailedAttributes {
  errors: string[];
}

export interface ManualUpgradeBannerRenderFailedAttributes {
  errors: string[];
}

export interface TryItFreeButtonClickedAttributes {
  cloudId: string;
  product: UiProductType;
  seatsTaken: number;
  seatsTotal: number;
  variant: 'approaching_limits' | 'at_user_limit';
}

export interface SiteUsersNumberAttributes {
  siteUsers: {
    cloudId: string;
    product: UiProductType;
    seatsTaken: number;
    seatsTotal: number;
  }[];
}
export const manualUpgradeEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/Land/pages/4476991121/Manual+Upgrade+as+users+added+via+Alert+-+Analytics+Specs',
  screen: {
    manualUpgradeBanner: (attributes: ManualUpgradeBannerScreenEventAttribute): ScreenEvent => ({
      data: {
        name: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73964',
    }),
  },
  ui: {
    tryItFreeButtonClicked: (attributes: TryItFreeButtonClickedAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tryItFree',
        source: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74537',
    }),
  },
  track: {
    eligibilityChecked: (attributes: EligibilityCheckedAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Checked,
        actionSubject: TrackActionSubject.Eligibility,
        // The default source is meant to be replaced by the actual source screen
        source: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71036',
    }),
  },
  operational: {
    manualUpgradeDataFetchFailed: (attributes: ManualUpgradeDataFetchFailedAttributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.ManualUpgradeDataFetch,
        // The default source is meant to be replaced by the actual source screen
        source: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74931',
    }),
    manualUpgradeBannerRenderFailed: (attributes: ManualUpgradeBannerRenderFailedAttributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.ManualUpgradeBannerRender,
        // The default source is meant to be replaced by the actual source screen
        source: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74932',
    }),
    siteUsersNumberCollected: (attributes: SiteUsersNumberAttributes): OperationalEvent => ({
      data: {
        action: OperationalAction.Collected,
        actionSubject: OperationalSubject.SiteUsersNumber,
        // The default source is meant to be replaced by the actual source screen
        source: 'manualUpgradeBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73959',
    }),
  },
};
