import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'siteGSuiteScreenEvent'
  | 'siteGSuiteConnectScreenEvent'
  | 'siteGSuiteSelectUsersScreenEvent'
  | 'siteGSuiteConfirmScreenEvent';

type UIEventKeys = 'setupSiteGSuiteClickEvent' | 'selectUsersNextSiteClickEvent' | 'startSiteGSuiteSyncClickEvent';

type TrackEventKeys = 'connectedSiteTrackEvent' | 'groupsUpdatedSiteTrackEvent' | 'disconnectedSiteTrackEvent';

export type siteGSuiteSyncType = 'syncAllUsers' | 'syncSpecificGroups';

export const siteGSuiteScreen = 'siteGSuiteScreen';
export const siteGSuiteConnectScreen = 'siteGSuiteConnectScreen';
export const siteGSuiteSelectUsersScreen = 'siteGSuiteSelectUsersScreen';
export const siteGSuiteConfirmScreen = 'siteGSuiteConfirmScreen';

export const siteGSuiteEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/590293654/M0+Spec+Analytic+Events',
  screen: {
    siteGSuiteScreenEvent: (attributes: { connected: boolean }): ScreenEvent => ({
      data: {
        name: siteGSuiteScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24352',
    }),
    siteGSuiteConnectScreenEvent: (attributes: { connected: boolean }): ScreenEvent => ({
      data: {
        name: siteGSuiteConnectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24353',
    }),
    siteGSuiteSelectUsersScreenEvent: (): ScreenEvent => ({
      data: {
        name: siteGSuiteSelectUsersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24354',
    }),
    siteGSuiteConfirmScreenEvent: (): ScreenEvent => ({
      data: {
        name: siteGSuiteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24355',
    }),
  },
  ui: {
    setupSiteGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'setupSiteGSuite',
        source: siteGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23721',
    }),
    selectUsersNextSiteClickEvent: (attributes: { syncType: siteGSuiteSyncType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'nextButtonSite',
        source: siteGSuiteSelectUsersScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23722',
    }),
    startSiteGSuiteSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'startSiteGSuiteSync',
        source: siteGSuiteConfirmScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23723',
    }),
  },
  operational: {},
  track: {
    connectedSiteTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Connected,
        actionSubject: TrackActionSubject.SiteGSuiteAccount,
        source: siteGSuiteConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23724',
    }),
    groupsUpdatedSiteTrackEvent: (attributes: { numberOfGroups: number }): TrackEvent => ({
      data: {
        action: TrackAction.GroupsUpdated,
        actionSubject: TrackActionSubject.SiteGSuiteAccount,
        source: siteGSuiteScreen,
        attributes: { numberOfGroups: attributes.numberOfGroups },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23725',
    }),
    disconnectedSiteTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Disconnected,
        actionSubject: TrackActionSubject.SiteGSuiteAccount,
        source: siteGSuiteScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/23726',
    }),
  },
};
