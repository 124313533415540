import type { UiProductType, UserProductRole } from '@adminhub/graphql-types';

import type { ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

interface Product {
  productId: UiProductType;
  workspaceId: string;
}

const siteGroupDetailsScreen = 'siteGroupDetailsScreen';
const uumAddProductToGroupModal = 'uumAddProductToGroupModal';

export type UUMSource = typeof siteGroupDetailsScreen | typeof uumAddProductToGroupModal;

export const uumEvents = {
  screen: {
    addProductToGroupModal: (): ScreenEvent => ({
      data: {
        name: 'siteAddProductToGroupModal',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72958',
    }),
  },
  ui: {
    openAddProductsToGroupsModal: (attributes: { groupId?: string; isAddProductsExpButton?: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModal',
        source: 'siteGroupDetailsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40604',
    }),
    addProductsToGroupsModalConfirm: (
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModalConfirm',
        source: 'siteAddProductToGroupModal',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40720',
    }),
    grantedGroupProductRole: (
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
      },
    ): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'grantedGroupProductRole',
        source: 'siteGroupDetailsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40722',
    }),
    openProductRoleDropdownList: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListTrigger,
        actionSubjectId: 'openProductRoleDropdownList',
        source: 'siteGroupDetailsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73272',
    }),
    unselectAllProductRole: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'unselectAllProductRole',
        source: 'siteGroupDetailsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73273',
    }),
  },
  track: {
    addedProductToGroup: (
      source: UUMSource,
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
        existingRoles?: UserProductRole[];
        userIds?: string[];
        totalUsers?: number;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.AddProduct,
        actionSubject: TrackActionSubject.Group,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33970',
    }),
    addedProductToGroupFailed: (
      source: UUMSource,
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
        existingRoles?: UserProductRole[];
        userIds?: string[];
        totalUsers?: number;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.AddProductFailed,
        actionSubject: TrackActionSubject.Group,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72971',
    }),
  },
};
