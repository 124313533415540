import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createPortal } from 'react-dom';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AkAvatar from '@atlaskit/avatar';
import { Grid as AkGrid, GridColumn as AkGridColumn } from '@atlaskit/page';
import type { xcss as akXcss } from '@atlaskit/primitives';

import { ErrorBoundary } from '@adminhub/error-boundary';
import { PageTimingLoadReporter } from '@adminhub/performance-metrics';

import { PageHeader } from '@adminhub/responsive';
import { Centered } from '@adminhub/styled';

import { useOrgName, useSiteName } from '@adminhub/router';

import { addCollapsedNavProp } from './add-collapsed-nav-prop';
import { InlineEditTitle } from './inline-edit-title';
import {
  Avatar,
  Children,
  CustomLayoutHeightWrapper,
  Description,
  Icon,
  Main,
  PageBannerWrapper,
  Subtitle,
  Title,
} from './main-layout.styled';

import { useMainLayoutRightSidebar } from '../hooks/use-main-layout-right-sidebar';

export const messages = defineMessages({
  admin: {
    id: 'main-layout.admin-nonfinal',
    description: 'General label for page titles.',
    defaultMessage: 'Atlassian Administration',
  },
});

export type PageType = 'skeleton' | 'error';

export interface MainLayoutProps {
  id: string;
  orgNameForSite?: string;
  pageType?: PageType;
  title?: React.ReactNode;
  inlineEdit?: {
    defaultValue: string;
    errorMessage?: string | null;
    onConfirm(value: string): void;
  };
  isDisabled?: boolean;
  subtitle?: any;
  children?: any;
  description?: any;
  side?: any;
  isFullWidth?: boolean;
  isAutoGridSize?: boolean;
  // Pass a URL to load an avatar
  avatarIconUrl?: string;
  // Pass AkIcon's to be rendered to the left of the title
  icon?: React.ReactNode;
  action?: JSX.Element;
  breadcrumbs?: React.ReactElement;
  pageParent?: React.ReactNode;
  pageBanner?: React.ReactNode;
  gridLayout?: 'fixed' | 'fluid';
  pageRef?: React.RefObject<HTMLElement>;
  testId?: string;
  childrenContainerStyle?: ReturnType<typeof akXcss>;
  /**
   * This value controls the CSS property "contain: paint". It is useful for hiding overflowing content.
   */
  preventContentOverflow?: boolean;
  /**
   * Use this prop to render a right sidebar from AUK (@atlassian/sidebar).
   * Use the useMainLayoutRightSidebar() hook to control the visibility of this sidebar.
   */
  rightSidebar?: Readonly<React.ReactNode>;
  showTitleOnPage?: boolean;
  /**
   * Use this prop to ensure tables do not take up full width of page.
   */
  excludeTableFullWidth?: boolean;
  mainContentHeightCss?: string;
  disableTitleStyles?: boolean;
  contentAboveDescription?: React.ReactNode;
}

export const MainLayoutImpl: React.FC<MainLayoutProps> = ({
  id,
  orgNameForSite,
  title,
  isDisabled = false,
  subtitle,
  action,
  description,
  children,
  side,
  breadcrumbs,
  pageParent,
  pageBanner,
  isAutoGridSize,
  isFullWidth,
  pageType,
  inlineEdit,
  gridLayout,
  pageRef,
  testId = 'main-layout',
  childrenContainerStyle,
  preventContentOverflow = false,
  rightSidebar,
  avatarIconUrl,
  icon,
  showTitleOnPage = true,
  excludeTableFullWidth = false,
  mainContentHeightCss,
  disableTitleStyles = false,
  contentAboveDescription,
}) => {
  const { hideSidebar } = useMainLayoutRightSidebar();
  React.useEffect(
    () => () => {
      hideSidebar();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sidebarsPortalEl = document.getElementById('sidebars-portal');

  const determineChildGridSize = (): number => {
    if (side || !isFullWidth) {
      return 8;
    }

    return 12;
  };

  const renderIcon = (): React.ReactNode => {
    if (avatarIconUrl) {
      return (
        <Avatar isDisabled={isDisabled || false}>
          <AkAvatar src={avatarIconUrl} size="large" />
        </Avatar>
      );
    }

    if (icon) {
      return <Icon isDisabled={isDisabled || false}>{icon}</Icon>;
    }

    return undefined;
  };

  const onInlineEditConfirm = (value: string) => {
    inlineEdit?.onConfirm(value);
  };

  // Extracts a string from a React node if it's a string
  const extractStringFromElement = (element: React.ReactNode): string => {
    if (typeof element === 'string') {
      return element;
    }

    return '';
  };

  // Extracts strings from an array of React nodes and concatenates them
  const extractStringFromArrayOfElements = (element: React.ReactNode): string => {
    if (Array.isArray(element)) {
      return element
        .map(extractStringFromElementRecursively)
        .filter((message) => message)
        .join(' ');
    }

    return '';
  };

  // Extracts a string from the props of a React element
  const extractStringFromElementProps = (props: {
    children?: React.ReactNode;
    defaultMessage?: string;
    isCustomTitle?: boolean;
    name?: string;
    productName?: string;
    displayName?: string;
  }): string => {
    if (props.isCustomTitle) {
      return props.name || props.productName || props.displayName || '';
    }

    return extractStringFromElementRecursively(props.children);
  };

  // Recursively extracts a string from a React element
  const extractStringFromElementRecursively = (element: React.ReactNode): string => {
    if (React.isValidElement(element)) {
      const props = element.props as {
        children?: React.ReactNode;
        defaultMessage?: string;
        isCustomTitle?: boolean;
        name?: string;
        productName?: string;
        displayName?: string;
      };
      if (
        (typeof element.type === 'function' || typeof element.type === 'object') &&
        'displayName' in element.type &&
        (element.type.displayName === 'FormattedMessage' || element.type.displayName === 'MemoizedFormattedMessage')
      ) {
        return props.defaultMessage || '';
      }

      return extractStringFromElementProps(props);
    }

    return '';
  };

  // Main function to extract a title string from a React node
  const extractTitleString = (element: React.ReactNode): string => {
    if (!element) {
      return '';
    }

    return extractStringFromElement(element) || extractStringFromArrayOfElements(element) || extractStringFromElementRecursively(element);
  };

  // Function to construct the document title with all provided components
  const formatDocumentTitle = (
    reworkedTitle: string,
    pageParentTitle: string,
    siteName: string | undefined | null,
    orgName: string | undefined,
    admin: string,
  ) => {
    // Initializing doc title to construct
    const toReturnDocTitle: string[] = [];

    // Adding provided components to doc title
    if (reworkedTitle) toReturnDocTitle.push(reworkedTitle);
    if (pageParentTitle) toReturnDocTitle.push(pageParentTitle);
    if (siteName) toReturnDocTitle.push(siteName);
    if (orgName && orgName !== reworkedTitle) toReturnDocTitle.push(orgName);
    toReturnDocTitle.push(admin);

    // Join all components together with a hyphen
    return toReturnDocTitle.join(' - ');
  };

  const extractedTitle = extractTitleString(title);
  const extractedParentTitle = extractTitleString(pageParent);
  const siteName = useSiteName();
  const orgName = useOrgName() || orgNameForSite;
  const adminTag = <FormattedMessage {...messages.admin} />;
  const admin = adminTag?.props?.defaultMessage && `${adminTag?.props?.defaultMessage}`;
  const docTitle = () => formatDocumentTitle(extractedTitle, extractedParentTitle, siteName, orgName, admin);

  // Updating the page title to be more specific depending on the path of the page.
  return (
    <ErrorBoundary>
      <div>
        <CustomLayoutHeightWrapper heightCss={mainContentHeightCss}>
          <Main innerRef={pageRef} data-testid={testId} preventContentOverflow={preventContentOverflow}>
            <DocumentTitle title={docTitle()} data-testid="document-title" />
            <AkGrid spacing={!excludeTableFullWidth ? 'cosy' : 'comfortable'} layout={!excludeTableFullWidth ? 'fluid' : gridLayout}>
              {!side && !isFullWidth && <AkGridColumn medium={2} /> /* adds blank space for sidebar */}
              {pageBanner && (
                <AkGridColumn medium={12}>
                  <PageBannerWrapper data-testid="pageBanner">{pageBanner}</PageBannerWrapper>
                </AkGridColumn>
              )}
              <AkGridColumn medium={isAutoGridSize ? undefined : determineChildGridSize()}>
                <PageHeader
                  bottomBar={subtitle && <Subtitle isDisabled={isDisabled}>{subtitle}</Subtitle>}
                  actions={action}
                  breadcrumbs={breadcrumbs}
                  disableTitleStyles={!!inlineEdit || disableTitleStyles}
                >
                  <PageTimingLoadReporter pageId={id} milestone={pageType || 'header-rendered'} />
                  <Centered axis="vertical">
                    {renderIcon()}
                    {showTitleOnPage && title && !inlineEdit && <Title isDisabled={isDisabled}>{title}</Title>}
                    {inlineEdit && (
                      <InlineEditTitle
                        defaultValue={inlineEdit.defaultValue}
                        errorMessage={inlineEdit.errorMessage}
                        onConfirm={onInlineEditConfirm}
                      />
                    )}
                  </Centered>
                </PageHeader>
                {contentAboveDescription || null}
                {description && <Description>{description}</Description>}
                {children && <Children xcss={childrenContainerStyle}>{children}</Children>}
                {rightSidebar && sidebarsPortalEl && createPortal(rightSidebar, sidebarsPortalEl)}
              </AkGridColumn>
              {side && (
                <AkGridColumn medium={4}>
                  <Children>{side}</Children>
                </AkGridColumn>
              )}
            </AkGrid>
          </Main>
        </CustomLayoutHeightWrapper>
      </div>
    </ErrorBoundary>
  );
};

export const MainLayout = addCollapsedNavProp(
  React.forwardRef((props: MainLayoutProps, ref: React.RefObject<HTMLElement>) => <MainLayoutImpl pageRef={ref} {...props} />),
);
MainLayout.displayName = 'MainLayout';
