import * as React from 'react';

import { AnalyticsListener as AkAnalyticsListener } from '@atlaskit/analytics-next';

import type { AnalyticsNextAttributes, UIAnalyticsNextEvent } from './analytics-next-types';
import type { Action, ActionSubject, UIEvent } from './new-analytics-types';
import type { AnalyticsClientProps } from './with-analytics-client';
import { withAnalyticsClient } from './with-analytics-client';

function getAttributes(atlaskitAnalyticsEvent: UIAnalyticsNextEvent): Partial<AnalyticsNextAttributes> {
  const contexts = atlaskitAnalyticsEvent.context;

  const attributesFromContexts = contexts.reduce(
    (a, b) => ({
      ...a,
      ...(b.attributes || {}),
    }),
    {} as { [key: string]: any },
  );

  return (atlaskitAnalyticsEvent.payload.providedAttributes || []).reduce(
    (a, b) => ({
      ...a,
      [b]: attributesFromContexts[b],
    }),
    {},
  );
}

function getScreen(atlaskitAnalyticsEvent: UIAnalyticsNextEvent) {
  const contexts = atlaskitAnalyticsEvent.context;

  return contexts.reduce((a, b) => (b.screen ? b.screen : a), 'none');
}

function formatUIEvent(atlaskitAnalyticsEvent: UIAnalyticsNextEvent): UIEvent {
  return {
    data: {
      // We assume that atlaskit actions match our own Action enum, so we cast Action and ActionSubject here
      action: atlaskitAnalyticsEvent.payload.action as Action,
      actionSubject: atlaskitAnalyticsEvent.payload.actionSubject as ActionSubject,
      actionSubjectId: atlaskitAnalyticsEvent.payload.actionSubjectId!,
      attributes: getAttributes(atlaskitAnalyticsEvent),
      source: getScreen(atlaskitAnalyticsEvent),
    },
  };
}

export class AnalyticsNextListenerImpl extends React.Component<AnalyticsClientProps> {
  public render() {
    return <AkAnalyticsListener onEvent={this.sendAnalytics}>{this.props.children}</AkAnalyticsListener>;
  }

  private sendAnalytics = (e: UIAnalyticsNextEvent) => {
    if (!e.payload.actionSubjectId) {
      return;
    }
    this.props.analyticsClient.sendUIEvent(formatUIEvent(e));
  };
}

export const AnalyticsNextListener = withAnalyticsClient<{}>(AnalyticsNextListenerImpl);
