// eslint-disable-next-line no-restricted-imports
import type { JiraProjectSortKey, JiraProjectSortOrder } from '@adminhub-page/sandbox-data-clone/src/ui/types';
import type { UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'sandboxScreen'
  | 'createSandboxModal'
  | 'deleteSandboxModal'
  | 'deleteSandboxUrlModal'
  | 'sandboxAddProductModal'
  | 'deactivateSandboxModal'
  | 'sandboxHardDeleteExpediteModal'
  | 'sandboxDataCloningModal'
  | 'sandboxDataCloningDrawer'
  | 'sandboxExpiredAppsModal'
  | 'sandboxOnboardingModal'
  | 'overwriteSandboxModal';
type UiKeys =
  | 'createSandboxButtonClicked'
  | 'learnMoreButtonClicked'
  | 'learnMoreJiraFamilyLinkClicked'
  | 'learnMoreCopyDataToSandboxLinkClicked'
  | 'learnMoreOfflineSandboxesClicked'
  | 'learnMoreCreateSandboxLinkClicked'
  | 'productCardClicked'
  | 'sandboxScreenSortByClicked'
  | 'actionsDeleteDropdownItemClicked'
  | 'deleteSandboxModalConfirmClicked'
  | 'deleteSandboxModalCloseClicked'
  | 'deleteSandboxUrlModalCloseClicked'
  | 'deactivateSandboxModalConfirmClicked'
  | 'deactivateSandboxModalCancelClicked'
  | 'sandboxHardDeleteExpediteModalConfirmClicked'
  | 'sandboxHardDeleteExpediteModalCancelClicked'
  | 'restoreSandboxButtonClicked'
  | 'createSandboxModalProductClicked'
  | 'createSandboxModalCancelClicked'
  | 'createSandboxModalCreateClicked'
  | 'createSandboxModalProductionLockedClicked'
  | 'sandboxDataCloningModalCancelClicked'
  | 'sandboxDataCloningModalConfirmClicked'
  | 'sandboxPopoverTriggerClicked'
  | 'sandboxDataCloneDrawerCloseConfirmed'
  | 'sandboxDataCloneSelectionTableSearched'
  | 'sandboxDataCloneSelectionTableCategorySelected'
  | 'sandboxDataCloneSelectionTableSorted'
  | 'sandboxDataCloneSelectionTableNextPageClicked'
  | 'sandboxDataCloneSelectionTablePreviousPageClicked'
  | 'sandboxDataCloneSelectionTablePageSizeSelected'
  | 'sandboxDataCloneSelectionTableTypeSelected'
  | 'sandboxDataCloneSelectDataCardSelected'
  | 'sandboxDataCloningDrawerCopyDataClicked'
  | 'sandboxSelectedFeatureGateSite'
  | 'sandboxTryItFreeButtonClicked'
  | 'sandboxAddProductModalCloseClicked'
  | 'sandboxAddProductModalConfirmClicked'
  | 'overwriteSandboxModalCloseClicked'
  | 'overwriteSandboxModalConfirmClicked'
  | 'sandboxOnboardingModalCloseClicked'
  | 'createSandboxModalCloseClicked'
  | 'sandboxOnboardingSupportLinkClicked'
  | 'feedbackButtonClicked'
  | 'supportLinkClicked'
  | 'updateUrlClick';

type TrackKeys =
  | 'sandboxCreated'
  | 'sandboxDeleted'
  | 'sandboxDeactivated'
  | 'sandboxHardDeleteExpedited'
  | 'sandboxCanceledDeletion'
  | 'sandboxClonedData'
  | 'sandboxClonedSelectiveData'
  | 'sandboxRestored';

type OperationalKeys = 'sandboxDataFetch';

export const sandboxScreen = 'sandboxScreen';
const createSandboxModal = 'createSandboxModal';
const deleteSandboxModal = 'deleteSandboxModal';
const deleteSandboxUrlModal = 'deleteSandboxUrlModal';
const sandboxAddProductModal = 'sandboxAddProductModal';
const deactivateSandboxModal = 'deactivateSandboxModal';
const sandboxDataCloningModal = 'sandboxDataCloningModal';
const sandboxHardDeleteExpediteModal = 'sandboxHardDeleteExpediteModal';
const sandboxDataCloningDrawer = 'sandboxDataCloningDrawer';
const sandboxExpiredAppsModal = 'sandboxExpiredAppsModal';
const sandboxOnboardingModal = 'sandboxOnboardingModal';
const overwriteSandboxModal = 'overwriteSandboxModal';

export const sandboxEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/624850260/Sandbox+Analytics+Spec',

  screen: {
    sandboxScreen: (attributes: { isEntitled: boolean; hasSandboxes: boolean }): ScreenEvent => ({
      data: {
        name: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22164',
    }),

    createSandboxModal: (attributes: { numberOfProducts: number }): ScreenEvent => ({
      data: {
        name: createSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22165',
    }),

    deleteSandboxModal: (): ScreenEvent => ({
      data: {
        name: deleteSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22166',
    }),

    deleteSandboxUrlModal: (): ScreenEvent => ({
      data: {
        name: deleteSandboxUrlModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74920',
    }),

    sandboxAddProductModal: (): ScreenEvent => ({
      data: {
        name: sandboxAddProductModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74919',
    }),

    sandboxOnboardingModal: (): ScreenEvent => ({
      data: {
        name: sandboxOnboardingModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74923',
    }),

    overwriteSandboxModal: (): ScreenEvent => ({
      data: {
        name: overwriteSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74924',
    }),

    deactivateSandboxModal: (): ScreenEvent => ({
      data: {
        name: deactivateSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62432',
    }),

    sandboxHardDeleteExpediteModal: (): ScreenEvent => ({
      data: {
        name: sandboxHardDeleteExpediteModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62022',
    }),

    sandboxDataCloningModal: (): ScreenEvent => ({
      data: {
        name: sandboxDataCloningModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30473',
    }),

    sandboxDataCloningDrawer: (): ScreenEvent => ({
      data: {
        name: sandboxDataCloningDrawer,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43901',
    }),

    sandboxExpiredAppsModal: (): ScreenEvent => ({
      data: {
        name: sandboxExpiredAppsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44190',
    }),
  },

  ui: {
    createSandboxButtonClicked: (attributes: { hasSandboxes: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createSandbox',
        source: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22167',
    }),

    learnMoreButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMore',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22168',
    }),

    learnMoreJiraFamilyLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreJiraFamily',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39478',
    }),

    learnMoreOfflineSandboxesClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreOfflineSandboxes',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50207',
    }),

    learnMoreCopyDataToSandboxLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreCopyDataToSandbox',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49593',
    }),

    learnMoreCreateSandboxLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreCreateSandbox',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61615',
    }),

    sandboxOnboardingSupportLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'contactSupport',
        source: sandboxOnboardingModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16337',
    }),

    productCardClicked: (attributes: { siteId: string; productType: UiProductType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'sandboxTableProductCard',
        source: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46904',
    }),

    sandboxScreenSortByClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sortBy',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74869',
    }),

    actionsDeleteDropdownItemClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'deleteSandbox',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22171',
    }),

    deleteSandboxModalConfirmClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: deleteSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    deleteSandboxModalCloseClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: deleteSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    sandboxAddProductModalCloseClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: sandboxAddProductModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    overwriteSandboxModalCloseClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: overwriteSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    deleteSandboxUrlModalCloseClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: deleteSandboxUrlModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    deactivateSandboxModalConfirmClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: deactivateSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    sandboxAddProductModalConfirmClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: sandboxAddProductModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    overwriteSandboxModalConfirmClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: overwriteSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    sandboxOnboardingModalCloseClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: sandboxOnboardingModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    createSandboxModalCloseClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: createSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    deactivateSandboxModalCancelClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: deactivateSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    sandboxHardDeleteExpediteModalConfirmClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirm',
        source: sandboxHardDeleteExpediteModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25543',
    }),

    sandboxHardDeleteExpediteModalCancelClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source: sandboxHardDeleteExpediteModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),

    restoreSandboxButtonClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'restoreSandbox',
        source: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36002',
    }),

    createSandboxModalProductClicked: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'productDropdownItem',
        source: createSandboxModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19775',
    }),

    createSandboxModalCancelClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: createSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),

    createSandboxModalCreateClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createSandbox',
        source: createSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22167',
    }),

    createSandboxModalProductionLockedClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productionLocked',
        source: createSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75091',
    }),

    sandboxDataCloningModalCancelClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: sandboxDataCloningModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),

    sandboxDataCloningModalConfirmClicked: (attributes: { sandboxSiteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cloneSandboxData',
        source: sandboxDataCloningModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30475',
    }),

    sandboxPopoverTriggerClicked: (attributes: { siteId: string; productType: UiProductType; eventGroupId?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'showDetails',
        source: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39142',
    }),

    sandboxDataCloneDrawerCloseConfirmed: (attributes: { sandboxSiteId: string; parentSiteId: string; productKeys: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeSandboxDataCopyingDrawer',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47378',
    }),

    sandboxDataCloneSelectionTableSearched: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'searchSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45299',
    }),

    sandboxDataCloneSelectionTableCategorySelected: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'selectCategoryForSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45951',
    }),

    sandboxDataCloneSelectionTableTypeSelected: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'selectProjectTypeForSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62201',
    }),

    sandboxDataCloneSelectionTableSorted: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'sortSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45295',
    }),

    sandboxDataCloneSelectionTableNextPageClicked: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'goToNextPageOfSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45296',
    }),

    sandboxDataCloneSelectionTablePreviousPageClicked: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'goToPreviousPageOfSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45297',
    }),

    sandboxDataCloneSelectionTablePageSizeSelected: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      total: number | null;
      offset: number;
      limit: number;
      sortKey: JiraProjectSortKey;
      sortOrder: JiraProjectSortOrder;
      query: string;
    }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'selectPageSizeOfSandboxDataCopyingSelectionTable',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45298',
    }),

    sandboxDataCloneSelectDataCardSelected: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      cardType: string;
      isSelected: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'toggleDataCardOfSandboxDataClone',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/65777',
    }),

    sandboxDataCloningDrawerCopyDataClicked: (attributes: {
      sandboxSiteId: string;
      parentSiteId: string;
      productKeys: string;
      isSelective: boolean;
      migrationId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'clickCopyDataButtonOnSandboxDataCloningDrawer',
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47414',
    }),

    sandboxSelectedFeatureGateSite: (attributes: { name: string }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.SelectItem,
        actionSubjectId: 'sandboxSite',
        source: sandboxScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59256',
    }),

    sandboxTryItFreeButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'sandboxTryItFree',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59258',
    }),
    feedbackButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedbackButton',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74855',
    }),
    supportLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'feedbackLink',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74856',
    }),
    updateUrlClick: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'updateUrlLink',
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74856',
    }),
  },

  track: {
    sandboxCreated: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: createSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22172',
    }),

    sandboxDeleted: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: deleteSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22173',
    }),

    sandboxDeactivated: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deactivated,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: deactivateSandboxModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62433',
    }),

    sandboxHardDeleteExpedited: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: sandboxHardDeleteExpediteModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62024',
    }),

    sandboxRestored: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Restored,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36003',
    }),

    sandboxCanceledDeletion: (attributes: { siteId: string }): TrackEvent => ({
      data: {
        action: TrackAction.CanceledDeletion,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.siteId,
        source: sandboxScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28799',
    }),

    sandboxClonedData: ({
      sandboxSiteId,
      productKeys,
      isMediaIncluded,
      isFromDrawer = false,
    }: {
      sandboxSiteId: string;
      productKeys: string;
      isMediaIncluded: boolean;
      isFromDrawer: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.ClonedData,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: sandboxSiteId,
        source: isFromDrawer ? sandboxDataCloningDrawer : sandboxDataCloningModal,
        attributes: {
          productKeys,
          isMediaIncluded,
          isFromDrawer,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30583',
    }),

    sandboxClonedSelectiveData: (attributes: { sandboxSiteId: string; parentSiteId: string; productKeys: string }): TrackEvent => ({
      data: {
        action: TrackAction.ClonedSelectiveData,
        actionSubject: TrackActionSubject.Sandbox,
        actionSubjectId: attributes.sandboxSiteId,
        source: sandboxDataCloningDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46992',
    }),
  },

  operational: {
    sandboxDataFetch: (attributes: { orgId: string; context: 'sandboxSubscription' | 'sandboxPolled' }): OperationalEvent => ({
      data: {
        action: OperationalAction.Triggered,
        actionSubject: OperationalSubject.Feature,
        source: 'sandbox',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59251',
    }),
  },
};
