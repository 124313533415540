import type { FeatureEvents, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, Source, TrackAction, TrackActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'searchAdminHubTextFieldClickEvent'
  | 'searchFeedbackFooterButton'
  | 'searchOpenFeedbackDialog'
  | 'viewMoreAdminSearchResults'
  | 'tellMeMoreAboutSearchLink';

type TrackEventKeys =
  | 'searchNoResultsFound'
  | 'searchResultSelected'
  | 'searchDropdownNoActionTaken'
  | 'searchSomeResults'
  | 'searchErrorEncountered'
  | 'timeBetweenSearchesMeasured';

export enum AdminSearchSources {
  searchDialogFooter = 'searchDialogFooter',
  noSearchResultsView = 'noSearchResultsView',
}

export const navbar = 'navbar';

export const adminSearchEvents: FeatureEvents<string, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2747255203/Analytics+Spec+M1+Search',
  screen: {},
  ui: {
    searchAdminHubTextFieldClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.TextField,
        actionSubjectId: 'searchAdminHub',
        source: navbar,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55326',
    }),
    searchFeedbackFooterButton: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedback',
        source: AdminSearchSources.searchDialogFooter,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    searchOpenFeedbackDialog: ({ source }: { source: AdminSearchSources }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'leaveFeedbackDialog',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    viewMoreAdminSearchResults: (attributes: { sectionName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewMoreAdminSearchResults',
        source: 'AdminSearchDialog',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69753',
    }),
    tellMeMoreAboutSearchLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'supportOutlink',
        source: 'AdminSearchDialog',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69754',
    }),
  },
  track: {
    searchNoResultsFound: (attributes: {
      searchQueryLength?: number;
      searchQueryNumberOfWords?: number;
      searchResponseTime?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.SearchNoResults,
        source: navbar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54456',
    }),
    searchResultSelected: (attributes: {
      selection: string;
      numResults: number;
      listOrder: number;
      searchQueryLength?: number;
      searchQueryNumberOfWords?: number;
      hasAccess?: boolean;
      sectionName?: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.SearchDropdownEntry,
        source: navbar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54454',
    }),
    searchDropdownNoActionTaken: (attributes: {
      numResults: number;
      searchQueryLength?: number;
      searchQueryNumberOfWords?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.SearchDropdownNoAction,
        source: navbar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54455',
    }),
    searchSomeResults: (attributes: {
      searchQueryLength: number;
      numResults: number;
      searchQueryNumberOfWords: number;
      searchResponseTime: number;
      numSections?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.SearchSomeResults,
        source: navbar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57206',
    }),
    searchErrorEncountered: (attributes: {
      searchQueryLength: number;
      searchQueryNumberOfWords: number;
      searchResponseTime: number;
      httpErrorCode?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.SearchErrorEncountered,
        source: navbar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57207',
    }),
    timeBetweenSearchesMeasured: (attributes: { timeBetween: number }): TrackEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: TrackActionSubject.AdminSearchNavigation,
        source: Source.AdminSearchDialog,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69755',
    }),
  },
  operational: {},
};
