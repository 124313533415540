import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export const enum ActionTypes {
  CONTACT = 'contactUser',
  COPY = 'copyEmail',
}

const enum SettingType {
  ALLOW_NEW = 'allowNew',
  REQUIRE_REVIEW = 'requireReview',
}
const enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const enum StatusType {
  DENIED = 'denied',
  NEEDS_REVIEW = 'needsReview',
  UNDER_REVIEW = 'underReview',
  RESOLVED = 'resolved',
}

type UIEventKeys =
  | 'productSettingDropdownSelectEvent'
  | 'updateRequestSettingsButtonClickEvent'
  | 'requestSettingsButtonClickEvent'
  | 'learnMoreAboutProductRequestsLinkClickEvent'
  | 'learnMoreAboutProductRequestsSettingsLinkClickEvent'
  | 'reviewRequestButtonClickEvent'
  | 'sortByStatusIconClickEvent'
  | 'emailNotificationCheckboxCheckEvent'
  | 'backToButtonClickEvent'
  | 'infoAllowNewButtonClickEvent'
  | 'copyEmailTextCopyEvent'
  | 'denyRequestButtonClickEvent'
  | 'resolveRequestButtonClickEvent'
  | 'markRequestUnderReviewButtonClickEvent'
  | 'contactUserButtonClickEvent'
  | 'closeModalButtonClickEvent'
  | 'learnMoreEnterprisePlansLinkClickEvent'
  | 'requestProductButtonClickEvent'
  | 'requestProductCancelButtonClickEvent'
  | 'completeRequestButtonClickEvent'
  | 'signUpForProductLinkRequestScreenClickEvent'
  | 'signUpForProductLinkConfirmScreenClickEvent'
  | 'contactSupportLinkClickEvent';

type ScreenEventKeys =
  | 'initialProductRequestsScreenEvent'
  | 'productRequestsScreenEvent'
  | 'productRequestSettingsScreenEvent'
  | 'reviewProductRequestModalScreenEvent'
  | 'requestProductScreenEvent'
  | 'confirmRequestedProductScreenEvent';

type TrackEventKeys = 'productStatusUpdated' | 'productSettingUpdated' | 'emailNotificationSettingUpdated' | 'productRequestSubmitted';

export const initialProductRequestsScreen = 'initialProductRequestsScreen';
export const productRequestsScreen = 'productRequestsScreen';
export const productRequestSettingsScreen = 'productRequestSettingsScreen';
export const reviewProductRequestModal = 'reviewProductRequestModal';
export const requestProductScreen = 'requestProductScreen';
export const confirmRequestedProductScreen = 'confirmRequestedProductScreen';

export const shadowItEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2416460464/Analytics+Spec+Shadow+IT',
  screen: {
    initialProductRequestsScreenEvent: (attributes: { isEnterprise: boolean; settingsConfigured: boolean }): ScreenEvent => ({
      data: {
        name: initialProductRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50017',
    }),
    productRequestsScreenEvent: (attributes: {
      isEnterprise: boolean;
      settingsConfigured: boolean;
      openRequests: {
        [product: string]: {
          needsReview: number;
          underReview: number;
        };
      };
    }): ScreenEvent => ({
      data: {
        name: productRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50076',
    }),
    productRequestSettingsScreenEvent: (attributes: {
      emailEnabled: boolean;
      products: {
        [product: string]: SettingType;
      };
    }): ScreenEvent => ({
      data: {
        name: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50077',
    }),
    reviewProductRequestModalScreenEvent: (attributes: {
      userId: string;
      product: string;
      currentStatus: StatusType;
      lastUpdated: string;
      requestedOn: string;
    }): ScreenEvent => ({
      data: {
        name: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50078',
    }),
    requestProductScreenEvent: (attributes: { requestedProducts: string[]; allowedProducts: string[]; orgId: string }): ScreenEvent => ({
      data: {
        name: requestProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52973',
    }),
    confirmRequestedProductScreenEvent: (): ScreenEvent => ({
      data: {
        name: confirmRequestedProductScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52974',
    }),
  },
  ui: {
    updateRequestSettingsButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateRequestSettings',
        source: initialProductRequestsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50079',
    }),
    requestSettingsButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'requestSettings',
        source: productRequestsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50080',
    }),
    learnMoreAboutProductRequestsLinkClickEvent: ({
      source,
      ...attributes
    }: {
      settingsConfigured: boolean;
      source: 'initialProductRequestsScreen' | 'productRequestsScreen';
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreProductRequests',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53047',
    }),
    learnMoreAboutProductRequestsSettingsLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreProductRequestSettings',
        source: productRequestSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53048',
    }),
    reviewRequestButtonClickEvent: (attributes: { product: string; currentStatus: StatusType; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reviewRequest',
        source: productRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50082',
    }),
    sortByStatusIconClickEvent: (attributes: { sortDirection: SortDirection }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Icon,
        actionSubjectId: 'sortByStatus',
        source: productRequestsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50083',
    }),
    emailNotificationCheckboxCheckEvent: (attributes: { isEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'emailNotification',
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50084',
    }),
    productSettingDropdownSelectEvent: (attributes: { product: string; settingType: SettingType }): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'productSetting',
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54498',
    }),
    backToButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backTo',
        source: productRequestSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50086',
    }),
    infoAllowNewButtonClickEvent: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'infoAllowNew',
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50087',
    }),
    learnMoreEnterprisePlansLinkClickEvent: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreEnterprisePlans',
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51629',
    }),
    contactUserButtonClickEvent: (attributes: { userId: string; product: string; currentStatus: StatusType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'contactUser',
        source: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50091',
    }),
    copyEmailTextCopyEvent: (attributes: { userId: string; product: string; currentStatus: StatusType }): UIEvent => ({
      data: {
        action: Action.Copy,
        actionSubject: ActionSubject.Text,
        actionSubjectId: 'copyEmail',
        source: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50089',
    }),
    markRequestUnderReviewButtonClickEvent: (attributes: { userId: string; product: string; contactedUser: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'underReview',
        source: reviewProductRequestModal,
        attributes: {
          currentStatus: StatusType.NEEDS_REVIEW,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51644',
    }),
    denyRequestButtonClickEvent: (attributes: {
      userId: string;
      product: string;
      contactedUser: boolean;
      currentStatus: Exclude<StatusType, StatusType.DENIED | StatusType.RESOLVED>;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'denyRequest',
        source: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50090',
    }),
    resolveRequestButtonClickEvent: (attributes: { userId: string; product: string; contactedUser: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resolveRequest',
        source: reviewProductRequestModal,
        attributes: {
          currentStatus: StatusType.UNDER_REVIEW,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51642',
    }),
    closeModalButtonClickEvent: (attributes: { actionsTaken: ActionTypes[]; timeSpent: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeModal',
        source: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50092',
    }),
    requestProductButtonClickEvent: (attributes: { requestedProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'requestProduct',
        source: requestProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52977',
    }),
    requestProductCancelButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRequest',
        source: requestProductScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52978',
    }),
    completeRequestButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'completeRequest',
        source: confirmRequestedProductScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52979',
    }),
    signUpForProductLinkRequestScreenClickEvent: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'signUp',
        source: requestProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52980',
    }),
    signUpForProductLinkConfirmScreenClickEvent: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'signUp',
        source: confirmRequestedProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52980',
    }),
    contactSupportLinkClickEvent: (
      _,
      data: {
        source:
          | 'initialProductRequestsScreen'
          | 'productRequestsScreen'
          | 'productRequestTable'
          | 'requestProductScreen'
          | 'productRequestSettingsScreen';
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'contactSupport',
        source: data.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16337',
    }),
  },
  track: {
    productStatusUpdated: (attributes: {
      userId: string;
      product: string;
      contactedUser: boolean;
      newStatus: Exclude<StatusType, StatusType.NEEDS_REVIEW>;
      success: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ProductStatus,
        source: reviewProductRequestModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52984',
    }),
    productSettingUpdated: (attributes: { product: string; settingType: SettingType; success: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ProductSetting,
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52983',
    }),
    emailNotificationSettingUpdated: (attributes: { success: boolean; isEnabled: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Toggled,
        actionSubject: TrackActionSubject.EmailNotification,
        source: productRequestSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52982',
    }),
    productRequestSubmitted: (attributes: { success: boolean; errorCode?: string }): TrackEvent => ({
      data: {
        action: TrackAction.Submitted,
        actionSubject: TrackActionSubject.ProductRequest,
        source: requestProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52981',
    }),
  },
  operational: {},
};
