import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'apiTokenPoliciesListScreenEvent' | 'apiTokenPoliciesSettingsScreenEvent';
type UIEventKeys =
  | 'learnMoreLinkEvent'
  | 'apiTokenPolicyNameLinkEvent'
  | 'updateApiTokenPolicyButtonEvent'
  | 'confirmUpdateApiTokenPolicyButtonEvent'
  | 'exportUsersButtonEvent'
  | 'confirmExportUsersButtonEvent';

type TrackEventKeys = 'updateApiTokenPolicyTrackEvent';

export enum ApiTokenPoliciesScreens {
  usersExportModal = 'usersExportModal',
  apiTokenPoliciesListScreen = 'apiTokenPoliciesListScreen',
  apiTokenPoliciesSettingsScreen = 'apiTokenPoliciesSettingsScreen',
  updateApiTokenPolicyModal = 'updateApiTokenPolicyModal',
}

export const apiTokenPoliciesEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/2474279106/ATC+Milestone+1.0+Frontend+Analytics',
  screen: {
    apiTokenPoliciesListScreenEvent: (): ScreenEvent => ({
      data: {
        name: ApiTokenPoliciesScreens.apiTokenPoliciesListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50489',
    }),
    apiTokenPoliciesSettingsScreenEvent: (attributes: { policyId: string }): ScreenEvent => ({
      data: {
        name: ApiTokenPoliciesScreens.apiTokenPoliciesSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50490',
    }),
  },
  ui: {
    learnMoreLinkEvent: ({ source }: { source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50491',
    }),
    apiTokenPolicyNameLinkEvent: (attributes: { policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'apiTokenPolicyLink',
        source: ApiTokenPoliciesScreens.apiTokenPoliciesListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50643',
    }),
    updateApiTokenPolicyButtonEvent: (attributes: { setting: string; policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateApiTokenPolicy',
        source: ApiTokenPoliciesScreens.apiTokenPoliciesSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50644',
    }),
    confirmUpdateApiTokenPolicyButtonEvent: (attributes: { setting: string; policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmUpdateApiTokenPolicy',
        source: ApiTokenPoliciesScreens.updateApiTokenPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50645',
    }),
    exportUsersButtonEvent: (attributes: { policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportApiTokenUsers',
        source: ApiTokenPoliciesScreens.apiTokenPoliciesSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50646',
    }),
    confirmExportUsersButtonEvent: (attributes: { policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmExportApiTokenUsers',
        source: ApiTokenPoliciesScreens.usersExportModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50647',
    }),
  },
  track: {
    updateApiTokenPolicyTrackEvent: (attributes: { policyId: string; setting: string }): TrackEvent => ({
      data: {
        action: TrackAction.SettingsUpdated,
        actionSubject: TrackActionSubject.ApiTokenPolicies,
        source: ApiTokenPoliciesScreens.apiTokenPoliciesSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50648',
    }),
  },
  operational: {},
};
