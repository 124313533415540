import * as React from 'react';

import { ExperienceFailure } from '@atlassian/experience-tracker';

import { monitoringClient } from '@adminhub/monitoring-client';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

interface OwnProps {
  children: JSX.Element;
  fallback?: JSX.Element;
}

export class ErrorBoundary extends React.Component<OwnProps, ErrorBoundaryState> {
  public readonly state: Readonly<ErrorBoundaryState> = {
    hasError: false,
  };

  public componentDidCatch(error: Error) {
    this.setState({ hasError: true, error });
    monitoringClient.onError(error);
  }

  public render() {
    if (this.state.hasError) {
      // TODO: Decide on how to surface errors.
      return (
        <>
          <ExperienceFailure error={this.state.error || new Error('Unknown error')} />
          {this.props.fallback}
        </>
      );
    }

    return this.props.children;
  }
}
