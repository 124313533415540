import * as React from 'react';

import AKCheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import AkCrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import AkErrorIcon from '@atlaskit/icon/glyph/error';
import AkInfoIcon from '@atlaskit/icon/glyph/info';
import AkWarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';

export const createErrorIcon = () =>
  React.createElement(AkWarningIcon, { label: '', primaryColor: token('color.icon.warning', '#E56910') });
export const createDangerIcon = () =>
  React.createElement(AkWarningIcon, { label: '', primaryColor: token('color.icon.accent.red', '#C9372C') });
export const createRhombusErrorIcon = () =>
  React.createElement(AkErrorIcon, { label: '', primaryColor: token('color.icon.danger', '#C9372C') });
export const createSuccessIcon = () =>
  React.createElement(AKCheckCircleIcon, { label: '', primaryColor: token('color.icon.success', '#22A06B') });
export const createErrorCrossIcon = () =>
  React.createElement(AkCrossCircleIcon, { label: '', primaryColor: token('color.icon.accent.red', '#C9372C') });
export const createInfoIcon = () => React.createElement(AkInfoIcon, { label: '', primaryColor: token('color.icon.brand', '#0C66E4') });
