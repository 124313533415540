import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { UNSAFE_inverseColorMap, UNSAFE_useSurface } from '@atlaskit/primitives';
import { useHeading } from './heading-context';
var sizeTagMap = {
  xxlarge: 'h1',
  xlarge: 'h1',
  large: 'h2',
  medium: 'h3',
  small: 'h4',
  xsmall: 'h5',
  xxsmall: 'h6'
};
var headingResetStyles = css({
  letterSpacing: 'normal',
  marginBlock: 0,
  textTransform: 'none'
});
var useColor = function useColor(colorProp) {
  var surface = UNSAFE_useSurface();

  /**
   * Where the color of the surface is inverted we always override the color
   * as there is no valid choice that is not covered by the override.
   */
  if (UNSAFE_inverseColorMap.hasOwnProperty(surface)) {
    return UNSAFE_inverseColorMap[surface];
  }
  return colorProp || 'color.text';
};

/**
 * __Heading__
 *
 * Heading is a typography component used to display text in defined sizes and styles.
 *
 * @example
 *
 * ```jsx
 * <Heading size="xxlarge">Page title</Heading>
 * ```
 */
var Heading = function Heading(_ref) {
  var children = _ref.children,
    size = _ref.size,
    id = _ref.id,
    testId = _ref.testId,
    as = _ref.as,
    colorProp = _ref.color;
  if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production' && as && typeof as !== 'string') {
    throw new Error('`as` prop should be a string.');
  }

  // Technically size can be undefined here due to how the types work.
  // Once removing the level prop this assertion can be removed since size will be a required prop.
  var _useHeading = useHeading(sizeTagMap[size]),
    _useHeading2 = _slicedToArray(_useHeading, 2),
    hLevel = _useHeading2[0],
    inferredElement = _useHeading2[1];
  var Component = as || inferredElement;
  var needsAriaRole = Component === 'div' && hLevel;
  var color = useColor(colorProp);
  return jsx(Component, {
    id: id,
    "data-testid": testId,
    role: needsAriaRole ? 'heading' : undefined,
    "aria-level": needsAriaRole ? hLevel : undefined,
    css: [headingResetStyles, size && headingSizeStylesMap[size], headingColorStylesMap[color]]
  }, children);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export var headingColorStylesMap = {
  'color.text': css({
    color: "var(--ds-text, #172B4D)"
  }),
  'color.text.inverse': css({
    color: "var(--ds-text-inverse, #FFFFFF)"
  }),
  'color.text.warning.inverse': css({
    color: "var(--ds-text-warning-inverse, #172B4D)"
  })
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::df829c6c0aa19bb57c0c77dc08d12d60>>
 * @codegenId typography
 * @codegenCommand yarn workspace @atlaskit/heading codegen
 */
var headingSizeStylesMap = {
  xxlarge: css({
    font: "var(--ds-font-heading-xxlarge, normal 500 35px/40px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xlarge: css({
    font: "var(--ds-font-heading-xlarge, normal 600 29px/32px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  large: css({
    font: "var(--ds-font-heading-large, normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  medium: css({
    font: "var(--ds-font-heading-medium, normal 500 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  small: css({
    font: "var(--ds-font-heading-small, normal 600 16px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xsmall: css({
    font: "var(--ds-font-heading-xsmall, normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xxsmall: css({
    font: "var(--ds-font-heading-xxsmall, normal 600 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  })
};
/**
 * @codegenEnd
 */

export default Heading;