import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'dataClassificationLandingPageEvent'
  | 'dataClassificationLevelDetailsPageEvent'
  | 'dataClassificationEditFocusTaskEvent'
  | 'dataClassificationOnboardingPageEvent'
  | 'dataClassificationArchiveConfirmationModalEvent'
  | 'dataClassificationPublishConfirmationModalEvent'
  | 'dataClassificationArchivedLevelsTableModalEvent'
  | 'dataClassificationArchiveDspAttachedModalEvent'
  | 'dataClassificationFocusTaskEvent'
  | 'dataClassificationOnboardingSelectOptionsFocusTaskEvent'
  | 'dataClassificationOnboardingTemplateInformationFocusTaskEvent'
  | 'dataClassificationSettingsPageEvent';

type UIEventKeys =
  | 'learnMoreLinkClickEvent'
  | 'createLevelButtonClickEvent'
  | 'levelDetailsLinkClickEvent'
  | 'archiveButtonLandingPageButtonClickEvent'
  | 'publishButtonLandingPageButtonClickEvent'
  | 'manageSettingsButtonLandingPageButtonClickEvent'
  | 'archivedLevelsButtonClickEvent'
  | 'publishAllLevelsButtonClickEvent'
  | 'viewAuditLogButtonClickEvent'
  | 'useTemplateCardCardSelectEvent'
  | 'customLevelCardCardSelectEvent'
  | 'onboardingLearnMoreLinkClickEvent'
  | 'getStartedButtonClickEvent'
  | 'stepOneNextButtonClickEvent'
  | 'stepOneBackButtonClickEvent'
  | 'stepTwoSaveButtonClickEvent'
  | 'stepTwoSaveAndCreateAnotherButtonClickEvent'
  | 'stepTwoBackButtonClickEvent'
  | 'confirmArchiveButtonClickEvent'
  | 'archiveCancelButtonClickEvent'
  | 'confirmPublishButtonClickEvent'
  | 'publishCancelButtonClickEvent'
  | 'archivedLevelsCloseButtonClickEvent'
  | 'restoreButtonClickEvent'
  | 'publishButtonDetailsPageButtonClickEvent'
  | 'archiveButtonDetailsPageButtonClickEvent'
  | 'detailsPageViewAuditLogsLinkClickEvent'
  | 'detailsPageBackButtonClickEvent'
  | 'editButtonDetailsPageClickEvent'
  | 'editButtonLandingPageClickEvent'
  | 'cancelDspArchiveButtonClickEvent'
  | 'visitDspArchiveButtonClickEvent'
  | 'onboardingSelectOptionsBackButtonClickEvent'
  | 'onboardingSelectOptionsNextButtonClickEvent'
  | 'onboardingTemplateInformationCloseButtonClickEvent'
  | 'closeEditDrawerClickEvent'
  | 'saveEditDrawerContentClickEvent'
  | 'editDrawerBackButtonClickEvent'
  | 'editDrawerNextButtonClickEvent'
  | 'editDrawerLearnMoreLinkClickEvent'
  | 'moveToTopButtonClickEvent'
  | 'moveUpButtonClickEvent'
  | 'moveDownButtonClickEvent'
  | 'moveToBottomButtonClickEvent'
  | 'moveButtonClickEvent'
  | 'whatsNextLearnMoreLinkClickEvent'
  | 'updateSettingsButtonClickEvent'
  | 'settingsPageCancelButtonClickEvent';

type TrackEventKeys =
  | 'dataClassificationLevelCreatedEvent'
  | 'dataClassificationLevelUpdatedEvent'
  | 'dataClassificationLevelReorderedEvent'
  | 'dataClassificationLevelCreatedFromTemplateEvent'
  | 'dataClassificationLevelArchivedEvent'
  | 'dataClassificationLevelRestoredEvent'
  | 'dataClassificationLevelPublishedEvent'
  | 'dataClassificationUpdateSettingsEvent';

export const dataClassificationLandingPage = 'dataClassificationLandingPage';
export const dataClassificationLevelDetailsPage = 'dataClassificationLevelDetailsPage';
export const dataClassificationCreateFocusTask = 'dataClassificationCreateFocusTask';
export const dataClassificationEditFocusTask = 'dataClassificationEditFocusTask';
export const dataClassificationFocusTask = 'dataClassificationFocusTask';
export const dataClassificationOnboardingSelectOptionsFocusTask = 'dataClassificationOnboardingSelectOptionsFocusTask';
export const dataClassificationOnboardingTemplateInformationFocusTask = 'dataClassificationOnboardingTemplateInformationFocusTask';
export const dataClassificationOnboardingPage = 'dataClassificationOnboardingPage';
export const dataClassificationArchiveConfirmationModal = 'dataClassificationArchiveConfirmationModal';
export const dataClassificationPublishConfirmationModal = 'dataClassificationPublishConfirmationModal';
export const dataClassificationArchivedLevelsTableModal = 'dataClassificationArchivedLevelsTableModal';
export const dataClassificationArchiveDspAttachedModal = 'dataClassificationArchiveDspAttachedModal';
export const dataClassificationSettingsPage = 'dataClassificationSettingsPage';

interface CreateLevelAttributes {
  name: string;
  definition: string;
  color: string;
  guidelines: string;
}

export const dataClassificationEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/SA/pages/2725942387',
  screen: {
    dataClassificationLandingPageEvent: (): ScreenEvent => ({
      data: {
        name: dataClassificationLandingPage,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58666',
    }),
    dataClassificationLevelDetailsPageEvent: (attributes: { levelId: string }): ScreenEvent => ({
      data: {
        name: dataClassificationLevelDetailsPage,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58665',
    }),
    dataClassificationEditFocusTaskEvent: (): ScreenEvent => ({
      data: {
        name: dataClassificationEditFocusTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66263',
    }),
    dataClassificationFocusTaskEvent: (): ScreenEvent => ({
      data: {
        name: dataClassificationFocusTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60622',
    }),
    dataClassificationOnboardingPageEvent: (): ScreenEvent => ({
      data: {
        attributes: {
          referrer: 'direct',
        },
        name: dataClassificationOnboardingPage,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58668',
    }),
    dataClassificationOnboardingSelectOptionsFocusTaskEvent: (): ScreenEvent => ({
      data: {
        attributes: {
          referrer: 'direct',
        },
        name: dataClassificationOnboardingSelectOptionsFocusTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60864',
    }),
    dataClassificationArchiveConfirmationModalEvent: (attributes: { levelId: string }): ScreenEvent => ({
      data: {
        name: dataClassificationArchiveConfirmationModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58662',
    }),
    dataClassificationPublishConfirmationModalEvent: (attributes: { levelId: string }): ScreenEvent => ({
      data: {
        name: dataClassificationPublishConfirmationModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58663',
    }),
    dataClassificationArchivedLevelsTableModalEvent: (): ScreenEvent => ({
      data: {
        name: dataClassificationArchivedLevelsTableModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58661',
    }),
    dataClassificationOnboardingTemplateInformationFocusTaskEvent: (): ScreenEvent => ({
      data: {
        name: dataClassificationOnboardingTemplateInformationFocusTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60865',
    }),
    dataClassificationArchiveDspAttachedModalEvent: (attributes: { levelId: string }): ScreenEvent => ({
      data: {
        name: dataClassificationArchiveDspAttachedModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60618',
    }),
    dataClassificationSettingsPageEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: dataClassificationSettingsPage,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68871',
    }),
  },
  ui: {
    learnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'dataClassificationLandingPageLearnMoreLink',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58771',
    }),
    createLevelButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createLevelButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58671',
    }),
    levelDetailsLinkClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'levelDetailsLink',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58672',
    }),
    archiveButtonLandingPageButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'archiveButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58675',
    }),
    publishButtonLandingPageButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'publishButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58674',
    }),
    manageSettingsButtonLandingPageButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageSettingsButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/68892',
    }),
    archivedLevelsButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'archivedLevelsButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58673',
    }),
    publishAllLevelsButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'publishAllLevelsButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58689',
    }),
    viewAuditLogButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAuditLogButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58679',
    }),
    editButtonLandingPageClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editLandingPage',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60126',
    }),
    moveToTopButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveToTopButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62093',
    }),
    moveUpButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveUpButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62095',
    }),
    moveDownButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveDownButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62096',
    }),
    moveToBottomButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveToBottomButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62097',
    }),
    moveButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moveButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62100',
    }),
    useTemplateCardCardSelectEvent: (): UIEvent => ({
      data: {
        source: dataClassificationOnboardingPage,
        action: Action.Selected,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'useTemplateCard',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58681',
    }),
    customLevelCardCardSelectEvent: (): UIEvent => ({
      data: {
        source: dataClassificationOnboardingPage,
        action: Action.Selected,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'customLevelCard',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58669',
    }),
    onboardingLearnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationOnboardingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'onboardingLearnMoreLink',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58678',
    }),
    whatsNextLearnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationOnboardingTemplateInformationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'whatsNextLearnMoreLink',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58703',
    }),

    getStartedButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationOnboardingPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'getStartedButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58685',
    }),
    stepOneNextButtonClickEvent: (attributes: CreateLevelAttributes): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'stepOneNextButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58688',
    }),
    onboardingSelectOptionsBackButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'onboardingSelectOptionsBackButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60623',
    }),
    onboardingSelectOptionsNextButtonClickEvent: (attributes?: CreateLevelAttributes): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'onboardingSelectOptionsNextButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60624',
    }),
    onboardingTemplateInformationCloseButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'onboardingTemplateInformationCloseButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60625',
    }),
    stepTwoBackButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'stepTwoBackButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58684',
    }),
    stepOneBackButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'stepOneBackButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58677',
    }),
    stepTwoSaveButtonClickEvent: (attributes: CreateLevelAttributes): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'stepTwoSave',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58676',
    }),
    stepTwoSaveAndCreateAnotherButtonClickEvent: (attributes: CreateLevelAttributes): UIEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'stepTwoSaveAndCreateAnotherButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58695',
    }),
    confirmArchiveButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationArchiveConfirmationModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmArchiveButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58693',
    }),
    archiveCancelButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationArchiveConfirmationModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'archiveCancelButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58773',
    }),
    confirmPublishButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationPublishConfirmationModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmPublishButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58682',
    }),
    publishCancelButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationPublishConfirmationModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'publishCancelButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58774',
    }),
    archivedLevelsCloseButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationArchivedLevelsTableModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'archivedLevelsCloseButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58776',
    }),
    restoreButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationArchivedLevelsTableModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'restoreButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58687',
    }),
    detailsPageBackButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'detailsPageBackButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58777',
    }),
    publishButtonDetailsPageButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'publish',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58691',
    }),
    archiveButtonDetailsPageButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'archive',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58692',
    }),
    detailsPageViewAuditLogsLinkClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'detailsPageViewAuditLogs',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58778',
    }),
    editButtonDetailsPageClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editDetailsPage',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60125',
    }),
    cancelDspArchiveButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationArchiveDspAttachedModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDspArchiveButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60688',
    }),
    visitDspArchiveButtonClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationArchiveDspAttachedModal,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'visitDspArchiveButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60620',
    }),
    closeEditDrawerClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationEditFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeEditDrawerButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61750',
    }),
    saveEditDrawerContentClickEvent: (attributes: { levelId: string }): UIEvent => ({
      data: {
        source: dataClassificationEditFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveEditDrawerButton',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61751',
    }),
    editDrawerBackButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationEditFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editDrawerBackButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61752',
    }),
    editDrawerNextButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationEditFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editDrawerNextButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61753',
    }),
    editDrawerLearnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationEditFocusTask,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'editDrawerLearnMoreLink',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61789',
    }),
    updateSettingsButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationSettingsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateSettingsButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69216',
    }),
    settingsPageCancelButtonClickEvent: (): UIEvent => ({
      data: {
        source: dataClassificationSettingsPage,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'settingsPageCancelButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69245',
    }),
  },
  track: {
    dataClassificationLevelCreatedEvent: (attributes: CreateLevelAttributes): TrackEvent => ({
      data: {
        source: dataClassificationFocusTask,
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58702',
    }),
    dataClassificationLevelUpdatedEvent: (attributes: {
      levelId: string;
      name: string;
      definition: string;
      guidelines: string;
      color: string;
      status: string;
      sensitivity: number;
    }): TrackEvent => ({
      data: {
        source: dataClassificationLevelDetailsPage,
        action: 'updated',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58698',
    }),
    dataClassificationLevelReorderedEvent: (attributes: { levelIds: string[] }): TrackEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: 'reordered',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58699',
    }),
    dataClassificationLevelCreatedFromTemplateEvent: (attributes: CreateLevelAttributes): TrackEvent => ({
      data: {
        source: dataClassificationOnboardingPage,
        action: 'createdFromTemplate',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58697',
    }),
    dataClassificationLevelArchivedEvent: (attributes: { levelId: string }): TrackEvent => ({
      data: {
        source: dataClassificationArchiveConfirmationModal,
        action: 'archived',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58696',
    }),
    dataClassificationLevelRestoredEvent: (attributes: { levelId: string }): TrackEvent => ({
      data: {
        source: dataClassificationArchivedLevelsTableModal,
        action: 'restored',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58701',
    }),
    dataClassificationLevelPublishedEvent: (attributes: { levelIds: string[] }): TrackEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: 'published',
        actionSubject: TrackActionSubject.DataClassificationLevel,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58700',
    }),
    dataClassificationUpdateSettingsEvent: (): TrackEvent => ({
      data: {
        source: dataClassificationLandingPage,
        action: 'updatedSettings',
        actionSubject: TrackActionSubject.DataClassificationLevel,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69209',
    }),
  },
  operational: {},
};
