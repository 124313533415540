import { Action, ActionSubject } from '../new-analytics-types';

import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';

export type ScreenKeys =
  | 'updateLinksScreenEvent'
  | 'focusPageUpdateLinksScreenEvent'
  | 'focusPageReviewScreenEvent'
  | 'lmsAuditLogDownloadScreen'
  | 'lmsAuditLogDownloadErrorScreen';
export type UIKeys =
  | 'updateLinksActionClickEvent'
  | 'downloadHistoryClickEvent'
  | 'downloadAuditLogClickEvent'
  | 'downloadAuditLogCsvEvent'
  | 'reviewFocusPageClickEvent'
  | 'startUpdatingLinksClickEvent'
  | 'focusPageBackClickEvent'
  | 'focusPageCloseClickEvent'
  | 'addProductClickEvent'
  | 'removeProductClickEvent'
  | 'migratedFromBackupChangeEvent'
  | 'learnMoreClickEvent';

export type LMSFocusPageType = 'update-links' | 'review';

// Screens
export const lmsFocusPageReviewScreen = 'focusPageReviewScreen';
export const linksMigrationUpdateLinksScreen = 'updateLinksScreen';
export const lmsFocusPageUpdateLinksScreen = 'focusPageUpdateLinksScreen';
export const lmsAuditLogDownloadScreen = 'auditLogDownloadLinksMigrationScreen';
export const lmsAuditLogDownloadErrorScreen = 'auditLogDownloadErrorLinksMigrationScreen';

// UI actionSubjectIds
export const lmsLearnMoreLink = 'helpLink';
export const lmsRemoveProductButton = 'removeProductButton';
export const lmsFocusPageBackButton = 'focusPageBackButton';
export const lmsFocusPageCloseButton = 'focusPageCloseButton';
export const lmsDownloadHistoryButton = 'downloadHistoryButton';
export const lmsReviewFocusPageButton = 'reviewFocusPageButton';
export const lmsDownloadAuditLogButton = 'downloadAuditLogButton';
export const lmsUpdateLinksActionButton = 'updateLinksActionButton';
export const lmsAddAnotherProductButton = 'addAntoherProductButton';
export const lmsStartUpdatingLinksButton = 'startUpdatingLinksButton';
export const lmsChangeMigratedFromBackupCheckbox = 'changeMigratedFromBackupCheckbox';

export const linksMigrationEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/MigPT/pages/2454321721/FBL+UI+Analytics+-+PROPOSAL',
  screen: {
    updateLinksScreenEvent: (): ScreenEvent => ({
      data: {
        name: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50294',
    }),
    focusPageUpdateLinksScreenEvent: (): ScreenEvent => ({
      data: {
        name: lmsFocusPageUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53625',
    }),
    focusPageReviewScreenEvent: (): ScreenEvent => ({
      data: {
        name: lmsFocusPageReviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53627',
    }),
    lmsAuditLogDownloadScreen: (): ScreenEvent => ({
      data: {
        name: lmsAuditLogDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60519',
    }),
    lmsAuditLogDownloadErrorScreen: (): ScreenEvent => ({
      data: {
        name: lmsAuditLogDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60567',
    }),
  },
  ui: {
    updateLinksActionClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsUpdateLinksActionButton,
        source: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50419',
    }),
    downloadHistoryClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsDownloadHistoryButton,
        source: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50420',
    }),
    downloadAuditLogClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsDownloadAuditLogButton,
        source: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50421',
    }),
    downloadAuditLogCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'forceDownloadAuditLogCsv',
        source: lmsAuditLogDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16830',
    }),
    reviewFocusPageClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsReviewFocusPageButton,
        source: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50487',
    }),
    startUpdatingLinksClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsStartUpdatingLinksButton,
        source: linksMigrationUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50488',
    }),
    focusPageBackClickEvent: (attributes: { page: LMSFocusPageType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsFocusPageBackButton,
        source: linksMigrationUpdateLinksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50500',
    }),
    focusPageCloseClickEvent: (attributes: { page: LMSFocusPageType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsFocusPageCloseButton,
        source: linksMigrationUpdateLinksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50501',
    }),
    addProductClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsAddAnotherProductButton,
        source: lmsFocusPageUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53629',
    }),
    removeProductClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: lmsRemoveProductButton,
        source: lmsFocusPageUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53630',
    }),
    migratedFromBackupChangeEvent: (attributes: { checked?: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: lmsChangeMigratedFromBackupCheckbox,
        source: lmsFocusPageUpdateLinksScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54006',
    }),
    learnMoreClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: lmsLearnMoreLink,
        source: lmsFocusPageUpdateLinksScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53633',
    }),
  },
  track: {},
  operational: {},
};
