import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'deleteConflictingSpacesClicked'
  | 'deleteConflictingSpacesConsentClicked'
  | 'excludeConflictingSpacesClicked'
  | 'excludeConflictingProjectsClicked'
  | 'deleteConflictingProjectsClicked'
  | 'deleteConflictingProjectsConsentClicked'
  | 'mergeConflictingCustomerOrgsConsentClicked'
  | 'downloadCsvClicked'
  | 'continueMergeGroupsClicked'
  | 'undoConsentClicked'
  | 'continuePublicAccessClicked';

const copyProductValidationScreen = 'copyProductValidationScreen';

export const dapoCopyEvents: FeatureEvents<string, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/l/cp/dQmB2tLk',
  screen: {},
  track: {},
  operational: {},
  ui: {
    mergeConflictingCustomerOrgsConsentClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoMergeConflictingCustomerOrgs',
        source: copyProductValidationScreen,
        attributes,
      },
      // todo
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71373',
    }),
    deleteConflictingSpacesClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoDeleteConflictingSpaces',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60377',
    }),
    deleteConflictingSpacesConsentClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoDeleteConflictingSpacesConsent',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67117',
    }),
    excludeConflictingSpacesClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoExcludeConflictingSpaces',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57709',
    }),
    excludeConflictingProjectsClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoExcludeConflictingProjects',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59049',
    }),
    deleteConflictingProjectsClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoDeleteConflictingProjects',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60811',
    }),
    deleteConflictingProjectsConsentClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoDeleteConflictingProjectsConsent',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67404',
    }),
    downloadCsvClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoDownloadCsv',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57710',
    }),
    continueMergeGroupsClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoContinueMergeGroups',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57711',
    }),
    undoConsentClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoUndoConsent',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57713',
    }),
    continuePublicAccessClicked: (attributes: {
      product: string;
      migrationType: string;
      migrationId: string;
      validationId: string;
      operationKey: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dapoContinuePublicAccess',
        source: copyProductValidationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57714',
    }),
  },
};
