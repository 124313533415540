import * as React from 'react';

import AkPageHeader from '@atlaskit/page-header';

interface PageHeaderOwnProps {
  breadcrumbs?: React.ReactElement;
  actions?: React.ReactElement;
  children?: React.ReactNode;
  bottomBar?: React.ReactElement;
  disableTitleStyles?: boolean;
}

export const PageHeader: React.FC<PageHeaderOwnProps> = (props) => <AkPageHeader {...props} />;
