import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'orgPickerGuardScreenEvent'
  | 'orgPickerGuardFullScreenEvent'
  | 'orgPickerGuardEmptyScreenEvent'
  | 'orgPickerGuardPremiumScreenEvent'
  | 'orgPickerGuardPremiumFullScreenEvent'
  | 'orgPickerGuardPremiumEmptyScreenEvent'
  | 'guardTrialSelectionScreenEvent'
  | 'guardPremiumTrialSelectionScreenEvent'
  | 'guardPremiumTrialHamsScreenEvent'
  | 'guardPremiumTrialNoSitesScreenEvent'
  | 'guardPremiumTrialSelectSitesScreenEvent'
  | 'guardPremiumTrialSelectSiteWarningScreenEvent';

type UIEventKeys =
  | 'selectOrgButtonClickEvent'
  | 'createOrganizationButtonClickEvent'
  | 'exploreAtlassianGuardLinkClickEvent'
  | 'goToAdminHubButtonClickEvent'
  | 'termsOfServiceLinkClickEvent'
  | 'privacyPolicyLinkClickEvent'
  | 'googlePrivacyLinkClickEvent'
  | 'googleTermsLinkClickEvent'
  | 'moreAboutPlansAndPricingLinkClickEvent'
  | 'closeButtonGuardClickEvent'
  | 'closeButtonNoGuardClickEvent'
  | 'securityGuideLinkClickEvent'
  | 'closeButtonHamsLinkClickEvent'
  | 'contactSupportHamsButtonClickEvent'
  | 'closeButtonCCPButtonClickEvent'
  | 'addProductButtonClickEvent'
  | 'guardPremiumTrialLinkClickEvent'
  | 'guardPremiumCloseButtonClickEvent'
  | 'guardPremiumStartTrialButtonClickEvent'
  | 'guardPremiumTrialSelectSiteWarningBackButtonClickEvent'
  | 'guardPremiumTrialSelectSiteWarningCloseButtonClickEvent'
  | 'startTrialGuardStandardButtonClickEvent'
  | 'startTrialGuardPremiumButtonClickEvent'
  | 'xCloseButtonClickEvent'
  | 'closeButtonClickEvent'
  | 'guardDetectLinkClickEvent'
  | 'learnMoreDataClassificationsClickEvent';

type TrackEventKeys = 'guardStandardTrialStartEvent' | 'guardPremiumTrialStartEvent';

export type AgpFlows = 'wacGeneric' | 'premiumFeatureGate' | 'wacPremium' | 'ahFeatureGate';

export type AgpEventSources =
  | 'orgPickerGuardPremium'
  | 'orgPickerGuardPremiumEmpty'
  | 'orgPickerGuardPremiumFull'
  | 'orgPickerGuard'
  | 'orgPickerGuardEmpty'
  | 'orgPickerGuardFull'
  | 'guardTrialSelection'
  | 'guardPremiumTrialSelection'
  | 'guardPremiumTrialSelectSite'
  | 'guardPremiumTrialNoSites'
  | 'guardPremiumTrialHams'
  | 'dataClassificationFeatureGate';

export interface AgpTrackAttributes {
  source: AgpFlows;
}

export const agpOnboardingEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/4003169653/Guard+Sign+Up+Flow+Overhaul+Analytics+Spec',
  screen: {
    orgPickerGuardScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'orgPickerGuard',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70308',
    }),
    orgPickerGuardFullScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'orgPickerGuardFull',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70311',
    }),
    orgPickerGuardEmptyScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'orgPickerGuardEmpty',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70312',
    }),
    orgPickerGuardPremiumScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'orgPickerGuardPremium',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70313',
    }),
    orgPickerGuardPremiumFullScreenEvent: (attributes: { source: string }): ScreenEvent => ({
      data: {
        name: attributes.source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70314',
    }),
    orgPickerGuardPremiumEmptyScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'orgPickerGuardPremiumEmpty',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70315',
    }),
    guardTrialSelectionScreenEvent: (attributes: { path: string }): ScreenEvent => ({
      data: {
        name: 'guardTrialSelection',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70316',
    }),
    guardPremiumTrialSelectionScreenEvent: (attributes: { path: string }): ScreenEvent => ({
      data: {
        name: 'guardPremiumTrialSelection',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70317',
    }),
    guardPremiumTrialHamsScreenEvent: (attributes: { path: string }): ScreenEvent => ({
      data: {
        name: 'guardPremiumTrialHams',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70318',
    }),
    guardPremiumTrialNoSitesScreenEvent: (attributes: { path: string }): ScreenEvent => ({
      data: {
        name: 'guardPremiumTrialNoSites',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70319',
    }),
    guardPremiumTrialSelectSitesScreenEvent: (attributes: { path: string }): ScreenEvent => ({
      data: {
        name: 'guardPremiumTrialSelectSite',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70320',
    }),
    guardPremiumTrialSelectSiteWarningScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'guardPremiumTrialSelectSiteWarning',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70321',
    }),
  },
  ui: {
    closeButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    xCloseButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'xCloseButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30332',
    }),
    startTrialGuardPremiumButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'startTrialGuardPremium',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70514',
    }),
    startTrialGuardStandardButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'startTrialGuardStandard',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70515',
    }),
    selectOrgButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectOrg',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19439',
    }),
    createOrganizationButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createOrganization',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62092',
    }),
    exploreAtlassianGuardLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'exploreAtlassianGuard',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67669',
    }),
    goToAdminHubButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goToAdminHub',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62032',
    }),
    termsOfServiceLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'termsOfService',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60241',
    }),
    privacyPolicyLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'privacyPolicy',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43298',
    }),
    googlePrivacyLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'googlePrivacy',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70363',
    }),
    moreAboutPlansAndPricingLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreAboutPricing',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71381',
    }),
    googleTermsLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'googleTerms',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70364',
    }),
    closeButtonGuardClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30332',
    }),
    closeButtonNoGuardClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    securityGuideLinkClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'securityGuide',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56123',
    }),
    closeButtonHamsLinkClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'close',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16337',
    }),
    contactSupportHamsButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'contactSupport',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    closeButtonCCPButtonClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    addProductButtonClickEvent: (attributes: { source: AgpEventSources }): UIEvent => ({
      data: {
        source: attributes.source,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addProduct',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27990',
    }),
    guardPremiumTrialLinkClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'guardPremiumTrial',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70322',
    }),
    guardPremiumCloseButtonClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeButton',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70323',
    }),
    guardPremiumStartTrialButtonClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'startTrial',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70324',
    }),
    guardPremiumTrialSelectSiteWarningBackButtonClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectSite',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),
    guardPremiumTrialSelectSiteWarningCloseButtonClickEvent: (): UIEvent => ({
      data: {
        source: 'agpSignup',
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    guardDetectLinkClickEvent: (): UIEvent => ({
      data: {
        source: 'guardPremiumTrialSelectSite',
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'guardDetect',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72213',
    }),
    learnMoreDataClassificationsClickEvent: (): UIEvent => ({
      data: {
        source: 'dataClassificationFeatureGate',
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreDataClassifications',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72472',
    }),
  },
  track: {
    guardStandardTrialStartEvent: (attributes: AgpTrackAttributes): TrackEvent => ({
      data: {
        source: attributes.source,
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.AgpStandardSignup,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72462',
    }),
    guardPremiumTrialStartEvent: (attributes: AgpTrackAttributes): TrackEvent => ({
      data: {
        source: attributes.source,
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.AgpPremiumSignup,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72463',
    }),
  },
  operational: {},
};
