import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';

import { Box as AkBox, xcss as akXcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

// Updated the Main element used in packages/common/page-layout/src/ui/main-layout.tsx since there were two main elements affecting accessibility . This happened after Page Layout was updated which now wraps everything in a Main tag.
// Note that breadcrumbs, which are wrapped by a nav landmark element, are a child but this is fine since breadcrumbs are a special page-specific navigation https://stackoverflow.com/a/22549923
export const Main = styled.div<{ preventContentOverflow: boolean }>`
  margin-top: ${token('space.negative.100', '-8px')};
  padding-bottom: ${token('space.400', '32px')};
  padding-left: ${token('space.300', '24px')};
  padding-right: ${token('space.300', '24px')};
  contain: ${({ preventContentOverflow }) => (preventContentOverflow ? 'paint' : 'unset')};
`;

export const Description = styled.div`
  max-width: 900px;
  margin-top: ${token('space.negative.100', '-8px')};
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
export const Title = styled.span`
  font-size: 1em;
  font-weight: 500;
  padding-top: ${'3'}px;
  margin-top: 0;
  ${({ isDisabled }: { isDisabled: boolean }) =>
    isDisabled
      ? css`
          color: ${token('color.text.disabled', '#A5ADBA')};
        `
      : ''};
`;

export const Subtitle = styled.span`
  font-style: italic;
  font-size: 12px;
  ${({ isDisabled }: { isDisabled: boolean }) =>
    isDisabled
      ? css`
          color: ${token('color.text.disabled', '#A5ADBA')};
        `
      : ''};
`;

export const Avatar = styled.div`
  padding-right: ${token('space.150', '12px')};
  float: left;
  ${({ isDisabled }: { isDisabled: boolean }) =>
    isDisabled
      ? css`
          filter: grayscale(100%);
        `
      : ''} & > div {
    display: initial !important;
  }
`;

export const Icon = styled.div`
  line-height: 1;
  margin-right: ${token('space.100', '8px')};
  ${({ isDisabled }: { isDisabled: boolean }) =>
    isDisabled
      ? css`
          filter: grayscale(100%);
        `
      : ''};
`;

export const Children: React.FC<{ xcss?: ReturnType<typeof akXcss> }> = ({ children, xcss }) => {
  const defaultChildrenXcss = akXcss({
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
    marginTop: 'space.300',
  });

  return <AkBox xcss={[defaultChildrenXcss, xcss]}>{children}</AkBox>;
};

export const PageBannerWrapper = styled.div`
  margin-top: ${token('space.100', '8px')};
  margin-bottom: ${token('space.negative.050', '-4px')};
`;

const AssessmentsLayoutWrapper = styled.div<{ heightCss: string }>`
  height: ${({ heightCss }) => heightCss};
  overflow: auto;
`;

export const CustomLayoutHeightWrapper: React.FC<{ heightCss?: string; children: React.ReactNode }> = ({ heightCss, children }) => {
  if (!heightCss) return <>{children}</>;

  return <AssessmentsLayoutWrapper heightCss={heightCss}>{children}</AssessmentsLayoutWrapper>;
};
