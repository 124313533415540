import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'selfSignUpViewEventData'
  | 'inviteUrlsScreenEvent'
  | 'accessRequestsScreenEvent'
  | 'approvedDomainScreen'
  | 'openInviteScreen'
  | 'regenerateInviteLinkModal';

type UIEventKeys =
  | 'vogueMigrationBannerLearnMoreClick'
  | 'selfSignUpSaveClickEventData'
  | 'inviteUrlCheckbox'
  | 'inviteUrlToggle'
  | 'copyInviteUrlButton'
  | 'regenerateInviteUrlLink'
  | 'inviteUrlToggleNew'
  | 'copyInviteLinkButton'
  | 'regenerateInviteLinkLink'
  | 'saveChangesButtonUIEventData'
  | 'accessRequestsRadioButtonUIEventData_None'
  | 'accessRequestsRadioButtonUIEventData_Domain'
  | 'accessRequestsRadioButtonUIEventData_Anyone'
  | 'accessRequestsDomainTextFieldUIEventData'
  | 'openInviteUIEventData'
  | 'confirmRegenerateInviteLink'
  | 'cancelRegenerateInviteLink'
  | 'voguePreMigrationBannerView';

type TrackEventKeys =
  | 'inviteUrlLinkToggled'
  | 'selfSignUpTrackEventData'
  | 'inviteUrlsLinkTrackEvent'
  | 'approvedDomainsTrackEventData'
  | 'accessRequestsSettingsTrackEventData'
  | 'openInviteTrackEventData';

type CheckboxState = 'enabled' | 'disabled';

type ToggleState = 'enabled' | 'disabled';

interface SaveChangesButtonAttributes {
  cloudId: string;
  currentStateRestrictedDomainsNumber?: number;
  newStateRestrictedDomainsNumber?: number;
  addedDomains?: string;
  removedDomains?: string;
  previousState?: CheckboxState;
  newState?: CheckboxState;
  newStateAccessRequestsSetting?: string;
  previousStateAccessRequestsSetting?: string;
  source?: string;
}

export const siteAccessScreen = 'siteAccessScreen';
export const inviteUrlsScreen = 'siteAccessInviteLinksScreen';
export const accessRequestsTabScreen = 'siteAccessAccessRequestsScreen';
export const approvedDomainScreen = 'siteAccessApprovedDomainsScreen';
export const openInviteScreen = 'siteAccessUserInvitesScreen';
const regenerateInviteLinkModalName = 'regenerateInviteLinkModal';

export const siteAccessEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1362707950/UAL+Frontend+analytics+technical+spec',
  screen: {
    selfSignUpViewEventData: (attributes: { cloudId: number }): ScreenEvent => ({
      data: {
        name: siteAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/1445',
    }),
    inviteUrlsScreenEvent: (attributes: { cloudId: number }): ScreenEvent => ({
      data: {
        name: inviteUrlsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18947',
    }),
    accessRequestsScreenEvent: (attributes: { cloudId: number }): ScreenEvent => ({
      data: {
        name: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18714',
    }),
    approvedDomainScreen: (attributes: { cloudId: number }): ScreenEvent => ({
      data: {
        name: approvedDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18878',
    }),
    openInviteScreen: (attributes: { cloudId: number }): ScreenEvent => ({
      data: {
        name: openInviteScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18880',
    }),
    regenerateInviteLinkModal: (attributes: {
      productKey: string;
      previousExpirationDate: string;
      previousDaysRemaining: number;
    }): ScreenEvent => ({
      data: {
        name: regenerateInviteLinkModalName,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18937',
    }),
  },
  ui: {
    selfSignUpSaveClickEventData: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveSelfSignupButton',
        source: siteAccessScreen,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/1434',
    }),
    inviteUrlCheckbox: (attributes: {
      cloudId: string;
      productKey: string;
      currentState: CheckboxState;
      newState: CheckboxState;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'inviteUrlCheckbox',
        source: siteAccessScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14837',
    }),
    inviteUrlToggle: (attributes: {
      cloudId: string;
      productKey: string;
      currentState: ToggleState;
      newState: ToggleState;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'inviteUrlToggle',
        source: siteAccessScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18345',
    }),
    copyInviteUrlButton: (attributes: {
      cloudId: string;
      productKey: string;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyInviteUrlButton',
        source: siteAccessScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14269',
    }),
    regenerateInviteUrlLink: (attributes: {
      cloudId: string;
      productKey: string;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateInviteUrlLink',
        source: siteAccessScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14270',
    }),
    inviteUrlToggleNew: (attributes: {
      cloudId: string;
      productKey: string;
      previousState: ToggleState;
      newState: ToggleState;
      previousExpirationDate?: string;
      previousDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'inviteUrlToggle',
        source: inviteUrlsScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18345',
    }),
    copyInviteLinkButton: (attributes: {
      cloudId: string;
      productKey: string;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'copyInviteLinkButton',
        source: inviteUrlsScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19160',
    }),
    regenerateInviteLinkLink: (attributes: {
      cloudId: string;
      productKey: string;
      previousExpirationDate?: string;
      previousDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'regenerateInviteLinkLink',
        source: inviteUrlsScreen,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18936',
    }),
    saveChangesButtonUIEventData: (attributes?: SaveChangesButtonAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'saveChangesButton',
        source: attributes?.source || siteAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18722',
    }),
    accessRequestsRadioButtonUIEventData_None: (attributes?: { previousStateAccessRequestsSetting: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'noneRadio',
        source: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19147',
    }),
    accessRequestsRadioButtonUIEventData_Domain: (attributes?: { previousStateAccessRequestsSetting: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'domainRestrictedRadio',
        source: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19148',
    }),
    accessRequestsRadioButtonUIEventData_Anyone: (attributes?: { previousStateAccessRequestsSetting: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'anyoneRadio',
        source: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19149',
    }),
    accessRequestsDomainTextFieldUIEventData: (attributes?: {
      isAccepted: boolean;
      currentStateRestrictedDomainsNumber: number;
      newStateRestrictedDomainsNumber: number;
      enabledDomainsNumber: number;
    }): UIEvent => ({
      data: {
        action: Action.Entered,
        actionSubject: ActionSubject.TextField,
        actionSubjectId: 'domainRestrictedTextField',
        source: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18719',
    }),
    openInviteUIEventData: (attributes?: { previousState: CheckboxState; newState: CheckboxState }): UIEvent => ({
      data: {
        source: openInviteScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'userInvitesCheckbox',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18881',
    }),
    confirmRegenerateInviteLink: (attributes: {
      cloudId: string;
      productKey: string;
      previousExpirationDate?: string;
      previousDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRegenerateInviteLink',
        source: regenerateInviteLinkModalName,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18938',
    }),
    cancelRegenerateInviteLink: (attributes: {
      cloudId: string;
      productKey: string;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRegenerateInviteLink',
        source: regenerateInviteLinkModalName,
        objectType: 'inviteUrl',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18939',
    }),
    vogueMigrationBannerLearnMoreClick: (attributes: { cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'vogueMigrationBannerLearnMoreClick',
        source: siteAccessScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42367',
    }),
    voguePreMigrationBannerView: (attributes: { cloudId: string }): UIEvent => ({
      data: {
        action: Action.Viewed,
        actionSubject: ActionSubject.Badge,
        actionSubjectId: 'voguePreMigrationBanner',
        source: siteAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44590',
    }),
  },
  track: {
    inviteUrlLinkToggled: (attributes: {
      cloudId: string;
      productKey: string;
      currentState: CheckboxState;
      newState: CheckboxState;
      currentExpirationDate?: string;
      currentDaysRemaining?: number;
      newExpirationDate?: string;
      newDaysRemaining?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Toggled,
        actionSubject: TrackActionSubject.InviteUrlLinkToggled,
        source: siteAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14875',
    }),
    selfSignUpTrackEventData: (attributes: { isOpenInvite: boolean; restrictions: string; notify: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Submitted,
        actionSubject: TrackActionSubject.Form,
        actionSubjectId: 'siteAccessForm',
        source: siteAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/1435',
    }),
    inviteUrlsLinkTrackEvent: (attributes: {
      cloudId: string;
      productKey: string;
      previousState?: CheckboxState;
      newState?: CheckboxState;
      previousExpirationDate?: string;
      previousDaysRemaining?: number;
      newExpirationDate?: string;
      newDaysRemaining?: number;
    }): TrackEvent => ({
      data: {
        action: Action.Updated,
        actionSubject: TrackActionSubject.InviteLinks,
        source: inviteUrlsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18883',
    }),
    approvedDomainsTrackEventData: (attributes?: SaveChangesButtonAttributes): TrackEvent => ({
      data: {
        action: Action.Updated,
        actionSubject: TrackActionSubject.ApprovedDomains,
        source: approvedDomainScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18879',
    }),
    accessRequestsSettingsTrackEventData: (attributes?: SaveChangesButtonAttributes): TrackEvent => ({
      data: {
        action: Action.Updated,
        actionSubject: TrackActionSubject.AccessRequests,
        source: accessRequestsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19138',
    }),
    openInviteTrackEventData: (attributes?: { previousState: CheckboxState; newState: CheckboxState }): TrackEvent => ({
      data: {
        source: openInviteScreen,
        action: Action.Updated,
        actionSubject: TrackActionSubject.UserInvite,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18882',
    }),
  },
  operational: {},
};
