import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import { adminInsightsScreen } from './admin-insights-event-data';

type UIEventKeys =
  | 'claimAccountsClickEvent'
  | 'verifyDomainsClickEvent'
  | 'externalUsersClickEvent'
  | 'viewAllDomainsClickEvent'
  | 'viewAllUsersClickEvent'
  | 'viewManagedUsersClickEvent'
  | 'userAccessBreakdownToolTipClickEvent'
  | 'domainsPresentInOrgToolTipClickEvent'
  | 'viewClaimAccountsTargetDomainLinkClickEvent'
  | 'viewVerifyTargetDomainLinkClickEvent'
  | 'externalUsersLoginToolTipClickEvent'
  | 'externalUsersExpandActionsClickEvent'
  | 'externalUsersPolicyToolTipClickEvent'
  | 'externalUsersViewPolicyClickEvent'
  | 'insightsChartHoverEvent'
  | 'reportRefreshButtonClickEvent';

export const enum AdminInsightsReportName {
  userBaseCompositionReport = 'userBaseCompositionReport',
  domainAccessCompositionReport = 'domainAccessCompositionReport',
  externalUsersLoginReport = 'externalUsersLoginReport',
  managedUsersLoginReport = 'managedUsersLoginReport',
  externalUsersPolicyCoverageReport = 'externalUsersPolicyCoverageReport',
}

export const enum AdminInsightsBannerName {
  verifyDomainClaimAccountBanner = 'verifyDomainClaimAccountBanner',
}

export interface accessSMBInsightsEventAttributes {
  reportName?: AdminInsightsReportName;
  bannerName?: AdminInsightsBannerName;
  openedTooltip?: boolean;
  domainStatus?: string;
}

export const adminInsightsAccessSMBEvents: FeatureEvents<string, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/3012892379/Actionable+Insights+M2+-+Analytics+spec',
  screen: {},
  track: {},
  operational: {},
  ui: {
    claimAccountsClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewClaimAccounts',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58452',
    }),
    verifyDomainsClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewVerifyDomains',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58453',
    }),
    externalUsersClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewExternalUsers',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58454',
    }),
    viewAllDomainsClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAllDomains',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58455',
    }),
    viewAllUsersClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewAllUsers',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58456',
    }),
    viewManagedUsersClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewManagedUsers',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58457',
    }),
    userAccessBreakdownToolTipClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'userAccessBreakdownInfo',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58597',
    }),
    domainsPresentInOrgToolTipClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'domainsPresentInOrgInfo',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58598',
    }),
    viewClaimAccountsTargetDomainLinkClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewClaimAccountsTargetDomain',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58600',
    }),
    viewVerifyTargetDomainLinkClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewVerifyTargetDomain',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58601',
    }),
    externalUsersLoginToolTipClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'externalUsersLoginInfo',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58604',
    }),
    externalUsersExpandActionsClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'externalUsersExpandActions',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58607',
    }),
    externalUsersPolicyToolTipClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'externalUsersPolicyInfo',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58608',
    }),
    externalUsersViewPolicyClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'externalUsersViewPolicy',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58609',
    }),
    insightsChartHoverEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'insightsCharts',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59346',
    }),
    reportRefreshButtonClickEvent: (attributes: accessSMBInsightsEventAttributes): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reportRefreshButtonClick',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62494',
    }),
  },
};
