/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import AkEmptyState from '@atlaskit/empty-state';

import { errorImage, errorV2Image } from '@adminhub/images';

// These should only be used for error flags, use the components for all other error messages
export const errorFlagMessages = defineMessages({
  title: {
    id: 'common.generic-error.title',
    defaultMessage: 'Something went wrong',
    description: 'Generic error message title',
  },
  description: {
    id: 'common.generic-error.description',
    defaultMessage: 'Try again later.',
    description: 'Generic error message description',
  },
});

const errorMessages = defineMessages({
  error: {
    id: 'common.generic-error',
    defaultMessage: 'Something went wrong. Try again later.',
    description: 'Generic error message',
  },
});

export const GenericErrorMessage: React.FC = () => <FormattedMessage {...errorMessages.error} />;

interface GenericErrorProps {
  testId?: string;
  header?: string;
  description?: React.ReactNode;
  imageVersion?: 'v1' | 'v2';
}

export const GenericError: React.FC<GenericErrorProps> = ({ header, description, imageVersion = 'v1', testId }) => {
  const intl = useIntl();

  return (
    <AkEmptyState
      testId={testId}
      imageUrl={imageVersion === 'v2' ? errorV2Image : errorImage}
      header={header || intl.formatMessage(errorFlagMessages.title)}
      description={description || intl.formatMessage(errorFlagMessages.description)}
      size="wide"
    />
  );
};
