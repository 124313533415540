import type { UIAnalyticsEvent as AkUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';

// eslint-disable-next-line no-restricted-imports
import type { ConfigType } from '@adminhub-page/site-access-config/src/ui/access-config-type';
import type { VerificationType } from '@adminhub/graphql-types';

import type { Permissions } from '@adminhub/site-user-permission-section';

import type { OrgSettings } from '@adminhub/schema-types';

import type { Domain } from '@adminhub/domain-claim';
import { getDomainStatusAttributes } from '@adminhub/domain-claim';

import type {
  FeatureEvents,
  OperationalData,
  OperationalEvent,
  ScreenData,
  ScreenEvent,
  TrackData,
  TrackEvent,
  UIData,
  UIEvent,
} from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import { AuthPolicyScreens } from './auth-policies-event-data';
import type { ExternalUserSecurityScreens } from './external-user-security-event-data';
import { SecurityChecklistScreen } from './security-guide-event-data';
import { selectiveDomainsScreen } from './selective-user-claim-event-data';

export interface AppDetailData {
  appId?: string | null;
  appName?: string;
  appVendor?: string | null;
  appContext: string;
}
interface ScreenDataAttributesI18n {
  language: string;
  isI18nEnabled: boolean;
}

interface OriginIdGeneratedAttributes {
  originIdGenerated: string;
  originProduct: string;
}

interface OriginIdAttributes {
  originId: string;
  originProduct: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14608
export const noPermissionScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'noPermissionScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/53021
export const forbiddenPageScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'forbiddenPageScreen',
  },
});

interface RemoveDomainClickAttributes {
  verified: boolean;
}

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/1689
export const removeDomainClickEventData = (attributes: RemoveDomainClickAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeDomainButton',
  source: 'domainClaimScreen',
  attributes,
});

export const launchpadScreen = 'launchpadScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14658
export const launchpadScreenEvent = (totalOrgCount: number, totalSiteCount?: number): ScreenEvent => ({
  data: {
    name: launchpadScreen,
    attributes: {
      totalOrgCount,
      totalSiteCount,
    },
  },
});

export const manageOrgsScreen = 'manageOrgsScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14003
export const orgNameLinkClickEventData = (cardPosition: number, source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'orgNameLink',
  source,
  attributes: {
    cardPosition,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16527
export const changeManagementSectionDismissEvent = (source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'doneAddingSitesLink',
  source,
});

// https://data-portal.internal.atlassian.com/analytics/registry/16721
export const changeManagementStartTour = (variation: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'startSpotlightTour',
  source: spotlightTourScreen,
  attributes: {
    variation,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16722
export const changeManagementEndTour = (variation: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'finishSpotlightTour',
  source: spotlightTourScreen,
  attributes: {
    variation,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16723
export const changeManagementDismissTour = (variation: string, dropOffStep?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'dismissSpotlightTour',
  source: spotlightTourScreen,
  attributes: {
    dropOffStep,
    variation,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16724
export const changeManagementSkipTour = (variation: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'skipSpotlightTour',
  source: spotlightTourScreen,
  attributes: {
    variation,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16725
export const changeManagementSpotlightTourScreenEvent = (variation: string): ScreenEvent => ({
  data: {
    name: spotlightTourScreen,
    attributes: {
      variation,
    },
  },
});
// https://data-portal.internal.atlassian.com/analytics/registry/14004
export const verifyDomainLinkClickEventData = (cardPosition: number, source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'verifyDomainLink',
  source,
  attributes: {
    cardPosition,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14005
export const domainLinkClickEventData = (cardPosition: number, source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'domainLink',
  source,
  attributes: {
    cardPosition,
  },
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/15196
export const verifyDomainTrackEventData = (orgId: string, method: VerificationType): TrackData => ({
  action: TrackAction.Verified,
  actionSubject: TrackActionSubject.Domain,
  actionSubjectId: orgId,
  source: 'verifyDomainModal',
  attributes: {
    method,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14006
export const managedAccountsLinkClickEventData = (cardPosition: number, source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'managedAccountsLink',
  source,
  attributes: {
    cardPosition,
  },
});

export type AtlassianAccessLearnMoreScreen =
  | 'orgOverviewScreen'
  | 'twoStepVerificationScreen'
  | 'passwordMgmtScreen'
  | 'samlScreen'
  | UserProvisioningScreen
  | 'memberDetailsApiTokensScreen'
  | 'auditLogScreen'
  | 'insightsScreen'
  | 'discoveredProductsScreen'
  | 'dataSecurityPoliciesScreen'
  | AuthPolicyScreens.authPolicyListScreen
  | ExternalUserSecurityScreens.externalUsersSecurityScreen
  | ExternalUserSecurityScreens.externalUserSecurityListScreen
  | 'jsdPortalCustomersScreen'
  | 'securityChecklistScreen'
  | 'userApiTokensScreen';

export type SessionDurationScreen = 'sessionDurationScreen';
export const orgOverviewScreen = 'orgOverviewScreen';
export const productsOverviewScreen = 'productsOverviewScreen';
export const membersPageScreen = 'managedAccountsScreen';
export const orgLandingScreen = 'orgLandingScreen';
export type OrgOverviewScreenVariation = 'noDomainClaim' | 'domainNoAccess' | 'withAccess' | 'withSite';
export const twoStepVerificationScreen: AtlassianAccessLearnMoreScreen = 'twoStepVerificationScreen';
export const passwordMgmtScreen: AtlassianAccessLearnMoreScreen = 'passwordMgmtScreen';
export const samlScreen: AtlassianAccessLearnMoreScreen = 'samlScreen';
export const auditLogScreen: AtlassianAccessLearnMoreScreen = 'auditLogScreen';
export const userApiTokensScreen: AtlassianAccessLearnMoreScreen = 'userApiTokensScreen';
export const siteAdminScreen = 'siteAdminScreen';
export const spotlightTourScreen = 'spotlightTourScreen';
export const userListScreen = 'userListScreen';
export const groupListScreen = 'groupListScreen';
export type OktaCloudConnectScreen = 'embeddedOktaScreen' | 'oktaAccountCreatedScreen' | 'embeddedOktaErrorScreen';
export const oktaAccountCreationScreenName: OktaCloudConnectScreen = 'embeddedOktaScreen';
export const oktaAccountCreatedScreenName: OktaCloudConnectScreen = 'oktaAccountCreatedScreen';
export const oktaErrorScreenName: OktaCloudConnectScreen = 'embeddedOktaErrorScreen';
export const domainsScreen = 'domainsScreen';
export const sessionDurationScreen: SessionDurationScreen = 'sessionDurationScreen';
export const memberDetailsScreen = 'memberDetailsScreen';
export const memberRecentDevicesTableScreen = 'memberRecentDevicesTableScreen';
export const memberRecentDevicesLogoutModalScreen = 'memberRecentDevicesLogoutModalScreen';
export type AddProductScreen = 'addProductScreen' | 'addProductSelectScreen' | 'addProductStartTrialScreen' | 'addProductSetupScreen';
export type AddProductSitelessScreen =
  | 'addProductSitelessSelectScreen'
  | 'addProductSitelessAddDetailsScreen'
  | 'addProductSitelessProvisioningScreen'
  | 'addProductSitelessBitbucketWorkspaceCreateScreen'
  | 'addProductSitelessSetupScreen'
  | 'addProductSitelessSuccessScreen'
  | 'selectSiteModalScreen';

export type LinkProductsScreen =
  | 'linkProductsImpactScreen'
  | 'linkProductsSelectScreen'
  | 'linkProductsConfirmScreen'
  | 'linkProductsErrorScreen';
export type OrgNameFormSource = 'orgOverviewScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/13999
export const orgOverviewScreenEvent = (orgId: string, variation: OrgOverviewScreenVariation, linkedSitesCount?: number): ScreenEvent => ({
  orgId,
  data: {
    name: orgOverviewScreen,
    attributes: {
      variation,
      currentSiteCount: linkedSitesCount,
    },
  },
});

// A temporary type for the Access SMB Checklist Experiment (to be removed after the experiment is concluded)
type AccessSMBChecklistExperimentPageVariant = 'expcen-2635_variation_0' | 'expcen-2635_variation_1' | 'expcen-2635_variation_2';

// A temporary type for the Statsig AA Experiment (to be removed after the experiment is concluded)
type StatsigAAExperimentPageVariant = 'expcen-4258_variation_0' | 'expcen-4258_variation_1';

type OverviewScreenEventAttributes =
  // The domain claim status attributes for the `experiment-domain-claim-flow` experiment in LaunchDarkly
  ReturnType<typeof getDomainStatusAttributes> & {
    pageVariant?: AccessSMBChecklistExperimentPageVariant;
    statsigPageVariant?: StatsigAAExperimentPageVariant;
    hasAccess?: boolean;
  };

// data-portal.internal.atlassian.com/analytics/registry/51652
export const overviewScreenEvent = (multivariateValue: SupportedFlagTypes, domains: Domain[], hasAccess: boolean): ScreenEvent => {
  const attributes: OverviewScreenEventAttributes = {
    ...getDomainStatusAttributes(domains),
  };

  attributes.hasAccess = hasAccess;
  // Only set `pageVariant` if the user is enrolled in the Access SMB Checklist experiment
  if (multivariateValue === 'control') {
    attributes.pageVariant = 'expcen-2635_variation_0';
  } else if (multivariateValue === 'variant1') {
    attributes.pageVariant = 'expcen-2635_variation_1';
  } else if (multivariateValue === 'variant2') {
    attributes.pageVariant = 'expcen-2635_variation_2';
  }

  return {
    data: {
      name: orgOverviewScreen,
      attributes,
    },
  };
};

// https://data-portal.internal.atlassian.com/analytics/registry/14007
export const siteCardLinkClickEventData = (siteId: string, source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'siteLink',
  source,
  attributes: {
    siteId,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14842
export const siteCardManageUsersLink = (siteId: string, source: string, isLinkedSite?: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'siteManageUsersLink',
  source,
  attributes: {
    siteId,
    isLinkedSite,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14843
export const siteCardManageGroupsLink = (siteId: string, source: string, isLinkedSite?: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'siteManageGroupsDropdownLink',
  source,
  attributes: {
    siteId,
    isLinkedSite,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14844
export const siteCardGrantProductAccessLink = (siteId: string, source: string, isLinkedSite?: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'siteProductAccessDropdownLink',
  source,
  attributes: {
    siteId,
    isLinkedSite,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14845
export const siteCardViewBillingDropdownLink = (siteId: string, source: string, isLinkedSite?: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'siteViewBillingDropdownLink',
  source,
  attributes: {
    siteId,
    isLinkedSite,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14000
export const renameOrgButtonEventData = (source: OrgNameFormSource): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'renameOrgButton',
  source,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14001
export const renameOrgConfirmButtonEventData = (source: OrgNameFormSource): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'renameOrgConfirmButton',
  source,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13492
export const atlassianAccessLearnMoreEventData = (screen: AtlassianAccessLearnMoreScreen): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'learnMoreAccessButton',
  source: screen,
});

type UserProvisioningScreen =
  | 'userProvisioningIntroScreen'
  | 'userProvisioningCreateTokenScreen'
  | 'userProvisioningRegenerateTokenScreen'
  | 'userProvisioningTokenDetailsScreen'
  | 'userProvisioningScreen';

export const userProvisioningIntroScreen: UserProvisioningScreen = 'userProvisioningIntroScreen';

// https://data-portal.internal.atlassian.com/eventcatalog/view/13376
export const userProvisioningIntroScreenEvent = (embeddedOkta: boolean): ScreenEvent => ({
  data: {
    name: userProvisioningIntroScreen,
    attributes: {
      embeddedOkta,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/39868
export const billingPreviewScreenEvent = (orgId: string, products: string[]): ScreenEvent => ({
  orgId,
  data: {
    name: 'billingPreviewScreen',
    product: 'admin',
    platform: 'web',
    attributes: {
      orgId,
      screenType: 'normal',
      products,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/39869
export const billingPreviewErrorScreenEvent = (orgId: string): ScreenEvent => ({
  orgId,
  data: {
    product: 'admin',
    platform: 'web',
    name: 'billingPreviewErrorScreen',
    attributes: {
      orgId,
      screenType: 'error',
      page: 'billingPreview',
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/39870
export const billingPreviewEmptyScreenEvent = (orgId: string): ScreenEvent => ({
  orgId,
  data: {
    product: 'admin',
    platform: 'web',
    name: 'billingPreviewEmptyScreen',
    attributes: {
      orgId,
      screenType: 'empty',
      page: 'billingPreview',
      products: [],
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/42154
export const siteBillingPreviewScreenEvent = (cloudId: string, products: string[]): ScreenEvent => ({
  cloudId,
  data: {
    name: 'siteBillingPreviewScreen',
    product: 'admin',
    platform: 'web',
    attributes: {
      cloudId,
      screenType: 'normal',
      products,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/42156
export const siteBillingPreviewErrorScreenEvent = (cloudId: string): ScreenEvent => ({
  cloudId,
  data: {
    product: 'admin',
    platform: 'web',
    name: 'siteBillingPreviewErrorScreen',
    attributes: {
      cloudId,
      screenType: 'error',
      page: 'billingPreview',
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/42158
export const siteBillingPreviewEmptyScreenEvent = (cloudId: string): ScreenEvent => ({
  cloudId,
  data: {
    product: 'admin',
    platform: 'web',
    name: 'siteBillingPreviewEmptyScreen',
    attributes: {
      cloudId,
      screenType: 'empty',
      page: 'billingPreview',
      products: [],
    },
  },
});

const billingPreviewScreenSource = 'billingPreview';
const productNameAdmin = 'admin';

// https://data-portal.internal.atlassian.com/eventcatalog/view/39941
export const billingPreviewManageSubscriptionsClickEvent = ({ productName }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'billingPreviewManageSubscriptionsCTA',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
  attributes: { product: [productName] },
});

// https://data-portal.internal.atlassian.com/analytics/registry/47361
export const ogJsmConfirmationModalMergeClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmConfirmationModalMergeCTA',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47360
export const ogJsmConfirmationModalCancelClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmConfirmationModalCancelCTA',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47465
export const ogJsmtooltipHoverEvent = (): UIData => ({
  action: Action.Hovered,
  actionSubject: ActionSubject.Tooltip,
  actionSubjectId: 'ogJsmTooltipBoxViewed',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/51808
export const billingUserContactSupportButtonClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'billingUserContactSupportClickEvent',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/51809
export const openedContactSupportClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'openedContactSupportClickEvent',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47463
export const ogJsmTooltipLearnMoreClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmTooltipLearnMoreClicked',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47464
export const ogJsmTooltipGetStartedClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmTooltipGetStartedClicked',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47633
export const ogJsmSuccessModalViewedEvent = (): ScreenData => ({
  name: 'OgJsmSuccessModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47634
export const ogJsmSuccessModalLearnToEnableServicesClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'OgJsmSuccessModalLearnToEnableServicesLink',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47635
export const ogJsmSuccessModalGoToJsmClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmSuccessModalGoToJsmButton',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47636
export const ogJsmSuccessSkipClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'OgJsmSuccessModalSkipButton',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/54154
export const billingAccessModalViewedEvent = (): ScreenData => ({
  name: 'BillingAccessModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/54182
export const billingAccessModalPrimaryButtonClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'BillingAccessModalPrimaryButton',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/analytics/registry/54183
export const billingAccessModalSecondaryButtonClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'BillingAccessModalSecondaryButton',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/39942
export const billingPreviewUserManagementClickEvent = ({ productName }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'billingPreviewUserManagementCTA',
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
  attributes: { product: [productName] },
});

// https://data-portal.internal.atlassian.com/analytics/registry/40548
// https://data-portal.internal.atlassian.com/analytics/registry/40547
export const billingPreviewFooterClickEvent = ({ productName, actionSubjectId }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId,
  source: billingPreviewScreenSource,
  product: productNameAdmin,
  eventType: 'ui',
  attributes: { product: [productName] },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13380
export const userProvisioningIntroCreateDirectoryEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createTokenButton',
  source: userProvisioningIntroScreen,
});

const createTokenScreen: UserProvisioningScreen = 'userProvisioningCreateTokenScreen';

// https://data-portal.internal.atlassian.com/eventcatalog/view/13378
export const userProvisioningCreateDirectoryScreenEvent = (): ScreenEvent => ({
  data: {
    name: createTokenScreen,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13380
export const userProvisioningCreateDirectoryEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createTokenButton',
  source: createTokenScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13381
export const userProvisioningCancelCreateDirectoryEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelCreateTokenButton',
  source: createTokenScreen,
});

const regenerateTokenScreen: UserProvisioningScreen = 'userProvisioningRegenerateTokenScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/13865
export const userProvisioningRegenerateApiKeyScreenEvent = (): ScreenEvent => ({
  data: {
    name: regenerateTokenScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/13866
export const userProvisioningRegenerateApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'regenerateTokenButton',
  source: regenerateTokenScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13867
export const userProvisioningCancelRegenerateApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelRegenerateTokenButton',
  source: regenerateTokenScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13868
export const scimApiKeyRegeneratedTrackData = (): TrackData => ({
  action: TrackAction.Regenerated,
  actionSubject: TrackActionSubject.Token,
  actionSubjectId: 'scimTokenRegeneration',
  source: regenerateTokenScreen,
});

const userProvisioningTokenDetailsScreen: UserProvisioningScreen = 'userProvisioningTokenDetailsScreen';

// https://data-portal.internal.atlassian.com/eventcatalog/view/13379
export const userProvisioningApiKeyDetailsScreenEvent = (): ScreenEvent => ({
  data: {
    name: userProvisioningTokenDetailsScreen,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13382
export const userProvisioningFinishCreateDirectoryEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'finishTokenCreationButton',
  source: userProvisioningTokenDetailsScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13383
export const userProvisioningCopyApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'copyTokenButton',
  source: userProvisioningTokenDetailsScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13384
export const userProvisioningCopyBaseUrlEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'copyBaseUrlButton',
  source: userProvisioningTokenDetailsScreen,
});

export const userProvisioningScreen: UserProvisioningScreen = 'userProvisioningScreen';

// https://data-portal.internal.atlassian.com/eventcatalog/view/13377
export const userProvisioningScreenEvent = ({
  orgId,
  syncedUserCount,
  syncedGroupCount,
  siteCount,
  pendingGroupCount,
}: {
  orgId: string;
  syncedUserCount?: number;
  syncedGroupCount?: number;
  siteCount?: number;
  pendingGroupCount: number;
}): ScreenEvent => ({
  orgId,
  data: {
    name: userProvisioningScreen,
    attributes: {
      syncedUserCount,
      syncedGroupCount,
      siteCount,
      pendingGroupCount,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13385
export const userProvisioningGroupsLink = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'viewSyncedGroupsLink',
  source: userProvisioningScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13386
export const userProvisioningDirectoryLink = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'viewTokenLink',
  source: userProvisioningScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13387
export const userProvisioningAuditLogLink = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'viewAuditLogLink',
  source: userProvisioningScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13927
export const userProvisioningProductAccessLink = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'viewProductAccessLink',
  source: userProvisioningScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13388
export const userProvisioningRemoveDirectoryButton = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'revokeTokenButton',
  source: userProvisioningScreen,
});

const userProvisioningDeleteGroupModalScreen = 'userProvisioningDeleteGroupModal';

// https://data-portal.internal.atlassian.com/analytics/registry/16387
export const userProvisioningDeleteGroupModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: userProvisioningDeleteGroupModalScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16389
export const userProvisioningDeleteGroupLinkUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'deleteGroupLink',
    source: userProvisioningScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const userProvisioningDeleteGroupSubmitUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'submitButton',
    source: userProvisioningDeleteGroupModalScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const userProvisioningDeleteGroupCancelUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelButton',
    source: userProvisioningDeleteGroupModalScreen,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13389
export const scimDirectoryCreatedTrackData = (): TrackData => ({
  action: TrackAction.Created,
  actionSubject: TrackActionSubject.Token,
  actionSubjectId: 'scimTokenCreation',
  source: createTokenScreen,
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13390
export const scimDirectoryRemovedTrackData = (): TrackData => ({
  action: TrackAction.Created,
  actionSubject: TrackActionSubject.Token,
  actionSubjectId: 'scimTokenRevoked',
  source: userProvisioningScreen,
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/1880
export const siteAdminSidebarViewEventData = (): ScreenData => ({
  name: 'siteAdminSidebar',
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13392
export const groupsScreenScreenEvent = ({ groupCount }: { groupCount?: number }): ScreenEvent => ({
  data: {
    name: 'groupsScreen',
    attributes: {
      groupCount,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/14150
export const gSuiteScreenScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'gSuiteScreen',
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13393
export const createGroupModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'createGroupModal',
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13394
export const addMembersToGroupModalScreenEvent = ({ groupId }: { groupId: string }): ScreenEvent => ({
  data: {
    name: 'addMembersToGroupModal',
    attributes: {
      groupId,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13395
export const groupDetailsScreenScreenEvent = ({ groupId, memberCount }: { groupId: string; memberCount: number }): ScreenEvent => ({
  data: {
    name: 'groupDetailsScreen',
    attributes: {
      groupId,
      memberCount,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13396
export const editGroupDescriptionModalScreenEvent = ({ groupId, memberCount }: { groupId: string; memberCount: number }): ScreenEvent => ({
  data: {
    name: 'editGroupDescriptionModal',
    attributes: {
      groupId,
      memberCount,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13397
export const deleteGroupModalScreenEvent = ({ groupId, memberCount }: { groupId: string; memberCount: number }): ScreenEvent => ({
  data: {
    name: 'deleteGroupModal',
    attributes: {
      groupId,
      memberCount,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13398
export const createGroupTrackEventData = ({
  groupId,
  description,
  memberCheckBox,
}: {
  groupId: string;
  description: boolean;
  memberCheckBox: boolean;
}): TrackData => ({
  action: TrackAction.Created,
  actionSubject: TrackActionSubject.Group,
  actionSubjectId: groupId,
  source: 'groupsScreen',
  attributes: {
    description,
    memberCheckBox,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13399
export const addMemberTrackEventData = ({
  source,
  groupId,
  memberCountAdded,
  productIds,
  userIds,
}: {
  source: 'groupsScreen' | 'groupsDetailsScreen';
  groupId: string;
  memberCountAdded: number;
  productIds: string[];
  userIds: string[];
}): TrackData => ({
  action: TrackAction.Added,
  actionSubject: TrackActionSubject.Member,
  actionSubjectId: groupId,
  source,
  attributes: {
    memberCountAdded,
    productIds,
    userIds,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13400
export const deletedGroupTrackEventData = ({ groupId, memberCount }: { groupId: string; memberCount: number }): TrackData => ({
  action: TrackAction.Deleted,
  source: 'groupsScreen',
  actionSubject: TrackActionSubject.Group,
  actionSubjectId: groupId,
  attributes: {
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13401
export const createGroupButtonClickedEventData = ({ groupCount }: { groupCount?: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createGroupButton',
  source: 'groupsScreen',
  attributes: {
    groupCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13402
export const groupListLearnMoreLinkClickedEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreLink',
  source: 'groupsScreen',
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13403?
export const groupNameLinkClickedEventData = ({
  groupId,
  groupCount,
  userCount,
  defaultAccess,
  productAdmin,
  hasSearchQuery,
  external,
}: {
  groupId: string;
  groupCount?: number;
  userCount: number;
  defaultAccess: boolean;
  productAdmin: boolean;
  hasSearchQuery: boolean;
  external: boolean;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'groupNameLink',
  source: 'groupsScreen',
  attributes: {
    groupId,
    groupCount,
    userCount,
    defaultAccess,
    productAdmin,
    hasSearchQuery,
    external,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13404
export const groupListSearchClickedEventData = ({ groupCount }: { groupCount?: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Field,
  actionSubjectId: 'groupSearchField',
  source: 'groupsScreen',
  attributes: {
    groupCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13447
export const groupListSearchTypedEventData = ({ groupCount }: { groupCount?: number }): UIData => ({
  action: Action.Searched,
  actionSubject: ActionSubject.Field,
  actionSubjectId: 'groupSearchField',
  source: 'groupsScreen',
  attributes: {
    groupCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13406
export const createGroupConfirmButtonClickedEventData = ({
  groupId,
  description,
  memberCheckBox,
}: {
  groupId: string;
  description: boolean;
  memberCheckBox: boolean;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createGroupConfirmButton',
  source: 'groupsScreen',
  attributes: {
    groupId,
    description,
    memberCheckBox,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13407
export const createGroupCancelButtonClickedEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createGroupCancelButton',
  source: 'groupsScreen',
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13408
export const addMembersConfirmButtonClickedEventData = ({
  groupId,
  memberCount,
  membersAddedCount,
}: {
  groupId: string;
  memberCount: number;
  membersAddedCount: number;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addMemberToGroupConfirm',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
    membersAddedCount,
    newTotalMemberCount: memberCount + membersAddedCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13409
export const addMembersCancelButtonClickedEventData = ({ groupId, memberCount }: { groupId: string; memberCount: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addMemberToGroupCancel',
  source: 'groupsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

interface GroupDetailButtonAttributes {
  groupId: string;
  memberCount: number;
}

// https://data-portal.internal.atlassian.com/eventcatalog/view/13410
export const editGroupDescriptionButtonClickedEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'editGroupDescriptionButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13411
export const addMembersButtonClickedEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addMembersButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13412
export const deleteGroupButtonClickedEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'deleteGroupButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13413
export const editGroupAccessLinkClickedEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'editGroupAccessLink',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13414
export const removeMemberLinkClickedEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'removeMemberLink',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16264
export const userNameLinkClickedEventData = ({ userId, source }: { userId: string; source: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'userNameLink',
  source,
  attributes: {
    userId,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13415
export const deleteGroupConfirmButtonEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'deleteGroupConfirmButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13416
export const deleteGroupCancelButtonEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'deleteGroupCancelButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13417
export const editGroupDescriptionConfirmButtonEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'editGroupDescriptionConfirmButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/13418
export const editGroupDescriptionCancelButtonEventData = ({ groupId, memberCount }: GroupDetailButtonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'editGroupDescriptionCancelButton',
  source: 'groupDetailsScreen',
  attributes: {
    groupId,
    memberCount,
  },
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/1777
export const i18nScreenEvent = (attributes: ScreenDataAttributesI18n): ScreenEvent => ({
  data: {
    name: 'i18nProvider',
    attributes,
  },
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/2002
export const accessOrgMovedScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'accessOrgMovedScreen',
  },
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/2003
export const accessNoOrgScreenEvent = (isNewLozengeShown: boolean, cloudId?: string): ScreenEvent => ({
  cloudId,
  data: {
    name: 'accessNoOrgScreen',
    attributes: {
      isNewLozengeShown,
    },
  },
});

// TODO add event catalog link - currently they cannot be distinguished by actionSubjectIds
export const atlassianAccessPricingUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnAboutAtlassianAccessPricingLink',
  source: 'accessNoOrgScreen',
});

// TODO add event catalog link - currently they cannot be distinguished by actionSubjectIds
export const continueToAccessUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'continueToAccess',
  source: 'accessNoOrgScreen',
});

// TODO add event catalog link - currently they cannot be distinguished by actionSubjectIds
export const learnAboutAccessUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnAboutAccessLink',
  source: 'accessNoOrgScreen',
});

const managedAccountsScreen = 'managedAccountsScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/15090
export const managedAccountsScreenEvent = (domains: Domain[]): ScreenEvent => {
  const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/15090';

  return {
    data: {
      name: managedAccountsScreen,
      attributes: {
        ...getDomainStatusAttributes(domains),
      },
    },
    dataPortalLink,
  };
};

export const exportManagedAccountsClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'exportAccounts',
  source: managedAccountsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/55169
export const learnMoreManagedAccountsClickedUIEvent = (source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreManagedAccounts',
  source: source ?? managedAccountsScreen,
});

export const verifyDomainsClickedUIEvent = (source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'verifyDomains',
  source: source ?? managedAccountsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/34515
export const viewDomainsClickedUIEvent = (source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'viewDomains',
  source: source ?? managedAccountsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/54352
export const learnMoreDomainsLinkUIEvent = (source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreDomains',
  source: source ?? selectiveDomainsScreen,
});

// https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/2202
export const initiateManagedAcountsExportData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'initiateManagedAcountsExport',
  source: managedAccountsScreen,
});

export interface ManagedAccountsScreenCommonAttributes {
  /**
   * Captures the number of managed accounts displayed on the current screen for the admin, e.g. the number of users returned after search or filter is applied.
   */
  managedUsersCountOnScreen: number;
  /**
   * The total count of managed accounts for an organization.
   */
  totalManagedUsersCount: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14582
export const editAccountLinkClickEventData = (
  attributes: ManagedAccountsScreenCommonAttributes & {
    /**
     * The number of page the admin is on
     */
    page: number;
    /**
     * The position of the user on the page
     */
    numberOnPage: number;
    /**
     * Whether the clicked account is a fuzzy match or exact match based on the search term last seen
     */
    fuzzyResults: boolean;
  },
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'editAccountLink',
  source: managedAccountsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14583
export const managedAccountsSearchFieldClickEventData = (attributes: ManagedAccountsScreenCommonAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Field,
  actionSubjectId: 'searchField',
  source: managedAccountsScreen,
  attributes,
});

const accessTrialModal = 'accessTrialModal';

// https://data-portal.internal.atlassian.com/analytics/registry/53912
export const learnMoreAccessClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreAccess',
  source: accessTrialModal,
});

// https://data-portal.internal.atlassian.com/analytics/registry/50092
export const closeAccessTrialModalClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeModal',
  source: accessTrialModal,
});

// https://data-portal.internal.atlassian.com/analytics/registry/53913
export const estimatePricingAccessClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'estimatePricing',
  source: accessTrialModal,
});

// https://data-portal.internal.atlassian.com/analytics/registry/53914
export const learnMoreTrustClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreTrust',
  source: accessTrialModal,
});

const accessRequestsScreen = 'accessRequestSiteAdminScreen';
const accessRequestsReviewModal = 'accessRequestReviewModal';
const denyAccessRequestModalScreen = 'denyAccessRequestModalScreen';

export interface AccessRequestAttributes {
  requesterUserId: string;
  requestedForUserId: string;
  status: string;
  requestedProduct: string;
  requestedTime: string;
  type: string;
  hasVerifiedEmail: boolean;
  subProducts?: string;
  deeplinkedAccessRequest?: boolean;
  userAccessLevel?: string | null;
  note?: string | null;
}
export interface UserRequestsAccessRequestAttributes {
  requesterUserId: string;
  requestedForUserId: string;
  status: string;
  requestedProduct: string;
  requestedTime: number;
  type: string;
  subProducts?: string;
  deeplinkedAccessRequest?: boolean;
  userAccessLevel?: string | null;
  note?: string | null;
}

interface DenialReasonLengthAttribute {
  denialReasonLength: number;
}

interface ErrorAttributes {
  errorMessage: string;
  errorStatus?: number;
}

interface RequestAccessApprovalImprovementFeatureFlagAttribute {
  requestAccessApprovalImprovementExperimentEnabled: boolean;
  requestAccessSurvey: boolean;
}

interface AccessRequestListImprovementsFeatureFlagAttribute {
  accessRequestListImprovementsExperimentEnabled: boolean;
}

interface AccessRequestListDataAttribute {
  accessRequestTotalOnPage: number;
  externalAccessRequestTotalOnPage: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/13979
export const accessRequestsViewData = (
  attributes?: Partial<
    OriginIdAttributes &
      RequestAccessApprovalImprovementFeatureFlagAttribute &
      AccessRequestListImprovementsFeatureFlagAttribute &
      AccessRequestListDataAttribute
  >,
): ScreenData => ({
  name: accessRequestsScreen,
  attributes,
});

interface AccessRequestsReviewModalViewDataAttributes {
  deeplinkedAccessRequest?: boolean;
  noteIncluded?: boolean;
  noteLength?: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/18876
export const accessRequestsReviewModalViewData = (attr?: AccessRequestsReviewModalViewDataAttributes): ScreenData => ({
  name: accessRequestsReviewModal,
  attributes: {
    deeplinkedAccessRequest: attr?.deeplinkedAccessRequest,
    noteIncluded: attr?.noteIncluded,
    noteLength: attr?.noteLength,
  },
});

interface RequestAccessTotal {
  accessRequestTotal: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/18147
export const accessRequestNavItemBadgeViewData = (source: string, attributes?: RequestAccessTotal): UIData => ({
  action: Action.Viewed,
  actionSubject: ActionSubject.Badge,
  actionSubjectId: 'accessRequestNavItemBadge',
  source,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13980
export const denyAccessRequestModalViewData = (attributes: AccessRequestAttributes): ScreenData => ({
  name: denyAccessRequestModalScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15415
export const denyAccessTrackData = (attributes: AccessRequestAttributes & DenialReasonLengthAttribute, source?: string): TrackData => ({
  action: TrackAction.Denied,
  actionSubject: TrackActionSubject.ProductAccess,
  actionSubjectId: attributes.requestedForUserId,
  source: source || accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/58357
export const learnMoreAccessRequestsClickEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreAccessRequests',
  source: accessRequestsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/19098
export const productAccessFailedOperationalData = (attributes: AccessRequestAttributes & ErrorAttributes): OperationalData => ({
  action: OperationalAction.Failed,
  actionSubject: OperationalSubject.ProductAccess,
  source: accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15414
export const approveAccessTrackData = (attributes: AccessRequestAttributes & OriginIdGeneratedAttributes, source?: string): TrackData => ({
  action: TrackAction.Approved,
  actionSubject: TrackActionSubject.ProductAccess,
  actionSubjectId: attributes.requestedForUserId,
  source: source || accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13981
export const approveAccessUIData = (attributes: AccessRequestAttributes & OriginIdGeneratedAttributes, source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'approveUserAccessRequestButton',
  source: source || accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/18877
export const reviewRequestAccessUIData = (attributes: AccessRequestAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'reviewAccessRequestButton',
  source: accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/19146
export const accountExtendedUIData = (attributes: AccessRequestAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'accountExtendedButton',
  source: accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13982
export const denyAccessUIData = (attributes: AccessRequestAttributes, source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'denyUserAccessRequestButton',
  source: source || accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13983
export const denyAccessConfirmUIData = (attributes: AccessRequestAttributes & DenialReasonLengthAttribute): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmDenyUserAccessRequestButton',
  source: denyAccessRequestModalScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13984
export const showProfileDropDownUIEventData = (attributes: AccessRequestAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'accessRequestViewUserProfileButton',
  source: accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/18974
export const requestMoreInfoButtonUIEventData = (attributes: AccessRequestAttributes, source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'accessRequestRequestMoreInfoButton',
  source: source || accessRequestsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/79092
export const goToUserRequestsClickUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubjectId: 'goToUserRequests',
  actionSubject: ActionSubject.Link,
  source: accessRequestsScreen,
});

export const userConnectedAppsScreen = 'singleAppListingUserConnectedAppsScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/69137
export const ConnectedAppsScreenEvent = (cloudId: string, appCount: number): ScreenEvent => ({
  cloudId,
  data: {
    name: userConnectedAppsScreen,
    attributes: {
      appCount,
    },
  },
});

export const requestedAppsScreen = 'userConnectedAppsRequestedAppsScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/75683
export const RequestedAppsScreenEvent = (cloudId: string, displayedAppRequests: number, totalAppRequests: number): ScreenEvent => ({
  cloudId,
  data: {
    name: requestedAppsScreen,
    attributes: {
      displayedAppRequests,
      totalAppRequests,
    },
  },
});

export type FocusedTaskUIEventMeta = Partial<Pick<UIEvent, 'cloudId' | 'orgId'>> & { data: Pick<UIEvent['data'], 'source' | 'attributes'> };

// https://data-portal.internal.atlassian.com/analytics/registry/14249
export const focusedTaskCloseButtonClickEvent = (meta: FocusedTaskUIEventMeta): UIEvent => ({
  ...meta,
  data: {
    ...meta.data,
    action: Action.Clicked,
    actionSubject: ActionSubject.NavigationItem,
    actionSubjectId: 'closeIcon',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14250
export const focusedTaskLogoButtonClickEvent = (meta: FocusedTaskUIEventMeta): UIEvent => ({
  ...meta,
  data: {
    ...meta.data,
    action: Action.Clicked,
    actionSubject: ActionSubject.NavigationItem,
    actionSubjectId: 'productLogo',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14251
export const orgCreatedTrackEvent = (orgId: string): TrackEvent => ({
  orgId,
  data: {
    action: TrackAction.Created,
    actionSubject: TrackActionSubject.Organization,
    actionSubjectId: orgId,
    source: createOrgScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/47321
export const orgCreationFailedTrackEvent = (error: string): TrackEvent => ({
  data: {
    action: TrackAction.CreationFailed,
    actionSubject: TrackActionSubject.Organization,
    attributes: {
      error,
    },
    source: createOrgScreen,
  },
});

export const createOrgScreen = 'createOrgScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14252
export const confirmOrgCreationButtonClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'confirmCreateOrgButton',
    source: createOrgScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14253
export const cancelOrgCreationButtonClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelOrgButton',
    source: createOrgScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15135
export const learnMoreAccessLinkClickedData = (screen: AtlassianAccessLearnMoreScreen): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMoreAccessLink',
  source: screen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15137
export const learnMoreDomainLinkClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnMoreDomainLink',
    source: 'accessOnboardingScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15138
export const findOutMoreLinkClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'findOutMoreLink',
    source: 'learnMoreAccess',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15139
export const learnPricingLinkClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnPricingLink',
    source: 'learnMoreAccess',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15140
export const tryFreeButtonClickedEvent = (source: string, domains: Domain[]): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'tryFreeButton',
    source,
    attributes: {
      ...getDomainStatusAttributes(domains),
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/55039
export const startFreeAccessTrialEvent = (source: string): TrackData => ({
  action: TrackAction.Started,
  source,
  actionSubject: TrackActionSubject.AccessTrial,
});

// https://data-portal.internal.atlassian.com/analytics/registry/23428
export const signUpForFreeOktaAccountClickedEvent = (source: string): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'signUpForFreeOktaAccount',
    source,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15083
export const domainScreenEvent = (domains: Domain[]): ScreenEvent => {
  const domainStatusAttributes = getDomainStatusAttributes(domains);
  const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/15083';

  return {
    data: {
      name: domainsScreen,
      attributes: {
        ...domainStatusAttributes,
      },
    },
    dataPortalLink,
  };
};

// https://data-portal.internal.atlassian.com/analytics/registry/15085
export const createOrgScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'createOrgScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15086
export const samlScreenEvent = (hasIdentityManager: boolean, hasDomainsVerified: boolean, embeddedOkta: boolean): ScreenEvent => ({
  data: {
    name: samlScreen,
    attributes: {
      hasIdentityManager,
      hasDomainsVerified,
      embeddedOkta,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15087
export const passwordMgmtScreenEvent = (hasIdentityManager: boolean, hasDomainsVerified: boolean): ScreenEvent => ({
  data: {
    name: passwordMgmtScreen,
    attributes: {
      hasIdentityManager,
      hasDomainsVerified,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15089
export const twoStepVerificationScreenEvent = (hasIdentityManager: boolean, hasDomainsVerified: boolean): ScreenEvent => ({
  data: {
    name: twoStepVerificationScreen,
    attributes: {
      hasIdentityManager,
      hasDomainsVerified,
    },
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/15912
export const auditLogSearchedEventData = ({
  from: dateFrom = '',
  to: dateTo = '',
  q: searchQuery = '',
  action: activitySelected = '',
  limit: resultSize = '',
}: {
  from?: string;
  to?: string;
  q?: string;
  action?: string;
  limit?: string;
}): UIData => ({
  action: Action.Searched,
  actionSubject: ActionSubject.Form,
  actionSubjectId: 'searchField',
  source: auditLogScreen,
  attributes: {
    dateFrom,
    dateTo,
    hasSearchQuery: !!(searchQuery && searchQuery.length > 0),
    activitySelected,
    resultSize: resultSize ? Number(resultSize) : undefined,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/15914
export const auditLogActivityHyperLinkEventData = ({ subjectType }: { subjectType: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'activitySubjectLink',
  source: auditLogScreen,
  attributes: {
    subjectType,
  },
});

// https://data-portal.internal.atlassian.com/eventcatalog/view/15915
export const auditLogPaginationEventData = ({
  previousPage,
  currentPage,
  limit: resultSize = '',
}: {
  previousPage: number;
  currentPage: number;
  limit?: string;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'pagination',
  source: auditLogScreen,
  attributes: {
    previousPage,
    currentPage,
    resultSize: resultSize ? Number(resultSize) : undefined,
  },
});

// <Admin API>

export const adminApiKeysScreen = 'adminApiKeysScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14178
export const apiKeysScreenEvent = (): ScreenEvent => ({
  data: {
    name: adminApiKeysScreen,
  },
});

export const createAdminApiKeyDrawerEventMeta: FocusedTaskUIEventMeta = {
  data: {
    source: adminApiKeysScreen,
  },
};

// https://data-portal.internal.atlassian.com/analytics/registry/14333
export const adminApiOpenCreateAdminApiKeyDrawerEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'openCreateAdminApiKeyDrawerButton',
  source: adminApiKeysScreen,
});

export const learnMoreAdminApiClickEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnMoreAdminApi',
    source: adminApiKeysScreen,
  },
  dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57998',
});

const adminApiCreateKeyScreen = 'adminApiCreateKeyScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14325
export const adminApiCreateKeyScreenEvent = (): ScreenEvent => ({
  data: {
    name: adminApiCreateKeyScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14326
export const adminApiCancelCreateAdminApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelCreateAdminApiKeyButton',
  source: adminApiCreateKeyScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14327
export const adminApiCreateAdminApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'createAdminApiKeyButton',
  source: adminApiCreateKeyScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14328
export const adminApiKeyCreatedTrackData = (): TrackData => ({
  action: TrackAction.Created,
  actionSubject: TrackActionSubject.AdminApiKey,
  actionSubjectId: 'adminApiKeyCreation',
  source: adminApiCreateKeyScreen,
});

const adminApiKeyDetailsScreen = 'adminApiKeyDetailsScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/14329
export const adminApiApiKeyDetailsScreenEvent = (): ScreenEvent => ({
  data: {
    name: adminApiKeyDetailsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14330
export const adminApiCopyApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'copyAdminApiKeyButton',
  source: adminApiKeyDetailsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14331
export const adminApiCopyOrgIdEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'copyAdminApiOrgIdButton',
  source: adminApiKeyDetailsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14332
export const adminApiFinishCreateApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'finishAdminApiKeyCreationButton',
  source: adminApiKeyDetailsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14374
export const adminApiRevokeApiKeyEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'revokeAdminApiKeyButton',
  source: adminApiKeysScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14373
export const adminApiKeyRevokedTrackData = (): TrackData => ({
  action: TrackAction.Deleted,
  actionSubject: TrackActionSubject.AdminApiKey,
  actionSubjectId: 'adminApiKeyRevoked',
  source: adminApiKeysScreen,
});

// </Admin API>

// https://data-portal.internal.atlassian.com/analytics/registry/14152
export const userConnectedAppsDetailsLinkClickEvent = (cloudId: string, oAuthClientId: string): UIEvent => ({
  cloudId,
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'appDetailsLink',
    source: userConnectedAppsScreen,
    attributes: {
      oAuthClientId,
    },
  },
});

export const atlassianConnectAppsDetailsLinkClickEvent = (cloudId: string, connectAppKey: string, installedProduct: string): UIEvent => ({
  cloudId,
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'appDetailsLink',
    source: userConnectedAppsScreen,
    attributes: {
      connectAppKey,
      installedProduct,
    },
  },
});

export const userConnectedAppDetailsScreen = 'userConnectedAppDetailsScreen';
export const userConnectedAppsForgeLogsModal = 'userConnectedAppsForgeLogsModal';

export interface UserConnectedAppDetailsData {
  oAuthClientId?: string;
  appKey?: string;
  appName?: string;
  appVendor?: string | null;
}

export interface UninstallAppData {
  buttonName: string;
  taskStatus?: string;
}

export const uninstallAppClickEvent = (
  { appId, appName, appVendor, appContext }: AppDetailData,
  { buttonName, taskStatus }: UninstallAppData,
): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'uninstallApp',
    objectType: 'app',
    objectId: appId!,
    source: userConnectedAppDetailsScreen,
    attributes: {
      appName,
      appVendor,
      buttonName,
      taskStatus,
      appContext,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14151
export const userConnectedAppDetailsScreenEvent = (
  cloudId: string,
  { oAuthClientId, appName, appVendor, appKey }: UserConnectedAppDetailsData,
): ScreenEvent => ({
  cloudId,
  data: {
    name: userConnectedAppDetailsScreen,
    attributes: {
      oAuthClientId,
      appName,
      appVendor,
      appKey,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14153
export const userConnectedAppBackToAllAppsLinkClickEvent = (
  cloudId: string,
  { oAuthClientId, appName, appVendor }: UserConnectedAppDetailsData,
): UIEvent => ({
  cloudId,
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'backToAllAppsLink',
    objectType: 'app',
    objectId: oAuthClientId,
    source: userConnectedAppDetailsScreen,
    attributes: {
      appName,
      appVendor,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14600
export const cancelMemberDeletionModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'cancelMemberDeletionModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14769
export const openCancelMemberDeletionModalButton = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelMemberDeletionModal',
  source: memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/16793
export const updateMemberEmail = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Updated,
    actionSubject: ActionSubject.Field,
    actionSubjectId: 'updateMemberEmail',
    source: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16796
export const updateMemberEmailDomainChangeSubmit = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'submitButton',
    source: 'memberDetailsDomainChangeModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16797
export const updateMemberEmailDomainChangeCancel = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelButton',
    source: 'memberDetailsDomainChangeModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16795
export const updateMemberJobTitle = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Updated,
    actionSubject: ActionSubject.Field,
    actionSubjectId: 'updateMemberJobTitle',
    source: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18183
export const updateMemberDepartment = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Updated,
    actionSubject: ActionSubject.Field,
    actionSubjectId: 'updateMemberDepartment',
    source: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18184
export const updateMemberLocation = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Updated,
    actionSubject: ActionSubject.Field,
    actionSubjectId: 'updateMemberLocation',
    source: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16794
export const updateMemberDisplayName = (attributes: { memberId: string }): UIEvent => ({
  data: {
    action: Action.Updated,
    actionSubject: ActionSubject.Field,
    actionSubjectId: 'updateMemberDisplayName',
    source: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14764
export const closeCancelDeletionDialogButton = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeCancelDeletion',
  source: 'cancelMemberDeletionModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14765
export const confirmCancelDeletionDialogButton = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmCancelDeletion',
  source: 'cancelMemberDeletionModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15115
export const reactivateMemberModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'reactivateMemberModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15112
export const openReactivateMemberModalButton = (attributes: { orgId: string; memberId: string }, source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'reactivateMemberModal',
  source: source ?? memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15113
export const closeReactivateDialogButton = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeReactivate',
  source: 'reactivateMemberModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15114
export const confirmReactivateDialogButton = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmReactivate',
  source: 'reactivateMemberModal',
  attributes,
});

export type UserState = 'grantedAccessToSite' | 'revokedAccessToSite' | 'disabled' | 'invited';

export interface RemovedUserFromGroupAttributes {
  groupId: string;
}

export interface AddedUserToGroupAttributes {
  groupsAddedCount: number;
  productIds?: string[];
}

export interface BaseUserPageAttributes {
  userState: UserState[];
  userId: string;
}

export interface UserInviteAttributes {
  numberOfUsersInvited: number;
  numberOfDomainsAddedToDRS?: number;
  numberOfDomainsNotAddedToDRS?: number;
  productsGrantedAccess?: { [productKey: string]: boolean }[];
  numberOfGroupsAdded?: number;
  isPersonalizedEmailSent?: boolean;
  isInviteSent?: boolean;
  permissionType: string;
  invitedUserId?: string;
}

export interface ExCoUserInviteAttributes {
  numberOfExCoUsersInvited: number;
  numberOfGroupsAdded: number;
  isInviteSent: boolean;
  personalizedInviteNoteLength: number;
}

export interface UserInviteLicenseExceededAttributes {
  licensesAvailable: number;
  licensesNeeded: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14241
export const removedUserFromSiteTrackEventData = (attributes: BaseUserPageAttributes): TrackData => ({
  action: 'removedFromSite',
  actionSubject: TrackActionSubject.User,
  source: 'removeUserFromSiteModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14242
export const impersonatedUserTrackEventData = (attributes: BaseUserPageAttributes): TrackData => ({
  action: 'impersonated',
  actionSubject: TrackActionSubject.User,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14243
export const resetUserPasswordTrackEventData = (attributes: BaseUserPageAttributes): TrackData => ({
  action: 'reset',
  actionSubject: TrackActionSubject.UserPassword,
  source: 'userDetailsScreen',
  attributes,
});

export type UserSiteAccessUpdateType = 'grant' | 'revoke';

export type UserSiteAccessProduct =
  | 'jira-core'
  | 'jira-work-management'
  | 'jira-servicedesk'
  | 'jira-software'
  | 'jira-product-discovery'
  | 'confluence';

interface UpdateUserSiteAccessAttributes {
  updateType: UserSiteAccessUpdateType;
  productList: string[];
}

// https://data-portal.internal.atlassian.com/analytics/registry/14244
export const changedUserSiteAccessTrackEventData = (attributes: BaseUserPageAttributes & UpdateUserSiteAccessAttributes): TrackData => ({
  action: 'changedSiteAccess',
  actionSubject: TrackActionSubject.User,
  source: 'userDetailsScreen',
  attributes,
});

interface UpdateUserProductAccessAttributes {
  updateType: UserSiteAccessUpdateType;
  product: UserSiteAccessProduct;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14245
export const changedUserProductAccessTrackEventData = (
  attributes: BaseUserPageAttributes & UpdateUserProductAccessAttributes,
): TrackData => ({
  action: 'changedProductAccess',
  actionSubject: TrackActionSubject.User,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14246
export const resentUserInvitationTrackEventData = (attributes: BaseUserPageAttributes): TrackData => ({
  action: 'resent',
  actionSubject: TrackActionSubject.Invitation,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14417
export const userInviteDrawerScreenEventData = (
  attributes: {
    availableProducts: string[];
    productsCheckedByDefault: string[];
  } & OrgSettings,
): ScreenData => ({
  name: 'userInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/35353
export const excoUserInviteDrawerScreenEventData = (): ScreenData => ({
  name: 'excoUserInviteDrawer',
});

// https://data-portal.internal.atlassian.com/analytics/registry/35477
export const inviteChoiceEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'inviteChoice',
  source: 'userListScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14433
export const toggleExCoPermissionsUIEventData = (attributes: {
  userId: string;
  product: 'confluence-guest';
  updateType: 'grant' | 'revoke';
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updatedUserProducts',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14433
export const toggleHelpseekerPermissionsUIEventData = (attributes: {
  userId: string;
  product: 'jira-servicedesk-customer';
  updateType: 'grant' | 'revoke';
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updatedUserProducts',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14245
export const toggleExCoPermissionsTrackEventData = (attributes: {
  userId: string;
  product: any;
  updateType: 'grant' | 'revoke';
}): TrackData => ({
  action: TrackAction.ChangedProductAccess,
  actionSubject: TrackActionSubject.User,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14245
export const toggleHelpseekerPermissionsTrackEventData = (attributes: {
  userId: string;
  product: 'jira-servicedesk-customer';
  updateType: 'grant' | 'revoke';
}): TrackData => ({
  action: TrackAction.ChangedProductAccess,
  actionSubject: TrackActionSubject.User,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/35478
export const exCoUserInviteSentUIEventData = (attributes: ExCoUserInviteAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'inviteExcoUser',
  source: 'guestUserInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/35479
export const exCoUserInviteSentTrackEventData = (actionSubjectId: string, attributes: ExCoUserInviteAttributes): TrackData => ({
  action: TrackAction.InvitedGuest,
  actionSubject: TrackActionSubject.User,
  actionSubjectId,
  source: 'guestUserInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14409
export const userInviteSentTrackEventData = (attributes: UserInviteAttributes & OriginIdGeneratedAttributes): TrackData => ({
  action: TrackAction.Invited,
  actionSubject: TrackActionSubject.User,
  actionSubjectId: attributes && attributes.invitedUserId,
  source: 'userInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14416
export const userInviteSentUIEventData = (source: string, attributes: UserInviteAttributes & OriginIdGeneratedAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'inviteUsers',
  source,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14750
export const userInviteChangedUserPermissionDrawerTrackEventData = (attributes: UserPermissionChangeAttributes): TrackData => ({
  action: 'changed',
  actionSubject: TrackActionSubject.UserPermission,
  source: 'userInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14752
export const userInviteClickedUserPermissionUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'userPermissions',
  source: 'userInviteDrawer',
});

// https://data-portal.internal.atlassian.com/analytics/registry/24965
export const userInviteUserLicenseExceededOperationalEventData = (
  source: string,
  actionSubjectId: string,
  attributes: UserInviteLicenseExceededAttributes,
): OperationalData => ({
  action: OperationalAction.Exceeded,
  actionSubject: OperationalSubject.UserLicense,
  source,
  actionSubjectId,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14416
export const userQuickInviteSentUIEventData = (attributes: UserInviteAttributes & OriginIdGeneratedAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'inviteUsers',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14409
export const userQuickInviteSentTrackEventData = (attributes: UserInviteAttributes & OriginIdGeneratedAttributes): TrackData => ({
  action: Action.Invited,
  actionSubject: TrackActionSubject.User,
  actionSubjectId: attributes && attributes.invitedUserId,
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/16182
export const userQuickInviteLearnMoreUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'learnMore',
  source: userListScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14418
export const userInviteCancelledUIEventData = (source: string, attributes: UserInviteAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelInviteUsers',
  source,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14419
export const userInviteClosedUIEventData = (attributes: UserInviteAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeInviteUsers',
  source: 'userInviteDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14621
export const userListScreenEvent = (attributes: {
  userCountOnScreen: number;
  totalUserCount: number;
  quickInviteVisible: boolean;
  userTypesSelected: string[];
  productKeysSelected: string[];
  adminTypesSelected: string[];
}): ScreenEvent => ({
  data: {
    name: userListScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/24039
export const userListScreenFailedEvent = (): ScreenEvent => ({
  data: {
    name: 'userListFailedScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14622
export const userListProductAccessModalScreenEvent = (attributes: {
  userId: string;
  selectedProductKeys: string[];
  unselectedProductKeys: string[];
}): ScreenEvent => ({
  data: {
    name: 'userListProductAccessModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14623
export const userListAddToGroupModalScreenEvent = (attributes: { userId: string; countGroupsAvailable: number }): ScreenEvent => ({
  data: {
    name: 'userListAddToGroupModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14624
export const userListNoResultsScreenEvent = (attributes: {
  countSearchCharacters: number;
  userTypesSelected: string[];
  productKeysSelected: string[];
  adminTypesSelected: string[];
}): ScreenEvent => ({
  data: {
    name: 'userListNoResultsScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14625
export const userListGrantAccessModalScreenEvent = (attributes: { userId: string }): ScreenEvent => ({
  data: {
    name: 'userListGrantAccessModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14626
export const userListRevokeAccessModalScreenEvent = (attributes: { userId: string }): ScreenEvent => ({
  data: {
    name: 'userListRevokeAccessModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/13915
export const userDetailsScreenEvent = (attributes: { userId: string }): ScreenEvent => ({
  data: {
    name: 'userDetailsScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14247
export const addedUserToGroupTrackEventData = (attributes: BaseUserPageAttributes & AddedUserToGroupAttributes): TrackData => ({
  action: 'addedToGroup',
  actionSubject: TrackActionSubject.User,
  source: 'addUserToGroupModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14248
export const removedUserFromGroupTrackEventData = (attributes: BaseUserPageAttributes & RemovedUserFromGroupAttributes): TrackData => ({
  action: 'removedFromGroup',
  actionSubject: TrackActionSubject.User,
  source: 'removeUserFromGroupModal',
  attributes,
});

interface AddUserToGroupsModalAttributes {
  groupCount: number;
  groupIds: string[];
}

// https://data-portal.internal.atlassian.com/analytics/registry/14403
export const addUserToGroupsUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addUserToGroups',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14404
export const addUserToGroupsConfirmUIEventData = (attributes: BaseUserPageAttributes & AddUserToGroupsModalAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addUserToGroupsConfirm',
  source: 'addUserToGroupsModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14405
export const addUserToGroupsCancelUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addUserToGroupsCancel',
  source: 'addUserToGroupsModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14339
export const confimRemoveUserFromSiteUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confimRemoveUserFromSite',
  source: 'removeUserFromSiteModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14340
export const cancelRemoveUserFromSiteUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelRemoveUserFromSite',
  source: 'removeUserFromSiteModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14341
export const removeUserFromSiteUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeUserFromSite',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/13916
export const removeUserFromSiteModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'removeUserFromSiteModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/13917
export const addUserToGroupsModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'addUserToGroupsModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/13918
export const removeUserFromGroupsModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'removeUserFromGroupsModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14430
export const resetPasswordUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'resetPassword',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14431
export const logInAsUserUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'logInAsUser',
  source: 'userDetailsScreen',
  attributes,
});

interface UpdateUserStatusAdditionalAttributes {
  updateType?: 'grant' | 'revoke';
}

// https://data-portal.internal.atlassian.com/analytics/registry/14432
export const updateUserActiveStatusUIEventData = (attributes: BaseUserPageAttributes & UpdateUserStatusAdditionalAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updatedUserActiveStatus',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14433
export const updatedUserProductsUIEventData = (attributes: BaseUserPageAttributes & UpdateUserStatusAdditionalAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updatedUserProducts',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14434
export const viewGroupUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'viewGroup',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14435
export const reInviteUserUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'reInviteUser',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14436
export const removeUserFromGroupUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removedUserFromGroup',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14438
export const confirmRemoveUserFromGroupUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmRemoveUserFromGroup',
  source: 'removeUserFromGroupModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14437
export const cancelRemoveUserFromGroupUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelRemoveUserFromGroup',
  source: 'removeUserFromGroupModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14266
const regenerateInviteUrlModalName = 'regenerateInviteUrlModal';
export const regenerateInviteUrlModal = (
  cloudId: string,
  productKey: string,
  currentExpirationDate: string,
  currentDaysRemaining: number,
): ScreenEvent => ({
  cloudId,
  data: {
    name: regenerateInviteUrlModalName,
    attributes: {
      productKey,
      currentExpirationDate,
      currentDaysRemaining,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14271
export const confirmRegenerateInviteUrlLink = (attributes: {
  cloudId: string;
  productKey: string;
  currentExpirationDate?: string;
  currentDaysRemaining?: number;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'confirmRegenerateInviteUrlLink',
    source: regenerateInviteUrlModalName,
    objectType: 'inviteUrl',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14272
export const cancelRegenerateInviteUrlLink = (attributes: {
  cloudId: string;
  productKey: string;
  currentExpirationDate?: string;
  currentDaysRemaining?: number;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelRegenerateInviteUrlLink',
    source: regenerateInviteUrlModalName,
    objectType: 'inviteUrl',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14876
export const inviteUrlLinkRegenerated = (
  cloudId: string,
  attributes: {
    productKey: string;
    currentExpirationDate?: string;
    currentDaysRemaining?: number;
    newExpirationDate?: string;
    newDaysRemaining?: number;
  },
): TrackEvent => ({
  cloudId,
  data: {
    action: TrackAction.Toggled,
    actionSubject: TrackActionSubject.InviteUrlLinkRegenerated,
    source: regenerateInviteUrlModalName,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14390
export const jsmExportCustomersUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'exportCustomersButton',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14336
export const memberDeletionErrorsScreenEvent = (orgId: string, memberId: string): ScreenEvent => ({
  orgId,
  data: {
    name: 'memberDeletionSPIErrorsScreen',
    attributes: {
      orgId,
      memberId,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14761
export const memberDeletionScreenEvent = (orgId: string, memberId: string): ScreenEvent => ({
  orgId,
  data: {
    name: 'memberDeletionSPIModal',
    attributes: {
      orgId,
      memberId,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14763
export const confirmMemberDeletion = (attributes: { orgId: string; memberId: string; products: string[]; [s: string]: any }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmMemberDeletion',
  source: 'memberDeletionSPIModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14762
export const closeMemberDeletion = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeMemberDeletion',
  source: 'memberDeletionSPIModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15143
export const memberDeactivationScreenEvent = (orgId: string, memberId: string): ScreenEvent => ({
  orgId,
  data: {
    name: 'memberDeactivationSPIModal',
    attributes: {
      orgId,
      memberId,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15200
export const memberDeactivationErrorScreenEvent = (orgId: string, memberId: string): ScreenEvent => ({
  orgId,
  data: {
    name: 'memberDeactivationSPIErrorModal',
    attributes: {
      orgId,
      memberId,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15144
export const confirmMemberDeactivation = (attributes: { orgId: string; memberId: string; products: string[] }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'confirmMemberDeactivation',
  source: 'memberDeactivationSPIModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15145
export const closeMemberDeactivation = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeMemberDeactivation',
  source: 'memberDeactivationSPIModal',
  attributes,
});

export interface UserPermissionChangeAttributes {
  previousPermissionType: Permissions;
  newPermissionType: Permissions;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14750
export const changedUserPermissionTrackEventData = (attributes: BaseUserPageAttributes & UserPermissionChangeAttributes): TrackData => ({
  action: 'changed',
  actionSubject: TrackActionSubject.UserPermission,
  source: 'userDetailsScreen',
  attributes,
});

export interface FailedUserPermissionChangeAttributes {
  previousPermissionType: Permissions;
  failedPermissionType: Permissions;
  failureReason: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14751
export const failedUserPermissionTrackEventData = (
  attributes: BaseUserPageAttributes & FailedUserPermissionChangeAttributes,
): TrackData => ({
  action: 'failed',
  actionSubject: TrackActionSubject.UserPermission,
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14752
export const selectedUserPermissionUIEventData = (attributes: BaseUserPageAttributes & UserPermissionChangeAttributes): UIData => ({
  action: Action.Selected,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'userPermissions',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14597
export const deleteAccountFocusedTaskScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'deleteAccountFocusedTaskScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15122
export const deactivateAccountFocusedTaskScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'deactivateAccountFocusedTaskScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14599
export const cancelDeleteButton = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelDelete',
  source: 'deleteAccountFocusedTaskScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15123
export const cancelDeactivateButton = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelDeactivate',
  source: 'deactivateAccountFocusedTaskScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14570
export const deleteAccountButton = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'deleteAccount',
  source: 'deleteAccountFocusedTaskScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15124
export const deactivateAccountButton = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'deactivateAccount',
  source: 'deactivateAccountFocusedTaskScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14573
export const deactivateAccountLink = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'deactivateAccountLink',
  source: 'deleteAccountFocusedTaskScreen',
});

interface UserListAttributes {
  userId: string;
  userStatus: string;
  userRole: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14628
export const userListScreenInviteResent = (attributes: BaseUserPageAttributes): TrackData => ({
  action: TrackAction.Resent,
  actionSubject: TrackActionSubject.Invitation,
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14629
export const userListGrantAccessModalSiteAccessGranted = (
  attributes: {
    lastSeenOnSite: string;
    productAccess: string[];
  } & UserListAttributes,
): TrackData => ({
  action: TrackAction.Granted,
  actionSubject: TrackActionSubject.SiteAccess,
  source: 'userListGrantAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14630
export const userListGrantAccessModalSiteAccessRevoked = (
  attributes: {
    lastSeenOnSite: string;
    productAccess: string[];
  } & UserListAttributes,
): TrackData => ({
  action: TrackAction.Revoked,
  actionSubject: TrackActionSubject.SiteAccess,
  source: 'userListRevokeAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14631
export const userListGrantAccessModalProductAccessSaved = (
  attributes: {
    productAccessBefore: string[];
    productAccessAfter: string[];
  } & UserListAttributes,
): TrackData => ({
  action: TrackAction.Saved,
  actionSubject: TrackActionSubject.SiteAccess,
  source: 'userListProductAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14633
export const userListOpenInviteUserDrawer = (attributes: { userCountOnScreen?: number; totalUserCount?: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'openInviteUserDrawer',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14634
export const userListExportUsers = (attributes: { userCountOnScreen?: number; totalUserCount?: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'exportUsers',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14635
export const userListSearchUser = (attributes: {
  userCountOnScreen: number;
  totalUserCount: number;
  countSearchCharacters: number;
}): UIData => ({
  action: Action.Updated,
  actionSubject: ActionSubject.Field,
  actionSubjectId: 'searchUser',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14636
// https://hello.atlassian.net/browse/ADMPF-892: add userTypesSelected attribute
export const userListNoSearchResultsInviteUsers = (attributes: { countSearchCharacters: number }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'noSearchResultsInviteUsers',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14637
export const userListUsersFilter = (attributes: { filters: string[] }): UIData => ({
  action: Action.Selected,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'usersFilter',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14638
export const userListProductFilter = (attributes: { filters: string[] }): UIData => ({
  action: Action.Selected,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'productFilter',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14639
export const userListAdminFilter = (attributes: { filters: string[] }): UIData => ({
  action: Action.Selected,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'adminFilter',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14640
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListResendInviteAction = (
  attributes: {
    clickedViaFilterResults: boolean;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'resendInvite',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14641
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListGrantSiteAccessAction = (
  attributes: {
    clickedViaFilterResults: boolean;
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'grantSiteAccess',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14642
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListRevokeSiteAccessAction = (
  attributes: {
    clickedViaFilterResults: boolean;
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'revokeSiteAccess',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14643
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListUpdateProductAccessAction = (
  attributes: {
    clickedViaFilterResults: boolean;
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updateProductAccess',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14644
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListAddToGroupsAction = (
  attributes: {
    clickedViaFilterResults: boolean;
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addToGroups',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14645
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
export const userListShowDetailsAction = (
  attributes: {
    clickedViaFilterResults: boolean;
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'showDetails',
  source: userListScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14646
// https://hello.atlassian.net/browse/ADMPF-891: Add paging event

// https://data-portal.internal.atlassian.com/analytics/registry/14647
export const userListSaveProductAccess = (
  attributes: {
    productAccessBefore: string[];
    productAccessAfter: string[];
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'saveProductAccess',
  source: 'userListProductAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14648
// https://hello.atlassian.net/browse/ADMPF-892: tests (pending users-activate-user-modal.tsx tests)
export const userListCancelProductAccess = (attributes: UserListAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelProductAccess',
  source: 'userListProductAccessModal',
  attributes,
});

// https://hello.atlassian.net/browse/ADMPF-892: Needs to be added to the data portal
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
// https://hello.atlassian.net/browse/ADMPF-892: tests (pending users-activate-user-modal.tsx tests)
export const userListGrantSiteAccess = (
  attributes: {
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'grantSiteAccess',
  source: 'userListGrantAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14651
// https://hello.atlassian.net/browse/ADMPF-892: tests (pending users-activate-user-modal.tsx tests)
export const userListCancelGrantSiteAccess = (attributes: UserListAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelGrantSiteAccess',
  source: 'userListGrantAccessModal',
  attributes,
});

// https://hello.atlassian.net/browse/ADMPF-892: Needs to be added to the data portal
// https://hello.atlassian.net/browse/ADMPF-890: add product attribute
// https://hello.atlassian.net/browse/ADMPF-892: tests (pending users-activate-user-modal.tsx tests)
export const userListRevokeSiteAccess = (
  attributes: {
    lastSeenOnSite: string;
  } & UserListAttributes,
): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'revokeSiteAccess',
  source: 'userListRevokeAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14652
// https://hello.atlassian.net/browse/ADMPF-892: tests (pending users-activate-user-modal.tsx tests)
export const userListCancelRevokeSiteAccess = (attributes: UserListAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelRevokeSiteAccess',
  source: 'userListRevokeAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14760
export const jsmPortalCustomersScreenEvent = (attributes: { customerCountOnScreen?: number }): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14759
export const jsmPortalCustomersEditNameModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersEditNameModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14816
export const jsmPortalCustomersDeactivateAccountModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersDeactivateAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14817
export const jsmPortalCustomersActivateAccountModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersActivateAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14780
export const jsmPortalCustomersChangePasswordModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersChangePasswordModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14821
export const jsmPortalCustomersDeleteModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersDeleteAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15081
export const jsmPortalCustomersMigrateAccountModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'jsdPortalCustomersMigrateAccountModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14573
export const jsmPortalCustomersDeactivateAccountModalLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'deactivateAccountLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14818
export const jsmPortalCustomersActivateAccountModalLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'activateAccountLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14766
export const jsmPortalCustomersEditNameModalLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'editNameLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14781
export const jsmPortalCustomersChangePasswordLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'changePasswordLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14822
export const jsmPortalCustomersDeleteModalLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'deleteAccountLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15082
export const jsmPortalCustomersMigrateAccountLinkEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'migrateAccountLink',
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const jsmPortalCustomersDeactivateAccountModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'submitButton',
  source: 'jsdPortalCustomersDeactivateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const jsmPortalCustomersActivateAccountModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'submitButton',
  source: 'jsdPortalCustomersActivateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const jsmPortalCustomersEditNameModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'submitButton',
  source: 'jsdPortalCustomersEditNameModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const jsmPortalCustomersDeleteModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'submitButton',
  source: 'jsdPortalCustomersDeleteAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14782
export const jsmPortalCustomersChangePasswordModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'changePasswordModalSubmitButton',
  source: 'jsdPortalCustomersChangePasswordModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15084
export const jsmPortalCustomersMigrateAccountModalSubmitEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'migrateAccountModalSubmitButton',
  source: 'jsdPortalCustomersMigrateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14768
export const jsmPortalCustomersEditNameModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'editNameModalCancelButton',
  source: 'jsdPortalCustomersEditNameModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14783
export const jsmPortalCustomersChangePasswordModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'changePasswordModalCancelButton',
  source: 'jsdPortalCustomersChangePasswordModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15101
export const emojiAdminScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'emojiScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15102
export const emojiDeletionModalEvent = (): ScreenData => ({
  name: 'emojiDeletionModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15103
export const allowUploadToggleData = (uploadEnabled: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Toggle,
  actionSubjectId: 'allowEmojiUploadToggle',
  source: 'emojiScreen',
  attributes: {
    uploadEnabled,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/66021
export const allowPublicSiteEmojiToggleData = (publicCustomEmojiEnabled: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Toggle,
  actionSubjectId: 'allowPublicSiteEmojiToggle',
  source: 'emojiScreen',
  attributes: {
    publicCustomEmojiEnabled,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15104
export const emojiSearchTypedData = (queryLength: number, numMatches: number): UIData => ({
  action: Action.Searched,
  actionSubject: ActionSubject.Field,
  actionSubjectId: 'emojiSearchField',
  source: 'emojiScreen',
  attributes: {
    queryLength,
    numMatches,
  },
});

export type EmojiTableSortKey = 'shortName' | 'addedOn';

// https://data-portal.internal.atlassian.com/analytics/registry/15105
export const emojiTableSortData = (key: EmojiTableSortKey, ascending: boolean): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'emojiTableSortHeading',
  source: 'emojiScreen',
  attributes: {
    key,
    ascending,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15106
export const emojiTablePaginationData = (previousPage: number, currentPage: number): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'emojiTablePagination',
  source: 'emojiScreen',
  attributes: {
    previousPage,
    currentPage,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15107
export const emojiTableRemoveData = (emojiId: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'emojiTableRemoveButton',
  source: 'emojiScreen',
  attributes: {
    emojiId,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15108
export const emojiDeletionConfirmButton = (emojiId: string, creatorUserId: string, createdDate: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'emojiDeletionConfirmButton',
  source: 'emojiDeletionModal',
  attributes: {
    emojiId,
    creatorUserId,
    createdDate,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15109
export const emojiDeletionCancelButton = (emojiId: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'emojiDeletionCancelButton',
  source: 'emojiDeletionModal',
  attributes: {
    emojiId,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const jsmPortalCustomersDeleteModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelButton',
  source: 'jsdPortalCustomersDeleteAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15088
export const jsmPortalCustomersMigrateAccountModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'migrateAccountModalCancelButton',
  source: 'jsdPortalCustomersMigrateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14823
export const jsmPortalCustomersDeleteModalTrackEvent = (): TrackData => ({
  action: TrackAction.Deleted,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
});

export const jsmPortalCustomersDeactivateAccountModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelButton',
  source: 'jsdPortalCustomersDeactivateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const jsmPortalCustomersActivateAccountModalCancelEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelButton',
  source: 'jsdPortalCustomersActivateAccountModal',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14819
export const jsmPortalCustomersDeactivateAccountModalTrackEvent = (): TrackData => ({
  action: TrackAction.Activated,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14820
export const jsmPortalCustomersActivateAccountModalTrackEvent = (): TrackData => ({
  action: TrackAction.Deactivated,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/15091
export const jsmPortalCustomersMigrateAccountModalTrackEvent = (): TrackData => ({
  action: TrackAction.Migrated,
  actionSubject: TrackActionSubject.Customer,
  source: 'jsdPortalCustomersScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14858
export const toggledNewUserAccessTrackEventData = (attributes: NewUserAccessAttributes): TrackData => ({
  action: 'toggled',
  actionSubject: TrackActionSubject.NewUserAccess,
  source: 'productAccessConfigurationScreen',
  attributes,
});

interface DefaultGroupAttributes {
  productKey: string;
  groupId: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14857
export const makeDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'madeDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14856
export const removeDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'removedDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14857
export const makeExCoDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'madeDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14856
export const removeExCoDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'removedDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14857
export const makeHelpseekerDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'madeDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'customerAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14856
export const removeHelpseekerDefaultGroupTrackEventData = (attributes: DefaultGroupAttributes): TrackData => ({
  action: 'removedDefault',
  actionSubject: TrackActionSubject.Group,
  source: 'customerAccessConfigurationScreen',
  attributes,
});

interface RemoveGroupAttributes {
  productKey: string;
  groupId: string;
  configType: ConfigType;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14855
export const removeGroupTrackEventData = (attributes: RemoveGroupAttributes): TrackData => ({
  action: 'removed',
  actionSubject: TrackActionSubject.Group,
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14855
export const removeAdminGroupTrackEventData = (attributes: RemoveGroupAttributes): TrackData => ({
  action: 'removed',
  actionSubject: TrackActionSubject.Group,
  source: 'adminProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14855
export const removeExCoGroupTrackEventData = (attributes: RemoveGroupAttributes): TrackData => ({
  action: 'removed',
  actionSubject: TrackActionSubject.Group,
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14855
export const removeHelpseekerGroupTrackEventData = (attributes: RemoveGroupAttributes): TrackData => ({
  action: 'removed',
  actionSubject: TrackActionSubject.Group,
  source: 'customerAccessConfigurationScreen',
  attributes,
});

interface AddGroupAttributes {
  productKey: string;
  groupsAddedCount: number;
  productAccessType: ConfigType;
  groupIds: string[] | undefined;
  userIds: string[] | undefined;
  totalUsers: number | undefined;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14854
export const addGroupTrackEventData = (attributes: AddGroupAttributes): TrackData => ({
  action: 'add',
  actionSubject: TrackActionSubject.Group,
  source: 'addGroupProductAccessModal',
  attributes,
});

interface NewUserAccessAttributes {
  productKey: string;
  hasAccess: boolean;
}

// // https://data-portal.internal.atlassian.com/analytics/registry/14853
export const newUserAccessUIEventData = (attributes: NewUserAccessAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Toggle,
  actionSubjectId: 'newUserAccess',
  source: 'productAccessConfigurationScreen',
  attributes,
});

interface AddGroupUIAttributes {
  productKey: string;
}

export const addGroupUIEventData = (attributes: AddGroupUIAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addGroup',
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14852
export const makeGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'makeGroupDefault',
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14851
export const removeGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupDefault',
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14852
export const makeExCoGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'makeGroupDefault',
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14851
export const removeExCoGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupDefault',
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14852
export const makeHelpseekerGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'makeGroupDefault',
  source: 'customerAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14851
export const removeHelpseekerGroupDefaultUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupDefault',
  source: 'customerAccessConfigurationScreen',
  attributes,
});

interface AddGroupToProductAttributes {
  productKey: string;
}

interface ShowGroupDetailsAttributes {
  productKey: string;
  groupId: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14850
export const showGroupDetailsUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'showGroupDetails',
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14850
export const showAdminGroupDetailsUIEventData = (attributes: ShowGroupDetailsAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'showGroupDetails',
  source: 'adminProductAccessConfigurationScreen',
  attributes,
});

interface RemoveGroupFromProductAttributes {
  productKey: string;
  groupId: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14849
export const removeGroupFromProductUIEventData = (attributes: RemoveGroupFromProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupFromProduct',
  source: 'productAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14849
export const removeGroupFromAdminProductUIEventData = (attributes: RemoveGroupFromProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupFromProduct',
  source: 'adminProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14849
export const removeGroupFromExCoProductUIEventData = (attributes: RemoveGroupFromProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupFromProduct',
  source: 'guestProductAccessConfigurationScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14849
export const removeGroupFromHelpseekerProductUIEventData = (attributes: RemoveGroupFromProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'removeGroupFromProduct',
  source: 'customerAccessConfigurationScreen',
  attributes,
});

interface AddGroupToProductAttributes {
  productKey: string;
  groupsAddedNumber: number;
  configType: ConfigType;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14848
export const addGroupToProductUIEventData = (attributes: AddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addGroup',
  source: 'addGroupAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14848
export const addGroupToAdminProductUIEventData = (attributes: AddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'addGroup',
  source: 'addGroupAccessModal',
  attributes,
});

interface CancelAddGroupToProductAttributes {
  productKey: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14847
export const cancelAddGroupToProductUIEventData = (attributes: CancelAddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelAddGroupToProduct',
  source: 'addGroupAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/14846
export const cancelAddGroupToAdminProductUIEventData = (attributes: CancelAddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelAddGroupToAdminProduct',
  source: 'addGroupAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/29165
export const cancelAddGroupToExcoProductUIEventData = (attributes: CancelAddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelAddGroupToExcoProduct',
  source: 'addGroupAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/48739
export const cancelAddGroupToHelpseekerProductUIEventData = (attributes: CancelAddGroupToProductAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelAddGroupToHelpseekerProduct',
  source: 'addGroupAccessModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15117
export const exportUserViewEventData = (usersInSiteCount: number): ScreenData => ({
  name: 'exportUserDrawer',
  attributes: {
    usersInSiteCount,
  },
});

type UserStatusSelected = 'onlyActiveUsers' | 'allUsers';

interface DownloadExportUserTrackAttributes {
  downloadedAllUsers: boolean;
  selectedGroupsCount?: number;
  userStatusSelected: UserStatusSelected;
  additionalDataSelectedGroupMembership: boolean;
  additionalDataSelectedProductAccess: boolean;
  usersInSiteCount: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/15142
export const downloadExportUserTrackEventData = (attributes: DownloadExportUserTrackAttributes): TrackData => ({
  action: TrackAction.Downloaded,
  actionSubject: TrackActionSubject.UserList,
  actionSubjectId: 'downloadExportUserList',
  source: 'exportUserDrawer',
  attributes,
});

interface DownloadExportUserUIAttributes {
  downloadedAllUsers: boolean;
  selectedGroupsCount?: number;
  userStatusSelected: UserStatusSelected;
  additionalDataSelectedGroupMembership: boolean;
  additionalDataSelectedProductAccess: boolean;
}

// https://data-portal.internal.atlassian.com/analytics/registry/15118
export const downloadExportUserUIEventData = (attributes: DownloadExportUserUIAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'downloadExportUserList',
  source: 'exportUserDrawer',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/15119
export const cancelExportUserUIEventData = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'cancelExportUserList',
  source: 'exportUserDrawer',
});

// https://data-portal.internal.atlassian.com/analytics/registry/14797
// https://hello.atlassian.net/wiki/spaces/MEASURE/pages/361020395/3.+Exposure+tracking
export const featureExposedEvent = (
  source: string,
  flagAttributes: {
    flagKey: string;
    reason?: string;
    ruleId?: string;
    value: boolean | string;
  },
  attributes?: Record<string, any>,
): TrackEvent => ({
  data: {
    action: TrackAction.Exposed,
    actionSubject: TrackActionSubject.Feature,
    source,
    attributes: {
      ...flagAttributes,
      ...(attributes || {}),
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/21022
export const switcherooFeatureExposedEvent = (
  source: string,
  flagAttributes: {
    flagKey: string;
    reason?: string;
    ruleId?: string;
    value: boolean | string;
  },
): OperationalEvent => ({
  data: {
    action: OperationalAction.Exposed,
    actionSubject: OperationalSubject.Feature,
    source,
    attributes: {
      ...flagAttributes,
    },
    tags: ['measurement'],
  },
});

export const featureEvaluatedEvent = (source: string, attributes?: Record<string, unknown>): OperationalEvent => ({
  data: {
    action: OperationalAction.Evaluated,
    actionSubject: OperationalSubject.Feature,
    source,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14860
export const addGroupProductAccessModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'addGroupProductAccessModal',
  },
});

interface ProductAccessAttributes {
  productKeys: string[];
  productCount: number;
}

// https://data-portal.internal.atlassian.com/analytics/registry/14861
export const adminProductAccessConfigurationScreenEvent = (attributes: ProductAccessAttributes): ScreenEvent => ({
  data: {
    name: 'adminProductAccessConfigurationScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14862
export const productAccessConfigurationScreenEvent = (
  attributes: ProductAccessAttributes & { defaultProducts: string[] },
): ScreenEvent => ({
  data: {
    name: 'productAccessConfigurationScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14863
export const importAccessConfigurationScreenEvent = (attributes: ProductAccessAttributes): ScreenEvent => ({
  data: {
    name: 'importAccessConfigurationScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/29164
export const excoProductAccessConfigurationScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'guestProductAccessConfigurationScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/48658
export const customerAccessConfigurationScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'customerAccessConfigurationScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15207
export const siteAdminNavigationScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'siteAdminNavigationScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15211
export const accessIntroScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'accessIntroScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15222
export const accessTrialModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'accessTrialModal',
  },
});

interface MemberApiTokensTableAttributes {
  tokensCount: number | undefined;
}

// https://data-portal.internal.atlassian.com/analytics/registry/15718
export const memberApiTokensTableScreenEvent = (attributes: MemberApiTokensTableAttributes): ScreenEvent => ({
  data: {
    name: 'memberApiTokensTableScreen',
    attributes,
  },
});

type MemberRecentDeviceScreenKeys = 'memberRecentDevicesTableScreenEvent' | 'memberRecentDevicesLogoutModalScreenEvent';
type MemberRecentDeviceUIEventKeys =
  | 'deviceVisibilityPaginationEvent'
  | 'revokeSessionTokenButtonClickEvent'
  | 'openRevokeSessionLogoutModalButtonClickEvent'
  | 'cancelRevokeSessionTokenButtonClickEvent';
type MemberRecentDeviceTrackEventKeys = 'revokeSessionTokenTrackEvent' | 'revokeOauthTokenTrackEvent';

interface DeviceVisibilityTableAttributes {
  devicesCount: number | undefined;
}

export const memberRecentDeviceEvents: FeatureEvents<
  MemberRecentDeviceScreenKeys,
  MemberRecentDeviceUIEventKeys,
  MemberRecentDeviceTrackEventKeys,
  string
> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1918585473/Device+visibility+v2+-+Analytics+spec',
  screen: {
    memberRecentDevicesTableScreenEvent: (attributes: DeviceVisibilityTableAttributes): ScreenEvent => ({
      data: {
        name: memberRecentDevicesTableScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27036',
    }),
    memberRecentDevicesLogoutModalScreenEvent: (attributes: DeviceVisibilityTableAttributes): ScreenEvent => ({
      data: {
        name: memberRecentDevicesLogoutModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47527',
    }),
  },
  ui: {
    deviceVisibilityPaginationEvent: ({ nextPage, currentPage }: { nextPage: number; currentPage: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'pagination',
        source: memberRecentDevicesTableScreen,
        attributes: {
          currentPage,
          nextPage,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27065',
    }),
    revokeSessionTokenButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'revokeSessionTokenButton',
        source: memberRecentDevicesTableScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47525',
    }),
    openRevokeSessionLogoutModalButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openRevokeSessionLogoutModalButton',
        source: memberRecentDevicesLogoutModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48402',
    }),
    cancelRevokeSessionTokenButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRevokeSessionTokenButton',
        source: memberRecentDevicesLogoutModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48403',
    }),
  },
  track: {
    revokeSessionTokenTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.MemberSessionToken,
        source: memberRecentDevicesTableScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47526',
    }),
    revokeOauthTokenTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.MemberOauthToken,
        source: memberRecentDevicesTableScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48340',
    }),
  },
  operational: {},
};

interface MemberDetailsScreenAttributes {
  memberId: string;
}

// https://data-portal.internal.atlassian.com/analytics/registry/15312
export const memberDetailsScreenEvent = (attributes: MemberDetailsScreenAttributes): ScreenEvent => ({
  data: {
    name: memberDetailsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15394
// https://data-portal.internal.atlassian.com/analytics/registry/15395
// https://data-portal.internal.atlassian.com/analytics/registry/15396
// https://data-portal.internal.atlassian.com/analytics/registry/15397
// https://data-portal.internal.atlassian.com/analytics/registry/15398
// https://data-portal.internal.atlassian.com/analytics/registry/15399
// https://data-portal.internal.atlassian.com/analytics/registry/15400
// https://data-portal.internal.atlassian.com/analytics/registry/15401

// Navigation Next
// orgHeader - https://data-portal.internal.atlassian.com/analytics/registry/15992
// orgOverview - https://data-portal.internal.atlassian.com/analytics/registry/15993
// managedAccounts - https://data-portal.internal.atlassian.com/analytics/registry/15994
// userProvisioning - https://data-portal.internal.atlassian.com/analytics/registry/15995
// saml - https://data-portal.internal.atlassian.com/analytics/registry/15996
// passwordPolicy - https://data-portal.internal.atlassian.com/analytics/registry/15997
// twoStepVerification - https://data-portal.internal.atlassian.com/analytics/registry/15998
// auditLog - https://data-portal.internal.atlassian.com/analytics/registry/15999
// orgDetails - https://data-portal.internal.atlassian.com/analytics/registry/16000
// orgAdmins - https://data-portal.internal.atlassian.com/analytics/registry/16001
// domains - https://data-portal.internal.atlassian.com/analytics/registry/16002
// apiKeys - https://data-portal.internal.atlassian.com/analytics/registry/16003
// orgBillingOverview - https://data-portal.internal.atlassian.com/analytics/registry/16004
// orgBillingDetails - https://data-portal.internal.atlassian.com/analytics/registry/16005
// orgBillingHistory - https://data-portal.internal.atlassian.com/analytics/registry/16006
// orgBillingEstimate - https://data-portal.internal.atlassian.com/analytics/registry/16007
// orgManageSubscriptions - https://data-portal.internal.atlassian.com/analytics/registry/16008
// orgSiteRedirect - https://data-portal.internal.atlassian.com/analytics/registry/16009
// backToOrgFromSite - https://data-portal.internal.atlassian.com/analytics/registry/16010
// backToOrgArrow - https://data-portal.internal.atlassian.com/analytics/registry/16011
// siteUsers - https://data-portal.internal.atlassian.com/analytics/registry/16012
// siteGroups - https://data-portal.internal.atlassian.com/analytics/registry/16013
// siteAccessRequests - https://data-portal.internal.atlassian.com/analytics/registry/16014
// jiraServiceManagement - https://data-portal.internal.atlassian.com/analytics/registry/16015
// siteAccess - https://data-portal.internal.atlassian.com/analytics/registry/16016
// productAccess - https://data-portal.internal.atlassian.com/analytics/registry/16017
// siteGSuite - https://data-portal.internal.atlassian.com/analytics/registry/16018
// siteEmoji - https://data-portal.internal.atlassian.com/analytics/registry/16019
// connectedApps - https://data-portal.internal.atlassian.com/analytics/registry/16020
// siteDiscoverApplications - https://data-portal.internal.atlassian.com/analytics/registry/16021
// siteBillingOverview - https://data-portal.internal.atlassian.com/analytics/registry/16022
// siteBillingDetails - https://data-portal.internal.atlassian.com/analytics/registry/16024
// siteBillingHistory - https://data-portal.internal.atlassian.com/analytics/registry/16025
// siteBillingEstimate - https://data-portal.internal.atlassian.com/analytics/registry/16027
// siteManagedSubscriptions - https://data-portal.internal.atlassian.com/analytics/registry/16028
// ipAllowList - https://data-portal.internal.atlassian.com/analytics/registry/26473
// mobileSecurity - https://data-portal.internal.atlassian.com/analytics/registry/36132

export const navigationItemEvent = (source: string, actionSubjectId: string, attributes: Record<string, string> = {}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.NavigationItem,
    actionSubjectId,
    source,
    attributes: {
      ...attributes,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15476
export const revokeMemberTokenButtonClickEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'revokeMemberTokenButton',
    source: memberDetailsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15477
export const revokeMemberTokenTrackEvent = (): TrackEvent => ({
  data: {
    action: TrackAction.Revoked,
    actionSubject: TrackActionSubject.MemberApiToken,
    source: memberDetailsScreen,
  },
});

const idpActivationExperimentScreen = 'idpActivationExperimentScreen';
// https://data-portal.internal.atlassian.com/analytics/registry/16153
export const adminAccessActivationExperimentScreenEvent = (
  adminAccessActivationEnabled: boolean | undefined,
  organizationCount: number | undefined,
  hasClaimedDomain: boolean | undefined,
  isTrialFourDaysPastStart: boolean | undefined,
  surveyCompleted: boolean | undefined,
): ScreenEvent => ({
  data: {
    name: idpActivationExperimentScreen,
    attributes: {
      adminAccessActivationEnabled,
      organizationCount,
      hasClaimedDomain,
      isTrialFourDaysPastStart,
      surveyCompleted,
    },
  },
});

const idpActivationScreen = 'idpActivationScreen';

// https://data-portal.internal.atlassian.com/analytics/registry/16153
export const adminAccessActivationScreenEvent = (): ScreenEvent => ({
  data: {
    name: idpActivationScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15870
export const adminAccessActivationVerifyDomainButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'verifyDomainButton',
    source: idpActivationScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15872
export const adminAccessActivationDoItLaterButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'doItLaterButton',
    source: idpActivationScreen,
  },
});

const idpActivationFeedbackModal = 'idpActivationFeedbackModal';

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const adminAccessActivationFeedbackSubmitUIEvent = (attributes: { accessDomainClaimSurveyResponseValue: string }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'submitButton',
    source: idpActivationFeedbackModal,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const adminAccessActivationFeedbackCancelUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelButton',
    source: idpActivationFeedbackModal,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16154
export const idpActivationFeedbackModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: idpActivationFeedbackModal,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15715
export const idpAwarenessBannerScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'idpAwarenessBanner',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15713
export const idpAwarenessBannerInterestedButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'interestedButton',
    source: 'idpAwarenessBanner',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15714
export const idpAwarenessBannerNotInterestedButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'notInterestedButton',
    source: 'idpAwarenessBanner',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15645
export const idpAwarenessFeedbackModalScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'idpAwarenessFeedbackModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const idpAwarenessFeedbackModalSubmitUIEvent = (attributes: {
  accessDomainClaimSurveyResponseValue: string;
  freeformResponse: string;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'submitButton',
    source: 'idpAwarenessFeedbackModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const idpAwarenessFeedbackModalCancelUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelButton',
    source: 'idpAwarenessFeedbackModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15928
export const idpAwarenessSamlLinkSidebarEvent = (): ScreenEvent => ({
  data: {
    name: 'idpAwarenessSamlLinkSidebar',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15757
export const idpAwarenessSamlScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'idpAwarenessSamlScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15713
export const idpAwarenessSamlInterestedButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'interestedButton',
    source: 'idpAwarenessSamlScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15714
export const idpAwarenessSamlNotInterestedButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'notInterestedButton',
    source: 'idpAwarenessSamlScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18236
export const verifiedDomainClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'verifiedDomain',
    source: samlScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18237
export const identityProviderClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'identityProvider',
    source: samlScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15981
export const verifyDomainPageButtonUIEvent = (attributes: { variation?: 'dnsScreen' | 'httpsScreen' }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'verifyDomain',
    source: domainsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15982
export const verifyDomainPageLearnMoreLinkUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnMoreDomainVerification',
    source: domainsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15983
export const verifyDomainPageCopyButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'copyDomain',
    source: domainsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15984
export const verifyDomainPageVerifyLinkUIEvent = (attributes: {
  status?: string;
  verificationType?: 'http' | 'dns' | 'google' | 'azure' | null;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'verifyDomain',
    source: domainsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15985
export const verifyDomainPageRemoveLinkUIEvent = (attributes: {
  status?: string;
  verificationType?: 'http' | 'dns' | 'google' | 'azure' | null;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'removeDomain',
    source: domainsScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15986
export const verifyDomainPageDownloadFileButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'downloadFile',
    source: domainsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15987
export const verifyDomainPageHttpsRadioUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.RadioButton,
    actionSubjectId: 'https',
    source: domainsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15988
export const verifyDomainPageTxtRecordRadioUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.RadioButton,
    actionSubjectId: 'txtRecord',
    source: domainsScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15981
export const verifyDomainModalVerifyButtonUIEvent = (attributes: { domain: string; method?: 'txtRecord' | 'https' }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'verifyDomain',
    source: 'verifyDomainModal',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/15990
export const verifyDomainModalCancelButtonUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancel',
    source: 'verifyDomainModal',
  },
});

export interface FeedbackModalAttributes {
  feedbackType: string;
  numberOfUsersInvited?: number;
  numberOfGroupsAdded?: number;
}

const feedbackModalScreen = 'feedbackModal';

// https://data-portal.internal.atlassian.com/analytics/registry/16335
export const feedbackModalScreenEvent = (attributes?: FeedbackModalAttributes): ScreenEvent => ({
  data: {
    name: feedbackModalScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14767
export const feedbackModalSubmitButtonUIEvent = (
  attributes?: FeedbackModalAttributes & { feedbackIssueId: string; followUpPermission?: boolean },
): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'submitButton',
    source: feedbackModalScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14571
export const feedbackModalCancelButtonUIEvent = (attributes?: FeedbackModalAttributes): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelButton',
    source: feedbackModalScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16336
export const feedbackModalViewSupportUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'viewSupport',
    source: feedbackModalScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16337
export const feedbackModalContactSupportUIEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'contactSupport',
    source: feedbackModalScreen,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16380
export const viewJiraProjectRolesButtonUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'viewJiraProjectRoles',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/16381
export const editJiraUserPropertiesButtonUIEventData = (attributes: BaseUserPageAttributes): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.DropListItem,
  actionSubjectId: 'editJiraUserProperties',
  source: 'userDetailsScreen',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/16252
export const storageViewScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'viewStorageScreen',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16254
export const increaseStorageButtonUIEvent = (attributes: {
  product: UserSiteAccessProduct;
  storageUsage: number;
  currentEdition: string;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'increaseStorageButton',
    source: 'viewStorageScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16255
export const viewStoragePolicyLinkUIEvent = (attributes: {
  product: UserSiteAccessProduct;
  storageUsage: number;
  currentEdition: string;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'storagePolicyLink',
    source: 'viewStorageScreen',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16703
export const auditLogModalExportButtonUIEventData = (attributes?: { error: any }): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'downloadAuditLogCsv',
    source: auditLogScreen,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16829
export const exportAuditLogButtonUIEventData = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'exportAuditLog',
    source: 'exportAuditLogModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16843
export const exportAuditLogModalData = (): ScreenEvent => ({
  data: {
    name: 'exportAuditLogModal',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/16844
export const cancelAuditLogExportData = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'cancelAuditLogExport',
    source: 'exportAuditLogModal',
  },
});

export interface ExperimentExposedAttributes {
  /** Suggested Cohort from launchDarkly. */
  cohort:
    | 'control'
    | 'variant'
    | 'variation'
    | 'not-enrolled' // Can be not-enrolled during rollout
    | undefined; // if failure reaching LD
  exposedCohort: // final cohort after extra checks for eligibility
  'control' | 'variant' | 'variation' | 'not-enrolled';
  experimentKey: string;
  ineligibilityReasons?: string[];
}

// https://data-portal.internal.atlassian.com/analytics/registry/17858
export const experimentExposedEvent = (source: string, attributes: ExperimentExposedAttributes): OperationalEvent => ({
  data: {
    action: OperationalAction.Exposed,
    actionSubject: OperationalSubject.Experiment,
    source,
    attributes,
  },
});

export interface AccessRequestKey {
  userId: string;
  productId: string;
}

export interface DeeplinkedAccessRequestAttributes {
  mismatched: boolean;
  userId?: string;
  productId?: string;
  status: 'shown' | 'mismatched';
}

// https://data-portal.internal.atlassian.com/analytics/registry/19097
export const deeplinkedAccessRequestEvent = (source: string, attributes: DeeplinkedAccessRequestAttributes): OperationalEvent => ({
  data: {
    action: OperationalAction.Triggered,
    actionSubject: OperationalSubject.DeeplinkedAccessRequest,
    source,
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/17859
export const confirmInviteModalScreenEvent = (numberOfUsersInvited: number): ScreenEvent => ({
  data: {
    name: 'confirmInviteModal',
    attributes: {
      numberOfUsersInvited,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18230
export const oktaAccountCreationScreenEvent = (): ScreenEvent => ({
  data: {
    name: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18229
export const oktaAccountCreatedScreenEvent = (): ScreenEvent => ({
  data: {
    name: oktaAccountCreatedScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18228
export const oktaErrorScreenEvent = (): ScreenEvent => ({
  data: {
    name: oktaErrorScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18227
export const oktaAccountSignupEvent = (source: string): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaAccountSignup',
    source,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18226
export const finishCreateOktaAccountEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'finishCreateOktaAccount',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18225
export const finishCreateOktaAccountTrackEvent = (): TrackEvent => ({
  data: {
    action: TrackAction.Created,
    actionSubject: TrackActionSubject.OktaAccount,
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18224
export const learnMoreCloudConnectClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnMoreCloudConnect',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18223
export const oktaHelpCenterClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaHelpCenter',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18222
export const oktaSecurityClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaSecurity',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18312
export const oktaDatasheetClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaDatasheet',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18221
export const oktaTermsAndConditionsClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaTermsAndConditions',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18220
export const oktaPrivacyPolicyClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'oktaPrivacyPolicy',
    source: oktaAccountCreationScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18219
export const learnMoreOktaActiveDirectoryClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'learnMoreOktaActiveDirectory',
    source: oktaAccountCreatedScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18218
export const manageOktaPreviewClickedEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'manageOktaPreview',
    source: oktaAccountCreatedScreenName,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/14881
export const oktaAccountCreatedCloseEvent = (): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Button,
    actionSubjectId: 'close',
    source: oktaAccountCreatedScreenName,
  },
});

export interface AtlassianSwitcherEventContext {
  navigationCtx?: {
    attributes?: {
      domain: string;
      itemId: string;
      productType: string;
    };
  };
}

export interface AtlassianSwitcherEvent extends AkUIAnalyticsEvent {
  payload: {
    action: Action;
    actionSubject: ActionSubject;
    actionSubjectId?: string;
    eventType: string;
    attributes: object;
  };
  context: AtlassianSwitcherEventContext[];
}

/*
 * Atlassian Switcher events are linked together via tag to share the same data-portal entry
 * regardless of which product the event comes from.
 *
 * https://data-portal.internal.atlassian.com/analytics/registry/17054
 * https://data-portal.internal.atlassian.com/analytics/registry/15740
 * https://data-portal.internal.atlassian.com/analytics/registry/18142
 * https://data-portal.internal.atlassian.com/analytics/registry/18141
 */
export const atlassianSwitcherUIEvent = (event: AtlassianSwitcherEvent): UIData => {
  const payload = event.payload || {};
  const { action, actionSubject, actionSubjectId, attributes } = payload;

  return {
    action,
    actionSubject,
    actionSubjectId: actionSubjectId || actionSubject,
    attributes,
    source: 'atlassian-switcher',
    tags: ['navigation'],
  };
};

export const domainDetailsScreen = 'domainDetailsScreen';
export const domainClaimSuccessScreen = 'domainClaimSuccessScreen';

// screen: domainDetailsScreen, https://data-portal.internal.atlassian.com/analytics/registry/18242
export const domainDetailsScreenEvent = (): ScreenEvent => ({
  data: {
    name: domainDetailsScreen,
  },
});

// screen: domainClaimSuccessScreen, https://data-portal.internal.atlassian.com/analytics/registry/18243
export const domainClaimSuccessScreenEvent = (): ScreenEvent => ({
  data: {
    name: domainClaimSuccessScreen,
  },
});

// exportAccounts, https://data-portal.internal.atlassian.com/analytics/registry/18244
export const exportAccountsClickedUIEvent = (memberTotal: number): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'exportAccounts',
  source: domainDetailsScreen,
  attributes: {
    numberOfAccounts: memberTotal,
  },
});

// closeDrawer, https://data-portal.internal.atlassian.com/analytics/registry/18245
export const closeDomainDrawerUIEvent = (source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeDrawer',
  source,
});

// claimAccounts, https://data-portal.internal.atlassian.com/analytics/registry/18246
export const claimAccountsButtonUIEvent = (source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'claimAccounts',
  source,
});

// verifyDomainButton, https://data-portal.internal.atlassian.com/analytics/registry/15870
export const verifyDomainUIEvent = (source: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'verifyDomainButton',
  source,
});

// viewManagedAccounts, https://data-portal.internal.atlassian.com/analytics/registry/18247
export const viewManagedAccountsUIEvent = (source?: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'viewManagedAccounts',
  source: source ?? domainClaimSuccessScreen,
});

// accountsPerDomain, https://data-portal.internal.atlassian.com/analytics/registry/18248
export const viewAccountsPerDomainUIEvent = (memberTotal: number): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'accountsPerDomain',
  source: domainsScreen,
  attributes: {
    numberOfAccounts: memberTotal,
  },
});

// openClaimAccountsDrawer, https://data-portal.internal.atlassian.com/analytics/registry/18249
export const openClaimAccountsDrawerUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'openClaimAccountsDrawer',
  source: domainsScreen,
});

// accountsClaimed, https://data-portal.internal.atlassian.com/analytics/registry/18251
export const accountsClaimedTrackEventData = (memberTotal: number): TrackData => ({
  action: TrackAction.Claimed,
  actionSubject: TrackActionSubject.Accounts,
  source: domainDetailsScreen,
  attributes: {
    numberOfAccounts: memberTotal,
  },
});

// screen: sessionDurationScreen, https://data-portal.internal.atlassian.com/analytics/registry/18456
export const sessionDurationScreenEvent = (hasDomainVerified = false): ScreenEvent => ({
  data: {
    name: sessionDurationScreen,
    attributes: {
      hasDomainVerified,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18457
export const updateSessionDurationUIEvent = (idleSessionDuration: number): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updateSessionDurationButton',
  source: sessionDurationScreen,
  attributes: {
    idleSessionDuration,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/18458
export const sessionDurationTrackEvent = (idleSessionDuration: number): TrackData => ({
  action: TrackAction.Changed,
  actionSubject: TrackActionSubject.SessionDuration,
  source: sessionDurationScreen,
  attributes: {
    idleSessionDuration,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/19158
export const externalRequestRenderUIEvent = (): UIData => ({
  // this should be track event, but due to analytic-next limitation, it supports only UI
  // intentionally not to put it into Action declaration
  action: 'rendered' as Action,
  actionSubject: 'accessRequest' as ActionSubject,
  actionSubjectId: 'externalAccessRequest',
  source: 'accessRequestSiteAdminScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/23119
export const updateRequestSettingsUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updateRequestSettingsButton',
  source: 'accessRequestSiteAdminScreen',
});

// https://data-portal.internal.atlassian.com/analytics/registry/24665
export const disableMemberTwoStepVerificationUIEvent = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'disableMemberTwoStepVerification',
  source: memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/24666
export const enableMemberTwoStepVerificationUIEvent = (attributes: { orgId: string; memberId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'enableMemberTwoStepVerification',
  source: memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/24668
export const disableMemberTwoStepVerificationTrackEventData = (attributes: { orgId: string; memberId: string }): TrackData => ({
  action: TrackAction.Disabled,
  actionSubject: TrackActionSubject.TwoStepVerification,
  source: memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/24667
export const enableMemberTwoStepVerificationTrackEventData = (attributes: { orgId: string; memberId: string }): TrackData => ({
  action: TrackAction.Enabled,
  actionSubject: TrackActionSubject.TwoStepVerification,
  source: memberDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33832
export const forgeLogsViewEventData = (attributes: { appId: string; installationContext: string }): ScreenData => ({
  name: 'forgeLogsModal',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33640
export const viewForgeLogsUIEvent = (attributes: { appId: string; installationContext: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'viewForgeLogs',
  source: userConnectedAppDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33921
export const closeForgeLogsModalUIEvent = (attributes: { appId: string; installationContext: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'closeFogeLogsModal',
  source: userConnectedAppsForgeLogsModal,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/72955
export const userConnectedAppsSearchFilterClickedUIEvent = (attributes: { searchValue: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.TextField,
  actionSubjectId: 'userConnectedAppsSearchFilter',
  source: userConnectedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/72954
export const userConnectedAppsInstalledByFilterClickedUIEvent = (attributes: {
  selectedInstalledByOptions: string[] | undefined;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Dropdown,
  actionSubjectId: 'userConnectedAppsInstalledByFilter',
  source: userConnectedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75721
export const requestedAppsSearchFilterClickedUIEvent = (attributes: { searchValue: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.TextField,
  actionSubjectId: 'appRequestsSearchFilter',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75724
export const requestedAppsStatusFilterClickedUIEvent = (attributes: { selectedStatusOptions: string[] | undefined }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.SelectItem,
  actionSubjectId: 'appRequestsStatusFilter',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75725
export const requestedAppsProductFilterClickedUIEvent = (attributes: { selectedProductOptions: string[] | undefined }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.SelectItem,
  actionSubjectId: 'appRequestsProductFilter',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75726
export const requestedAppsActionDropdownClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestsActionMenu',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75727
export const requestedAppsActionDropdownViewDetailsOptionClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.MenuItem,
  actionSubjectId: 'appRequestsActionsViewDetailsOption',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75728
export const requestedAppsActionDropdownRejectRequestOptionClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.MenuItem,
  actionSubjectId: 'appRequestsActionsRejectRequestOption',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75729
export const requestedAppsActionDropdownReviewOptionClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.MenuItem,
  actionSubjectId: 'appRequestsActionsReviewOption',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75730
export const requestedAppsReviewRequestButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestsActionsReviewButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75731
export const requestedAppsReviewModalCloseButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestReviewModalCloseButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75732
export const requestedAppsReviewModalRejectRequestButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestReviewModalRejectRequestButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75733
export const requestedAppsReviewModalViewAppDetailsButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestReviewModalViewAppDetailsButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75734
export const requestedAppsRejectModalCancelRequestButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestRejectModalCancelRejectModalButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75735
export const requestedAppsRejectModalViewAppDetailsLinkClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'appRequestRejectModalViewAppDetailsLink',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/75736
export const requestedAppsRejectModalRejectRequestButtonClickedUIEvent = (attributes: { appKey: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'appRequestRejectModalRejectRequestButton',
  source: requestedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/72953
export const userConnectedAppsBuildNewAppLinkClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'userConnectedAppsBuildNewAppLink',
  source: userConnectedAppsScreen,
});

export const userConnectedAppsAppRowClickedUIEvent = (attributes: { appId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Row,
  actionSubjectId: 'appWithMultipleInstallations',
  source: userConnectedAppsScreen,
  attributes,
});

export const userConnectedAppsActionMenuItemClickedUIEvent = (attributes: { appId: string }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.MenuItem,
  actionSubjectId: 'ellipsisAction',
  source: userConnectedAppsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/72952
export const userConnectedAppsDiscoverNewAppsCtaClickedUIEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'userConnectedAppsDiscoverNewAppsCta',
  source: userConnectedAppsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/72957
export const userConnectedAppsInstalledOnOptionClickedUIEvent = (attributes: {
  selectedInstalledOnOption: string | undefined;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Dropdown,
  actionSubjectId: 'userConnectedAppsInstalledOnOption',
  source: userConnectedAppsScreen,
  attributes,
});

export const userConnectedAppsSettingsSetAccessTokenSubmitButtonClickedUIEvent = (attributes: {
  accessTokenLicenseState: string | undefined;
  capabilitySet: string | undefined;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsSettingsSetAccessTokenSubmit',
  source: 'userConnectedAppsSettingsTab',
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33797
export const downloadForgeLogsUIEvent = (attributes: {
  appId: string;
  installationContext: string;
  startDate: string;
  endDate: string;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'downloadForgeLogs',
  source: userConnectedAppsForgeLogsModal,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33806
export const previewForgeLogsUIEvent = (attributes: {
  appId: string;
  installationContext: string;
  startDate: string;
  endDate: string;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'previewForgeLogs',
  source: userConnectedAppsForgeLogsModal,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33833
export const forgeLogsPreviewTrackEventData = (attributes: {
  appId: string;
  installationContext: string;
  startDate: string;
  endDate: string;
  logLineNumbers: number;
}): TrackData => ({
  action: TrackAction.Submitted,
  actionSubject: TrackActionSubject.ForgeLogsPreview,
  actionSubjectId: 'forgeLogsPreviewLines',
  source: userConnectedAppsForgeLogsModal,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/33835
export const forgeLogsDateUpdateUIEvent = (attributes: {
  appId: string;
  installationContext: string;
  startDate: string;
  endDate: string;
  errors: (string | undefined)[];
}): UIData => ({
  action: Action.Updated,
  actionSubject: ActionSubject.Datepicker,
  actionSubjectId: 'forgeLogsDateUpdate',
  source: userConnectedAppsForgeLogsModal,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/17469
export const forgeLogsPreviewFetchEvent = (attributes: {
  appId: string;
  installationContext: string;
  startDate: string;
  endDate: string;
  responseTime: number;
}): OperationalEvent => ({
  data: {
    action: OperationalAction.Fetched,
    actionSubject: OperationalSubject.GraphqlSever,
    actionSubjectId: 'fetchForgeLogsPreview',
    source: 'any',
    attributes,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/39361
export const viewForgeLogsAppDetailsPageUIEvent = (attributes: { appId: string; installationContexts: string[] }): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'viewForgeLogsAppDetailsPage',
  source: userConnectedAppDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/39358
export const updateForgeLogsAccessUIEvent = (attributes: {
  appId: string;
  installationContexts: string[];
  appEnvironment: string;
}): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'updateForgeLogsAccess',
  source: userConnectedAppDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/39359
export const enableForgeLogsAccessTrackEventData = (attributes: {
  appId: string;
  installationContexts: string[];
  success: boolean;
}): TrackData => ({
  action: TrackAction.Submitted,
  actionSubject: TrackActionSubject.EnableForgeLogsAccess,
  actionSubjectId: 'enableForgeLogsAccess',
  source: userConnectedAppDetailsScreen,
  attributes,
});

// https://data-portal.internal.atlassian.com/analytics/registry/39360
export const disableForgeLogsAccessTrackEventData = (attributes: {
  appId: string;
  installationContexts: string[];
  success: boolean;
}): TrackData => ({
  action: TrackAction.Submitted,
  actionSubject: TrackActionSubject.DisableForgeLogsAccess,
  actionSubjectId: 'disableForgeLogsAccess',
  source: userConnectedAppDetailsScreen,
  attributes,
});

export enum CommonExpiredSamlConfigIdpBannerScreens {
  authPolicyListScreen = AuthPolicyScreens.authPolicyListScreen,
  securityChecklistScreen = SecurityChecklistScreen.securityChecklistScreen,
}

// https://data-portal.internal.atlassian.com/analytics/registry/47771
export const commonExpiredSamlConfigCheckSamlCertificationLinkClickEvent = (attributes: {
  source: CommonExpiredSamlConfigIdpBannerScreens;
}): UIEvent => ({
  data: {
    action: Action.Clicked,
    actionSubject: ActionSubject.Link,
    actionSubjectId: 'commonExpiredSamlConfigCheckSamlCertificationLink',
    source: attributes.source as unknown as string,
  },
  dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47771',
});

// https://data-portal.internal.atlassian.com/analytics/registry/47772
export const commonExpiredSamlConfigIdpBannerEvent = (attributes: { source: CommonExpiredSamlConfigIdpBannerScreens }): ScreenEvent => ({
  data: {
    name: 'commonExpiredSamlConfigIdpBannerEvent',
    attributes,
  },
  dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47772',
});

// https://data-portal.internal.atlassian.com/analytics/registry/48721
export const viewDomainsLinkClickedEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'viewDomains',
  source: membersPageScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/65952
export const dismissAccessRebrandBannerClickedEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'dismissAccessRebrandBanner',
  source: 'accessRebrandBanner',
});

// https://data-portal.internal.atlassian.com/analytics/registry/65953
export const whyAccessRebrandLinkClickedEvent = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'whyAccessRebrand',
  source: 'accessRebrandBanner',
});

// https://data-portal.internal.atlassian.com/analytics/registry/79094
export const connectedAppsInstallIntegrationsSelectAppScreenEvent = (): ScreenEvent => ({
  data: {
    name: 'userConnectedAppsInstallIntegrationsSelectApp',
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79099
export const connectedAppsInstallIntegrationsConfigureProductScreenEvent = (connectorType: string): ScreenEvent => ({
  data: {
    name: 'userConnectedAppsInstallIntegrationsConfigureProduct',
    attributes: {
      connectorType,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79101
export const connectedAppsConnectorDetailsScreenEvent = (connectorType: string): ScreenEvent => ({
  data: {
    name: 'userConnectedAppsConnectorDetails',
    attributes: {
      connectorType,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79102
export const connectedAppsConnectorSettingsScreenEvent = (connectorType: string): ScreenEvent => ({
  data: {
    name: 'userConnectedAppsConnectorSettings',
    attributes: {
      connectorType,
    },
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79095
export const userConnectedAppsInstallIntegrationsOpenDrawerClicked = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsInstallIntegrationsButtonOpenDrawer',
  source: userConnectedAppsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/79096
export const userConnectedAppsInstallIntegrationsCloseDrawerClicked = (): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsInstallIntegrationsButtonCloseDrawer',
  source: userConnectedAppsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/79097
export const userConnectedAppsInstallIntegrationsDrawerClosed = (): UIData => ({
  action: Action.Closed,
  actionSubject: ActionSubject.Modal,
  actionSubjectId: 'userConnectedAppsInstallIntegrationsCloseDrawer',
  source: userConnectedAppsScreen,
});

// https://data-portal.internal.atlassian.com/analytics/registry/79098
export const userConnectedAppsInstallIntegrationsSelectConnector = (connectorType: string): UIData => ({
  action: Action.Selected,
  actionSubject: ActionSubject.Radio,
  actionSubjectId: 'userConnectedAppsInstallIntegrationsSelectConnector',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79100
export const userConnectedAppsTableConnectorDetailsClicked = (connectorType: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Link,
  actionSubjectId: 'userConnectedAppsTableConnectorDetailsClicked',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79103
export const userConnectedAppsConnectorUninstallButtonClicked = (connectorType: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsConnectorUninstallButtonClicked',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79104
export const userConnectedAppsConnectorCancelUninstallButtonClicked = (connectorType: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsConnectorCancelUninstallButtonClicked',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79105
export const userConnectedAppsConnectorConfirmUninstallButtonClicked = (connectorType: string): UIData => ({
  action: Action.Clicked,
  actionSubject: ActionSubject.Button,
  actionSubjectId: 'userConnectedAppsConnectorConfirmUninstallButtonClicked',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79116
export const userConnectedAppsInstallIntegrationsInstallationComplete = (connectorType: string): TrackData => ({
  action: TrackAction.Connected,
  actionSubject: TrackActionSubject.TWGConnector,
  actionSubjectId: 'userConnectedAppsInstallIntegrationsInstallationComplete',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79115
export const userConnectedAppsConnectorUninstalledComplete = (connectorType: string): TrackData => ({
  action: TrackAction.Disconnected,
  actionSubject: TrackActionSubject.TWGConnector,
  actionSubjectId: 'userConnectedAppsConnectorUninstalledComplete',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});

// https://data-portal.internal.atlassian.com/analytics/registry/79114
export const userConnectedAppsConnectorUninstalledErrored = (connectorType: string): TrackData => ({
  action: TrackAction.Errored,
  actionSubject: TrackActionSubject.TWGConnector,
  actionSubjectId: 'userConnectedAppsConnectorUninstalledErrored',
  source: userConnectedAppsScreen,
  attributes: {
    connectorType,
  },
});
