import type { UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'complianceLandingScreen'
  | 'hipaaComplianceFocusTaskScreen'
  | 'baaFeatureGateScreen'
  | 'baaSignInFocusTaskScreen'
  | 'manageProductsScreen'
  | 'selectProductsFocusTaskStepScreen'
  | 'reviewAndTagFocusTaskStepScreen'
  | 'untagProductModalScreen';

type UiKeys =
  | 'hipaaComplianceCardAction'
  | 'learnMoreAboutCompliancePrograms'
  | 'learnMoreAboutHipaa'
  | 'hipaaComplianceBackAction'
  | 'hipaaComplianceUnderstandAction'
  | 'hipaaComplianceLearnMoreAboutHipaa'
  | 'hipaaComplianceLearnHowToSignBaa'
  | 'hipaaComplianceLearnMoreAboutTaggingProducts'
  | 'hipaaComplianceLearnMoreAboutImplementationGuide'
  | 'baaSignInAction'
  | 'baaFeatureGateLearnMoreAboutHipaa'
  | 'baaFeatureGateLearnHowToSignBaa'
  | 'baaSignInCancelAction'
  | 'baaSignInSubmitAction'
  | 'baaSignInLearnHowToSignBaa'
  | 'manageProductsViewImplementationGuide'
  | 'backToComplianceHomeAction'
  | 'tagProductsAction'
  | 'untagProductAction'
  | 'untagProductModalCancelAction'
  | 'untagProductModalUntagAction'
  | 'untagProductModalLearnMoreAboutHipaa'
  | 'selectProductsLearnMoreAboutTaggingProducts'
  | 'selectProductsBackAction'
  | 'selectProductsReviewAction'
  | 'selectProductAction'
  | 'reviewAndTagLearnMoreAboutHipaa'
  | 'reviewAndTagLearnMoreAboutTaggingProducts'
  | 'reviewAndTagBackAction'
  | 'reviewAndTagProductsAction'
  | 'enterpriseContactUsAction';

type TrackKeys = 'productsTagged' | 'productUntagged' | 'baaAgreementSignInRequested';

interface EventDataAttributes {
  cloudId: string;
  product: UiProductType;
}

export enum ComplianceScreens {
  complianceLandingScreen = 'complianceLandingScreen',
  hipaaComplianceFocusTaskScreen = 'hipaaComplianceFocusTaskScreen',
  baaFeatureGateScreen = 'baaFeatureGateScreen',
  baaSignInFocusTaskScreen = 'baaSignInFocusTaskScreen',
  manageProductsScreen = 'manageProductsScreen',
  selectProductsFocusTaskStepScreen = 'selectProductsFocusTaskStepScreen',
  reviewAndTagFocusTaskStepScreen = 'reviewAndTagFocusTaskStepScreen',
  untagProductModalScreen = 'untagProductModalScreen',
}

export const complianceEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/DaPo/pages/2781229137/HIPAA+compliance+analytics+specs',

  screen: {
    complianceLandingScreen: (): ScreenEvent => ({
      data: {
        name: ComplianceScreens.complianceLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54836',
    }),
    hipaaComplianceFocusTaskScreen: (): ScreenEvent => ({
      data: {
        name: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54837',
    }),
    baaFeatureGateScreen: (attributes: { baaStatus: string }): ScreenEvent => ({
      data: {
        name: ComplianceScreens.baaFeatureGateScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54838',
    }),
    baaSignInFocusTaskScreen: (): ScreenEvent => ({
      data: {
        name: ComplianceScreens.baaSignInFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54839',
    }),
    manageProductsScreen: (attributes: { taggedProductsCount: number; totalProductsCount: number }): ScreenEvent => ({
      data: {
        name: ComplianceScreens.manageProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54840',
    }),
    selectProductsFocusTaskStepScreen: (attributes: { untaggedProductsCount: number; totalProductsCount: number }): ScreenEvent => ({
      data: {
        name: ComplianceScreens.selectProductsFocusTaskStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54841',
    }),
    reviewAndTagFocusTaskStepScreen: (): ScreenEvent => ({
      data: {
        name: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54842',
    }),
    untagProductModalScreen: (): ScreenEvent => ({
      data: {
        name: ComplianceScreens.untagProductModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54843',
    }),
  },

  ui: {
    hipaaComplianceCardAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'hipaaComplianceCardAction',
        source: ComplianceScreens.complianceLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54893',
    }),
    learnMoreAboutCompliancePrograms: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutCompliancePrograms',
        source: ComplianceScreens.complianceLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54894',
    }),
    learnMoreAboutHipaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutHipaa',
        source: ComplianceScreens.complianceLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54895',
    }),
    hipaaComplianceBackAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backAction',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54896',
    }),
    hipaaComplianceUnderstandAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'understandAction',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54897',
    }),
    hipaaComplianceLearnMoreAboutHipaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'hipaaComplianceLearnMoreAboutHipaa',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54910',
    }),
    hipaaComplianceLearnHowToSignBaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'hipaaComplianceLearnHowToSignBaa',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54911',
    }),
    hipaaComplianceLearnMoreAboutTaggingProducts: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutTaggingProducts',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54912',
    }),
    hipaaComplianceLearnMoreAboutImplementationGuide: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutImplementationGuide',
        source: ComplianceScreens.hipaaComplianceFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54913',
    }),
    baaSignInAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'baaSignInAction',
        source: ComplianceScreens.baaFeatureGateScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54898',
    }),
    enterpriseContactUsAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'enterpriseContactUsAction',
        source: ComplianceScreens.baaFeatureGateScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54951',
    }),
    baaFeatureGateLearnMoreAboutHipaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'baaFeatureGateLearnMoreAboutHipaa',
        source: ComplianceScreens.baaFeatureGateScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54914',
    }),
    baaFeatureGateLearnHowToSignBaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'baaFeatureGateLearnHowToSignBaa',
        source: ComplianceScreens.baaFeatureGateScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54915',
    }),
    baaSignInCancelAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelBaaSignInAction',
        source: ComplianceScreens.baaSignInFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54899',
    }),
    baaSignInSubmitAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitBaaSignInAction',
        source: ComplianceScreens.baaSignInFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54900',
    }),
    baaSignInLearnHowToSignBaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'baaSignInLearnHowToSignBaa',
        source: ComplianceScreens.baaSignInFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54916',
    }),
    manageProductsViewImplementationGuide: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'manageProductsViewImplementationGuide',
        source: ComplianceScreens.manageProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54918',
    }),
    backToComplianceHomeAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backToComplianceHomeAction',
        source: ComplianceScreens.manageProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54901',
    }),
    tagProductsAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'tagProductsAction',
        source: ComplianceScreens.manageProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54903',
    }),
    untagProductAction: (attributes: EventDataAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'untagProductAction',
        source: ComplianceScreens.manageProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54904',
    }),
    untagProductModalCancelAction: (attributes: EventDataAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'untagCancelAction',
        source: ComplianceScreens.untagProductModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54905',
    }),
    untagProductModalUntagAction: (attributes: EventDataAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitUntagAction',
        source: ComplianceScreens.untagProductModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54906',
    }),
    untagProductModalLearnMoreAboutHipaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'untagProductModalLearnMoreAboutHipaa',
        source: ComplianceScreens.untagProductModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54919',
    }),
    selectProductsLearnMoreAboutTaggingProducts: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'selectProductsLearnMoreAboutTaggingProducts',
        source: ComplianceScreens.selectProductsFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54953',
    }),
    selectProductsBackAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectProductsBackAction',
        source: ComplianceScreens.selectProductsFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54907',
    }),
    selectProductsReviewAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectProductsReviewAction',
        source: ComplianceScreens.selectProductsFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54908',
    }),
    selectProductAction: (attributes: EventDataAttributes): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'selectProductAction',
        source: ComplianceScreens.selectProductsFocusTaskStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54923',
    }),
    reviewAndTagLearnMoreAboutHipaa: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'reviewAndTagLearnMoreAboutHipaa',
        source: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54921',
    }),
    reviewAndTagLearnMoreAboutTaggingProducts: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'reviewAndTagLearnMoreAboutTaggingProducts',
        source: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54922',
    }),
    reviewAndTagBackAction: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reviewAndTagBackAction',
        source: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54909',
    }),
    reviewAndTagProductsAction: (attributes: { taggedProductCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'submitTagProductsAction',
        source: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54902',
    }),
  },

  track: {
    productsTagged: (attributes: { productsList: EventDataAttributes[] }): TrackEvent => ({
      data: {
        action: TrackAction.Added,
        actionSubject: TrackActionSubject.HipaaCompliance,
        actionSubjectId: 'productsTagged',
        source: ComplianceScreens.reviewAndTagFocusTaskStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54874',
    }),
    productUntagged: (attributes: EventDataAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.HipaaCompliance,
        actionSubjectId: 'productUntagged',
        source: ComplianceScreens.untagProductModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54875',
    }),
    baaAgreementSignInRequested: (): TrackEvent => ({
      data: {
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.BaaSignIn,
        actionSubjectId: 'baaAgreementSignInRequested',
        source: ComplianceScreens.baaSignInFocusTaskScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54876',
    }),
  },

  operational: {},
};
