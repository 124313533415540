import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'siteSelectionTable';

type UIEventKeys =
  | 'siteSelectionTableRadioButtonClicked'
  | 'siteSelectionRefreshButtonClicked'
  | 'siteSelectionNextPageButtonClicked'
  | 'siteSelectionPrevPageButtonClicked'
  | 'siteSelectionPageSizeHandlerClicked'
  | 'siteSelectionDropdownFilterClicked'
  | 'siteSelectionResetAllFiltersClicked'
  | 'siteSelectionSearchSubmitted'
  | 'siteSelectionColumnSortClicked';

export const siteSelectionTable = 'siteSelectionTable';
export const filterBar = 'filterBar';

export const siteSelectionTableEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/Legos/pages/3363228869/Analytics+specs+Site+Selection+Table',
  screen: {
    siteSelectionTable: (attributes: { feature: string; orgId: string }): ScreenEvent => ({
      data: {
        name: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61007',
    }),
  },
  track: {},
  operational: {},
  ui: {
    siteSelectionTableRadioButtonClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'siteSelectionTableRadioButton',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61022',
    }),
    siteSelectionRefreshButtonClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteSelectionRefreshButton',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61008',
    }),
    siteSelectionNextPageButtonClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteSelectionNextPageButton',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61020',
    }),
    siteSelectionPrevPageButtonClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteSelectionPrevPageButton',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61021',
    }),
    siteSelectionPageSizeHandlerClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'siteSelectionPageSizeHandler',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61024',
    }),
    siteSelectionDropdownFilterClicked: (attributes: { feature: string; orgId: string; filterName: string; value: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'siteSelectionDropdownFilter',
        source: filterBar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61025',
    }),
    siteSelectionResetAllFiltersClicked: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteSelectionResetAllFilters',
        source: filterBar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61026',
    }),
    siteSelectionSearchSubmitted: (attributes: { feature: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Submitted,
        actionSubject: ActionSubject.TextField,
        actionSubjectId: 'siteSelectionSearch',
        source: filterBar,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61027',
    }),
    siteSelectionColumnSortClicked: (attributes: { feature: string; orgId: string; columnName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteSelectionColumnSort',
        source: siteSelectionTable,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61028',
    }),
  },
};
