import type { AddProductScreen } from './analytics-event-data';
import { productsOverviewScreen } from './analytics-event-data';

import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'addProductSetupScreenEvent';

type UIEventKeys =
  | 'addProductClickEvent'
  | 'linkProductsClickEvent'
  | 'existingSiteClickEvent'
  | 'setupProductClickEvent'
  | 'setupManageUsersClickEvent';

export const addProductScreen: AddProductScreen = 'addProductScreen';
const addProductSetupScreen: AddProductScreen = 'addProductSetupScreen';

export const addProductEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/818724659/Add+Product+front+end+analytics+spec',
  screen: {
    addProductSetupScreenEvent: (attributes: { product: string; cloudId: string }): ScreenEvent => ({
      data: {
        name: addProductSetupScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27989',
    }),
  },
  ui: {
    addProductClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addProduct',
        actionSubject: ActionSubject.Button,
        source: productsOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27990',
    }),
    linkProductsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.MenuItem,
        actionSubjectId: 'linkProducts',
        source: productsOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46210',
    }),
    existingSiteClickEvent: (attributes: { existingSites: number; cloudId: string }, data: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'existingSite',
        actionSubject: ActionSubject.Button,
        source: data.source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27993',
    }),
    setupProductClickEvent: (attributes: { product: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'setupProduct',
        actionSubject: ActionSubject.Button,
        source: addProductSetupScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27998',
    }),
    setupManageUsersClickEvent: (attributes: { product: string; cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'setupManageUsers',
        actionSubject: ActionSubject.Button,
        source: addProductSetupScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27999',
    }),
  },
  track: {},
  operational: {},
};
