// Note: Dangerously set the style of the document body
// It's not recommended to use these functions unless very needed

export const dangerouslyHideApplicationUI = () => {
  document.body.style.visibility = 'hidden';
  document.body.style.pointerEvents = 'none';
};

export const dangerouslyShowApplicationUI = () => {
  document.body.style.visibility = '';
  document.body.style.pointerEvents = 'auto';
};
