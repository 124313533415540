import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'userApiTokensScreenEvent';
type UIEventKeys =
  | 'confirmRevokeApiTokensButtonEvent'
  | 'cancelRevokeApiTokensButtonEvent'
  | 'applyTokenSearchFiltersButtonEvent'
  | 'resetTokenSearchFiltersButtonEvent'
  | 'moreActionsButtonEvent'
  | 'exportApiTokensListButtonEvent';

type TrackEventKeys =
  | 'revokeApiTokensTrackEvent'
  | 'searchApiTokensTrackEvent'
  | 'exportApiTokensSuccessTrackEvent'
  | 'exportApiTokensFailureTrackEvent';

export enum ApiTokensScreens {
  userApiTokensScreen = 'userApiTokensScreen',
}

export const apiTokenEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/2882123396/ATC+Milestone+1.3+Analytics',
  screen: {
    userApiTokensScreenEvent: (): ScreenEvent => ({
      data: {
        name: ApiTokensScreens.userApiTokensScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57384',
    }),
  },
  ui: {
    confirmRevokeApiTokensButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRevokeApiTokens',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57385',
    }),
    cancelRevokeApiTokensButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRevokeApiTokens',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57386',
    }),
    applyTokenSearchFiltersButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'applyTokenSearchFilters',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57387',
    }),
    resetTokenSearchFiltersButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resetTokenSearchFilters',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57388',
    }),
    moreActionsButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'moreActions',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57390',
    }),
    exportApiTokensListButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportApiTokensList',
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57391',
    }),
  },
  track: {
    revokeApiTokensTrackEvent: (attributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.UserApiTokens,
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57392',
    }),
    searchApiTokensTrackEvent: (attributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Searched,
        actionSubject: TrackActionSubject.UserApiTokens,
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57393',
    }),
    exportApiTokensSuccessTrackEvent: (attributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Exported,
        actionSubject: TrackActionSubject.UserApiTokens,
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57394',
    }),
    exportApiTokensFailureTrackEvent: (attributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Exported,
        actionSubject: TrackActionSubject.UserApiTokens,
        source: ApiTokensScreens.userApiTokensScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57395',
    }),
  },
  operational: {},
};
