import * as React from 'react';
import Loadable from 'react-loadable';

import type { AnalyticsClientProps } from '@adminhub/analytics';
import { OperationalAction, OperationalSubject, withAnalyticsClient } from '@adminhub/analytics';

import { FullPageLoading, PageLoading } from './page-loading';

const peformanceMark = '🚛🤚 pick loader';

export function PickLoader<T extends object, U extends React.ComponentType>(
  importer: () => Promise<T>,
  pick: (x: T) => U,
  options: Partial<Loadable.CommonOptions> = {},
): U & LoadableExport.LoadableComponent {
  let firstInvocationTime = 0;
  let firstRenderTime = 0;

  const LoadableComponent = withAnalyticsClient(
    Loadable({
      loader: importer,
      loading: PageLoading,
      ...options,
      render(lib, { analyticsClient, ...props }: AnalyticsClientProps) {
        const Component: React.ComponentType = pick(lib);

        if (!firstRenderTime) {
          firstRenderTime = Date.now();
          const duration = firstRenderTime - firstInvocationTime;

          // eslint-disable-next-line no-console
          console.timeEnd(peformanceMark);
          // send analytics event
          analyticsClient.sendOperationalEvent({
            data: {
              action: OperationalAction.Triggered,
              actionSubject: OperationalSubject.PickLoader,
              actionSubjectId: 'pick-loader',
              source: 'loader',
              attributes: {
                duration,
              },
            },
          });
        }

        return <Component {...(props as any)} />;
      },
    }),
  ) as any;

  const loader = ((props: any) => {
    if (!firstInvocationTime) {
      firstInvocationTime = Date.now();
      // eslint-disable-next-line no-console
      console.time(peformanceMark);
    }

    return <LoadableComponent {...props} />;
  }) as any;
  loader.preload = importer;

  return loader;
}

export function PickPageLoader<T extends object, U extends React.ComponentType>(importer: () => Promise<T>, pick: (x: T) => U) {
  return PickLoader(importer, pick, {
    loading: FullPageLoading,
  });
}
