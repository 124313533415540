import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'brieBackupDashboardScreen'
  | 'brieRestoreDashboardScreen'
  | 'brieDownloadsDashboardScreen'
  | 'brieExportsDownloadsDashboardScreen'
  | 'brieManageStorageDashboardScreen'
  | 'brieBackupDetailsScreen'
  | 'brieRunBackupModal'
  | 'brieBackupDrawerOnboardingScreen'
  | 'briePolicyDrawerOnboardingScreen'
  | 'briePolicyDrawerDataPreferenceScreen'
  | 'brieBackupDrawerSelectProductScreen'
  | 'brieBackupDrawerReviewScreen'
  | 'brieRestoreDrawerOnboardingScreen'
  | 'brieRestoreDrawerSelectProductScreen'
  | 'brieRestoreDrawerSelectBackupScreen'
  | 'brieRestoreDrawerSelectDestinationScreen'
  | 'brieRestoreDrawerReviewScreen'
  | 'brieBackupPolicyDashboardEmptyScreen'
  | 'brieCommonLandingModal'
  | 'brieLandingDashboard'
  | 'brieValidationRenderer'
  | 'brieValidationCheckModal'
  | 'briePolicySettingsScreen'
  | 'brieBackupsByPolicyScreen'
  | 'brieExportDashboardScreen'
  | 'brieManageStorageScreen'
  | 'brieRestoreDrawerBackupViewScreen'
  | 'brieRestoreDrawerValidationEngineScreen'
  | 'brieConnectStorageScreen'
  | 'briePostOperationReportScreen';

type UiKeys =
  | 'feedbackButton'
  | 'feedbackLink'
  | 'focussedTaskBackButton'
  | 'focussedTaskNextButton'
  | 'addExternalStorageButton'
  | 'externalStorageInstructionLink'
  | 'generateJsonButton'
  | 'createStorageSuccess'
  | 'connectStorageButton'
  | 'cancelConnectStorageButton'
  | 'createStorageFailed'
  | 'createBackupPolicyButton'
  | 'externalStorageRadioButton'
  | 'downloadBackupLocallyRadioButton'
  | 'learnMoreRestoreLink'
  | 'selectBackupProductLearnMore'
  | 'includeAttachmentsCheckbox'
  | 'includePersonalSpacesCheckbox'
  | 'runBackupButton'
  | 'editPolicyButton'
  | 'activatePolicyButton'
  | 'navTabChange'
  | 'confirmBackupModalButton'
  | 'backToBackupDashboardButton'
  | 'confirmExportButton'
  | 'downloadBackupButton'
  | 'confirmPrepareDownloadButton'
  | 'brieRestoreButton'
  | 'storageLocationCopy'
  | 'restoreWarningModalConfirmButton'
  | 'unableRestoreButton'
  | 'unableDownloadButton'
  | 'exportToStorageButton'
  | 'confirmModalButton'
  | 'createBackupLearnMoreLink'
  | 'proceedToRestore'
  | 'cancelProceedToRestore'
  | 'uploadQuit'
  | 'cancelUploadQuit'
  | 'brieWelcomeModalButton'
  | 'brieWelcomeTextLearnMore'
  | 'briePostopDownloadButton'
  | 'brieSiteSelectionDropdownFilter'
  | 'brieSiteSelectionRowRadioButton'
  | 'brieBackupSelectRowRadioButton'
  | 'brieRestoreExcludeUsersGroups'
  | 'brieValidationAddConsent'
  | 'brieValidationRemoveConsent'
  | 'brieValidationCloseModal'
  | 'brieValidationOpenModal'
  | 'brieValidationDownloadCsvButton'
  | 'brieErrorFlag'
  | 'backupPoliciesRefreshButton'
  | 'viewPolicyBackupsButton';

export enum BrieScreens {
  brieBackupDashboardScreen = 'brieBackupDashboardScreen',
  brieRestoreDashboardScreen = 'brieRestoreDashboardScreen',
  brieDownloadsDashboardScreen = 'brieDownloadsDashboardScreen',
  brieExportsDownloadsDashboardScreen = 'brieExportsDownloadsDashboardScreen',
  brieManageStorageDashboardScreen = 'brieManageStorageDashboardScreen',
  brieBackupDetailsScreen = 'brieBackupDetailsScreen',
  brieRunBackupModal = 'brieRunBackupModal',
  brieBackupDrawerOnboardingScreen = 'brieBackupDrawerOnboardingScreen',
  briePolicyDrawerOnboardingScreen = 'briePolicyDrawerOnboardingScreen',
  briePolicyDrawerDataPreferenceScreen = 'briePolicyDrawerDataPreferenceScreen',
  brieBackupDrawerSelectProductScreen = 'brieBackupDrawerSelectProductScreen',
  brieBackupDrawerReviewScreen = 'brieBackupDrawerReviewScreen',
  brieRestoreDrawerOnboardingScreen = 'brieRestoreDrawerOnboardingScreen',
  brieRestoreDrawerSelectProductScreen = 'brieRestoreDrawerSelectProductScreen',
  brieRestoreDrawerSelectBackupScreen = 'brieRestoreDrawerSelectBackupScreen',
  brieRestoreDrawerSelectDestinationScreen = 'brieRestoreDrawerSelectDestinationScreen',
  brieRestoreDrawerReviewScreen = 'brieRestoreDrawerReviewScreen',
  brieBackupPolicyDashboardEmptyScreen = 'brieBackupPolicyDashboardEmptyScreen',
  brieAddExternalStorageScreen = 'brieAddExternalStorageScreen',
  brieBackupPolicyDashboardScreen = 'brieBackupPolicyDashboardScreen',
  brieBackupPolicySelectStorageTypeScreen = 'brieBackupPolicySelectStorageTypeScreen',
  brieBackupPolicyInstructionsScreen = 'brieBackupPolicyInstructionsScreen',
  brieBackupPolicySelectProductScreen = 'brieBackupPolicySelectProductScreen',
  brieBackupPolicySelectPreferenceScreen = 'brieBackupPolicySelectPreferenceScreen',
  brieBackupPolicySelectStorageScreen = 'brieBackupPolicySelectStorageScreen',
  brieBackupPolicyReviewScreen = 'brieBackupPolicyReviewScreen',
  brieBackupPolicyCreationFlow = 'brieBackupPolicyCreationFlow',
  briePolicySettingsScreen = 'briePolicySettingsScreen',
  brieBackupDetailsSidepanelScreen = 'brieBackupDetailsSidepanelScreen',
  brieBackupDownloadModal = 'brieBackupDownloadModal',
  brieConfirmExportModal = 'brieConfirmExportModal',
  brieCommonLandingModal = 'brieCommonLandingModal',
  brieLandingDashboard = 'brieLandingDashboard',
  brieValidationRenderer = 'brieValidationRenderer',
  brieValidationCheckModal = 'brieValidationCheckModal',
  brieBackupsByPolicyScreen = 'brieBackupsByPolicyScreen',
  brieExportDashboardScreen = 'brieExportDashboardScreen',
  brieManageStorageScreen = 'brieManageStorageScreen',
  brieRestoreDrawerBackupViewScreen = 'brieRestoreDrawerBackupViewScreen',
  brieRestoreDrawerValidationEngineScreen = 'brieRestoreDrawerValidationEngineScreen',
  brieConnectStorageScreen = 'brieConnectStorageScreen',
  briePostOperationReportScreen = 'briePostOperationReportScreen',
}

export const brieEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/3160450379/BRIE+Analytics+Spec',
  screen: {
    brieConnectStorageScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.brieConnectStorageScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73304',
    }),
    briePostOperationReportScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.briePostOperationReportScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73306',
    }),
    brieRestoreDrawerValidationEngineScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerValidationEngineScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73238',
    }),
    brieRestoreDrawerBackupViewScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerBackupViewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73237',
    }),
    brieManageStorageScreen: (attributes: { hasRecords: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieManageStorageScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73236',
    }),
    brieExportDashboardScreen: (attributes: { hasRecords: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieExportDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73234',
    }),
    brieBackupsByPolicyScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupsByPolicyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73205',
    }),
    briePolicySettingsScreen: (): ScreenEvent => ({
      data: {
        name: BrieScreens.briePolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73204',
    }),
    brieCommonLandingModal: (): ScreenEvent => ({
      data: {
        name: BrieScreens.brieCommonLandingModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69902',
    }),
    brieBackupDashboardScreen: (attributes: { hasBackups: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58342',
    }),
    brieRestoreDashboardScreen: (attributes: { hasRestores: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58343',
    }),
    brieDownloadsDashboardScreen: (attributes: { hasDownloads: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieDownloadsDashboardScreen,
        attributes,
      },
      // TODO - change this
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58343',
    }),
    brieExportsDownloadsDashboardScreen: (attributes: { hasRecords: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieExportsDownloadsDashboardScreen,
        attributes,
      },
      // TODO - change this
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58343',
    }),
    brieManageStorageDashboardScreen: (attributes: { hasStorages: boolean }): ScreenEvent => ({
      data: {
        name: BrieScreens.brieManageStorageDashboardScreen,
        attributes,
      },
      // TODO - change this
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58343',
    }),
    brieBackupDetailsScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58894',
    }),
    brieRunBackupModal: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRunBackupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58895',
    }),
    brieBackupDrawerOnboardingScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupDrawerOnboardingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58896',
    }),
    briePolicyDrawerOnboardingScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.briePolicyDrawerOnboardingScreen,
        attributes,
      },
      // TODO: brie - change this
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58896',
    }),
    brieBackupDrawerSelectProductScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupDrawerSelectProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58897',
    }),
    briePolicyDrawerDataPreferenceScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.briePolicyDrawerDataPreferenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73194',
    }),
    brieBackupDrawerReviewScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupDrawerReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58898',
    }),
    brieRestoreDrawerOnboardingScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerOnboardingScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58899',
    }),
    brieRestoreDrawerSelectProductScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerSelectProductScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58900',
    }),
    brieRestoreDrawerSelectBackupScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerSelectBackupScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58901',
    }),
    brieRestoreDrawerSelectDestinationScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerSelectDestinationScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58902',
    }),
    brieRestoreDrawerReviewScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieRestoreDrawerReviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58903',
    }),
    brieBackupPolicyDashboardEmptyScreen: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieBackupPolicyDashboardEmptyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69433',
    }),
    brieLandingDashboard: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieLandingDashboard,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69909',
    }),
    brieValidationRenderer: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73287',
    }),
    brieValidationCheckModal: (attributes): ScreenEvent => ({
      data: {
        name: BrieScreens.brieValidationCheckModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73292',
    }),
  },

  ui: {
    focussedTaskBackButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'focussedTaskCancelButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69454',
    }),
    focussedTaskNextButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'focussedTaskNextButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69455',
    }),
    feedbackButton: ({ source, attributes }: { source: BrieScreens; attributes: { feedback: boolean } }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedbackButton',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58340',
    }),
    feedbackLink: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'feedbackLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58341',
    }),
    addExternalStorageButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addExternalStorageButton',
        source: BrieScreens.brieBackupPolicyDashboardEmptyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69434',
    }),
    externalStorageInstructionLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'externalStorageInstructionLink',
        source: BrieScreens.brieAddExternalStorageScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69436',
    }),
    generateJsonButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'generateJsonButton',
        source: BrieScreens.brieAddExternalStorageScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69437',
    }),
    createStorageSuccess: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Modal,
        actionSubjectId: 'createStorageSuccess',
        source: BrieScreens.brieAddExternalStorageScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69438',
    }),
    connectStorageButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectStorageButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69439',
    }),
    cancelConnectStorageButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelConnectStorageButton',
        source: BrieScreens.brieAddExternalStorageScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69440',
    }),
    createStorageFailed: (attributes: { errorMessage: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Modal,
        actionSubjectId: 'createStorageFailed',
        source: BrieScreens.brieAddExternalStorageScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69441',
    }),
    createBackupPolicyButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createBackupPolicyButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69450',
    }),
    backupPoliciesRefreshButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backupPoliciesRefreshButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76665',
    }),
    externalStorageRadioButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'externalStorageRadioButton',
        source: BrieScreens.brieBackupPolicySelectStorageTypeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69452',
    }),
    downloadBackupLocallyRadioButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'downloadBackupLocallyRadioButton',
        source: BrieScreens.brieBackupPolicySelectStorageTypeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69453',
    }),
    learnMoreRestoreLink: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreRestoreLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69460',
    }),
    selectBackupProductLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'selectBackupProductLearnMore',
        source: BrieScreens.brieBackupPolicySelectProductScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69461',
    }),
    createBackupLearnMoreLink: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'createBackupLearnMoreLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69889',
    }),
    includeAttachmentsCheckbox: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'includeAttachmentsCheckbox',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69462',
    }),
    includePersonalSpacesCheckbox: (): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'includePersonalSpacesCheckbox',
        source: BrieScreens.brieBackupPolicySelectPreferenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69463',
    }),
    brieRestoreExcludeUsersGroups: (): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'brieRestoreExcludeUsersGroups',
        source: BrieScreens.brieRestoreDrawerBackupViewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73285',
    }),
    runBackupButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'runBackupButton',
        source: BrieScreens.briePolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69521',
    }),
    editPolicyButton: ({ source }: { source?: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editPolicyButton',
        source: source ?? BrieScreens.briePolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69522',
    }),
    activatePolicyButton: ({ source }: { source?: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activatePolicyButton',
        source: source ?? BrieScreens.briePolicySettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76872',
    }),
    viewPolicyBackupsButton: ({ source }: { source?: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewPolicyBackupsButton',
        source: source ?? BrieScreens.brieBackupDashboardScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77005',
    }),
    navTabChange: ({ source, attributes }: { source: BrieScreens; attributes: { title: string } }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'navTabChange',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73203',
    }),
    confirmBackupModalButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmBackupModalButton',
        source: BrieScreens.brieRunBackupModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69530',
    }),
    backToBackupDashboardButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backToBackupDashboardButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69531',
    }),
    confirmExportButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmExportButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69533',
    }),
    downloadBackupButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadBackupButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69534',
    }),
    confirmPrepareDownloadButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmPrepareDownloadButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69535',
    }),
    brieRestoreButton: ({ source, attributes }: { source: BrieScreens; attributes: { backupStatus: string | undefined } }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieRestoreButton',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69536',
    }),
    storageLocationCopy: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'storageLocationCopy',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69539',
    }),
    restoreWarningModalConfirmButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'restoreWarningModalConfirmButton',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69541',
    }),
    unableRestoreButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'unableRestoreButton',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69542',
    }),
    unableDownloadButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'unableDownloadButton',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69543',
    }),
    exportToStorageButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportToStorageButton',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69543',
    }),
    confirmModalButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmModalButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69548',
    }),
    proceedToRestore: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'proceedToRestore',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69890',
    }),
    cancelProceedToRestore: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelProceedToRestore',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69891',
    }),
    uploadQuit: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'uploadQuit',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69897',
    }),
    cancelUploadQuit: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelUploadQuit',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69896',
    }),
    brieWelcomeModalButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieWelcomeModalButton',
        source: BrieScreens.brieCommonLandingModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69903',
    }),
    brieWelcomeTextLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'brieWelcomeTextLearnMore',
        source: BrieScreens.brieCommonLandingModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69908',
    }),
    briePostopDownloadButton: ({ attributes }: { attributes: { operationType: string } }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'briePostOpReportButton',
        source: BrieScreens.brieBackupDetailsSidepanelScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72745',
    }),
    brieSiteSelectionDropdownFilter: ({
      source,
      attributes,
    }: {
      source: BrieScreens;
      attributes: { filterName: string; value: string };
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'brieSiteSelectionDropdownFilterClicked',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73247',
    }),
    brieSiteSelectionRowRadioButton: ({ source }: { source: BrieScreens }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'brieSiteSelectionRowRadioButton',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73249',
    }),
    brieBackupSelectRowRadioButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.RadioButton,
        actionSubjectId: 'brieBackupSelectRowRadioButton',
        source: BrieScreens.brieRestoreDrawerSelectBackupScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73251',
    }),
    brieValidationAddConsent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieValidationAddConsentButton',
        source: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73284',
    }),
    brieValidationRemoveConsent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieValidationRemoveConsentButton',
        source: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73282',
    }),
    brieValidationCloseModal: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieValidationCloseModalButton',
        source: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73281',
    }),
    brieValidationOpenModal: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieValidationOpenModalButton',
        source: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73280',
    }),
    brieValidationDownloadCsvButton: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'brieValidationDownloadCsvButton',
        source: BrieScreens.brieValidationRenderer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73291',
    }),
    brieErrorFlag: ({ source, attributes }: { source: BrieScreens; attributes: { errorCode?: string; errorType: string } }): UIEvent => ({
      data: {
        action: Action.Viewed,
        actionSubject: ActionSubject.Flag,
        actionSubjectId: 'brieErrorFlag',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73303',
    }),
  },

  track: {},

  operational: {},
};
