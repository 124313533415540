import type { ProductName } from '@adminhub/icons';
import { fromProductConfig } from '@adminhub/product-config';

import type { AddProductSitelessScreen } from './analytics-event-data';
import { productsOverviewScreen } from './analytics-event-data';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'addProductSitelessSelectScreenEvent'
  | 'addProductSitelessAddDetailsScreenEvent'
  | 'addProductSitelessProvisioningScreenEvent'
  | 'addProductSitelessSetupScreenEvent'
  | 'selectSiteModalScreenEvent';

type UIEventKeys =
  | 'addProductClickEvent'
  | 'discoverMoreClickEvent'
  | 'drawerCloseClickEvent'
  | 'learnMoreClickEvent'
  | 'backClickEvent'
  | 'createNewSiteInfoHoverEvent'
  | 'createNewSiteLearnMoreClickEvent'
  | 'selectSiteInfoHoverEvent'
  | 'agreeAndStartNowClickEvent'
  | 'selectClickEvent'
  | 'selectStandardClickEvent'
  | 'selectEnterpriseClickEvent'
  | 'addProductLearnMoreClickEvent'
  | 'termsOfServiceClickEvent'
  | 'privacyPolicyClickEvent'
  | 'manageUsersClickEvent'
  | 'goToProductClickEvent'
  | 'discoverNewProductsClickEvent'
  | 'enterpriseQuotingBannerClickEvent'
  | 'learnMoreAboutRovoClickEvent'
  | 'exploreRovoFeaturesClickEvent'
  | 'learnMoreAboutAdministeringRovoClickEvent'
  | 'learnMoreAtlassianCustomerAgreementClickEvent'
  | 'learnMoreAtlassianIntelligenceRovoTermsClickEvent'
  | 'learnMoreRovoBillingClickEvent';

type TrackEventKeys = 'createdInstance' | 'productActivationCompleted' | 'rovoGAStarted' | 'rovoSiteStarted';

export const addProductSitelessSelectScreen: AddProductSitelessScreen = 'addProductSitelessSelectScreen';
export const addProductSitelessAddDetailsScreen: AddProductSitelessScreen = 'addProductSitelessAddDetailsScreen';
export const addProductSitelessProvisioningScreen: AddProductSitelessScreen = 'addProductSitelessProvisioningScreen';
export const addProductSitelessSetupScreen: AddProductSitelessScreen = 'addProductSitelessSetupScreen';
export const addProductSitelessSuccessScreen: AddProductSitelessScreen = 'addProductSitelessSuccessScreen';
export const selectSiteModalScreen: AddProductSitelessScreen = 'selectSiteModalScreen';

export const sitelessAddProductEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2288539476/Analytics+Spec+M2+Add+product+flow',
  screen: {
    addProductSitelessSelectScreenEvent: (attributes: { emauProducts: string[] }): ScreenEvent => ({
      data: {
        name: addProductSitelessSelectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49818',
    }),
    addProductSitelessAddDetailsScreenEvent: (attributes: {
      availableSites: number;
      isEnterpriseEnabled: boolean;
      product: ProductName;
    }): ScreenEvent => ({
      data: {
        name: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49819',
    }),

    addProductSitelessProvisioningScreenEvent: (attributes: { product: ProductName }): ScreenEvent => ({
      data: {
        name: addProductSitelessProvisioningScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49820',
    }),
    addProductSitelessSetupScreenEvent: (attributes: { cloudId: string; product: ProductName; isEnterprise: boolean }): ScreenEvent => ({
      data: {
        name: addProductSitelessSetupScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51707',
    }),
    selectSiteModalScreenEvent: (attributes: { existingSites: number }): ScreenEvent => ({
      data: {
        name: selectSiteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52876',
    }),
  },
  ui: {
    addProductClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addProduct',
        actionSubject: ActionSubject.Button,
        source: productsOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27990',
    }),
    discoverMoreClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'discoverMore',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49817',
    }),
    drawerCloseClickEvent: (
      attributes: { product?: ProductName; isEnterprise?: boolean },
      data: { source: AddProductSitelessScreen },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'drawerClose',
        source: data.source,
        attributes: {
          ...attributes,
          ...(attributes.product ? { emauProduct: fromProductConfig({ name: attributes.product }).emauKey } : {}),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47441',
    }),
    learnMoreClickEvent: (attributes: { product: ProductName }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMore',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessSelectScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
    selectClickEvent: (attributes: { isEnterprise: boolean; product: ProductName }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'select',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessSelectScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27994',
    }),
    backClickEvent: (
      attributes: { product: ProductName | null; isEnterprise: boolean },
      data: { source: AddProductSitelessScreen },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'back',
        actionSubject: ActionSubject.Button,
        source: data.source,
        attributes: {
          ...attributes,
          ...(attributes.product ? { emauProduct: fromProductConfig({ name: attributes.product }).emauKey } : {}),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),
    createNewSiteInfoHoverEvent: (attributes: { product: ProductName; isEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubjectId: 'createNewSiteInfo',
        actionSubject: ActionSubject.Icon,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50282',
    }),
    createNewSiteLearnMoreClickEvent: (attributes: { product: ProductName; isEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createSiteLearnMore',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50283',
    }),
    selectSiteInfoHoverEvent: (attributes: { product: ProductName; isEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubjectId: 'selectSiteInfo',
        actionSubject: ActionSubject.Icon,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54304',
    }),
    agreeAndStartNowClickEvent: (
      attributes: { product: ProductName; isEnterprise: boolean },
      data: { source: AddProductSitelessScreen },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'agreeAndStartNow',
        actionSubject: ActionSubject.Button,
        source: data.source,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50213',
    }),
    selectEnterpriseClickEvent: (attributes: { product: ProductName }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectEnterprise',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38768',
    }),
    selectStandardClickEvent: (attributes: { product: ProductName }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectStandard',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38767',
    }),
    addProductLearnMoreClickEvent: (attributes: { product: ProductName; isEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addProductLearnMore',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50018',
    }),
    termsOfServiceClickEvent: (
      attributes: { product: ProductName; isEnterprise: boolean },
      data: { source: AddProductSitelessScreen },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'termsOfService',
        actionSubject: ActionSubject.Link,
        source: data.source,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60241',
    }),
    privacyPolicyClickEvent: (
      attributes: { product: ProductName; isEnterprise: boolean },
      data: { source: AddProductSitelessScreen },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'privacyPolicy',
        actionSubject: ActionSubject.Link,
        source: data.source,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50024',
    }),
    manageUsersClickEvent: (attributes: { cloudId: string; product: ProductName; isEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'manageUsers',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessSetupScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20211',
    }),
    goToProductClickEvent: (attributes: {
      cloudId: string;
      product: ProductName;
      isEnterprise: boolean;
      workspaceAri: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'goToProduct',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessSetupScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51708',
    }),
    discoverNewProductsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'discoverNewProduct',
        actionSubject: ActionSubject.Button,
        source: addProductSitelessSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52877',
    }),
    enterpriseQuotingBannerClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'enterpriseQuotingBannerCard',
        actionSubject: ActionSubject.Card,
        source: addProductSitelessSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59169',
    }),
    learnMoreAboutRovoClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutRovo',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71995',
    }),
    exploreRovoFeaturesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'exploreRovoFeatures',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71980',
    }),
    learnMoreAboutAdministeringRovoClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutAdministeringRovo',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71981',
    }),
    learnMoreAtlassianCustomerAgreementClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAtlassianCustomerAgreement',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71982',
    }),
    learnMoreAtlassianIntelligenceRovoTermsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAtlassianIntelligenceRovoTerms',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71983',
    }),
    learnMoreRovoBillingClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreRovoBilling',
        actionSubject: ActionSubject.Link,
        source: addProductSitelessAddDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71984',
    }),
  },
  track: {
    createdInstance: (attributes: { isEnterprise: boolean; product: ProductName; cloudId?: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.Instance,
        source: addProductSitelessAddDetailsScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34968',
    }),
    productActivationCompleted: (attributes: { isEnterprise: boolean; product: ProductName; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Completed,
        actionSubject: TrackActionSubject.ProductActivation,
        source: addProductSitelessSetupScreen,
        attributes: {
          ...attributes,
          emauProduct: fromProductConfig({ name: attributes.product }).emauKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52439',
    }),
    rovoGAStarted: (attributes: { isSuccess: boolean; timestamp: number; source: string }): TrackEvent => ({
      data: {
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.RovoGA,
        actionSubjectId: 'rovoGAStarted',
        source: attributes.source,
        attributes: {
          isSuccess: attributes.isSuccess,
          timestamp: attributes.timestamp,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71985',
    }),
    rovoSiteStarted: (attributes: { isSuccess: boolean; timestamp: number; source: string }): TrackEvent => ({
      data: {
        action: TrackAction.Started,
        actionSubject: TrackActionSubject.RovoSite,
        actionSubjectId: 'rovoSite',
        source: attributes.source,
        attributes: {
          isSuccess: attributes.isSuccess,
          timestamp: attributes.timestamp,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71986',
    }),
  },
  operational: {},
};
