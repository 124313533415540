import * as React from 'react';

import type { AnalyticsClient } from './analytics-client';

const noop = () => undefined;
export const NewAnalyticsContext = React.createContext<AnalyticsClient>({
  init: noop,
  initOriginTracingHandlers: noop,
  setOrgId: noop,
  sendScreenEvent: noop,
  sendTrackEvent: noop,
  sendUIEvent: noop,
  sendOperationalEvent: noop,
  getAnalyticsWebClient: noop,
});
