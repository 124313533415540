import type { UiProductType } from '@adminhub/graphql-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'discoveredProductsScreenEvent' | 'productsScreen' | 'joinAsAdminModalScreen';

type UIEventKeys =
  | 'learnMoreAboutDiscoveredProductsClickEvent'
  | 'emailNotificationToggleEvent'
  | 'csvExportClickEvent'
  | 'actionColumnClickEvent'
  | 'contactAdminsClickEvent'
  | 'copyRowToClipboardClickEvent'
  | 'filteredTableEvent'
  | 'sortedTableEvent'
  | 'feedbackClickEvent'
  | 'openFeedbackDialogClickEvent'
  | 'reviewProductsClickEvent'
  | 'dismissedOnboardingClickEvent'
  | 'dwellOnPageEvent'
  | 'joinAsAdminClickEvent'
  | 'confirmJoinAsAdminClickEvent'
  | 'cancelJoinAsAdminClickEvent'
  | 'learnMoreAboutAdminRolesClickEvent'
  | 'openOrganizationOnSuccessFlagClickEvent'
  | 'openOrganizationDropdownMenuClickEvent'
  | 'openProductDropdownMenuClickEvent';

type TrackEventKeys = 'joinedAsAdminTrackEvent';

export const discoveredProductsScreen = 'discoveredProductsScreen';
export const productsScreen = 'productsScreen';
export const joinAsAdminModalScreen = 'discoveredProductsJoinAsAdminModal';

export interface TableRowProps {
  product: string;
  cloudid: string;
  userCount: number;
}

export const discoveredProductsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/943741610/Frontend+Analytics+Spec+Shadow+IT+-+Discoverability',
  screen: {
    discoveredProductsScreenEvent: (attributes: {
      hasDomainsVerified: boolean;
      numberOfDiscoveredProducts: number;
      hasSearchQuery: boolean;
      filteredProducts: string[];
      hasAccessSubscription: boolean;
    }): ScreenEvent => ({
      data: {
        name: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32387',
    }),
    productsScreen: (attributes: { reviewProducts: boolean; numberOfDiscoveredProducts: number }): ScreenEvent => ({
      data: {
        name: productsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32388',
    }),
    joinAsAdminModalScreen: (): ScreenEvent => ({
      data: {
        name: joinAsAdminModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58002',
    }),
  },
  ui: {
    learnMoreAboutDiscoveredProductsClickEvent: (attributes: {
      hasDomainsVerified: boolean;
      numberOfDiscoveredProducts: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutDiscoveredProducts',
        actionSubject: ActionSubject.Link,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32389',
    }),
    emailNotificationToggleEvent: (attributes: { statusChanged: 'enabled' | 'disabled' }): UIEvent => ({
      data: {
        action: Action.Switched,
        actionSubjectId: 'emailNotificationPreference',
        actionSubject: ActionSubject.Toggle,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32390',
    }),
    csvExportClickEvent: (attributes: { hasSearchQuery: boolean; filteredProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'downloadDiscoveredProductCsv',
        actionSubject: ActionSubject.Button,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32391',
    }),
    actionColumnClickEvent: (attributes: TableRowProps): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'discoveredProductTableActionColumn',
        actionSubject: ActionSubject.Button,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32392',
    }),
    contactAdminsClickEvent: (attributes: TableRowProps): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'contactAdmins',
        actionSubject: ActionSubject.Button,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32396',
    }),
    copyRowToClipboardClickEvent: (attributes: TableRowProps): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'copyRowToClipboard',
        actionSubject: ActionSubject.Button,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32397',
    }),
    filteredTableEvent: (attributes: { hasSearchQuery: boolean; filteredProducts: UiProductType[] }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubjectId: 'filteredDiscoveredProducts',
        actionSubject: ActionSubject.Table,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32398',
    }),
    sortedTableEvent: (attributes: { column: string; sortDirection: 'asc' | 'desc' }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'sortedTable',
        actionSubject: ActionSubject.Table,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32399',
    }),
    feedbackClickEvent: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'feedback',
        actionSubject: ActionSubject.Button,
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    openFeedbackDialogClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'leaveFeedbackDialog',
        actionSubject: ActionSubject.Link,
        source: discoveredProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    reviewProductsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'reviewProducts',
        actionSubject: ActionSubject.Link,
        source: productsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32400',
    }),
    dismissedOnboardingClickEvent: (attributes: { numberOfDiscoveredProducts: number }): UIEvent => ({
      data: {
        action: Action.Closed,
        actionSubjectId: 'dismissedOnboarding',
        actionSubject: ActionSubject.Notification,
        source: productsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32401',
    }),
    dwellOnPageEvent: (): UIEvent => ({
      data: {
        action: Action.Dwelled,
        actionSubjectId: discoveredProductsScreen,
        actionSubject: ActionSubject.Page,
        source: discoveredProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34637',
    }),
    joinAsAdminClickEvent: (attributes: { product: string; userCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'joinAsAdmin',
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58003',
    }),
    confirmJoinAsAdminClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmJoinAsAdmin',
        source: joinAsAdminModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58004',
    }),
    cancelJoinAsAdminClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelJoinAsAdmin',
        source: joinAsAdminModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58005',
    }),
    learnMoreAboutAdminRolesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutAdminRoles',
        source: joinAsAdminModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58006',
    }),
    openOrganizationOnSuccessFlagClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'openOrganizationSuccessFlag',
        source: discoveredProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58007',
    }),
    openOrganizationDropdownMenuClickEvent: (attributes: { product: string; userCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openOrganizationDropdownMenu',
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58008',
    }),
    openProductDropdownMenuClickEvent: (attributes: { product: string; userCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openProductDropdownMenu',
        source: discoveredProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58009',
    }),
  },
  track: {
    joinedAsAdminTrackEvent: (attributes: {
      success: boolean;
      currentOrgId: string;
      unmanagedOrgId: string;
      productId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Granted,
        actionSubject: TrackActionSubject.DiscoveredProducts,
        source: joinAsAdminModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58012',
    }),
  },
  operational: {},
};
