import { Navigation } from '@adminhub/reactive-vars';

export const useMainLayoutRightSidebar = () =>
  ({
    showSidebar() {
      Navigation.reactiveVariables.showRightSidebarContainer(true);
    },

    hideSidebar() {
      Navigation.reactiveVariables.showRightSidebarContainer(false);
    },
  }) as const;
