/** @jsx jsx */

import { css, jsx } from '@emotion/react';
var spinnerContainerStyles = css({
  width: "var(--ds-space-300, 24px)",
  marginLeft: "var(--ds-space-200, 16px)"
});

/**
 * __Spinner container__
 *
 * A spinner container for loading state of Empty State.
 *
 * @internal
 */
var SpinnerContainer = function SpinnerContainer(_ref) {
  var children = _ref.children;
  return jsx("div", {
    css: spinnerContainerStyles
  }, children);
};
export default SpinnerContainer;