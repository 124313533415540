import { Action, ActionSubject } from '../new-analytics-types';

import type { FeatureEvents, FeatureEventUi, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { mapEventsWithMigrationAttribute } from './migration-shared-experiences-event-data';

type ScreenKeys = 'migrationControlCenterDashboardScreenEvent' | 'migrationControlCenterDetailsScreenEvent';

type UIKeys =
  | 'learnMoreLinkClickEvent'
  | 'viewDetailsClickEvent'
  | 'goToTargetSiteClickEvent'
  | 'goToSourceSiteClickEvent'
  | 'downloadReportsClickEvent'
  | 'runMigrationClickEvent'
  | 'editPlanClickEvent'
  | 'copyMigrationClickEvent'
  | 'editPlanFromDetailsPageClickEvent'
  | 'downloadLogsClickEvent'
  | 'sxpFeedbackLinkClickEvent'
  | 'sxpFeedbackButtonClickEvent';

// Screens
export const migrationControlCenterDashboardScreen = 'migrationControlCenterDashboardScreen';
export const migrationControlCenterDetailsScreen = 'migrationControlCenterDetailsScreen';

// UI ActionSubjectIds
const enum ActionSubjectId {
  MccViewDetailsDropdownItem = 'viewDetailsDropdownItem',
  MccGoToTargetSiteDropdownItem = 'goToTargetSiteDropdownItem',
  MccGoToSourceSiteDropdownItem = 'goToSourceSiteDropdownItem',
  MccDownloadReportsAndLogsButton = 'downloadReportsAndLogsButton',
  LearnMoreLink = 'learnMoreLink',
  MpsRunMigrationDropdownItem = 'runMigrationDropdownItem',
  MpsDownloadLogsAndReports = 'logsAndReports',
  MpsEditPlanDropdownItem = 'editPlanDropdownItem',
  MpsEditPlanButton = 'editPlanButton',
  MpsCopyPlanDropdownItem = 'copyPlanDropdownItem',
  SxpFeedbackLink = 'migrationSharedExperiencesFeedbackLink',
  SxpFeedbackButton = 'migrationSharedExperiencesFeedbackButton',
}

export type MCCScreenType = Record<ScreenKeys, (attributes?: Record<string, any>) => ScreenEvent>;
export type MCCUIType = Record<UIKeys, FeatureEventUi>;
export type MCCTrackType = Record<string, (attributes?: Record<string, any>) => TrackEvent>;
export type MCCOperationalType = Record<string, (attributes?: Record<string, any>) => OperationalEvent>;

export const migrationControlCenterEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/MigPT/pages/2656674811/MCC+Analytics',
  screen: mapEventsWithMigrationAttribute<MCCScreenType>({
    migrationControlCenterDashboardScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59333',
    }),
    migrationControlCenterDetailsScreenEvent: (attributes: {
      migrationId: string;
      cloudId: string;
      stage: string;
      status: string;
      product: string;
      sandbox: boolean;
      cloudLicense: string;
    }): ScreenEvent => ({
      data: {
        name: migrationControlCenterDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53942',
    }),
  }),
  ui: mapEventsWithMigrationAttribute<MCCUIType>({
    viewDetailsClickEvent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: ActionSubjectId.MccViewDetailsDropdownItem,
        source: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54160',
    }),
    goToTargetSiteClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: ActionSubjectId.MccGoToTargetSiteDropdownItem,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54161',
    }),
    goToSourceSiteClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: ActionSubjectId.MccGoToSourceSiteDropdownItem,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54162',
    }),
    downloadReportsClickEvent: (attributes: {
      migrationId: string;
      cloudId: string;
      state: string;
      resultedInDownload: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: ActionSubjectId.MccDownloadReportsAndLogsButton,
        source: migrationControlCenterDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58631',
    }),
    runMigrationClickEvent: (attributes: { planScopeId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: ActionSubjectId.MpsRunMigrationDropdownItem,
        source: migrationControlCenterDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69966',
    }),
    learnMoreLinkClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: ActionSubjectId.LearnMoreLink,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    editPlanClickEvent: (attributes: { serverId: string; cloudId: string; migrationId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: ActionSubjectId.MpsEditPlanDropdownItem,
        source: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74130',
    }),
    copyMigrationClickEvent: (attributes: { serverId: string; cloudId: string; migrationId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: ActionSubjectId.MpsCopyPlanDropdownItem,
        source: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74131',
    }),
    editPlanFromDetailsPageClickEvent: (attributes: {
      serverId: string;
      cloudId: string;
      migrationId: string;
      supportEntitlementNumber: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: ActionSubjectId.MpsEditPlanButton,
        source: migrationControlCenterDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74160',
    }),
    downloadLogsClickEvent: (attributes: {
      serverId: string;
      cloudId: string;
      migrationId: string;
      supportEntitlementNumber: string;
      reportType: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: ActionSubjectId.MpsDownloadLogsAndReports,
        source: migrationControlCenterDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74132',
    }),
    sxpFeedbackButtonClickEvent: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedbackButton',
        source: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58340',
    }),
    sxpFeedbackLinkClickEvent: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedbackLink',
        source: migrationControlCenterDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58341',
    }),
  }),
  track: mapEventsWithMigrationAttribute<MCCTrackType>({}),
  operational: mapEventsWithMigrationAttribute<MCCOperationalType>({}),
};
