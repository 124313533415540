import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UiKeys =
  | 'insightsAccountUsageTable'
  | 'insightsAccountUsageAccountDownload'
  | 'insightsAccountUsageAccountsViewAll'
  | 'insightsAccountUsageTableRetry'
  | 'insightsAccountUsageCardShown'
  | 'insightsAccountUsagePageChange';

export const adminInsightsAccountUsageEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/575486649/Organization+Insights+Account+Usage+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    insightsAccountUsageTable: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Table,
        actionSubjectId: 'activeAccountUsage',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20511',
    }),
    insightsAccountUsageAccountDownload: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'activeAccountsDownloadListAction',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20512',
    }),
    insightsAccountUsageAccountsViewAll: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'activeAccountsViewAllAction',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20513',
    }),
    insightsAccountUsageTableRetry: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activeAccountsTableRetry',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20514',
    }),
    insightsAccountUsageCardShown: (attributes: { selectedProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'activeAccountUsage',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20515',
    }),
    insightsAccountUsagePageChange: (attributes: { previousPage: number; currentPage: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activeAccountsPagination',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27774',
    }),
  },
  track: {},
  operational: {},
};
