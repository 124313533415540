import _defineProperty from "@babel/runtime/helpers/defineProperty";
/** @jsx jsx */
import { useContext } from 'react';
import { css, jsx } from '@emotion/react';
import { defaultGridColumnWidth, defaultLayout, spacingMapping, varColumnsNum, varGridSpacing } from './constants';
import { GridContext } from './grid-context';
var gridStyles = css({
  display: 'flex',
  margin: '0 auto',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  padding: "0 calc(var(".concat(varGridSpacing, ") / 2)"),
  position: 'relative',
  alignItems: 'flex-start',
  flexWrap: 'wrap'
});
var gridLayoutStyles = {
  fixed: css({
    maxWidth: "calc(var(".concat(varColumnsNum, ") * ").concat(defaultGridColumnWidth, "px)")
  }),
  fluid: css({
    maxWidth: '100%'
  })
};
var nestedGridStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  margin: "0 calc(-1 * var(".concat(varGridSpacing, "))")
});

/**
 * __Grid__
 *
 * A container for one or more `GridColumn`.
 *
 * This is the internal component, which relies on the context provided by the
 * grid wrapper.
 *
 * @internal
 */
export var Grid = function Grid(_ref) {
  var _ref2;
  var _ref$layout = _ref.layout,
    layout = _ref$layout === void 0 ? defaultLayout : _ref$layout,
    testId = _ref.testId,
    children = _ref.children;
  var _useContext = useContext(GridContext),
    isNested = _useContext.isNested,
    columns = _useContext.columns,
    spacing = _useContext.spacing;
  return jsx("div", {
    css: [gridStyles, gridLayoutStyles[layout], isNested && nestedGridStyles],
    style: (_ref2 = {}, _defineProperty(_ref2, varColumnsNum, columns), _defineProperty(_ref2, varGridSpacing, "".concat(spacingMapping[spacing], "px")), _ref2),
    "data-testid": testId
  }, children);
};