import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'dataManagementHomeScreen' | 'dataManagementBenefitsModal';

type UIEventKeys =
  | 'dataManagementSideNavClicked'
  | 'dataManagementBenefitsModalButtonClicked'
  | 'dataManagementC2CButtonClicked'
  | 'dataManagementSandboxButtonClicked'
  | 'dataManagementBrieButtonClicked'
  | 'dataManagementC2CLinkClicked'
  | 'dataManagementSandboxLinkClicked'
  | 'dataManagementBrieLinkClicked';

export const dataManagementHomeScreen = 'dataManagementHomeScreen';
export const dataManagementBenefitsModal = 'dataManagementBenefitsModal';

export const dataManagementHomePageEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/l/cp/8mAm4JLt',
  screen: {
    dataManagementHomeScreen: (): ScreenEvent => ({
      data: {
        name: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59731',
    }),
    dataManagementBenefitsModal: (): ScreenEvent => ({
      data: {
        name: dataManagementBenefitsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59753',
    }),
  },
  track: {},
  operational: {},
  ui: {
    dataManagementSideNavClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'dataManagementSideNav',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59757',
    }),
    dataManagementBenefitsModalButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dataManagementBenefitsModalBtn',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59758',
    }),
    dataManagementC2CButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dataManagementC2CButton',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59759',
    }),
    dataManagementSandboxButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dataManagementSandboxButton',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59760',
    }),
    dataManagementBrieButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dataManagementBrieButton',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59761',
    }),
    dataManagementC2CLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'dataManagementC2CLink',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59762',
    }),
    dataManagementSandboxLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'dataManagementSandboxLink',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60285',
    }),
    dataManagementBrieLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'dataManagementBrieLink',
        source: dataManagementHomeScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59764',
    }),
  },
};
