import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UiKeys = 'insightsActiveTrelloUsersTableCardShown' | 'insightsActiveTrelloUsersTableTryAgain';

export const adminInsightsActiveTrelloUsersTableEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1359197433/Organization+Insights+-+Active+Trello+users+chart+Active+Trello+users+by+pricing+plan+table+-+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    insightsActiveTrelloUsersTableCardShown: (attributes: { hasTrelloEnterprise: boolean }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'activeTrelloUsersPricingPlan',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40019',
    }),
    insightsActiveTrelloUsersTableTryAgain: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activeTrelloUsersTableTryAgain',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41449',
    }),
  },
  track: {},
  operational: {},
};
