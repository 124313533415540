/* eslint-disable max-classes-per-file */
import type { History } from 'history';
import { produce, setAutoFreeze } from 'immer';
import type { OutputParams } from 'query-string';
import { parse, parseUrl } from 'query-string';

import PlatformAnalyticsClient, { envType, tenantType, userType } from '@atlassiansox/analytics-web-client';
import OriginTracer from '@atlassiansox/origin-tracing';

import AkFeatureGates from '@atlaskit/feature-gate-js-client';

import type { EnvironmentConfig } from '@adminhub/config';
import { getConfig } from '@adminhub/config';
import { withMockOverride } from '@adminhub/help';
import { getCloudIdFromPath, getOrgIdFromPath } from '@adminhub/utilities/src/get-id-from-path';

import type {
  OperationalData,
  OperationalEvent,
  ScreenData,
  ScreenEvent,
  TrackData,
  TrackEvent,
  UIData,
  UIEvent,
} from './new-analytics-types';
import { sanitizeUrl } from './sanitization';

const product = {
  siteAdmin: 'siteAdmin',
  adminHub: 'adminHub',
};

const environmentType: EnvironmentConfig = {
  local: envType.LOCAL,
  ddev: envType.DEV,
  'stg-east': envType.STAGING,
  'stg-fedm-east': envType.STAGING,
  'prod-east': envType.PROD,
  'prod-west': envType.PROD,
  'prod-fedm-east': envType.PROD,
};

type Event = UIEvent | ScreenEvent | TrackEvent | OperationalEvent;

export interface AnalyticsClient {
  init(): void;
  initOriginTracingHandlers(): void;
  setOrgId(orgId: string): void;

  sendUIEvent(e: UIEvent, onEventSent?: any): void; // onEventSent is an exposed onEventSent from '@atlassiansox/analytics-web-client'. Will be called when Event is sent.
  sendScreenEvent(e: ScreenEvent, onEventSent?: any): void; // onEventSent is an exposed onEventSent from '@atlassiansox/analytics-web-client'. Will be called when Event is sent.
  sendTrackEvent(e: TrackEvent, onEventSent?: any): void; // onEventSent is an exposed onEventSent from '@atlassiansox/analytics-web-client'. Will be called when Event is sent.
  sendOperationalEvent(e: OperationalEvent, onEventSent?: any): void;

  getAnalyticsWebClient(): any;
}

class AnalyticsWebClient implements AnalyticsClient {
  private client: any;
  private originTracingAttributes: Record<string, string> = {};

  constructor(
    client?: any,
    private getPathname: () => string = () => window.location.pathname,
    history?: History,
  ) {
    this.client = client || AnalyticsWebClient.createClient(history);
  }

  public static createClient = (history?: History) =>
    new PlatformAnalyticsClient(
      {
        env: environmentType[getConfig().analyticsClientEnvironment],
        product: 'admin',
        subproduct: product.adminHub,
        version: '1.0.0',
        locale: 'en-US',
      },
      {
        // function to remove atlOrigin query parameter in the URL
        historyReplaceFn: (url: string) => {
          const { pathname, search, hash } = new URL(url);
          if (history) {
            history.replace({ pathname, search, hash });
          }
        },
        apiHostProtocol: typeof window === 'undefined' ? 'http' : window.location.protocol.slice(0, -1),
      },
      {
        disableCookiePersistence: true,
      },
    );

  public init = () => {
    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    this.setAtlassianAccountId();
  };

  public static create(history?: History): AnalyticsWebClient {
    return new AnalyticsWebClient(undefined, undefined, history);
  }

  public initOriginTracingHandlers = () => {
    this.client.setOriginTracingHandlers({
      atlOrigin: (encodedOrigin: string) => {
        const origin = OriginTracer.fromEncoded(encodedOrigin);

        if (origin.isValid() && !origin.isMalformed() && origin.product) {
          this.originTracingAttributes = { originProduct: origin.product };
        }

        return {
          originTracingAttributes: origin.toAnalyticsAttributes(),
          ...(!origin.isMalformed() && { taskSessionId: origin.id }),
        };
      },
    });
  };

  public getAnalyticsWebClient = () => this.client;

  public sendUIEvent = (e: UIEvent, onEventSent?: any) => {
    this.sendEvent(e, this.client.sendUIEvent.bind(this.client), onEventSent);
  };

  public sendScreenEvent = (e: ScreenEvent, onEventSent?: any) => {
    this.sendEvent(e, this.client.sendScreenEvent.bind(this.client), onEventSent);
  };

  public sendTrackEvent = (e: TrackEvent, onEventSent?: any) => {
    this.sendEvent(e, this.client.sendTrackEvent.bind(this.client), onEventSent);
  };

  public sendOperationalEvent = (e: OperationalEvent, onEventSent?: any) => {
    this.sendEvent(e, this.client.sendOperationalEvent.bind(this.client), onEventSent);
  };

  public setOrgId = (orgId: string) => {
    this.client.setOrgInfo(orgId);
  };

  private get referrerAnalytics() {
    if (document.referrer !== '') {
      const { url, query } = parseUrl(document.referrer);

      const referrerParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'].reduce((params, key) => {
        if (query[key]) {
          params[key] = query[key];
        }

        return params;
      }, {} as OutputParams);

      const referrerUrl = sanitizeUrl(url);

      return {
        referrerParams,
        referrerUrl,
      };
    }

    const { srcType } = parse(window.location.search);

    if (srcType) {
      return {
        referrerParams: null,
        referrerUrl: sanitizeUrl(srcType as string),
      };
    }

    return {
      referrerParams: null,
      referrerUrl: null,
    };
  }

  private sendEvent(
    e: Event,
    send: (data: UIData | ScreenData | TrackData | OperationalData, onEventSent?: any) => void,
    onEventSent?: any,
  ) {
    this.client.clearTenantInfo();

    const cloudIdFromPath = getCloudIdFromPath(this.getPathname());
    const orgIdFromPath = getOrgIdFromPath(this.getPathname());

    setAutoFreeze(false);
    const finalEventData = produce(e.data, (draft) => {
      draft.attributes = {
        ...draft.attributes,
        ...this.referrerAnalytics,
        ...this.originTracingAttributes,
        languagePreference: window.navigator.language,
        languageMultiplePreferences: window.navigator.languages,
        path: window.location.pathname,
      };

      return draft;
    });
    setAutoFreeze(true);

    if (e.cloudId || cloudIdFromPath) {
      this.client.setTenantInfo(tenantType.CLOUD_ID, e.cloudId || cloudIdFromPath);
    }
    if (e.orgId || orgIdFromPath) {
      this.client.setOrgInfo(e.orgId || orgIdFromPath);
      this.client.setTenantInfo(tenantType.ORG_ID, e.orgId || orgIdFromPath);
    }

    if (process.env.NODE_ENV === 'test') {
      // do not send analytics while running unit tests
      return;
    }

    send(finalEventData, onEventSent);
  }

  private async setAtlassianAccountId() {
    try {
      const currentUserResponse = await fetch(`${getConfig().apiUrl}/me`, {
        method: 'GET',
      });

      if (!currentUserResponse.ok) {
        return;
      }

      const currentUser = await currentUserResponse.json();

      this.client.setUserInfo(userType.ATLASSIAN_ACCOUNT, currentUser.account_id);
    } catch (_) {
      // noop because we dont want to double-up on reporting errors
      // for the getCurrentUser call failing as we are already reporting those elsewhere
    }
  }
}

class AnalyticsEventWrapper extends AnalyticsWebClient {
  private analyticsWebClient: AnalyticsWebClient;
  private eventHandlers: { name: string; handler(...args: any[]): any }[] | undefined;

  constructor(client: any, getPathname: () => string = () => window.location.pathname, history?: History) {
    super(client, getPathname, history);
    this.analyticsWebClient = new AnalyticsWebClient(client || AnalyticsEventWrapper.createClient(history), getPathname, history);
  }

  public static override create(history?: History): AnalyticsEventWrapper {
    return new AnalyticsEventWrapper(undefined, undefined, history);
  }

  public override init = (eventHandlers?: { name: string; handler(...args: any[]): any }[]) => {
    this.analyticsWebClient.init();
    if (eventHandlers) this.eventHandlers = eventHandlers;
  };

  public static override createClient = (history?: History) => AnalyticsWebClient.createClient(history);

  public override initOriginTracingHandlers = () => {
    this.analyticsWebClient.initOriginTracingHandlers();
  };

  public override getAnalyticsWebClient = () => this.analyticsWebClient.getAnalyticsWebClient();

  public override sendUIEvent = (e: UIEvent, onEventSent?: any) => {
    this.analyticsWebClient.sendUIEvent(e, onEventSent);

    if (!AkFeatureGates.checkGate('add-surveys-to-analytic-events')) return;

    this.eventHandlers?.forEach((eventHandler) => {
      if (eventHandler.name === 'createSurvey') {
        const surveyEventId = this.generateSurveyEventId(e.data.action, e.data.actionSubject, e.data.source, undefined);
        eventHandler.handler(surveyEventId, e);
      } else {
        eventHandler.handler();
      }
    });
  };

  public override sendTrackEvent = (e: TrackEvent, onEventSent?: any) => {
    this.analyticsWebClient.sendTrackEvent(e, onEventSent);

    if (!AkFeatureGates.checkGate('add-surveys-to-analytic-events')) return;

    this.eventHandlers?.forEach((eventHandler) => {
      if (eventHandler.name === 'createSurvey') {
        const surveyEventId = this.generateSurveyEventId(e.data.action, e.data.actionSubject, e.data.source, undefined);
        eventHandler.handler(surveyEventId, e);
      } else {
        eventHandler.handler();
      }
    });
  };

  public override sendOperationalEvent = (e: OperationalEvent, onEventSent?: any) => {
    this.analyticsWebClient.sendOperationalEvent(e, onEventSent);

    if (!AkFeatureGates.checkGate('add-surveys-to-analytic-events')) return;

    this.eventHandlers?.forEach((eventHandler) => {
      if (eventHandler.name === 'createSurvey') {
        const surveyEventId = this.generateSurveyEventId(e.data.action, e.data.actionSubject, e.data.source, undefined);
        eventHandler.handler(surveyEventId, e);
      } else {
        eventHandler.handler();
      }
    });
  };

  public override sendScreenEvent = (e: ScreenEvent, onEventSent?: any) => {
    this.analyticsWebClient.sendScreenEvent(e, onEventSent);

    if (!AkFeatureGates.checkGate('add-surveys-to-analytic-events')) return;

    this.eventHandlers?.forEach((eventHandler) => {
      if (eventHandler.name === 'createSurvey') {
        const surveyEventId = this.generateSurveyEventId(undefined, undefined, undefined, e.data.name);
        eventHandler.handler(surveyEventId, e);
      } else {
        eventHandler.handler();
      }
    });
  };

  public override setOrgId = (orgId: string) => {
    this.analyticsWebClient.setOrgId(orgId);
  };

  private generateSurveyEventId = (
    eventAction: string | undefined,
    eventActionSubject: string | undefined,
    eventSource: string | undefined,
    eventName: string | undefined,
  ): string => {
    const stack: string[] = [];
    if (eventAction) stack.push(eventAction);
    if (eventActionSubject) stack.push(eventActionSubject);
    if (eventSource) stack.push(eventSource);
    if (eventName) stack.push(eventName);

    return stack.join('_');
  };
}

const PickedAnalyticsWebClient = withMockOverride(AnalyticsEventWrapper, 'AnalyticsWebClient');

export { PickedAnalyticsWebClient as AnalyticsWebClient };
