import type { CapabilitySubject, FeatureEvents, OperationalAction, OperationalEvent } from '../new-analytics-types';

type OperationalKeys = 'capabilityOperationalEvent';

export const capabilityEvents: FeatureEvents<string, string, string, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/653505700/Capability+Analytics+Spec',
  screen: {},
  ui: {},
  operational: {
    capabilityOperationalEvent: (attributes: {
      source: string;
      action: OperationalAction;
      actionSubject: CapabilitySubject;
      duration: number | undefined;
      isSynthetic: boolean;
      errorAttributes: {
        errorMessage?: string;
        statusCode?: (number | string)[] | number | string;
        region?: string[] | string;
        reason?: string[] | string;
        errorCode?: string[] | string;
        requestId?: string;
      };
      customAttributes?;
    }): OperationalEvent => ({
      data: {
        action: attributes.action,
        actionSubject: attributes.actionSubject,
        source: attributes.source,
        attributes: {
          // custom attributes must be listed first so that if dev tries to set attributes such as
          // application, duration, etc, it will be overridden by the correct value below.
          ...attributes.customAttributes,
          // Event is used for tracking capabilities in SignalFX
          // Only product, subproduct, platform, source, env, and browser are sent to SignalFX by default,
          // everything else needs to be in attributes
          task: attributes.actionSubject,
          duration: attributes.duration,
          application: 'access',
          isSynthetic: attributes.isSynthetic,
          ...attributes.errorAttributes,
        },
      },
      dataPortalLink: [
        'https://data-portal.internal.atlassian.com/analytics/registry/23279', // domainsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/23280', // domainsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/23397', // managedAccountsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/23398', // managedAccountsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/23848', // auditLogViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/23849', // auditLogViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24552', // managedAccountPasswordReset taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24551', // managedAccountPasswordReset taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24461', // insights taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24462', // insights taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24407', // siteAccountsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24408', // siteAccountsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24409', // twoStepVerificationEnabled taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24410', // twoStepVerificationEnabled taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24411', // twoStepVerificationDisabled taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24412', // twoStepVerificationDisabled taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24413', // samlConfigured taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24414', // samlConfigured taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24415', // managedAccountApiTokenRevoked taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24416', // managedAccountApiTokenRevoked taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24576', // managedAccountDeactivated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24577', // managedAccountDeactivated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24611', // managedAccountEdited taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24612', // managedAccountEdited taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24701', // managedAccountDetailsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24702', // managedAccountDetailsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/24827', // domainVerified taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/24829', // domainVerified taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/26691', // ipAllowlistCreated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/26710', // ipAllowlistCreated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/26712', // ipAllowlistUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/26713', // ipAllowlistUpdated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/26714', // ipAllowlistDeleted taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/26715', // ipAllowlistDeleted taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/26998', // dataResidencyViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/26999', // dataResidencyViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/28117', // dataResidencyChanged taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/28118', // dataResidencyChanged taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/36678', // dataResidencyCanceled taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/36679', // dataResidencyCanceled taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27000', // releaseTracksViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27001', // releaseTracksViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46205', // releaseTracksChangelogsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46206', // releaseTracksChangelogsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27002', // releaseTrackChanged taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27003', // releaseTrackChanged taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/47985', // sandboxesViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/47987', // sandboxesViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27443', // authPolicyDeleted taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27444', // authPolicyDeleted taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27441', // authPolicyDefaultPolicyChanged taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27442', // authPolicyDefaultPolicyChanged taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27435', // authPolicyCreated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27436', // authPolicyCreated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27482', // authPolicyMembersTabViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27483', // authPolicyMembersTabViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27484', // authPoliciesListPageViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27485', // authPoliciesListPageViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27486', // authPolicySettingsTabViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27487', // authPolicySettingsTabViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27437', // authPolicyAddMembers taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27438', // authPolicyAddMembers taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27439', // authPolicyAddMembersWithCSV taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27440', // authPolicyAddMembersWithCSV taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/27445', // authPolicyUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/27448', // authPolicyUpdated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/40028', // authPolicyMembersExportInitiated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/40007', // authPolicyMembersExportInitiated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/40012', // authPolicyResetSessions taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/40013', // authPolicyResetSessions taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/40008', // exportDownloaded taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/40010', // exportDownloaded taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44809', // orgRevokeProductAccessGroupDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44810', // orgRevokeProductAccessGroupDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/28075', // addProduct taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/28077', // addProduct taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/45074', // orgImpersonateUser taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/45075', // orgImpersonateUser taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44455', // orgInviteUser taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44456', // orgInviteUser taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44790', // orgGrantProductAccessGroupDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44791', // orgGrantProductAccessGroupDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44599', // orgRemoveUserFromGroup taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44600', // orgRemoveUserFromGroup taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44605', // orgGrantProductAccessUserDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44606', // orgGrantProductAccessUserDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44760', // orgRevokePlatformRole taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44761', // orgRevokePlatformRole taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/30018', // authPoliciesEnabledChecked taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/30017', // authPoliciesEnabledChecked taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/31962', // authPolicyTaskPollingCompleted taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/32385', // discoveredProductsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/32384', // discoveredProductsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/40227', // auditLogActivitySettingsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/40228', // auditLogActivitySettingsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/40229', // auditLogActivitySettingsChanged taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/40230', // auditLogActivitySettingsChanged taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44718', // orgAssignPlatformRole taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44719', // orgAssignPlatformRole taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44314', // groupListViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44315', // groupListViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44588', // orgAddUserToGroupModal taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44589', // orgAddUserToGroupModal taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44677', // orgRevokeProductAccessUserDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44678', // orgRevokeProductAccessUserDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44325', // orgUserListViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44326', // orgUserListViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44398', // orgProductDetailsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44404', // orgProductDetailsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44327', // orgGroupDetailsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44328', // orgGroupDetailsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44285', // licenseUtilizationViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44284', // licenseUtilizationViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44526', // createGroup taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44529', // createGroup taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44530', // UserDetailsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44531', // UserDetailsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44534', // deleteGroup taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44535', // deleteGroup taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44513', // orgRemoveUser taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44514', // orgRemoveUser taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44603', // AddUserToGroupDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44604', // AddUserToGroupDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/47567', // externalUsersExportInitiated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/47568', // externalUsersExportInitiated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/47569', // externalUserPolicyCreated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/47570', // externalUserPolicyCreated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/51588', // externalUserPolicyUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/51589', // externalUserPolicyUpdated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/51607', // externalUserApiTokenPolicyUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/51608', // externalUserApiTokenPolicyUpdated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44766', // orgActivateUser taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44767', // orgActivateUser taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44762', // orgDeactivateUser taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44763', // orgDeactivateUser taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44823', // orgUpdateGroupAsDefault taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44824', // orgUpdateGroupAsDefault taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/44991', // orgPromptResetPassword taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/44992', // orgPromptResetPassword taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/45020', // orgAddGroupToProductDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/45021', // orgAddGroupToProductDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/45124', // orgSuggestChangesToUserDetails taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/45125', // orgSuggestChangesToUserDetails taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/45708', // contactsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/45709', // contactsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46396', // productUpdatesViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46397', // productUpdatesViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46515', // productUpdatesDetailsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46516', // productUpdatesDetailsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46517', // productUpdatesEmailSubscribe taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46518', // productUpdatesEmailSubscribe taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46558', // productUpdatesReleaseDatesViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46559', // productUpdatesReleaseDatesViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/46453', // userConnectedAppsViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/46454', // userConnectedAppsViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/54993', // userConnectedAppsDetailsPageViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/54994', // userConnectedAppsDetailsPageViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/47924', // workspacesViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/47926', // workspacesViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/48416', // orgBillingPreviewViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/48418', // orgBillingPreviewViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/48478', // opsgenieJSMMerged taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/48481', // opsgenieJSMMerged taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/48482', // siteBillingPreviewViewed taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/48486', // siteBillingPreviewViewed taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/51522', // addedAiToWorkspaces taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/51523', // addedAiToWorkspaces taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/51521', // removedAiFromWorkspace taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/51520', // removedAiFromWorkspace taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/52765', // identityProviderCreated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/52766', // identityProviderCreated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/54979', // featureGatesClientInitialized taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/54980', // featureGatesClientInitialized taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/55638', // externalUserPolicySessionReset taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/55639', // externalUserPolicySessionReset taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/55665', // externalUserPolicySessionDurationUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/55666', // externalUserPolicySessionDurationUpdated taskFail
        'https://data-portal.internal.atlassian.com/analytics/registry/56231', // dataSecurityPoliciesPublicLinksRuleUpdated taskSuccess
        'https://data-portal.internal.atlassian.com/analytics/registry/56232', // dataSecurityPoliciesPublicLinksRuleUpdated taskFail
      ],
    }),
  },
  track: {},
};
