import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { isActionEventType, validateActionEvent, validateActionEventType } from '../eventValidation';
import { partition } from '../objectUtils';
export class CompressionRule {
  /**
   * @callback EventPredicate
   * @param {object} event An analytics event
   * @returns {boolean} A flag indicating whether or not the event matches the conditions
   */

  /**
   * @callback CompressionFunction
   * @param {Array.<Object>} events A collection of analytics events that match the predicate of this compression rule
   * @returns {Array.<Object>} compressedEvents The compressed analytics events to fire
   */
  /**
   * This constructor defines a new rule for compressing analytics events.
   * @param {EventPredicate} predicate
   * @param {CompressionFunction} compressFn
   */
  constructor(predicate, compressFn) {
    /**
     * This function returns a flag indicating whether or not this instance is capable of compressing the given event.
     * @param event
     * @returns {boolean}
     */
    _defineProperty(this, "canCompress", event => {
      try {
        return event && isActionEventType(event.eventType) && this.predicate(event);
      } catch (e) {
        return false;
      }
    });
    /**
     * This function compresses as many events in the given collection as possible.<p>
     * Any events in the given collection that do not match the conditions of {@link canCompress} will be left uncompressed and
     * appended to the end of the resulting array.
     *
     * @param events A collection of analytic events to compress
     * @returns {Array.<Object>} The action fields for the resulting compressed events
     */
    _defineProperty(this, "compress", events => {
      const [compatibleEvents, incompatibleEvents] = partition(events, event => this.canCompress(event));
      const compressedEvents = this.compressFn(compatibleEvents);
      if (!compressedEvents) {
        throw new Error('No events were returned from the compression function');
      }
      compressedEvents.forEach(event => {
        validateActionEventType(event.eventType);
        validateActionEvent(event);
      });
      return compressedEvents.concat(incompatibleEvents);
    });
    if (typeof predicate !== 'function') {
      throw new Error('Invalid predicate, must be a function that accepts an event and returns a boolean');
    }
    if (typeof compressFn !== 'function') {
      throw new Error('Invalid compressFn, must be a function that both accepts and returns an array of events');
    }
    this.predicate = predicate;
    this.compressFn = compressFn;
  }
}