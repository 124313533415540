import { defineMessages } from 'react-intl-next';

// Define alternative messages for use in `alt` tags.
// Key for message should match the exported image name in index.ts
export const svgAltDescriptions = defineMessages({
  azureLogoImage: {
    id: 'common.images.azure.logo.image',
    description: 'Azure logo.',
    defaultMessage: 'Azure logo',
  },
  bitiumLogoImage: {
    id: 'common.images.bitium.logo.image',
    description: 'Bitium logo.',
    defaultMessage: 'Bitium logo',
  },
  centrifyLogoImage: {
    id: 'common.images.centrify.logo.image',
    description: 'Centrify logo.',
    defaultMessage: 'Centrify logo',
  },
  oktaLogoImage: {
    id: 'common.images.okta.logo.image',
    description: 'Okta logo.',
    defaultMessage: 'Azure logo',
  },
  oneloginLogoImage: {
    id: 'common.images.onelogin.logo.image',
    description: 'Onelogin logo.',
    defaultMessage: 'Onelogin logo',
  },
  microsoftLogoImage: {
    id: 'common.images.microsoft.logo.image',
    description: 'Microsoft logo.',
    defaultMessage: 'Microsoft logo',
  },
});
