import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

export type ErrorVariation = 'cannotSyncError' | 'loadUserError' | 'productAccessError' | 'otherErrors';
export type ExpandView = 'unchanged' | 'removed' | 'added';

type ScreenKeys = 'syncExternalUsersScreenEvent';

type UiKeys =
  | 'reviewExternalUsersLinkClicked'
  | 'externalUsersLearnMoreClicked'
  | 'externalUsersSectionMessageShown'
  | 'downloadExternalUsersClicked'
  | 'startExternalUsersSyncClicked'
  | 'reviewExternalUsersCancelClicked';

type TrackKeys = 'startExternalUsersSyncActionCompleted';

const userProvisioningScreen = 'userProvisioningScreen';
const syncExternalUsersScreen = 'syncExternalUsersScreen';

export const scimExternalUsersEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/I/pages/944156776/SCIM+External+Users+Analytics+Events',

  screen: {
    syncExternalUsersScreenEvent: (): ScreenEvent => ({
      data: {
        name: syncExternalUsersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32629',
    }),
  },

  ui: {
    reviewExternalUsersLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'syncExternalUsers',
        source: userProvisioningScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32630',
    }),
    externalUsersLearnMoreClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        source: userProvisioningScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
    externalUsersSectionMessageShown: (attributes: { numberOfUsers: number }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Notification,
        actionSubjectId: 'syncExternalUsers',
        source: userProvisioningScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32631',
    }),
    downloadExternalUsersClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'downloadExternalUsers',
        source: syncExternalUsersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32780',
    }),
    startExternalUsersSyncClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'startExternalUsersSync',
        source: syncExternalUsersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32632',
    }),
    reviewExternalUsersCancelClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: syncExternalUsersScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
  },

  track: {
    startExternalUsersSyncActionCompleted: (attributes: { numberOfUsers: number }): TrackEvent => ({
      data: {
        action: TrackAction.Completed,
        actionSubject: TrackActionSubject.Group,
        source: syncExternalUsersScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24846',
    }),
  },

  operational: {},
};
