import * as React from 'react';

import { ExperienceTrackerContext } from '@atlassian/experience-tracker';

import type { ExperienceTrackerAPI } from './use-experience-tracker';

export interface ExperienceTrackerProps {
  experienceTracker: ExperienceTrackerAPI;
}

export function withExperienceTracker<TOwnProps>(
  WrappedComponent: React.ComponentType<TOwnProps & ExperienceTrackerProps>,
): React.ComponentClass<TOwnProps> {
  return class extends React.Component<TOwnProps> {
    public static WrappedComponent = WrappedComponent;

    public render() {
      return <ExperienceTrackerContext.Consumer>{this.wrappedComponentRenderer}</ExperienceTrackerContext.Consumer>;
    }

    private wrappedComponentRenderer = (experienceTracker: ExperienceTrackerAPI): React.ReactNode => (
      <WrappedComponent {...this.props} experienceTracker={experienceTracker} />
    );
  };
}
