import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'noPaymentDetailsBanner' | 'noPaymentDetailsWarningIcon';

type UiKeys =
  | 'addBillingDetailsLinkClicked'
  | 'removeUsersLinkClicked'
  | 'noPaymentDetailsWarningIconTooltipShown'
  | 'noPaymentDetailsWarningIconButtonClicked';

const noPaymentDetailsAlert = 'noPaymentDetailsAlert';
const noPaymentDetailsWarningIcon = 'noPaymentDetailsWarningIcon';

export const noPaymentDetailsAlertEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/Growth/pages/3267834848/Admin+hub+no+payment+details+alert+-+Analytics+specification',
  screen: {
    noPaymentDetailsBanner: (attributes: Record<string, string>): ScreenEvent => ({
      data: {
        name: 'noPaymentDetailsBanner',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62131',
    }),
    noPaymentDetailsWarningIcon: (attributes: Record<string, string>): ScreenEvent => ({
      data: {
        name: noPaymentDetailsWarningIcon,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62130',
    }),
  },
  ui: {
    addBillingDetailsLinkClicked: (attributes: Record<string, string>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'addBillingDetails',
        source: noPaymentDetailsAlert,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62127',
    }),
    removeUsersLinkClicked: (attributes: Record<string, string>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'removeUsers',
        source: noPaymentDetailsAlert,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72026',
    }),
    noPaymentDetailsWarningIconTooltipShown: (attributes: Record<string, string>): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: noPaymentDetailsWarningIcon,
        source: noPaymentDetailsAlert,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62129',
    }),
    noPaymentDetailsWarningIconButtonClicked: (attributes: Record<string, string>): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: noPaymentDetailsWarningIcon,
        source: noPaymentDetailsAlert,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62128',
    }),
  },
  track: {},
  operational: {},
};
