import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UiKeys = 'dismissBannerClickEvent' | 'bannerShownEvent';

export const adminInsightsTrelloDiscoverabilityEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1359197433/Organization+Insights+-+Active+Trello+users+chart+Active+Trello+users+by+pricing+plan+table+-+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    dismissBannerClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismissTrelloDiscoverabilityNotification',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40920',
    }),
    bannerShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Notification,
        actionSubjectId: 'trelloDiscoverabilityNotification',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40921',
    }),
  },
  track: {},
  operational: {},
};
