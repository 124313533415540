import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import type { UserRequestsAccessRequestAttributes } from './analytics-event-data';

type UIEventKeys =
  | 'viewInterestButtonClickEvent'
  | 'tryNowButtonLinkClickEvent'
  | 'venderLinkClickEvent'
  | 'closeSidebarButtonClickEvent'
  | 'sortByButtonClickEvent'
  | 'aboutAccessRequestsLinkClickEvent'
  | 'userAccessSettingsLinkClickEvent'
  | 'siteFilterClickEvent'
  | 'siteFilterDropdownItemClickEvent'
  | 'reviewRequestButtonClickEvent'
  | 'userRequestsTabClickEvent'
  | 'resultsPerPageDropdownItemClickEvent'
  | 'previousPageButtonClickEvent'
  | 'nextPageButtonClickEvent'
  | 'approveAccessRequestClickEvent'
  | 'requestMoreInfoClickEvent'
  | 'denyAccessRequestClickEvent'
  | 'closeModalClickEvent'
  | 'denyAccessConfirmClickEvent'
  | 'seeUserProfileClickEvent';

type ScreenEventKeys = 'userInterestsTabScreenEvent' | 'accessRequestsTabScreenEvent';
type TrackEventKeys = 'siteFilterUpdatedTrackEvent' | 'denyAccessRequestTrackEvent' | 'approveAccessRequestTrackEvent';

export const userRequestsScreenNameSource = {
  userInterestsTab: 'userInterestsTab',
  accessRequestsTab: 'accessRequestsTab',
};

export const userRequestsEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4490957534/URM+-+Access+Request+and+Interests+Analytics+Spec',
  screen: {
    userInterestsTabScreenEvent: (attributes: {}): ScreenEvent => ({
      data: {
        name: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74800',
    }),
    accessRequestsTabScreenEvent: (attributes: { totalResults: number | null }): ScreenEvent => ({
      data: {
        name: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74882',
    }),
  },
  ui: {
    viewInterestButtonClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewInterest',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74868',
    }),
    tryNowButtonLinkClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'tryNow',
        actionSubject: ActionSubject.Link,
        source: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74867',
    }),
    venderLinkClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'vendorLink',
        actionSubject: ActionSubject.Link,
        source: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74866',
    }),
    closeSidebarButtonClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'closeSidebar',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74865',
    }),
    sortByButtonClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'sortBy',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.userInterestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74869',
    }),
    aboutAccessRequestsLinkClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'aboutAccessRequests',
        actionSubject: ActionSubject.Link,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74884',
    }),
    userAccessSettingsLinkClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userAccessSettings',
        actionSubject: ActionSubject.Link,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74885',
    }),
    siteFilterClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'siteFilter',
        actionSubject: ActionSubject.Dropdown,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74898',
    }),
    siteFilterDropdownItemClickEvent: (attributes: { cloudId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'siteFilterDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74899',
    }),
    reviewRequestButtonClickEvent: (attributes: {}): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'reviewRequestButton',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74900',
    }),
    userRequestsTabClickEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userRequestsTab',
        actionSubject: ActionSubject.Tab,
        source: attributes.source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74907',
    }),
    resultsPerPageDropdownItemClickEvent: (attributes: { resultsPerPage: number; source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'resultsPerPageDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: attributes.source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74908',
    }),
    previousPageButtonClickEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'previousPageButton',
        actionSubject: ActionSubject.Button,
        source: attributes.source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74909',
    }),
    nextPageButtonClickEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'nextPageButton',
        actionSubject: ActionSubject.Button,
        source: attributes.source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74910',
    }),
    approveAccessRequestClickEvent: (attributes: {
      accessRequestDetails: UserRequestsAccessRequestAttributes;
      modal?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'approveUserAccessRequestButton',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13981',
    }),
    requestMoreInfoClickEvent: (attributes: { accessRequestDetails: UserRequestsAccessRequestAttributes; modal?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'requestMoreInfo',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75137',
    }),
    denyAccessRequestClickEvent: (attributes: { accessRequestDetails: UserRequestsAccessRequestAttributes; modal?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'denyAccessRequest',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75145',
    }),
    closeModalClickEvent: (attributes: { accessRequestDetails: UserRequestsAccessRequestAttributes; modal?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'closeReviewRequestModal',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75138',
    }),
    denyAccessConfirmClickEvent: (attributes: {
      accessRequestDetails: UserRequestsAccessRequestAttributes;
      denialReasonLength: number;
      modal?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'denyUserAccessRequestButton',
        actionSubject: ActionSubject.Button,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          denialReasonLength: attributes.denialReasonLength,
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13982',
    }),
    seeUserProfileClickEvent: (attributes: { accessRequestDetails: UserRequestsAccessRequestAttributes }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'seeUserProfile',
        actionSubject: ActionSubject.Link,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75139',
    }),
  },
  track: {
    siteFilterUpdatedTrackEvent: (attributes: { cloudIds: string[] }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.SiteFilter,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74981',
    }),
    denyAccessRequestTrackEvent: (attributes: {
      accessRequestDetails: UserRequestsAccessRequestAttributes;
      modal: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Denied,
        actionSubject: TrackActionSubject.ProductAccess,
        actionSubjectId: attributes.accessRequestDetails.requestedForUserId,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          modal: attributes.modal,
          ...attributes.accessRequestDetails,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15415',
    }),
    approveAccessRequestTrackEvent: (attributes: {
      accessRequestDetails: UserRequestsAccessRequestAttributes;
      modal?: string;
      daNudgeOnUrmCohort?: 'variation' | 'control' | 'not-enrolled';
      numEligibleProductsForSmartSettings?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Approved,
        actionSubject: TrackActionSubject.ProductAccess,
        actionSubjectId: attributes.accessRequestDetails.requestedForUserId,
        source: userRequestsScreenNameSource.accessRequestsTab,
        attributes: {
          ...(attributes.modal && { modal: attributes.modal }),
          ...attributes.accessRequestDetails,
          ...(attributes.daNudgeOnUrmCohort && { daNudgeOnUrmCohort: attributes.daNudgeOnUrmCohort }),
          ...(attributes.numEligibleProductsForSmartSettings && {
            numEligibleProductsForSmartSettings: attributes.numEligibleProductsForSmartSettings,
          }),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15414',
    }),
  },
  operational: {},
};
