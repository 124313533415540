import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'userListScreens' | 'accessAdWhyAmISeeingThisModal';

type UIEventKeys =
  | 'freeTrialClickEvent'
  | 'learnMoreAccessClickEvent'
  | 'openAccessAdMenuClickEvent'
  | 'accessAdMenuNotInterestedClickEvent'
  | 'accessAdMenuWhyAmISeeingThisClickEvent'
  | 'accessAdPrivacyLinkClickEvent';

const userListScreens = 'userListScreens';
const accessAdWhyAmISeeingThisModal = 'accessAdWhyAmISeeingThisModal';

export const directoryUpsellEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/CloudSecurity/pages/3409184393/Atlassian+Access+Banner+on+Directory+Pages+Analytics+Spec',
  screen: {
    userListScreens: (attributes?: { pageVariant: string }): ScreenEvent => ({
      data: {
        name: userListScreens,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62005',
    }),
    accessAdWhyAmISeeingThisModal: (): ScreenEvent => ({
      data: {
        name: accessAdWhyAmISeeingThisModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54669',
    }),
  },
  ui: {
    freeTrialClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'freeTrialButton',
        source: userListScreens,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15443',
    }),
    learnMoreAccessClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMoreAccessButton',
        source: userListScreens,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13492',
    }),
    openAccessAdMenuClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAccessAdMenu',
        source: userListScreens,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54665',
    }),
    accessAdMenuNotInterestedClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessAdMenuNotInterested',
        source: userListScreens,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54666',
    }),
    accessAdMenuWhyAmISeeingThisClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'accessAdMenuWhyAmISeeingThis',
        source: userListScreens,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54667',
    }),
    accessAdPrivacyLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'accessAdPrivacyLink',
        source: accessAdWhyAmISeeingThisModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54668',
    }),
  },
  track: {},
  operational: {},
};
