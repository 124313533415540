import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import type { LinkProductsScreen } from './analytics-event-data';

type ScreenKeys =
  | 'linkProductsImpactScreenEvent'
  | 'linkProductsSelectScreenEvent'
  | 'linkProductsConfirmScreenEvent'
  | 'linkProductsErrorScreenEvent';
type UIEventKeys =
  | 'learnMoreClickEvent'
  | 'pagePreviousClickEvent'
  | 'pageNextClickEvent'
  | 'resultsPerPageClickEvent'
  | 'contactSupportClickEvent'
  | 'drawerCloseClickEvent'
  | 'nextButtonClickEvent'
  | 'selectButtonClickEvent'
  | 'backButtonClickEvent'
  | 'closeButtonClickEvent'
  | 'linkButtonClickEvent';

type TrackEventKeys = 'updatedOrganization';
export const linkProductsSelectScreen: LinkProductsScreen = 'linkProductsSelectScreen';
export const linkProductsImpactScreen: LinkProductsScreen = 'linkProductsImpactScreen';
export const linkProductsConfirmScreen: LinkProductsScreen = 'linkProductsConfirmScreen';
export const linkProductsErrorScreen: LinkProductsScreen = 'linkProductsErrorScreen';

type ProductTypes = 'bitbucket';

export const linkProductsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/1894011890/Analytics+spec+M1+Linking+Flow+Visibility',
  screen: {
    linkProductsSelectScreenEvent: (attributes: {
      hasError: boolean;
      isEmpty: boolean;
      productTypes: string[];
      numBitbucketWorkspaces: number;
      visibleWorkspaceUuids: string[];
    }): ScreenEvent => ({
      data: {
        name: linkProductsSelectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46412',
    }),
    linkProductsImpactScreenEvent: (): ScreenEvent => ({
      data: {
        name: linkProductsImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46416',
    }),
    linkProductsConfirmScreenEvent: (attributes: { productTypes: ProductTypes[]; workspaceUuids: string[] }): ScreenEvent => ({
      data: {
        name: linkProductsConfirmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46860',
    }),
    linkProductsErrorScreenEvent: (attributes: { productTypes: ProductTypes[]; workspaceUuids: string[] }): ScreenEvent => ({
      data: {
        name: linkProductsErrorScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46861',
    }),
  },
  ui: {
    learnMoreClickEvent: ({ source }: { source: LinkProductsScreen }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMore',
        actionSubject: ActionSubject.Link,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
    pagePreviousClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'pagePrevious',
        actionSubject: ActionSubject.Button,
        source: linkProductsSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46413',
    }),
    pageNextClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'pageNext',
        actionSubject: ActionSubject.Button,
        source: linkProductsSelectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46414',
    }),
    resultsPerPageClickEvent: (attributes: { newPageLen: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'resultsPerPage',
        actionSubject: ActionSubject.DropdownItem,
        source: linkProductsSelectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46415',
    }),
    contactSupportClickEvent: ({ source }: { source: LinkProductsScreen }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'contactSupport',
        actionSubject: ActionSubject.Link,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16337',
    }),
    drawerCloseClickEvent: ({ source }: { source: LinkProductsScreen }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'drawerClose',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47441',
    }),
    nextButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'next',
        source: linkProductsImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25541',
    }),
    selectButtonClickEvent: (attributes: { hasSelectedWorkspace: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'select',
        source: linkProductsSelectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47439',
    }),
    backButtonClickEvent: ({ source }: { source: LinkProductsScreen }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'back',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28051',
    }),
    closeButtonClickEvent: ({ source }: { source: LinkProductsScreen }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'close',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14881',
    }),
    linkButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'link',
        source: linkProductsConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47442',
    }),
  },
  track: {
    updatedOrganization: ({
      orgId,
      ...attributes
    }: {
      linkedWorkspace: string;
      orgId: string;
      productType: string;
      vortexModeEnabled?: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.Organization,
        actionSubjectId: orgId,
        source: linkProductsConfirmScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47443',
    }),
  },
  operational: {},
};
