import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

import type { DataSecurityPolicyOrgPolicyAppAccessRule } from '../../../../pages/data-security-policies/src/ui/types';

// https://product-fabric.atlassian.net/wiki/spaces/SA/pages/3189014590/Data+Security+Policies+-+Analytics+spec#Screen-events
type ScreenKeys =
  | 'dataSecurityPoliciesScreenEvent'
  | 'dataSecurityPoliciesCreateModalScreenEvent'
  | 'dataSecurityPoliciesDetailScreenEvent'
  | 'dataSecurityPoliciesCoverageModalScreenEvent'
  | 'dataSecurityPoliciesAppControlsRuleModalScreenEvent'
  | 'dataSecurityPoliciesRenameModalScreenEvent'
  | 'dataSecurityPoliciesAuditLogsModalScreenEvent'
  | 'dataSecurityPoliciesDeleteModalScreenEvent'
  | 'dataSecurityPoliciesPublicLinksRuleFocusedTaskScreenEvent'
  | 'dataSecurityPoliciesAppAccessDisclosureModalScreenEvent'
  | 'dataSecurityPoliciesAppAccessScreenEvent'
  | 'dataSecurityPoliciesCoverageSelectionScreen';

// https://product-fabric.atlassian.net/wiki/spaces/SA/pages/3189014590/Data+Security+Policies+-+Analytics+spec#UI-events
type UIEventKeys =
  | 'dataSecurityPolicyClickEvent'
  | 'createDataSecurityPolicyInitClickEvent'
  | 'learnMoreAboutDataSecurityPolicyClickEvent'
  | 'createDataSecurityPolicyFinalClickEvent'
  | 'cancelCreateDataSecurityPolicyClickEvent'
  | 'enableDataSecurityDataSecurityPolicyClickEvent'
  | 'deleteDataSecurityPolicyClickEvent'
  | 'cancelDeleteDataSecurityPolicyClickEvent'
  | 'renameDataSecurityPolicyClickEvent'
  | 'cancelRenameDataSecurityPolicyClickEvent'
  | 'saveDataSecurityPolicyCoverageClickEvent'
  | 'cancelSaveDataSecurityPolicyCoverageClickEvent'
  | 'selectDataSecurityPolicyCoverageClickEvent'
  | 'createDataClassificationFromDataSecurityPolicyClickEvent'
  | 'publicLinksConfigurationCardClickEvent'
  | 'publicLinksRadioButtonClickEvent'
  | 'savePublicLinksRuleClickEvent'
  | 'closePublicLinksModalClickEvent'
  | 'confirmPublicLinksRuleUpdateClickEvent'
  | 'cancelConfirmPublicLinksRuleUpdateClickEvent'
  | 'appAccessDisclosureModalLinkClickEvent'
  | 'appAccessRuleModalFooterLinkClickEvent'
  | 'appAccessRuleConfigurationCardClickEvent'
  | 'confirmUpdateActivatedPolicyAppListClickEvent'
  | 'cancelUpdateActivatedPolicyAppListClickEvent'
  | 'grantProductAccessClickEvent'
  | 'createNewPolicyClickEvent';

// https://product-fabric.atlassian.net/wiki/spaces/SA/pages/3189014590/Data+Security+Policies+-+Analytics+spec#Track-events
type TrackEventKeys =
  | 'createdDataSecurityPolicy'
  | 'deletedDataSecurityPolicy'
  | 'renamedDataSecurityPolicy'
  | 'enabledDataSecurityPolicy'
  | 'disabledDataSecurityPolicy'
  | 'selectedDataSecurityPolicyCoverage'
  | 'updatedDataSecurityPolicyRule'
  | 'updatedDataSecurityPolicyAppAccessRule';

// https://product-fabric.atlassian.net/wiki/spaces/SA/pages/3189014590/Data+Security+Policies+-+Analytics+spec#Screen-events
export enum DataSecurityPoliciesScreens {
  dataSecurityPoliciesScreen = 'dataSecurityPoliciesScreen',
  dataSecurityPoliciesCreateModalScreen = 'dataSecurityPoliciesCreateModalScreen',
  dataSecurityPoliciesDetailScreen = 'dataSecurityPoliciesDetailScreen',
  dataSecurityPoliciesCoverageModalScreen = 'dataSecurityPoliciesCoverageModalScreen',
  dataSecurityPoliciesCoverageSelectionScreen = 'dataSecurityPoliciesCoverageSelectionScreen',
  dataSecurityPoliciesAppControlsRuleModalScreen = 'dataSecurityPoliciesAppControlsRuleModalScreen',
  dataSecurityPoliciesRenameModalScreen = 'dataSecurityPoliciesRenameModalScreen',
  dataSecurityPoliciesAuditLogsModalScreen = 'dataSecurityPoliciesAuditLogsModalScreen',
  dataSecurityPoliciesDeleteModalScreen = 'dataSecurityPoliciesDeleteModalScreen',
  dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen = 'dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen',
  dataSecurityPoliciesAppAccessDisclosureModalScreen = 'dataSecurityPoliciesAppAccessDisclosureModalScreen',
  dataSecurityPoliciesAppAccessRuleModalScreen = 'dataSecurityPoliciesAppAccessRuleModalScreen',
  dataSecurityPoliciesAppAccessScreen = 'dataSecurityPoliciesAppAccessScreen',
}

const enum DataSecurityPolicyRule {
  ALLOW = 'allow',
  BLOCK = 'block',
}

const enum RuleName {
  APP_ACCESS = 'appAccess',
  EXPORT = 'export',
  PUBLIC_LINKS = 'publicLinks',
}

const convertToDataSecurityPolicyRule = (ruleValue: boolean): DataSecurityPolicyRule => {
  if (ruleValue) {
    return DataSecurityPolicyRule.BLOCK;
  }

  return DataSecurityPolicyRule.ALLOW;
};

const convertToAppAccessRule = (rule) => rule?.blockAll ?? rule?.blockedApps ?? rule?.allowedApps;

// TBD: add corresponding dataPortalLinks before adding analytics events to data security policies UI
export const dataSecurityPolicyEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://product-fabric.atlassian.net/wiki/spaces/SA/pages/3189014590/Analytics+Event+Spec+for+Data+Security+Policies',
  screen: {
    dataSecurityPoliciesScreenEvent: (attributes: { numPolicies: number }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43136',
    }),
    dataSecurityPoliciesCreateModalScreenEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43137',
    }),
    dataSecurityPoliciesDetailScreenEvent: (attributes: { numRules: number; policyId: string; orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43138',
    }),
    dataSecurityPoliciesCoverageModalScreenEvent: (attributes: { policyId: number; orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43139',
    }),
    dataSecurityPoliciesAppControlsRuleModalScreenEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesAppControlsRuleModalScreen,
        attributes,
      },
      dataPortalLink: 'TBD',
    }),
    dataSecurityPoliciesRenameModalScreenEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesRenameModalScreen,
        attributes,
      },
      dataPortalLink: 'TBD',
    }),
    dataSecurityPoliciesAuditLogsModalScreenEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesAuditLogsModalScreen,
        attributes,
      },
      dataPortalLink: 'TBD',
    }),
    dataSecurityPoliciesDeleteModalScreenEvent: (attributes: { orgId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43140',
    }),
    dataSecurityPoliciesPublicLinksRuleFocusedTaskScreenEvent: (attributes: { orgId: string; policyId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56233',
    }),
    dataSecurityPoliciesAppAccessDisclosureModalScreenEvent: (attributes: {
      orgId: string;
      policyId: string;
      userId: string;
    }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessDisclosureModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57416',
    }),
    dataSecurityPoliciesAppAccessScreenEvent: (attributes: { orgId: string; policyId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58886',
    }),
    dataSecurityPoliciesCoverageSelectionScreen: (attributes: { orgId: string; policyId: string; userId: string }): ScreenEvent => ({
      data: {
        name: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60684',
    }),
  },
  ui: {
    dataSecurityPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'dataSecurityPolicy',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesScreen, // TBD: confirm this, this varies on where the user is navigating from
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43141',
    }),
    createDataSecurityPolicyInitClickEvent: (attributes: { numPolices: number; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createDataSecurityPolicyInit',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43142',
    }),
    learnMoreAboutDataSecurityPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutDataSecurityPolicy',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43143',
    }),
    createNewPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'emptyStateCreateNewPolicy',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/65999',
    }),
    createDataSecurityPolicyFinalClickEvent: (attributes: {
      dataSecurityPolicyName: string;
      dataSecurityPolicyDescription: string;
      orgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createDataSecurityPolicyFinal',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43144',
    }),
    cancelCreateDataSecurityPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelCreateDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43145',
    }),
    enableDataSecurityDataSecurityPolicyClickEvent: (attributes: {
      statusChanged: string;
      orgId: string;
      policyId: string;
      userId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'enableDataSecurityDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49958',
    }),
    deleteDataSecurityPolicyClickEvent: (attributes: { policyId: string; userId: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'deleteDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43146',
    }),
    cancelDeleteDataSecurityPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelDeleteDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43147',
    }),
    renameDataSecurityPolicyClickEvent: (attributes: {
      oldDataSecurityPolicyName: string;
      newDataSecurityPolicyName: string;
      userId: string;
      orgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'renameDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesRenameModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43148',
    }),
    cancelRenameDataSecurityPolicyClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelRenameDataSecurityPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesRenameModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43149',
    }),
    saveDataSecurityPolicyCoverageClickEvent: (attributes: {
      policyId: string;
      userId: string;
      coverage: string;
      numRules: number;
      orgId: string;
      coverageType: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'saveDataSecurityPolicyCoverage',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43150',
    }),
    cancelSaveDataSecurityPolicyCoverageClickEvent: (attributes: {
      policyId: string;
      userId: string;
      coverage: string;
      numRules: number;
      orgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelSaveDataSecurityPolicyCoverage',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43151',
    }),
    selectDataSecurityPolicyCoverageClickEvent: (attributes: {
      policyId: string;
      userId: string;
      orgId: string;
      coverageType: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'selectDataSecurityPolicyCoverage',
        actionSubject: ActionSubject.Card,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageSelectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60526',
    }),
    createDataClassificationFromDataSecurityPolicyClickEvent: (attributes: {
      policyId: string;
      userId: string;
      orgId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createDataClassificationFromDataSecurityPolicy',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60536',
    }),
    publicLinksConfigurationCardClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'publicLinksRuleConfigurationCard',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56224',
    }),
    publicLinksRadioButtonClickEvent: (attributes: {
      policyId: string;
      orgId: string;
      userId: string;
      oldValue: boolean;
      newValue: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'publicLinksRuleRadio',
        actionSubject: ActionSubject.RadioButton,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes: {
          ...attributes,
          oldValue: convertToDataSecurityPolicyRule(attributes.oldValue),
          newValue: convertToDataSecurityPolicyRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56225',
    }),
    savePublicLinksRuleClickEvent: (attributes: {
      policyId: string;
      orgId: string;
      userId: string;
      oldValue: boolean;
      newValue: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'savePublicLinksRuleButton',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes: {
          ...attributes,
          oldValue: convertToDataSecurityPolicyRule(attributes.oldValue),
          newValue: convertToDataSecurityPolicyRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56226',
    }),
    closePublicLinksModalClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'closePublicLinksRuleFocusedTaskButton',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56227',
    }),
    confirmPublicLinksRuleUpdateClickEvent: (attributes: {
      policyId: string;
      orgId: string;
      userId: string;
      oldValue: boolean;
      newValue: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmUpdateActivatedPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes: {
          ...attributes,
          oldValue: convertToDataSecurityPolicyRule(attributes.oldValue),
          newValue: convertToDataSecurityPolicyRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56228',
    }),
    cancelConfirmPublicLinksRuleUpdateClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelUpdateActivatedPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56229',
    }),
    appAccessDisclosureModalLinkClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'appAccessDisclosureModalClickLink',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessDisclosureModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57932',
    }),
    appAccessRuleModalFooterLinkClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'showAppAccessDisclosureModalLink',
        actionSubject: ActionSubject.Link,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessRuleModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58182',
    }),
    appAccessRuleConfigurationCardClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'appAccessRuleConfigurationCard',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58827',
    }),
    confirmUpdateActivatedPolicyAppListClickEvent: (attributes: {
      policyId: string;
      orgId: string;
      userId: string;
      oldValue: DataSecurityPolicyOrgPolicyAppAccessRule;
      newValue: DataSecurityPolicyOrgPolicyAppAccessRule;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmUpdateActivatedPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessRuleModalScreen,
        attributes: {
          ...attributes,
          oldValue: convertToAppAccessRule(attributes.oldValue),
          newValue: convertToAppAccessRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58830',
    }),
    cancelUpdateActivatedPolicyAppListClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelUpdateActivatedPolicy',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesAppAccessRuleModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58832',
    }),
    grantProductAccessClickEvent: (attributes: { policyId: string; orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'grantProductAccessButton',
        actionSubject: ActionSubject.Button,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60863',
    }),
  },
  track: {
    createdDataSecurityPolicy: (attributes: { policyId: string; userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCreateModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43152',
    }),
    deletedDataSecurityPolicy: (attributes: { policyId: string; userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDeleteModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43153',
    }),
    renamedDataSecurityPolicy: (attributes: {
      policyId: string;
      userId: string;
      oldDataSecurityPolicyName: string;
      newDataSecurityPolicyName: string;
      orgId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesRenameModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43154',
    }),
    enabledDataSecurityPolicy: (attributes: {
      policyId: string;
      userId: string;
      coverage: string;
      numRules: number;
      orgId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Enabled,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43155',
    }),
    disabledDataSecurityPolicy: (attributes: {
      policyId: string;
      userId: string;
      coverage: string;
      numRules: number;
      orgId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Disabled,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesDetailScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43156',
    }),
    selectedDataSecurityPolicyCoverage: (attributes: {
      policyId: string;
      userId: string;
      coverage: string;
      orgId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesCoverageModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43157',
    }),
    updatedDataSecurityPolicyRule: (attributes: {
      ruleName: RuleName;
      policyId: string;
      orgId: string;
      userId: string;
      isPolicyEnabled: boolean;
      oldValue: boolean;
      newValue: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.RuleUpdated,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes: {
          ...attributes,
          oldValue: convertToDataSecurityPolicyRule(attributes.oldValue),
          newValue: convertToDataSecurityPolicyRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56230',
    }),
    updatedDataSecurityPolicyAppAccessRule: (attributes: {
      ruleName: RuleName;
      policyId: string;
      orgId: string;
      userId: string;
      isPolicyEnabled: boolean;
      oldValue: DataSecurityPolicyOrgPolicyAppAccessRule;
      newValue: DataSecurityPolicyOrgPolicyAppAccessRule;
    }): TrackEvent => ({
      data: {
        action: TrackAction.RuleUpdated,
        actionSubject: TrackActionSubject.DataSecurityPolicies,
        source: DataSecurityPoliciesScreens.dataSecurityPoliciesPublicLinksRuleFocusedTaskScreen,
        attributes: {
          ...attributes,
          oldValue: convertToAppAccessRule(attributes.oldValue),
          newValue: convertToAppAccessRule(attributes.newValue),
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58834',
    }),
  },
  operational: {
    featureFlagEvaluated: (attributes: {
      source: string;
      flagAttributes: {
        flagKey: string;
        reason?: string;
        ruleId?: string;
        value: boolean | string;
      };
    }): OperationalEvent => ({
      data: {
        action: OperationalAction.Exposed,
        actionSubject: OperationalSubject.Feature,
        source: attributes.source,
        attributes: {
          ...attributes.flagAttributes,
        },
        tags: ['measurement'],
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54699',
    }),
  },
};
