import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { AnalyticsWebClient } from './analytics-client';
import { AnalyticsNextListener } from './analytics-next-listener';
import { NewAnalyticsContext } from './new-analytics-context';

interface AppAnalyticsProviderProps {
  children?: React.ReactNode;
  analyticsEventHandlers?: { name: string; handler(...args: any[]): any }[];
}

class AppAnalyticsProviderImpl extends React.Component<AppAnalyticsProviderProps & RouteComponentProps<any>> {
  private newAnalyticsClient;

  constructor(props) {
    super(props);
    this.newAnalyticsClient = AnalyticsWebClient.create(props.history);
  }

  public shouldComponentUpdate(nextProps) {
    return this.props.location.pathname !== nextProps.location.pathname;
  }

  public componentDidMount() {
    this.newAnalyticsClient.init(this.props.analyticsEventHandlers);
    this.newAnalyticsClient.initOriginTracingHandlers();
  }

  public render() {
    return (
      <NewAnalyticsContext.Provider value={this.newAnalyticsClient}>
        <AnalyticsNextListener>{this.props.children}</AnalyticsNextListener>
      </NewAnalyticsContext.Provider>
    );
  }
}

export const AppAnalyticsProvider = withRouter<AppAnalyticsProviderProps>(AppAnalyticsProviderImpl);
