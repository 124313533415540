import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'licenseUtilizationOverviewScreen'
  | 'licenseUtilizationDetailsScreen'
  | 'licenseUtilizationDetailsOverviewTab'
  | 'licenseUtilizationDetailsProductsTab'
  | 'licenseUtilizationDetailsReportSettingsTab'
  | 'licenseUtilizationDetailsActivatedSitesTab';
type UIEventKeys =
  | 'userCountsTablePopover'
  | 'viewUsageDetails'
  | 'overTierLimitIndicatorDescription'
  | 'learnMoreAboutPlansView'
  | 'userCountsLandingPageFilter'
  | 'refreshLandingTable'
  | 'exportUserCountsDetails'
  | 'viewBillingAdmins'
  | 'userCountsDetailsBlockDescription'
  | 'productManageUsers'
  | 'productsTabPagination'
  | 'productsPaginationSize'
  | 'clickedViewDetails'
  | 'clickedLearnMoreAboutEnterpriseUserTiers'
  | 'feedbackClickEvent'
  | 'openFeedbackDialogClickEvent'
  | 'hoveredOverTierLimitIndicator'
  | 'toggledEmailOptInToggle'
  | 'hoveredUniqueUsersChart'
  | 'clickedUserTiersOverviewTableUnavailableData'
  | 'clickedExportUserTierOverview'
  | 'hoveredUserTiersOverviewTableColumnHeaders'
  | 'clickedContactSalesLink'
  | 'clickedManageUser';

type UserCountErrorType = 'NO_ELIGIBLE_PLANS' | 'PERMISSIONS_ERROR' | 'DATA_ERROR' | 'NOT_FOUND_404_ERROR';

export enum LicenseUtilizationScreens {
  licenseUtilizationOverviewScreen = 'licenseUtilizationOverviewScreen',
  licenseUtilizationDetailsScreen = 'licenseUtilizationDetailsScreen',
  licenseUtilizationDetailsOverviewTab = 'licenseUtilizationDetailsOverviewTab',
  licenseUtilizationDetailsProductsTab = 'licenseUtilizationDetailsProductsTab',
  licenseUtilizationDetailsReportSettingsTab = 'licenseUtilizationDetailsReportSettingsTab',
  licenseUtilizationDetailsActivatedSitesTab = 'licenseUtilizationDetailsActivatedSitesTab',
}

export const licenseUtilizationEvents: FeatureEvents<ScreenKeys, UIEventKeys, string, string> = {
  confluenceSpec: [
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1671333327/Enterprise+license+utilization+Analytic+Spec',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2079086094/User+counts+Phase+2+Analytics+Spec',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4445638577/WIP+TwC+Monitoring+Usages+Analytics+Spec',
  ],
  screen: {
    licenseUtilizationOverviewScreen: (attributes: {
      workspaceTypesCount: number;
      numberOfWorkspacesWithOverTierLimit: number;
      numberOfWorkspacesWithNearTierLimit: number;
      hasError: UserCountErrorType;
    }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44273',
    }),
    licenseUtilizationDetailsScreen: (attributes: {
      totalWorkspaces: number;
      isOverTierLimit: boolean;
      utilizationPercentage: number;
      bundleName: string;
      bundleType: string;
      hasError: UserCountErrorType;
    }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44274',
    }),
    licenseUtilizationDetailsOverviewTab: (attributes: { productType: string; bundleName: string; bundleType: string }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationDetailsOverviewTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50002',
    }),
    licenseUtilizationDetailsProductsTab: (attributes: {
      productType: string;
      bundleName: string;
      bundleType: string;
      totalWorksapces: Number;
    }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationDetailsProductsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50003',
    }),
    licenseUtilizationDetailsReportSettingsTab: (attributes: {
      productType: string;
      bundleName: string;
      bundleType: string;
    }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationDetailsReportSettingsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50004',
    }),
    licenseUtilizationDetailsActivatedSitesTab: (attributes: { bundleName: string; bundleType: string }): ScreenEvent => ({
      data: {
        name: LicenseUtilizationScreens.licenseUtilizationDetailsActivatedSitesTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79013',
    }),
  },
  ui: {
    userCountsTablePopover: (attributes: { columnName: string; bundleName: string; bundleType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userCountsTablePopover',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79226',
    }),
    viewUsageDetails: (attributes: { bundleName: string; bundleType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewUsageDetails',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79041',
    }),
    overTierLimitIndicatorDescription: (attributes: {
      bundleName: string;
      bundleType: string;
      utilizationPercentage: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'overTierLimitIndicatorDescription',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79042',
    }),
    learnMoreAboutPlansView: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutPlansView',
        actionSubject: ActionSubject.Link,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79043',
    }),
    userCountsLandingPageFilter: (attributes: { selectedItems: Object; filterType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userCountsLandingPageFilter',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79231',
    }),
    refreshLandingTable: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'refreshLandingTable',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79048',
    }),
    exportUserCountsDetails: (attributes: { bundleName: string; bundleType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'exportUserCountsDetails',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79055',
    }),
    viewBillingAdmins: (attributes: { bundleName: string; bundleType: string; usageStatus: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewBillingAdmins',
        actionSubject: ActionSubject.Link,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79056',
    }),
    userCountsDetailsBlockDescription: (attributes: {
      bundleName: string;
      bundleType: string;
      summaryValue: string;
      blockType: string;
      eventSource: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userCountsDetailsBlockDescription',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsOverviewTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79057',
    }),
    productManageUsers: (attributes: { bundleName: string; bundleType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'productManageUsers',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsProductsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79060',
    }),
    productsTabPagination: (attributes: {
      bundleName: string;
      bundleType: string;
      pageSize: number;
      totalResults: number;
      startIndex: number;
      endIndex: number;
      direction: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'productsTabPagination',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsProductsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79243',
    }),
    productsPaginationSize: (attributes: { bundleName: string; bundleType: string; pageSize: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'productsPaginationSize',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsProductsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79063',
    }),
    clickedViewDetails: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewLicenseDetails',
        actionSubject: ActionSubject.Link,
        source: LicenseUtilizationScreens.licenseUtilizationOverviewScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44279',
    }),
    clickedLearnMoreAboutEnterpriseUserTiers: ({ source }: { source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreAboutEnterpriseUserTiers',
        actionSubject: ActionSubject.Link,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44700',
    }),
    feedbackClickEvent: (attributes: { feedback: boolean; source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'feedback',
        actionSubject: ActionSubject.Button,
        source: attributes.source,
        attributes: {
          feedback: attributes.feedback,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    openFeedbackDialogClickEvent: ({ source }: { source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'leaveFeedbackDialog',
        actionSubject: ActionSubject.Link,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    hoveredOverTierLimitIndicator: ({ source }: { source: ScreenKeys }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubjectId: 'overTierLimitIndicator',
        actionSubject: ActionSubject.Tooltip,
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44282',
    }),

    toggledEmailOptInToggle: (attributes: { productType: string; optedIn: boolean; bundleName: string; bundleType: string }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubjectId: 'emailOptInToggle',
        actionSubject: ActionSubject.Toggle,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsReportSettingsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50005',
    }),
    hoveredUniqueUsersChart: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubjectId: 'uniqueUsersChart',
        actionSubject: ActionSubject.Chart,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsOverviewTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50006',
    }),
    clickedUserTiersOverviewTableUnavailableData: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'userTiersOverviewTableUnavailableData',
        actionSubject: ActionSubject.Tooltip,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsProductsTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50007',
    }),
    clickedExportUserTierOverview: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'exportUserTierOverview',
        actionSubject: ActionSubject.Button,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50008',
    }),
    hoveredUserTiersOverviewTableColumnHeaders: (attributes: { productType: string; column: string }): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubjectId: 'userTiersOverviewTableColumnHeaders',
        actionSubject: ActionSubject.Tooltip,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsOverviewTab,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50009',
    }),
    clickedContactSalesLink: (attributes: {
      productType: string;
      utilizationPercentage: number;
      bundleName: string;
      bundleType: string;
      usageStatus: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'contactSalesLink',
        actionSubject: ActionSubject.Link,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50010',
    }),
    clickedManageUser: (attributes: {
      productType: string;
      utilizationPercentage: number;
      bundleName: string;
      bundleType: string;
      usageStatus: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'manageUser',
        actionSubject: ActionSubject.Link,
        source: LicenseUtilizationScreens.licenseUtilizationDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50011',
    }),
  },
  track: {},
  operational: {},
};
