import { Action, ActionSubject } from '../new-analytics-types';

import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';

type ScreenKeys = 'linkOnPremDashboardScreenEvent' | 'linkOnPremProductsDrawerScreenEvent';

type UIKeys = 'learnMoreLinkClickEvent' | 'linkOnPremProductButtonClickEvent' | 'linkOnPremProductLinkClickEvent';

export const linkOnPremDashboardScreen = 'linkOnPremDashboardScreen';
export const linkOnPremProductsDrawerScreen = 'linkOnPremProductsDrawerScreen';

export const linkOnPremProductsEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CT5/pages/4181018588/Migration+Trust+FE+Analytics',
  screen: {
    linkOnPremDashboardScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: linkOnPremDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71670',
    }),
    linkOnPremProductsDrawerScreenEvent: (attributes): ScreenEvent => ({
      data: {
        name: linkOnPremProductsDrawerScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71671',
    }),
  },
  ui: {
    learnMoreLinkClickEvent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'linkOnPremProductsLearnMoreLink',
        source: linkOnPremDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71672',
    }),
    linkOnPremProductButtonClickEvent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'linkOnPremProduct',
        source: linkOnPremDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71669',
    }),
    linkOnPremProductLinkClickEvent: (attributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'linkOnPremProduct',
        source: linkOnPremDashboardScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71674',
    }),
  },
  track: {},
  operational: {},
};
