import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import type { ScreenKeys } from './admin-insights-event-data';
import { adminInsightsEvents, adminInsightsScreen } from './admin-insights-event-data';

type UIKeys =
  | 'insightsTwoStepVerificationCoverageShown'
  | 'insightsTwoStepVerificationCoverageTryAgain'
  | 'insightsTwoStepVerificationCoverageChartHover'
  | 'insightsTwoStepVerificationCoverageLearnMore'
  | 'insightsTwoStepVerificationCoverageErrorShown';

export const adminInsightsTwoStepVerificationCoverageEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec:
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/900235826/Organization+Insights+Two+Step+Verification+Analytics+Spec',
  screen: adminInsightsEvents.screen,
  ui: {
    insightsTwoStepVerificationCoverageShown: (attributes: { selectedProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'twoStepVerification',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29208',
    }),
    insightsTwoStepVerificationCoverageChartHover: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'twoStepVerificationChart',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29236',
    }),
    insightsTwoStepVerificationCoverageTryAgain: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'twoStepVerificationTryAgain',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29235',
    }),
    insightsTwoStepVerificationCoverageLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'twoStepVerificationLearnMore',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29211',
    }),
    insightsTwoStepVerificationCoverageErrorShown: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Error,
        actionSubjectId: 'twoStepVerificationError',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29220',
    }),
  },
  track: {},
  operational: {},
};
