/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import Button from '@atlaskit/button/standard-button';
import ConfirmIcon from '@atlaskit/icon/glyph/check';
import CancelIcon from '@atlaskit/icon/glyph/cross';
import { B400, N0, N20A, N30A, N50A, N60A } from '@atlaskit/theme/colors';
import { fontSize } from './constants';
var buttonsContainerStyles = css({
  display: 'flex',
  marginBlockStart: "var(--ds-space-075, 6px)",
  position: 'absolute',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  insetBlockStart: '100%',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  insetInlineEnd: 0,
  flexShrink: 0
});
var buttonWrapperBaseStyles = css({
  boxSizing: 'border-box',
  width: "var(--ds-space-400, 32px)",
  zIndex: 200,
  backgroundColor: "var(--ds-surface-overlay, ".concat(N0, ")"),
  borderRadius: "var(--ds-border-radius, 3px)",
  fontSize: fontSize,
  '&:last-child': {
    marginInlineStart: "var(--ds-space-050, 4px)"
  },
  boxShadow: "var(--ds-shadow-overlay, ".concat("0 4px 8px -2px ".concat(N50A, ", 0 0 1px ").concat(N60A), ")"),
  // These buttons are floating, so they need an override to overlay interaction states
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > button': {
    backgroundColor: "var(--ds-surface-overlay, ".concat(N20A, ")")
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > button:hover': {
    backgroundColor: "var(--ds-surface-overlay-hovered, ".concat(N30A, ")")
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > button:active': {
    backgroundColor: "var(--ds-surface-overlay-pressed, rgba(179, 212, 255, 0.6))",
    color: "var(--ds-text, ".concat(B400, ")")
  }
});
var Buttons = function Buttons(_ref) {
  var confirmButtonLabel = _ref.confirmButtonLabel,
    cancelButtonLabel = _ref.cancelButtonLabel,
    onMouseDown = _ref.onMouseDown,
    onCancelClick = _ref.onCancelClick;
  return jsx("div", {
    css: buttonsContainerStyles
  }, jsx("div", {
    css: buttonWrapperBaseStyles
  }, jsx(Button, {
    "aria-label": confirmButtonLabel,
    type: "submit",
    iconBefore: jsx(ConfirmIcon, {
      label: confirmButtonLabel,
      size: "small"
    }),
    shouldFitContainer: true,
    onMouseDown: onMouseDown
  })), jsx("div", {
    css: buttonWrapperBaseStyles
  }, jsx(Button, {
    "aria-label": cancelButtonLabel,
    iconBefore: jsx(CancelIcon, {
      label: cancelButtonLabel,
      size: "small"
    }),
    onClick: onCancelClick,
    shouldFitContainer: true,
    onMouseDown: onMouseDown
  })));
};
export default Buttons;