import * as React from 'react';
import type { Subtract } from 'utility-types';

import type { RouteIdContextProps } from './route-id-context';
import { RouteIdContext } from './route-id-context';

export interface SiteNameProps {
  siteName?: string | null;
}

export function withSiteName<TOwnProps extends SiteNameProps>(
  Component: React.ComponentType<TOwnProps>,
): React.ComponentClass<Subtract<TOwnProps, SiteNameProps>> {
  return class extends React.Component<TOwnProps> {
    public render() {
      return (
        <RouteIdContext.Consumer>
          {(value: RouteIdContextProps) => <Component {...(this.props as any)} siteName={value.siteName} />}
        </RouteIdContext.Consumer>
      );
    }
  };
}

export const useSiteName = (): string | undefined | null => {
  const routeInfo = React.useContext(RouteIdContext);

  return routeInfo.siteName;
};
