import { matchPath } from 'react-router-dom';

import { dangerouslyHideApplicationUI } from '@adminhub/utilities/src/application';

import { ROOT_ROUTES } from './async/top-routes';

// eslint-disable-next-line @typescript-eslint/tslint/config, @typescript-eslint/no-floating-promises
(async function preload() {
  const currentURL = window?.location?.pathname;

  // PRE REACTIONS
  // - growth need to hide site admin loading (#SUPERNOVA-267)
  // - Growth ticket (https://hello.atlassian.net/browse/PBAU-45)
  // PC Ticket - https://hello.atlassian.net/browse/PC-12359
  // this is done to smooth out the experience for user
  // the user in growth exp should only see either white screen or opened focusedTask
  if (
    matchPath(currentURL, {
      path: '/s/:cloudId/upflow/paymentdetails',
    })
  ) {
    dangerouslyHideApplicationUI();
  }

  // find top match
  // TODO: what if more that one?
  const topMatch = Object.keys(ROOT_ROUTES).filter((route) =>
    matchPath(currentURL, {
      path: route,
      exact: ROOT_ROUTES[route]?.exact,
    }),
  )[0];

  if (topMatch === undefined) {
    return;
  }
  /* eslint-disable  no-console */

  const blockName = `🚛 preloading ${topMatch}`;
  console.time(blockName);
  try {
    // Loadable has broken definitions
    await (ROOT_ROUTES[topMatch].importer as any).preload();
  } finally {
    console.timeEnd(blockName);
  }

  /* eslint-enable */
})();
