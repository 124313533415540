import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';
import { adminInsightsScreen } from './admin-insights-event-data';
import { membersPageScreen } from './analytics-event-data';

type UIEventKeys =
  | 'learnMoreAboutInsightsLinkClickEvent'
  | 'howDoManagedAccountsLoginToolTipTooltipClickEvent'
  | 'learnMoreAboutKeepingYourOrgSecureLinkClickEvent'
  | 'viewTwoSVEnabledAccountsLinkClickEvent'
  | 'viewTwoSVDisabledAccountsLinkClickEvent'
  | 'viewIdpsLinkClickEvent'
  | 'learnAboutAuthPoliciesLinkClickEvent'
  | 'exploreAtlassianAccessButtonClickEvent'
  | 'viewDomainsButtonClickEvent'
  | 'secureLoginTooltipTooltipClickEvent'
  | 'enforceTwoStepVerificationLinkClickEvent'
  | 'viewAuthPoliciesLinkClickEvent'
  | 'secureLoginPercentageClickEvent'
  | 'secureLoginPercentageHoverEvent';

export const accessSMBInsightsTwoSVEvents: FeatureEvents<string, UIEventKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/SA/pages/2783535080',
  screen: {},
  track: {},
  operational: {},
  ui: {
    learnMoreAboutInsightsLinkClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutInsights',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54410',
    }),
    howDoManagedAccountsLoginToolTipTooltipClickEvent: (attributes: {
      hasAccess: boolean;
      managedAccountTotal: number;
      openedTooltip: boolean;
    }): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'howDoManagedAccountsLoginToolTip',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54411',
    }),
    learnMoreAboutKeepingYourOrgSecureLinkClickEvent: (attributes: { hasAccess: boolean; managedAccountTotal: number }): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutKeepingYourOrgSecure',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54412',
    }),
    viewTwoSVEnabledAccountsLinkClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewTwoSVEnabledAccounts',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54413',
    }),
    viewTwoSVDisabledAccountsLinkClickEvent: (attributes: {}): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewTwoSVDisabledAccounts',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54414',
    }),
    viewIdpsLinkClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewIdps',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54415',
    }),
    learnAboutAuthPoliciesLinkClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnAboutAuthPolicies',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54417',
    }),
    exploreAtlassianAccessButtonClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exploreAtlassianAccess',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54418',
    }),
    viewDomainsButtonClickEvent: (): UIEvent => ({
      data: {
        source: adminInsightsScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewDomains',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34515',
    }),
    secureLoginTooltipTooltipClickEvent: (): UIEvent => ({
      data: {
        source: membersPageScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Tooltip,
        actionSubjectId: 'secureLoginTooltip',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54419',
    }),
    secureLoginPercentageClickEvent: (attributes: { hasAccess: boolean; securedUsersPercentage: number }): UIEvent => ({
      data: {
        source: membersPageScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'secureLoginPercentageClick',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56119',
    }),
    secureLoginPercentageHoverEvent: (attributes: { hasAccess: boolean; securedUsersPercentage: number }): UIEvent => ({
      data: {
        source: membersPageScreen,
        action: Action.Hovered,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'secureLoginPercentageHover',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56075',
    }),
    enforceTwoStepVerificationLinkClickEvent: (): UIEvent => ({
      data: {
        source: membersPageScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'enforceTwoStepVerification',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54510',
    }),
    viewAuthPoliciesLinkClickEvent: (): UIEvent => ({
      data: {
        source: membersPageScreen,
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewAuthPolicies',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/54511',
    }),
  },
};
