import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type ScreenKeys = 'errorPages404ScreenEvent' | 'errorPages500ScreenEvent';
type UiKeys = 'error404GoHomeButton' | 'error500StatusLink' | 'error500SupportLink';

export const errorPagesEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/850320402/Error%2BPages%2B-%2BAnalytics%2BSpec',
  screen: {
    errorPages404ScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'error404Screen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28393',
    }),
    errorPages500ScreenEvent: (): ScreenEvent => ({
      data: {
        name: 'error500Screen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29558',
    }),
  },
  ui: {
    error404GoHomeButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goHome',
        source: 'error404Screen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/28394',
    }),
    error500StatusLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'status',
        source: 'error500Screen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29559',
    }),
    error500SupportLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'support',
        source: 'error500Screen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29560',
    }),
  },
  track: {},
  operational: {},
};
