import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ConnectorType = 'slackWorkspace' | 'googleWorkspace' | 'sharepointDomain' | 'webDomain';
type ScreenKeys =
  | 'thirdPartyConnectModalScreen'
  | 'thirdPartyManageModalScreen'
  | 'thirdPartyDisconnectModalScreen'
  | 'rovoSelectProductStepScreen'
  | 'rovoConnectSuccessScreen'
  | 'rovoConnectSlackScreen'
  | 'rovoConnectGoogleDriveScreen'
  | 'rovoConnectMicrosoftSharepointScreen'
  | 'rovoAddDataSourceScreen'
  | 'rovoConnectCustomWebsiteScreen';

type UIKeys =
  | 'removeGoogleConnector'
  | 'removeSharePointConnector'
  | 'addGoogleConnector'
  | 'addSharePointConnector'
  | 'addSlackConnector'
  | 'addRovoDataSource'
  | 'removeSlackConnector'
  | 'addWebDomainConnector'
  | 'removeWebDomainConnector'
  | 'connectOrManageApp'
  | 'disconnectConnector'
  | 'cancelDisconnect'
  | 'nextRovoSelectProduct'
  | 'backRovoSelectProduct'
  | 'closeRovoSelectProduct';

export const thirdPartyConnectModalScreen = 'thirdPartyConnectModalScreen';
export const thirdPartyManageModalScreen = 'thirdPartyManageModalScreen';
export const thirdPartyConnectScreen = 'thirdPartyConnectScreen';
export const thirdPartyDisconnectModalScreen = 'thirdPartyDisconnectModalScreen';
export const rovoSelectProductStepScreen = 'rovoSelectProductStepScreen';
export const rovoConnectSuccessScreen = 'rovoConnectSuccessScreen';
export const rovoConnectSlackScreen = 'rovoConnectSlackScreen';
export const rovoConnectGoogleDriveScreen = 'rovoConnectGoogleDriveScreen';
export const rovoConnectMicrosoftSharepointScreen = 'rovoConnectMicrosoftSharepointScreen';
export const rovoConnectCustomWebsiteScreen = 'rovoConnectCustomWebsiteScreen';
export const rovoAddDataSourceScreen = 'rovoAddDataSourceScreen';

export const thirdPartyConnectorEvents: FeatureEvents<ScreenKeys, UIKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/ETF/pages/3696480870/Rovo+Admin+Hub+Beta+-+Product+Analytics+Spec',

  screen: {
    thirdPartyConnectModalScreen: (attributes: { source: string; connectorType: ConnectorType }): ScreenEvent => ({
      data: {
        name: thirdPartyConnectModalScreen,
        attributes: {
          integration: attributes.source,
          connectorType: attributes.connectorType,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67294',
    }),
    thirdPartyManageModalScreen: (attributes: { source: string; connectorType: ConnectorType }): ScreenEvent => ({
      data: {
        name: thirdPartyManageModalScreen,
        attributes: {
          integration: attributes.source,
          connectorType: attributes.connectorType,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67295',
    }),
    thirdPartyDisconnectModalScreen: (): ScreenEvent => ({
      data: {
        name: thirdPartyDisconnectModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72359',
    }),
    rovoSelectProductStepScreen: (): ScreenEvent => ({
      data: {
        name: rovoSelectProductStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72360',
    }),
    rovoConnectSuccessScreen: (): ScreenEvent => ({
      data: {
        name: rovoConnectSuccessScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72361',
    }),
    rovoConnectSlackScreen: (): ScreenEvent => ({
      data: {
        name: rovoConnectSlackScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72362',
    }),
    rovoConnectGoogleDriveScreen: (): ScreenEvent => ({
      data: {
        name: rovoConnectGoogleDriveScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72363',
    }),
    rovoConnectMicrosoftSharepointScreen: (): ScreenEvent => ({
      data: {
        name: rovoConnectMicrosoftSharepointScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72364',
    }),
    rovoConnectCustomWebsiteScreen: (): ScreenEvent => ({
      data: {
        name: rovoConnectCustomWebsiteScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72365',
    }),
    rovoAddDataSourceScreen: (): ScreenEvent => ({
      data: {
        name: rovoAddDataSourceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72584',
    }),
  },

  ui: {
    connectOrManageApp: (attributes: { source: string; connectorType: ConnectorType; isConnected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectOrManageApp',
        source: thirdPartyConnectScreen,
        attributes: {
          integration: attributes.source,
          connectorType: attributes.connectorType,
          isConnected: attributes.isConnected,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67260',
    }),
    addGoogleConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGoogleConnector',
        source: thirdPartyConnectModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67276',
    }),
    addSharePointConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addSharePointConnector',
        source: thirdPartyConnectModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67277',
    }),
    addSlackConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addSlackConnector',
        source: thirdPartyConnectModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67278',
    }),
    addRovoDataSource: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addRovoDataSource',
        source: thirdPartyConnectModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72585',
    }),
    addWebDomainConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addWebDomainConnector',
        source: thirdPartyConnectModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69163',
    }),
    removeGoogleConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeGoogleConnector',
        source: thirdPartyManageModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67279',
    }),
    removeSharePointConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeSharePointConnector',
        source: thirdPartyManageModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67280',
    }),
    removeSlackConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeSlackConnector',
        source: thirdPartyManageModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/67281',
    }),
    removeWebDomainConnector: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeWebDomainConnector',
        source: thirdPartyManageModalScreen,
        attributes: {
          integration: attributes.source,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69164',
    }),
    disconnectConnector: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'disconnectConnector',
        source: thirdPartyDisconnectModalScreen,
        attributes: {
          product: attributes.product,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72367',
    }),
    cancelDisconnect: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDisconnect',
        source: thirdPartyDisconnectModalScreen,
        attributes: {
          product: attributes.product,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72368',
    }),
    nextRovoSelectProduct: (attributes: { product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextRovoSelectProduct',
        source: rovoSelectProductStepScreen,
        attributes: {
          product: attributes.product,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72369',
    }),
    backRovoSelectProduct: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backRovoSelectProduct',
        source: rovoSelectProductStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72370',
    }),
    closeRovoSelectProduct: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeRovoSelectProduct',
        source: rovoSelectProductStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72371',
    }),
  },

  track: {
    connectRovoSearchDataSourceSuccess: (attributes: { source: string; connectorType: ConnectorType; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.RovoSearchConnection,
        source: attributes.source,
        attributes: {
          connectorType: attributes.connectorType,
          cloudId: attributes.cloudId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69526',
    }),
    connectRovoSearchDataSourceFailure: (attributes: { source: string; connectorType: ConnectorType; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.CreationFailed,
        actionSubject: TrackActionSubject.RovoSearchConnection,
        source: attributes.source,
        attributes: {
          connectorType: attributes.connectorType,
          cloudId: attributes.cloudId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69527',
    }),
    deleteRovoSearchDataSourceSuccess: (attributes: { source: string; connectorType: ConnectorType; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.RovoSearchConnection,
        source: attributes.source,
        attributes: {
          connectorType: attributes.connectorType,
          cloudId: attributes.cloudId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69900',
    }),
    deleteRovoSearchDataSourceFailure: (attributes: { source: string; connectorType: ConnectorType; cloudId: string }): TrackEvent => ({
      data: {
        action: TrackAction.CanceledDeletion,
        actionSubject: TrackActionSubject.RovoSearchConnection,
        source: attributes.source,
        attributes: {
          connectorType: attributes.connectorType,
          cloudId: attributes.cloudId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69901',
    }),
    disconnectRovo3PConnector: (attributes: { product: string; isSuccess: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.RovoConnector,
        actionSubjectId: 'disconnectRovo3PConnector',
        source: thirdPartyDisconnectModalScreen,
        attributes: {
          product: attributes.product,
          isSuccess: attributes.isSuccess,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72372',
    }),
  },

  operational: {},
};
