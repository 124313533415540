/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { N800 } from '@atlaskit/theme/colors';
var descriptionStyles = css({
  marginTop: "var(--ds-space-0, 0px)",
  marginBottom: "var(--ds-space-300, 24px)",
  color: "var(--ds-text, ".concat(N800, ")")
});

/**
 * __Description__
 *
 * Description of Empty State.
 *
 * @internal
 */
var Description = function Description(_ref) {
  var children = _ref.children;
  return jsx("p", {
    css: descriptionStyles
  }, children);
};
export default Description;