/** @jsx jsx */

import { css, jsx } from '@emotion/react';
var actionsStyles = css({
  display: 'flex',
  marginBottom: "var(--ds-space-100, 8px)",
  paddingLeft: "var(--ds-space-500, 40px)",
  alignItems: 'center',
  justifyContent: 'center'
});

/**
 * __Actions container__
 *
 * A container for actions: primary action, secondary action, and tertiary action.
 *
 * @internal
 */
var ActionsContainer = function ActionsContainer(_ref) {
  var children = _ref.children;
  return jsx("div", {
    css: actionsStyles
  }, children);
};
export default ActionsContainer;