/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { h600 } from '@atlaskit/theme/typography';
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var headerStyles = css([h600(), {
  marginTop: "var(--ds-space-0, 0px)",
  marginBottom: "var(--ds-space-200, 16px)"
}]);

/**
 * __Header__
 *
 * Header of Empty State.
 *
 * @internal
 */
var EmptyStateHeader = function EmptyStateHeader(_ref) {
  var children = _ref.children,
    _ref$level = _ref.level,
    level = _ref$level === void 0 ? 4 : _ref$level;
  var Tag = "h".concat(level > 0 && level < 7 ? level : level > 6 ? 6 : 4);
  return jsx(Tag, {
    css: headerStyles
  }, children);
};
export default EmptyStateHeader;