import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'externalUsersSecurityScreenEvent'
  | 'externalUsersSecurityListScreenEvent'
  | 'updateExternalUserPolicyTestUsersFocusedTaskEvent'
  | 'updateExternalUserApiTokenPolicyFocusedTaskEvent'
  | 'updateExternalUserPolicySessionDurationFocusedTaskScreenEvent';
type UIEventKeys =
  | 'externalUserSecurityFeedbackButton'
  | 'externalUserSecurityFeedbackModalLink'
  | 'exportExternalUsersButtonEvent'
  | 'confirmExternalUsersExportEvent'
  | 'cancelExternalUsersExportEvent'
  | 'updatePolicyButtonEvent'
  | 'updatePolicyConfirmButtonEvent'
  | 'updatePolicyCancelButtonEvent'
  | 'learnMoreLinkEvent'
  | 'reviewAuditLogButtonEvent'
  | 'addExternalUserPolicyEvent'
  | 'confirmAddExternalUserTestPolicyEvent'
  | 'cancelAddExternalUserPolicyEvent'
  | 'dismissEvent'
  | 'updateExternalUserPolicyTestUsersConfigCardEvent'
  | 'confirmUpdateExternalUserPolicyTestUsersEvent'
  | 'cancelUpdateExternalUserPolicyTestUsersEvent'
  | 'viewPolicyClickEvent'
  | 'updateExternalUserApiTokenPolicyConfigCardEvent'
  | 'updateExternalUserApiTokenPolicyButtonEvent'
  | 'updateExternalUserApiTokenPolicyCancelButtonEvent'
  | 'confirmExternalUsersResetSessionsEvent'
  | 'cancelExternalUsersResetSessionsEvent'
  | 'confirmNextExternalUserPolicySessionDuration'
  | 'confirmUpdateExternalUserPolicySessionDuration'
  | 'cancelUpdateExternalUsersSessionDurationEvent';

type TrackEventKeys =
  | 'createdExternalUserPolicyTrackEvent'
  | 'reactivatedExternalUserPolicyTrackEvent'
  | 'deactivatedExternalUserPolicyTrackEvent'
  | 'updatedExternalUserApiTokenPolicyTrackEvent'
  | 'updatedExternalUserTestPolicyUsersTrackEvent'
  | 'externalUsersResetSessionsTrackEvent'
  | 'updatedExternalUserSessionDurationTrackEvent';

export enum ExternalUserSecurityScreens {
  externalUsersExportModal = 'externalUsersExportModal',
  activateExternalUserPolicyModal = 'activateExternalUserPolicyModal',
  deactivateExternalUserPolicyModal = 'deactivateExternalUserPolicyModal',
  externalUsersSecurityScreen = 'externalUsersSecurityScreen',
  externalUserSecurityListScreen = 'externalUserSecurityListScreen',
  updateExternalUserPolicyModal = 'updateExternalUserPolicyModal',
  updateExternalUserPolicyTestUsersFocusedTask = 'updateExternalUserPolicyTestUsersFocusedTask',
  updateExternalUserApiTokenPolicyFocusedTask = 'updateExternalUserApiTokenPolicyFocusedTask',
  addExternalUserPolicyModal = 'addExternalUserPolicyModal',
  externalUserSecurityTestUserErrorMessage = 'externalUserSecurityTestUserErrorMessage',
  externalUserPolicyResetSessionsModal = 'externalUserPolicyResetSessionsModal',
  updateExternalUserPolicySessionDurationFocusedTask = 'updateExternalUserPolicySessionDurationFocusedTask',
}

type PolicyActionScope = 'org' | 'policy';

export const externalUserSecurityEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: [
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1603408649/EUS+MVP+Analytics+spec',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2106101719/EUS+M1.1+Analytics+spec',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2808053990/EUS+M1.2+Analytics+spec',
  ],
  screen: {
    externalUsersSecurityScreenEvent: (attributes: { isPolicyEnabled: boolean; policyScope: string; policyId?: string }): ScreenEvent => ({
      data: {
        name: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47927',
    }),
    externalUsersSecurityListScreenEvent: (attributes: { numTestUsers: boolean }): ScreenEvent => ({
      data: {
        name: ExternalUserSecurityScreens.externalUserSecurityListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49018',
    }),
    updateExternalUserPolicyTestUsersFocusedTaskEvent: (attributes: { numTestUsers: boolean }): ScreenEvent => ({
      data: {
        name: ExternalUserSecurityScreens.updateExternalUserPolicyTestUsersFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49019',
    }),
    updateExternalUserApiTokenPolicyFocusedTaskEvent: (attributes: { apiTokenAccessMode?: boolean; policyId?: string }): ScreenEvent => ({
      data: {
        name: ExternalUserSecurityScreens.updateExternalUserApiTokenPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51511',
    }),
    updateExternalUserPolicySessionDurationFocusedTaskScreenEvent: (): ScreenEvent => ({
      data: {
        name: ExternalUserSecurityScreens.updateExternalUserPolicySessionDurationFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55668',
    }),
  },
  ui: {
    externalUserSecurityFeedbackButton: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedbackButton',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    externalUserSecurityFeedbackModalLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'feedbackLink',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40232',
    }),
    exportExternalUsersButtonEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportExternalUsers',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47929',
    }),
    confirmExternalUsersExportEvent: (attributes: { numUsers: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmExternalUsersExport',
        source: ExternalUserSecurityScreens.externalUsersExportModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47935',
    }),
    cancelExternalUsersExportEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: ExternalUserSecurityScreens.externalUsersExportModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    updatePolicyButtonEvent: (attributes: {
      isActivating: boolean;
      policyScope: string;
      policyId?: string;
      numUsers?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateExternalUserPolicy',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47930',
    }),
    updatePolicyConfirmButtonEvent: (attributes: {
      isActivating: boolean;
      policyScope: string;
      policyId?: string;
      numUsers?: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmExternalUserPolicyUpdate',
        source: ExternalUserSecurityScreens.updateExternalUserPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47931',
    }),
    updatePolicyCancelButtonEvent: (attributes: { isActivating: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: ExternalUserSecurityScreens.updateExternalUserPolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    updateExternalUserApiTokenPolicyButtonEvent: (attributes: {
      policyId?: string;
      numUsers?: number;
      apiTokenPolicyMode?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateExternalUserApiTokenPolicy',
        source: ExternalUserSecurityScreens.updateExternalUserApiTokenPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51509',
    }),
    updateExternalUserApiTokenPolicyConfigCardEvent: (attributes: {
      policyId?: string;
      numUsers?: number;
      isPolicyEnabled: boolean;
      apiTokenPolicyMode?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'updateExternalUserApiTokenPolicyConfigCard',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51512',
    }),
    updateExternalUserApiTokenPolicyCancelButtonEvent: (attributes: {
      policyId?: string;
      numUsers?: number;
      isPolicyEnabled: boolean;
      apiTokenPolicyMode?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateExternalUserApiTokenPolicyCancel',
        source: ExternalUserSecurityScreens.updateExternalUserApiTokenPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51510',
    }),
    confirmExternalUsersResetSessionsEvent: (attributes: { scope: PolicyActionScope; id: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmExternalUsersResetSessionsEvent',
        source: ExternalUserSecurityScreens.externalUserPolicyResetSessionsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55640',
    }),
    cancelExternalUsersResetSessionsEvent: (attributes: { scope: PolicyActionScope; id: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: ExternalUserSecurityScreens.externalUserPolicyResetSessionsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    learnMoreLinkEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    reviewAuditLogButtonEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'reviewAuditLog',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44752',
    }),
    addExternalUserPolicyEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addExternalUserPolicy',
        source: ExternalUserSecurityScreens.externalUserSecurityListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49059',
    }),
    confirmAddExternalUserTestPolicyEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmAddExternalUserTestPolicy',
        source: ExternalUserSecurityScreens.addExternalUserPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49060',
    }),
    cancelAddExternalUserPolicyEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelAddExternalUserPolicy',
        source: ExternalUserSecurityScreens.addExternalUserPolicyModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49061',
    }),
    dismissEvent: (attributes: { policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismiss',
        source: ExternalUserSecurityScreens.externalUserSecurityTestUserErrorMessage,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49057',
    }),
    updateExternalUserPolicyTestUsersConfigCardEvent: (attributes: { policyId: string; isPolicyEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'updateExternalUserPolicyTestUsersConfigCard',
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49053',
    }),
    confirmUpdateExternalUserPolicyTestUsersEvent: (attributes: { policyId: string; numTestUsers: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmUpdateExternalUserPolicyTestUsers',
        source: ExternalUserSecurityScreens.updateExternalUserPolicyTestUsersFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49055',
    }),
    cancelUpdateExternalUserPolicyTestUsersEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelUpdateExternalUserPolicyTestUsers',
        source: ExternalUserSecurityScreens.updateExternalUserPolicyTestUsersFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49054',
    }),
    viewPolicyClickEvent: (attributes: { policyId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'externalUserPolicyName',
        source: ExternalUserSecurityScreens.externalUserSecurityListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49058',
    }),
    confirmNextExternalUserPolicySessionDuration: (attributes: {
      stepUpSessionDurationMinutes: number;
      policyId?: string;
      isPolicyEnabled: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmNextExternalUserPolicySessionDuration',
        source: ExternalUserSecurityScreens.updateExternalUserPolicySessionDurationFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55669',
    }),
    confirmUpdateExternalUserPolicySessionDuration: (attributes: {
      stepUpSessionDurationMinutes: number;
      policyId?: string;
      isPolicyEnabled: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmUpdateExternalUserPolicySessionDuration',
        source: ExternalUserSecurityScreens.updateExternalUserPolicySessionDurationFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55670',
    }),
    cancelUpdateExternalUsersSessionDurationEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: ExternalUserSecurityScreens.updateExternalUserPolicySessionDurationFocusedTask,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
  },
  track: {
    createdExternalUserPolicyTrackEvent: (attributes: { numUsers?: number; policyScope: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47934',
    }),
    reactivatedExternalUserPolicyTrackEvent: (attributes: { numUsers?: number; policyScope: string; policyId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Reactivated,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47933',
    }),
    deactivatedExternalUserPolicyTrackEvent: (attributes: { numUsers?: number; policyScope: string; policyId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deactivated,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.externalUsersSecurityScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47932',
    }),
    updatedExternalUserApiTokenPolicyTrackEvent: (attributes: {
      numUsers?: number;
      policyId: string;
      policyScope: string;
      apiTokenAccessMode?: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.updateExternalUserApiTokenPolicyFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51572',
    }),
    updatedExternalUserTestPolicyUsersTrackEvent: (attributes: {
      numTestUsers: number;
      policyId: string;
      isPolicyEnabled: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.UsersUpdated,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.updateExternalUserPolicyTestUsersFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49082',
    }),
    externalUsersResetSessionsTrackEvent: (attributes: { scope: PolicyActionScope; id: string }): TrackEvent => ({
      data: {
        action: TrackAction.SessionReset,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.externalUserPolicyResetSessionsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55641',
    }),
    updatedExternalUserSessionDurationTrackEvent: (attributes: {
      stepUpSessionDurationMinutes: number;
      policyScope: string;
      policyId?: string;
      isPolicyEnabled: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.ExternalUserPolicy,
        source: ExternalUserSecurityScreens.updateExternalUserPolicySessionDurationFocusedTask,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51572',
    }),
  },
  operational: {},
};
