import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

export const adminInsightsScreen = 'adminInsightsScreen';

export interface adminInsightsScreenEventAttributes {
  isAccessEnabled: boolean;
  noSites?: boolean;
  numberOfSites?: number;
  isConfluencePremium?: boolean;
  isJsdPremium?: boolean;
  isJswPremium?: boolean;
  hasTrelloData?: boolean;
  hasClaimedUsers?: boolean;
  hasDomainsClaimed?: boolean;
  hasExternalUserPolicy?: boolean;
}

export type ScreenKeys = 'adminInsightsScreenEvent';
type UiKeys =
  | 'insightsActiveUsersChart'
  | 'insightProductDropdownItem'
  | 'insightTimeDropdownItem'
  | 'insightsNoProductsLearnMore'
  | 'insightsError'
  | 'insightsFeedbackButton'
  | 'insightsFeedbackModalLink'
  | 'insightsIncreaseAdoptionTips'
  | 'insightsActiveUsersCardShown';

export const adminInsightsEvents: FeatureEvents<ScreenKeys, UiKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/522032691/Admin+Insights+Analytics+Spec',
  screen: {
    adminInsightsScreenEvent: (attributes: adminInsightsScreenEventAttributes): ScreenEvent => ({
      data: {
        name: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18556',
    }),
  },
  ui: {
    insightsActiveUsersChart: (): UIEvent => ({
      data: {
        action: Action.Hovered,
        actionSubject: ActionSubject.Chart,
        actionSubjectId: 'activeUsers',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18660',
    }),
    insightProductDropdownItem: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'productDropdownItem',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19775',
    }),
    insightTimeDropdownItem: (attributes: { timePeriod: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'timeDropdownItem',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20245',
    }),
    insightsNoProductsLearnMore: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'noProductsLearnMore',
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18563',
    }),
    insightsError: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Error,
        source: adminInsightsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18762',
    }),
    insightsFeedbackButton: (attributes: { feedback: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'feedback',
        source: 'insightsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18566',
    }),
    insightsFeedbackModalLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'leaveFeedbackDialog',
        source: 'insightsScreen',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18567',
    }),
    insightsIncreaseAdoptionTips: (attributes: { activeUsercount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'increaseAdoptionTips',
        source: adminInsightsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18568',
    }),
    insightsActiveUsersCardShown: (attributes: { selectedProducts: string[] }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Card,
        actionSubjectId: 'activeUsers',
        source: 'insightsScreen',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19667',
    }),
  },
  track: {},
  operational: {},
};
