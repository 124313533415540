import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackActionSubject } from '../new-analytics-types';
import { HELP_ITEM_EVENT_ID } from './navigation-event-data';

export const iphEvents: FeatureEvents<string, string, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/~722414669/pages/4152853924/IPH+Analytics',
  ui: {
    iphSideBarOpened: (attributes: { routeName?: string; routeGroup?: string }): UIEvent => ({
      data: {
        action: Action.Viewed,
        actionSubjectId: 'iph',
        actionSubject: ActionSubject.IphSideBar,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onCloseButtonClick: (attributes: { routeName?: string; routeGroup?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'closeButton',
        actionSubject: ActionSubject.Button,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onBackButtonClick: (attributes: { routeName?: string; routeGroup?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'backButton',
        actionSubject: ActionSubject.Button,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onSearchInputChanged: (attributes: { query: string }): UIEvent => ({
      data: {
        action: Action.Changed,
        actionSubjectId: 'searchInput',
        actionSubject: ActionSubject.Input,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onSearchInputCleared: (): UIEvent => ({
      data: {
        action: Action.Cleared,
        actionSubjectId: 'searchInput',
        actionSubject: ActionSubject.Input,
        source: 'iphScreen',
        tags: ['iph'],
      },
    }),
    onSearchResultItemClick: (attributes: { itemId: string; rank: number; queryId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'searchResultItem',
        actionSubject: ActionSubject.SearchResult,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onSearchExternalUrlClick: (attributes: { routeName: string; routeGroup: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'externalUrl',
        actionSubject: ActionSubject.Link,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onWhatsNewButtonClick: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'whatsNewButton',
        actionSubject: ActionSubject.Button,
        source: 'iphScreen',
        tags: ['iph'],
      },
    }),
    onWasHelpfulSubmit: (attributes: { wasHelpful: boolean }): UIEvent => ({
      data: {
        action: Action.Submitted,
        actionSubjectId: 'wasHelpful',
        actionSubject: ActionSubject.Form,
        source: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
    }),
    onWasHelpfulYesButtonClick: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'wasHelpfulYesButton',
        actionSubject: ActionSubject.Button,
        source: 'iphScreen',
        tags: ['iph'],
      },
    }),
    onWasHelpfulNoButtonClick: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'wasHelpfulNoButton',
        actionSubject: ActionSubject.Button,
        source: 'iphScreen',
        tags: ['iph'],
      },
    }),
  },
  screen: {
    iphScreenEvent: (attributes: { routeName?: string; routeGroup?: string }): ScreenEvent => ({
      data: {
        name: 'iphScreen',
        attributes,
        tags: ['iph'],
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71416',
    }),
  },
  track: {
    searchedArticlesFetched: (attributes: { queryId: string }): TrackEvent => ({
      data: {
        source: 'iph',
        action: Action.Searched,
        actionSubject: TrackActionSubject.SearchResults,
        actionSubjectId: 'searchedArticlesFetched',
        attributes,
        tags: ['iph'],
      },
    }),
    relatedArticlesFetched: (): TrackEvent => ({
      data: {
        source: 'iph',
        action: Action.Fetch,
        actionSubject: TrackActionSubject.RelatedArticles,
        actionSubjectId: 'relatedArticlesFetched',
        tags: ['iph'],
      },
    }),
    articleFetched: (attributes: { articleId: string }): TrackEvent => ({
      data: {
        source: 'iph',
        action: Action.Fetch,
        actionSubject: TrackActionSubject.RelatedArticles,
        actionSubjectId: 'relatedArticlesFetched',
        tags: ['iph'],
        attributes,
      },
    }),
    supportLinkClicked: (): TrackEvent => ({
      data: {
        source: 'iph',
        action: Action.Clicked,
        actionSubject: TrackActionSubject.ContactSupport,
        actionSubjectId: 'supportLinkClicked',
        tags: ['iph'],
        attributes: { itemId: HELP_ITEM_EVENT_ID.CONTACT_SUPPORT },
      },
    }),
  },
  operational: {
    iphSearchError: (attributes: { error: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubjectId: 'iph',
        actionSubject: OperationalSubject.SearchError,
        source: 'iph',
        attributes,
        tags: ['iph'],
      },
    }),
    iphRelatedArticlesError: (attributes: { error: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubjectId: 'iph',
        actionSubject: OperationalSubject.RelatedArticlesError,
        source: 'iph',
        attributes,
        tags: ['iph'],
      },
    }),
    iphArticleError: (attributes: { error: string; id: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubjectId: 'iph',
        actionSubject: OperationalSubject.ArticleFetchError,
        source: 'iph',
        attributes,
        tags: ['iph'],
      },
    }),
  },
};
