import { config } from '../data/product-config-json';
import type { ProductConfigMapJson } from './product-config-schema';
import { ProductConfigMapJsonSchema } from './product-config-schema';

export function loadProductConfiguration(): ProductConfigMapJson {
  return ProductConfigMapJsonSchema.parse(config);
}

export * from './product-config-schema';
export * from './product-config-types';
