/** @jsx jsx */

import { css, jsx } from '@emotion/react';

// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize as getGridSize } from '@atlaskit/theme/constants';
var gridSize = getGridSize();
var verticalMarginSize = "var(--ds-space-600, 48px)";
var columnWidth = gridSize * 8;
var gutter = gridSize * 2;
var containerStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  margin: "".concat(verticalMarginSize, " auto"),
  textAlign: 'center'
});

/* Use max-width so the component can shrink on smaller viewports. */
var wideContainerStyles = css({
  maxWidth: "".concat(columnWidth * 6 + gutter * 5, "px")
});
var narrowContainerStyles = css({
  maxWidth: "".concat(columnWidth * 4 + gutter * 3, "px")
});
/**
 * __Container__
 *
 * Upper level container for Empty State.
 *
 * @internal
 */
var Container = function Container(_ref) {
  var children = _ref.children,
    width = _ref.width,
    testId = _ref.testId;
  return jsx("div", {
    "data-testid": testId,
    css: [containerStyles, width === 'narrow' ? narrowContainerStyles : wideContainerStyles]
  }, children);
};
export default Container;