import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

import type { FocusedTaskUIEventMeta } from './analytics-event-data';

type ScreenKeys =
  | 'applicationTunnelsScreenEvent'
  | 'createTunnelScreenEvent'
  | 'copySecureKeyScreenEvent'
  | 'regenerateKeyScreenEvent'
  | 'copyRegeneratedKeyScreenEvent';

type UIEventKeys =
  | 'learnMoreLinkClickEvent'
  | 'createTunnelDrawerOpenClickEvent'
  | 'createTunnelClickEvent'
  | 'copyAndProceedToOnPremiseUrlClickEvent'
  | 'deleteTunnelClickEvent'
  | 'regenerateTunnelKeyClickEvent'
  | 'copyRegeneratedKeyAndProceedToOnPremiseUrlClickEvent';

type TrackEventKeys = 'createdTrackEvent' | 'deletedTrackEvent' | 'regenerateKeyTrackEvent';

export const applicationTunnelsScreen = 'applicationTunnelsScreen';
export const createTunnelScreen = 'createTunnelScreen';
export const copySecureKeyScreen = 'copySecureKeyScreen';
export const regenerateKeyScreen = 'regenerateKeyScreen';
export const copyRegeneratedKeyScreen = 'copyRegeneratedKeyScreen';

export const secureTunnelsEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/XDEP/pages/1094489460/GSM+Application+Tunnelling',
  screen: {
    applicationTunnelsScreenEvent: (): ScreenEvent => ({
      data: {
        name: applicationTunnelsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39609',
    }),
    createTunnelScreenEvent: (): ScreenEvent => ({
      data: {
        name: createTunnelScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39612',
    }),
    copySecureKeyScreenEvent: (): ScreenEvent => ({
      data: {
        name: copySecureKeyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39616',
    }),
    regenerateKeyScreenEvent: (): ScreenEvent => ({
      data: {
        name: regenerateKeyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42505',
    }),
    copyRegeneratedKeyScreenEvent: (): ScreenEvent => ({
      data: {
        name: copyRegeneratedKeyScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42506',
    }),
  },
  ui: {
    learnMoreLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreLink',
        source: applicationTunnelsScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    createTunnelDrawerOpenClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createTunnelDrawerOpen',
        source: applicationTunnelsScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39611',
    }),
    createTunnelClickEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'createTunnel',
        source: createTunnelScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39614',
    }),
    copyAndProceedToOnPremiseUrlClickEvent: (attributes: { orgId: string; tunnelId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'copyAndProceedToOnPremiseUrl',
        source: copySecureKeyScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39617',
    }),
    deleteTunnelClickEvent: (attributes: { orgId: string; tunnelId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'deleteTunnel',
        source: applicationTunnelsScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39618',
    }),
    regenerateTunnelKeyClickEvent: (attributes: { orgId: string; tunnelId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'regenerateTunnelKey',
        source: regenerateKeyScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42507',
    }),
    copyRegeneratedKeyAndProceedToOnPremiseUrlClickEvent: (attributes: { orgId: string; tunnelId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'copyRegeneratedKeyAndProceedToOnPremiseUrl',
        source: copyRegeneratedKeyScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42508',
    }),
  },
  track: {
    createdTrackEvent: (attributes: { orgId: string; tunnelId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.ApplicationTunnel,
        source: createTunnelScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39624',
    }),
    deletedTrackEvent: (attributes: { orgId: string; tunnelId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.ApplicationTunnel,
        source: applicationTunnelsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39625',
    }),
    regenerateKeyTrackEvent: (attributes: { orgId: string; tunnelId: string }): TrackEvent => ({
      data: {
        action: TrackAction.KeyRegenerated,
        actionSubject: TrackActionSubject.ApplicationTunnel,
        source: regenerateKeyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/42509',
    }),
  },
  operational: {},
};

export const secureTunnelsDrawerEventMeta: FocusedTaskUIEventMeta = {
  data: {
    source: applicationTunnelsScreen,
  },
};
