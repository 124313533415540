import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

import type { FocusedTaskUIEventMeta } from './analytics-event-data';

type ScreenKeys =
  | 'orgGSuiteScreenEvent'
  | 'orgGSuiteConnectScreenEvent'
  | 'orgGSuiteConfirmScreenEvent'
  | 'disconnectGSuiteModalEvent'
  | 'orgGSuiteSetupScreenEvent'
  | 'orgGSuiteSettingsScreenEvent'
  | 'orgGSuiteSettingsConfirmModalEvent';

type UIEventKeys =
  | 'orgGSuiteClickEvent'
  | 'setupOrgGSuiteClickEvent'
  | 'learnMoreLinkClickEvent'
  | 'connectOrgGSuiteClickEvent'
  | 'selectUsersBackClickEvent'
  | 'selectUsersNextClickEvent'
  | 'confirmBackClickEvent'
  | 'startOrgGSuiteSyncClickEvent'
  | 'viewAuditLogClickEvent'
  | 'orgGSuiteSyncNowButtonClickEvent'
  | 'manageUsersClickEvent'
  | 'editGroupsClickEvent'
  | 'disconnectOrgGSuiteAccountClickEvent'
  | 'disconnectOrgGSuiteAccountConfirmClickEvent'
  | 'saveOrgGSuiteGroupsClickEvent'
  | 'editOrgGSuiteGroupsClickEvent'
  | 'orgGSuiteSyncNowLinkClickEvent'
  | 'orgGSuiteError'
  | 'learnMoreOrgGSuiteClickEvent'
  | 'removeOrgGSuiteDomainClickEvent'
  | 'domainsGoToGSuiteClickEvent'
  | 'cannotRemoveGoToGSuiteClickEvent'
  | 'importUsersNextClickEvent'
  | 'connectDifferentOrgClickEvent'
  | 'closeUnableToConnectClickEvent'
  | 'learnMoreGSuiteSetupClickEvent'
  | 'startSyncClickEvent'
  | 'dismissWelcomeNotificationClickEvent'
  | 'startSyncUsersClickEvent'
  | 'cancelStartSyncUsersClickEvent'
  | 'dismissSyncInProgressNotificationClickEvent'
  | 'reviewOrgGSuiteGroupsNotificationClickEvent'
  | 'reviewOrgGSuiteGroupsInStatusClickEvent'
  | 'dismissSyncCompletedNotificationClickEvent'
  | 'learnMoreGSuiteStatusClickEvent'
  | 'editOrgGSuiteSettingsClickEvent'
  | 'updateOrgGSuiteGroupClickEvent'
  | 'saveOrgGSuiteSettingsClickEvent'
  | 'saveAndSyncLaterOrgGSuiteSettingsClickEvent'
  | 'syncNowClickEvent'
  | 'dismissSettingsSavedNotificationClickEvent'
  | 'cancelGSuiteSettingsClickEvent'
  | 'disconnectOrgGSuiteAccountInSettingsClickEvent'
  | 'confirmDisconnecingtOrgGSuiteAccountClickEvent'
  | 'orgGSuiteActionRequiredBeforeNextSyncSettingsError'
  | 'orgGSuiteActionRequiredBeforeNextSyncStatusError'
  | 'orgGSuiteSavedSettingsNotificationShownEvent'
  | 'orgGSuiteSyncInProgressStatusNotificationShownEvent'
  | 'orgGSuiteSyncCompleteStatusNotificationShownEvent'
  | 'atlassianAccessEnabledNotificationShownEvent'
  | 'orgGsuiteDisconnectedShownEvent';

type TrackEventKeys =
  | 'connectedTrackEvent'
  | 'syncStartedTrackEvent'
  | 'syncFinishedTrackEvent'
  | 'disconnectedTrackEvent'
  | 'settingsUpdatedTrackEvent';

export type orgGSuiteSyncType = 'syncAllUsers' | 'syncSpecificGroups';
export type orgGSuiteSyncMethod = 'manualSync' | 'connectSync';
export type orgGSuiteSyncSource = 'orgGSuiteConfirmScreen' | 'orgGSuiteScreen';

export const orgGSuiteScreen = 'orgGSuiteScreen';
export const siteGSuiteScreen = 'siteGSuiteScreen';
export const orgGSuiteConnectScreen = 'orgGSuiteConnectScreen';
export const siteGSuiteConnectScreen = 'siteGSuiteConnectScreen';
export const orgGSuiteSelectUsersScreen = 'orgGSuiteSelectUsersScreen';
export const siteGSuiteSelectUsersScreen = 'siteGSuiteSelectUsersScreen';
export const orgGSuiteConfirmScreen = 'orgGSuiteConfirmScreen';
export const siteGSuiteConfirmScreen = 'siteGSuiteConfirmScreen';
export const orgGSuiteEditUsersModal = 'orgGSuiteEditUsersModal';
export const disconnectGSuiteModal = 'disconnectGSuiteModal';
export const adminNavigation = 'adminNavigation';
export const domainsScreen = 'domainsScreen';
export const cannotRemoveGSuiteModal = 'cannotRemoveGSuiteModal';
export const orgGSuiteSetupScreen = 'orgGSuiteSetupScreen';
export const orgGSuiteSettingsScreen = 'orgGSuiteSettingsScreen';
export const orgGSuiteSettingsConfirmModal = 'orgGSuiteSettingsConfirmModal';
export const atlassianAccessNotification = 'atlassianAccessNotification';
export const orgGSuiteSyncInProgressStatusNotification = 'orgGSuiteSyncInProgressStatusNotification';
export const orgGSuiteSyncCompleteStatusNotification = 'orgGSuiteSyncCompleteStatusNotification';
export const orgGSuiteSavedSettingsNotification = 'orgGSuiteSavedSettingsNotification';
export const orgGSuiteStatusScreen = 'orgGSuiteStatusScreen';
export const userListScreen = 'userListScreen';
export const groupsScreen = 'groupsScreen';
export const siteGsuiteScreen = 'siteGsuiteScreen';
export const managedAccountsScreen = 'managedAccountsScreen';
export const productAccessScreen = 'productAccessScreen';

export const orgGSuiteEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/590293654/M0+Spec+Analytic+Events',
  screen: {
    orgGSuiteScreenEvent: (attributes: { connected: boolean }): ScreenEvent => ({
      data: {
        name: orgGSuiteScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20199',
    }),
    orgGSuiteConnectScreenEvent: (attributes: { connected: boolean }): ScreenEvent => ({
      data: {
        name: orgGSuiteConnectScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20200',
    }),
    orgGSuiteConfirmScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgGSuiteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20202',
    }),
    disconnectGSuiteModalEvent: (): ScreenEvent => ({
      data: {
        name: disconnectGSuiteModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20204',
    }),
    orgGSuiteSetupScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgGSuiteSetupScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25789',
    }),
    orgGSuiteSettingsScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgGSuiteSettingsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26352',
    }),
    orgGSuiteSettingsConfirmModalEvent: (): ScreenEvent => ({
      data: {
        name: orgGSuiteSettingsConfirmModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26353',
    }),
  },
  ui: {
    orgGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'orgGSuite',
        source: adminNavigation,
        actionSubject: ActionSubject.NavigationItem,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20205',
    }),
    setupOrgGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'setupOrgGSuite',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20206',
    }),
    learnMoreLinkClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreLink',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    connectOrgGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'connectOrgGSuite',
        source: orgGSuiteConnectScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20207',
    }),
    selectUsersBackClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'backButton',
        source: orgGSuiteSelectUsersScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15325',
    }),
    selectUsersNextClickEvent: (attributes: { syncType: orgGSuiteSyncType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'nextButton',
        source: orgGSuiteSelectUsersScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15319',
    }),
    confirmBackClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'backButton',
        source: orgGSuiteConfirmScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15325',
    }),
    startOrgGSuiteSyncClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'startOrgGSuiteSync',
        source: orgGSuiteConfirmScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20208',
    }),
    viewAuditLogClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'viewAuditLog',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20209',
    }),
    orgGSuiteSyncNowButtonClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'orgGSuiteSyncNow',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20210',
    }),
    manageUsersClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'manageUsers',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20211',
    }),
    editGroupsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'editGroups',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20212',
    }),
    disconnectOrgGSuiteAccountClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'disconnectOrgGSuiteAccount',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20213',
    }),
    disconnectOrgGSuiteAccountConfirmClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'disconnectOrgGSuiteAccountConfirm',
        source: disconnectGSuiteModal,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20214',
    }),
    saveOrgGSuiteGroupsClickEvent: (attributes: { syncType: orgGSuiteSyncType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'saveOrgGSuiteGroups',
        source: orgGSuiteEditUsersModal,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20215',
    }),
    editOrgGSuiteGroupsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'editOrgGSuiteGroups',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20216',
    }),
    orgGSuiteSyncNowLinkClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'orgGSuiteSyncNow',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20217',
    }),
    orgGSuiteError: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Error,
        source: orgGSuiteScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20218',
    }),
    learnMoreOrgGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreOrgGSuite',
        source: domainsScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20219',
    }),
    domainsGoToGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'goToGSuite',
        source: domainsScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20226',
    }),
    removeOrgGSuiteDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'removeOrgGSuiteDomain',
        source: domainsScreen,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20227',
    }),
    cannotRemoveGoToGSuiteClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'goToGSuite',
        source: cannotRemoveGSuiteModal,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20226',
    }),
    importUsersNextClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'nextButton',
        source: orgGSuiteConnectScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15319',
    }),
    connectDifferentOrgClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'connectToADifferentOrg',
        source: orgGSuiteConnectScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26394',
    }),
    closeUnableToConnectClickEvent: (attributes: { connected: boolean; reason: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'closeButton',
        source: orgGSuiteConnectScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15449',
    }),
    learnMoreGSuiteSetupClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreOrgGSuite',
        source: orgGSuiteSetupScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20219',
    }),
    startSyncClickEvent: (attributes: {
      connected: boolean;
      syncUsers: 'all' | 'group';
      sendEmailInvites: boolean;
      googleAuthOnly: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'startSyncOrgGSuite',
        source: orgGSuiteSetupScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26395',
    }),
    dismissWelcomeNotificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'dismissButton',
        source: atlassianAccessNotification,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18755',
    }),
    startSyncUsersClickEvent: (attributes: {
      connected: boolean;
      syncUsers: 'all' | 'group';
      sendEmailInvites: boolean;
      googleAuthOnly: boolean;
      domainsVerified: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'startSync',
        source: orgGSuiteSettingsConfirmModal,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26396',
    }),
    cancelStartSyncUsersClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelSync',
        source: orgGSuiteSettingsConfirmModal,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26397',
    }),
    dismissSyncInProgressNotificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'dismissButton',
        source: orgGSuiteSyncInProgressStatusNotification,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18755',
    }),
    reviewOrgGSuiteGroupsNotificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'reviewOrgGSuiteGroups',
        source: orgGSuiteSyncCompleteStatusNotification,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26398',
    }),
    reviewOrgGSuiteGroupsInStatusClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'reviewOrgGSuiteGroups',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26398',
    }),
    dismissSyncCompletedNotificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'dismissButton',
        source: orgGSuiteSyncCompleteStatusNotification,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18755',
    }),
    learnMoreGSuiteStatusClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreOrgGSuite',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20219',
    }),
    editOrgGSuiteSettingsClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'editOrgGSuiteSettings',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26399',
    }),
    updateOrgGSuiteGroupClickEvent: (attributes: { connected: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'updateOrgGSuiteGroup',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.Link,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26400',
    }),
    saveOrgGSuiteSettingsClickEvent: (attributes: {
      enableSync: boolean;
      syncUsers: 'all' | 'group';
      sendEmailInvites: boolean;
      googleAuthOnly: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'saveOrgGSuiteSettings',
        source: orgGSuiteSettingsScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26401',
    }),
    saveAndSyncLaterOrgGSuiteSettingsClickEvent: (attributes: {
      enableSync: boolean;
      syncUsers: 'all' | 'group';
      sendEmailInvites: boolean;
      googleAuthOnly: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'saveAndSyncLater',
        source: orgGSuiteSetupScreen,
        actionSubject: ActionSubject.Button,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32459',
    }),
    syncNowClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'syncNow',
        source: orgGSuiteSavedSettingsNotification,
        actionSubject: ActionSubject.Link,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26402',
    }),
    dismissSettingsSavedNotificationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'dismissButton',
        source: orgGSuiteSavedSettingsNotification,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18755',
    }),
    cancelGSuiteSettingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        source: orgGSuiteSettingsScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    disconnectOrgGSuiteAccountInSettingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'disconnectOrgGSuiteAccount',
        source: orgGSuiteSettingsScreen,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20213',
    }),
    confirmDisconnecingtOrgGSuiteAccountClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmButton',
        source: disconnectGSuiteModal,
        actionSubject: ActionSubject.Button,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26403',
    }),
    orgGSuiteActionRequiredBeforeNextSyncStatusError: (attributes: { errorType?: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGSuiteActionRequiredBeforeNextSync',
        source: orgGSuiteStatusScreen,
        actionSubject: ActionSubject.Error,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26404',
    }),
    orgGSuiteActionRequiredBeforeNextSyncSettingsError: (attributes: { errorType?: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGSuiteActionRequiredBeforeNextSync',
        source: orgGSuiteSettingsScreen,
        actionSubject: ActionSubject.Error,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26404',
    }),
    orgGSuiteSavedSettingsNotificationShownEvent: (attributes: { source: string }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGSuiteSavedSettingsNotification',
        source: attributes.source,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26509',
    }),
    orgGSuiteSyncInProgressStatusNotificationShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGSuiteSyncInProgressStatusNotification',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26510',
    }),
    orgGSuiteSyncCompleteStatusNotificationShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGSuiteSyncCompleteStatusNotification',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26511',
    }),
    atlassianAccessEnabledNotificationShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'atlassianAccessEnabledNotification',
        source: orgGSuiteSettingsScreen,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26512',
    }),
    orgGsuiteDisconnectedShownEvent: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubjectId: 'orgGsuiteDisconnected',
        source: orgGSuiteScreen,
        actionSubject: ActionSubject.NotificationModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26513',
    }),
  },
  operational: {},
  track: {
    connectedTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Connected,
        actionSubject: TrackActionSubject.OrgGSuiteAccount,
        source: orgGSuiteConnectScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20228',
    }),
    syncStartedTrackEvent: (attributes: {
      syncType: orgGSuiteSyncType;
      syncMethod: orgGSuiteSyncMethod;
      source: orgGSuiteSyncSource;
    }): TrackEvent => ({
      data: {
        action: TrackAction.SyncStarted,
        actionSubject: TrackActionSubject.OrgGSuiteAccount,
        source: attributes.source,
        attributes: { syncType: attributes.syncType, syncMethod: attributes.syncMethod },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20229',
    }),
    settingsUpdatedTrackEvent: (attributes: {
      syncUsers: 'all' | 'group';
      numberOfGroups: number;
      enableSync: boolean;
      sendEmailInvites: boolean;
      googleAuthOnly: boolean;
      orgId: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.SettingsUpdated,
        actionSubject: TrackActionSubject.OrgGSuiteAccount,
        actionSubjectId: attributes.orgId,
        source: orgGSuiteSettingsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26405',
    }),
    syncFinishedTrackEvent: (attributes: { syncType: orgGSuiteSyncType; syncMethod: orgGSuiteSyncMethod }): TrackEvent => ({
      data: {
        action: TrackAction.SyncFinished,
        actionSubject: TrackActionSubject.OrgGSuiteAccount,
        source: orgGSuiteScreen,
        attributes: { syncType: attributes.syncType, syncMethod: attributes.syncMethod },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20230',
    }),
    disconnectedTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Disconnected,
        actionSubject: TrackActionSubject.OrgGSuiteAccount,
        source: orgGSuiteScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20232',
    }),
  },
};

export const createGSyncDrawerEventMeta: FocusedTaskUIEventMeta = {
  data: {
    source: orgGSuiteConnectScreen,
  },
};
