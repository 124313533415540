import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'externalUsersDownloadScreenEvent'
  | 'externalUsersDownloadErrorScreenEvent'
  | 'externalUsersDownloadExpiredScreenEvent'
  | 'managedAccountsDownloadScreenEvent'
  | 'managedAccountsDownloadErrorScreenEvent'
  | 'managedAccountsDownloadExpiredScreenEvent'
  | 'auditLogDownloadScreenEvent'
  | 'auditLogDownloadErrorScreenEvent'
  | 'auditLogDownloadExpiredScreenEvent'
  | 'authPolicyMembersDownloadScreenEvent'
  | 'authPolicyMembersDownloadErrorScreenEvent'
  | 'authPolicyMembersDownloadExpiredScreenEvent'
  | 'claimManagedAccountsDownloadScreenEvent'
  | 'claimManagedAccountsDownloadErrorScreenEvent'
  | 'claimManagedAccountsDownloadExpiredScreenEvent'
  | 'unclaimManagedAccountsDownloadScreenEvent'
  | 'unclaimManagedAccountsDownloadErrorScreenEvent'
  | 'unclaimManagedAccountsDownloadExpiredScreenEvent'
  | 'activeUsersByProductDownloadScreen'
  | 'activeUsersByProductDownloadErrorScreen'
  | 'activeUsersByProductDownloadExpiredScreen';

type UIEventKeys =
  | 'downloadExternalUsersCsvEvent'
  | 'downloadManagedAccountsCsvEvent'
  | 'downloadAuditLogCsvEvent'
  | 'downloadAuthPolicyMembersEvent'
  | 'exportAuthPolicyMembersClickEvent'
  | 'cancelExportAuthPolicyMembersEvent'
  | 'downloadClaimManagedAccountsCsvEvent'
  | 'downloadUnclaimManagedAccountsCsvEvent'
  | 'downloadActiveUsersByProductCsvEvent';

type TrackEventKeys =
  | 'authPolicyExportDownloadTrackEvent'
  | 'externalUsersExportDownloadTrackEvent'
  | 'downloadClaimManagedAccountsCsvTrackEvent'
  | 'downloadUnclaimManagedAccountsCsvTrackEvent'
  | 'downloadActiveUsersByProductCsvTrackEvent';

export const exportScreens = {
  externalUsersDownloadScreen: 'externalUsersDownloadScreen',
  externalUsersDownloadErrorScreen: 'externalUsersDownloadErrorScreen',
  externalUsersDownloadExpiredScreen: 'externalUsersDownloadExpiredScreen',
  managedAccountsDownloadScreen: 'managedAccountsDownloadScreen',
  managedAccountsDownloadErrorScreen: 'managedAccountsDownloadErrorScreen',
  managedAccountsDownloadExpiredScreen: 'managedAccountsDownloadExpiredScreen',
  auditLogDownloadScreen: 'auditLogDownloadScreen',
  auditLogDownloadErrorScreen: 'auditLogDownloadErrorScreen',
  auditLogDownloadExpiredScreen: 'auditLogDownloadExpiredScreen',
  authPolicyMembersDownloadScreen: 'authPolicyMembersDownloadScreen',
  authPolicyMembersDownloadErrorScreen: 'authPolicyMembersDownloadErrorScreen',
  authPolicyMembersDownloadExpiredScreen: 'authPolicyMembersDownloadExpiredScreen',
  authPolicyMembersExportModal: 'authPolicyMembersExportModal',
  claimManagedAccountsDownloadScreen: 'claimManagedAccountsDownloadScreen',
  claimManagedAccountsDownloadErrorScreen: 'claimManagedAccountsDownloadErrorScreen',
  claimManagedAccountsDownloadExpiredScreen: 'claimManagedAccountsDownloadExpiredScreen',
  unclaimManagedAccountsDownloadScreen: 'unclaimManagedAccountsDownloadScreen',
  unclaimManagedAccountsDownloadErrorScreen: 'unclaimManagedAccountsDownloadErrorScreen',
  unclaimManagedAccountsDownloadExpiredScreen: 'unclaimManagedAccountsDownloadExpiredScreen',
  activeUsersByProductDownloadScreen: 'activeUsersByProductDownloadScreen',
  activeUsersByProductDownloadErrorScreen: 'activeUsersByProductDownloadErrorScreen',
  activeUsersByProductDownloadExpiredScreen: 'activeUsersByProductDownloadExpiredScreen',
};

export const exportEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: [
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1260694776/Auth+Policies+Usability+Improvements+Analytics+Spec',
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1603408649/EUS+MVP+Analytics+spec',
    'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1860595888/SUC+-+download+csv',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2079086094/User+counts+Phase+2+Analytics+Spec',
  ],
  screen: {
    externalUsersDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.externalUsersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43629',
    }),
    externalUsersDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.externalUsersDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43630',
    }),
    externalUsersDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.externalUsersDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43631',
    }),
    managedAccountsDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.managedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/2201',
    }),
    managedAccountsDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.managedAccountsDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/13605',
    }),
    managedAccountsDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.managedAccountsDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/13606',
    }),
    auditLogDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.auditLogDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16826',
    }),
    auditLogDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.auditLogDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16828',
    }),
    auditLogDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.auditLogDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16827',
    }),
    authPolicyMembersDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.authPolicyMembersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38856',
    }),
    authPolicyMembersDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.authPolicyMembersDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38859',
    }),
    authPolicyMembersDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.authPolicyMembersDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38858',
    }),
    claimManagedAccountsDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.claimManagedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46106',
    }),
    claimManagedAccountsDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.claimManagedAccountsDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46107',
    }),
    claimManagedAccountsDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.claimManagedAccountsDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46108',
    }),
    unclaimManagedAccountsDownloadScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.unclaimManagedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49289',
    }),
    unclaimManagedAccountsDownloadErrorScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.unclaimManagedAccountsDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49290',
    }),
    unclaimManagedAccountsDownloadExpiredScreenEvent: (): ScreenEvent => ({
      data: {
        name: exportScreens.unclaimManagedAccountsDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49291',
    }),
    activeUsersByProductDownloadScreen: (): ScreenEvent => ({
      data: {
        name: exportScreens.activeUsersByProductDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50295',
    }),
    activeUsersByProductDownloadErrorScreen: (): ScreenEvent => ({
      data: {
        name: exportScreens.activeUsersByProductDownloadErrorScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50296',
    }),
    activeUsersByProductDownloadExpiredScreen: (): ScreenEvent => ({
      data: {
        name: exportScreens.activeUsersByProductDownloadExpiredScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50297',
    }),
  },
  ui: {
    downloadExternalUsersCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadExternalUsersCsv',
        source: exportScreens.externalUsersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43632',
    }),
    downloadManagedAccountsCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadManagedAccountsCsv',
        source: exportScreens.managedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.us-east-1.staging.public.atl-paas.net/eventcatalog/view/2203',
    }),
    downloadAuditLogCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'forceDownloadAuditLogCsv',
        source: exportScreens.auditLogDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16830',
    }),
    downloadAuthPolicyMembersEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadAuthPolicyMembersCsv',
        source: exportScreens.authPolicyMembersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38217',
    }),
    exportAuthPolicyMembersClickEvent: ({ source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportMembers',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38272',
    }),
    cancelExportAuthPolicyMembersEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancel',
        source: exportScreens.authPolicyMembersExportModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15990',
    }),
    downloadClaimManagedAccountsCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadClaimManagedAccountsCsv',
        source: exportScreens.managedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46009',
    }),
    downloadUnclaimManagedAccountsCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadUnclaimManagedAccountsCsv',
        source: exportScreens.managedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49292',
    }),
    downloadActiveUsersByProductCsvEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadActiveUsersByProductCsv',
        source: exportScreens.activeUsersByProductDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50298',
    }),
  },
  track: {
    authPolicyExportDownloadTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.MembersAuthPolicy,
        source: exportScreens.authPolicyMembersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38800',
    }),
    externalUsersExportDownloadTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.ExternalUsers,
        source: exportScreens.externalUsersDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/44956',
    }),
    downloadClaimManagedAccountsCsvTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.ClaimManagedAccounts,
        source: exportScreens.claimManagedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49294',
    }),
    downloadUnclaimManagedAccountsCsvTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.UnclaimManagedAccounts,
        source: exportScreens.unclaimManagedAccountsDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49293',
    }),
    downloadActiveUsersByProductCsvTrackEvent: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.ActiveUsersByProduct,
        source: exportScreens.activeUsersByProductDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/50299',
    }),
  },
  operational: {},
};
