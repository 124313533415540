import type { FeatureEvents, OperationalEvent, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'onboardingNotificationDismissButton'
  | 'onboardingNotificationLearnMoreLink'
  | 'enableNavigationV3Link'
  | 'appSwitcherClickEvent'
  | 'productLogoClickEvent'
  | 'organizationDropdownClickEvent'
  | 'billingClickEvent'
  | 'helpClickEvent'
  | 'overviewClickEvent'
  | 'directoryClickEvent'
  | 'billingPreviewClickEvent'
  | 'productsClickEvent'
  | 'securityClickEvent'
  | 'settingsClickEvent'
  | 'privacyClickEvent'
  | 'appSwitcherItemClickEvent'
  | 'organizationItemClickEvent'
  | 'billingItemClickEvent'
  | 'helpItemClickEvent'
  | 'organizationSwitchEvent'
  | 'welcomeNotificationLearnMoreClickEvent'
  | 'welcomeNotificationGotItClickEvent';

type ScreenEventKeys = 'onboardingNotificationScreenEvent' | 'navigationV3WelcomeBannerScreenEvent';
type OperationEventKeys = 'contextChangedOperationalEvent' | 'navigationDoneOperationalEvent';

export enum HELP_ITEM_EVENT_ID {
  DOCUMENTATION = 'documentation',
  LANGUAGE_SETTINGS = 'languageSettings',
  TERMS_OF_USE = 'termsOfUse',
  PRIVACY_POLICY = 'privacyPolicy',
  NOTICE_AT_COLLECTION = 'noticeAtCollection',
  CONTACT_SUPPORT = 'contactSupport',
}

type HelpItemId = HELP_ITEM_EVENT_ID;

export const managedAccountsScreen = 'managedAccountsScreen';
export const onboardingNotificationInlineDialog = 'navigationV3OnboardingNotificationInlineDialog';
export const navigationSource = 'navigationV3';
export const containerNavigation = 'containerNavigationV3';
export const navigationWelcomeBanner = 'navigationV3WelcomeBanner';

export const navigationEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, string, OperationEventKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/699371555/Navigation+v3+Analytics+Spec',
  screen: {
    onboardingNotificationScreenEvent: (attributes: { rolloutoutEnabled: boolean }): ScreenEvent => ({
      data: {
        name: onboardingNotificationInlineDialog,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24490',
    }),
    navigationV3WelcomeBannerScreenEvent: (): ScreenEvent => ({
      data: {
        name: navigationWelcomeBanner,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26434',
    }),
  },
  ui: {
    onboardingNotificationDismissButton: (attributes: { rolloutEnabled: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismissButton',
        source: onboardingNotificationInlineDialog,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/18755',
    }),
    onboardingNotificationLearnMoreLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreLink',
        source: onboardingNotificationInlineDialog,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13402',
    }),
    enableNavigationV3Link: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'enableNavigationV3Link',
        source: onboardingNotificationInlineDialog,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/25443',
    }),
    appSwitcherClickEvent: (attributes: { navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'appSwitcher',
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24502',
    }),
    productLogoClickEvent: (attributes: { navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'productLogo',
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14250',
    }),
    organizationDropdownClickEvent: (attributes: { navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'organizationDropdown',
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24502',
    }),
    billingClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'billing',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26934',
    }),
    helpClickEvent: (attributes: { navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'help',
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24502',
    }),
    overviewClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'overview',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26936',
    }),
    directoryClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'directory',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26935',
    }),
    billingPreviewClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'billingPreview',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39871',
    }),
    productsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'products',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26937',
    }),
    securityClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'security',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26933',
    }),
    settingsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'settings',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26938',
    }),
    privacyClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.NavigationItem,
        actionSubjectId: 'privacy',
        source: navigationSource,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73952',
    }),
    appSwitcherItemClickEvent: (attributes: { navigationContext: string; product: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.AppSwitcherItem,
        source: containerNavigation,
        attributes: {
          navigationLayer: 'appSwitcherLayer',
          isExpanded: true,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24505',
    }),
    organizationItemClickEvent: (attributes: { orgId: string; itemId: string; navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.OrganizationSelectorItem,
        source: containerNavigation,
        attributes: {
          navigationLayer: 'container',
          isExpanded: true,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24509',
    }),
    billingItemClickEvent: (attributes: { orgId?: string; cloudId?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.BillingSelectorItem,
        source: containerNavigation,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24510',
    }),
    helpItemClickEvent: (attributes: { navigationContext: string; itemId: HelpItemId }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.HelpSelectorItem,
        source: containerNavigation,
        attributes: {
          navigationLayer: 'container',
          isExpanded: true,
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24511',
    }),
    organizationSwitchEvent: (attributes: { orgId: string; navigationContext: string }): UIEvent => ({
      data: {
        action: Action.Switched,
        actionSubject: ActionSubject.Organization,
        actionSubjectId: attributes.orgId,
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24512',
    }),
    welcomeNotificationLearnMoreClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'learnMore',
        source: navigationWelcomeBanner,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22168',
    }),
    welcomeNotificationGotItClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'gotIt',
        source: navigationWelcomeBanner,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26436',
    }),
  },
  operational: {
    contextChangedOperationalEvent: (attributes: { navigationContext: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.Changed,
        actionSubject: OperationalSubject.Context,
        actionSubjectId: 'navigationContext',
        source: navigationSource,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24513',
    }),
    navigationDoneOperationalEvent: (attributes: { duration: number }): OperationalEvent => ({
      data: {
        action: OperationalAction.Done,
        actionSubject: OperationalSubject.Navigation,
        source: navigationSource,
        attributes: {
          navigationView: 'global',
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/24514',
    }),
  },
  track: {},
};
