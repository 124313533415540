import * as React from 'react';

import { RootConsumer } from './page-timing-root';
import type { PageTimingRootContext } from './types';

export interface PageTimingProps {
  pageTiming: PageTimingRootContext;
}

export function withPageTiming<TOwnProps>(
  WrappedComponent: React.ComponentType<TOwnProps & PageTimingProps>,
): React.ComponentClass<TOwnProps> {
  return class extends React.Component<TOwnProps> {
    public static WrappedComponent = WrappedComponent;

    public render() {
      return <RootConsumer>{this.wrappedComponentRenderer}</RootConsumer>;
    }

    private wrappedComponentRenderer = (pageTiming: PageTimingRootContext): React.ReactNode => (
      <WrappedComponent {...this.props} pageTiming={pageTiming} />
    );
  };
}
