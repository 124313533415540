import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys = 'emailAddressesScreenEvent' | 'createEmailAddressModalScreenEvent' | 'removeEmailAddressModalScreenEvent';

type UIEventKeys =
  | 'addEmailAddressesClickEvent'
  | 'removeEmailAddressClickEvent'
  | 'addEmailAddressToListClickEvent'
  | 'selectDropdownDomainItemClickEvent'
  | 'selectDropdownProductItemClickEvent'
  | 'confirmEmailAddressesClickEvent'
  | 'cancelEmailAddressesClickEvent'
  | 'productAvailabilityToggleEvent'
  | 'confirmEmailAddressRemovalClickEvent'
  | 'cancelEmailAddressRemovalClickEvent';

type TrackEventKeys = 'addedEmailAddressTrackEvent' | 'removedEmailAddressTrackEvent';

export const emailAddressesScreen = 'emailAddressesScreen';
export const createEmailAddressModalScreen = 'createEmailAddressModal';
export const removeEmailAddressModalScreen = 'removeEmailAddressModal';

export const emailAddressEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1280793145/DMARC+-+Email+Address+-+Analytics+spec',
  screen: {
    emailAddressesScreenEvent: (attributes: {
      isEmailAddressesAvailable: boolean;
      numEmailAddresses: number;
      policies: Record<string, string>[];
    }): ScreenEvent => ({
      data: {
        name: emailAddressesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38919',
    }),
    createEmailAddressModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: createEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38920',
    }),
    removeEmailAddressModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: removeEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38921',
    }),
  },
  ui: {
    addEmailAddressesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addEmailAddresses',
        actionSubject: ActionSubject.Button,
        source: emailAddressesScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38922',
    }),
    removeEmailAddressClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'removeEmailAddress',
        actionSubject: ActionSubject.Link,
        source: emailAddressesScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38923',
    }),
    addEmailAddressToListClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addEmailToList',
        actionSubject: ActionSubject.Button,
        source: createEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38924',
    }),
    selectDropdownDomainItemClickEvent: (attributes: { domainName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'domainDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: createEmailAddressModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38927',
    }),
    selectDropdownProductItemClickEvent: (attributes: { productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'productDropdownItem',
        actionSubject: ActionSubject.DropdownItem,
        source: createEmailAddressModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/19775',
    }),
    confirmEmailAddressesClickEvent: (attributes: { domainName: string; productName: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmButton',
        actionSubject: ActionSubject.Button,
        source: createEmailAddressModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26403',
    }),
    cancelEmailAddressesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: createEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    productAvailabilityToggleEvent: (attributes: { statusChanged: 'enabled' | 'disabled' }): UIEvent => ({
      data: {
        action: Action.Switched,
        actionSubjectId: 'productAvailability',
        actionSubject: ActionSubject.Toggle,
        source: emailAddressesScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38925',
    }),
    confirmEmailAddressRemovalClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmButton',
        actionSubject: ActionSubject.Button,
        source: removeEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26403',
    }),
    cancelEmailAddressRemovalClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: removeEmailAddressModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
  },
  track: {
    addedEmailAddressTrackEvent: (attributes: { products: Record<string, string>[] }): TrackEvent => ({
      data: {
        action: TrackAction.Added,
        actionSubject: TrackActionSubject.EmailAddress,
        source: createEmailAddressModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52784',
    }),
    removedEmailAddressTrackEvent: (attributes: { policyId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Removed,
        actionSubject: TrackActionSubject.EmailAddress,
        source: removeEmailAddressModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53121',
    }),
  },
  operational: {},
};
