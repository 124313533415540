import * as React from 'react';

import { Navigation } from '@adminhub/reactive-vars';

export interface AddedProps {
  collapsedNav?: boolean;
}

export function addCollapsedNavProp<TOwnProps>(Component: React.ComponentType<TOwnProps>): React.ComponentClass<TOwnProps & AddedProps> {
  const isVisible = (props: NavVisibilityControllerImpl['props']): boolean => {
    const { collapsedNav } = props;

    return !collapsedNav;
  };

  interface State {
    shouldShowNav?: boolean;
  }

  class NavVisibilityControllerImpl extends React.Component<TOwnProps & AddedProps, State> {
    public readonly state: Readonly<State> = {};

    public static getDerivedStateFromProps(nextProps: NavVisibilityControllerImpl['props'], prevState: State) {
      const newShouldShow = isVisible(nextProps);
      if (prevState.shouldShowNav === newShouldShow) {
        return null;
      }

      return {
        shouldShowNav: newShouldShow,
      };
    }

    public componentDidMount() {
      const { shouldShowNav } = this.state;

      if (typeof shouldShowNav !== 'undefined') {
        Navigation.reactiveVariables.showLeftSidebarContainer(shouldShowNav);
      }
    }

    public componentDidUpdate(_: any, nextState: State) {
      if (this.state.shouldShowNav === nextState.shouldShowNav || typeof nextState.shouldShowNav === 'undefined') {
        return;
      }

      Navigation.reactiveVariables.showLeftSidebarContainer(nextState.shouldShowNav);
    }

    public render() {
      const {
        // we are stripping this one to not provide it to the `Component />`
        // eslint-disable-next-line @typescript-eslint/tslint/config
        collapsedNav,
        // we are stripping this one to not provide it to the `Component />`
        // eslint-disable-next-line @typescript-eslint/tslint/config
        toggleNavigation,
        ...rest
      } = this.props as any; // we put `any` here to avoid TS error about destructuring a generic type

      return <Component {...rest} />;
    }
  }

  return NavVisibilityControllerImpl;
}
