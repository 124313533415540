import type { FeatureEvents, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type UIEventKeys =
  | 'bulkActionActivate'
  | 'bulkActionDeactivate'
  | 'confirmBulkDeactivate'
  | 'confirmBulkActivate'
  | 'confirmBulkActionFailure';
type TrackEventKeys = 'memberDeactivated' | 'memberReactivated';
type ScreenEventKeys = 'failedDeactivateModalScreenEvent' | 'failedReactivateModalScreenEvent' | 'confirmBulkDeactivateDrawerScreenEvent';
export const managedAccountsScreen = 'managedAccountsScreen';
export const confirmBulkDeactivateDrawer = 'confirmBulkDeactivateDrawer';
export const failedReactivateAccountsModal = 'failedReactivateAccountsModal';
export const failedDeactivateAccountsModal = 'failedDeactivateAccountsModal';

export const memberTableBulkActionEvents: FeatureEvents<ScreenEventKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/622114996/Bulk+Action+Analytics',
  screen: {
    failedDeactivateModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: failedDeactivateAccountsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22146',
    }),
    failedReactivateModalScreenEvent: (): ScreenEvent => ({
      data: {
        name: failedReactivateAccountsModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22147',
    }),
    confirmBulkDeactivateDrawerScreenEvent: (): ScreenEvent => ({
      data: {
        name: confirmBulkDeactivateDrawer,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22148',
    }),
  },
  ui: {
    bulkActionActivate: ({ numberOfCheckedAccounts }: { numberOfCheckedAccounts: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'bulkActionActivate',
        source: managedAccountsScreen,
        attributes: {
          numberOfCheckedAccounts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22149',
    }),
    bulkActionDeactivate: ({ numberOfCheckedAccounts }: { numberOfCheckedAccounts: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'bulkActionDeactivate',
        source: managedAccountsScreen,
        attributes: {
          numberOfCheckedAccounts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22150',
    }),
    confirmBulkDeactivate: ({
      source,
      numberOfCheckedAccounts,
    }: {
      source: 'managedAccountsScreen' | 'confirmBulkDeactivateDrawer';
      numberOfCheckedAccounts: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmBulkDeactivate',
        source,
        attributes: {
          numberOfCheckedAccounts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22151',
    }),
    confirmBulkActivate: ({ numberOfCheckedAccounts }: { numberOfCheckedAccounts: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmBulkDeactivate',
        source: managedAccountsScreen,
        attributes: {
          numberOfCheckedAccounts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22152',
    }),
    confirmBulkActionFailure: ({
      source,
      numberOfFailedAccounts,
    }: {
      source: 'failedDeactivateAccountsModal' | 'failedReactivateAccountsModal';
      numberOfFailedAccounts: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmBulkActionFailure',
        source,
        attributes: {
          numberOfFailedAccounts,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22153',
    }),
  },
  operational: {},
  track: {
    memberDeactivated: ({ memberId, isBulkAction, source }: { memberId: string; isBulkAction: boolean; source: string }) => ({
      data: {
        action: TrackAction.Deactivated,
        actionSubject: TrackActionSubject.Member,
        actionSubjectId: memberId,
        source,
        attributes: {
          bulkAction: isBulkAction,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22144',
    }),
    memberReactivated: ({ memberId, isBulkAction, source }: { memberId: string; isBulkAction: boolean; source: string }) => ({
      data: {
        action: TrackAction.Activated,
        actionSubject: TrackActionSubject.Member,
        actionSubjectId: memberId,
        source,
        attributes: {
          bulkAction: isBulkAction,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22145',
    }),
  },
};
