import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'atlassianIntelligenceScreen'
  | 'addAtlassianIntelligenceFocusTaskSelectProductsStepScreen'
  | 'addAtlassianIntelligenceFocusTaskReviewStepScreen'
  | 'atlassianIntelligenceDataFlowDiagram'
  | 'aiAccessRuleInfoSection';

type UIEventKeys =
  | 'clickedAddAtlassianIntelligence'
  | 'clickedLearnMoreAboutAtlassianIntelligenceLink'
  | 'clickedRemoveAtlassianIntelligence'
  | 'clickedConfirmRemoveAtlassianIntelligence'
  | 'clickedCancelRemoveAtlassianIntelligence'
  | 'clickedConfirmProductSelection'
  | 'clickedCancelProductSelection'
  | 'clickedConfirmProductSelectionAndSubmit'
  | 'returnToProductSelection'
  | 'toggledAgreeToTermsOfService'
  | 'toggledDisableAIForNewProducts'
  | 'clickedLearnMoreAboutDataProtectionLink'
  | 'clickedAIFeaturesLink'
  | 'clickedLearnMoreOnPermissionsLink'
  | 'clickedAtlassianIntelligenceGuideLink'
  | 'clickedTrustStandardsLink'
  | 'clickedAcceptablePolicyUseLink'
  | 'clickedBetaTermsCloudLink'
  | 'clickedAtlassianIntelligenceTermsLink'
  | 'clickedAddAtlassianIntelligenceFromEmptyLandingPage'
  | 'atlassianIntelligenceDataFlowDiagramPositiveFeedbackClickEvent'
  | 'atlassianIntelligenceDataFlowDiagramNegativeFeedbackClickEvent'
  | 'displayedAIRemovedFlagEvent'
  | 'clickedOpenAIRemovedSurveyButtonEvent'
  | 'clickedReadPrivacyPolicyOnAIRemovedSurveyEvent'
  | 'clickedReadAboutAtlassianResearchGroupOnAIRemovedSurveyEvent'
  | 'clickedSkipAIRemovedSurveyButton';

type TrackEventKeys = 'submittedAIRemovedSurveyTrackEvent';

type OperationalEventKeys = string;

export const atlassianIntelligenceScreen = 'atlassianIntelligenceScreen';
export const addAtlassianIntelligenceFocusTaskSelectProductsStepScreen = 'addAtlassianIntelligenceFocusTaskSelectProductsStepScreen';
export const addAtlassianIntelligenceFocusTaskReviewStepScreen = 'addAtlassianIntelligenceFocusTaskReviewStepScreen';
export const atlassianIntelligenceDataFlowDiagram = 'atlassianIntelligenceDataFlowDiagram';
export const aiAccessRuleInfoSection = 'aiAccessRuleInfoSection';
export const atlassianIntelligenceRemovedSurveyModal = 'atlassianIntelligenceRemovedSurveyModal';

export const atlassianIntelligenceEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, OperationalEventKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/2527271162/Generative+AI+Frontend+Analytics+Tech+Spec',
  screen: {
    atlassianIntelligenceScreen: (attributes: { numberOfWorkspacesEnabled: number }): ScreenEvent => ({
      data: {
        name: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51524',
    }),
    addAtlassianIntelligenceFocusTaskSelectProductsStepScreen: (attributes: { numberOfWorkspaceAvailable: number }): ScreenEvent => ({
      data: {
        name: addAtlassianIntelligenceFocusTaskSelectProductsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51525',
    }),
    addAtlassianIntelligenceFocusTaskReviewStepScreen: (attributes: { numberOfWorkspaceSelected: number }): ScreenEvent => ({
      data: {
        name: addAtlassianIntelligenceFocusTaskReviewStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51526',
    }),
    atlassianIntelligenceDataFlowDiagram: (): ScreenEvent => ({
      data: {
        name: atlassianIntelligenceDataFlowDiagram,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62889',
    }),
    aiAccessRuleInfoSection: (): ScreenEvent => ({
      data: {
        name: aiAccessRuleInfoSection,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70977',
    }),
  },
  ui: {
    clickedAddAtlassianIntelligence: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddAtlassianIntelligenceFocusTask',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51527',
    }),
    clickedLearnMoreAboutAtlassianIntelligenceLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutAtlassianIntelligenceLink',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51528',
    }),
    clickedRemoveAtlassianIntelligence: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openRemoveAtlassianIntelligenceModal',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51529',
    }),
    clickedConfirmRemoveAtlassianIntelligence: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRemoveAtlassianIntelligence',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51530',
    }),
    clickedCancelRemoveAtlassianIntelligence: (attributes: { productType: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelRemoveAtlassianIntelligence',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51531',
    }),
    clickedConfirmProductSelection: (attributes: { numberOfWorkspaceSelected: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmProductSelectionAndMoveToReview',
        source: addAtlassianIntelligenceFocusTaskSelectProductsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51532',
    }),
    clickedCancelProductSelection: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelProductSelection',
        source: addAtlassianIntelligenceFocusTaskSelectProductsStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51533',
    }),
    clickedConfirmProductSelectionAndSubmit: (attributes: { numberOfWorkspaceSelected: number; consentedToTerms: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmProductSelectionAndSubmit',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51534',
    }),
    returnToProductSelection: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'returnToProductSelection',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51535',
    }),
    toggledAgreeToTermsOfService: (attributes: { enabled: boolean }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'agreeToTermsOfService',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/51536',
    }),
    toggledDisableAIForNewProducts: (attributes: { enabled: boolean }): UIEvent => ({
      data: {
        action: Action.Toggled,
        actionSubject: ActionSubject.Checkbox,
        actionSubjectId: 'disableAiForNewEnterpriseProdcuts',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75424',
    }),
    clickedLearnMoreAboutDataProtectionLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreAboutDataProtectionLink',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59055',
    }),
    clickedAIFeaturesLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'exploreAIFeaturesLink',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59056',
    }),
    clickedLearnMoreOnPermissionsLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreOnPermissionsLink',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59057',
    }),
    clickedAtlassianIntelligenceGuideLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewAIGuideLink',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59058',
    }),
    clickedTrustStandardsLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'trustStandardsLink',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59059',
    }),
    clickedAcceptablePolicyUseLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'acceptableUsePolicyLink',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59060',
    }),
    clickedBetaTermsCloudLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'betaTermsCloudLink',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59061',
    }),
    clickedAtlassianIntelligenceTermsLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'atlassianIntelligenceTerms',
        source: addAtlassianIntelligenceFocusTaskReviewStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59062',
    }),
    clickedAddAtlassianIntelligenceFromEmptyLandingPage: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddAtlassianIntelligenceFocusTaskFromEmptyPage',
        source: atlassianIntelligenceScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/59176',
    }),
    atlassianIntelligenceDataFlowDiagramPositiveFeedbackClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'atlassianIntelligenceDataFlowDiagramPositiveFeedback',
        actionSubject: ActionSubject.Button,
        source: atlassianIntelligenceDataFlowDiagram,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62884',
    }),
    atlassianIntelligenceDataFlowDiagramNegativeFeedbackClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'atlassianIntelligenceDataFlowDiagramNegativeFeedback',
        actionSubject: ActionSubject.Button,
        source: atlassianIntelligenceDataFlowDiagram,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62885',
    }),
    displayedAIRemovedFlagEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Displayed,
        actionSubject: ActionSubject.Flag,
        actionSubjectId: 'atlassianIntelligenceRemovedFlag',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72248',
    }),
    clickedOpenAIRemovedSurveyButtonEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAIRemovedSurveyButton',
        source: atlassianIntelligenceScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72249',
    }),
    clickedReadPrivacyPolicyOnAIRemovedSurveyEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'readPrivacyPolicyLink',
        source: atlassianIntelligenceRemovedSurveyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72250',
    }),
    clickedReadAboutAtlassianResearchGroupOnAIRemovedSurveyEvent: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'readAboutAtlassianResearchGroupLink',
        source: atlassianIntelligenceRemovedSurveyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72251',
    }),
    clickedSkipAIRemovedSurveyButton: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'skipAIRemovedSurveyButton',
        source: atlassianIntelligenceRemovedSurveyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72253',
    }),
  },
  track: {
    submittedAIRemovedSurveyTrackEvent: ({
      orgId,
      removeAIReasons,
    }: {
      orgId: string;
      removeAIReasons: Record<string, boolean | string>;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Submitted,
        actionSubject: TrackActionSubject.AIRemovedSurvey,
        source: atlassianIntelligenceScreen,
        attributes: {
          orgId,
          ...removeAIReasons,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72252',
    }),
  },
  operational: {},
};
