import * as React from 'react';

import AkFabricAnalyticsListeners, { FabricChannel as AkFabricChannel } from '@atlaskit/analytics-listeners';

import type { AnalyticsClientProps } from './with-analytics-client';

export const withFabricAnalyticsListeners =
  <POwnProps extends {}>(WrappedComponent: React.ComponentType<POwnProps>) =>
  (props: POwnProps & AnalyticsClientProps) => {
    const analyticsClient = props.analyticsClient?.getAnalyticsWebClient?.();

    return (
      <AkFabricAnalyticsListeners
        client={analyticsClient}
        excludedChannels={[AkFabricChannel.atlaskit, AkFabricChannel.elements, AkFabricChannel.editor, AkFabricChannel.media]}
      >
        <WrappedComponent {...props} />
      </AkFabricAnalyticsListeners>
    );
  };
