import type { OrgPolicyStatus } from '@adminhub/graphql-types';

import type { FeatureEvents } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

const screens = {
  encryptionLandingScreen: 'encryptionLandingScreen',
  encryptionPolicyProductsScreen: 'encryptionPolicyProductsScreen',
} as const;

type Screen = (typeof screens)[keyof typeof screens];

export const encryptionEvents = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/BYOK/pages/2806088458/BYOK+-+Front+End+Analytics+Spec',
  screen: {
    encryptionLandingScreen: () => ({
      data: {
        name: screens.encryptionLandingScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56121',
    }),
    encryptionPolicyProductsScreen: (attributes: { policyStatus: OrgPolicyStatus }) => ({
      data: {
        name: screens.encryptionPolicyProductsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56122',
    }),
  },

  ui: {
    learnMore: (_: undefined, data: { source: Screen }) => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMore',
        ...data,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),

    encryptionPolicyProductsScreenLearnMoreError: () => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'errorLearnMore',
        source: screens.encryptionPolicyProductsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56297',
    }),
  },
  track: {},
  operational: {},
} satisfies FeatureEvents<Screen, string, never, never>;
