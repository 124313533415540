import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

const orgDeleteSectionScreen = 'orgDeleteSectionScreen';
const orgDeleteImpactScreen = 'orgDeleteImpactScreen';
const orgDeleteConfirmScreen = 'orgDeleteConfirmScreen';
const orgDeleteSuccessScreen = 'orgDeleteSuccessScreen';

type ScreenKeys =
  | 'orgDeleteSectionScreenEvent'
  | 'orgDeleteImpactScreenEvent'
  | 'orgDeleteConfirmScreenEvent'
  | 'orgDeleteSuccessScreenEvent';
type UiKeys =
  | 'orgDeleteLearnMoreLinkClicked'
  | 'orgDeleteButtonClicked'
  | 'removeAllDomainsLinkClicked'
  | 'unsubscribeProductsLinkClicked'
  | 'orgDataDeleteLearnMoreLinkClicked'
  | 'continueDeleteButtonClicked'
  | 'confirmDeleteButtonClicked'
  | 'backToImpactButtonClicked'
  | 'contactSupportLinkClicked'
  | 'impactScreenCancelDeleteButtonClicked'
  | 'confirmScreenCancelDeleteButtonClicked'
  | 'successScreenCloseButtonClicked'
  | 'returnToHomeLinkClicked'
  | 'orgDeleteErrorShown'
  | 'errorFlagDomainsLinkClicked'
  | 'errorFlagUnsubscribeProductsLinkClicked';

type TrackKeys = 'orgDeleted';

export const orgDeleteEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1486470706/Org+Delete+Analytic+Specs',
  screen: {
    orgDeleteSectionScreenEvent: (attributes: { removeAllDomains: boolean; unsubscribeProducts: boolean }): ScreenEvent => ({
      data: {
        name: orgDeleteSectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41818',
    }),
    orgDeleteImpactScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgDeleteImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41819',
    }),
    orgDeleteConfirmScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41820',
    }),
    orgDeleteSuccessScreenEvent: (): ScreenEvent => ({
      data: {
        name: orgDeleteSuccessScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41821',
    }),
  },
  ui: {
    orgDeleteLearnMoreLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'orgDeleteLearnMore',
        source: orgDeleteSectionScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41822',
    }),
    orgDeleteButtonClicked: (attributes: { removeAllDomains: boolean | ''; unsubscribeProducts: boolean | '' }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'orgDelete',
        source: orgDeleteSectionScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41823',
    }),
    removeAllDomainsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'removeAllDomains',
        source: orgDeleteSectionScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43304',
    }),
    unsubscribeProductsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'unsubscribeProducts',
        source: orgDeleteSectionScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41825',
    }),
    orgDataDeleteLearnMoreLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'orgDataDeleteLearnMore',
        source: orgDeleteImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41828',
    }),
    continueDeleteButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'continueDelete',
        source: orgDeleteImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41829',
    }),
    confirmDeleteButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmDelete',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41830',
    }),
    backToImpactButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backToImpact',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41831',
    }),
    contactSupportLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'contactSupport',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16337',
    }),
    impactScreenCancelDeleteButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelOrgDelete',
        source: orgDeleteImpactScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41832',
    }),
    confirmScreenCancelDeleteButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelOrgDelete',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41832',
    }),
    successScreenCloseButtonClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelOrgDelete',
        source: orgDeleteSuccessScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41832',
    }),
    returnToHomeLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'returnToAdminHome',
        source: orgDeleteSuccessScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41833',
    }),
    orgDeleteErrorShown: (): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Error,
        actionSubjectId: 'orgDeleteError',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20218',
    }),
    errorFlagDomainsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'removeAllDomains',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43304',
    }),
    errorFlagUnsubscribeProductsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'unsubscribeProducts',
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41825',
    }),
  },
  track: {
    orgDeleted: (attributes: { orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.Organization,
        actionSubjectId: attributes.orgId,
        source: orgDeleteConfirmScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/41834',
    }),
  },
  operational: {},
};
