import type { FeatureEvents, OperationalEvent, ScreenEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject } from '../new-analytics-types';

type ScreenKeys = 'productRequestFlag';

type UiKeys = 'productRequestFlagCTAClicked' | 'productRequestFlagCloseClicked';

type OperationalKeys =
  | 'fetchNotificationSucceed'
  | 'fetchNotificationFailed'
  | 'markNotificationGroupAsReadSucceed'
  | 'markNotificationGroupAsReadFailed';

const productRequestFlag = 'productRequestFlag';

export const showProductRequestFlagEvents: FeatureEvents<ScreenKeys, UiKeys, string, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/ECON/pages/2658620039/Show+product+requests+in+Admin+Hub+-+Analytics+spec',
  screen: {
    productRequestFlag: (): ScreenEvent => ({
      data: {
        name: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52995',
    }),
  },
  ui: {
    productRequestFlagCTAClicked: (attributes: { buttonType: 'seeRequest' | 'remindMeLater'; hasDisplayPicture: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productRequestFlagCTA',
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52996',
    }),
    productRequestFlagCloseClicked: (attributes: { hasDisplayPicture: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productRequestFlagClose',
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/52997',
    }),
  },
  track: {},
  operational: {
    fetchNotificationSucceed: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Succeeded,
        actionSubject: OperationalSubject.FetchNotification,
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53022',
    }),
    fetchNotificationFailed: (attributes: { errorMessage: string; isUnexpectedError: boolean }): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.FetchNotification,
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53023',
    }),
    markNotificationGroupAsReadSucceed: (): OperationalEvent => ({
      data: {
        action: OperationalAction.Succeeded,
        actionSubject: OperationalSubject.MarkNotificationGroupAsRead,
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53029',
    }),
    markNotificationGroupAsReadFailed: (attributes: { errorMessage: string; isUnexpectedError: boolean }): OperationalEvent => ({
      data: {
        action: OperationalAction.Failed,
        actionSubject: OperationalSubject.MarkNotificationGroupAsRead,
        source: productRequestFlag,
        attributes: {
          growthInitiative: 'cross-flow',
          ...attributes,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53030',
    }),
  },
};
