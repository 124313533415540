export const matchInternalUrl = (url: string) => url.match(/https?:\/\/(([^/])+\.)?atlassian\.(com|net)(\/|$)/);

const matchSourceType = (name: string) => {
  switch (name) {
    case 'email':
      return '<email>';
    default:
      return '<external>';
  }
};

/**
 * Redacts external URLs to avoid recording them in analytics
 *
 * @param {string} url The URL you are sanitizing
 * @returns {string} <external> if it's an external URL, otherwise, just the normal URL you passed in
 */
export const sanitizeUrl = (url: string): string => (matchInternalUrl(url) ? url : matchSourceType(url));
