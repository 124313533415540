import { ApolloError } from '@apollo/client';

interface GraphQLError {
  operation: string;
  message: string;
  path: string[];
  locations: unknown;
  extensions: unknown;
}

export const isApolloError = (error: unknown): boolean => error instanceof ApolloError;

export const hasGraphQlProperties = (error: unknown): error is GraphQLError => {
  if (typeof error !== 'object' || error === undefined || error === null) {
    return false;
  }

  const graphQlErrorKeys = ['operation', 'message', 'path', 'locations', 'extensions'];

  return graphQlErrorKeys.every((key) => key in error);
};
