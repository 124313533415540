import type { DelayOptInStatus } from '@adminhub/schema-types';

import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';
import type { FeatureEvents, OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';

type ScreenKeys =
  | 'productUpdatesListScreen'
  | 'productUpdatesDetailsScreen'
  | 'productUpdatesReleaseDatesDetailScreen'
  | 'deferRolloutOptInModal'
  | 'deferRolloutModal';

type UiKeys =
  | 'productUpdatesProductFilterButton'
  | 'productUpdatesStatusFilterButton'
  | 'productUpdatesDeferRolloutFilterButton'
  | 'productUpdatesTypeFilterButton'
  | 'productUpdatesBlogDateFilterButton'
  | 'productUpdatesSearchButton'
  | 'productUpdatesPublicRoadmapLink'
  | 'productUpdatesEmailSubscribeButton'
  | 'productUpdatesViewReleaseDatesButton'
  | 'productUpdatesViewReleaseTracksLink'
  | 'productUpdatesReleaseDatesSearchInput'
  | 'productUpdatesUnsupportedProductsBannerShownEvent'
  | 'productUpdatesUnsupportedProductsBannerDismissedEvent'
  | 'learnMoreProductUpdatesClickEvent'
  | 'deferRolloutOptInButton'
  | 'deferRolloutOptInAllow'
  | 'deferRolloutOptInCancel'
  | 'deferRolloutChangeDefer'
  | 'deferRolloutChangeDeferSupport'
  | 'deferRolloutChangeDeferConfirmDeferral'
  | 'deferRolloutChangeDeferCancel'
  | 'rolloutScheduleProductSort'
  | 'rolloutScheduleProductFilter';

type TrackKeys = 'productUpdatesFilter' | 'productUpdatesEmailSubsciptionSubscribed' | 'productUpdatesEmailSubsciptionUnsubscribed';

type OperationalKeys =
  | 'releaseControlsChangeDelayTaskSuccess'
  | 'releaseControlsChangeDelayTaskFail'
  | 'deferRolloutOptInTaskSuccess'
  | 'deferRolloutOptInTaskFail';

export enum ProductUpdatesScreens {
  productUpdatesListScreen = 'productUpdatesListScreen',
  productUpdatesDetailsScreen = 'productUpdatesDetailsScreen',
  productUpdatesReleaseDatesDetailScreen = 'productUpdatesReleaseDatesDetailScreen',
  deferRolloutOptInModal = 'deferRolloutOptInModal',
  deferRolloutModal = 'deferRolloutModal',
}

interface ProductUpdatesListScreenParams {
  delayOptInStatus: DelayOptInStatus;
}

interface ProductUpdatesDetailsScreenParams {
  productUpdatesProductTypes: string;
  changelogId: string;
  fromSubscriberEmail?: boolean;
  hasReleaseTrackProduct?: boolean;
  isViewingDelayInfoSection?: boolean;
}

interface ReleaseControlsChangeDelayParams {
  changelogId: string;
  productTypes: string;
  orgId: string;
  deferralDate: string;
}

export const productUpdatesEvents: FeatureEvents<ScreenKeys, UiKeys, TrackKeys, OperationalKeys> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1552205232/Product+Updates+Analytics+Spec',

  screen: {
    productUpdatesListScreen: ({ delayOptInStatus }: ProductUpdatesListScreenParams): ScreenEvent => ({
      data: {
        name: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: { delayOptInStatus },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46328',
    }),
    productUpdatesDetailsScreen: ({
      productUpdatesProductTypes,
      fromSubscriberEmail,
      changelogId,
      hasReleaseTrackProduct,
      isViewingDelayInfoSection,
    }: ProductUpdatesDetailsScreenParams): ScreenEvent => ({
      data: {
        name: ProductUpdatesScreens.productUpdatesDetailsScreen,
        attributes: {
          productUpdatesProductTypes,
          fromSubscriberEmail,
          changelogId,
          hasReleaseTrackProduct,
          isViewingDelayInfoSection,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46494',
    }),
    productUpdatesReleaseDatesDetailScreen: ({ changelogId }: { changelogId: string }): ScreenEvent => ({
      data: {
        name: ProductUpdatesScreens.productUpdatesReleaseDatesDetailScreen,
        attributes: {
          changelogId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46555',
    }),
    deferRolloutOptInModal: (): ScreenEvent => ({
      data: {
        name: ProductUpdatesScreens.deferRolloutOptInModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74642',
    }),
    deferRolloutModal: (): ScreenEvent => ({
      data: {
        name: ProductUpdatesScreens.deferRolloutModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74641',
    }),
  },

  ui: {
    productUpdatesProductFilterButton: ({ products }: { products: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesProductFilterButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSelectedProduct: products,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46403',
    }),
    productUpdatesStatusFilterButton: ({ statuses }: { statuses: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesStatusFilterButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSelectedStatus: statuses,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46404',
    }),
    productUpdatesDeferRolloutFilterButton: ({ deferRolloutOptions }: { deferRolloutOptions: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesDeferRolloutFilterButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSelectedDeferRollout: deferRolloutOptions,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74803',
    }),
    productUpdatesTypeFilterButton: ({ types }: { types: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesTypeFilterButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSelectedType: types,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46405',
    }),
    productUpdatesBlogDateFilterButton: ({ productUpdatesSelectedBlogDate }: { productUpdatesSelectedBlogDate: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesBlogDateFilterButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSelectedBlogDate,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46406',
    }),
    productUpdatesSearchButton: ({ search }: { search: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesSearchButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          productUpdatesSearchBox: search,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46408',
    }),
    productUpdatesPublicRoadmapLink: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesPublicRoadmapLink',
        source: ProductUpdatesScreens.productUpdatesListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46409',
    }),
    productUpdatesEmailSubscribeButton: ({
      changelogId,
      currentlySubscribed,
    }: {
      changelogId: string;
      currentlySubscribed: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesEmailSubscribeButton',
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
        attributes: {
          currentlySubscribed,
          changelogId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46511',
    }),
    productUpdatesViewReleaseDatesButton: ({ changelogId }: { changelogId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesViewReleaseDatesButton',
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
        attributes: {
          changelogId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46512',
    }),
    productUpdatesViewReleaseTracksLink: ({ changelogId }: { changelogId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'productUpdatesViewReleaseTracksLink',
        source: ProductUpdatesScreens.productUpdatesReleaseDatesDetailScreen,
        attributes: {
          changelogId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46556',
    }),
    productUpdatesReleaseDatesSearchInput: ({
      changelogId,
      searchInputValue,
    }: {
      changelogId: string;
      searchInputValue: string;
    }): UIEvent => ({
      data: {
        action: Action.Submitted,
        actionSubject: ActionSubject.TextField,
        actionSubjectId: 'productUpdatesReleaseDatesSearchInput',
        source: ProductUpdatesScreens.productUpdatesReleaseDatesDetailScreen,
        attributes: {
          changelogId,
          searchInputValue,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46557',
    }),
    productUpdatesUnsupportedProductsBannerShownEvent: ({
      orgId,
      unsupportedProducts,
    }: {
      orgId: string;
      unsupportedProducts: string[];
    }): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Notification,
        actionSubjectId: 'productUpdatesUnsupportedProductsBannerShownEvent',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: { orgId, unsupportedProducts },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47369',
    }),
    productUpdatesUnsupportedProductsBannerDismissedEvent: ({ orgId }: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productUpdatesUnsupportedProductsBannerDismissedEvent',
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: { orgId },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47370',
    }),
    learnMoreProductUpdatesClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreProductUpdates',
        source: ProductUpdatesScreens.productUpdatesListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/58000',
    }),
    deferRolloutOptInButton: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutOptInButton',
        source: ProductUpdatesScreens.productUpdatesListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74760',
    }),
    deferRolloutOptInAllow: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutOptInAllow',
        source: ProductUpdatesScreens.deferRolloutOptInModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74762',
    }),
    deferRolloutOptInCancel: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutOptInCancel',
        source: ProductUpdatesScreens.deferRolloutOptInModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74763',
    }),
    deferRolloutChangeDefer: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutChangeDefer',
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73820',
    }),
    deferRolloutChangeDeferSupport: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutChangeDeferSupport',
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73821',
    }),
    deferRolloutChangeDeferConfirmDeferral: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutChangeDeferConfirmDeferral',
        source: ProductUpdatesScreens.deferRolloutModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73822',
    }),
    deferRolloutChangeDeferCancel: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deferRolloutChangeDeferCancel',
        source: ProductUpdatesScreens.deferRolloutModal,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73823',
    }),
    rolloutScheduleProductSort: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rolloutScheduleProductSort',
        source: ProductUpdatesScreens.productUpdatesReleaseDatesDetailScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74949',
    }),
    rolloutScheduleProductFilter: ({ products }: { products: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rolloutScheduleProductFilter',
        source: ProductUpdatesScreens.productUpdatesReleaseDatesDetailScreen,
        attributes: {
          rolloutScheduleSelectedProduct: products,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73824',
    }),
  },

  track: {
    productUpdatesFilter: ({ orgId, filter }: { orgId: string; filter: string }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.ProductUpdatesFilter,
        actionSubjectId: orgId,
        source: ProductUpdatesScreens.productUpdatesListScreen,
        attributes: {
          filter,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46411',
    }),
    productUpdatesEmailSubsciptionSubscribed: ({ changelogId, product }: { changelogId: string; product: string }): TrackEvent => ({
      data: {
        action: TrackAction.Subscribed,
        actionSubject: TrackActionSubject.ProductUpdatesEmailSubsciption,
        actionSubjectId: changelogId,
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
        attributes: {
          changelogId,
          product,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46513',
    }),
    productUpdatesEmailSubsciptionUnsubscribed: ({ changelogId, product }: { changelogId: string; product: string }): TrackEvent => ({
      data: {
        action: TrackAction.Unsubscribed,
        actionSubject: TrackActionSubject.ProductUpdatesEmailSubsciption,
        actionSubjectId: changelogId,
        source: ProductUpdatesScreens.productUpdatesDetailsScreen,
        attributes: {
          changelogId,
          product,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46514',
    }),
  },

  operational: {
    releaseControlsChangeDelayTaskSuccess: ({
      changelogId,
      productTypes,
      orgId,
      deferralDate,
    }: ReleaseControlsChangeDelayParams): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskSuccess,
        actionSubject: OperationalSubject.ReleaseControlsChangeDelay,
        source: ProductUpdatesScreens.deferRolloutModal,
        attributes: {
          changelogId,
          productTypes,
          orgId,
          deferralDate,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73828',
    }),
    releaseControlsChangeDelayTaskFail: ({
      changelogId,
      productTypes,
      orgId,
      deferralDate,
    }: ReleaseControlsChangeDelayParams): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskFail,
        actionSubject: OperationalSubject.ReleaseControlsChangeDelay,
        source: ProductUpdatesScreens.deferRolloutModal,
        attributes: {
          changelogId,
          productTypes,
          orgId,
          deferralDate,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73829',
    }),
    deferRolloutOptInTaskSuccess: ({ orgId }: { orgId: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskSuccess,
        actionSubject: OperationalSubject.DeferRolloutOptIn,
        source: ProductUpdatesScreens.deferRolloutOptInModal,
        attributes: {
          orgId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75064',
    }),
    deferRolloutOptInTaskFail: ({ orgId }: { orgId: string }): OperationalEvent => ({
      data: {
        action: OperationalAction.TaskFail,
        actionSubject: OperationalSubject.DeferRolloutOptIn,
        source: ProductUpdatesScreens.deferRolloutOptInModal,
        attributes: {
          orgId,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75065',
    }),
  },
};
