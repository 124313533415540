import type { FeatureEvents, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject } from '../new-analytics-types';

type UIKeys = 'memberPageAccountsDropdownItemClicked' | 'memberPageAccountsFilterClicked';
export const managedAccountsScreen = 'managedAccountsScreen';
export type accountFilterType =
  | 'all'
  | 'enabled'
  | 'disabled'
  | 'verified'
  | 'unverified'
  | 'billable'
  | 'non-billable | two_step_enforced | two_step_not_enforced';
export const managedAccountsAccountsFilterEvents: FeatureEvents<string, UIKeys, string, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/930419934/Managed+accounts+-+account+filter+-analytics+spec',
  screen: {},
  ui: {
    memberPageAccountsDropdownItemClicked: (attributes: { accountFilterName: accountFilterType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropdownItem,
        actionSubjectId: 'accountsDropdownItem',
        source: managedAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29947',
    }),
    memberPageAccountsFilterClicked: (attributes: { verifiedFilterShown: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'managedAccountsFilter',
        source: managedAccountsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/29974',
    }),
  },
  track: {},
  operational: {},
};
