import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["level"];
/* eslint-disable @atlaskit/design-system/no-deprecated-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useHeading } from './heading-context';
import NewHeading from './heading.partial';
// https://atlassian.design/foundations/typography
var levelMap = {
  h900: 'h1',
  h800: 'h1',
  h700: 'h2',
  h600: 'h3',
  h500: 'h4',
  h400: 'h5',
  h300: 'h6',
  // NB: These two levels are not covered at all by the existing @atlaskit/css-reset
  h200: 'div',
  h100: 'div'
};
var headingResetStyles = css({
  color: "var(--ds-text, #172B4D)",
  marginBlock: "var(--ds-space-0, 0px)"
});
var h900Styles = css({
  fontSize: "var(--ds-font-size-600, 35px)",
  fontWeight: "var(--ds-font-weight-medium, 500)",
  letterSpacing: '-0.01em',
  lineHeight: "var(--ds-font-lineHeight-600, 40px)"
});
var h800Styles = css({
  fontSize: "var(--ds-font-size-500, 29px)",
  fontWeight: "var(--ds-font-weight-semibold, 600)",
  letterSpacing: '-0.01em',
  lineHeight: "var(--ds-font-lineHeight-500, 32px)"
});
var h700Styles = css({
  fontSize: "var(--ds-font-size-400, 24px)",
  fontWeight: "var(--ds-font-weight-medium, 500)",
  letterSpacing: '-0.01em',
  lineHeight: "var(--ds-font-lineHeight-400, 28px)"
});
var h600Styles = css({
  fontSize: "var(--ds-font-size-300, 20px)",
  fontWeight: "var(--ds-font-weight-medium, 500)",
  letterSpacing: '-0.008em',
  lineHeight: "var(--ds-font-lineHeight-300, 24px)"
});
var h500Styles = css({
  fontSize: "var(--ds-font-size-200, 16px)",
  fontWeight: "var(--ds-font-weight-semibold, 600)",
  letterSpacing: '-0.006em',
  lineHeight: "var(--ds-font-lineHeight-200, 20px)"
});
var h400Styles = css({
  fontSize: "var(--ds-font-size-100, 14px)",
  fontWeight: "var(--ds-font-weight-semibold, 600)",
  letterSpacing: '-0.003em',
  lineHeight: "var(--ds-font-lineHeight-100, 16px)"
});
var h300Styles = css({
  fontSize: "var(--ds-font-size-075, 12px)",
  fontWeight: "var(--ds-font-weight-semibold, 600)",
  letterSpacing: 0,
  lineHeight: "var(--ds-font-lineHeight-100, 16px)",
  textTransform: 'uppercase'
});
var h200Styles = css({
  fontSize: "var(--ds-font-size-075, 12px)",
  fontWeight: "var(--ds-font-weight-semibold, 600)",
  letterSpacing: 0,
  lineHeight: "var(--ds-font-lineHeight-100, 16px)"
});
var h100Styles = css({
  fontSize: "var(--ds-font-size-050, 11px)",
  fontWeight: "var(--ds-font-weight-bold, 700)",
  letterSpacing: 0,
  lineHeight: "var(--ds-font-lineHeight-100, 16px)"
});
var inverseStyles = css({
  color: "var(--ds-text-inverse, #FFF)"
});
var subtlestStyles = css({
  color: "var(--ds-text-subtlest, #6B778C)"
});

/**
 * __Heading__
 *
 * A heading is a typography component used to display text in different sizes and formats.
 *
 * @example
 *
 * ```jsx
 * import Heading from '@atlaskit/heading';
 *
 * const H100 = () => (
 *   <Heading level="h100">h100</Heading>
 * );
 * ```
 */
var OldHeading = function OldHeading(_ref) {
  var children = _ref.children,
    level = _ref.level,
    id = _ref.id,
    testId = _ref.testId,
    as = _ref.as,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'default' : _ref$color;
  if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production' && as && typeof as !== 'string') {
    throw new Error('`as` prop should be a string.');
  }
  var _useHeading = useHeading(levelMap[level]),
    _useHeading2 = _slicedToArray(_useHeading, 2),
    hLevel = _useHeading2[0],
    inferredElement = _useHeading2[1];
  var Markup = as || inferredElement;
  var isSubtleHeading = level === 'h200' || level === 'h100';
  var needsAriaRole = Markup === 'div' && hLevel;
  return jsx(Markup, {
    id: id,
    "data-testid": testId,
    role: needsAriaRole ? 'heading' : undefined,
    "aria-level": needsAriaRole ? hLevel : undefined,
    css: [headingResetStyles, level === 'h100' && h100Styles, level === 'h200' && h200Styles, level === 'h300' && h300Styles, level === 'h400' && h400Styles, level === 'h500' && h500Styles, level === 'h600' && h600Styles, level === 'h700' && h700Styles, level === 'h800' && h800Styles, level === 'h900' && h900Styles, color === 'inverse' && inverseStyles, color === 'default' && isSubtleHeading && subtlestStyles]
  }, children);
};

/**
 * __Heading__
 *
 * A heading is a typography component used to display text in different sizes and formats.
 *
 * @example
 *
 * ```jsx
 * import Heading from '@atlaskit/heading';
 *
 * // New component
 * <Heading size="xxlarge">Page title</Heading>
 *
 * // Old component
 * const H100 = () => (
 *   <Heading level="h100">h100</Heading>
 * );
 * ```
 */
var Heading = function Heading(_ref2) {
  var level = _ref2.level,
    props = _objectWithoutProperties(_ref2, _excluded);
  return level ?
  // eslint-disable-next-line jsx-a11y/heading-has-content, @repo/internal/react/no-unsafe-spread-props
  jsx(OldHeading, _extends({
    level: level
  }, props)) :
  // eslint-disable-next-line jsx-a11y/heading-has-content, @repo/internal/react/no-unsafe-spread-props
  jsx(NewHeading, props);
};
export default Heading;