// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';

export type CenteredAxis = 'horizontal' | 'vertical' | 'both';

export interface CenteredProps {
  axis?: CenteredAxis;
}

function horizontal(axis: CenteredAxis): boolean {
  return axis !== 'vertical';
}

function vertical(axis: CenteredAxis): boolean {
  return axis !== 'horizontal';
}

export const Centered = styled.div`
  display: flex;
  ${({ axis = 'both' }: CenteredProps) =>
    horizontal(axis)
      ? css`
          justify-content: center;
        `
      : ''}
  ${({ axis = 'both' }: CenteredProps) =>
    vertical(axis)
      ? css`
          align-items: center;
        `
      : ''};
`;
