import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'emailDomainsScreenEvent'
  | 'emailsScreenEvent'
  | 'emailDomainsInputModalScreenEvent'
  | 'dnsRecordsConfirmationModalScreenEvent'
  | 'dnsRecordsModalScreenEvent'
  | 'removeEmailDomainModalScreenEvent';

type UIEventKeys =
  | 'learnMoreAboutEmailDomainsClickEvent'
  | 'learnMoreAboutSendingEmailsDomainsClickEvent'
  | 'addDomainClickEvent'
  | 'submitDomainClickEvent'
  | 'cancelDomainClickEvent'
  | 'learnMoreAboutContactSupportClickEvent'
  | 'saveAndVerifyDomainsClickEvent'
  | 'copyDnsRecordsClickEvent'
  | 'cancelDomainConfirmationClickEvent'
  | 'seeDnsRecordsClickEvent'
  | 'checkDomainProviderClickEvent'
  | 'cancelDnsRecordsClickEvent'
  | 'removeDomainsClickEvent'
  | 'confirmRemoveDomainClickEvent'
  | 'cancelRemoveDomainClickEvent'
  | 'contentRestrictedNotificationInputChanged';

type TrackEventKeys = 'verifiedDomainTrackEvent' | 'removedDomainTrackEvent';

export const emailDomainsScreen = 'emailDomainsScreen';
export const emailsScreen = 'emailsScreen';
export const emailDomainsInputModalScreen = 'emailDomainsInputModal';
export const dnsRecordsConfirmationModalScreen = 'dnsRecordsConfirmationModal';
export const dnsRecordsModalScreen = 'dnsRecordsModal';
export const removeEmailDomainModalScreen = 'removeEmailDomainModal';

export const emailDomainsEvents = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/AtlasPrime/pages/1280789384/DMARC+-+Email+domains+-+Analytics+spec',
  screen: {
    emailDomainsScreenEvent: (attributes: {
      isDomainAvailable: boolean;
      numDomains?: number;
      policies?: Record<string, string>[];
    }): ScreenEvent => ({
      data: {
        name: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38905',
    }),
    emailsScreenEvent: (): ScreenEvent => ({
      data: {
        name: emailsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/39008',
    }),
    emailDomainsInputModalScreenEvent: (attributes?: {}): ScreenEvent => ({
      data: {
        name: emailDomainsInputModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38907',
    }),
    dnsRecordsConfirmationModalScreenEvent: (attributes?: {}): ScreenEvent => ({
      data: {
        name: dnsRecordsConfirmationModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38908',
    }),
    dnsRecordsModalScreenEvent: (attributes?: {}): ScreenEvent => ({
      data: {
        name: dnsRecordsModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38909',
    }),
    removeEmailDomainModalScreenEvent: (attributes?: {}): ScreenEvent => ({
      data: {
        name: removeEmailDomainModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38910',
    }),
  },
  ui: {
    learnMoreAboutEmailDomainsClickEvent: (attributes: { isDomainAvailable: false }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMore',
        actionSubject: ActionSubject.Link,
        source: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
    // This is common learn more click event for learn more link in description
    learnMoreAboutSendingEmailsDomainsClickEvent: (attributes?: { isDomainsAvailable: true }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMoreSendEmails',
        actionSubject: ActionSubject.Link,
        source: emailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/57999',
    }),
    addDomainClickEvent: (attributes: { isDomainAvailable: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'addDomain',
        actionSubject: ActionSubject.Button,
        source: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38912',
    }),
    submitDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'submitButton',
        actionSubject: ActionSubject.Button,
        source: emailDomainsInputModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14767',
    }),
    cancelDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: emailDomainsInputModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    learnMoreAboutContactSupportClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'learnMore',
        actionSubject: ActionSubject.Link,
        source: dnsRecordsConfirmationModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16182',
    }),
    saveAndVerifyDomainsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'saveAndVerifyDomains',
        actionSubject: ActionSubject.Button,
        source: dnsRecordsConfirmationModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38913',
    }),
    copyDnsRecordsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'copyDnsRecords',
        actionSubject: ActionSubject.Button,
        source: dnsRecordsConfirmationModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38914',
    }),
    cancelDomainConfirmationClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: dnsRecordsConfirmationModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    seeDnsRecordsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'seeDnsRecord',
        actionSubject: ActionSubject.Link,
        source: emailDomainsScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38915',
    }),
    checkDomainProviderClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'checkDomainProvider',
        actionSubject: ActionSubject.Button,
        source: dnsRecordsModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38916',
    }),
    cancelDnsRecordsClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: dnsRecordsModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    removeDomainsClickEvent: (attributes?: { emailDomain: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'removeDomains',
        actionSubject: ActionSubject.Link,
        source: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38917',
    }),
    confirmRemoveDomainClickEvent: (attributes?: { emailDomain: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'confirmButton',
        actionSubject: ActionSubject.Button,
        source: removeEmailDomainModalScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/26403',
    }),
    cancelRemoveDomainClickEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'cancelButton',
        actionSubject: ActionSubject.Button,
        source: removeEmailDomainModalScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14571',
    }),
    contentRestrictedNotificationInputChanged: ({
      firstTimeActivated = false,
      ...attributes
    }: {
      orgId: string;
      blockEmail: boolean;
      firstTimeActivated: boolean;
      orgPolicyId?: string;
    }) => ({
      data: {
        action: Action.Clicked,
        actionSubjectId: 'contentRestrictedNotificationInputClicked',
        actionSubject: ActionSubject.Toggle,
        source: 'contentRestrictedNotificationInput',
        attributes: { ...attributes, firstTimeActivated },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/69118',
    }),
  },
  track: {
    verifiedDomainTrackEvent: ({ attributes, orgId }: { attributes: { domain: string; policyId: string }; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Verified,
        actionSubjectId: orgId,
        actionSubject: TrackActionSubject.Domain,
        source: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15196',
    }),
    removedDomainTrackEvent: ({ attributes, orgId }: { attributes: { domain?: string; policyId: string }; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.RemovedDomain,
        actionSubjectId: orgId,
        actionSubject: TrackActionSubject.Domain,
        source: emailDomainsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/38918',
    }),
  },
  operational: {},
} satisfies FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string>;
