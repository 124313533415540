import type { ApolloError } from '@apollo/client';

import type {
  DirectoryEntityType,
  LandingHostProduct,
  ProductAccessInput,
  ProductOfflineReason,
  UiProductType,
  UserProductRole,
} from '@adminhub/graphql-types';
import { ActiveStatus, OrgUsersRole, OrgUsersStatus, PlatformRole } from '@adminhub/graphql-types';
import type { BlockedReason } from '@adminhub/schema-types';

import type { OperationalEvent, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, OperationalAction, OperationalSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

interface OriginVersioningAnalyticsAttributes {
  originLibrary: string;
}
type DefaultOriginAnalyticsAttributes = OriginVersioningAnalyticsAttributes & {
  originId: string;
  originProduct: string;
};
type GeneratedOriginAnalyticsAttributes = OriginVersioningAnalyticsAttributes & {
  originIdGenerated: string;
  originProduct: string;
};
type MalformedOriginAnalyticsAttributes = OriginVersioningAnalyticsAttributes & {
  originMalformed: true;
};
type EmptyOriginAnalyticsAttributes = OriginVersioningAnalyticsAttributes;

type OriginAnalyticsAttributes =
  | DefaultOriginAnalyticsAttributes
  | GeneratedOriginAnalyticsAttributes
  | MalformedOriginAnalyticsAttributes
  | EmptyOriginAnalyticsAttributes;

interface UserListAttributes {
  clickedViaFilterResults: boolean;
  hasAvatar: boolean;
  lastSeenOnSite: string;
  userId: string;
  userStatus?: OrgUsersStatus;
  userRole?: OrgUsersRole;
}

export interface ExportUsersAttributes {
  additionalDataSelectedGroupMembership: boolean;
  additionalDataSelectedProductAccess: boolean;
  downloadedAllUsers: boolean;
  selectedGroupsCount: number;
  isPivotSelected: boolean;
  userStatusSelected: 'onlyActiveUsers' | 'allUsers';
  flagLink: boolean;
}

export interface VortexSiteAdminAttributes {
  vortexStatus: boolean;
  tenantCount?: number;
  enhancedSiteAdmin: boolean;
  userRole?: string;
}

export const activeStatusToUserStatus = (activeStatus: ActiveStatus | null) => {
  switch (activeStatus) {
    case ActiveStatus.ENABLED:
      return OrgUsersStatus.ACTIVE;
    case ActiveStatus.DISABLED:
      return OrgUsersStatus.DEACTIVATED;
    case ActiveStatus.BLOCKED:
      return OrgUsersStatus.SUSPENDED;
    default:
      return undefined;
  }
};

export const userListAttributes = (
  {
    activeStatus,
    created,
    id: userId,
    invitationStatus,
    picture,
    platformRole,
    presence,
  }: {
    activeStatus: ActiveStatus | null;
    created: string | null;
    id: string;
    invitationStatus: { invitedAt: string } | null;
    picture: string | null;
    platformRole: PlatformRole | null;
    presence: string | null;
  },
  isFilterActive = false,
): UserListAttributes => {
  const userAddedDate = invitationStatus?.invitedAt ?? created;
  let lastSeenOnSite = '';
  if (presence) {
    lastSeenOnSite = presence;
  } else if (activeStatus === ActiveStatus.ENABLED && userAddedDate) {
    lastSeenOnSite = userAddedDate;
  }

  return {
    clickedViaFilterResults: isFilterActive,
    hasAvatar: !!picture,
    lastSeenOnSite,
    userId,
    userStatus: activeStatusToUserStatus(activeStatus),
    userRole: platformRole === PlatformRole.ORG_ADMIN ? OrgUsersRole.ORG_ADMIN : undefined,
  };
};

export const orgProductListScreen = 'orgProductListScreen';
export const userInviteDrawerSelectRolesStep = 'userInviteDrawerSelectRoles';
export const userInviteDrawerReviewRolesStep = 'userInviteDrawerReviewRoles';
export const updateAccessDrawer = 'updateAccessDrawer';
export const productRoleTableScreen = 'productRoleTableScreen';

const userInviteDrawer = 'orgUserInviteDrawer';
const userInviteDrawerInviteStep = 'orgUserInviteDrawerInviteStep';
const siteInviteDrawer = 'siteUserInviteDrawer';
const userListScreen = 'orgUserListScreen';
const userDetailsScreen = 'orgUserDetailsScreen';
const manageProductsUserLimitsBanner = 'manageProductsUserLimitsBanner';
const manageProductsPaymentDetailsBanner = 'manageProductsPaymentDetailsBanner';
const manageProductsUserLimits = 'manageProductsUserLimits';
const orgProductDetailsScreen = 'orgProductDetailsScreen';
const orgProductDetailsAddGroupsToProductModal = 'orgProductDetailsAddGroupsToProductModal';
const orgUpdateDefaultProductModal = 'orgUpdateDefaultProductModal';
const orgRemoveProductAccessConfirmModal = 'orgRemoveProductAccessConfirmModal';
const groupList = 'orgGroupsScreen';
const orgCreateGroupModal = 'orgCreateGroupModal';
const orgDeleteGroupModal = 'orgDeleteGroupModal';
const groupDetails = 'orgGroupDetailsScreen';
const addMembersToGroup = 'orgAddMembersToGroupModal';
const addUserToGroupsModal = 'orgAddGroupsToUsersModal';
const addProductToGroupModal = 'orgAddProductToGroupModal';
const addProductToUserModal = 'orgAddProductToUserModal';
const orgAddGroupToProductModal = 'orgAddGroupToProductModal';
const orgSuggestChangesModal = 'orgSuggestChangesModal';
const orgOverviewScreen = 'orgOverviewScreen';
const productsOverviewScreen = 'productsOverviewScreen';
const userExportDownloadScreen = 'userExportDownloadScreen';
const orgGroupRenameModal = 'orgGroupRenameModal';
const siteAdminModal = 'siteAdminModal';
const orgGroupRenameErrorModal = 'orgGroupRenameErrorModal';
const releaseTracksScreen = 'releaseTracksScreen';
const removeUserModal = 'removeUserModal';
const addUserToGroupDetailsModal = 'addUserToGroupDetailsModal';
const configureInvitedGuestsSpaceAccessFlag = 'configureInvitedGuestsSpaceAccessFlag';
const userInviteLimitSpotlight = 'userInviteLimitSpotlight';
const productActionsMenu = 'productActionsMenu';
const createAndSyncTeamModal = 'createAndSyncTeamModal';
const showMeHowSpotlight = 'showMeHowSpotlight';
const sendReminderSpotlight = 'sendReminderSpotlight';
const nudgeAdminReinviteSpotlight = 'nudgeAdminSpotlight';
const removeScimSyncedTeamModal = 'removeScimSyncedTeamModal';
const userInviteViaCreateGroupModalSpotlight = 'userInviteViaCreateGroupModalSpotlight';
const userInviteViaAddGroupMembersModalSpotlight = 'userInviteViaAddGroupMembersModalSpotlight';
const groupListProductAccessTooltip = 'groupListProductAccessTooltip';
const rovoBookmarksTab = 'rovoBookmarksTab';
const rovoBookmarksSpotlight = 'rovoBookmarksSpotlight';
const unifiedUserListScreen = 'unifiedUserListScreen';

export type UMSource =
  | typeof userInviteDrawer
  | typeof userInviteDrawerSelectRolesStep
  | typeof userInviteDrawerReviewRolesStep
  | typeof siteInviteDrawer
  | typeof updateAccessDrawer
  | typeof productRoleTableScreen
  | typeof userListScreen
  | typeof unifiedUserListScreen
  | typeof userDetailsScreen
  | typeof removeUserModal
  | typeof orgProductListScreen
  | typeof orgProductDetailsScreen
  | typeof orgProductDetailsAddGroupsToProductModal
  | typeof orgUpdateDefaultProductModal
  | typeof orgRemoveProductAccessConfirmModal
  | typeof groupList
  | typeof orgCreateGroupModal
  | typeof orgDeleteGroupModal
  | typeof groupDetails
  | typeof addMembersToGroup
  | typeof addUserToGroupDetailsModal
  | typeof addProductToGroupModal
  | typeof addProductToUserModal
  | typeof orgAddGroupToProductModal
  | typeof addUserToGroupsModal
  | typeof orgSuggestChangesModal
  | typeof orgOverviewScreen
  | typeof productsOverviewScreen
  | typeof releaseTracksScreen
  | typeof createAndSyncTeamModal
  | typeof rovoBookmarksTab;

export type ButtonClickType = 'button' | 'dropdown' | 'dropdownItem';

export interface ProductListItemAttributes extends Product {
  isSandbox: boolean;
  offlineReasons?: ProductOfflineReason[];
  plan?: string | null;
}

interface ChangePlanLinkAttributes extends ProductListItemAttributes {
  flagKey: string;
  growthInitiative: string;
  orgId: string;
  entitlementId?: string;
}

interface Product {
  productId: UiProductType;
  workspaceId: string;
}

interface SuggestChangesAttributes {
  userId: string;
  emailChanged: boolean;
  displayNameChanged: boolean;
}

interface OrgUserInviteDrawerFieldValuesAttributes {
  numberOfUsersInputted: number;
  numberOfGroupsInputted: number;
  totalProducts: number;
  productAccessInputted: ProductAccessInput[];
  isPersonalizedInviteChecked: boolean;
  personalizedInviteNoteLength: number;
}

interface OrgUserInviteDrawerFieldChangesAttributes {
  hasEmailsEverChanged: boolean;
  hasGroupsEverChanged: boolean;
  hasNoteEverChanged: boolean;
  hasProductRolesEverChanged: boolean;
  hasSendPersonalizeInvitationEverChanged: boolean;
}

interface OrgGroupRenameAttributes {
  groupId: string;
  groupMemberCount: number;
}

interface GroupRenameAttributes {
  groupId: string;
  groupMemberCount: number;
  vortexMode: boolean;
}

interface OrgSiteAdminModifyModalAttributes {
  vortexStatus: boolean;
  tenantCount: number;
  enhancedSiteAdmin: boolean;
  userRole?: string;
}

export interface OrgGroupRenameErrorAttributes extends OrgGroupRenameAttributes {
  blockedReason: BlockedReason;
  jiraErrorCount: number;
  jiraErrorDetails: string[];
}

export interface GroupRenameErrorAttributes extends GroupRenameAttributes {
  blockedReason: BlockedReason;
  jiraErrorCount: number;
  jiraErrorDetails: string[];
}

export const userManagementEvents = {
  confluenceSpec: [
    'https://hello.atlassian.net/wiki/spaces/I/pages/744718557/Vortex+Analytic+Events',
    'https://hello.atlassian.net/wiki/spaces/CLOUDADMIN/pages/4903279108/TwC+Grant+Access+Analytics+Spec',
  ],
  screen: {
    userInviteDrawer: (attributes: { inviteDrawerMultiStepExperimentCohort?: string }): ScreenEvent => ({
      data: {
        name: userInviteDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33319',
    }),
    userInviteDrawerInviteStep: (attributes: { currentStep: number }): ScreenEvent => ({
      data: {
        name: userInviteDrawerInviteStep,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77545',
    }),
    userInviteDrawerSelectRoles: (): ScreenEvent => ({
      data: {
        name: userInviteDrawerSelectRolesStep,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79564',
    }),
    userInviteDrawerReviewRoles: (): ScreenEvent => ({
      data: {
        name: userInviteDrawerReviewRolesStep,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79565',
    }),
    updateAccessDrawer: (attributes: { entityType: DirectoryEntityType }): ScreenEvent => ({
      data: {
        name: updateAccessDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79566',
    }),
    orgUpdateDefaultProductModal: (attributes: Product): ScreenEvent => ({
      data: {
        name: orgUpdateDefaultProductModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33810',
    }),
    orgSuggestChangesModal: (attributes: { userId: string }): ScreenEvent => ({
      data: {
        name: orgSuggestChangesModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/',
    }),
    orgProductListScreen: (attributes: { productCount?: number } = {}): ScreenEvent => ({
      data: {
        name: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33690',
    }),
    orgProductDetailsAddGroupsToProductModal: (attributes: Product): ScreenEvent => ({
      data: {
        name: orgProductDetailsAddGroupsToProductModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34129',
    }),
    orgProductDetailsScreen: (attributes: Product & { userCount: number }): ScreenEvent => ({
      data: {
        name: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33728',
    }),
    userListScreen: (attributes: {
      userCountOnScreen?: number;
      totalUserCount?: number;
      quickInviteVisible: boolean;
      countSearchCharacters: number;
      isOrgAdmin?: boolean;
    }): ScreenEvent => {
      const dataPortalLink = 'https://data-portal.internal.atlassian.com/analytics/registry/33411';

      return {
        data: {
          name: userListScreen,
          attributes,
        },
        dataPortalLink,
      };
    },
    userDetailsScreen: (attributes: { userId: string; userStatus?: OrgUsersStatus }): ScreenEvent => ({
      data: {
        name: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33689',
    }),
    groupList: (attributes: { totalGroupCount: number; countSearchCharacters: number }): ScreenEvent => ({
      data: {
        name: groupList,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33857',
    }),
    createGroupModal: (attributes: { isGroupInviteFunctionalityEnabled: boolean }): ScreenEvent => ({
      data: {
        name: orgCreateGroupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33858',
    }),
    groupDetails: (attributes: {
      groupId: string;
      memberCount: number;
      productAccessCount: number;
      addProductsExpCohort: string;
    }): ScreenEvent => ({
      data: {
        name: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33859',
    }),
    addUserToGroupsModal: (attributes: { userId: string }): ScreenEvent => ({
      data: {
        name: addUserToGroupsModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34779',
    }),
    addMembersToGroup: (attributes: { groupId: string; isGroupInviteFunctionalityEnabled: boolean }): ScreenEvent => ({
      data: {
        name: addMembersToGroup,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33860',
    }),
    userExportDownloadScreen: (attributes: { result: 'success' | 'error' | 'expired' }): ScreenEvent => ({
      data: {
        name: userExportDownloadScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43166',
    }),
    orgGroupRenameModal: (attributes: OrgGroupRenameAttributes): ScreenEvent => ({
      data: {
        name: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48321',
    }),
    groupRenameModal: (attributes: GroupRenameAttributes): ScreenEvent => ({
      data: {
        name: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48321',
    }),
    orgSiteAdminModifyModal: (attributes: OrgSiteAdminModifyModalAttributes): ScreenEvent => ({
      data: {
        name: siteAdminModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66532',
    }),
    orgGroupRenameErrorModal: (attributes: OrgGroupRenameErrorAttributes): ScreenEvent => ({
      data: {
        name: orgGroupRenameErrorModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48320',
    }),
    groupRenameErrorModal: (attributes: GroupRenameErrorAttributes): ScreenEvent => ({
      data: {
        name: orgGroupRenameErrorModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48320',
    }),
    configureInvitedGuestsSpaceAccessFlag: (attributes: {
      groupCount?: number;
      groupIds?: string[];
      userCount?: number;
      userIds?: string[];
      workspaceId: string;
    }): ScreenEvent => ({
      data: {
        name: configureInvitedGuestsSpaceAccessFlag,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/53523',
    }),
    userInviteLimitSpotlight: (): ScreenEvent => ({
      data: {
        name: userInviteLimitSpotlight,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/61776',
    }),
    userInviteViaCreateGroupModalSpotlight: (): ScreenEvent => ({
      data: {
        name: userInviteViaCreateGroupModalSpotlight,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75120',
    }),
    userInviteViaAddGroupMembersModalSpotlight: (): ScreenEvent => ({
      data: {
        name: userInviteViaAddGroupMembersModalSpotlight,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75121',
    }),
    productActionsMenu: (attributes: {
      orgId: string;
      entitlementId?: string;
      type: UiProductType;
      analyticsSource: UMSource;
      offlineReasons?: ProductOfflineReason[];
      hasChangePlanLink?: boolean;
      edition?: string | null;
    }): ScreenEvent => ({
      data: {
        name: productActionsMenu,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70984',
    }),
    productDetailsAddPayment: (
      attributes: {
        cloudId?: string;
        productName?: string;
        edition?: string | null;
        isAboveUserLimit: boolean;
      } & Record<string, unknown>,
    ): ScreenEvent => ({
      data: {
        name: manageProductsPaymentDetailsBanner,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73673',
    }),
    productDetailsUpgrade: (
      attributes: { cloudId?: string; productName?: string; edition?: string | null; isEqualOrAboveUserLimit: boolean } & Record<
        string,
        unknown
      >,
    ): ScreenEvent => ({
      data: {
        name: manageProductsUserLimitsBanner,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73674',
    }),
    productDetailsUserLimit: (
      attributes: { seatsTaken?: number | null; seatsTotal?: number | null; productName?: string; edition?: string | null } & Record<
        string,
        unknown
      >,
    ): ScreenEvent => ({
      data: {
        name: manageProductsUserLimits,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73675',
    }),
    showMeHowSpotlight: (attributes: { orgSize: number }): ScreenEvent => ({
      data: {
        name: showMeHowSpotlight,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74442',
    }),
    sendReminderSpotlight: (attributes: { orgSize: number }): ScreenEvent => ({
      data: {
        name: sendReminderSpotlight,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74443',
    }),
    groupListProductAccessTooltip: (attributes: { rows: number }): ScreenEvent => ({
      data: {
        name: groupListProductAccessTooltip,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76970',
    }),
    rovoBookmarksSpotlight: (): ScreenEvent => ({
      data: {
        name: rovoBookmarksSpotlight,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77314',
    }),
  },
  ui: {
    productDetailsBillableUsersFilterClicked: (attributes: { count?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'billableUsersFilter',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry',
    }),
    clickedNextInDirectorySpotlight: (
      source: UMSource,
      attributes: { orgId: string; userId?: string; vortexMigratedDate?: string },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'vortexDirectorySpotlight',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40747',
    }),
    clickedNextInProductSpotlight: (
      source: UMSource,
      attributes: { orgId: string; userId?: string; vortexMigratedDate?: string },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'vortexProductSpotlight',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40748',
    }),
    productDetailsToggleDefaultProductSettings: (attributes: Product & { hasAccess: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Toggle,
        actionSubjectId: 'newUserAccess',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14853',
    }),
    productDetailsRemoveProductAccessConfirmClicked: (attributes: Product & { groupId: string; groupMemberCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRemoveGroupFromProduct',
        source: orgRemoveProductAccessConfirmModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33817',
    }),
    productDetailsUpdateDefaultGroupForProductModalClicked: (
      attributes: Product & { groupId: string; addedRole?: UserProductRole; removedRole?: UserProductRole },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateGroupDefault',
        source: orgUpdateDefaultProductModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33736',
    }),
    productDetailsGroupNameClicked: (attributes: { userCount?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'group',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33730',
    }),
    productDetailsAddGroupModalConfirmClicked: (
      attributes: Product & { groupIds: string[]; productRoles: UserProductRole[] },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGroupsToProduct',
        source: orgProductDetailsAddGroupsToProductModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33734',
    }),
    productDetailsAddGroupClicked: (attributes: Product): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGroups',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33735',
    }),
    productDetailsShowGroupDetailsClicked: (attributes: Product): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'showGroupDetails',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14850',
    }),
    productDetailsRemoveGroupFromProductClicked: (attributes: Product): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeGroupFromProduct',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14849',
    }),
    productDetailsUpdateGroupDefaultClicked: (attributes: Product): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateGroupDefault',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33736',
    }),
    productDetailsUpgradeProduct: (attributes: {
      cloudId?: string;
      productName?: string;
      edition?: string | null;
      isEqualOrAboveUserLimit: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageProductsTryItFree',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73676',
    }),
    productDetailsAddPaymentDetails: (attributes: {
      cloudId?: string;
      productName?: string;
      edition?: string | null;
      isAboveUserLimit: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageProductsAddPaymentDetails',
        source: orgProductDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73677',
    }),
    productListAddProductsClicked: (attributes: { isSiteless: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addProduct',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/27990',
    }),
    productListLinkProductsClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.MenuItem,
        actionSubjectId: 'linkProducts',
        source: orgProductListScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/46210',
    }),
    productListOpenLandingProductModal: (attributes: { orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openUpdateLandingProductModal',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40749',
    }),
    productListUpdateLandingProductConfirm: (attributes: {
      products: {
        workspaceId: string;
        landingHostProduct: LandingHostProduct;
      }[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateLandingProductConfirm',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40751',
    }),
    productListManageAccessClicked: (attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageAccess',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33691',
    }),
    productListManageUsersClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageUsers',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/20211',
    }),
    productListChangePlanClicked: (source: UMSource, attributes: ChangePlanLinkAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'changePlan',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/32777',
    }),
    productListViewBillClicked: (attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewBill',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33693',
    }),
    productListCustomizeUrlClicked: (attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'customizeUrl',
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33692',
    }),
    productListProductSettingsClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productSettings',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33695',
    }),
    productListProductNavigationClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productNavigation',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34596',
    }),
    productListProductNavigationAppsClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productAppsNavigation',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34597',
    }),
    productListProductNavigationGuestAccessClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productGuestAccessNavigation',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40574',
    }),
    productListCreateSandboxClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createSandbox',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/22167',
    }),
    productListUpdateReleaseTrackClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateReleaseTrack',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34591',
    }),
    productListManageDataResidencyClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageDataResidency',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34592',
    }),
    productListManageBackupAndRestoreClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageBackupAndRestore',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/56292',
    }),
    userQuickInviteButtonClicked: (attributes: {
      numberOfUsersInvited: number;
      totalProducts: number;
      productsGrantedAccess: ProductAccessInput[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'quickInviteUsers',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34600',
    }),
    inviteDrawerNextButtonClicked: (
      attributes: OrgUserInviteDrawerFieldValuesAttributes & OrgUserInviteDrawerFieldChangesAttributes & { error?: string },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nextToReviewRolesStep',
        source: userInviteDrawerSelectRolesStep,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79782',
    }),
    inviteDrawerBackButtonToCloseClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeSelectRolesStep',
        source: userInviteDrawerSelectRolesStep,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79769',
    }),
    inviteDrawerBackButtonClicked: (
      attributes: OrgUserInviteDrawerFieldValuesAttributes & OrgUserInviteDrawerFieldChangesAttributes,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'backToSelectRolesStep',
        source: userInviteDrawerReviewRolesStep,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79770',
    }),
    inviteDrawerCloseButtonClicked: ({ source }: { source: UMSource }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeInviteDrawer',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79771',
    }),
    appsTabClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'appSelectionTab',
        source: userInviteDrawerSelectRolesStep,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79772',
    }),
    groupsTabClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'groupSelectionTab',
        source: userInviteDrawerSelectRolesStep,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79773',
    }),
    nudgeUpdateSelectionButtonClicked: ({
      attributes,
      source,
    }: {
      attributes: { nudgeType?: string; entityType?: DirectoryEntityType };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nudgeUpdateSelection',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79774',
    }),
    nudgeDismissButtonClicked: ({
      attributes,
      source,
    }: {
      attributes: { nudgeType?: string; entityType?: DirectoryEntityType };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nudgeDismiss',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79775',
    }),
    nudgeSuccessDismissButtonClicked: ({
      attributes,
      source,
    }: {
      attributes?: { entityType?: DirectoryEntityType };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'nudgeSuccessDismiss',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79776',
    }),
    userInviteButtonClicked: ({
      attributes,
      source = userInviteDrawer,
    }: {
      attributes: {
        numberOfUsersInvited: number;
        numberOfGroupsAdded: number;
        isPersonalizedInviteSent: boolean;
        personalizedInviteNoteLength: number;
        isInviteSent: boolean;
        totalProducts: number;
        productsGrantedAccess: ProductAccessInput[];
        error?: string;
      } & OrgUserInviteDrawerFieldChangesAttributes;
      source?: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'inviteUsers',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14416',
    }),
    userInviteNextButtonClicked: (
      attributes: OrgUserInviteDrawerFieldValuesAttributes & { error?: string } & OrgUserInviteDrawerFieldChangesAttributes,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'inviteUsersNext',
        source: userInviteDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77508',
    }),
    userInviteGoBackButtonClicked: (
      attributes: OrgUserInviteDrawerFieldValuesAttributes & OrgUserInviteDrawerFieldChangesAttributes,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'inviteUsersGoBack',
        source: userInviteDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77509',
    }),
    userInviteCancelButtonClicked: (
      attributes?: OrgUserInviteDrawerFieldValuesAttributes & OrgUserInviteDrawerFieldChangesAttributes,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelInviteUsers',
        source: userInviteDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14418',
    }),
    userInviteCloseButtonClicked: (
      attributes?: OrgUserInviteDrawerFieldValuesAttributes &
        OrgUserInviteDrawerFieldChangesAttributes & {
          inviteDrawerStep: number;
        },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeInviteUsers',
        source: userInviteDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14419',
    }),
    unselectAllProductRolesLinkClicked: (source: UMSource, attributes: { count: number; roles: ProductAccessInput[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'unselectAllProductRoles',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/73218',
    }),
    guestInviteSpaceConfigurationLinkClicked: (attributes: { siteId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'configureInvitedGuestsSpaceAccess',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/45377',
    }),
    userListFilterSelected: (attributes: {
      userCountOnScreen?: number;
      totalUserCount?: number;
      searchFieldLength: number;
      filterRolesSelected: OrgUsersRole[];
      filterStatusSelected: OrgUsersStatus[];
      filterProductSelected: UiProductType[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'userListFilterSelected',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/49390',
    }),
    userFilterOpened: (attributes: {
      userCountOnScreen?: number;
      totalUserCount?: number;
      filterRolesSelected: OrgUsersRole[];
      filterStatusSelected: OrgUsersStatus[];
      filterProductSelected: UiProductType[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'userListFilterOpened',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33442',
    }),
    userFilterClosed: (attributes: {
      userCountOnScreen?: number;
      totalUserCount?: number;
      filterRolesSelected: OrgUsersRole[];
      filterStatusSelected: OrgUsersStatus[];
      filterProductSelected: UiProductType[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'userListFilterClosed',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33443',
    }),
    userInviteDrawerButtonClicked: (attributes: { userCountOnScreen?: number; totalUserCount?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openInviteUserDrawer',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14633',
    }),
    userLinkClicked: (source: UMSource, attributes: { userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'userNameLink',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16264',
    }),
    totalUsersFilter: (attributes: { count?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'totalUsersFilter',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34776',
    }),
    activeUsersFilter: (attributes: { count?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'activeUsersFilter',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34777',
    }),
    adminUsersFilter: (attributes: { count?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'adminUsersFilter',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34778',
    }),
    exportUsers: (attributes: { userCountOnScreen?: number; totalUserCount?: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'exportUsers',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14634',
    }),
    downloadExportUserList: (attributes: ExportUsersAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadExportUserList',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15118',
    }),
    showUserDetails: (attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'showDetails',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14645',
    }),
    resendInvite: (attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resendInvite',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14640',
    }),
    productRoleTableFiltersChanged: ({
      attributes,
      source,
    }: {
      attributes: { filterState: { apps: string[]; plans: string[] }; entityType?: DirectoryEntityType };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Changed,
        actionSubject: ActionSubject.FilterController,
        actionSubjectId: 'productRoleTableFilters',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79900',
    }),
    productRoleTablePaginationNextClicked: ({
      attributes,
      source,
    }: {
      attributes: { entityType?: DirectoryEntityType; startIndex: number; endIndex: number; totalResults: number };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productRoleTablePaginationNext',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79780',
    }),
    productRoleTablePaginationPreviousClicked: ({
      attributes,
      source,
    }: {
      attributes: { entityType?: DirectoryEntityType; startIndex: number; endIndex: number; totalResults: number };
      source: UMSource;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'productRoleTablePaginationPrevious',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79781',
    }),
    addUserToGroups: (source: UMSource, attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addToGroups',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14644',
    }),
    removeUserFromGroups: (
      source: UMSource,
      attributes: { groupId: string; membersAdded: number; productAccessCount?: number },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeUserFromGroupConfirm',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/',
    }),
    suspendAccess: (source: UMSource, attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'suspendAccess',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33596',
    }),
    resumeAccess: (source: UMSource, attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'resumeAccess',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33597',
    }),
    assignOrganizationRole: (attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'assignOrganizationRole',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33731',
    }),
    assignOrganizationRoleConfirm: (attributes: { userId: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'assignOrganizationRoleConfirm',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40606',
    }),
    openAddProductsModal: (attributes: { userId: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModal',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40604',
    }),
    grantedUserProductRole: (source: UMSource, attributes: UserListAttributes & { products: ProductAccessInput[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'grantedUserProductRole',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/',
    }),
    grantedUserProductRoleViaDropdown: (
      source: UMSource,
      attributes: UserListAttributes & { products: ProductAccessInput[] },
    ): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'grantedUserProductRole',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40602',
    }),
    revokedUserProductRoleViaDropdown: (
      source: UMSource,
      attributes: UserListAttributes & { products: ProductAccessInput[] },
    ): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'revokedUserProductRole',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40603',
    }),
    revokedUserProductRole: (attributes: UserListAttributes & { products: ProductAccessInput[] }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'revokedUserAllProductRolesMoreMenu',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40224',
    }),
    openSuggestChangesModal: (attributes: { userId: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openSuggestChangesModal',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40609',
    }),
    openManagedAccount: (attributes: { userId: string; orgId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openManagedAccountScreen',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40610',
    }),
    promptResetPassword: (attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'promptResetPassword',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33732',
    }),
    viewJiraProjectRoles: (attributes: Product & UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'viewJiraProjectRoles',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16380',
    }),
    editJiraProjectRoles: (attributes: Product & UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'editJiraUserProperties',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/16381',
    }),
    viewProductDetails: (attributes: Product & UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewProductDetails',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33864',
    }),
    manageSpaceAccess: (attributes: Product & UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageSpaceAccess',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40566',
    }),
    loginAsUser: (attributes: Product & { userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'loginAsUser',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14431',
    }),
    suggestChanges: (attributes: SuggestChangesAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'suggestChanges',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34926',
    }),
    removeUser: (attributes: UserListAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeUser',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33960',
    }),
    confirmRemoveUser: (attributes: { userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'confirmRemoveUser',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34889',
    }),
    menuDropdownOpenSiteAdminModal: (attributes: VortexSiteAdminAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'siteAdminMenu',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66692',
    }),
    grantButtonOpenSiteAdminModal: (attributes: VortexSiteAdminAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'grantSiteAdminExisting',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66695',
    }),
    updateOrGrantSiteAdminModal: (attributes: VortexSiteAdminAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateSiteAdmin',
        source: siteAdminModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66693',
    }),
    cancelSiteAdminModal: (attributes: VortexSiteAdminAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelSiteAdmin',
        source: siteAdminModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66694',
    }),
    viewGroup: (attributes: { groupId: string; accessToProductCount: number; userStatus?: OrgUsersStatus; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewGroup',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14434',
    }),
    viewGroupViaLink: (source: UMSource, attributes: { groupId: string; userId?: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'groupNameLink',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13403',
    }),
    removeUserFromThisGroup: (attributes: {
      groupId: string;
      accessToProductCount: number;
      userStatus?: OrgUsersStatus;
      userId: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeUserFromThisGroup',
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33990',
    }),
    removeUserFromThisGroupConfirm: (
      source,
      attributes: {
        groupId: string;
        productAccessCount?: number;
        userStatus?: OrgUsersStatus;
        userIdRemoved: string;
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeUserFromThisGroupConfirm',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40222',
    }),
    addUserToGroupsModalConfirmClicked: (
      source: UMSource,
      attributes: { groupIds: string[]; userId: string; userStatus?: OrgUsersStatus; workspaceIds?: string[]; productIds?: string[] },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGroupsToUserConfirm',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40221',
    }),
    groupListSearch: (attributes: { countSearchCharacters: number }): UIEvent => ({
      data: {
        action: Action.Searched,
        actionSubject: ActionSubject.Field,
        actionSubjectId: 'groupSearchField',
        source: groupList,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13447',
    }),
    groupListCreateGroup: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createGroupButton',
        source: groupList,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13401',
    }),
    groupListShowGroupDetails: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'groupDetailsLink',
        source: groupList,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/',
    }),
    createGroupConfirmButtonClicked: (attributes: {
      description: boolean;
      membersAdded: number;
      isGroupInviteFunctionalityEnabled: boolean;
      emailsAdded: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createGroupConfirmButton',
        source: orgCreateGroupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13406',
    }),
    groupShowUserDetails: (attributes: { groupId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'showUserDetails',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33861',
    }),
    addGroupMembers: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addGroupMembers',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33862',
    }),
    addGroupMembersConfirm: (attributes: {
      groupId: string;
      isGroupInviteFunctionalityEnabled: boolean;
      emailsAdded: number;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'addMemberToGroupConfirm',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13408',
    }),
    openAddProductsToGroupsModal: (attributes: { groupId?: string; isAddProductsExpButton?: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModal',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40604',
    }),
    addProductsToGroupsModalConfirm: (
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
      },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openAddProductsModalConfirm',
        source: addProductToGroupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40720',
    }),
    editGroupDescription: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editGroupDescriptionButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13410',
    }),
    cancelGroupRename: (attributes: { groupId: string; groupMemberCount: number; vortexMode: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelGroupRename',
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47983',
    }),
    jiraRenameBlockerLink: (attributes: {
      groupId: string;
      groupMemberCount: number;
      jiraErrorCount: number;
      URL?: string;
      vortexMode: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'jiraRenameBlockerLink',
        source: orgGroupRenameErrorModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48325',
    }),
    cancelOrgGroupRename: (attributes: { groupId: string; groupMemberCount: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelGroupRename',
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47983',
    }),
    jiraOrgRenameBlockerLink: (attributes: {
      groupId: string;
      groupMemberCount: number;
      jiraErrorCount: number;
      URL?: string;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'jiraRenameBlockerLink',
        source: orgGroupRenameErrorModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/48325',
    }),
    grantedGroupProductRole: (
      source: UMSource,
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
      },
    ): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'grantedGroupProductRole',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40722',
    }),
    revokedGroupProductRole: (
      source: UMSource,
      attributes: Product & {
        groupId: string;
        revokedRole: UserProductRole;
      },
    ): UIEvent => ({
      data: {
        action: Action.Selected,
        actionSubject: ActionSubject.DropListItem,
        actionSubjectId: 'revokedGroupProductRole',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40723',
    }),
    editGroupDescriptionConfirm: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editGroupDescriptionConfirmButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13417',
    }),
    deleteGroup: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteGroupButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13412',
    }),
    deleteGroupConfirm: (attributes: { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteGroupConfirmButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13415',
    }),
    removeUserFromGroup: (attributes: { groupId: string; userIdRemoved: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeMember',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33863',
    }),
    viewGroupProductDetails: (attributes: Product & { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewProductDetails',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33864',
    }),
    removeProductFromGroup: (attributes: Product & { groupId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeProductFromGroup',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33865',
    }),
    removeProductFromGroupConfirm: (
      attributes: Product & { groupId: string; revokedRole: UserProductRole; existingRoles?: UserProductRole[] },
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeProductFromGroupConfirm',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40223',
    }),
    userExportDownloadClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'downloadUserExportCsv',
        source: userExportDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43164',
    }),
    productListManageProductClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageProduct',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55171',
    }),
    viewUserCountsClickedEvent: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewUserCounts',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71419',
    }),
    viewInsightsButtonClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'viewInsights',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/30559',
    }),
    productListManageProductAppsClicked: (source: UMSource, attributes: ProductListItemAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'manageApps',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/55173',
    }),
    userPendingRequestsClicked: (
      source: UMSource,
      siteId: string,
      numPendingRequests: number,
      type: ButtonClickType = 'button',
      isUnifiedRequestEnabled?: boolean,
    ): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'approveRequests',
        source,
        attributes: {
          siteId,
          numPendingRequests,
          type,
          isUnifiedRequestEnabled: isUnifiedRequestEnabled ? true : undefined,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62171',
    }),
    groupPickerDropdownClicked: (source: UMSource): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'groupPicker',
        source,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70119',
    }),
    groupPickerDropdownShown: (
      source: UMSource,
      attributes: {
        optionMaxSiteCount: number;
        optionMaxDescriptionLength: number;
        optionMaxNameLength: number;
        totalProductsShown: number;
        totalProductOverflowShown: number;
        totalCharacterCount: number;
      },
    ): UIEvent => ({
      data: {
        action: Action.Shown,
        actionSubject: ActionSubject.Dropdown,
        actionSubjectId: 'groupPicker',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/70327',
    }),
    goToUserAccessSettingsLinkClicked: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'goToUserAccessSettings',
        source: 'daDiscoveryBanner',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71665',
    }),
    dismissedDaDiscoveryBanner: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'dismissDaDiscoveryBanner',
        source: 'daDiscoveryBanner',
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71666',
    }),
    groupDetailsCreateTeam: (attributes: {
      groupId: string;
      nonVortexMode?: boolean;
      groupMemberCount?: number;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createTeamButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72406',
    }),
    createAndSyncTeamConfirmButtonClicked: (attributes: {
      groupId: string;
      nonVortexMode?: boolean;
      memberCount?: number;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createAndSyncTeamConfirmButton',
        source: createAndSyncTeamModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72414',
    }),
    createAndSyncTeamCancelButtonClicked: (attributes: { groupId: string; nonVortexMode?: boolean; isScimGroup: boolean }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createAndSyncTeamCancelButton',
        source: createAndSyncTeamModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72415',
    }),
    createAndSyncTeamSuccessFlagViewTeam: (attributes: {
      groupId: string;
      teamId: string;
      nonVortexMode?: boolean;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'createAndSyncTeamSuccessFlagViewTeam',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72416',
    }),
    connectTeamButtonClicked: (attributes: {
      groupId: string;
      teamId: string;
      orgId: string;
      siteId: string;
      groupMemberCount: number;
      isScimGroup: boolean;
      nonVortexMode?: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'connectTeamButton',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75737',
    }),
    groupListTeamLinkClicked: (attributes: {
      teamId: string;
      groupId: string;
      nonVortexMode?: boolean;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'groupListTeamLink',
        source: groupList,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72417',
    }),
    groupDetailsTeamLinkClicked: (attributes: {
      teamId: string;
      groupId: string;
      nonVortexMode?: boolean;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'groupDetailsTeamLink',
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72418',
    }),
    nudgeAdminSpotlightClicked: (attributes: { buttonName: string; orgSize: number }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'spotlightInteract',
        source: nudgeAdminReinviteSpotlight,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74352',
    }),
    removeSyncTeamConfirmButtonClicked: (attributes: {
      groupId: string;
      nonVortexMode?: boolean;
      memberCount?: number;
      isScimGroup: boolean;
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'removeSyncTeamConfirmButton',
        source: removeScimSyncedTeamModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74299',
    }),
    clickedNextInRovoBookmarksSpotlight: (source: UMSource, attributes: { orgId: string; userId: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'rovoBookmarksSpotlight',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/77315',
    }),
    clickedUpdateAccess: (attributes: {
      entityType: DirectoryEntityType;
      grantedRoles: ProductAccessInput[];
      revokedRoles: ProductAccessInput[];
    }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'updateAccess',
        source: updateAccessDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79784',
    }),
    closeUpdateAccess: (attributes: { entityType: DirectoryEntityType }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'closeUpdateAccess',
        source: updateAccessDrawer,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79785',
    }),
  },

  track: {
    userInvited: ({
      attributes,
      source = userInviteDrawer,
    }: {
      attributes: {
        numberOfUsersInvited: number;
        numberOfGroupsAdded: number;
        isPersonalizedInviteSent: boolean;
        personalizedInviteNoteLength: number;
        isInviteSent: boolean;
        totalProducts: number;
        productsGrantedAccess: ProductAccessInput[];
        invitedUserIds: string[];
      } & OriginAnalyticsAttributes;
      source: UMSource;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Invited,
        actionSubject: TrackActionSubject.User,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14409',
    }),
    resendUserInvite: (attributes: { userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Resent,
        actionSubject: TrackActionSubject.Invitation,
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry',
    }),
    userInvitedViaQuickInvite: (
      attributes: {
        numberOfUsersInvited: number;
        totalProducts: number;
        productsGrantedAccess: ProductAccessInput[];
        invitedUserIds: string[];
      } & OriginAnalyticsAttributes,
    ): TrackEvent => ({
      data: {
        action: TrackAction.Invited,
        actionSubject: TrackActionSubject.User,
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14409',
    }),
    groupCreated: (attributes: {
      description: boolean;
      membersAdded: number;
      createdGroupId: string;
      isGroupInviteFunctionalityEnabled: boolean;
      emailsAdded: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.Group,
        source: orgCreateGroupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13398',
    }),
    editGroupDescriptionConfirm: (attributes: { groupId: string }): TrackEvent => ({
      data: {
        action: TrackAction.DescriptionUpdated,
        actionSubject: TrackActionSubject.Group,
        source: orgCreateGroupModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40721',
    }),
    editGroupRenameConfirm: (attributes: { vortexMode: boolean; groupId: string; groupMemberCount: number }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.GroupName,
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47986',
    }),
    editGroupRenameError: (attributes: {
      vortexMode: boolean;
      groupId: string;
      groupMemberCount: number;
      failureType: string;
    }): TrackEvent => ({
      data: {
        action: TrackAction.ChangeFailed,
        actionSubject: TrackActionSubject.GroupName,
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47984',
    }),
    editOrgGroupRenameConfirm: (attributes: { groupId: string; groupMemberCount: number }): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.GroupName,
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47986',
    }),
    editOrgGroupRenameError: (attributes: { groupId: string; groupMemberCount: number; failureType: string }): TrackEvent => ({
      data: {
        action: TrackAction.ChangeFailed,
        actionSubject: TrackActionSubject.GroupName,
        source: orgGroupRenameModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/47984',
    }),
    usersAddedToGroup: (attributes: {
      groupId: string;
      membersAdded: number;
      productAccessCount?: number;
      userIds?: string[];
      workspaceIds?: string[];
      productIds?: string[];
      isGroupInviteFunctionalityEnabled: boolean;
      emailsAdded?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.AddedUsers,
        actionSubject: TrackActionSubject.Group,
        source: addMembersToGroup,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33866',
    }),
    groupsAddedToUser: (
      source,
      attributes: {
        groupIds: string[];
        userId: string;
        userStatus?: OrgUsersStatus | string;
        workspaceIds: string[] | undefined;
        productIds: string[] | undefined;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.AddedToGroup,
        actionSubject: TrackActionSubject.User,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/14247',
    }),
    deleteGroup: (attributes: { groupId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.Group,
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/13400',
    }),
    grantedUserProductAccess: (attributes: UserListAttributes & { grantedRoles?: ProductAccessInput[]; originId: string }): TrackEvent => ({
      data: {
        action: TrackAction.GrantedProductAccess,
        actionSubject: TrackActionSubject.User,
        source: 'updateAccessDrawer',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79794',
    }),
    revokedUserProductAccess: (attributes: UserListAttributes & { revokedRoles?: ProductAccessInput[]; originId: string }): TrackEvent => ({
      data: {
        action: TrackAction.RevokedProductAccess,
        actionSubject: TrackActionSubject.User,
        source: 'updateAccessDrawer',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79795',
    }),
    grantedProductAccess: (
      source: UMSource,
      attributes: UserListAttributes & { products: ProductAccessInput[]; originId: string },
    ): TrackEvent => ({
      data: {
        action: TrackAction.Granted,
        actionSubject: TrackActionSubject.ProductAccess,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34927',
    }),
    revokedProductAccess: (attributes: UserListAttributes & { products: ProductAccessInput[] }): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.ProductAccess,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34928',
    }),
    grantedOrgAccess: (source: UMSource, attributes: { userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Granted,
        actionSubject: TrackActionSubject.OrgAccess,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40600',
    }),
    revokedOrgAccess: (source: UMSource, attributes: { userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.OrgAccess,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40601',
    }),
    promptResetPassword: (attributes: UserListAttributes): TrackEvent => ({
      data: {
        action: TrackAction.PromptPassword,
        actionSubject: TrackActionSubject.User,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76197',
    }),
    suggestChanges: (attributes: SuggestChangesAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Sent,
        actionSubject: TrackActionSubject.SuggestChanges,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40605',
    }),
    grantedOrgAdminAccess: (attributes: { userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Granted,
        actionSubject: TrackActionSubject.OrgAdminAccess,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34886',
    }),
    revokedOrgAdminAccess: (attributes: { userId: string; orgId: string }): TrackEvent => ({
      data: {
        action: TrackAction.Revoked,
        actionSubject: TrackActionSubject.OrgAdminAccess,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/34887',
    }),
    removeUserFromOrg: (attributes: { userId: string }): TrackEvent => ({
      data: {
        action: TrackAction.RemoveFromOrg,
        actionSubject: TrackActionSubject.User,
        source: userDetailsScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40607',
    }),
    removeUserFromGroup: (
      source: UMSource,
      attributes: { groupId: string; userIdRemoved: string; productAccessCount?: number },
    ): TrackEvent => ({
      data: {
        action: TrackAction.RemoveMember,
        actionSubject: TrackActionSubject.Group,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33867',
    }),
    grantedGroupProductAccess: (attributes: {
      groupId: string;
      grantedRoles?: ProductAccessInput[];
      userIds?: string[];
      totalUsers?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.GrantedProductAccess,
        actionSubject: TrackActionSubject.Group,
        source: 'updateAccessDrawer',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79790',
    }),
    revokedGroupProductAccess: (attributes: {
      groupId: string;
      revokedRoles?: ProductAccessInput[];
      userIds?: string[];
      totalUsers?: number;
    }): TrackEvent => ({
      data: {
        action: TrackAction.RevokedProductAccess,
        actionSubject: TrackActionSubject.Group,
        source: 'updateAccessDrawer',
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/79791',
    }),
    addedProductToGroup: (
      source: UMSource,
      attributes: Product & {
        groupId: string;
        grantedRole: UserProductRole;
        existingRoles?: UserProductRole[];
        userIds?: string[];
        totalUsers?: number;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.AddProduct,
        actionSubject: TrackActionSubject.Group,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33970',
    }),
    removeProductFromGroup: (
      source: UMSource,
      attributes: Product & { groupId: string; revokedRole: UserProductRole; existingRoles?: UserProductRole[] },
    ): TrackEvent => ({
      data: {
        action: TrackAction.RemoveProduct,
        actionSubject: TrackActionSubject.Group,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/33868',
    }),
    updateLandingProduct: (attributes: {
      products: {
        workspaceId: string;
        landingHostProduct: LandingHostProduct;
      }[];
    }): TrackEvent => ({
      data: {
        action: TrackAction.Updated,
        actionSubject: TrackActionSubject.LandingProduct,
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40752',
    }),
    grantedDefaultRole: (
      attributes: Product & {
        groupId: string;
        role: UserProductRole;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.DefaultGroupUpdated,
        actionSubject: TrackActionSubject.Group,
        source: orgProductListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40724',
    }),
    userExportDownloaded: (): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.User,
        source: userExportDownloadScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/43165',
    }),
    userListExportDownloaded: (attributes: ExportUsersAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Downloaded,
        actionSubject: TrackActionSubject.UserList,
        actionSubjectId: 'downloadExportUserList',
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/15118',
    }),
    featureExposed: (
      source: string,
      attributes: {
        flagKey?: string;
        cohort?: string;
        reason?: string;
        experimentName?: string;
        source?: string;
        value?: string;
        growthInitiative?: string;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.Exposed,
        actionSubject: TrackActionSubject.Feature,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/62183',
    }),
    interveningRemovalTrackEvent: (
      source: string,
      attributes: {
        flagKey?: string;
        experimentCohort?: string;
        reason?: string;
        experimentName?: string;
        source?: string;
        value?: string;
        growthInitiative?: string;
      },
    ): TrackEvent => ({
      data: {
        action: TrackAction.RemovedUser,
        actionSubject: TrackActionSubject.RemovalUser,
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/76496',
    }),
    grantedVortexSASuccess: (attributes: VortexSiteAdminAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.UserSiteAdminRole,
        source: siteAdminModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66810',
    }),
    grantedVortexSAFailed: (attributes: VortexSiteAdminAttributes): TrackEvent => ({
      data: {
        action: TrackAction.ChangeFailed,
        actionSubject: TrackActionSubject.UserSiteAdminRole,
        source: siteAdminModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/66811',
    }),
    createAndSyncTeamFailed: (attributes: { groupId: string; error: any; nonVortexMode?: boolean; isScimGroup: boolean }): TrackEvent => ({
      data: {
        action: TrackAction.CreationFailed,
        actionSubject: TrackActionSubject.Team,
        source: createAndSyncTeamModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72422',
    }),
    createAndSyncTeamMemberCountTooLarge: (attributes: {
      groupId: string;
      nonVortexMode?: boolean;
      groupMemberCount?: number;
      isScimGroup: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.CreationFailed,
        actionSubject: TrackActionSubject.Team,
        source: createAndSyncTeamModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72422',
    }),
    connectTeamFailed: (attributes: {
      groupId: string;
      teamId: string;
      orgId: string;
      siteId: string;
      isScimGroup: boolean;
      groupMemberCount: number;
      error: any;
      nonVortexMode?: boolean;
    }): TrackEvent => ({
      data: {
        action: TrackAction.ConnectionFailed,
        actionSubject: TrackActionSubject.Team,
        source: groupDetails,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/75738',
    }),
    /**
     * Track event for when the experiment eligibility was checked
     *
     * @param {string} source - source of the event
     * @param {string} attributes - flagKey, flagCohort, ineligibilityReasons
     * @param {string} attributes.flagKey - experiment id e.g. 'da_discovery_and_optimisation`
     * @param {string} attributes.flagCohort - cohort value e.g. 'control' or 'variation'
     * @param {string} attributes.ineligibilityReasons - optional array of reasons why the target is ineligible for the experiment
     * @returns {TrackEvent} - event data
     */
    eligibilityChecked: (
      source: string,
      attributes: {
        flagKey: string;
        flagCohort: string;
        ineligibilityReasons: string[] | undefined;
      },
    ): TrackEvent => ({
      data: {
        source,
        actionSubject: TrackActionSubject.Eligibility,
        action: TrackAction.Checked,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/71036',
    }),
  },
  operational: {
    pendingAccessRequestsFetched: (siteId: string, attributes: { totalPendingAccessRequests: number }): OperationalEvent => ({
      data: {
        action: OperationalAction.Fetched,
        actionSubject: OperationalSubject.PendingAccessRequests,
        actionSubjectId: siteId,
        source: userListScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/60633',
    }),
    featureExposed: (cohort: string, flagKey: string, source: UMSource): OperationalEvent => ({
      data: {
        action: OperationalAction.Exposed,
        actionSubject: OperationalSubject.Feature,
        source,
        attributes: {
          value: cohort,
          flagKey,
        },
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/21022',
    }),
    graphqlError(source: string, queryName: string, error: ApolloError, attributes: object): OperationalEvent {
      return {
        data: {
          actionSubject: OperationalSubject.GraphQL,
          action: OperationalAction.Error,
          source,
          attributes: {
            queryName,
            error,
            ...attributes,
          },
        },
        dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/72177',
      };
    },
  },
};
