import type { FormValues } from '@adminhub/mobile-security-types';

import type { FeatureEvents, ScreenEvent, TrackEvent, UIEvent } from '../new-analytics-types';
import { Action, ActionSubject, TrackAction, TrackActionSubject } from '../new-analytics-types';

type ScreenKeys =
  | 'devicePolicyEmptyScreenEvent'
  | 'createDevicePolicyModalScreenEvent'
  | 'editDevicePolicyModalScreenEvent'
  | 'devicePolicyTableScreenEvent'
  | 'confirmDeleteDevicePolicyModalScreenEvent'
  | 'editDevicePolicySettingsTabScreenEvent'
  | 'editDevicePolicyAppTabScreenEvent'
  | 'createDevicePolicyAppsStepScreenEvent'
  | 'createDevicePolicyNameStepScreenEvent'
  | 'createDevicePolicyUsersStepScreenEvent'
  | 'createDevicePolicySettingsStepScreenEvent';
type UIEventKeys =
  | 'openCreateDevicePolicyEvent'
  | 'openEditDevicePolicyEvent'
  | 'deleteDevicePolicyEvent'
  | 'learnMoreMobileSecurityEvent'
  | 'moreAboutMobileAppEvent'
  | 'howToCreateAMobileAppPolicyEvent'
  | 'howToEditAMobileAppPolicyEvent'
  | 'learnMoreMobileAppPolicySettingsEvent'
  | 'ipAllowlistLinkClickEvent'
  | 'createDevicePolicyEvent'
  | 'editDevicePolicyEvent'
  | 'cancelCreateDevicePolicyEvent'
  | 'cancelEditDevicePolicyEvent'
  | 'cancelDeleteDevicePolicyEvent'
  | 'openDevicePolicyActionsEvent'
  | 'namePolicyStepBackEvent'
  | 'selectUsersStepBackEvent'
  | 'settingsStepBackEvent'
  | 'selectAppsStepNextEvent'
  | 'namePolicyStepNextEvent'
  | 'selectUsersStepNextEvent'
  | 'settingsStepNextEvent'
  | 'openDeleteDevicePolicyEvent';
type TrackEventKeys = 'createdDevicePolicyTrackEvent' | 'editedDevicePolicyTrackEvent' | 'deletedDevicePolicyTrackEvent';

export const mobileDevicePolicyScreens = {
  devicePolicyEmptyScreen: 'devicePolicyEmptyScreen',
  createDevicePolicyModal: 'createDevicePolicyModalScreen',
  editDevicePolicyModal: 'editDevicePolicyModalScreen',
  editDevicePolicyScreen: 'editDevicePolicyScreen',
  devicePolicyTableScreen: 'devicePolicyTableScreen',
  confirmDeleteDevicePolicyModal: 'confirmDeleteDevicePolicyModalScreen',
  createDevicePolicyAppsStepScreen: 'createDevicePolicyAppsStepScreen',
  createDevicePolicyNameStepScreen: 'createDevicePolicyNameStepScreen',
  createDevicePolicyUsersStepScreen: 'createDevicePolicyUsersStepScreen',
  createDevicePolicySettingsStepScreen: 'createDevicePolicySettingsStepScreen',
  editDevicePolicyAppTabScreen: 'editDevicePolicyAppTabScreen',
  editDevicePolicySettingsTabScreen: 'editDevicePolicySettingsTabScreen',
};

interface MAMAttributes {
  orgId: string;
}

interface MobileDevicePolicyAttributes extends MAMAttributes {
  mobileDevicePolicyCount: number;
}

interface MoreAboutMobileAppAttributes {
  currentApp: string;
  selectedAppAris: string[];
}

interface CreatePolicyAttributes extends MobileDevicePolicyAttributes {
  selectedUserIds: string[];
  selectAppAris: string[];
}

interface EditPolicyAttributes extends MobileDevicePolicyAttributes {
  selectAppAris: string[];
}

interface EmptyPolicyAttributes extends MAMAttributes {
  hasAccessSubscription: boolean;
  hasVerifiedDomain: boolean;
}

interface IndividualPolicyAttributes extends MobileDevicePolicyAttributes {
  isSpecificUserPolicy: boolean;
}

interface FormAttributes extends MobileDevicePolicyAttributes {
  formValues: FormValues;
}

interface SelectAppsStepAttributes extends MobileDevicePolicyAttributes {
  selectedAppAris: string[];
}

interface SelectUsersStepAttributes extends MobileDevicePolicyAttributes {
  applyPolicyToAllUsers: boolean;
  selectedUserIds: string[];
}

export const mobileDevicePolicyEvents: FeatureEvents<ScreenKeys, UIEventKeys, TrackEventKeys, string> = {
  confluenceSpec: 'https://hello.atlassian.net/wiki/spaces/I/pages/1114636388/MAM+FE+Analytic+Events',
  screen: {
    devicePolicyEmptyScreenEvent: (attributes: EmptyPolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.devicePolicyEmptyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36040',
    }),
    createDevicePolicyModalScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.createDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36137',
    }),
    editDevicePolicyModalScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.editDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36136',
    }),
    devicePolicyTableScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.devicePolicyTableScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36043',
    }),
    confirmDeleteDevicePolicyModalScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.confirmDeleteDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40148',
    }),
    editDevicePolicySettingsTabScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.editDevicePolicySettingsTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74601',
    }),
    editDevicePolicyAppTabScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.editDevicePolicyAppTabScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74602',
    }),
    createDevicePolicyAppsStepScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.createDevicePolicyAppsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74586',
    }),
    createDevicePolicyNameStepScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.createDevicePolicyNameStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74588',
    }),
    createDevicePolicyUsersStepScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.createDevicePolicyUsersStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74591',
    }),
    createDevicePolicySettingsStepScreenEvent: (attributes: MobileDevicePolicyAttributes): ScreenEvent => ({
      data: {
        name: mobileDevicePolicyScreens.createDevicePolicySettingsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74594',
    }),
  },
  ui: {
    openCreateDevicePolicyEvent: (attributes: MAMAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openCreateDevicePolicy',
        source: mobileDevicePolicyScreens.devicePolicyEmptyScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36086',
    }),
    openEditDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openEditDevicePolicy',
        source: mobileDevicePolicyScreens.devicePolicyTableScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36087',
    }),
    deleteDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'deleteDevicePolicy',
        source: mobileDevicePolicyScreens.confirmDeleteDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36088',
    }),
    learnMoreMobileSecurityEvent: (attributes: MobileDevicePolicyAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreMobileSecurity',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36089',
    }),
    moreAboutMobileAppEvent: (attributes: MoreAboutMobileAppAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'moreAboutMobileApp',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74457',
    }),
    howToCreateAMobileAppPolicyEvent: (attributes: CreatePolicyAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewCreateAMobileAppPolicy',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74458',
    }),
    howToEditAMobileAppPolicyEvent: (attributes: EditPolicyAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewEditAMobileAppPolicy',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74461',
    }),
    learnMoreMobileAppPolicySettingsEvent: (attributes: FormAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'learnMoreMobileAppPolicySettings',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74463',
    }),
    ipAllowlistLinkClickEvent: (attributes: MobileDevicePolicyAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Link,
        actionSubjectId: 'viewIpAllowLists',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74510',
    }),
    createDevicePolicyEvent: (attributes: FormAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'createDevicePolicy',
        source: mobileDevicePolicyScreens.createDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/37100',
    }),
    editDevicePolicyEvent: (attributes: FormAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'editDevicePolicy',
        source: mobileDevicePolicyScreens.editDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/37099',
    }),
    cancelCreateDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelCreateDevicePolicy',
        source: mobileDevicePolicyScreens.createDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40145',
    }),
    cancelEditDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelEditDevicePolicy',
        source: mobileDevicePolicyScreens.editDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40146',
    }),
    cancelDeleteDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'cancelDeleteDevicePolicy',
        source: mobileDevicePolicyScreens.confirmDeleteDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/40147',
    }),
    openDevicePolicyActionsEvent: (attributes: MobileDevicePolicyAttributes, { source }: { source: string }): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openDevicePolicyActions',
        source,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74584',
    }),
    namePolicyStepBackEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'namePolicyStepBack',
        source: mobileDevicePolicyScreens.createDevicePolicyNameStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74589',
    }),
    selectUsersStepBackEvent: (attributes: SelectUsersStepAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectUsersStepBack',
        source: mobileDevicePolicyScreens.createDevicePolicyUsersStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74592',
    }),
    settingsStepBackEvent: (attributes: FormAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'settingsStepBack',
        source: mobileDevicePolicyScreens.createDevicePolicySettingsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74596',
    }),
    selectAppsStepNextEvent: (attributes: SelectAppsStepAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectAppsStepNext',
        source: mobileDevicePolicyScreens.createDevicePolicyAppsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74587',
    }),
    namePolicyStepNextEvent: (): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'namePolicyStepNext',
        source: mobileDevicePolicyScreens.createDevicePolicyNameStepScreen,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74590',
    }),
    selectUsersStepNextEvent: (attributes: SelectUsersStepAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'selectUsersStepNext',
        source: mobileDevicePolicyScreens.createDevicePolicyUsersStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74593',
    }),
    settingsStepNextEvent: (attributes: FormAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'settingsStepNext',
        source: mobileDevicePolicyScreens.createDevicePolicySettingsStepScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74598',
    }),
    openDeleteDevicePolicyEvent: (attributes: IndividualPolicyAttributes): UIEvent => ({
      data: {
        action: Action.Clicked,
        actionSubject: ActionSubject.Button,
        actionSubjectId: 'openDeleteDevicePolicy',
        source: mobileDevicePolicyScreens.devicePolicyTableScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/74600',
    }),
  },
  track: {
    createdDevicePolicyTrackEvent: (attributes: FormAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Created,
        actionSubject: TrackActionSubject.MobileDevicePolicy,
        source: mobileDevicePolicyScreens.createDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36067',
    }),
    editedDevicePolicyTrackEvent: (attributes: FormAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Changed,
        actionSubject: TrackActionSubject.MobileDevicePolicy,
        source: mobileDevicePolicyScreens.editDevicePolicyModal,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36068',
    }),
    deletedDevicePolicyTrackEvent: (attributes: IndividualPolicyAttributes): TrackEvent => ({
      data: {
        action: TrackAction.Deleted,
        actionSubject: TrackActionSubject.MobileDevicePolicy,
        source: mobileDevicePolicyScreens.devicePolicyTableScreen,
        attributes,
      },
      dataPortalLink: 'https://data-portal.internal.atlassian.com/analytics/registry/36069',
    }),
  },
  operational: {},
};
